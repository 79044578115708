import Popup from "reactjs-popup";
import Cross from "../../assets/images/xmark-solid.svg";
import Google from "../../assets/images/google.png";
import { format, addDays, addMonths } from "date-fns";
import { Calendar, DateRange } from "react-date-range";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  signin,
  userSignup,
  userVerification,
  userGoogleSignin,
  authSelector,
  resendCode,
  emailOtpSend,
} from "../../redux/slices/mobile/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Visible from "../../assets/images/visibility.svg";
import VisibleOff from "../../assets/images/visibility_off.svg";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import UserPopUpInfo from "../userPopUpInfo/userPopUpInfo";
import { createActivity } from "../../redux/slices/mobile/activitySlice";
import { PhotoSource, SignupType, YesNo } from "../utils/subjects";
import { useNavigate } from "react-router-dom";
import {
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import FacebookLogin from "./FacebookLogin";

const Login = ({
  callback,
  redirectTo,
  setVendorSignUpModal,
  postValue,
  setPostValue,
  placeArr,
  userArr,
  mState,
  setMState,
  setPlaceArr,
  setUserArr,
}) => {
  let [authState, setAuthState] = useState("login");
  let [PVState, setPVState] = useState("password");
  const [forgetPassMessage, setForgetPassMessage] = useState(false);

  let navigate = useNavigate();
  const dateRef = useRef();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [date, setDate] = useState(new Date());
  const [otp, setOtp] = useState(undefined);
  const { auth, errors } = useSelector(authSelector);
  const loginErrors = errors;
  let dispatch = useDispatch();
  let authinfo = useSelector(authSelector).auth;
  const maxCount = 20;
  const [timerCount, setTimer] = useState(maxCount);
  let signupControl = () => {
    setAuthState("sign up");
  };
  let VisibleControl = () => {
    if (PVState === "text") {
      setPVState("password");
    } else {
      setPVState("text");
    }
  };
  console.log("Login Errors", loginErrors);
  // const googleLogin = useGoogleLogin({
  //     onSuccess: credentialResponse => {
  //         if (credentialResponse.access_token) {
  //             // dispatch(userGoogleSignin({ idToken: credentialResponse.access_token }));
  //             fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
  //                 method: 'post',
  //                 headers: {
  //                     "Authorization": `Bearer ${credentialResponse.access_token}`
  //                 }
  //             })
  //                 .then(res => res.json())
  //                 .then(data => console.log(data))

  //         }

  //         console.log(credentialResponse);
  //     },

  // });

  useEffect(() => {
    const hideModalOutSideClickHandle = (e) => {
      if (!dateRef?.current?.contains(e.target)) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("mousedown", hideModalOutSideClickHandle);
  });

  useEffect(() => {
    if (auth?.user?.verified === 1 && postValue?.value != undefined) {
      dispatch(
        createActivity({
          data: {
            name: postValue.value,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            recordStatus: 4,
            finished: YesNo.No,
            tagChecked: false,
            participants: userArr ? userArr : undefined,
            locations: placeArr
              ? placeArr.map((item) => {
                  return {
                    locationId: item?.plus_code?.global_code || item?.place_id,
                    latitude: item?.photos[0].photo_reference
                      ? item?.geometry.location.lat
                      : item?.geometry.location.lat(),
                    longitude: item?.photos[0].photo_reference
                      ? item?.geometry.location.lat
                      : item?.geometry.location.lat(),
                    name: item.name,
                    photo: {
                      photoReference: item?.photos[0].photo_reference
                        ? item?.photos[0].photo_reference
                        : item?.photos[0].getUrl(),
                      photoSource: item?.photos[0].photo_reference
                        ? PhotoSource.GooglePlace
                        : PhotoSource.GooglePlaceFullURL,
                    },
                  };
                })
              : undefined,
          },
          callback: (res) => {
            setMState();
            console.log("Res", res);
            setPostValue({ vissible: true, value: undefined });
            setPlaceArr([]);
            setUserArr([]);
            navigate(getJourneyNavOption(res));
          },

          token: auth.token,
        })
      );
    }
  }, [authinfo]);

  const startTimer = () => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000); //each count lasts for a second
    //cleanup the interval on complete
    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (authState == "verify") {
      startTimer();
    }
  }, [authState]);

  const resendOtp = () => {
    setTimer(maxCount);
    startTimer();
    if (authState == "verify") {
      dispatch(resendCode({ data: { email: auth?.user?.email } }));
    }
  };

  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return (
    <>
      <Popup
        open={mState}
        closeOnDocumentClick={false}
        contentStyle={{
          width: "100%",
          height: "100vh",
          backgroundColor: "transparent",
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px",
          overflow: "scroll",
        }}
      >
        <div className="relative bg-white w-[500px] space-y-5 mx-auto rounded-[20px] p-[40px] max-[600px]:h-screen max-[600px]:p-5 max-[600px]:overflow-y-scroll  max-[600px]:rounded-none">
          <div className="flex items-start gap-3">
            <img
              src={Cross}
              alt="cross"
              className="cursor-pointer w-[20px] "
              onClick={setMState}
            />

            <p className="text-[30px] font-semibold leading-[30px] w-full text-center">
              {authState === "verify" && "Verify Your Email"}
              {authState === "login" && "welcome to jrnyfy"}
              {authState === "sign up" && "Sign Up"}
              {authState === "forgotPass" && "Reset Your Password"}
            </p>
          </div>

          {authinfo?.user?.verified === 1 ? (
            <UserPopUpInfo data={authinfo} modalcontrol={setMState} />
          ) : (
            <div className="text-center space-y-5">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  name: "",
                  date: "",
                  otp: "",
                  repass: "",
                }}
                validate={(values) => {
                  console.log("Signup Value", values);
                  const errors = {};

                  if (
                    (authState === "sign up" ||
                      authState === "login" ||
                      authState === "forgotPass") &&
                    !values.email
                  ) {
                    errors.email = "email is Required";
                  } else if (!regex.test(values.email)) {
                    errors.email = "Invalid email address";
                  } else if (
                    !values.password &&
                    (authState === "sign up" || authState === "login")
                  ) {
                    errors.password = "password is Required";
                  } else if (
                    values.password.length <= 5 &&
                    (authState === "sign up" || authState === "login")
                  ) {
                    errors.password = "password is too short";
                  } else if (
                    authState === "sign up" &&
                    values.repass !== values.password
                  ) {
                    errors.repass = "password does not match";
                  } else if (
                    authState === "sign up" &&
                    !values.name &&
                    !authinfo?.user
                  ) {
                    errors.name = "Name is Required";
                  } else if (
                    authState === "sign up" &&
                    !values.date &&
                    !authinfo?.user
                  ) {
                    errors.date = "date is Required";
                  }
                  return errors;
                }}
                onSubmit={(
                  values,
                  { errors, setFieldError, setSubmitting }
                ) => {
                  let test = {
                    email: values.email,
                    password: values.password,
                    name: values.name,
                    dob: values.date,
                  };
                  console.log("Submit");
                  if (authState === "sign up") {
                    dispatch(
                      userSignup({
                        data: test,
                        successCallback: (res) => {
                          setAuthState("verify");
                        },
                        rejectCallback: (res) => {
                          res.errors.map((val) => {
                            if (val.param == "dob") {
                              setFieldError("date", val.msg);
                            } else if (val.param == "email") {
                              setFieldError("email", val.msg);
                            }
                          });
                        },
                      })
                    );
                  } else if (authState === "login") {
                    dispatch(
                      signin({
                        signupType: SignupType.Registration,
                        email: test.email,
                        password: test.password,
                        callback: (res) => {
                          if (res?.user?.verified == 1) {
                            if (res?.user?.role == 50) {
                              if (redirectTo) {
                                if (redirectTo == "none") {
                                  // callback(1);
                                } else {
                                  //navigate('/vendor');
                                }
                              } else {
                                //navigate('/vendor');
                              }
                            } else {
                              if (setVendorSignUpModal) {
                                setMState();
                                setVendorSignUpModal(true);
                              }
                            }
                          } else {
                            setAuthState("verify");
                          }
                        },
                        errorMessage: (err) => {
                          console.log("Error", err);
                        },
                      })
                    );
                  } else if (authState === "forgotPass") {
                    console.log("forgotPass", authState);
                    dispatch(
                      emailOtpSend({
                        data: {
                          verifyUrl: YesNo.Yes,
                          email: values.email,
                        },
                        callback: () => {
                          setForgetPassMessage(true);
                        },
                      })
                    );
                  } else {
                    dispatch(
                      userVerification({
                        data: {
                          otp: otp,
                          sk: authinfo?.user?.sk,
                          createdAt: authinfo?.user?.createdAt,
                        },
                        id: authinfo?.user?.id,
                        successCallback: () => {
                          dispatch(signin(test));
                          setMState();
                        },
                        rejectCallback: (res) => {
                          setFieldError("otp", res.msg);
                        },
                      })
                    );
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col space-y-2"
                  >
                    {authState === "sign up" ? (
                      <input
                        type="text"
                        name="name"
                        placeholder="name"
                        className="border-[3px] border-solid border-jrnyfy-border rounded-[50px] p-[15px_30px] max-[767px]:p-[5px_10px_10px_10px] max-[767px]:border-[2px]"
                        onChange={handleChange}
                        value={values.name}
                        autoFocus={true}
                        onBlur={handleBlur}
                      />
                    ) : null}

                    {errors.name && touched.name && (
                      <span className="text-red-500">{errors.name}</span>
                    )}

                    {authState === "sign up" ||
                    authState === "login" ||
                    authState == "forgotPass" ? (
                      <input
                        type="email"
                        name="email"
                        placeholder="email"
                        className="border-[3px] border-solid border-jrnyfy-border rounded-[50px] p-[15px_30px] max-[767px]:p-[5px_10px_10px_10px] max-[767px]:border-[2px"
                        onChange={handleChange}
                        value={values.email}
                      />
                    ) : null}

                    {errors.email && touched.email && (
                      <span className="text-red-500">{errors.email}</span>
                    )}

                    {authState === "sign up" || authState === "login" ? (
                      <div className="flex relative">
                        <input
                          type={PVState}
                          name="password"
                          placeholder="password"
                          className="border-[3px] border-solid border-jrnyfy-border rounded-[50px] p-[15px_30px] w-full max-[767px]:p-[5px_10px_10px_10px] max-[767px]:border-[2px"
                          onChange={handleChange}
                          value={values.password}
                        />
                        {PVState === "password" ? (
                          <img
                            src={VisibleOff}
                            alt="visibility"
                            className="absolute w-[30px] top-0 bottom-0 m-auto right-[25px]"
                            onClick={VisibleControl}
                          />
                        ) : (
                          <img
                            src={Visible}
                            alt="visibility"
                            className="absolute w-[30px] top-0 bottom-0 m-auto right-[25px]"
                            onClick={VisibleControl}
                          />
                        )}
                      </div>
                    ) : null}

                    {errors.password && touched.password && (
                      <span className="text-red-500">{errors.password}</span>
                    )}

                    {authState === "sign up" ? (
                      <div className="flex relative">
                        <input
                          type={PVState}
                          name="repass"
                          placeholder="re type your password"
                          className="border-[3px] border-solid border-jrnyfy-border rounded-[50px] p-[15px_30px] w-full max-[767px]:p-[5px_10px_10px_10px] max-[767px]:border-[2px"
                          onChange={handleChange}
                          value={values.repass}
                        />
                        {PVState === "password" ? (
                          <img
                            src={VisibleOff}
                            alt="visibility"
                            className="absolute w-[30px] top-0 bottom-0 m-auto right-[25px]"
                            onClick={VisibleControl}
                          />
                        ) : (
                          <img
                            src={Visible}
                            alt="visibility"
                            className="absolute w-[30px] top-0 bottom-0 m-auto right-[25px]"
                            onClick={VisibleControl}
                          />
                        )}
                      </div>
                    ) : null}

                    {errors.repass && touched.repass && (
                      <span className="text-red-500">{errors.repass}</span>
                    )}

                    {authState === "sign up" ? (
                      <div className="relative" ref={dateRef}>
                        <input
                          onClick={() =>
                            setShowDatePicker((prevState) => !prevState)
                          }
                          className="border-[3px] border-solid border-jrnyfy-border rounded-[50px] p-[15px_30px] w-full max-[767px]:p-[5px_10px_10px_10px] max-[767px]:border-[2pxx"
                          type="text"
                          name="date"
                          value={format(date, "dd-LLL-yyyy")}
                        />

                        {showDatePicker && (
                          <Calendar
                            maxDate={new Date()}
                            className="absolute top-[50px] left-0 z-40 rounded-xl shadow-xl max-[370px]:w-[100%] max-[767px]:top-[unset] max-[767px]:bottom-[50px]"
                            onChange={(item) => {
                              setShowDatePicker(false);
                              setDate(item);
                              console.log("date change");
                              setFieldValue("date", format(item, "yyyy-MM-dd"));
                            }}
                            date={date}
                          />
                        )}
                      </div>
                    ) : null}

                    {errors.date && touched.date && (
                      <span className="text-red-500">{errors.date}</span>
                    )}
                    {authState === "verify" ? (
                      <p>
                        a one time password has been sent to your email address,
                        please check your email and type in the one time
                        password to validate your account
                      </p>
                    ) : null}
                    {authState === "verify" ? (
                      <>
                        <input
                          type="text"
                          name="otp"
                          placeholder="verification code"
                          className="border-[3px] border-solid border-jrnyfy-border rounded-[50px] p-[15px_30px] max-[767px]:p-[5px_10px_10px_10px] max-[767px]:border-[2px"
                          onChange={(e) => setOtp(e.target.value)}
                          value={otp}
                        />
                        {errors.otp && touched.otp && (
                          <span className="text-red-500">{errors.otp}</span>
                        )}
                      </>
                    ) : null}

                    {authState == "login" && (
                      <span className="text-red-500">
                        {loginErrors && loginErrors[0]?.msg}
                      </span>
                    )}

                    {authState == "login" && (
                      <div>
                        <button
                          onClick={() => setAuthState("forgotPass")}
                          type="button"
                        >
                          Forgot Password
                        </button>
                      </div>
                    )}

                    {authState == "forgotPass" && (
                      <div className="p-2 space-y-10">
                        <div className="flex flex-col gap-3 justify-center">
                          {forgetPassMessage && (
                            <span>
                              Please open your email, We have send an email to
                              reset your password
                            </span>
                          )}
                          <div className="flex justify-center">
                            <button
                              type="submit"
                              className="w-fit bg-jrnyfy-themeColor p-[10px_15px_10px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <span className="text-red-500">
                                        {errors.date && touched.date && errors.date}
                                    </span> */}

                    <div className="space-x-4">
                      <div className="flex justify-center gap-5">
                        {authState == "login" ? (
                          <button
                            type="submit"
                            className="p-[10px_20px] bg-jrnyfy-themeColor text-white font-bold rounded-[50px] font-SourceSansPro"
                          >
                            login
                          </button>
                        ) : null}
                        {(authState == "sign up" || authState == "login") && (
                          <button
                            type="submit"
                            className="p-[10px_20px] bg-jrnyfy-themeColor text-white font-bold rounded-[50px] font-SourceSansPro"
                            onClick={signupControl}
                          >
                            sign up
                          </button>
                        )}
                      </div>
                      <div className="flex flex-col gap-5 items-center">
                        {authState == "verify" && (
                          <button onClick={resendOtp}>{`Resend code ${
                            timerCount > 0 ? timerCount : ""
                          }`}</button>
                        )}
                        {authState == "verify" && (
                          <button
                            type="submit"
                            className="p-[10px_20px] bg-jrnyfy-themeColor text-white font-bold rounded-[50px] font-SourceSansPro"
                          >
                            finish
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>

              {/* <button onClick={googleLogin} className="space-x-1 border-[3px] border-solid border-jrnyfy-border rounded-[50px] p-2">
                            <img src={Google} alt='google_logo' className="w-[20px] inline-block mr-[5px]" />
                            continue with google
                        </button> */}

              <div className="space-y-2">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    dispatch(
                      signin({
                        signupType: SignupType.Google,
                        idToken: credentialResponse.credential,
                      })
                    );
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />

                <div className="flex items-center justify-center">
                  <FacebookLogin />
                </div>
              </div>
            </div>
          )}
        </div>
      </Popup>
    </>
  );
};

export default Login;
