import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { tagPaginationOffset } from '../../../res/dimens';
import { baseUrl } from '../../../environments/proxyUrl';
import {
  parse_feedListObjectWithInnerHits_likes_comments_inspirers,
  parse_SourceArray,
  parse_SourceObjectWithInnerHitsTags,
  parse_SourceObjectWithInnerHits_Leads,
  parse_userListObjectWithInnerHits_Feed,
  commentParser,
  parse_Participant,
  parse_UserWithContent,
  parse_users,
  pasrseGetGalleryItem,
  pasrseGetJourneyTimeline,
  parseFeed,
  locationParser,
  getSingleComment,
} from './elasticParser';
import {
  ActionType,
  ParticipantStatus,
  PhotoSize,
  PhotoSource,
  RecordStatus,
  TaskAssigneeStatus,
  TimelineType,
  UserContentFilter,
} from '../../../components/utils/subjects';
import { sortGallery } from '../../../components/utils/gallerySorting';
import {
  changeUrlToSize,
  constructXLPhotoUrl,
  getPhotosUrl,
  getThumbnailFromUrl,
} from '../../../res/commonFunctions';
import { hash } from '../../../components/utils/static/global_functions';

export const createActivity = createAsyncThunk(
  'activity/create',
  async ({ data, token, auth, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/activities/v1/jrny`,
        data,
        { headers: { Authorization: token } },
      );
      console.log('callBack Test LOG', res);
      if (callback) {
        callback(res.results);
      }
      return { ...res.results, templatePhotoCount: auth.templatePhotoCount };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createInspireActivity = createAsyncThunk(
  'activity/createInspireActivity',
  async ({ data, token, activityId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/activities/v1/inspire/${activityId}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      console.log('Inspired Response', res.results);
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const ConvertDraftJourneyToMainJourney = createAsyncThunk(
  'profile/convertDraftJourneyToMainJourney',
  async ({ data, id, token, callback }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${baseUrl}/api/activities/v1/draft/${id}`, data, {
        headers: { Authorization: token },
      });
      if (callback) {
        callback(response.data.results);
      }
      return {
        size: data.size,
        from: data.from,
        draftActivities: response.data.results,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createLinkActivity = createAsyncThunk(
  'activity/createLinkActivity',
  async ({ data, token, parentId, activityId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/activities/v1/link/${activityId}`,
        { ...data, parentId },
        {
          headers: { Authorization: token },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateActivity = createAsyncThunk(
  'activity/update',
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/jrny/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      console.log('updated res', res);
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const addBudget = createAsyncThunk(
  'activity/addBudget',
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/budget/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      console.log('addBudget res', res);
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const addEndDate = createAsyncThunk(
  'activity/addEndDate',
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/edate/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const addLocationIntoActivity = createAsyncThunk(
  'activity/addLocationIntoActivity',
  async (
    { data, token, id, user, callback, failed_callback },
    { rejectWithValue },
  ) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/activities/v1/task/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      console.log("LOcationRawResponse", res)
      if (callback) {
        callback(res.results);
      }
      return { user: user, ...res.results };
    } catch (err) {
      if (failed_callback) {
        failed_callback(rejectWithValue(err.response.data));
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getLocationFromActivity = createAsyncThunk(
  'activity/getLocationFromActivity',
  async (
    { data, token, id, user, callback, failed_callback },
    { rejectWithValue },
  ) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/activities/v1/location/${id}`,

        {
          params: data,
          headers: { Authorization: token },
        },
      );
      const results = locationParser(res.results)
      console.log('LocationsResults', results)
      if (callback) {
        callback(results);
      }
      return { user: user, ...results };
    } catch (err) {
      if (failed_callback) {
        failed_callback(rejectWithValue(err.response.data));
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
//TODO: do not exist on server side
export const removeLocationIntoActivity = createAsyncThunk(
  'activity/removeLocationIntoActivity',
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/timeline/${id}`,
        {
          headers: { Authorization: token },
          params: { ...data },
        },
      );
      console.log(' removeLocationIntoActivity res', {
        ...res.results,
        data,
      });
      if (callback) {
        callback(res);
      }
      return { ...res.results, ...data };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const removeTimelineItem = createAsyncThunk(
  'activity/removeTimelineItem',
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/timeline/${id}`,
        {
          headers: { Authorization: token },
          params: data,
        },
      );
      console.log(' removeTimelineItem res', {
        ...res.results,
        data,
      });
      if (callback) {
        callback(res);
      }
      return { ...res.results, ...data };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const addTagIntoActivity = createAsyncThunk(
  'activity/addTagIntoActivity',
  async ({ data, token, id, tagName, tagId, callback }, { rejectWithValue }) => {
    console.log('data tag', data);
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/tag/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteTagFromActivity = createAsyncThunk(
  'activity/deleteTagFromActivity',
  async ({ data, token, id, tagName, tagId, callback }, { rejectWithValue }) => {
    console.log('data tag', data);
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/tag/${id}`,
        {
          params: { ...data, tagName, tagId },
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const addInspirer = createAsyncThunk(
  'activity/addInspirer',
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/inspirer/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      console.log('AddInspirer', res.results);
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteInspirer = createAsyncThunk(
  'activity/deleteInspirer',
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/inspirer/${id}`,
        {
          params: { ...data },
          headers: { Authorization: token },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const addGuide = createAsyncThunk(
  'activity/addGuide',
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/guide/${id}`,
        { guideId: data.id },
        {
          headers: { Authorization: token },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteGuide = createAsyncThunk(
  'activity/deleteGuide',
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/guide/${id}`,
        {
          params: data,
          headers: { Authorization: token },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const addInterest = createAsyncThunk(
  'activity/addInterest',
  async ({ data, token, id, interestName }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/interest/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteInterest = createAsyncThunk(
  'activity/deleteInterest',
  async ({ data, token, id, interestName }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/interest/${id}`,
        {
          params: {},
          headers: { Authorization: token },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateActivityName = createAsyncThunk(
  'activity/updateName',
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    console.log('data tag', data);
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/name/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback();
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const addParticipantIntoActivity = createAsyncThunk(
  'activity/addParticipant',
  async ({ data, token, id, participantId, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/activities/v1/participant/${id}`,
        { ...data, participantId },
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res.results)
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const acceptOrRejectParticipant = createAsyncThunk(
  'activity/acceptOrRejectParticipant',
  async ({ data, token, id }, { rejectWithValue }) => {
    console.log('acceptOrRejectParticipant');
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/participant/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const deleteParticipantFromActivity = createAsyncThunk(
  'activity/deleteParticipantFromActivity',
  async ({ data, token, id, callback, participantId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/participant/${id}`,
        {
          headers: { Authorization: token },
          params: data,
        },
      );
      if (callback) {
        callback();
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);


export const addArbitraryPeople = createAsyncThunk(
  'activity/addArbitraryPeople',
  async ({ token, id, data, callback }, { rejectWithValue }) => {
    console.log('data tag', data);
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/activities/v1/cuser/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback()
      }

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// export const addArbitraryPeople = createAsyncThunk(
//   'activity/addArbitraryPeople',
//   async ({ token, id, data }, { rejectWithValue }) => {
//     try {
//       const { data: res } = await axios.post(
//         `${baseUrl}/api/activities/v1/cuser/${id}`,
//          data,
//         {
//           headers: { Authorization: token },
//         },
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const deleteArbitraryPeople = createAsyncThunk(
  'activity/deleteArbitraryPeople',
  async ({ token, id, userId, data, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/cuser/${id}`,
        {
          params: data,
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback()
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const addTaskAssignee = createAsyncThunk(
  'activity/addTaskAssignee',
  async ({ data, token, activiytId, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/task/${activiytId}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// export const acceptOrRejectTask = createAsyncThunk(
//   'activity/acceptOrRejectTask',
//   async ({data, token, activiytId, taskId}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/activities/v1/assignee/${activiytId}`,
//         {...data, taskId},
//         {
//           headers: {Authorization: token},
//         },
//       );

//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const acceptOrRejectParticipantRequestByParticipant = createAsyncThunk(
  'profile/acceptOrRejectParticipantRequestByParticipant',
  async ({ data, token, activityId, userId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/preq/${activityId}`,
        { ...data, participantId: userId },
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const acceptOrRejectParticipantRequestByOwner = createAsyncThunk(
  'profile/acceptOrRejectParticipantRequestByOwner',
  async ({ data, token, activityId, userId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/preq/${activityId}`,
        { ...data, participantId: userId },
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteTaskAsssignee = createAsyncThunk(
  'activity/deleteTaskAsssignee',
  async ({ data, token, timeLineId, activiytId, taskId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/task/${activiytId}`,
        {
          headers: { Authorization: token },
          params: { ...data, timelineId: timeLineId, taskId },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createTaskForActivity = createAsyncThunk(
  'activity/CreateTask',
  async ({ data, token, id, user, callback }, { rejectWithValue }) => {
    console.log('data tag', data);
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/activities/v1/task/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res);
      }
      return { user: user, ...res.results };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

//need to add timeLineId
export const updateActivityTask = createAsyncThunk(
  'activity/updateActivityTask',
  async ({ data, token, callback, activityId }, { rejectWithValue }) => {
    console.log('data tag', data);
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/task/${activityId}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      console.log('updateActivityTask', res);
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteTaskFromActivity = createAsyncThunk(
  'activity/deleteTask',
  async (
    { token, id, suggestedTaskId, timeLineId, callback, taskId, data },
    { rejectWithValue },
  ) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/task/${id}`,
        {
          headers: { Authorization: token },
          params: { ...data, suggestedTaskId, timelineId: timeLineId, taskId },
        },
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const addSuggestedTask = createAsyncThunk(
  'activity/addSuggestedTask',
  async ({ token, id, taskId, user, data, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/activities/v1/stask/${id}`,
        { ...data, taskId },
        { headers: { Authorization: token } },
      );
      const result = { ...res.results, user };
      if (callback) {
        callback(result);
      }
      return result;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// export const uploadActivityPhotos = createAsyncThunk(
//   'activity/uploadActivityPhoto',
//   async ({formData, token}, {rejectWithValue}) => {
//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/worker/v1/activity-image-uploader`,
//         formData,
//         {
//           headers: {
//             Authorization: token,
//             'Content-Type': `multipart/form-data;`,
//           },
//         },
//       );

//       return response.data.results;
//     } catch (err) {
//       if (!err) {
//         throw err;
//       }
//       return rejectWithValue(err);
//     }
//   },
// );
//user can upload of max 7 pictures.
//user have to delete pictures to upload more
export const uploadGalleryPhotos = createAsyncThunk(
  'activity/uploadGalleryPhotos',
  async ({ formData, token, callback, onUploadProgress, uploadProgress }, { rejectWithValue }) => {
    console.log('TestUploadApi', formData);
    try {
      const response = await axios.post(
        `${baseUrl}/api/worker/v1/upload`,
        formData,
        {
          headers: {
            Authorization: token,
            'Content-Type': `multipart/form-data; `,
          },
          onUploadProgress,
          maxContentLength: Infinity,
          maxBodyLength: Infinity
        },

      );

      console.log('uploadGalleryPhotos55555', response);
      if (callback) {
        callback(response);
      }
      return response.data.results;
    } catch (err) {
      if (!err) {
        console.log('UPloadGllaeryErrorResponse', err);
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const updateGallery = createAsyncThunk(
  'activity/updateGallery',
  async (
    { token, id, data, callback, timeLineData, user },
    { rejectWithValue },
  ) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/gallery/${id}`,
        data,
        { headers: { Authorization: token } },
      );
      if (callback) {
        callback(res);
      }
      return { ...res.results, timeLineData, user };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getGalleryItems = createAsyncThunk(
  'activity/getGalleryItems',
  async (
    { token, id, data, callback, timeLineData, user },
    { rejectWithValue },
  ) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/activities/v1/gallery/`,
        { params: data, headers: { Authorization: token } },
      );
      console.log("GalleryItmes", res.results)
      const result = await pasrseGetGalleryItem(res.results.gallery)
      if (callback) {
        callback(result,res.results.comments);
      }
      return result;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getGallerySingleItem = createAsyncThunk(
  'activity/getGallerySingleItem',
  async (
    { token, id, data, callback, timeLineData, user },
    { rejectWithValue },
  ) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/activities/v1/gallery/${data.id}`,
        { params: data, headers: { Authorization: token } },
      );
      console.log("GalleryItmes", res.results)
      const result = await pasrseGetGalleryItem(res.results)
      if (callback) {
        callback(result);
      }
      return result;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteGalleryItem = createAsyncThunk(
  'activity/deleteGalleryItem',
  async (
    {
      token,
      id,
      photoSource,
      callback,
      timeLineData,
      sk,
      createdAt,
      gallerySk,
      galleryId
    },
    { rejectWithValue },
  ) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/gallery/${id}`,
        {
          headers: { Authorization: token },
          params: {
            sk, createdAt, photoSource, gallerySk,
            galleryId
          },
        },
      );
      if (callback) {
        callback(res.results);
      }
      return { ...res.results, timeLineData };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getActivitiesFilters = createAsyncThunk(
  'activity/filters',
  async ({ token }, { rejectWithValue }) => {
    console.log('get activities filter2');

    return {
      filters: [
        {
          name: 'all',
          label: 'all',
        },
        {
          name: 'popular',
          label: 'popular',
        },
      ],
    };
  },
);

export const getActivityById = createAsyncThunk(
  'activity/getActivityById',
  async (
    {
      auth,
      token,
      id,
      templatePhotoCount,
      callback,
      isPublic,
      cmtIds,
      withCmt,
      sk,
      createdAt,
      recordStatus,
      timelineId
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/activities/v1/jrny/${id}`,
        {
          headers: { Authorization: token },
          params: { isPublic, sk, createdAt,cmtIds,withCmt, recordStatus, timelineId },
        },
      );
      // const results = parse_SourceArray(data.results);
      console.log('getActivityById', data);
      const results = await pasrseGetJourneyTimeline(data.results)
      console.log('getActivityByIddddd', results);


      if (callback) {
        callback(results);
      }
      return {
        ...results,
        templatePhotoCount: auth.templatePhotoCount,
        ideas: [
          await parse_SourceObjectWithInnerHits_Leads({
            results: data?.results?.ideas,
          }),
        ],
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getPlaceDetails = createAsyncThunk(
  'activity/getPlaceDetails',
  async ({ place_id, callback }, { rejectWithValue }) => {
    try {
      const detailsResponse = await axios.get(
        'https://maps.googleapis.com/maps/api/place/details/json',
        {
          params: {
            place_id,
            key: 'AIzaSyCaSWkFT0UboGYyuf6p7CFt2etRCyMQBEM',
          },
        },
      );

      if (callback) {
        callback(detailsResponse);
      }
      return detailsResponse;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getFeedDescription = createAsyncThunk(
  'activity/getFeedDescription',
  async (
    { token, id, callback, descOnly, isPublic, sk, createdAt, recordStatus },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/activities/v1/jrny/${id}`,
        {
          headers: { Authorization: token },
          params: { isPublic, sk, createdAt, recordStatus, descOnly },
        },
      );
      // const results = parse_SourceArray(data.results);
      console.log('getActivityById', data);
      if (callback) {
        callback(data.results);
      }
      return data.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

//TODO: not exist on server side
export const getMatchedTags = createAsyncThunk(
  'activities/getMatchedTags',
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(`${baseUrl}/api/search/v1/tags`, {
        params: { ...data },
        headers: {
          Authorization: token,
        },
      });

      return parse_SourceArray(res.results);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
//TODO: not exist in server side
export const getMoreMatchedTags = createAsyncThunk(
  'activities/getNextMatchedTags',
  async ({ token, data }) => {
    const { data: res } = await axios.get(`${baseUrl}/api/search/v1/tags`, {
      params: { ...data },
      headers: {
        Authorization: token,
      },
    });

    return parse_SourceArray(res.results);
  },
);

// export const updateTag = createAsyncThunk(
//   'tags/updateTag',
//   async ({token, data, tagName}) => {
//     const {data: res} = await axios.put(
//       `${baseUrl}/api/tags/v1/${tagName}`,
//       data,
//       {
//         headers: {
//           Authorization: token,
//         },
//       },
//     );

//     return res.results;
//   },
// );

// export const uploadTagPhoto = createAsyncThunk(
//   'tag/uploadTagPhoto',
//   async ({formData, token}, {rejectWithValue}) => {
//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/worker/v1/tag-image-uploader`,
//         formData,
//         {
//           headers: {
//             Authorization: token,
//             'Content-Type': `multipart/form-data;`,
//           },
//         },
//       );

//       return response;
//     } catch (err) {
//       if (!err) {
//         throw err;
//       }
//       return rejectWithValue(err);
//     }
//   },
// );
//TODO: not found in server side
export const addReviewRatingOfProduct = createAsyncThunk(
  'activity/addReviewRatingOfProduct',
  async ({ token, data, activityId, productId, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/p-review/${productId}`,
        { ...data, activityId },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
//TODO: not found in server side

export const getProductReviewRating = createAsyncThunk(
  'product/getProductReviewRating',
  async ({ token, productId, callback, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/products/v1/p-review/${productId}`,
        {
          headers: {
            Authorization: token,
            params: { ...data },
          },
        },
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const changeActivityPrivacyMode = createAsyncThunk(
  'activity/changeActivityPrivacyMode',
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/jrny/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const removeActivity = createAsyncThunk(
  'activity/removeActivity',
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/jrny/${id}`,
        {
          headers: { Authorization: token },
          params: data,
        },
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const cancelRequest = createAsyncThunk(
  'activity/cancelRequest',
  async ({ data, token, paymentId, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/payments/v1/req-cancel${paymentId}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const disputeRequest = createAsyncThunk(
  'activity/disputeRequest',
  async ({ data, token, paymentId, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/payments/v1/dispute/${paymentId}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// export const reversalTransfer = createAsyncThunk(
//   'activity/reversalTransfer',
//   async ({data, token, paymentId, callback}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/payments/v1/s-transfer-reversal/${paymentId}`,
//         data,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       if (callback) {
//         callback(res);
//       }
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const shareTicket = createAsyncThunk(
  'activity/shareTicket',
  async (
    { token, data, activityId, userId, callback, timelineId },
    { rejectWithValue },
  ) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/products/v1/ticket`,
        data,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      if (callback) {
        callback(res);
      }
      return { ...res.results, timelineId: timelineId };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const cancelSharedTicket = createAsyncThunk(
  'activity/cancelSharedTicket',
  async ({ token, data, callback, timelineId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/ticket`,
        {
          headers: {
            Authorization: token,
          },
          params: data,
        },
      );
      if (callback) {
        callback(res);
      }
      return { ...res.results, timelineId: timelineId };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTimeLineForJourney = createAsyncThunk(
  'payment/getTimeLineForJourney',
  async ({ token, data, callback, activityId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/activities/v1/timeline/${activityId}`,
        {
          params: { ...data },
          headers: {
            Authorization: token,
          },
        },
      );
      console.log('GetTimelineFor Journey', res);
      if (callback) {
        callback(res.results);
      }
      return {
        skip: data.skip,
        limit: data.limit,
        timeLineData: res.results.timelines,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getMoreTimeline = createAsyncThunk(
  'timeline/getMoreTimeline',
  async ({ data, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(`${baseUrl}/api/search/v1/feeds/`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      console.log('raw data getFeeds', res);
      const result = await parseFeed(res)
      console.log("ParseData", result)
      if (callback) {
        callback(result);
      }

      return {
        feeds: result,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
//comments api's
export const createComment = createAsyncThunk(
  'activity/createComment',
  async ({ data, token, callback, activityId, user }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/activities/v1/comment/${activityId}`,
        data,
        { headers: { Authorization: token } },
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const deleteComment = createAsyncThunk(
  'activity/deleteComment',
  async ({ data, token, callback, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/comment/${id}`,
        data,
        { headers: { Authorization: token } },
      );
      if (callback) {
        callback(res);
      }
      return {
        ...res.results,
        commentID: id,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const updateComment = createAsyncThunk(
  'activity/updateComment',
  async ({ data, token, callback, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/comment/${id}`,
        data,
        { headers: { Authorization: token } },
      );
      if (callback) {
        callback(res);
      }
      return {
        ...res.results,
        commentID: id,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getJourneyContent = createAsyncThunk(
  'activity/getJourneyContent',
  async ({ token, data, callback, activityId }, { rejectWithValue }) => {
    try {
      const { data: res, headers } = await axios.get(
        `${baseUrl}/api/search/v1/uc/`,
        {
          params: data,
          headers: {
            Authorization: token,
          },
        },
      );
      console.log('raw data getComments ', res);
      console.log('comments headers', headers);
      if (callback) {
        callback(res.results);
      }
      return {};
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getCommentsByID = createAsyncThunk(
  'activity/getCommentsByID',
  async ({ token, data, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/activities/v1/comment`,
        {
          params: data,
          headers: {
            Authorization: token,
          },
        },
      );
      console.log('getCommentsByID raw', res)
      const results = await getSingleComment(res.results.hits)
      if (callback) {
        callback(results);
      }
      return results
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getMoreComments = createAsyncThunk(
  'activity/getMoreComments',
  async ({ token, data, callback, activityId }, { rejectWithValue }) => {
    try {
      const { data: res, headers } = await axios.get(
        `${baseUrl}/api/activities/v1/comment/${activityId}`,
        {
          params: { ...data, filter: UserContentFilter.GetCommentsOfAJourney },
          headers: {
            Authorization: token,
          },
        },
      );
      if (callback) {
        callback(res.results);
      }
      console.log('getMoreComments redux', res);
      console.log('getMoreComments headers', headers);
      const count = headers['content-range']?.split('/')[1];
      return {
        skip: data.from,
        limit: data.size,
        comments:
          await parse_feedListObjectWithInnerHits_likes_comments_inspirers(
            res.results,
          ),
        count: count,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getAcceptedParticipantsOfJourney = createAsyncThunk(
  'activity/getAcceptedParticipantsOfJourney',
  async ({ token, data, callback, activityId }, { rejectWithValue }) => {
    try {
      const { data: res, headers } = await axios.get(
        `${baseUrl}/api/search/v1/uc/`,
        {
          params: data
          ,
          headers: {
            Authorization: token,
          },
        },
      );
      console.log('raw accepted participant ', res);
      const count = headers['content-range']?.split('/')[1];
      if (callback) {
        callback(res.results);
      }
      return {
        skip: data.from,
        limit: data.size,
        users: await parse_Participant(res.results),
        count: count,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getAllParticipantsOfJourney = createAsyncThunk(
  'activity/getAllParticipantsOfJourney',
  async ({ token, data, callback, activityId }, { rejectWithValue }) => {
    try {
      const { data: res, headers } = await axios.get(
        `${baseUrl}/api/search/v1/content/`,
        {
          params: {
            ...data,
            filter: UserContentFilter.GetParticipantsOfAJourney,
          },
          headers: {
            Authorization: token,
          },
        },
      );
      console.log('raw data getParticipantsOfJourney ', res);
      const count = headers['content-range']?.split('/')[1];
      if (callback) {
        callback(res.results);
      }
      return {
        skip: data.from,
        limit: data.size,
        users: await parse_Participant(res.results),
        count: count,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getContentUsers = createAsyncThunk(
  'activity/getContentUsers',
  async ({ token, data, callback }, { rejectWithValue }) => {
    try {
      const { data: res, headers } = await axios.get(
        `${baseUrl}/api/search/v1/uc`,
        {
          params: data,
          headers: {
            Authorization: token,
          },
        },
      );
      console.log('getParticipantsOfJourney ', res);
      const count = headers['content-range']?.split('/')[1];
      let result = [];
      if (data.filter == UserContentFilter.GetUsers) {
        result = await parse_users(res.results);
      } else {
        result = await parse_UserWithContent(res.results);
      }
      console.log('GetContentUserResult', result);
      if (callback) {
        callback(result);
      }
      return {
        skip: data.from,
        limit: data.size,
        users: result,
        count: count,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getIdeasForJourney = createAsyncThunk(
  'activity/getIdeasForJourney',
  async ({ data, token, activityId, callback }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/ideas`, {
        params: { ...data, activityId },
        headers: { Authorization: token },
      });
      if (callback) {
        callback(parse_SourceObjectWithInnerHits_Leads(response.data));
      }
      console.log('raw data getIdeasForJourney', response);
      return {
        size: data.size,
        from: data.from,
        products: await parse_SourceObjectWithInnerHits_Leads(response.data),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMoreIdeasForJourney = createAsyncThunk(
  'activity/getMoreIdeasForJourney',
  async ({ data, size, activityId, token, callback }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/ideas`, {
        params: { ...data, activityId },
        headers: { Authorization: token },
      });

      return {
        size: data.size,
        from: data.from,
        products: await parse_SourceArray(response.data.results),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
const activitySlice = createSlice({
  name: 'activity',
  initialState: {
    tags: [],
    selectedTags: [],
    newlyCreateTag: {},
    activities: [],
    linkedActivities: [],
    filters: [],
    activityItem: {},
    errors: [],
    createdActivity: [],
    activity: {},
    loading: false,
    uploadingPhoto: false,
    selectedTask: {},
    parentChildStack: [],
    participant: [],
    contentRangeMoreAllActivities: [],
    allActivities: [],
    // gallery: [],
    journeyTimeLine: [],
    comments: [],
    contentRangeComments: undefined,
    contentRangeJourneyParticipants: undefined,
    journey_loading: false,
    journeyAcceptedParticipants: [],
    journeyAllParticipants: [],
    ideas: [],
    contentRangeIdea: undefined,
    sortedGalleryObjects: [],
    inpiredJouneyLocations: [],
    createJourneyLocations: [],
    bgPhotoUploadProgress: 0,
    photoUploadQueue: [],
    isUploadBgPhoto: false,
    bgUploadTimeline: [],
    timelineUpdate: {},
    toastMessages: {},
    currentGalleryIndex: 0,
  },
  reducers: {
    setCurrentGalleryState: (state, action) => {
      state.currentGalleryIndex = action.payload;
    },
    addToastMessage: (state, action) => {
      state.toastMessages[action.payload.id] = true;
    },
    removeToastMessage: (state, action) => {
      delete state.toastMessages[action.payload.id];
    },
    userProfileActivityUpdate: (state, action) => {
      console.log('ActionPlayload', action.payload)
      state.activityItem = action.payload;
    },
    uploadGalleryTrigger: (state, action) => {
      state.isUploadBgPhoto = action.payload;
      console.log('UploadBacgroudPhotostate', state.isUploadBgPhoto);
    },
    uploadBgPhotoProgress: (state, action) => {
      state.bgPhotoUploadProgress = action.payload;
      console.log('UploadBacgroudPhotostate', state.bgPhotoUploadProgress);
    },

    backgroundPhotoUploadQueue: (state, action) => {
      state.photoUploadQueue?.unshift(action.payload);
      if (action.payload.actionType == ActionType.Create) {
        state.bgUploadTimeline?.unshift(action.payload);
        console.log('ActionPlayload', action.payload);
      }
      // state.photoUploadQueue = [];
      // state.bgUploadTimeline = [];
      // state.isUploadBgPhoto = false;
    },
    updateBackgroundPhotoUploadQueue: (state, action) => {
      console.log('updateTimelinedd', action.payload);
      console.log('bgUploadTimelinedd', state.bgUploadTimeline);
      const indexof = state.bgUploadTimeline.findIndex(
        item => item.id == action.payload.id,
      );
      console.log('updateTimelineIndex', indexof);
      if (indexof > -1) {
        state.bgUploadTimeline[indexof] = {
          ...action.payload?.editItem,
          ...action.payload?.timeline,
          status: action.payload.status,
          isBgUpload: true,
          activityId: action.payload.activityId,
        };

      } else {
        state.timelineUpdate = {
          ...action.payload?.editItem,
          ...action.payload?.timeline,
          status: action.payload.status,
          id: action.payload.id,
        };
      }
    },
    removeUploadedBackgroundTimeline: (state, action) => {
      console.log('removeBgPhotoRedux', state.bgUploadTimeline);
      state.bgUploadTimeline = state.bgUploadTimeline?.filter(
        item =>
          item?.status != 2 &&
          item?.status != 3 &&
          action.payload.activityId == item.activityId,
      );
    },
    removeSignleUploadedBackgroundTimeline: (state, action) => {
      console.log('removeSignle', action.payload);
      state.bgUploadTimeline = state.bgUploadTimeline.filter(
        item => item.id != action.payload,
      );
    },
    removeUploadedBackgroundQueue: (state, action) => {
      console.log('removeBgPhotoRedux', state.photoUploadQueue);
      state.photoUploadQueue.pop();
    },
    activtyLocations: (state, action) => {
      console.log("Add activtyLocation", action.payload)
      state.createJourneyLocations.push(action.payload.item);
    },
    resetActivityLocations: (state, action) => {
      state.createJourneyLocations = [];
    },
    updateTagList: (state, action) => {
      console.log('updateTagList', action.payload);
      if (action.payload.loading) {
        state.activity.tags.unshift(action.payload);
      } else {
        state.activity.tags[0] = action.payload.tags;
      }
    },
    setSelectedTask: (state, action) => {
      const { selectedTask } = action.payload;
      state.selectedTask = selectedTask;
    },
    setActivityThumbnail: (state, action) => {
      const { thumbnail, id } = action.payload;
      let array = state.activities.map(activity => {
        if (activity.id === id) {
          activity.thumbnail = thumbnail;
          activity?.gallery?.push(action.payload);
        }
        return activity;
      });

      state.activities = array;
    },
    setCurrentActivityGalleryAndThumbnail: (state, action) => {
      const { gallery, thumbnail, id } = action.payload;
      console.log('GalleryThumbnail', gallery, thumbnail);
      if (state.activity.id == id) {
        console.log('If condition ', JSON.stringify(state.activity.gallery));
        state.activity.gallery = gallery;
        // state.gallery = gallery;
        state.activity.thumbnail = thumbnail;
        // if (!state.activity.thumbnail) {
        //   state.activity.thumbnail = thumbnail;
        // }
        console.log('If condition after', state.activity.gallery);
        console.log('If condition after Gallery', state.gallery);
      }
    },
    clearTags: (state, action) => {
      state.tags = [];
      state.newlyCreateTag = {};
      state.uploadingPhoto = false;
    },
    clearErrors: (state, action) => {
      // state.errors = [];
    },
    pushToParentChildStack: (state, action) => {
      const { id } = action.payload;

      let found = state.parentChildStack.includes(id);
      if (!found) {
        state.parentChildStack.push(id);
      }
    },
    pullFromParentChildStack: (state, action) => {
      const { count } = action.payload;
      for (let i = 0; i < count; i++) {
        state.parentChildStack.pop();
      }
    },
    participantAccepted: (state, action) => {
      const { participantId, activityId } = action.payload;
      if (
        state.activity &&
        state.activity.id == activityId &&
        state.activity.participants
      ) {
        let array = state.activity.participants.map((item, index) => {
          if (participantId == item.id) {
            return { ...item, status: ParticipantStatus.Accepted };
          } else {
            return item;
          }
        });
        state.activity.participants = array;
      }
    },
    taskAssigneeAccepted: (state, action) => {
      const { assigneeId, taskId, activityId } = action.payload;
      if (
        state.activity &&
        state.activity.id == activityId &&
        state.activity.tasks
      ) {
        let array = state.activity.tasks.map((item, index) => {
          if (taskId == item.id) {
            if (item.assignee) {
              const assignee = {
                ...item.assignee,
                status: TaskAssigneeStatus.Accepted,
              };
              return { ...item, assignee };
            } else {
              return item;
            }
          } else {
            return item;
          }
        });
        state.activity.tasks = array;
      }
    },
    addWishListTask: (state, action) => {
      const { tasks, timelines } = action.payload;
      console.log('Timelines', timelines);
      console.log('action.payload. addWishListTask', action.payload);
      console.log('Before Push' + state.activity.timelines);
      state.journeyTimeLine?.unshift(timelines[0]);
      console.log('After Push' + state.activity.timelines);
      state?.activity?.wishList?.push({
        productId: action.payload.product.id,
        timelineId: action.payload.timelineId,
      });
      if (state?.activity?.tasks) {
        state.activity.tasks = state.activity.tasks.concat(tasks);
      }
    },
    removeWishListTask: (state, action) => {
      const { productId, id } = action.payload;
      console.log('action.payload. removeWishListTask', action.payload);
      //removed wish list item from journey timeline
      state.journeyTimeLine = state.journeyTimeLine.filter(
        item => id != item.id,
      );
      state.activity.wishList = state.activity?.wishList?.filter(
        item => id != item?.timelineId,
      );
    },
    addNewCaption: (state, action) => {
      const { id, caption } = action.payload;
      let array = state.activities.map(activity => {
        if (activity?.id === id) {
          activity.thumbnail = action.payload.thumbnail;
        }
        return activity;
      });

      state.activities = array;
    },
    pushIntoSortedGallery: (state, action) => {
      const { galleryItems } = action.payload;
      console.log('pushIntoSortedGallery', galleryItems);

      if (state.sortedGalleryObjects.length == undefined) {
        state.sortedGalleryObjects = galleryItems;
      } else {
        let sG = state.sortedGalleryObjects;
        for (let i = 0; i < galleryItems.length; i++) {
          sG.unshift(galleryItems[i]);
        }
        state.sortedGalleryObjects = sG;
      }
    },
    replaceActivityGallery: (state, action) => {
      const { galleryItems } = action.payload;
      state.sortedGalleryObjects = galleryItems;
    },
    replaceSorteGalleryItems: (state, action) => {
      const { selectedImage, photoReference } = action.payload;
      console.log('Replace', selectedImage, photoReference);
      state.sortedGalleryObjects.forEach((item, index) => {
        if (item.photoReference == photoReference) {
          console.log('yes matched');
          state.sortedGalleryObjects[index] = selectedImage;
        }
      });
    },
    moveUpdownSortedGalleryItems: (state, action) => {
      state.sortedGalleryObjects = action.payload.galleryItems;
    },
    deletePictureFromGallery: (state, action) => {
      state.sortedGalleryObjects = state.sortedGalleryObjects.filter(
        item => item.photoReference != action.payload.photoReference,
      );
    },
  },
  extraReducers: {
    [createActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.activity = {};
      state.currentGalleryIndex = 0;
    },
    [createActivity.fulfilled]: (state, action) => {
      state.errors = [];
      console.log('ActionPayLoadId', action.payload.id);
      const journeyNameToNumber =
        hash(action?.payload?.name) % action.payload.templatePhotoCount;
      console.log('journeyNameToNumberTimeline', action.payload, journeyNameToNumber, action.payload.templatePhotoCount);
      state.sortedGalleryObjects =
        action.payload?.gallery?.length > 0
          ? action.payload.gallery
          : [
            {
              photoReference: `https://storage.googleapis.com/jrnyfy/mobile/template-${journeyNameToNumber + 1}-lr.jpeg`,
              photoSource: PhotoSource.LocalStorage,
            },
          ];
      state.toastMessages[action.payload.id] = { status: true, message: (RecordStatus.Draft == action.payload.recordStatus ? 'This journey is in draft mode. This means this journey is only visible to you. Hit Start Journey to share this journey with others' : 'Journeys are more effective when they are well-defined and actionable.Try adding the purpose or why you are embarking on this journey.') };
      state.activity = action.payload;
      state.createdActivity = action.payload;
      // state.sortedGalleryObjects = sortGallery(action.payload.gallery);
      state.journeyTimeLine = action.payload.timelines;
      state.loading = false;
    },
    [createActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [changeActivityPrivacyMode.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [changeActivityPrivacyMode.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      state.activity.privacy = action.payload.privacy;
    },
    [changeActivityPrivacyMode.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [createInspireActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.activity = {};
    },
    [createInspireActivity.fulfilled]: (state, action) => {
      state.errors = [];
      if (state.activities && state.activities.length > 0) {
        let array = state.activities.map(item => item);
        array.unshift({
          id: action.payload.id,
          name: action.payload.name,
          thumbnail: action.payload.thumbnail,
        });
        state.activities = array;
        state.activity = action.payload;
      } else {
        state.activities = [
          {
            id: action.payload.id,
            name: action.payload.name,
            thumbnail: action.payload.thumbnail,
          },
        ];
        state.activity = action.payload;
      }

      state.loading = false;
    },
    [createInspireActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },

    [ConvertDraftJourneyToMainJourney.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [ConvertDraftJourneyToMainJourney.fulfilled]: (state, action) => {
      state.errors = [];
      state.toastMessages[action.payload.draftActivities.id] = { status: true, message: 'Journeys are more effective when they are well-defined and actionable.Try adding the purpose or why you are embarking on this journey.' };
      state.activity.recordStatus = RecordStatus.Active
      state.loading = false;
    },
    [ConvertDraftJourneyToMainJourney.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [createLinkActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.activity = {};
    },
    [createLinkActivity.fulfilled]: (state, action) => {
      state.errors = [];
      if (state.activities && state.activities.length > 0) {
        let array = state.activities.map(item => item);
        array.unshift({
          id: action.payload.id,
          name: action.payload.name,
          thumbnail: action.payload.thumbnail,
        });
        state.activities = array;
        state.activity = action.payload;
      } else {
        state.activities = [
          {
            id: action.payload.id,
            name: action.payload.name,
            thumbnail: action.payload.thumbnail,
          },
        ];
        state.activity = action.payload;
      }

      state.loading = false;
    },
    [createLinkActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    // [uploadActivityPhotos.pending]: state => {
    //   state.errors = [];
    //   state.uploadingPhoto = true;
    // },
    // [uploadActivityPhotos.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   // state.uploadingPhoto = false;
    //   console.log('ppppp', action.payload.photos);

    //   let sPhotos = state.activity.photos
    //     ? state.activity.photos.map(item => item)
    //     : [];
    //   for (let i = 0; i < action.payload.photos.length; i++) {
    //     sPhotos.push(action.payload.photos[i]);
    //   }
    //   console.log('asPhotos', sPhotos);

    //   state.activity.photos = sPhotos;
    //   console.log('asPhotos', {...state.activity});
    // },
    // [uploadActivityPhotos.rejected]: (state, action) => {
    //   state.uploadingPhoto = false;
    //   state.errors = action.payload;
    // },
    [updateActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [updateActivity.fulfilled]: (state, action) => {
      state.errors = [];
      let array = state.activities.map((item, index) => {
        if (action.payload.id === item.id) {
          return { ...item, ...action.payload };
        } else {
          return item;
        }
      });
      state.activities = array;
      state.activity = { ...state.activity, ...action.payload };
      state.loading = false;
    },
    [updateActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [addBudget.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addBudget.fulfilled]: (state, action) => {
      state.errors = [];
      if (state.activity.id == action.payload.id) {
        state.activity = { ...state.activity, ...action.payload };
      }
      state.loading = false;
    },
    [addBudget.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [addEndDate.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addEndDate.fulfilled]: (state, action) => {
      state.errors = [];
      if (state.activity.id == action.payload.id) {
        state.activity = { ...state.activity, ...action.payload };
      }
      state.loading = false;
    },
    [addEndDate.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [updateActivityName.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [updateActivityName.fulfilled]: (state, action) => {
      state.errors = [];
      let array = state.activities.map((item, index) => {
        if (action.payload.id === item.id) {
          return { ...item, name: action.payload.name };
        } else {
          return item;
        }
      });
      state.activities = array;
      let prod = state.activity;
      // let filteredTags = [];
      // if (state.activity.tags) {
      //   filteredTags = state.activity.tags.filter(
      //     (a) => !action.payload.deletedTags.map((b) => b.id).includes(a.id)
      //   );
      // }
      // prod.tags = [...filteredTags, ...action.payload.tags];
      prod.tags = action.payload.tags;
      prod.name = action.payload.name;
      prod.completedActivitiesByTags = action.payload.completedActivitiesByTags;
      prod.averageCompletionTime = action.payload.averageCompletionTime;
      prod.realisticScore = action.payload.realisticScore;
      prod.averageActualCost = action.payload.averageActualCost;
      prod.averageBudget = action.payload.averageBudget;
      state.activity = prod;
      state.loading = false;
    },
    [updateActivityName.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [addTagIntoActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addTagIntoActivity.fulfilled]: (state, action) => {
      console.log('add tag', action.payload);
      const photo = action?.payload?.photo;
      if (photo) {
        state.sortedGalleryObjects.unshift(photo);
      }
      let selectedTags = state.activity.tags
        ? state.activity.tags.map(item => item)
        : [];
      selectedTags.push(action.payload.tag);
      state.activity.tags = selectedTags;

      state.activity.completedActivitiesByTags =
        action.payload.completedActivitiesByTags;
      state.activity.averageCompletionTime =
        action.payload.averageCompletionTime;
      state.activity.averageActualCost = action.payload.averageActualCost;
      state.activity.realisticScore = action.payload.realisticScore;
      state.activity.averageBudget = action.payload.averageBudget;
      // state.tags = [];
      state.tags = state.tags.filter(t => t.name !== action.payload.tag.name);
      state.loading = false;
    },
    [addTagIntoActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteTagFromActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteTagFromActivity.fulfilled]: (state, action) => {
      state.errors = [];
      let dTPhotoSource = action?.payload?.deletedThumbnail?.photoSource;
      let dTPhotoRef = action?.payload?.deletedThumbnail?.photoReference;

      if (dTPhotoSource) {
        if (dTPhotoSource == PhotoSource.UserUploadedPublicRecord) {
          dTPhotoRef = constructXLPhotoUrl(dTPhotoRef);
        }
        state.sortedGalleryObjects = state.sortedGalleryObjects.filter(
          item => item.photoReference != dTPhotoRef,
        );
        // const index = state.sortedGalleryObjects.indexOf(
        //   getPhotosUrl({
        //     photoSource: dTPhotoSource,
        //     photoReference: dTPhotoRef,
        //   }),
        // );
        // if (index > -1) {
        //   // only splice array when item is found
        //   state.sortedGalleryObjects.splice(index, 1); // 2nd parameter means remove one item only
        // }
      }

      // New thumbnail already in the gallery so we do not need to push it sortedGalleryObjects
      // if (nTPhotoSource) {
      //   if (nTPhotoSource == PhotoSource.UserUploadedPublicRecord) {
      //     nTPhotoRef = constructXLPhotoUrl(nTPhotoRef);
      //   }
      //   state.sortedGalleryObjects.unshift(getPhotosUrl(nTPhotoRef));
      // }

      let tags = state.activity.tags.filter(
        tag => tag.name !== action.payload.tag.name,
      );
      let newActivity = {
        ...state.activity,
        tags,

        completedActivitiesByTags: action.payload.completedActivitiesByTags,
        averageCompletionTime: action.payload.averageCompletionTime,
        averageActualCost: action.payload.averageActualCost,
        averageBudget: action.payload.averageBudget,
        realisticScore: action.payload.realisticScore,
      };

      state.activity = newActivity;
      state.loading = false;
    },
    [deleteTagFromActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [addArbitraryPeople.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addArbitraryPeople.fulfilled]: (state, action) => {
      if (state.activity.arbitraryPeoples) {
        let selectedArbitraryPeople = state.activity.arbitraryPeoples.map(
          item => item,
        );
        selectedArbitraryPeople.push(action.payload.arbitraryPeople);
        state.activity.arbitraryPeoples = selectedArbitraryPeople;
      } else {
        state.activity.arbitraryPeoples = [action.payload.arbitraryPeople];
      }
    },
    [addArbitraryPeople.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [deleteArbitraryPeople.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteArbitraryPeople.fulfilled]: (state, action) => {
      state.errors = [];
      let arbitraryPeoples = state.activity.arbitraryPeoples.filter(
        arbitraryPeople =>
          arbitraryPeople.id !== action.payload.arbitraryPeople.id,
      );
      let newActivity = {
        ...state.activity,
        arbitraryPeoples,
      };
      state.activity = newActivity;
      state.loading = false;
    },
    [deleteArbitraryPeople.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [addParticipantIntoActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addParticipantIntoActivity.fulfilled]: (state, action) => {
      state.journeyAcceptedParticipants.unshift(action.payload.participant);
      // if (state.activity.participants) {
      //   let selectedParticipants = state.activity.participants.map(
      //     item => item,
      //   );
      //   selectedParticipants.push(action.payload.participant);
      //   state.activity.participants = selectedParticipants;
      // } else {
      //   state.activity.participants = [action.payload.participant];
      // }
      // state.journeyTimeLine.forEach(item => {
      //   if (item.type === TimelineType.ParticipantAdded) {
      //     item.participants?.push(action.payload.participant);
      //   }
      // });

      state.loading = false;
    },
    [addParticipantIntoActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [deleteParticipantFromActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteParticipantFromActivity.fulfilled]: (state, action) => {
      state.errors = [];
      let participants = state.activity.participants.filter(
        participant => participant.id !== action.payload.participant.id,
      );
      let newActivity = {
        ...state.activity,
        participants,
      };
      state.activity = newActivity;
      // state.activity.isEditable = false;
      state.journeyTimeLine.forEach(item => {
        if (item.type === TimelineType.ParticipantAdded) {
          let participants = item.participants?.filter(
            participant => participant.id !== action.payload.participant.id,
          );
          item.participants = participants;
        }
      });
      state.journeyAcceptedParticipants =
        state.journeyAcceptedParticipants.filter(
          item => item.id != action.payload.participant.id,
        );
      state.journeyAllParticipants = state.journeyAllParticipants.filter(
        item => item.id != action.payload.participant.id,
      );
      state.loading = false;
    },
    [deleteParticipantFromActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [createTaskForActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [createTaskForActivity.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.activity;
      state.sortedGalleryObjects = action.payload.gallery;
      // state.activityThumbnail = action.payload.thumbnail;
      if (state.activity.tasks) {
        prod.tasks = [action.payload.task, ...state.activity.tasks];
      } else {
        prod.tasks = [action.payload.task];
      }
      state.activity = {
        ...prod,
      };
      state.journeyTimeLine.unshift(action.payload.timeline);

      state.loading = false;
    },
    [createTaskForActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [updateActivityTask.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [updateActivityTask.fulfilled]: (state, action) => {
      state.errors = [];
      // const newTimeline = state.journeyTimeLine.map(t => {
      //   if (t.id == action.payload?.timelines[0]?.id) {
      //     return {
      //       ...action.payload?.timelines[0],
      //       task: {
      //         ...action.payload?.timelines[0].task,
      //         assignee: t?.task?.assignee,
      //       },
      //       user: t.user,
      //     };
      //   } else {
      //     return t;
      //   }
      // });
      // state.journeyTimeLine = newTimeline;

      state.loading = false;
    },
    [updateActivityTask.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [addTaskAssignee.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addTaskAssignee.fulfilled]: (state, action) => {
      if (state.journeyTimeLine) {
        const tempTimeLine = [];
        state.journeyTimeLine?.forEach(item => {
          if (item?.task?.id == action.payload?.task.id) {
            item.task.assignee = action.payload.task.assignee;
          }
        });
      }
      state.loading = false;
    },
    [addTaskAssignee.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteTaskAsssignee.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteTaskAsssignee.fulfilled]: (state, action) => {
      if (state.journeyTimeLine) {
        state.journeyTimeLine?.forEach(item => {
          if (item?.task?.id == action.payload?.task?.id) {
            item.task.assignee = undefined;
          }
        });
      }
    },
    [deleteTaskAsssignee.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    // [acceptOrRejectTask.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [acceptOrRejectTask.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.loading = false;
    // },
    // [acceptOrRejectTask.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    [acceptOrRejectParticipant.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [acceptOrRejectParticipant.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
    },
    [acceptOrRejectParticipant.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteTaskFromActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteTaskFromActivity.fulfilled]: (state, action) => {
      state.errors = [];
      const { timeline } = action.payload;
      const temp = state.journeyTimeLine.filter(t => t?.id != timeline.id);
      state.journeyTimeLine = temp;
      if (timeline.task?.location?.photo) {
        state.sortedGalleryObjects = state.sortedGalleryObjects.filter(
          item =>
            item.photoReference !=
            timeline.task?.location?.photo.photoReference,
        );

        // const index = state.sortedGalleryObjects.indexOf(
        //   getPhotosUrl(timeline.task?.location?.photo),
        // );
        // if (index > -1) {
        //   // only splice array when item is found
        //   state.sortedGalleryObjects.splice(index, 1); // 2nd parameter means remove one item only
        // }
      }
      state.loading = false;
    },
    [deleteTaskFromActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [addSuggestedTask.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addSuggestedTask.fulfilled]: (state, action) => {
      state.errors = [];
      // state.journeyTimeLine.unshift({
      //   type: 1,
      //   task: action.payload?.task,
      //   id: action.payload.task.id,
      //   createdAt: new Date(),
      //   user: action.payload.user,
      // });
      state.journeyTimeLine.unshift(action.payload.timelines[0]);

      state.loading = false;
    },
    [addSuggestedTask.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getActivityById.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.activity = {};
      // state.gallery = [];
      state.journeyTimeLine = [];
      state.journey_loading = true;
      state.sortedGalleryObjects = [];
      state.currentGalleryIndex = 0;
    },
    [getActivityById.fulfilled]: (state, action) => {
      state.journey_loading = false;
      state.errors = [];

      const journeyNameToNumber =
        hash(action?.payload?.name) % action.payload.templatePhotoCount;
      if (action.payload.clearParentStack) {
        state.parentChildStack = [];
      }
      console.log('journeyNameToNumberTimeline', action.payload);
      state.activity = action.payload;
      state.journeyTimeLine = action.payload.timelines;
      // state.gallery = action.payload?.gallery;
      console.log('state.journeyTimeLine', state.journeyTimeLine);
      state.sortedGalleryObjects =
        action.payload?.gallery?.length > 0
          ? action.payload.gallery
          : [
            {
              photoReference: `https://storage.googleapis.com/jrnyfy/mobile/template-${journeyNameToNumber + 1
                }-lr.jpeg`,
              photoSource: PhotoSource.LocalStorage,
            },
          ];
      console.log('ActivitySortedGallery', state.sortedGalleryObjects);
      // const ideas = action?.payload?.ideas;
      // const fnc = async () => {
      //   return await parse_SourceObjectWithInnerHits_Leads({results: ideas});
      // };
      // const temp =  fnc();
      state.ideas = action?.payload?.ideas;
      state.loading = false;
    },
    [getActivityById.rejected]: (state, action) => {
      state.loading = false;
      state.journey_loading = false;
      state.errors = action.payload;
    },
    [getPlaceDetails.pending]: state => { },
    [getPlaceDetails.fulfilled]: (state, action) => { },
    [getPlaceDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getFeedDescription.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getFeedDescription.fulfilled]: (state, action) => {
      state.journey_loading = false;

      state.loading = false;
    },
    [getFeedDescription.rejected]: (state, action) => {
      state.loading = false;
      state.journey_loading = false;
      state.errors = action.payload;
    },
    [updateGallery.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [updateGallery.fulfilled]: (state, action) => {
      if (action.payload.gallery1) {
        const sortedGallery = action.payload.gallery1.sort(
          (a, b) => a.position + ''.localeCompare(b.position + ''),
        );
        state.activity.gallery = sortedGallery;
        // state.gallery = sortedGallery;
      }
      if (!action.payload?.timeLineData) {
        // state.journeyTimeLine.unshift({
        //   photos: action.payload.gallery1,
        //   type: 4,
        //   id: action.payload.id,
        //   createdAt: new Date(),
        //   user: action.payload.user,
        // });
      } else {
        state.journeyTimeLine?.forEach(item => {
          if (item.id == action.payload?.timeLineData?.id) {
            const temp = [];
            action.payload?.gallery1.forEach(e => {
              temp.push({
                thumbnail: {
                  photoReference: e.photoReference,
                  photoSource: e.photoSource,
                },
                ...e,
              });
            });
            item.photos = temp;
          }
        });
      }
      state.loading = false;
    },
    [updateGallery.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getGalleryItems.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getGalleryItems.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getGalleryItems.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getGallerySingleItem.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getGallerySingleItem.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getGallerySingleItem.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteGalleryItem.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    // [deleteGalleryItem.fulfilled]: (state, action) => {
    //   let temp = state.activity.gallery.filter(
    //     gallery =>
    //       action.payload.gallery.photoReference != gallery.photoReference,
    //   );
    //   state.activity.gallery = temp;
    //   state.gallery = temp;
    //   state.journeyTimeLine?.forEach(item => {
    //     if (item.id == action.payload?.timeLine?.id) {
    //       const tempPic = [];
    //       item.photos.forEach(pic => {
    //         if (pic.id != action.payload.gallery.id) {
    //           tempPic.push(pic);
    //         }
    //       });
    //       item.photos = tempPic;
    //     }
    //   });
    //   state.loading = false;
    // },
    [deleteGalleryItem.fulfilled]: (state, action) => {
      //state.sortedGalleryObjects = action.payload.gallery;
      // let temp = state.activity.gallery.filter(
      //   gallery =>
      //     action.payload.gallery.photoReference != gallery.photoReference,
      // );
      // console.log('ActivitySlice Temp', temp);
      // state.activity.gallery = temp;
      // state.gallery = temp;
      // console.log('SortGallery', sortGallery(temp));

      //state.sortedGalleryObjects = sortGallery(temp);
      // console.log('SortGallery555', state.sortedGalleryObjects);
      // const { timeline } = action.payload;
      // console.log('Timeline', timeline);
      // if (timeline) {
      //   if (timeline.type == TimelineType.PhotoAdded) {
      //     if (timeline.photos.length == 0) {
      //       state.journeyTimeLine = state.journeyTimeLine?.filter(
      //         item => item.id != timeline.id,
      //       );
      //     } else {
      //       const zt = state.journeyTimeLine?.map(item => {
      //         if (item.id == timeline.id) {
      //           console.log('Check Timline');
      //           let photos = item.photos.filter(
      //             p =>
      //               (p.photoSource == PhotoSource.UserUploaded &&
      //                 p.photoReference !=
      //                 changeUrlToSize(
      //                   action.payload.gallery.photoReference,
      //                   PhotoSize.Medium,
      //                 )) ||
      //               (p.photoSource != PhotoSource.UserUploaded &&
      //                 p.photoReference !=
      //                 action.payload.gallery.photoReference),
      //           );
      //           console.log('TimelinPhotots', photos);
      //           return {
      //             ...item,
      //             photos,
      //           };
      //         } else {
      //           return item;
      //         }
      //       });
      //       state.journeyTimeLine = zt;
      //       console.log('ZT', zt);
      //     }
      //   } else if (timeline.type == TimelineType.Progress) {
      //     if (timeline.photos.length == 0 && !timeline.text) {
      //       state.journeyTimeLine = state.journeyTimeLine?.filter(
      //         item => item.id != timeline.id,
      //       );
      //     } else if (timeline.photos.length > 0) {
      //       const zt = state.journeyTimeLine?.map(item => {
      //         if (item.id == timeline.id) {
      //           let photos = item.photos.filter(
      //             p =>
      //               (p.photoSource == PhotoSource.UserUploaded &&
      //                 p.photoReference !=
      //                 changeUrlToSize(
      //                   action.payload.gallery.photoReference,
      //                   PhotoSize.Medium,
      //                 )) ||
      //               (p.photoSource != PhotoSource.UserUploaded &&
      //                 p.photoReference !=
      //                 action.payload.gallery.photoReference),
      //           );
      //           return {
      //             ...item,
      //             photos,
      //           };
      //         } else {
      //           return item;
      //         }
      //       });
      //       state.journeyTimeLine = zt;
      //       console.log('ZT', zt);
      //     }
      //   }
      // }

      // if (!timeline.text) {
      //   if (timeline.photos.length == 0) {
      //     console.log('journeyTimeLine', state.journeyTimeLine);
      //     state.journeyTimeLine = state.journeyTimeLine.filter(
      //       t => timeline.id != t.id,
      //     );
      //     console.log('TimelineState', state.timeline);
      //   } else {
      //     state.journeyTimeLine?.forEach(item => {
      //       if (item.id == timeline.id) {
      //         item.photos = item.photos.filter(
      //           p => p.id != action.payload.gallery.id,
      //         );
      //       }
      //     });
      //   }
      // } else {
      //   state.journeyTimeLine?.forEach(item => {
      //     if (item.id == timeline.id) {
      //       item.photos = item.photos.filter(
      //         p => p.id != action.payload.gallery.id,
      //       );
      //     }
      //   });
      // }
      state.loading = false;
    },
    [deleteGalleryItem.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [uploadGalleryPhotos.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [uploadGalleryPhotos.fulfilled]: (state, action) => {
      let temp = [];
      if (action.payload.gallery) {
        state.activity.gallery = action.payload.gallery;
        state.sortedGalleryObjects = action.payload.gallery;
      }
      if (action.payload.thumbnail) {
        state.activity.thumbnail = action.payload.thumbnail;
      }
      state.journeyTimeLine = action.payload
      state.loading = false;
      state.bgPhotoUploadProgress = 0;
    },
    [uploadGalleryPhotos.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [getActivitiesFilters.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getActivitiesFilters.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload.filters) {
        state.filters = action.payload.filters;
      }
      state.loading = false;
    },
    [getActivitiesFilters.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMatchedTags.pending]: state => {
      state.loading = true;
      state.errors = [];
    },
    [getMatchedTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.tags = action.payload;
    },
    [getMatchedTags.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreMatchedTags.pending]: state => {
      state.loading = true;
      state.errors = [];
    },
    [getMoreMatchedTags.fulfilled]: (state, action) => {
      state.loading = false;
      const aTags = state.tags.concat(action.payload);
      state.tags = aTags;
    },
    [getMoreMatchedTags.rejected]: (state, action) => {
      state.loading = false;
    },

    // [createTag.pending]: state => {
    //   state.loading = true;
    //   state.errors = [];
    // },
    // [createTag.fulfilled]: (state, action) => {
    //   console.log('create tag__', action.payload);
    //   if (action.payload && action.payload.tag) {
    //     state.newlyCreateTag = action.payload.tag;
    //     if (state.activity.tags && state.activity.tags.length > 0) {
    //       let tags = state.activity.tags.map(item => item);
    //       tags.push(action.payload.tag);
    //       state.activity.tags = tags;
    //     } else {
    //       state.activity.tags = [action.payload.tag];
    //     }
    //   }
    //   state.loading = false;
    // },
    // [createTag.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },

    // [updateTag.pending]: state => {
    //   state.loading = true;
    //   state.errors = [];
    // },
    // [updateTag.fulfilled]: (state, action) => {
    //   console.log('update tag__', action.payload);
    //   state.loading = false;
    // },
    // [updateTag.rejected]: (state, action) => {
    //   state.loading = false;
    // },
    [addInspirer.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addInspirer.fulfilled]: (state, action) => {
      let inspirers = state.activity.inspirers
        ? state.activity.inspirers.map(item => item)
        : [];
      inspirers.push(action.payload.inspirer);
      state.activity.inspirers = inspirers;
    },
    [addInspirer.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteInspirer.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteInspirer.fulfilled]: (state, action) => {
      state.errors = [];
      let inspirers = state.activity.inspirers.filter(
        inspirer => inspirer.id !== action.payload.inspirer.id,
      );
      let newActivity = {
        ...state.activity,
        inspirers,
      };
      state.activity = newActivity;
      state.loading = false;
    },
    [deleteInspirer.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [addGuide.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addGuide.fulfilled]: (state, action) => {
      let guides = state.activity.guides
        ? state.activity.guides.map(item => item)
        : [];
      guides.push(action.payload.guide);
      state.activity.guides = guides;
    },
    [addGuide.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteGuide.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteGuide.fulfilled]: (state, action) => {
      state.errors = [];
      let guides = state.activity.guides.filter(
        guide => guide.id !== action.payload.guide.id,
      );
      let newActivity = {
        ...state.activity,
        guides,
      };
      state.activity = newActivity;
      state.loading = false;
    },
    [deleteGuide.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [addInterest.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addInterest.fulfilled]: (state, action) => {
      let interests = state.activity.interests
        ? state.activity.interests.map(item => item)
        : [];
      interests.push(action.payload.interest);
      state.activity.interests = interests;
    },
    [addInterest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [addLocationIntoActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addLocationIntoActivity.fulfilled]: (state, action) => {
      // const temp1 = state?.activity?.locations;
      // const temp2 = action?.payload?.timelines[0]?.task.location;
      // const photo = action?.payload?.photo;
      console.log('ActivityLocatoinsBBB', state.activity);
      state.activity.latestLocation = action.payload.latestLocation
      if (state.activity?.locations) {
        state.activity.locations = [
          ...state?.activity?.locations,
          action?.payload?.timeline
        ];
      } else {
        state.activity.locations = [
          action?.payload?.timeline
        ];
      }

      console.log('ActivityLocatoinsAAAA', state.activity);
      // state.activity.tasks = [...state?.activity?.tasks, action?.payload?.timelines[0].task];
      // state.journeyTimeLine = action.payload;

      state.sortedGalleryObjects = action.payload.gallery;

    },
    [addLocationIntoActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getLocationFromActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getLocationFromActivity.fulfilled]: (state, action) => {
      state.inpiredJouneyLocations = action.payload.locations;
    },
    [getLocationFromActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [removeLocationIntoActivity.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [removeLocationIntoActivity.fulfilled]: (state, action) => {
      state.errors = [];
      const temp = [];
      console.log('data from remove location', action);
      state.activity.locations.forEach(item => {
        if (action?.payload?.name != item?.name) {
          temp.push(item);
        }
      });
      state.activity.locations = temp;
      state.loading = true;
    },
    [removeLocationIntoActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [removeTimelineItem.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [removeTimelineItem.fulfilled]: (state, action) => {
      state.errors = [];
      state.activity.locations = state.activity?.locations?.filter(
        item => item.id != action.payload.id,
      );
      state.sortedGalleryObjects = action.payload.gallery;
      state.loading = true;
    },
    [removeTimelineItem.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteInterest.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteInterest.fulfilled]: (state, action) => {
      state.errors = [];
      let interests = state.activity.interests.filter(
        interest => interest.name !== action.payload.interest.name,
      );
      let newActivity = {
        ...state.activity,
        interests,
      };
      state.activity = newActivity;
      state.loading = false;
    },
    [deleteInterest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [shareTicket.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [shareTicket.fulfilled]: (state, action) => {
      state.errors = [];
      const timelineId = action.payload.timelineId;
      state.journeyTimeLine?.forEach(item => {
        if (item.id === timelineId) {
          item?.order?.tickets.forEach(ticket => {
            if (ticket.id == action.payload.ticketId) {
              ticket.owner.id = action.payload.ownerId;
              ticket.user.id = action.payload.userId;
            }
          });
        }
      });
      state.loading = false;
    },
    [shareTicket.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    //
    [cancelSharedTicket.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [cancelSharedTicket.fulfilled]: (state, action) => {
      state.errors = [];
      const timelineId = action.payload.timelineId;
      state.journeyTimeLine?.forEach(item => {
        if (item.id === timelineId) {
          item?.order?.tickets.forEach(ticket => {
            if (ticket.id == action.payload.ticketId) {
              ticket.owner.id = action.payload.ownerId;
              ticket.user.id = action.payload.userId;
            }
          });
        }
      });
      state.loading = false;
    },
    [cancelSharedTicket.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    //time Line state
    [getTimeLineForJourney.pending]: state => {
      state.errors = [];
      state.loading = true;

      state.journey_loading = true;
    },
    [getTimeLineForJourney.fulfilled]: (state, action) => {
      state.errors = [];
      console.log('Payload Data', action.payload);
      state.journeyTimeLine = [
        ...state.journeyTimeLine,
        ...action.payload.timeLineData,
      ];
      // if (action.payload.timeLineData) {
      //   state.journeyTimeLine = [
      //     ...state.journeyTimeLine,
      //     action.payload.timeLineData,
      //   ];
      //   state.activity.timelines = action.payload.timeLineData;
      // } else {
      //   state.journeyTimeLine = [...state.journeyTimeLine, action.payload];
      //   state.activity.timelines = action.payload;
      // }
      state.loading = false;
      state.journey_loading = false;
    },
    [getTimeLineForJourney.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
      state.journey_loading = false;
    },
    [getMoreTimeline.pending]: state => {
      state.errors = [];
      state.loading = true;

      state.journey_loading = true;
    },
    [getMoreTimeline.fulfilled]: (state, action) => {
      state.errors = [];
      console.log('Payload Data', state.journeyTimeLine);
      // state.journeyTimeLine = [
      //   ...state.journeyTimeLine,
      //   ...action.payload,
      // ];

    },
    [getMoreTimeline.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
      state.journey_loading = false;
    },
    [updateComment.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [updateComment.fulfilled]: (state, action) => {
      state.errors = [];
      const comm = state.comments.comments;
      state.loading = false;
    },
    [updateComment.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getJourneyContent.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.comments = [];
    },
    [getJourneyContent.fulfilled]: (state, action) => {
      state.errors = [];
      // //state.comments = action.payload.comments;
      // state.comments = {
      //   comments: action.payload.results,
      //   count: action.payload.count,
      // };
      // state.participant = action.payload.results;
      // let aSize = action.payload?.results?.length;
      // let limit = action.payload.limit;
      // let skip = action.payload.skip;
      // let sum = parseInt(limit) + parseInt(skip);
      // if (aSize < limit) {
      //   state.contentRangeComments = `${skip}-${sum}/false`;
      // } else {
      //   state.contentRangeComments = `${skip}-${sum}/true`;
      // }
      state.loading = false;
    },
    [getJourneyContent.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getCommentsByID.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.comments = [];
    },
    [getCommentsByID.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
    },
    [getCommentsByID.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreComments.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getMoreComments.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      console.log('action.payload getMoreComments', action.payload);
      const comments = state.comments.comments.concat(action.payload.comments);
      console.log('redux concat', comments);
      const temp = [...state.comments.comments, ...action.payload.comments];
      state.comments = {
        comments: comments,
        count: action.payload.count,
      };
      let aSize = action.payload?.comments?.length;
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSize < limit) {
        state.contentRangeComments = `${skip}-${sum}/false`;
      } else {
        state.contentRangeComments = `${skip}-${sum}/true`;
      }
    },
    [getMoreComments.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [createComment.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [createComment.fulfilled]: (state, action) => {
      state.errors = [];
      // const temp = {
      //   ...action?.payload,
      //   thumbnail: action?.payload?.user?.thumbnail,
      // };
      // console.log('state.createComment.comments', temp);
      // state.comments.comments.unshift(action?.payload);
      // state.comments.count = parseInt(state.comments.count) + 1;
      state.loading = false;
    },
    [createComment.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteComment.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteComment.fulfilled]: (state, action) => {
      state.errors = [];
      const comm = state.comments.comments;
      // const temp = comm.filter(
      //   item => item.commentId != action.payload.commentID,
      // );
      // state.comments.comments = temp;
      // state.comments.count = parseInt(state.comments.count) - 1;
      state.loading = false;
    },
    [deleteComment.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getAcceptedParticipantsOfJourney.pending]: state => {
      state.errors = [];
      state.journeyAcceptedParticipants = [];
      state.loading = true;
    },
    [getAcceptedParticipantsOfJourney.fulfilled]: (state, action) => {
      state.errors = [];

      state.journeyAcceptedParticipants = action.payload.users;
      console.log(
        'action.payload getParticipantsOfJourney11',
        state.journeyAcceptedParticipants,
      );
      let aSize = action.payload?.users?.length;
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSize < limit) {
        state.contentRangeJourneyParticipants = `${skip}-${sum}/false`;
      } else {
        state.contentRangeJourneyParticipants = `${skip}-${sum}/true`;
      }
      state.loading = false;
    },
    [getAcceptedParticipantsOfJourney.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getAllParticipantsOfJourney.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getAllParticipantsOfJourney.fulfilled]: (state, action) => {
      state.errors = [];

      state.journeyAllParticipants = action.payload.users;
      console.log(
        'action.payload getParticipantsOfJourney11',
        state.journeyAllParticipants,
      );
      let aSize = action.payload?.users?.length;
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSize < limit) {
        state.contentRangeJourneyParticipants = `${skip}-${sum}/false`;
      } else {
        state.contentRangeJourneyParticipants = `${skip}-${sum}/true`;
      }
      state.loading = false;
    },
    [getAllParticipantsOfJourney.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getContentUsers.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getContentUsers.fulfilled]: (state, action) => {
      state.errors = [];

      state.loading = false;
    },
    [getContentUsers.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getIdeasForJourney.pending]: state => {
      state.errors = [];
      state.ideas = [];
      state.loading = true;
    },
    [getIdeasForJourney.fulfilled]: (state, action) => {
      state.errors = [];
      state.ideas = [...action.payload.products];
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.products.length;
      if (aSize < size) {
        state.contentRangeIdea = `${from}-${size}/false`;
      } else {
        state.contentRangeIdea = `${from}-${size}/true`;
      }
      // state.contentRangeIdea = action.payload.contentRange;
      state.loading = false;
    },
    [getIdeasForJourney.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreIdeasForJourney.pending]: state => {
      state.loading = true;
      state.errors = [];
      state.contentRangeIdea = undefined;
    },

    [getMoreIdeasForJourney.fulfilled]: (state, action) => {
      state.loading = false;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.products.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeIdea = `${from}-${sum}/false`;
      } else {
        state.contentRangeIdea = `${from}-${sum}/true`;
      }
      const products = state.ideas.concat(action.payload.products);
      state.ideas = products;
    },
    [getMoreIdeasForJourney.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  setActivityThumbnail,
  setSelectedTask,
  clearTags,
  setCurrentActivityGalleryAndThumbnail,
  pushToParentChildStack,
  pullFromParentChildStack,
  clearErrors,
  participantAccepted,
  taskAssigneeAccepted,
  removeWishListTask,
  addWishListTask,
  pushIntoSortedGallery,
  deletePictureFromGallery,
  replaceSorteGalleryItems,
  moveUpdownSortedGalleryItems,
  addTagsActivity,
  updateTagList,
  replaceActivityGallery,
  activtyLocations,
  resetActivityLocations,
  uploadGalleryTrigger,
  backgroundPhotoUploadQueue,
  updateBackgroundPhotoUploadQueue,
  removeUploadedBackgroundQueue,
  removeUploadedBackgroundTimeline,
  uploadBgPhotoProgress,
  removeSignleUploadedBackgroundTimeline,
  userProfileActivityUpdate,
  addToastMessage,
  removeToastMessage,
  setCurrentGalleryState,
} = activitySlice.actions;

export default activitySlice.reducer;

export const activitySelector = state => state.activity;
