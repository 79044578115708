import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import {
  activitySelector,
  deleteTagFromActivity,
  updateActivity,
  uploadGalleryPhotos,
} from '../../redux/slices/mobile/activitySlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { networkStatusSelector } from '../../redux/slices/web/networkStatusSlice';
import {
  BTN_JOURNEY_TAKE_PLACE,
  BTN_SHARE_YOUR_STORY,
  BTN_TAG_SUCCESS_STORY,
  BTN_WHO_CAME_WITH_YOU,
  BTN_WHO_INSPIRED_YOU,
  PLACEHOLDER_TEXT_STORY_DESCRIPTION,
} from '../../utils/static';
import ProgressPicThumb from '../jrnyTimline/progressPicThumb';
import {
  FileUploadFor,
  PhotoSize,
  PhotoSource,
  RecordStatus,
} from '../utils/subjects';
import { onTypeChange } from '../utils/typing-change';
import AddFriends from '../AddFriends/AddFriends';
import { buildUrlToSize } from '../../utils/build-image-url';
import AddPlaces from '../AddPlaces/AddPlaces';
import addPlaceIcon from '../../assets/images/button_menu_places.svg';
import TagList from '../TagList/TagList';
import {
  deleteTagFromProduct,
  productSelector,
} from '../../redux/slices/mobile/productSlice';
import InspiredList from '../InspiredList/InspiredList';

const UpdateStoryPopUp = ({
  updateSuccessStoryModal,
  updateSuccessStoryHandle,
}) => {
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  const inputRef = useRef();
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);

  let dispatch = useDispatch();
  let { auth } = useSelector(authSelector);
  let { connection } = useSelector(networkStatusSelector);
  let [userArr, setUserArr] = useState([]);
  let [placeArr, setPlaceArr] = useState([]);
  const [isEditEnable, setIsEditEnable] = useState(false);
  let [friendState, setFriendState] = useState(false);
  const { activity } = useSelector(activitySelector);
  const { product } = useSelector(productSelector);
  const [showTags, setShowTags] = useState(undefined);
  const [showInspirer, setShowInspirer] = useState(false);
  const [storyImages, setStoryImages] = useState([]);
  const [storyDescription, setStoryDescription] = useState({
    vissible: true,
    value: undefined,
  });
  const type = 'journey';

  console.log('Activity', activity);
  let friendControl = () => {
    setFriendState((prevState) => !prevState);
  };

  let [locState, setLocState] = useState(false);
  let locationControl = () => {
    setLocState((state) => !state);
  };

  const inspirerHandle = () => {
    setShowInspirer((prevState) => !prevState);
  };

  const tagListHandle = () => {
    setShowTags((prevState) => !prevState);
  };

  const UploadStory = () => {
    console.log('Upload');
    let formData = new FormData();
    formData.append('id', activity.id);
    formData.append('sk', activity.sk);
    formData.append('createdAt', activity.createdAt);
    formData.append('name', removeSpecialCharacters(activity.name));
    formData.append('uploadFor', FileUploadFor.Activity);
    for (let i = 0; i < storyImages.length; i++) {
      formData.append('photos', storyImages[i]?.file);
    }
    dispatch(uploadGalleryPhotos({ formData, token: auth.token }));
  };

  const removeTag = (tag) => {
    if (type == 'journey') {
      dispatch(
        deleteTagFromActivity({
          data: {
            sk: activity.sk,
            createdAt: activity.createdAt,
          },
          token: auth.token,
          id: activity.id,
          tagName: tag.name,
          tagId: tag.id,
          callback: (result) => {
            console.log('Deleted Task Result', result);
          },
        })
      );
    } else {
      dispatch(
        deleteTagFromProduct({
          data: {
            sk: product.sk,
            createdAt: product.createdAt,
          },
          token: auth.token,
          id: product.id,
          tagName: tag.name,
          tagId: tag.id,
        })
      );
    }
  };

  return (
    <Popup
      open={updateSuccessStoryModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <AnimatePresence>
        <div className='bg-white p-10  w-[500px] rounded-[20px] max-[767px]:overflow-y-scroll max-[600px]:h-screen  max-[600px]:rounded-none max-[600px]:p-5 '>
          {/* -------cross button-------- */}
          {!friendState && !locState && !showTags && !showInspirer && (
            <img
              src={Cross}
              alt='cross'
              className='cursor-pointer w-[20px] '
              onClick={updateSuccessStoryHandle}
            />
          )}
          {showInspirer && <InspiredList inspirerHandle={inspirerHandle} />}
          {showTags && <TagList tagListHandle={tagListHandle} type='journey' />}
          {friendState && (
            <AddFriends
              headerText={'who inspired you take this journey'}
              control={friendControl}
              userAdd={setUserArr}
              userArr={userArr}
            />
          )}
          {locState && (
            <AddPlaces
              showBackBtn={true}
              control={locationControl}
              placeAdd={setPlaceArr}
              placeArr={placeArr}
            />
          )}
          {!friendState && !locState && !showTags && !showInspirer && (
            <>
              {!isEditEnable && (
                <>
                  <div className='my-[20px]  font-SourceSansPro'>
                    <p>{activity?.name}</p>
                  </div>
                  <div className='flex flex-col mt-[20px] relative'>
                    <textarea
                      ref={inputRef}
                      onChange={onTypeChange(
                        typeInput,
                        setTypeInput,
                        (value) => {
                          if (connection) {
                            dispatch(
                              updateActivity({
                                data: {
                                  description: value,
                                  sk: activity.sk,
                                  createdAt: activity.createdAt,
                                },
                                token: auth.token,
                                id: activity.id,
                              })
                            );
                          }
                        }
                      )}
                      spellCheck='true'
                      className='resize-none  min-h-[150px] z-40 break-words text-[20px] focus:outline-none'
                      value={typeInput.text}
                    />

                    {!typeInput.text && (
                      <span className='absolute top-0 pointer-events-none w-full text-[20px] min-[350px]:max-[460px]:text-[17px] min-[350px]:max-[460px]:leading-[21px] text-jrnyfy-textprimary font-Montserrat z-[999]'>
                        {PLACEHOLDER_TEXT_STORY_DESCRIPTION}
                      </span>
                    )}
                  </div>
                </>
              )}
              <div className='items-center space-y-6'>
                <div className='flex justify-evenly'>
                  <ProgressPicThumb
                    setIsEditEnable={setIsEditEnable}
                    isEditEnable={isEditEnable}
                    btnText='Add Success Story Images'
                    selectImg={storyImages}
                    setSelectImg={setStoryImages}
                  />
                  {/* <ProgressPicThumb />
                <ProgressPicThumb />
                <ProgressPicThumb /> */}
                </div>

                {!isEditEnable && (
                  <div className='flex  flex-wrap'>
                    <button
                      onClick={locationControl}
                      className={
                        (userArr.length > 0
                          ? 'rounded-[10px]'
                          : 'rounded-[50px]') +
                        ' border-[1px] mt-[10px] whitespace-nowrap border-solid text-sm mr-[5px] px-[10px] p-[5px] '
                      }
                    >
                      {BTN_JOURNEY_TAKE_PLACE}
                      <div className='flex flex-wrap'>
                        {placeArr &&
                          placeArr.map(
                            (val, ind) =>
                              ind <= 3 && (
                                <img
                                  key={ind}
                                  src={
                                    val?.photos === undefined
                                      ? addPlaceIcon
                                      : buildUrlToSize({
                                          photoReference: val?.photos[0]
                                            .photo_reference
                                            ? val?.photos[0].photo_reference
                                            : val?.photos[0].getUrl(),
                                          photoSource: val?.photos[0]
                                            .photo_reference
                                            ? PhotoSource.GooglePlace
                                            : PhotoSource.GooglePlaceFullURL,
                                        })
                                  }
                                  alt={val.name}
                                  className='w-[25px] h-[25px] max-w-none rounded-[50%] ring-2 ring-white ml-[-7px]'
                                />
                              )
                          )}
                        {placeArr.length > 4 && (
                          <div>+{placeArr.length - 4}</div>
                        )}
                      </div>
                    </button>
                    <button
                      onClick={friendControl}
                      className={
                        (placeArr.length > 0
                          ? 'rounded-[10px]'
                          : 'rounded-[50px]') +
                        ' border-[1px] mt-[10px] whitespace-nowrap border-solid text-sm mr-[5px] px-[10px] p-[5px] '
                      }
                    >
                      {BTN_WHO_CAME_WITH_YOU}

                      <div className='flex flex-wrap'>
                        {userArr &&
                          userArr.map(
                            (val, ind) =>
                              ind <= 3 && (
                                <div className='text-center text-[22px] overflow-hidden bg-jrnyfy-themeColor text-white w-[25px] h-[25px] rounded-[50%] ring-2 ring-white ml-[-7px]'>
                                  {buildUrlToSize(
                                    val.thumbnail,
                                    PhotoSize.Large
                                  ) === undefined ? (
                                    val.name.slice(0, 1)
                                  ) : (
                                    <img
                                      key={ind}
                                      src={buildUrlToSize(
                                        val.thumbnail,
                                        PhotoSize.Large
                                      )}
                                      alt={val.name}
                                      className='w-[35px] h-[35px] rounded-[50%]'
                                    />
                                  )}
                                </div>
                              )
                          )}
                        {userArr.length > 4 && <div>+{userArr.length - 4}</div>}
                      </div>
                    </button>
                    <button
                      onClick={inspirerHandle}
                      className={`${
                        activity?.inspirers?.length > 0
                          ? 'rounded-[10px]'
                          : 'rounded-[50px]'
                      } border-[1px] mt-[10px] whitespace-nowrap border-solid text-sm mr-[5px] px-[10px] p-[5px]`}
                    >
                      {BTN_WHO_INSPIRED_YOU}
                      <div className='flex flex-wrap'>
                        {activity?.inspirers &&
                          activity?.inspirers.map(
                            (val, ind) =>
                              ind <= 3 && (
                                <img
                                  key={ind}
                                  src={
                                    val.thumbnail === undefined
                                      ? addPlaceIcon
                                      : buildUrlToSize(
                                          val?.thumbnail,
                                          PhotoSize.Large
                                        )
                                  }
                                  alt={val.name}
                                  className='w-[25px] h-[25px] max-w-none rounded-[50%] ring-2 ring-white ml-[-7px]'
                                />
                              )
                          )}
                        {activity?.inspirers?.length > 4 && (
                          <div>+{activity?.inspirers.length - 4}</div>
                        )}
                      </div>
                    </button>
                    <button
                      onClick={tagListHandle}
                      className={`${
                        activity?.tags?.length > 0
                          ? 'rounded-[10px]'
                          : 'rounded-[50px]'
                      } border-[1px] mt-[10px] whitespace-nowrap border-solid text-sm [5px] px-[10px] p-[5px] rounded-[50px]`}
                    >
                      {BTN_TAG_SUCCESS_STORY}
                      <div className='flex flex-wrap'>
                        {activity.tags &&
                          activity.tags.map(
                            (val, ind) =>
                              ind <= 3 && (
                                <img
                                  key={ind}
                                  src={
                                    val.thumbnail === undefined
                                      ? addPlaceIcon
                                      : buildUrlToSize(val?.thumbnail)
                                  }
                                  alt={val.name}
                                  className='w-[25px] h-[25px] max-w-none rounded-[50%] ring-2 ring-white ml-[-7px]'
                                />
                              )
                          )}
                        {activity?.inspirers?.length > 4 && (
                          <div>+{activity?.inspirers.length - 4}</div>
                        )}
                      </div>
                    </button>
                  </div>
                )}
                {activity.tags && (
                  <div className=' space-x-1 space-y-1'>
                    {activity.tags.map((tag) => {
                      console.log('UpdateStoryTags', tag);
                      return (
                        <button className='bg-jrnyfy-textprimary p-[5px_10px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic'>
                          <div className='flex'>
                            {tag.name}
                            <img
                              src={Cross}
                              alt='remove'
                              className='w-[10px] ml-2'
                              onClick={() => {
                                removeTag(tag);
                              }}
                            />
                          </div>
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
              {!isEditEnable && (
                <div className='text-center mt-7'>
                  <button
                    className='bg-jrnyfy-themeColor p-[10px_20px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic'
                    onClick={() => {
                      if (storyImages) {
                        UploadStory();
                      }

                      updateSuccessStoryHandle();
                      if (connection) {
                        dispatch(
                          updateActivity({
                            data: {
                              recordStatus: RecordStatus.Active,
                              sk: activity.sk,
                              createdAt: activity.createdAt,
                            },
                            token: auth.token,
                            id: activity.id,
                            callback: () => {},
                          })
                        );
                      }
                    }}
                  >
                    {BTN_SHARE_YOUR_STORY}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </AnimatePresence>
    </Popup>
  );
};

const removeSpecialCharacters = (str) => {
  return str.replace(/[^a-zA-Z ]/g, '');
};

export default UpdateStoryPopUp;
