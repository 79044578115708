import React from 'react';
import FeaturedProduct from './FeaturedProduct';

const ServiceFeatures = () => {
  return (
    <div className='mt-[10px]'>
      <div className='inline-flex items-center justify-center w-full mt-[10px]'>
        <hr className='w-full h-px my-4 bg-gray-200 border-0 dark:bg-gray-700' />
        <span className='absolute bg-white italic mb-2 px-3 text-gray-400 font-extrabold text-[25px] -translate-x-1/2 left-1/2'>
          featured products
        </span>
      </div>
      <div className='text-[20px] text-center text-gray-400'>
        <p>spend less time planning your trip, spend time enjoying your trip</p>
      </div>
      <div className='max-w-[1200px] mx-auto flex mt-[30px] gap-[70px] '>
        <FeaturedProduct />
        <FeaturedProduct />
      </div>
    </div>
  );
};

export default ServiceFeatures;
