import React, { useEffect, useRef, useState } from 'react'
import Spinner from '../spinner/spinner'
import Comments from '../Comments/Comments'
import { useDispatch, useSelector } from 'react-redux'
import { activitySelector, getActivityById } from '../../redux/slices/mobile/activitySlice'
import { YesNo } from '../utils/subjects'
import TimeLineSlider from '../timeLineSlider/timeLineSlider'

export default function JourneyComments({ item, comments, type, auth }) {
    const dispatch = useDispatch()
    const { activity } = useSelector(activitySelector)
    const [itemDetails, setItemDetails] = useState()
    const [itemLoading, setItemLoading] = useState(true);
    const [commentLoading, setCommentLoading] = useState(false)
    const [commentsList, setCommentsList] = useState([]);
    const commentRef = useRef(null)

    return (
        <div className='flex flex-col flex-1 h-full'>
            <Comments
                ref={commentRef}
                comments={comments}
                commentItem={item}
                type={type}
                content={
                    <TimeLineSlider
                        data={activity}
                    />
                }
            />
        </div>
    )
}
