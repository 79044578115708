import React, { useEffect, useState } from 'react';
import Login from '../Login/Login';
import ResetPasswordModal from './ResetPasswordModal';
import { useParams, useSearchParams } from 'react-router-dom';
import { parseForgotPasswordParams } from '../../utils/get-nav-option';
import { userVerification } from '../../redux/slices/mobile/authSlice';
import { useDispatch } from 'react-redux';

const ForgetPassword = () => {
  const [resetPassModalModal, setResetPassModal] = useState(true);
  const dispatch = useDispatch();
  const resetPassModalHandle = () => {
    setResetPassModal((state) => !state);
  };
  const [searchParams] = useSearchParams();
  const forgotPassParamsData = parseForgotPasswordParams(searchParams);

  // dispatch(
  //   userVerification({
  //     data: {
  //       otp: forgotPassParamsData.otp,
  //       sk: forgotPassParamsData.sk,
  //       createdAt: forgotPassParamsData.createdAt,
  //     },
  //     id: forgotPassParamsData.id,
  //     successCallback: () => {
  //       setResetPassModal(true);
  //     },
  //     rejectCallback: (res) => {},
  //   })
  // );

  console.log('forgotPassParamsData', forgotPassParamsData);
  return (
    <div>
      <ResetPasswordModal
        forgotPassParamsData={forgotPassParamsData}
        resetPassModalHandle={resetPassModalHandle}
        resetPassModalModal={resetPassModalModal}
      />
    </div>
  );
};

export default ForgetPassword;
