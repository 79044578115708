import React, { useEffect, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { Swiper, SwiperSlide } from 'swiper/react';
import { buildUrlToSize } from '../../utils/build-image-url';
import { FreeMode, Navigation, Pagination } from 'swiper';
import { Link, useLocation } from 'react-router-dom';
import service2 from '../../assets/images/service-image.png';
import service1 from '../../assets/images/stories1.png';
import service3 from '../../assets/images/stories3.png';
import checkCircle from '../../assets/images/check-circle-svgrepo-com.svg';
import clock from '../../assets/images/time-svgrepo-com.svg';
import profile from '../../assets/images/profile-pic.svg';
import achievement from '../../assets/images/achievement.svg';
import { useDispatch, useSelector } from 'react-redux';
import { JourneyFilter, PhotoSize, UserContentFilter, YesNo } from '../utils/subjects';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import ImageGallery from 'react-image-gallery';
import {
  getProductById,
  getReviews,
  productSelector,
} from '../../redux/slices/mobile/productSlice';
import { showUTCHoursMins } from '../../res/commonFunctions';
import {
  convertIntTo12HourFormat,
  convertIntToHourAndMinuteTwelveHourFormat,
} from '../../components/utils/convertIntToHourAndMinute';
import ServicePaymentPopUp from './ServicePaymentPopUp';
import {
  getActiveActivitiesOfUser,
  viewProfileSelector,
} from '../../redux/slices/mobile/viewProfileSlice';
import { UserJourneyFilter } from '../../utils/filters';
import { activitySelector } from '../../redux/slices/mobile/activitySlice';
import Moment from 'react-moment';
import ReviewsModal from './ReviewsModal';
import { getUserThumbnailFromName } from '../utils/getUserThumnailFromName';
import 'react-image-gallery/styles/css/image-gallery.css';
import Cross from '../../assets/images/xmark-solid.svg';
import axios from 'axios';
import StripePayment from './StripePayment';
import baseUrl from '../../environments/proxyUrl';
import { bookingSelector } from '../../redux/slices/mobile/bookingSlice';
import CompletePaymentModal from './CompletePaymentModal';
import cancelBtnSvg from '../../assets/images/Cancel.svg';
import Seo from '../Seo/Seo';

const ServiceDetails = ({
  selectedDate,
  selectedPeople,
  showHideTimeStatus,
}) => {
  const Images = [{ url: service1 }, { url: service2 }, { url: service3 }];
  const { auth } = useSelector(authSelector);
  const { calendar } = useSelector(bookingSelector);
  const { activeActivities } = useSelector(viewProfileSelector);
  const { datesOfMonth } = useSelector(bookingSelector);
  const { product } = useSelector(productSelector);
  const [selectTime, setSelectTime] = useState('');
  const [paymentModal, setPaymentModal] = useState(false);
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);
  console.log('Auth', auth);
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const fetchPaymentSheetParams = async () => {
      console.log('auth token', auth.token);
      const { data: res } = await axios.post(
        `${baseUrl}/api/payments/v1/p-intent`,
        {
          amount: 110,
          customerId: undefined, // get customer id from shared pref, if not found send undefined
        },
        { headers: { Authorization: auth.token } }
      );

      setClientSecret(res.results);
      console.log('res.result', res.results);
    };
    fetchPaymentSheetParams();
  }, []);

  useEffect(() => {
    setGalleryImages([]);
    product?.gallery?.forEach((item) => {
      const imgSrc = buildUrlToSize(item, PhotoSize.Large);
      console.log(imgSrc);
      setGalleryImages((prevState) => [
        ...prevState,
        {
          original: imgSrc,
          thumbnail: imgSrc,
          thumbnailHeight: '176',
          thumbnailWidth: '190',
        },
      ]);
    });
  }, [product?.gallery]);
  useEffect(() => {
    console.log('Images', galleryImages);
  }, [galleryImages]);

  console.log('Products', product);

  let [popUpImage, setpopUpImage] = useState({
    status: false,
    value: null,
  });
  let modalCondition = (imageUrl) => {
    setpopUpImage((state) => ({ status: !state.status, value: imageUrl }));
  };
  const Days = parseInt(product.duration / 1440);
  const Hours = parseInt((product.duration % 1440) / 60);
  const Minutes = parseInt(((product.duration % 1440) % 60) % 60);

  const [reviewsModalState, setReviewsModalSate] = useState(false);
  const [paymentCompleteModal, setPaymentCompleteModal] = useState(false);

  const reviewsModalHandle = () => {
    setReviewsModalSate((prevState) => !prevState);
  };

  const paymentModalHandle = () => {
    setPaymentModal((prevState) => !prevState);
  };

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = 'hidden';
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = 'scroll';
  };

  useEffect(() => {
    dispatch(
      getActiveActivitiesOfUser({
        data: {
          from: 0,
          size: 10,
          filter: JourneyFilter.Current,
        },
        token: auth.token,
        id: auth?.user?.id,
      })
    );
  }, []);
  let reviews = [];
  if (product.reviews) {
    reviews = product.reviews.map((item) => {
      return {
        review: item._source,
        user: item.inner_hits.user.hits.hits[0]._source,
      };
    });
  }

  useEffect(() => {}, [calendar]);

  const paymentCompleteModalHandle = () => {
    setPaymentCompleteModal((prevState) => !prevState);
  };
  return (
    <>
      <Seo
        title={`${product?.name?.slice(0, 15)}... | Service Details`}
        description=''
        type='webapp'
        name='jrnyfy crop.'
      />
      <div className='flex-1 max-w-[750px]'>
        <div className='space-y-8'>
          <div className='border-r pr-10 space-y-8'>
            <h3 className='text-3xl font-bold dark:text-white'>
              {product.name}
            </h3>
            <div className='flex flex-col space-y-5'>
              <div className='text-center w-fit flex items-center gap-10 ml-5'>
                <div className='flex items-center gap-3'>
                  <div className='flex flex-col'>
                    {product.rating > 1 ? (
                      <div className='flex items-center'>
                        <ReactStars
                          classNames='leading-[25px]'
                          count={5}
                          size={25}
                          edit={false}
                          value={product.rating}
                          isHalf={true}
                          emptyIcon={<i className='far fa-star'></i>}
                          halfIcon={<i className='fa fa-star-half-alt'></i>}
                          fullIcon={<i className='fa fa-star'></i>}
                          activeColor='#ffd700'
                        />
                        <span className='mt-1 ml-2'>
                          {product.ratingCount} Reviews
                        </span>
                      </div>
                    ) : (
                      <span>No Ratings Yet</span>
                    )}
                  </div>
                </div>
                <span>
                  {product.city},{product.country}
                </span>
              </div>
              <div>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={15}
                  freeMode={true}
                  modules={[FreeMode, Navigation, Pagination]}
                  navigation
                  pagination={{ clickable: true }}
                >
                  {product?.gallery &&
                    product.gallery.map((val, ind) => (
                      <SwiperSlide key={ind}>
                        <img
                          onClick={() => {
                            setGalleryCurrentIndex(ind);
                            openImageGallery();
                          }}
                          src={buildUrlToSize(val, PhotoSize.Large)}
                          alt='places'
                          className='w-[300px] rounded-[39px] h-[200px]  cursor-pointer object-cover'
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            {showHideTimeStatus && (
              <div className='space-y-5'>
                <h5 className='text-xl font-bold text-gray-600'>
                  Select a timeslot
                </h5>
                <div className='flex items-center flex-wrap pl-3 gap-3'>
                  <div className='flex gap-2 flex-wrap'>
                    {calendar?.timeSlots &&
                      calendar.timeSlots.map((item) => {
                        return (
                          <button
                            onClick={() => setSelectTime(item)}
                            className={`
                          ${
                            item.expired || item.spots <= 0
                              ? 'cursor-not-allowed disabled:opacity-30 bg-jrnyfy-textprimary'
                              : ''
                          }
                          ${
                            selectTime == item
                              ? 'bg-jrnyfy-themeColor text-white'
                              : 'border-2 border-jrnyfy-themeColor text-jrnyfy-themeColor'
                          } p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro`}
                            disabled={item.expired}
                          >
                            {`${
                              convertIntTo12HourFormat(item.startTime).hour
                            }:${
                              convertIntTo12HourFormat(item.startTime).minute
                            } ${convertIntTo12HourFormat(item.startTime).amPm}`}
                          </button>
                        );
                      })}
                  </div>
                  <div>
                    <button
                      onClick={paymentModalHandle}
                      type='button'
                      className={`${
                        selectTime
                          ? 'cursor-pointer bg-jrnyfy-themeColor hover:bg-jrnyfy-textprimary'
                          : 'cursor-not-allowed disabled:opacity-30 bg-jrnyfy-textprimary'
                      } p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro`}
                      disabled={!selectTime}
                    >
                      book now
                    </button>
                  </div>
                </div>
              </div>
            )}

            {paymentModal && (
              <ServicePaymentPopUp
                selectedDate={selectedDate}
                selectedPeople={selectedPeople}
                paymentCompleteModalHandle={paymentCompleteModalHandle}
                selectTime={selectTime}
                product={product}
                clientSecret={clientSecret}
                activeActivities={activeActivities}
                paymentModal={paymentModal}
                paymentModalHandle={paymentModalHandle}
              />
            )}
            <CompletePaymentModal
              paymentCompleteModal={paymentCompleteModal}
              paymentCompleteModalHandle={paymentCompleteModalHandle}
            />
            {product.description && (
              <div className='space-y-3'>
                <h5 className='text-xl font-bold text-gray-600'>Overview</h5>
                <p className='pl-3 text-gray-500'>
                  {product.description}. <Link>Read More...</Link>
                </p>
              </div>
            )}
            {(product.freeCancellation || product.duration) && (
              <div className='border-b border-t py-6 pl-5'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center justify-between gap-10'>
                    {product.duration && (
                      <div className='flex items-center gap-2'>
                        <img src={clock} alt='service-time' />
                        <span>
                          {Days > 0 && Days + ' Days '}
                          {Hours > 0 && Hours + ' Hours '}
                          {Minutes > 0 && Minutes + ' Minutes'}
                        </span>
                      </div>
                    )}
                    {product.freeCancellation ? (
                      <div className='flex items-center gap-2'>
                        <img src={checkCircle} alt='check-circle' />
                        <span>Free Cancelation</span>
                      </div>
                    ) : (
                      <div>
                        {product.cancellationCharges &&
                          product.cancellationCharges.map((item) => {
                            return (
                              <p>
                                if customer cancel before {item.days} days,
                                {item.percentage}% will be refunded
                              </p>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {product.tasks?.length > 0 && (
            <div className='space-y-3'>
              <h5 className='text-xl font-bold text-gray-600'>
                Milestones you will achieve
              </h5>
              <div className='space-y-5 ml-10 relative'>
                <div className='absolute border-l-4 left-[33px] h-[100%] z-[-1]'></div>
                {product.tasks &&
                  product.tasks.map((item) => {
                    return (
                      <div className='flex items-center gap-3'>
                        {item.thumbnail ? (
                          <img
                            src={achievement}
                            alt='userThumb'
                            className='rounded-[50%] border-4 w-[70px] h-[70px]'
                          />
                        ) : (
                          <div className=' w-[70px] h-[35px] flex items-center justify-center'>
                            <div className='w-[30px] h-[30px] rounded-[50%] bg-gray-200 border border-jrnyfy-textprimary'></div>
                          </div>
                        )}
                        <span>{item.description}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {reviews.length > 0 && (
            <div className='space-y-5 pb-10'>
              <h5 className='text-xl font-bold text-gray-600'>Reviews</h5>
              <div className='ml-10'>
                {reviews.map((item) => {
                  return (
                    <div className='flex gap-5'>
                      {item.user.thumbnail === undefined ? (
                        <div className='shrink-0 flex items-center justify-center text-center text-[22px] bg-jrnyfy-themeColor font-SourceSansPro italic rounded-[50%] text-white border-4 w-[70px] h-[70px]'>
                          {getUserThumbnailFromName(item.user)}
                        </div>
                      ) : (
                        <img
                          src={buildUrlToSize(
                            item.user.thumbnail,
                            PhotoSize.Large
                          )}
                          className='rounded-[50%] border-4 w-[70px] h-[70px]'
                          alt={getUserThumbnailFromName(item.user)}
                        />
                      )}
                      <div>
                        <div className='pt-[15px] text-[18px] text-jrnyfy-title'>
                          {item.user.name}
                        </div>
                        <div className='flex flex-col gap-1'>
                          <ReactStars
                            classNames='leading-[20px]'
                            count={5}
                            size={20}
                            edit={false}
                            value={item.review.rating}
                            isHalf={true}
                            emptyIcon={<i className='far fa-star'></i>}
                            halfIcon={<i className='fa fa-star-half-alt'></i>}
                            fullIcon={<i className='fa fa-star'></i>}
                            activeColor='#ffd700'
                          />
                          <span className='text-[11px] text-jrnyfy-textprimary leading-[11px]'>
                            Posted on{' '}
                            <Moment format='D MMM YYYY'>
                              {item.user.createdAt}
                            </Moment>
                          </span>
                        </div>
                        <p className='mt-2'>{item.review.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              {product.ratingCount > 0 && (
                <div className='flex justify-center'>
                  <button
                    onClick={reviewsModalHandle}
                    type='button'
                    className='cursor-pointer border border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor  p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro'
                  >
                    show more reviews
                  </button>
                  <ReviewsModal
                    product={product}
                    reviewsModalState={reviewsModalState}
                    reviewsModalHandle={reviewsModalHandle}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {showImageGallery && (
          <div className='fixed flex items-center bg-black top-0 left-0 right-0 bottom-0 m-auto z-40 '>
            <button onClick={closeImageGallery}>
              <img
                src={cancelBtnSvg}
                alt='cross'
                className='cursor-pointer w-[25px] absolute top-[20px] left-[25px] z-50'
              />
            </button>
            <ImageGallery
              startIndex={galleryCurrentIndex}
              showIndex={true}
              items={galleryImages}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ServiceDetails;
