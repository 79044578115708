import { buildUrlToSize } from '../../utils/build-image-url';
import mapIcon from '../../assets/images/location-pin-svgrepo-com.svg';
import { PhotoSize } from '../utils/subjects';

const Places = ({ data, tagSearchTextHandle, tagSearchText, searchedText }) => {
  let thumb = buildUrlToSize(data._source.thumbnail, PhotoSize.Medium);
  return (
    <div
      onClick={() => {
        if (tagSearchTextHandle) {
          tagSearchTextHandle({ ...data._source, place: true }, true);
        }
      }}
      className='w-[50px] text-center mr-5 mb-4 cursor-pointer hover:scale-[1.1] transition-all'
    >
      <img
        src={thumb ? thumb : mapIcon}
        alt='Places'
        className={`${!thumb && 'p-2'} w-[50px] h-[50px] rounded-[50px] ${searchedText == data._source.name
          ? 'border-4 border-jrnyfy-themeColor'
          : ''
          }`}
      />
      <p
        className={`${searchedText == data._source.name
          ? 'text-jrnyfy-themeColor'
          : ''
          } text-[9px] font-semibold leading-[11px] break-words`}
      >
        {data._source.name}
      </p>
    </div>
  );
};

export default Places;
