import { buildUrlToSize } from '../../utils/build-image-url';
import { useDispatch, useSelector } from 'react-redux';
import {
  signout,
  authSelector,
  logOut,
} from '../../redux/slices/mobile/authSlice';
import UserProfile from '../userProfile/userProfile';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/slices/mobile/app';
import { getUserNavOption } from '../../utils/get-nav-option';
import { PhotoSize } from '../utils/subjects';
import Thumbnail from '../thumbnails/Thumbnail';
import { socketGlobal } from '../../socket/socket';

const UserPopUpInfo = ({ data, modalcontrol }) => {
  let dispatch = useDispatch();
  let authinfo = useSelector(authSelector).auth;
  let navigate = useNavigate();

  const signOut = () => {
    socketGlobal.disconnect();
    navigate('/');
    dispatch(logOut());
  };

  return (
    <div className='text-center'>
      <div className='flex justify-center items-center w-[70px] h-[70px] mx-auto rounded-[50%] bg-jrnyfy-themeColor text-white text-center text-[40px]'>
        <Thumbnail
          nameIconWordCount={1}
          ShowName={false}
          btnFollow={false}
          data={data?.user}
          PhotoSize={PhotoSize.Large}
          thumbnailContainerStyle={'!w-[70px] !h-[70px] !text-[50px]'}
        />
      </div>

      <p
        className='text-[30px] font-bold hover:underline cursor-pointer'
        onClick={() => {
          navigate(getUserNavOption(authinfo.user));
          modalcontrol();
        }}
      >
        {data?.user?.name}
      </p>
      <button
        className='bg-jrnyfy-border rounded-[50px] mt-[40px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
        onClick={signOut}
      >
        sign out
      </button>
    </div>
  );
};

export default UserPopUpInfo;
