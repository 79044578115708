import React, { useEffect, useState } from 'react';
import Thumbnail from '../thumbnails/Thumbnail';
import {
  muteMember,
  removeMember,
} from '../../redux/slices/mobile/messagingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import Popup from 'reactjs-popup';
import { PhotoSize } from '../utils/subjects';
import Cross from '../../assets/images/xmark-solid.svg';

const MembersModal = ({
  showMembersModal,
  showMembersModalHandle,
  chatMembers,
  selectedChat,
}) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [admin, setAdmin] = useState();
  console.log('Chat selectedChat', selectedChat);
  useEffect(() => {
    setAdmin(
      selectedChat?.members?.find((item) => item.id == selectedChat?.adminId)
    );
  }, []);
  console.log('Adminset', admin);
  return (
    <Popup
      open={showMembersModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        maxHeight: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='p-5 w-[500px] h-[500px] bg-white rounded-3xl max-[600px]:h-screen  max-[600px]:rounded-none '>
        <div className='h-fit'>
          <img
            src={Cross}
            alt='back-btn'
            className='cursor-pointer w-[15px] '
            onClick={() => showMembersModalHandle()}
          />
        </div>
        <div className='space-y-5 h-full overflow-y-scroll'>
          <div className='flex items-center justify-between'>
            <div className='flex items-start gap-3'>
              <Thumbnail
                btnFollow={false}
                data={admin}
                PhotoSize={PhotoSize.Small}
              />
              <div>
                <p className='max-[500px]:text-[14px] min-[501px]:text-jSecTitle font-semibold md:overflow-hidden md:whitespace-nowrap md:text-ellipsis'>
                  {admin?.name}
                </p>

                <p>Admin</p>
              </div>
            </div>
          </div>
          {chatMembers?.map((item) => {
            return (
              <div className='flex items-center justify-between'>
                <div className='flex items-start gap-3'>
                  <Thumbnail
                    btnFollow={false}
                    data={item}
                    PhotoSize={PhotoSize.Small}
                  />
                  <div>
                    <p className='max-[500px]:text-[14px] min-[501px]:text-jSecTitle font-semibold md:overflow-hidden md:whitespace-nowrap md:text-ellipsis'>
                      {item.name}
                    </p>

                    <p>Member</p>
                  </div>
                </div>
                <div className='space-x-1'>
                  {selectedChat?.adminId == auth?.user?.id && (
                    <button
                      onClick={() => {
                        dispatch(
                          removeMember({
                            data: {
                              chatId: selectedChat.id,
                              sk: selectedChat.sk,
                              createdAt: selectedChat.createdAt,
                              userId: item.id,
                              createdAtUser: item.createdAt,
                              skUser: item.sk,
                            },
                            token: auth.token,
                          })
                        );
                      }}
                      className='cursor-pointer border bg-white border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor p-[3px_10px] italic rounded-[50px] text-jrnyfy-themeColor text-[14px] hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro'
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Popup>
  );
};

export default MembersModal;
