import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  tagSelector,
  getTopTags,
  getTopPlaces,
  getTopLocations,
} from '../../redux/slices/web/topTagSlice';
import { buildUrlToSize } from '../../utils/build-image-url';
import Persons from '../Persons/Persons';
import Places from '../Places/Places';
import TopInspirers from './TopInspirers';
import {
  ElasticSortOrder,
  ElasticTagSortField,
  TagType,
} from '../utils/subjects';

const Aside = ({ tagSearchText, tagSearchTextHandle, searchedText }) => {
  let dispatch = useDispatch();
  let user = useSelector((state) => state.auth.users);
  let topTags = useSelector(tagSelector);
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getTopTags({
        data: {
          from: 0,
          size: 10,
          sortField: ElasticTagSortField.UsedCount,
          sortOrder: ElasticSortOrder.Descending
        },
      })
    );

    dispatch(
      getTopPlaces({
        data: {
          from: 0,
          size: 10,
          sortField: ElasticTagSortField.UsedCount,
          sortOrder: ElasticSortOrder.Descending,
          typeFilter: TagType.Place,
        },
      })
    );
    // dispatch(
    //   getTopLocations({
    //     data: {
    //       from: 0,
    //       size: 10,
    //     },
    //   })
    // );
  }, []);

  console.log('TopTagsLit', topTags, tagSearchText);

  return (
    <div className='hidden md:block max-w-[270px] ml-auto h-full max-h-screen sticky top-0 pt-[127px]'>
      <div className='h-full overflow-auto scrollbar'>
        <TopInspirers />
        <p className='text-jSecTitle font-semibold'>
          <Link to='/top-things'>top journey ideas</Link>
        </p>
        <div className='flex px-2 py-3 flex-wrap'>
          {topTags.ideas.length > 0
            ? topTags.ideas
              .map((val, ind) => (
                <p
                  key={ind}
                  onClick={() => {
                    if (tagSearchTextHandle) {
                      tagSearchTextHandle(val._source);
                    }
                  }}
                  className={`${searchedText == val._source.name
                    ? 'bg-jrnyfy-themeColor text-white'
                    : 'bg-white text-jrnyfy-textprimary'
                    } cursor-pointer border-2 border-solid border-jrnyfy-border rounded-[50px] p-[5px_10px] m-[3px]  text-[11px] font-bold`}
                >
                  {val._source.name}
                </p>
              ))
              .slice(0, 10)
            : ''}
        </div>
        <p className='text-jSecTitle font-semibold'>
          <Link to='top-places'>top journey places</Link>
        </p>
        <div className='flex flex-wrap px-2 py-3'>
          {topTags.places.length > 0
            ? topTags.places
              .map((val, ind) => (
                <Places
                  searchedText={searchedText}
                  tagSearchTextHandle={tagSearchTextHandle}
                  tagSearchText={tagSearchText}
                  key={ind}
                  data={val}
                />
              ))
              .slice(0, 9)
            : ''}
        </div>
      </div>
    </div>
  );
};

export default Aside;
