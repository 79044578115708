import React, { useContext, useState } from "react";
import Popup from "reactjs-popup";
import FunctionalButton from "../FunctionalButton/FunctionalButton";
import Cross from "../../assets/images/xmark-solid.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  activitySelector,
  removeTimelineItem,
} from "../../redux/slices/mobile/activitySlice";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { timelineDataContext } from "../jrnyTimline/JrnyTimline";
import backBtn from "../../assets/icons/back.png";

const TimelineOptionsModal = ({
  index,
  data,
  deleteModal,
  deleteModalHandle,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const { activity } = useSelector(activitySelector);
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  const showDeleteHandle = (action) => {
    setShowDelete(action);
  };

  const { timeLineContent, setTimelineContent } =
    useContext(timelineDataContext);
  let tempTimelineContent = [...timeLineContent];
  const removeTimelineItemHandle = () => {
    setActionStates({ ...actionStates, loading: true });

    dispatch(
      removeTimelineItem({
        data: { sk: data?.sk, createdAt: data?.createdAt, id: data?.id },
        id: activity?.id,
        token: auth?.token,
      })
    ).then((res) => {
      console.log("Response", res.meta.requestStatus);
      tempTimelineContent.splice(index, 1);
      setTimelineContent(tempTimelineContent);
      if (res.meta.requestStatus == "fulfilled") {
        setActionStates({ ...actionStates, loading: false, fulfilled: true });
        deleteModalHandle(false);
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
      if (res.meta.requestStatus == "rejected") {
        setActionStates({ ...actionStates, loading: false, rejected: true });
        deleteModalHandle(false);
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };

  return (
    <Popup
      open={deleteModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: "100%",
        height: "100vh",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      <div className="space-y-5 relative bg-white w-[400px] rounded-[20px] p-10  max-[500px]:p-5 max-[500px]:h-screen max-[500px]:w-full  max-[500px]:overflow-y-scroll max-[500px]:rounded-none">
        <div className="flex items-center gap-3">
          <img
            src={showDelete ? backBtn : Cross}
            alt="cross"
            className={`cursor-pointer ${
              showDelete ? " w-[10px]" : " w-[20px]"
            } `}
            onClick={() =>
              showDelete ? showDeleteHandle(false) : deleteModalHandle(false)
            }
          />

          <p className="text-jrnyfy-textprimary font-semibold w-full text-center">
            Timeline Options
          </p>
        </div>

        <div className="text-center space-y-3">
          <button
            onClick={() => showDeleteHandle(true)}
            className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            ASSIGN IT
          </button>
          <button
            onClick={() => showDeleteHandle(true)}
            className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            EDIT
          </button>
          <button
            onClick={() => showDeleteHandle(true)}
            className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            ADD A DUE DATE
          </button>
          <button
            onClick={() => showDeleteHandle(true)}
            className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            MARK COMPLETE
          </button>
          <button
            onClick={() => showDeleteHandle(true)}
            className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            Remove
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default TimelineOptionsModal;
