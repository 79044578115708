import React, { useEffect, useRef, useState } from 'react';
import ChatList from './ChatList';
import StartConversationModal from './StartConversationModal';
import {
  getChats,
  getMessages,
  messageSelector,
  resetMessages,
} from '../../redux/slices/mobile/messagingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import SendMessages from './SendMessages';
import Seo from '../Seo/Seo';
import { useLocation, useNavigate } from 'react-router-dom';

const Messenger = () => {
  const { state } = useLocation();
  const [showStartConversationList, setShowConversationList] = useState();
  const [selectedChat, setSelectedChat] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [showChatRbSheet, setShowChatRbSheet] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isCalling, setIsCalling] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.innerWidth <= 760
  );
  const { auth, users } = useSelector(authSelector);
  const { chats } = useSelector(messageSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkScreenSize = window.innerWidth <= 760;
    setIsMobileScreen(checkScreenSize);
    if (!checkScreenSize) {
      setShowChatRbSheet(true);
    }
    console.log('Current WIdht', showChatRbSheet);
  }, []);

  const showStartConversationHandle = (action) => {
    if (action) {
      window.history.pushState(null, '', location.pathname);
    } else {
      navigate(-1);
    }
    setShowConversationList(action);
  };

  useEffect(() => {
    dispatch(getChats({ token: auth.token })).then((res) => {
      console.log('ChatFirst', res);
      if (!state) {
        getMessageHandle(res?.payload?.chats[0]);
      } else {
        getMessageHandle(
          res?.payload?.chats?.find((item) => item.id == state?.id)
        );
        showChatRbSheetHandle(true);
      }
    });
  }, []);

  const showChatRbSheetHandle = (action) => {
    if (isMobileScreen) {
      console.log('Selected ChatItems mobile', showChatRbSheet);
      setShowChatRbSheet(action);
    }

    if (action) {
      //document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  };

  const handleGetMessages = (item) => {
    dispatch(
      getMessages({
        data: {
          from: currentPage,
          size: 30,
          chat: item?.id
            ? {
                id: item?.id,
                sk: item?.sk,
                createdAt: item?.createdAt,
              }
            : undefined,
          members: item.members,
        },
        callback: (res) => {
          setIsCalling(false);

          if (res.messages.length < 30) {
            console.log('GetMessagesLeng', res.messages.length);
            setIsEnd(true);
          }
        },
        token: auth.token,
      })
    );
  };

  useEffect(() => {
    if (currentPage >= 30) {
      handleGetMessages(selectedChat);
    }
  }, [currentPage]);

  const getMessageHandle = (item) => {
    console.log('Selected ChatItems', item);
    setIsEnd(false);
    setSelectedChat(item);
    handleGetMessages(item);
    dispatch(resetMessages());
  };
  window.addEventListener('popstate', () => {
    if (showStartConversationList) {
      // navigate(null, { replace: true });
      setShowConversationList(false);
    }
  });

  useEffect(() => {
    console.log('Statedddddd', state);
    // if (chats?.chats?.length > 0 && state) {
    //   getMessageHandle(chats?.chats?.find((item) => item.id == state?.id));

    //   // if (item.id == state.id) {
    //   //   setSelectedChat(chats?.chats[index]);
    //   //   return false;
    //   //   // }
    //   // });
    // }
  }, [state]);

  return (
    <>
      <Seo
        title='Messenger | chat with your friends'
        description=''
        type='webapp'
        name='jrnyfy crop.'
        canonicalHref='/messenger'
      />
      <div
        style={{ height: window?.innerHeight - 177 }}
        className='max-w-[1073px] mx-auto px-5 bg-red'
      >
        <div className='flex max-[1050px]:gap-5cd mt-5 h-full'>
          <div className='w-[400px] space-y-3 flex flex-col items-stretch content-stretch h-full'>
            <div>
              <button
                onClick={() => showStartConversationHandle(true)}
                type='button'
                className={`text-white bg-jrnyfy-themeColor  p-[3px_10px] italic rounded-[50px] font-semibold text-jSecTitle border border-jrnyfy-themeColor hover:bg-white  hover:text-jrnyfy-themeColor hover:scale-[1.1] max-[767px]:font-normal max-[767px]:text-[14px] transition-all font-SourceSansPro`}
              >
                START A CONVERSATION
              </button>
              <p>My Chats</p>
            </div>
            <div className='flex-grow overflow-y-auto pr-5'>
              <ChatList
                setIsEnd={setIsEnd}
                setSelectedChat={setSelectedChat}
                selectedChat={selectedChat}
                showChatRbSheetHandle={showChatRbSheetHandle}
                getMessageHandle={getMessageHandle}
              />
            </div>
          </div>
          {showChatRbSheet && (
            <div className='w-full h-full'>
              <SendMessages
                isCalling={isCalling}
                setIsCalling={setIsCalling}
                isEnd={isEnd}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setSelectedChat={setSelectedChat}
                selectedChat={selectedChat}
                showChatRbSheetHandle={showChatRbSheetHandle}
                isMobileScreen={isMobileScreen}
              />
            </div>
          )}
        </div>
        <StartConversationModal
          showStartConversationList={showStartConversationList}
          showStartConversationHandle={showStartConversationHandle}
          getMessageHandle={getMessageHandle}
          showChatRbSheetHandle={showChatRbSheetHandle}
        />
      </div>
    </>
  );
};

export default Messenger;
