import SpinnerIcon from '../../assets/images/spinner.svg';

const Spinner = () => {
    return (
        <div className='w-[40px] mx-auto'>
            <img src={SpinnerIcon} alt="spinner" className='animate-spin w-full' />
        </div>
    )
};

export default Spinner;