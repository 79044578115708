import React, { useEffect, useRef, useState } from 'react';
import Thumbnail from '../thumbnails/Thumbnail';
import { buildUrlToSize } from '../../utils/build-image-url';
import {
  MessageType,
  MessageUpdateRequestStatus,
  PhotoSize,
} from '../utils/subjects';
import Option from '../../assets/images/ellipsis-vertical-solid.svg';
import Icons from '../Icons/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import {
  deleteMessage,
  editMessage,
} from '../../redux/slices/mobile/messagingSlice';

import autosize from 'autosize';
import ImageGalleryRenderer from '../ImageGallery/ImageGalleryRenderer';
const SendMessageLayout = ({ item, chat }) => {
  const [messageOptions, setMessageOptions] = useState();
  const { auth } = useSelector(authSelector);
  const [owner, setOwner] = useState();
  const messageOptionRef = useRef();
  const dispatch = useDispatch();
  const [showMessageEditor, setShowMessageEditor] = useState(false);
  const [editedMessage, setEditedMessage] = useState({});
  const messageEditorRef = useRef();
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);
  const [mobileOptions, setMobileOptions] = useState();
  const pressTimeRef = useRef();
  const mobileOptionsRef = useRef();

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = 'hidden';
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = 'scroll';
  };

  const removeMessage = () => {
    setMessageOptions(false);
    setMobileOptions(false);
    dispatch(
      deleteMessage({
        data: {
          status: MessageUpdateRequestStatus.Deleted,
          sk: item.sk,
          createdAt: item.createdAt,
        },
        id: item.id,
        token: auth.token,
      })
    );
  };
  useEffect(() => {
    if (chat) {
      const owner = chat.members.find((member) => member.id == item.owner.id);
      setOwner(owner);
    }
  }, [chat]);
  console.log('Owner Details', owner);
  const messageOptionsHandle = () => {
    setMessageOptions((prevState) => !prevState);
  };

  useEffect(() => {
    const hideModalOutSideClickHandle = (e) => {
      if (!messageOptionRef?.current?.contains(e.target)) {
        setMessageOptions(false);
      }
      if (!mobileOptionsRef?.current?.contains(e.target)) {
        setMobileOptions(false);
      }
    };
    document.addEventListener('mousedown', hideModalOutSideClickHandle);
  });

  const updateMessageText = () => {
    console.log('Clickeddddddd');
    dispatch(
      editMessage({
        data: {
          status: MessageUpdateRequestStatus.Edited,
          message: editedMessage,
          sk: item.sk,
          createdAt: item.createdAt,
        },
        id: item.id,
        token: auth.token,
      })
    );
  };

  useEffect(() => {
    autosize(messageEditorRef);
  }, [editedMessage]);

  const handleOnMouseUp = () => {
    console.log('mouseUp');
    clearTimeout(pressTimeRef.current);
  };
  const handleOnMouseDown = () => {
    console.log('mouseDown');
    startPress();
  };
  const handleOnTouchStart = () => {
    console.log('TouchUp');
    startPress();
  };
  const handleOnTouchEnd = () => {
    console.log('TouchDown');
    clearTimeout(pressTimeRef.current);
  };

  const startPress = () => {
    console.log('Loog PressCalled');
    pressTimeRef.current = setTimeout(() => {
      console.log('Loog Press');
      setMobileOptions(true);
    }, 500);
  };
  console.log('NewSentMessage', item)
  return (
    <div key={item?.id} className='space-y-3'>
      <div className='group flex items-center justify-start gap-3'>
        <Thumbnail btnFollow={false} data={owner} PhotoSize={PhotoSize.Small} />
        <div>
          {item.type == MessageType.Text &&
            (!showMessageEditor ? (
              <button
                onmouseup={handleOnMouseUp}
                onmousedown={handleOnMouseDown}
                onTouchStart={handleOnTouchStart}
                onTouchEnd={handleOnTouchEnd}
                className='text-[14px] text-white  bg-jrnyfy-themeColor p-[8px_10px] rounded-[18px] select-none'
              >
                {item.message}
              </button>
            ) : (
              <div className='bg-jrnyfy-colorLightGray rounded-md p-2'>
                <textarea
                  ref={messageEditorRef}
                  spellCheck='true'
                  onChange={(e) => setEditedMessage(e.target.value)}
                  className=' bg-jrnyfy-colorLightGray w-full text-[14px] text-jrnyfy-textprimary font-Montserrat focus:outline-none'
                  value={editedMessage}
                />
                <div className='flex gap-3'>
                  <button
                    className='text-white text-[12px] bg-jrnyfy-themeColor rounded-md p-[2px_10px] font-SourceSansPro'
                    onClick={() => setShowMessageEditor(false)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      setShowMessageEditor();
                      updateMessageText();
                    }}
                    className='text-white text-[12px] bg-jrnyfy-themeColor rounded-md p-[2px_10px] font-SourceSansPro'
                  >
                    Save
                  </button>
                </div>
              </div>
            ))}
          <div
            onmouseup={handleOnMouseUp}
            onmousedown={handleOnMouseDown}
            onTouchStart={handleOnTouchStart}
            onTouchEnd={handleOnTouchEnd}
            className='flex flex-wrap gap-3 max-[550px]:gap-1 select-none relative'
          >
            {item.type == MessageType.Photo &&
              item.photos.map((photo, ind) => {
                return (
                  <img
                    onClick={() => {
                      setGalleryCurrentIndex(ind);
                      openImageGallery();
                    }}
                    src={buildUrlToSize(photo, PhotoSize.Medium)}
                    alt='chat-picture'
                    className='w-[120px] h-[120px] max-[550px]:w-[80px] max-[550px]:h-[80px]  rounded-xl object-cover'
                  />
                );
              })}

            {item.uploading && (
              <div className='absolute w-full h-full flex justify-center items-center bg-black opacity-40 rounded-xl'>
                <div>
                  <div className='spinner'></div>
                </div>
              </div>
            )}
          </div>
          <div>
            <p className='text-[12px] text-end text-jrnyfy-textprimary'>{item.uploading ? 'sending...' : 'sent'}</p>
          </div>
        </div>
        <div className=' relative'>
          <div
            className={`${messageOptions ? 'visible' : 'invisible'
              } group-hover:visible`}
          >
            <Icons
              icon={Option}
              alt='Option'
              width='10px'
              iconClick={messageOptionsHandle}
            />
          </div>
          {messageOptions && (
            <div
              ref={messageOptionRef}
              className='p-2 w-[150px] absolute bottom-10 right-0 flex items-start flex-col bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg z-10'
            >
              <button
                className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'
                onClick={removeMessage}
              >
                Remove
              </button>
              {/* <button className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'>
                Forward
              </button> */}
              <button
                onClick={() => {
                  setEditedMessage(item.message)
                  setMessageOptions(false);
                  setShowMessageEditor(true);
                }}
                className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'
              >
                Edit
              </button>
              <div class='w-0 h-0 absolute -bottom-[19px] right-[18px] border-t-[20px] border-t-transparent border-r-[30px] border-r-white border-b-[20px] border-b-transparent'></div>
            </div>
          )}
        </div>
        {mobileOptions && (
          <div
            ref={mobileOptionsRef}
            className='absolute bottom-0 left-0 right-0 m-auto rounded-t-3xl bg-white z-20 w-full p-5 border space-y-3 max-w-[600px]'
          >
            <button
              className='w-full text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md z-40'
              onClick={removeMessage}
            >
              Remove
            </button>
            {/* <button className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'>
                Forward
              </button> */}
            <button
              onClick={() => {
                setEditedMessage(item.message)
                setMessageOptions(false);
                setShowMessageEditor(true);
                setMobileOptions(false);
              }}
              className='w-full text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md z-40'
            >
              Edit
            </button>
          </div>
        )}
      </div>

      {item?.nextDate && (
        <p className='text-[12px] text-jrnyfy-textprimary text-center'>
          {item?.nextDate}
        </p>
      )}

      {showImageGallery && (
        <ImageGalleryRenderer
          closeImageGallery={closeImageGallery}
          galleryCurrentIndex={galleryCurrentIndex}
          setGalleryCurrentIndex={setGalleryCurrentIndex}
          photos={item?.photos}
        />
      )}
    </div>
  );
};

export default SendMessageLayout;
