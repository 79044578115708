import React from "react";
import { Link } from "react-router-dom";
import Seo from "../components/Seo/Seo";

const PrivacyPolilcy = () => {
  return (
    <>
      <Seo
        title="Trending Journeys"
        description="Discover incredible places to see and amazing things to do"
        type="webapp"
        name="jrnyfy crop"
        canonicalHref="/privacy-policy"
      />
      <main className="pt-4">
        <div className="w-[90%] min-[1100px]:w-[75%] mx-auto flex justify-center min-[3000px]:w-1/2">
          <div className="w-full">
            <section className="mb-10">
              <div className="mb-10">
                <h3 class="text-3xl font-bold dark:text-white mb-5">
                  PRIVACY POLICY
                </h3>
                <span>Last updated March 25, 2023</span>
              </div>
              <div>
                <p className="mb-5">
                  This privacy notice for Jrnyfy Corp. ("
                  <span className="font-bold">Company</span>," "
                  <span className="font-bold">we</span>," "
                  <span className="font-bold">us</span>," or "
                  <span className="font-bold">our</span>"), describes how and
                  why we might collect, store, use, and/or share ("
                  <span className="font-bold">process</span>") your information
                  when you use our services ("
                  <span className="font-bold">Services</span>"), such as when
                  you:
                </p>
                <ul className="list-disc ml-10">
                  <li className="mb-5">
                    Visit our website at jrnyfy.com, or any website of ours that
                    links to this privacy notice
                  </li>
                  <li className="mb-5">
                    Download and use our mobile application (Jrnyfy), or any
                    other application of ours that links to this privacy notice
                  </li>
                  <li className="mb-5">
                    Engage with us in other related ways, including any sales,
                    marketing, or events
                  </li>
                </ul>
                <p>
                  <span className="font-bold">Questions or concerns?</span>{" "}
                  Reading this privacy notice will help you understand your
                  privacy rights and choices. If you do not agree with our
                  policies and practices, please do not use our Services. If you
                  still have any questions or concerns, please contact us at
                  admin@jrnyfy.com.
                </p>
              </div>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                SUMMARY OF KEY POINTS
              </h5>
              <div>
                <p className="italic font-bold mb-5">
                  This summary provides key points from our privacy notice, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our{" "}
                  <Link className="underline text-blue-500" to={""}>
                    table of contents
                  </Link>{" "}
                  below to find the section you are looking for.
                </p>
                <p className="mb-5">
                  <span className="font-bold">
                    What personal information do we process?
                  </span>{" "}
                  When you visit, use, or navigate our Services, we may process
                  personal information depending on how you interact with Jrnyfy
                  Corp. and the Services, the choices you make, and the products
                  and features you use. Learn more about{" "}
                  <Link className="underline text-blue-500" to={""}>
                    personal information you disclose to us
                  </Link>
                  .
                </p>
                <p className="mb-5">
                  <span className="font-bold">
                    Do we process any sensitive personal information?
                  </span>{" "}
                  We may process sensitive personal information when necessary
                  with your consent or as otherwise permitted by applicable law.
                  Learn more about
                  <Link className="underline text-blue-500" to={""}>
                    sensitive information we process
                  </Link>
                  .
                </p>
                <p className="mb-5">
                  <span className="font-bold">
                    Do we receive any information from third parties?
                  </span>{" "}
                  We may receive information from public databases, marketing
                  partners, social media platforms, and other outside sources.
                  Learn more about
                  <Link className="underline text-blue-500" to={""}>
                    information collected from other sources
                  </Link>
                  .
                </p>
                <p className="mb-5">
                  <span className="font-bold">
                    How do we process your information?
                  </span>{" "}
                  We process your information to provide, improve, and
                  administer our Services, communicate with you, for security
                  and fraud prevention, and to comply with law. We may also
                  process your information for other purposes with your consent.
                  We process your information only when we have a valid legal
                  reason to do so. Learn more about{" "}
                  <Link className="underline text-blue-500" to={""}>
                    how we process your information
                  </Link>
                  .
                </p>
                <p className="mb-5">
                  <span className="font-bold">
                    In what situations and with which parties do we share
                    personal information?
                  </span>{" "}
                  We may share information in specific situations and with
                  specific third parties. Learn more about{" "}
                  <Link className="underline text-blue-500" to={""}>
                    {" "}
                    when and with whom we share your personal information
                  </Link>
                  .
                </p>
                <p className="mb-5">
                  <span className="font-bold">
                    How do we keep your information safe?
                  </span>{" "}
                  We have organizational and technical processes and procedures
                  in place to protect your personal information. However, no
                  electronic transmission over the internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorized third parties will not be able to defeat
                  our security and improperly collect, access, steal, or modify
                  your information. Learn more about{" "}
                  <Link className="underline text-blue-500" to={""}>
                    how we keep your information safe
                  </Link>
                  .
                </p>
                <p className="mb-5">
                  <span className="font-bold">What are your rights?</span>{" "}
                  Depending on where you are located geographically, the
                  applicable privacy law may mean you have certain rights
                  regarding your personal information. Learn more about{" "}
                  <Link className="underline text-blue-500" to={""}>
                    your privacy rights
                  </Link>
                  .
                </p>
                <p className="mb-5">
                  <span className="font-bold">
                    How do you exercise your rights?
                  </span>{" "}
                  The easiest way to exercise your rights is by visiting
                  __________, or by contacting us. We will consider and act upon
                  any request in accordance with applicable data protection
                  laws.
                </p>
                <p className="mb-5">
                  <span className="font-bold">
                    Want to learn more about what Jrnyfy Corp. does with any
                    information we collect?
                  </span>{" "}
                  <Link className="underline text-blue-500" to={""}>
                    Review the privacy notice in full
                  </Link>
                  .
                </p>
              </div>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                TABLE OF CONTENTS
              </h5>
              <div>
                <ol>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      1.WHAT INFORMATION DO WE COLLECT?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      2.HOW DO WE PROCESS YOUR INFORMATION?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      3.WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                      INFORMATION?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      4.WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                      INFORMATION?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      5.WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?{" "}
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      6.DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      7.HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      8.IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      9.HOW LONG DO WE KEEP YOUR INFORMATION?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      10.HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      11.DO WE COLLECT INFORMATION FROM MINORS?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      12.WHAT ARE YOUR PRIVACY RIGHTS?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      13.CONTROLS FOR DO-NOT-TRACK FEATURES
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      14.DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      15.DO WE MAKE UPDATES TO THIS NOTICE?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      16.HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </Link>
                  </li>
                  <li>
                    <Link className="underline text-blue-500" to={""}>
                      17.HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </Link>
                  </li>
                </ol>
              </div>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                1. WHAT INFORMATION DO WE COLLECT?
              </h5>
              <h6 class="text-lg mb-5 font-bold dark:text-white">
                Personal information you disclose to us
              </h6>
              <p className="italic mb-5">
                <span className="font-bold">In Short:</span> We collect personal
                information that you provide to us.
              </p>
              <p className="mb-5">
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services, or otherwise
                when you contact us.
              </p>
              <div className="mb-5">
                <p className="mb-5">
                  Sensitive Information. When necessary, with your consent or as
                  otherwise permitted by applicable law, we process the
                  following categories of sensitive information:
                </p>
                <ul className="list-disc ml-12">
                  <li>names</li>
                  <li>phone numbers</li>
                  <li>email addresses</li>
                  <li>passwords</li>
                  <li>usernames</li>
                  <li>contact preferences</li>
                  <li>billing addresses</li>
                  <li>contact or authentication data</li>
                  <li>mailing addresses</li>
                  <li>location</li>
                </ul>
              </div>
              <div className="mb-5">
                <p className="mb-5">
                  <span className="font-bold">Sensitive Information.</span> When
                  necessary, with your consent or as otherwise permitted by
                  applicable law, we process the following categories of
                  sensitive information:
                </p>
                <ul className="list-disc ml-12">
                  <li>financial data</li>
                </ul>
              </div>
              <div className="mb-5">
                <p>
                  <span className="font-bold">Payment Data.</span> We may
                  collect data necessary to process your payment if you make
                  purchases, such as your payment instrument number, and the
                  security code associated with your payment instrument. All
                  payment data is stored by __________. You may find their
                  privacy notice link(s) here: __________.
                </p>
              </div>
              <div className="mb-5">
                <p>
                  <span className="font-bold">Social Media Login Data</span>. We
                  may provide you with the option to register with us using your
                  existing social media account details, like your Facebook,
                  Twitter, or other social media account. If you choose to
                  register in this way, we will collect the information
                  described in the section called "HOW DO WE HANDLE YOUR SOCIAL
                  LOGINS?" below.
                </p>
              </div>
              <div className="mb-5">
                <p className="mb-5">
                  <span className="font-bold">Application Data.</span> If you
                  use our application(s), we also may collect the following
                  information if you choose to provide us with access or
                  permission:
                </p>
                <ul className="list-disc ml-12">
                  <li className="mb-5">
                    <span className="font-bold">Geolocation Information.</span>{" "}
                    We may request access or permission to track location-based
                    information from your mobile device, either continuously or
                    while you are using our mobile application(s), to provide
                    certain location-based services. If you wish to change our
                    access or permissions, you may do so in your device's
                    settings.
                  </li>
                  <li className="mb-5">
                    <span className="font-bold">Push Notifications.</span> We
                    may request to send you push notifications regarding your
                    account or certain features of the application(s). If you
                    wish to opt out from receiving these types of
                    communications, you may turn them off in your device's
                    settings.
                  </li>
                </ul>
                <p className="mb-5">
                  This information is primarily needed to maintain the security
                  and operation of our application(s), for troubleshooting, and
                  for our internal analytics and reporting purposes.
                </p>
                <p className="mb-5">
                  All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.
                </p>
              </div>
              <div className="mb-5">
                <h6 class="text-lg mb-5 font-bold dark:text-white">
                  Information automatically collected
                </h6>
                <p className="italic mb-5">
                  <span className="font-bold">In Short:</span> Some information
                  — such as your Internet Protocol (IP) address and/or browser
                  and device characteristics — is collected automatically when
                  you visit our Services.
                </p>
                <p className="mb-5">
                  We automatically collect certain information when you visit,
                  use, or navigate the Services. This information does not
                  reveal your specific identity (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our Services, and other technical information. This
                  information is primarily needed to maintain the security and
                  operation of our Services, and for our internal analytics and
                  reporting purposes.
                </p>
                <p className="mb-5">
                  Like many businesses, we also collect information through
                  cookies and similar technologies.
                </p>
                <p className="mb-5">The information we collect includes:</p>
                <ul className="list-disc ml-12">
                  <li className="mb-5">
                    Log and Usage Data. Log and usage data is service-related,
                    diagnostic, usage, and performance information our servers
                    automatically collect when you access or use our Services
                    and which we record in log files. Depending on how you
                    interact with us, this log data may include your IP address,
                    device information, browser type, and settings and
                    information about your activity in the Services (such as the
                    date/time stamps associated with your usage, pages and files
                    viewed, searches, and other actions you take such as which
                    features you use), device event information (such as system
                    activity, error reports (sometimes called "crash dumps"),
                    and hardware settings).
                  </li>
                  <li className="mb-5">
                    Device Data. We collect device data such as information
                    about your computer, phone, tablet, or other device you use
                    to access the Services. Depending on the device used, this
                    device data may include information such as your IP address
                    (or proxy server), device and application identification
                    numbers, location, browser type, hardware model, Internet
                    service provider and/or mobile carrier, operating system,
                    and system configuration information.
                  </li>
                  <li className="mb-5">
                    Location Data. We collect location data such as information
                    about your device's location, which can be either precise or
                    imprecise. How much information we collect depends on the
                    type and settings of the device you use to access the
                    Services. For example, we may use GPS and other technologies
                    to collect geolocation data that tells us your current
                    location (based on your IP address). You can opt out of
                    allowing us to collect this information either by refusing
                    access to the information or by disabling your Location
                    setting on your device. However, if you choose to opt out,
                    you may not be able to use certain aspects of the Services.
                  </li>
                </ul>

                <h6 class="text-lg mb-5 font-bold dark:text-white">
                  Information collected from other sources
                </h6>
                <p className="mb-5 italic">
                  <span className="font-bold">In Short:</span> We may collect
                  limited data from public databases, marketing partners, social
                  media platforms, and other outside sources.
                </p>
                <p className="mb-5">
                  In order to enhance our ability to provide relevant marketing,
                  offers, and services to you and update our records, we may
                  obtain information about you from other sources, such as
                  public databases, joint marketing partners, affiliate
                  programs, data providers, social media platforms, and from
                  other third parties. This information includes mailing
                  addresses, job titles, email addresses, phone numbers, intent
                  data (or user behavior data), Internet Protocol (IP)
                  addresses, social media profiles, social media URLs, and
                  custom profiles, for purposes of targeted advertising and
                  event promotion. If you interact with us on a social media
                  platform using your social media account (e.g., Facebook or
                  Twitter), we receive personal information about you such as
                  your name, email address, and gender. Any personal information
                  that we collect from your social media account depends on your
                  social media account's privacy settings.
                </p>
              </div>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> We process your
                information to provide, improve, and administer our Services,
                communicate with you, for security and fraud prevention, and to
                comply with law. We may also process your information for other
                purposes with your consent.
              </p>
              <p className="mb-5">
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> We only process
                your personal information when we believe it is necessary and we
                have a valid legal reason (i.e., legal basis) to do so under
                applicable law, like with your consent, to comply with laws, to
                provide you with services to enter into or fulfill our
                contractual obligations, to protect your rights, or to fulfill
                our legitimate business interests.
              </p>
              <p className="mb-5">
                If you are located in Canada, this section applies to you.
              </p>
              <p className="mb-5">
                We may process your information if you have given us specific
                permission (i.e., express consent) to use your personal
                information for a specific purpose, or in situations where your
                permission can be inferred (i.e., implied consent). You can{" "}
                <Link className="underline text-blue-500" to={""}>
                  withdraw your consent
                </Link>{" "}
                at any time.
              </p>
              <p className="mb-5">
                In some exceptional cases, we may be legally permitted under
                applicable law to process your information without your consent,
                including, for example:
              </p>
              <div className="mb-5">
                <ul className="list-disc ml-12">
                  <li className="mb-5">
                    If collection is clearly in the interests of an individual
                    and consent cannot be obtained in a timely way
                  </li>
                  <li>For investigations and fraud detection and prevention</li>
                  <li className="mb-5">
                    For business transactions provided certain conditions are
                    met
                  </li>
                  <li className="mb-5">
                    If it is contained in a witness statement and the collection
                    is necessary to assess, process, or settle an insurance
                    claim
                  </li>
                  <li className="mb-5">
                    For identifying injured, ill, or deceased persons and
                    communicating with next of kin
                  </li>
                  <li className="mb-5">
                    If we have reasonable grounds to believe an individual has
                    been, is, or may be victim of financial abuse
                  </li>
                  <li className="mb-5">
                    If it is reasonable to expect collection and use with
                    consent would compromise the availability or the accuracy of
                    the information and the collection is reasonable for
                    purposes related to investigating a breach of an agreement
                    or a contravention of the laws of Canada or a province
                  </li>
                  <li className="mb-5">
                    If disclosure is required to comply with a subpoena,
                    warrant, court order, or rules of the court relating to the
                    production of records
                  </li>
                  <li className="mb-5">
                    If it was produced by an individual in the course of their
                    employment, business, or profession and the collection is
                    consistent with the purposes for which the information was
                    produced
                  </li>
                  <li className="mb-5">
                    If the collection is solely for journalistic, artistic, or
                    literary purposes
                  </li>
                  <li className="mb-5">
                    If the collection is solely for journalistic, artistic, or
                    literary purposes
                  </li>
                </ul>
              </div>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> We may share
                information in specific situations described in this section
                and/or with the following third parties.
              </p>
              <p className="mb-5">
                We may need to share your personal information in the following
                situations:
              </p>
              <div>
                <ul className="list-disc ml-12">
                  <li className="mb-5">
                    Business Transfers. We may share or transfer your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.
                  </li>
                  <li className="mb-5">
                    When we use Google Maps Platform APIs. We may share your
                    information with certain Google Maps Platform APIs (e.g.,
                    Google Maps API, Places API). We use certain Google Maps
                    Platform APIs to retrieve certain information when you make
                    location-specific requests. This includes: Users location;
                    and other similar information. A full list of what we use
                    information for can be found in this section and in the
                    previous section titled "
                    <Link className="underline text-blue-500">
                      HOW DO WE PROCESS YOUR INFORMATION?
                    </Link>
                    ". We obtain and store on your device ("cache") your
                    location. You may revoke your consent anytime by contacting
                    us at the contact details provided at the end of this
                    document. The Google Maps Platform APIs that we use store
                    and access cookies and other information on your devices. If
                    you are a user currently in the European Economic Area (EU
                    countries, Iceland, Liechtenstein, and Norway) or the United
                    Kingdom, please take a look at our Cookie Notice.
                  </li>
                  <li className="mb-5">
                    Affiliates. We may share your information with our
                    affiliates, in which case we will require those affiliates
                    to honor this privacy notice. Affiliates include our parent
                    company and any subsidiaries, joint venture partners, or
                    other companies that we control or that are under common
                    control with us.
                  </li>
                  <li className="mb-5">
                    Business Partners. We may share your information with our
                    business partners to offer you certain products, services,
                    or promotions.
                  </li>
                  <li className="mb-5">
                    Offer Wall. Our application(s) may display a third-party
                    hosted "offer wall." Such an offer wall allows third-party
                    advertisers to offer virtual currency, gifts, or other items
                    to users in return for the acceptance and completion of an
                    advertisement offer. Such an offer wall may appear in our
                    application(s) and be displayed to you based on certain
                    data, such as your geographic area or demographic
                    information. When you click on an offer wall, you will be
                    brought to an external website belonging to other persons
                    and will leave our application(s). A unique identifier, such
                    as your user ID, will be shared with the offer wall provider
                    in order to prevent fraud and properly credit your account
                    with the relevant reward.
                  </li>
                </ul>
              </div>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> We are not
                responsible for the safety of any information that you share
                with third parties that we may link to or who advertise on our
                Services, but are not affiliated with, our Services.
              </p>
              <p className="mb-5">
                The Services, including our offer wall, may link to third-party
                websites, online services, or mobile applications and/or contain
                advertisements from third parties that are not affiliated with
                us and which may link to other websites, services, or
                applications. Accordingly, we do not make any guarantee
                regarding any such third parties, and we will not be liable for
                any loss or damage caused by the use of such third-party
                websites, services, or applications. The inclusion of a link
                towards a third-party website, service, or application does not
                imply an endorsement by us. We cannot guarantee the safety and
                privacy of data you provide to any third parties. Any data
                collected by third parties is not covered by this privacy
                notice. We are not responsible for the content or privacy and
                security practices and policies of any third parties, including
                other websites, services, or applications that may be linked to
                or from the Services. You should review the policies of such
                third parties and contact them directly to respond to your
                questions.
              </p>
            </section>
            <section>
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> We may use cookies
                and other tracking technologies to collect and store your
                information.
              </p>
              <p className="mb-5">
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice.
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> If you choose to
                register or log in to our Services using a social media account,
                we may have access to certain information about you.
              </p>
              <p className="mb-5">
                Our Services offer you the ability to register and log in using
                your third-party social media account details (like your
                Facebook or Twitter logins). Where you choose to do this, we
                will receive certain profile information about you from your
                social media provider. The profile information we receive may
                vary depending on the social media provider concerned, but will
                often include your name, email address, friends list, and
                profile picture, as well as other information you choose to make
                public on such a social media platform.
              </p>
              <p className="mb-5">
                We will use the information we receive only for the purposes
                that are described in this privacy notice or that are otherwise
                made clear to you on the relevant Services. Please note that we
                do not control, and are not responsible for, other uses of your
                personal information by your third-party social media provider.
                We recommend that you review their privacy notice to understand
                how they collect, use, and share your personal information, and
                how you can set your privacy preferences on their sites and
                apps.
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> We may transfer,
                store, and process your information in countries other than your
                own.
              </p>
              <p className="mb-5">
                Our servers are located in. If you are accessing our Services
                from outside, please be aware that your information may be
                transferred to, stored, and processed by us in our facilities
                and by those third parties with whom we may share your personal
                information (see "
                <Link className="underline text-blue-500" to={""}>
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </Link>
                " above), in and other countries.
              </p>
              <p className="mb-5">
                If you are a resident in the European Economic Area (EEA) or
                United Kingdom (UK), then these countries may not necessarily
                have data protection laws or other similar laws as comprehensive
                as those in your country. However, we will take all necessary
                measures to protect your personal information in accordance with
                this privacy notice and applicable law.
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                9. HOW LONG DO WE KEEP YOUR INFORMATION?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> We keep your
                information for as long as necessary to fulfill the purposes
                outlined in this privacy notice unless otherwise required by
                law.
              </p>
              <p className="mb-5">
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements).
              </p>
              <p className="mb-5">
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                10. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> We aim to protect
                your personal information through a system of organizational and
                technical security measures.
              </p>
              <p className="mb-5">
                We have implemented appropriate and reasonable technical and
                organizational security measures designed to protect the
                security of any personal information we process. However,
                despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorized third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information. Although we will do our best to protect your
                personal information, transmission of personal information to
                and from our Services is at your own risk. You should only
                access the Services within a secure environment.
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                11. DO WE COLLECT INFORMATION FROM MINORS?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> We do not knowingly
                collect data from or market to children under 18 years of age.
              </p>
              <p className="mb-5">
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the Services, you represent that
                you are at least 18 or that you are the parent or guardian of
                such a minor and consent to such minor dependent’s use of the
                Services. If we learn that personal information from users less
                than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such
                data from our records. If you become aware of any data we may
                have collected from children under age 18, please contact us at
                admin@jrnyfy.com.
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                12. WHAT ARE YOUR PRIVACY RIGHTS?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> In some regions,
                such as , you have rights that allow you greater access to and
                control over your personal information. You may review, change,
                or terminate your account at any time.
              </p>
              <p className="mb-5">
                In some regions (like ), you have certain rights under
                applicable data protection laws. These may include the right (i)
                to request access and obtain a copy of your personal
                information, (ii) to request rectification or erasure; (iii) to
                restrict the processing of your personal information; and (iv)
                if applicable, to data portability. In certain circumstances,
                you may also have the right to object to the processing of your
                personal information. You can make such a request by contacting
                us by using the contact details provided in the section{" "}
                <Link className="underline text-blue-500" to={""}>
                  "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                </Link>
              </p>
              <p className="mb-5">
                We will consider and act upon any request in accordance with
                applicable data protection laws.
              </p>
              <p className="mb-5">
                If you are located in the EEA or UK and you believe we are
                unlawfully processing your personal information, you also have
                the right to complain to your{" "}
                <Link className="underline text-blue-500" to={""}>
                  Member State data protection authority
                </Link>{" "}
                or{" "}
                <Link className="underline text-blue-500" to={""}>
                  UK data protection authority.
                </Link>
              </p>
              <p className="mb-5">
                If you are located in Switzerland, you may contact the Federal
                Data Protection and Information Commissioner.
              </p>
              <p className="mb-5">
                <Link className="underline font-bold" to={""}>
                  Withdrawing your consent:
                </Link>{" "}
                If we are relying on your consent to process your personal
                information, which may be express and/or implied consent
                depending on the applicable law, you have the right to withdraw
                your consent at any time. You can withdraw your consent at any
                time by contacting us by using the contact details provided in
                the section{" "}
                <Link className="underline text-blue-500" to={""}>
                  "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                </Link>{" "}
                below.
              </p>
              <p className="mb-5">
                However, please note that this will not affect the lawfulness of
                the processing before its withdrawal nor, when applicable law
                allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent.
              </p>
              <p className="mb-5">
                <Link className="underline font-bold" to={""}>
                  Opting out of marketing and promotional communications:
                </Link>{" "}
                You can unsubscribe from our marketing and promotional
                communications at any time by clicking on the unsubscribe link
                in the emails that we send, or by contacting us using the
                details provided in the section{" "}
                <Link className="underline text-blue-500" to={""}>
                  "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                </Link>{" "}
                below. You will then be removed from the marketing lists.
                However, we may still communicate with you — for example, to
                send you service-related messages that are necessary for the
                administration and use of your account, to respond to service
                requests, or for other non-marketing purposes.
              </p>
              <p className="mb-5">Account Information</p>
              <p className="mb-5">
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
              </p>
              <p className="mb-5">
                <Link className="underline font-bold" to={""}>
                  Cookies and similar technologies:
                </Link>{" "}
                Most Web browsers are set to accept cookies by default. If you
                prefer, you can usually choose to set your browser to remove
                cookies and to reject cookies. If you choose to remove cookies
                or reject cookies, this could affect certain features or
                services of our Services. You may also{" "}
                <Link className="underline text-blue-500" to={""}>
                  opt out of interest-based advertising by advertisers
                </Link>{" "}
                on our Services.
              </p>
              <p className="mb-5">
                If you have questions or comments about your privacy rights, you
                may email us at admin@jrnyfy.com.
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                13. CONTROLS FOR DO-NOT-TRACK FEATURES
              </h5>
              <p className="mb-5">
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> Yes, if you are a
                resident of California, you are granted specific rights
                regarding access to your personal information.
              </p>
              <p className="mb-5">
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
              </p>
              <p className="mb-5">
                If you are under 18 years of age, reside in California, and have
                a registered account with Services, you have the right to
                request removal of unwanted data that you publicly post on the
                Services. To request removal of such data, please contact us
                using the contact information provided below and include the
                email address associated with your account and a statement that
                you reside in California. We will make sure the data is not
                publicly displayed on the Services, but please be aware that the
                data may not be completely or comprehensively removed from all
                our systems (e.g., backups, etc.).
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                15. DO WE MAKE UPDATES TO THIS NOTICE?
              </h5>
              <p className="mb-5 italic">
                <span className="font-bold">In Short:</span> Yes, we will update
                this notice as necessary to stay compliant with relevant laws.
              </p>
              <p className="mb-5">
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </h5>
              <p className="mb-5">
                If you have questions or comments about this notice, you may
                email us at admin@jrnyfy.com or by post to:
              </p>
              <p className="mb-5">Jrnyfy Corp.</p>
              <p>__________</p>
              <p>Toronto, Ontario</p>
              <p>Canada</p>
            </section>
            <section className="mb-10">
              <h5 class="text-xl mb-5 font-bold dark:text-white">
                17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </h5>
              <p className="mb-5">
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it. To request to
                review, update, or delete your personal information, please
                visit: __________.
              </p>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default PrivacyPolilcy;
