const Icons = ({
  icon,
  alt,
  content,
  width,
  textClick,
  iconClick,
  activityManageModalHandle,
}) => {
  return (
    <div
      onClick={activityManageModalHandle}
      className='flex justify-center items-center flex-wrap'
    >
      {icon && (
        <button
          onClick={iconClick}
          className='flex items-center justify-center cursor-pointer w-[30px] h-[30px] p-[5px] rounded-[50%] hover:bg-jrnyfy-colorLightGray'
        >
          <img src={icon} alt={alt} className={` h-full w-full`} />
        </button>
      )}
      {content && (
        <span
          className='flex items-center gap-1 text-[12px] font-semibold text-jrnyfy-textprimary  cursor-pointer'
          onClick={textClick}
        >
          <span className='font-extrabold'>{content?.amount}</span>{' '}
          <span>{content?.name}</span>
        </span>
      )}
    </div>
  );
};

export default Icons;
