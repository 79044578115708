import { NavLink } from "react-router-dom";

const FeedTags = ({ to, content }) => {
    let Content = content.toUpperCase();
    return (
        <div>
            <NavLink to={to}>
                <p className="p-[5px_10px] rounded-[50px] text-[10px] text-jrnyfy-themeColor font-bold border border-solid border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor hover:text-white italic">
                    {Content}
                </p>
            </NavLink>
        </div>
    )
};

export default FeedTags;