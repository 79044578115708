import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {baseUrl} from '../../../environments/proxyUrl';
import {parse_SourceArray} from './elasticParser';

export const getInterests = createAsyncThunk(
  'interest/getInterests',
  async ({token, data}) => {
    const {data: res} = await axios.get(`${baseUrl}/api/tags/v1/interest/`, {
      params: {limit: data.limit, skip: data.skip},
      validateStatus: false,
      headers: {
        Authorization: token,
      },
    });

    return parse_SourceArray(res.results);
  },
);

// export const getInterestByName = createAsyncThunk(
//   'interest/getInterestByName',
//   async ({token, name, readOnly = true}, {rejectWithValue}) => {
//     try {
//       const {data} = await axios.get(`${baseUrl}/api/search/v1/interest/${name}`, {
//         headers: {Authorization: token},
//       });
//       // const results = parse_SourceArray(data.results);
//       const results = await parse_SourceArray(data.results);
//       return {results, readOnly};
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
const interestSlice = createSlice({
  name: 'interest',
  initialState: {
    interests: [],
    interest: {},
  },
  reducers: {},
  extraReducers: {
    [getInterests.pending]: state => {
      state.loading = true;
      state.errors = [];
    },
    [getInterests.fulfilled]: (state, action) => {
      state.loading = false;
      state.interests = action.payload;
    },
    [getInterests.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    // [getInterestByName.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    //   state.interest = {};
    // },
    // [getInterestByName.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   if (action.payload.results.length > 0) {
    //     state.interest = {
    //       ...action.payload.results[0],
    //       readOnly: action.payload.readOnly,
    //     };
    //   }
    //   state.loading = false;
    // },
    // [getInterestByName.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
  },
});

export const {} = interestSlice.actions;

export default interestSlice.reducer;

export const interestSelector = state => state.interest;
