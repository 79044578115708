import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  activitySelector,
  addTagIntoActivity,
  deleteTagFromActivity,
} from '../../redux/slices/mobile/activitySlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import addPlaceIcon from '../../assets/images/button_menu_places.svg';
import { FreeMode } from 'swiper';
import Cross from '../../assets/images/xmark-solid.svg';
import {
  getMatchedTags,
  removeTagAfterSelection,
  tagSelectorV2,
} from '../../redux/slices/mobile/tagSliceV2';
import Persons from '../Persons/Persons';
import { buildUrlToSize } from '../../utils/build-image-url';
import { productSelector } from '../../redux/slices/mobile/productSlice';
import { PhotoSize } from '../utils/subjects';
import { onTypeChange } from '../utils/typing-change';
import BackButton from '../BackButton/BackButton';
const size = 110;

const TagList = (props) => {
  const { activity } = useSelector(activitySelector);
  const { product } = useSelector(productSelector);
  const { auth } = useSelector(authSelector);
  const { tags } = useSelector(tagSelectorV2);
  const [tag, setTag] = useState([]);
  const type = 'journey';
  const inputRef = useRef();
  const [typedText, setTypedText] = useState();
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  console.log('TagListProps', props);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getMatchedTags({
        token: auth.token,
        data: {
          from: 0,
          size: size,
          name: '',
          tagsNameExclude:
            props.type == 'create_screen'
              ? props?.data?.map((item) => item.name)
              : props.type == 'journey'
              ? activity.tags?.map((item) => item.name)
              : product.tags?.map((item) => item.name),
        },
      })
    );
  }, []);

  const addTagToSelectedList = (tag) => {
    console.log('AddTag', tag);
    dispatch(
      addTagIntoActivity({
        data: {
          createdAt: activity.createdAt,
          sk: activity.sk,
          tagName: tag.name,
          tagId: tag.id,
        },
        token: auth.token,
        id: activity.id,
        callback: (result) => {
          console.log('AddTagIntoActivity', result);
        },
      })
    );
  };
  const removeTag = (tag) => {
    if (type == 'journey') {
      dispatch(
        deleteTagFromActivity({
          data: {
            sk: activity.sk,
            createdAt: activity.createdAt,
          },
          token: auth.token,
          id: activity.id,
          tagName: tag.name,
          tagId: tag.id,
          callback: (result) => {
            console.log('Deleted Task Result', result);
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getMatchedTags({
        token: auth.token,
        data: {
          from: 0,
          size: size,
          name: typedText,
        },
      })
    );
  }, [typedText]);

  useEffect(() => {
    inputRef?.current?.blur();
  }, []);

  console.log('Tags', tags);
  return (
    <div className='space-y-3 h-full flex flex-col items-stretch content-stretch'>
      <div>
        <div className='flex items-center gap-3'>
          {props.tagListHandle ? (
            <BackButton callback={() => props.tagListHandle()} />
          ) : (
            <img
              src={Cross}
              alt='cross'
              className='cursor-pointer w-[20px]'
              onClick={() => props.tagListModalHandle(false)}
            />
          )}
          <div className='flex justify-center items-center w-full'>
            <img src={addPlaceIcon} alt='place' className='max-w-[30px]' />
            <p className='text-jrny font-semibold'>add tags to you journey</p>
          </div>
        </div>
        <div>
          <div className='relative'>
            <div className='relative'>
              <input
                type='search'
                ref={inputRef}
                placeholder='search tags'
                className='bg-gray-100 w-full p-[10px_20px] rounded-[50px] focus:outline-none'
                onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                  setTypedText(value);
                })}
                value={typeInput.text}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='py-4 space-y-2 overflow-y-auto grow flex flex-col items-stretch content-stretch'>
        <p>matched tags</p>
        <div className=' overflow-y-auto pr-3 grow'>
          {tags.length >= 1
            ? tags.map((val, ind) => (
                <div
                  key={ind}
                  className='flex justify-between items-center w-full'
                >
                  <Persons
                    avater={
                      val?.thumbnail === undefined
                        ? addPlaceIcon
                        : buildUrlToSize(val?.thumbnail, PhotoSize.Large)
                    }
                    name={val.name}
                    userid={val.vicinity}
                    className='w-[55px] h-[55px] rounded-[50%] text-white max-w-none'
                    nameStyle='text-jSecTitle font-semibold leading-[17px]'
                  />

                  <div>
                    <button
                      className='p-[5px_15px] font-SourceSansPro rounded-[50px] font-semibold border-jrnyfy-textprimary border border-solid'
                      onClick={() => {
                        addTagToSelectedList(val);
                        dispatch(removeTagAfterSelection(val));
                      }}
                    >
                      add
                    </button>
                  </div>
                </div>
              ))
            : ''}
        </div>
      </div>
    </div>
  );
};

export default TagList;
