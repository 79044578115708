import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import Thumbnail from '../thumbnails/Thumbnail';
import { viewProfileSelector } from '../../redux/slices/mobile/viewProfileSlice';
import { addIntoWishList } from '../../redux/slices/mobile/bookingSlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { UserJourneyFilter } from '../../utils/filters';
import {
  getActiveActivitiesOfUser,
  profileSelector,
} from '../../redux/slices/mobile/profileSlice';
import { JourneyFilter, PhotoSize } from '../utils/subjects';
import { onTypeChange } from '../utils/typing-change';
import Cross from '../../assets/images/xmark-solid.svg';
import JrnyCreate from '../jrnyCreate/jrnyCreate';

const JrnyListModal = ({
  data,
  jrnyListModalHandle,
  jrnyListModal,
  addThisToYourJourney,
}) => {
  const { activeActivities } = useSelector(profileSelector);
  const { auth } = useSelector(authSelector);
  const [selectedActivity, setSelectedActivity] = useState();
  const [searchText, setSearchText] = useState();
  const [currentPage, setCurrentPage] = useState();
  const dispatch = useDispatch();
  let [userArr, setUserArr] = useState([]);
  let [placeArr, setPlaceArr] = useState([]);
  let [postValue, setPostValue] = useState({
    vissible: true,
    value: undefined,
  });
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  let [jrnyModal, setJrnyModal] = useState(false);
  let jrnyModalControl = () => {
    setJrnyModal((state) => !state);
  };

  useEffect(() => {
    dispatch(
      getActiveActivitiesOfUser({
        data: {
          from: 0,
          value: searchText && searchText,
          size: 10,
          filter: JourneyFilter.Current,
        },
        token: auth.token,
        id: auth?.user?.id,
      })
    );
  }, [searchText]);

  useEffect(() => {
    console.log('ActiveActivites', activeActivities);
  }, [activeActivities]);

  const removeFromWishListHandle = () => {};
  useEffect(() => {
    setPlaceArr([
      {
        ...data?.feed?.task?.location,
        photos: [
          { photo_reference: data?.feed?.task?.location.photo.photoReference },
        ],
        place_id: data?.feed?.task?.location.id,
      },
    ]);
    console.log('PlaceData', placeArr);
  }, [data]);

  return (
    <Popup
      open={jrnyListModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='bg-white w-[600px] flex flex-col items-stretch content-stretch space-y-3 h-[500px] rounded-[20px] gap-5 overflow-hidden p-10 max-[500px]:p-5 max-[600px]:h-full max-[600px]:rounded-[unset]'>
        <div className='space-y-3'>
          <div className='flex items-center'>
            <div className='w-fit'>
              <img
                src={Cross}
                alt='cross'
                className='cursor-pointer w-[20px]'
                onClick={jrnyListModalHandle}
              />
            </div>
            <p className='w-full text-center text-jrnyfy-textprimary font-bold'>
              Add this Location
            </p>
          </div>
          <div className='space-y-2'>
            <div className='flex items-center gap-3'>
              <button
                onClick={jrnyModalControl}
                className='p-[10px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro '
              >
                create a new journey
              </button>
              <p className='text-jrnyfy-textprimary'>with this location</p>
            </div>
            <p className='text-jrnyfy-textprimary'>
              or add this to your existing journey
            </p>
          </div>
          {jrnyModal && (
            <JrnyCreate
              setPostValue={setPostValue}
              postValue={postValue}
              setPlaceArr={setPlaceArr}
              placeArr={placeArr}
              setUserArr={setUserArr}
              userArr={userArr}
              jrnyModal={jrnyModal}
              jrnyModalControl={jrnyModalControl}
            />
          )}
          <div className='relative w-full'>
            <input
              type='search'
              placeholder='find someone'
              className='bg-gray-100 w-full p-[10px_20px] rounded-[50px]'
              onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                setCurrentPage(0);
                setSearchText(value);
              })}
            />
            <button className='w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]'></button>
          </div>
        </div>

        <div
          className={`w-full h-full grow  space-y-5 transition-all duration-10000 overflow-y-scroll `}
        >
          {activeActivities.length > 0 ? (
            activeActivities.map((item) => {
              return (
                <div className='flex justify-between w-full'>
                  <div>
                    <Thumbnail
                      showName={true}
                      btnFollow={false}
                      data={item}
                      PhotoSize={PhotoSize.Small}
                    />
                  </div>

                  {item.id == selectedActivity?.id ? (
                    <button
                      onClick={() => removeFromWishListHandle(item)}
                      className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
                    >
                      remove
                    </button>
                  ) : (
                    <button
                      onClick={() => addThisToYourJourney(item)}
                      className='p-[2px_20px_2px_20px] text-[14px] border italic rounded-[50px] text-jrnyfy-textprimary transition-all font-SourceSansPro'
                    >
                      add
                    </button>
                  )}
                </div>
              );
            })
          ) : (
            <div>You don't have any journey. Please create a new journey</div>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default JrnyListModal;
