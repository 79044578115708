import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {baseUrl} from '../../../environments/proxyUrl';

export const getStoreFilters = createAsyncThunk(
  'store/filters',
  async ({token}, {rejectWithValue}) => {
    console.log('get products filter2');

    return {
      filters: [
        {
          name: 'all',
          label: 'all',
        },
        {
          name: 'popular',
          label: 'popular',
        },
      ],
    };
  },
);

// export const getStoreProductById = createAsyncThunk(
//   'store/getStoreProductById',
//   async ({token, id, callback}, {rejectWithValue}) => {
//     try {
//       const {data} = await axios.get(`${baseUrl}/api/products/v1/find/${id}`, {
//         headers: {Authorization: token},
//       });
//       if (callback) {
//         callback(data.results);
//       }
//       return data.results;

//       // return result;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const getReviews = createAsyncThunk(
  'activity/getReviews',
  async ({token, data, id, callback}, {rejectWithValue}) => {
    try {
      const {data: res, headers} = await axios.get(
        `${baseUrl}/api/products/v1/review/find/${id}`,
        {
          params: {...data},
          headers: {
            Authorization: token,
          },
        },
      );
      const count = headers['content-range'].split('/')[1];

      if (callback) {
        callback(res.results);
      }
      return {
        // contentRange: response.headers['content-range'],
        skip: data.skip,
        limit: data.limit,
        reviews: res.results,
        count: count,
      };
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getMoreReviews = createAsyncThunk(
  'activity/getMoreReviews',
  async ({token, data, id}, {rejectWithValue}) => {
    try {
      const {data: res, headers} = await axios.get(
        `${baseUrl}/api/products/v1/review/find/${id}`,
        {
          params: {...data},
          headers: {
            Authorization: token,
          },
        },
      );
      const count = headers['content-range'].split('/')[1];
      return {
        skip: data.skip,
        limit: data.limit,
        reviews: res.results,
        count: count,
      };
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getProductCustomOffers = createAsyncThunk(
  'product/getProductCustomOffers',
  async ({token, id, callback, body}, {rejectWithValue}) => {
    try {
      const {data} = await axios.get(
        `${baseUrl}/api/products/v1/offer/${id}`,
        {
          params: {...body},
          headers: {Authorization: token},
        },
      );
      if (callback) {
        callback(data.results);
      }
      return data.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// export const getMyCustomOffers = createAsyncThunk(
//   'product/getMyCustomOffers',
//   async ({token, activityId, data}, {rejectWithValue}) => {
//     try {
//       const {data: response} = await axios.get(
//         `${baseUrl}/api/products/v1/price/offer/user/${activityId}`,
//         {
//           params: {...data},
//           headers: {
//             Authorization: token,
//           },
//         },
//       );
//       return {
//         skip: data.skip,
//         limit: data.limit,
//         offers: response.results,
//       };
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

// export const addCustomPriceOffer = createAsyncThunk(
//   'product/addCustomPriceOffer',
//   async (
//     {token, data, id, userId, activityId, callback},
//     {rejectWithValue},
//   ) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/products/v1/price/offer/${id}/${userId}/${activityId}`,
//         data,
//         {
//           headers: {
//             Authorization: token,
//           },
//         },
//       );
//       if (callback) {
//         callback(res);
//       }
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
// export const deleteCustomPriceOffer = createAsyncThunk(
//   'product/deleteCustomPriceOffer',
//   async ({token, id, data, activityId, callback}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.delete(
//         `${baseUrl}/api/products/v1/price/offer/${id}`,
//         {
//           params: {...data},
//           headers: {Authorization: token},
//         },
//       );
//       if (callback) {
//         callback(res);
//       }
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

const storeSlice = createSlice({
  name: 'store',
  initialState: {
    products: [],
    ideas: [],
    errors: [],
    product: {},
    reviews: [],
    loading: false,
    contentRange: undefined,
    contentRangeIdea: undefined,
    productWithDetails: undefined,
    contentRangeReviews: undefined,
    customOffers: undefined, //for products
    customOffers_activity: undefined, //for activities
    viewProduct: undefined,
  },
  reducers: {
    setProduct: (state, action) => {
      const {product} = action.payload;
      console.log(
        'action.payload setProduct --------------------------------------------',
        product,
      );
      state.product = product;
    },
  },
  extraReducers: {
    // [getStoreProductById.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    //   state.product = {};
    //   state.productWithDetails = undefined;
    // },
    // [getStoreProductById.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.product = action.payload;
    //   state.productWithDetails = action.payload;
    //   state.loading = false;
    // },
    // [getStoreProductById.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    [getStoreFilters.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getStoreFilters.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload.filters) {
        state.filters = action.payload.filters;
      }
      state.loading = false;
    },
    [getStoreFilters.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getReviews.pending]: state => {
      state.loading = true;
      state.errors = [];
      state.contentRangeReviews = undefined;
    },
    [getReviews.fulfilled]: (state, action) => {
      // state.product.reviews = action.payload.reviews;
      state.product = {...state.product, reviews: action.payload.reviews};
      state.reviews = {
        reviews: action.payload.reviews,
        count: action.payload.count,
      };
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let aSkip = action.payload.reviews.length;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSkip < limit) {
        state.contentRangeReviews = `${skip}-${sum}/false`;
      } else {
        state.contentRangeReviews = `${skip}-${sum}/true`;
      }
      state.loading = false;
    },
    [getReviews.rejected]: (state, action) => {
      state.loading = false;
    },

    [getMoreReviews.pending]: state => {
      state.loading = true;
      state.errors = [];
      state.contentRangeReviews = undefined;
    },
    [getMoreReviews.fulfilled]: (state, action) => {
      const rvs = state.product.reviews
        ? state.product.reviews.map(item => item)
        : [];
      const aR = rvs.concat(action.payload.reviews);
      state.product = {...state.product, reviews: aR};
      //state.reviews = aR;
      state.reviews = {reviews: aR, count: action.payload.count};

      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let aSkip = action.payload.reviews.length;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSkip < limit) {
        state.contentRangeReviews = `${skip}-${sum}/false`;
      } else {
        state.contentRangeReviews = `${skip}-${sum}/true`;
      }
      state.loading = false;
    },
    [getMoreReviews.rejected]: (state, action) => {
      state.loading = false;
    },
    //new getProductCustomOffers
    [getProductCustomOffers.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.customOffers = undefined;
    },
    [getProductCustomOffers.fulfilled]: (state, action) => {
      state.errors = [];
      state.customOffers = action.payload;
      state.loading = false;
    },
    [getProductCustomOffers.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    //add offer
    // [addCustomPriceOffer.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [addCustomPriceOffer.fulfilled]: (state, action) => {
    //   const temp_customOffers = state.customOffers;
    //   const filteredOffers = action.payload.offer;

    //   if (action.payload.offer) {
    //     state.customOffers = [...temp_customOffers, filteredOffers];
    //   }

    //   state.errors = [];
    //   state.loading = false;
    // },
    // [addCustomPriceOffer.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    //delete
    // [deleteCustomPriceOffer.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [deleteCustomPriceOffer.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   if (state.customOffers) {
    //     const temp_customOffers = state.customOffers;

    //     const filteredOffers = temp_customOffers.filter(offer => {
    //       offer.activity.id == action.payload.activity;
    //     });
    //     console.log('filteredOffers ==', filteredOffers);
    //     state.customOffers = filteredOffers;
    //   }
    //   state.loading = false;
    // },
    // [deleteCustomPriceOffer.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    //get activity specific custom offer {getMyCustomOffers}
    // [getMyCustomOffers.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    //   state.customOffers_activity = undefined;
    // },
    // [getMyCustomOffers.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.customOffers_activity = action.payload;
    //   state.loading = false;
    // },
    // [getMyCustomOffers.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
  },
});

export const {setProduct} = storeSlice.actions;

export default storeSlice.reducer;

export const storeSelector = state => state.store;
