import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../../environments/proxyUrl';

export const getTopTags = createAsyncThunk(
  'topTag/getTopTags',
  async ({ data }, { rejectWithValue }) => {
    try {
      let { data: response } = await axios.get(
        `${baseUrl}/api/search/v1/tags`,
        { params: data }
      );
      console.log('top Tag slice: ', response);
      return response.results;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return error.response.data;
    }
  }
);

export const getTopPlaces = createAsyncThunk(
  'topTag/getTopPlaces',
  async ({ data }, { rejectWithValue }) => {
    try {
      let { data: response } = await axios.get(
        `${baseUrl}/api/search/v1/tags`,
        { params: data }
      );
      console.log('top Tag slice: ', response);
      return response.results;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return error.response.data;
    }
  }
);

export const topPlaces = createAsyncThunk('topTag/topPlaces', async () => {
  try {
    let { data: response } = await axios.get(
      `${baseUrl}/api/search/v1/top-tags?size=100&from=0&type=1`
    );
    console.log('top Tag slice: ', response);
    return response.results;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return error.response.data;
  }
});

const topTagSlice = createSlice({
  name: 'tagSlice',
  initialState: {
    ideas: [],
    places: [],
  },
  reducers: {},
  extraReducers: {
    [getTopTags.pending]: (state, action) => {},
    [getTopTags.fulfilled]: (state, action) => {
      state.ideas = action.payload;
    },
    [getTopTags.rejected]: (state, action) => {},
    [getTopPlaces.pending]: (state, action) => {},
    [getTopPlaces.fulfilled]: (state, action) => {
      state.places = action.payload;
    },
    [getTopPlaces.rejected]: (state, action) => {},
  },
});

export default topTagSlice.reducer;
export const tagSelector = (state) => state.topTagSlice;
