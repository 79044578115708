import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import Thumbnail from '../thumbnails/Thumbnail';
import StripePayment from './StripePayment';
import baseUrl from '../../environments/proxyUrl';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { createActivity } from '../../redux/slices/mobile/activitySlice';
import { PhotoSize, YesNo } from '../utils/subjects';

const ServicePaymentPopUp = ({
  selectedDate,
  selectedPeople,
  selectTime,
  product,
  clientSecret,
  activeActivities,
  paymentModal,
  paymentModalHandle,
  paymentCompleteModalHandle,
}) => {
  console.log('Journeys', activeActivities);
  const { auth } = useSelector(authSelector);
  const [selectedActivity, setSelectedActivity] = useState(undefined);
  const [openPayment, setOpenPayment] = useState(false);
  const dispatch = useDispatch();

  if (!activeActivities) {
    setOpenPayment(true);
  }
  console.log('Selected Date', selectedDate, 'selected People', selectedPeople);
  return (
    <Popup
      open={paymentModal}
      closeOnDocumentClick
      onClose={paymentModalHandle}
      contentStyle={{
        maxWidth: '520px',
        minHeight: '700px',
        borderRadius: '20px',
        padding: '50px',
      }}
    >
      <div className='flex flex-col justify-between gap-10 overflow-hidden'>
        {openPayment ? (
          <div className='text-jrnyfy-textprimary'>add payment details</div>
        ) : (
          <div className='text-jrnyfy-textprimary'>
            Would you like to add this reservation to existing journey?
          </div>
        )}
        <div className='relative p-[50px] min-h-[500px] w-full space-y-5'>
          <div
            className={`${
              openPayment ? 'left-[-100%]' : 'left-0'
            } absolute top-0 w-full h-full  space-y-5 transition-all duration-10000 p-5 overflow-y-scroll `}
          >
            {activeActivities &&
              activeActivities.map((item) => {
                return (
                  <div className='flex justify-between w-full'>
                    <Thumbnail
                      btnFollow={false}
                      data={item}
                      PhotoSize={PhotoSize.Small}
                    />

                    {item.id == selectedActivity?.id ? (
                      <button
                        onClick={() => setOpenPayment(true)}
                        className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        onClick={() => setSelectedActivity(item)}
                        className='p-[2px_20px_2px_20px] text-[14px] border italic rounded-[50px] text-jrnyfy-textprimary transition-all font-SourceSansPro'
                      >
                        add
                      </button>
                    )}
                  </div>
                );
              })}
          </div>

          <div
            className={`${
              openPayment ? 'left-0' : 'left-[-100%]'
            } absolute top-0 w-full h-full transition-all duration-10000 space-y-3`}
          >
            {selectedActivity && (
              <div>
                <span>Selected Journey</span>

                <Thumbnail
                  btnFollow={false}
                  data={selectedActivity}
                  PhotoSize={PhotoSize.Small}
                />
              </div>
            )}
            <StripePayment
              selectedDate={selectedDate}
              selectedPeople={selectedPeople}
              paymentCompleteModalHandle={paymentCompleteModalHandle}
              paymentModalHandle={paymentModalHandle}
              selectTime={selectTime}
              clientSecret={clientSecret}
              product={product}
              selectedActivity={selectedActivity}
            />
          </div>
        </div>

        {openPayment ? (
          <div className='w-full flex justify-end'>
            <button
              onClick={() => setOpenPayment(false)}
              className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
            >
              Back
            </button>
          </div>
        ) : (
          !selectedActivity && (
            <div className='w-full flex justify-end'>
              <button
                onClick={() => setOpenPayment(true)}
                className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
              >
                skip
              </button>
            </div>
          )
        )}
      </div>
    </Popup>
  );
};

export default ServicePaymentPopUp;
