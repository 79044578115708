import { buildUrlToSize } from "../../utils/build-image-url";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import { PhotoSize, YesNo } from "../utils/subjects";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import {
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import PopUpImageGallery from "../popUpImageGallery/PopUpImageGallery";
import Cross from "../../assets/images/xmark-solid.svg";
import ImageGallery from "react-image-gallery";
import cancelBtnSvg from "../../assets/images/Cancel.svg";
import Thumbnail from "../thumbnails/Thumbnail";
import Icons from "../Icons/Icons";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";
import TreeIcon from '../../assets/icons/sprout-tree.svg'

const ActivityCreate = ({ index, data }) => {
  const { activity } = useSelector(activitySelector);
  const journeyName = data?.activity?.name ? data?.activity?.name : activity?.name;
  let [popUpImage, setpopUpImage] = useState({
    status: false,
    value: null,
  });
  console.log("Task Create:", data);
  const user = data?.feed ? data?.feed?.user : data?.user;
  let modalCondition = (imageUrl) => {
    setpopUpImage((state) => ({ status: !state.status, value: imageUrl }));
    console.log(popUpImage);
  };
  let avater = buildUrlToSize(
    data?.feed ? data?.feed?.user?.thumbnail : data?.user?.thumbnail,
    PhotoSize.Large
  );
  let thumbnail = buildUrlToSize(data?.thumbnail, PhotoSize.Large);
  let userName = data?.feed?.user?.name
    ? data?.feed?.user?.name
    : data?.user?.name;
  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();
  let fName = data?.feed?.user?.name.slice(0, 1);
  let mNamePos = data?.feed?.user?.name.search(" ");
  let mName = data?.feed?.user?.name.slice(mNamePos + 1, mNamePos + 2);
  let altTitle = fName + mName;
  let navigate = useNavigate();
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = "hidden";
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = "scroll";
  };

  useEffect(() => {
    setGalleryImages([]);

    setGalleryImages((prevState) => [
      ...prevState,
      { original: thumbnail, thumbnail: thumbnail },
    ]);
  }, [thumbnail]);

  return (
    <div
    className="cursor-pointer"
    onClick={() => {
      // navigate(`/jrny/${data.id}`);
      navigate(getJourneyNavOption(data));
    }}
    >
      {/* <Popup
        open={mState}
        closeOnDocumentClick={false}
        contentStyle={{
          width: '100%',
          backgroundColor: 'transparent',
          border: 'none',
        }}
      >
        <div className='h-full max-h-screen overflow-auto'>
          <div className='text-right'>
            <button
              className='text-[35px] font-bold text-white translate-x-[-260px]'
              onClick={modalCondition}
            >
              &#10006;
            </button>
          </div>
          <div className='flex justify-center'>
            <img src={thumbnail} alt='Place' />
          </div>
        </div>
      </Popup> */}

      <PopUpImageGallery
        popUpImage={popUpImage}
        modalCondition={modalCondition}
      />

      <div className="flex justify-between items-start">
        <div className="relative">
          <div className="absolute top-0 left-[-24px]">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>
          {data?.feed?.user ? (
            <p
              className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold"
              onClick={() => {
                // navigate(`/jrny/${data.id}`);
                navigate(getJourneyNavOption(data));
              }}
            >
              <span className="text-jrnyfy-title cursor-pointer">
                {userName} started journey :
              </span>
              {journeyName}
            </p>
          ) : (
            <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold">
              <span className="text-jrnyfy-title">
                {userName} started journey :
              </span>
              {journeyName}
            </p>
          )}

          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {userName}, created{" "}
            <Moment fromNow>{data?.createdAt}</Moment>
          </p>
        </div>
      </div>

      {thumbnail === undefined ? null : (
        <div className="ml-[20px] w-auto mt-4 taskimg">
          <img
            src={thumbnail}
            alt="user profile"
            className="aspect-video rounded-3xl w-full cursor-pointer object-cover"
            onClick={() => {
              openImageGallery();
            }}
          />

          <div className="absolute z-20 text-white bottom-[15px] left-0 px-[20px] text-[12px] md:text-[18px]">
            <p>
              {userName} JRNY: {journeyName}
            </p>
          </div>
        </div>
      )}

      <div className="flex justify-center items-end py-10">
        <Thumbnail
          ShowName={false}
          btnFollow={false}
          data={user}
          PhotoSize={PhotoSize.Medium}
          thumbnailContainerStyle={'!w-[110px] !h-[110px]'}
        />
        <img className="ml-[-35px] z-10" src={TreeIcon} />
      </div>

      {showImageGallery && (
        <div className="fixed flex items-center bg-black top-0 left-0 right-0 bottom-0 m-auto z-40 ">
          <button onClick={closeImageGallery}>
            <img
              src={cancelBtnSvg}
              alt="cross"
              className="cursor-pointer w-[25px] absolute top-[20px] left-[25px] z-50"
            />
          </button>
          <ImageGallery
            startIndex={galleryCurrentIndex}
            showIndex={true}
            items={galleryImages}
          />
        </div>
      )}
    </div>
  );
};

export default ActivityCreate;
