import React, { useState } from 'react';
import TagList from '../TagList/TagList';
import Popup from 'reactjs-popup';
import NoPicture from '../../assets/images/image_upload.svg';
import Cross from '../../assets/images/xmark-solid.svg';
import { CreateFor, USER_TYPE, UserUploadedImageType } from '../utils/subjects';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import {
  activitySelector,
  addTagsActivity,
} from '../../redux/slices/mobile/activitySlice';
import { addTags } from '../../redux/slices/mobile/productSlice';
import { createNewTag } from '../../redux/slices/mobile/tagSliceV2';
import backBtn from '../../assets/icons/back.png';

const TagListModal = ({ showTagsModal, tagListModalHandle }) => {
  const { auth, userType } = useSelector(authSelector);
  const { activity } = useSelector(activitySelector);
  const [isCreateTag, setIsCreateTag] = useState(false);
  console.log('Activity', activity);
  const dispatch = useDispatch();
  const [tagData, setTagData] = useState({
    imageType: UserUploadedImageType.Tag,
    date: new Date(),
    name: '',
    photo: '',
    description: '',
    createForDocId: activity.id,
    createForDocSk: activity.sk,
    createForDocCreatedAt: activity.createdAt,
    createFor:
      userType == USER_TYPE.USER ? CreateFor.Journey : CreateFor.Service,
  });
  console.log('TagData', tagData);
  return (
    <Popup
      open={showTagsModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='flex flex-col h-[600px] items-stretch content-stretch gap-5 w-[500px] overflow-hidden p-10 bg-white rounded-[20px] max-[500px]:p-5 max-[767px]:h-full  max-[767px]:overflow-y-scroll max-[600px]:rounded-none'>
        {isCreateTag ? (
          <div className='space-y-5'>
            <div className='flex items-center'>
              <div className='w-fit'>
                <img
                  src={backBtn}
                  alt='cross'
                  className='cursor-pointer w-[15px] '
                  onClick={() => setIsCreateTag(false)}
                />
              </div>
              <p className='text-center w-full text-jrnyfy-textprimary'>
                Create a New Tag
              </p>
            </div>
            <div className='space-y-5'>
              <p className='text-center text-jrnyfy-textprimary text-[12px]'>
                tagging helps the community group similar journeys together and
                helps users find your journey.
              </p>
            </div>
            <div>
              <div className='flex flex-col mt-[20px] relative'>
                <input
                  spellCheck='true'
                  className='resize-none  min-h-[30px] z-40 break-words text-[20px]'
                  value={tagData.name}
                  onChange={(e) =>
                    setTagData({
                      ...tagData,
                      name: e.target.value,
                      createForDocId: activity.id,
                      createForDocSk: activity.sk,
                      createForDocCreatedAt: activity.createdAt,
                      createFor:
                        userType == USER_TYPE.USER
                          ? CreateFor.Journey
                          : CreateFor.Service,
                    })
                  }
                />

                <span
                  className=' absolute text-center pointer-events-none w-full text-[20px] min-[350px]:max-[460px]:text-[17px] min-[350px]:max-[460px]:leading-[21px] text-jrnyfy-textprimary font-Montserrat z-[999]'
                  style={
                    tagData.name ? { display: 'none' } : { display: 'block' }
                  }
                >
                  Add the name of the tag
                </span>
              </div>
            </div>
            <div className='space-y-5 flex items-center justify-center flex-col'>
              <div className='h-32 w-[200px] bg-jrnyfy-border rounded-[30px] flex justify-center items-center'>
                <img
                  src={
                    tagData.photo
                      ? URL.createObjectURL(tagData.photo)
                      : NoPicture
                  }
                  alt='select_image_thumbnail'
                  className={`${
                    tagData.photo
                      ? 'w-full h-full rounded-[30px]'
                      : 'w-[30px] h-[30px]'
                  }`}
                />
              </div>
              <label className='mx-auto p-[10px] border w-fit text-jrnyfy-textprimary  border-jrnyfy-border rounded-[30px] flex justify-center items-center'>
                add a picture of the tag
                <input
                  onChange={(e) =>
                    setTagData({ ...tagData, photo: e.target.files[0] })
                  }
                  type='file'
                  accept='image/*'
                  className='hidden'
                />
              </label>
            </div>
            <div className='flex flex-col mt-[20px] relative'>
              <textarea
                spellCheck='true'
                className='resize-none border-b border-jrnyfy-colorLightGray  min-h-[67px] z-40 break-words text-[20px] max-[600px]:text-[14px] focus:outline-none text-jrnyfy-textprimary'
                value={tagData.description}
                onChange={(e) =>
                  setTagData({
                    ...tagData,
                    description: e.target.value,
                  })
                }
              />

              <span
                className=' absolute top-0 pointer-events-none w-full text-[20px] max-[600px]:text-[14px]  text-jrnyfy-textprimary font-Montserrat z-[999]'
                style={
                  tagData.description
                    ? { display: 'none' }
                    : { display: 'block' }
                }
              >
                Add a description of the tag. If it is a place or a things to
                do, describe it
              </span>
            </div>
            <div className='flex justify-center mt-5'>
              <button
                onClick={() => {
                  dispatch(
                    createNewTag({
                      formData: tagData,
                      token: auth.token,
                      callback: (res) => {
                        if (userType == USER_TYPE.VENDOR) {
                          dispatch(addTags({ tags: res }));
                        } else if (userType == USER_TYPE.USER) {
                          dispatch(addTagsActivity({ tags: res }));
                        }
                        setIsCreateTag(false);
                      },
                    })
                  );
                }}
                className='bg-jrnyfy-themeColor p-[10px_20px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic'
              >
                add your tag
              </button>
            </div>
          </div>
        ) : (
          <div className='grow overflow-y-auto flex flex-col items-stretch content-stretch'>
            {/* <img
              src={Cross}
              alt='cross'
              className='cursor-pointer w-[25px] absolute top-[20px] left-[25px] z-40'
              onClick={() => tagListModalHandle(false)}
            /> */}
            <div className='overflow-y-auto grow'>
              <TagList tagListModalHandle={tagListModalHandle} type='journey' />
            </div>
            <div className='space-y-2'>
              <p className='text-center'>Can't find a tag for your journey?</p>
              <div className='flex justify-center'>
                <button
                  onClick={() => setIsCreateTag(true)}
                  className='cursor-pointer border-2 border-solid border-jrnyfy-border p-[5px_7px] md:p-[5px_10px] rounded-[30px] text-jrnyfy-textprimary text-[15px] md:font-bold text-center'
                >
                  Create a New Tag
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default TagListModal;
