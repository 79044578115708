import React, { useRef, useState } from 'react'
import Thumbnail from '../thumbnails/Thumbnail'
import { ContentFilter, PhotoSize, UserActionComment } from '../utils/subjects'
import Moment from 'react-moment'
import LikeIcon from "../../assets/images/feed_component_like.svg";
import Heart from "../../assets/images/heart_icon.svg";
import Icons from '../Icons/Icons';
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import { useDispatch } from 'react-redux';
import { getJourneyContent, updateComment } from '../../redux/slices/mobile/activitySlice';
import { commentParser } from '../../redux/slices/mobile/elasticParser';
import CustomPopUp from '../SharedComponents/CustomPopUp';
import LikeModal from '../FeedCard/likeModal';

export default function RenderComment({
    comment,
    lavel,
    index,
    parent,
    itemDetails,
    addComment,
    deleteCommentItem,
    addReplies,
    navigation,
    auth,
}) {

    const [commentLikes, setCommentLikes] = useState(comment?.likes);
    const [isLiked, setIsLiked] = useState(comment?.cmtlike);
    const [isReadMore, setIsReadMore] = useState(false);
    const [showReplies, setShowReplies] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [likedList,setLikedList] = useState(false)
    const commentInputRef = useRef()
    const [isCommentOption, setIsCommentOption] = useState(false)
    // const [reply, setReply] = useState('');
    // const [isReply, setIsReply] = useState(false);
    const [replyDetails, setReplyDetails] = useState({})
    const commentOptions = useRef();
    const reportModalRef = useRef();
    const dispatch = useDispatch()

    const likeListHandle = ()=>{
        setLikedList(prevState=>!prevState)
    }

    const updateCommentItem = status => {
        let data = {
            docSk: comment.sk,
            timelineId: itemDetails.id,
            id: itemDetails.activity.id,
            sk: itemDetails.activity.sk,
            createdAt: itemDetails.activity.createdAt,
            action: status,
        };
        console.log('dfdfjdkfjdlkfdjklf', parent)
        if (parent?.length > 1) {
            console.log('lavelonlisekunline', parent);

            data['comment'] = {
                id: parent[0].id,
                sk: parent[0].sk,
            };
        }
        if (parent?.length > 2) {
            console.log('IsitreplyDetails');

            data['subComment'] = {
                id: parent[1].id,
                sk: parent[1].sk,
            };
        }

        dispatch(
            updateComment({
                data: data,
                id: comment.id,
                token: auth.token,
                callback: () => { },
            }),
        );
    };

    const getReplies = page => {
        setShowReplies(true);
        if (!isLoading) {
            setIsLoading(true);
        }
        dispatch(
            getJourneyContent({
                data: {
                    excludeIds: [],
                    from: 0,
                    size: 10,
                    filterId: comment.id,
                    filter: ContentFilter.Comment
                },
                callback: async (res) => {
                    console.log('commentsResponse', res);
                    let comments = await commentParser(res)
                    addReplies(comments, comment.id)
                    setIsLoading(false);
                    //setCurrentPage(page + 10);
                    if (comments?.length < 10) {
                        //setIsEnd(true);
                    }
                },
                token: auth.token,
            }),
        );
    };
    return (
        <div>
            <div className='my-5'>
                <div className='flex justify-between'>
                    <div className="flex items-start">
                        <Thumbnail
                            ShowName={false}
                            btnFollow={false}
                            data={comment?.user}
                            PhotoSize={PhotoSize.Small}
                        />
                        <div className='pl-3'>
                            <p className="max-[767px]:text-[14px] text-jrny font-semibold">
                                <span className="text-jrnyfy-title">
                                    {comment?.user.name}
                                </span>
                            </p>
                            <p className="">{comment?.description} </p>
                            <div className='flex items-center gap-3'>
                                <p onClick={() => {
                                    if (auth.user.id == comment.user.id) {
                                        setReplyDetails(comment.user)
                                    } else {
                                        setReplyDetails({ ...comment.user, text: comment.user.name })
                                    }
                                    setTimeout(() => {
                                        commentInputRef.current.focus()
                                    }, 1);
                                }} className="cursor-pointer">
                                    Reply
                                </p>
                                <p className="text-[14px] font-semibold text-jrnyfy-textprimary">
                                    <Moment fromNow>{comment?.createdAt}</Moment>
                                </p>
                            </div>
                            {comment?.replyCount > 0 && !showReplies && (<p className="cursor-pointer" onClick={() => getReplies(0)}>Show {comment?.replyCount} replies</p>)}
                        </div>

                    </div>
                    {comment.status == 0 ? (<p>Posting ...</p>) : (<div className="flex items-center">
                        <div className='flex flex-col items-center'>
                            <Icons
                                iconClick={() => {
                                    if (isLiked) {
                                        setIsLiked(true);
                                        setIsLiked(false)
                                        updateCommentItem(UserActionComment.Dislike);
                                        setCommentLikes(prevVal => (prevVal = prevVal - 1));
                                    } else {
                                        setIsLiked(true);
                                        updateCommentItem(UserActionComment.Like);
                                        setCommentLikes(prevVal => (prevVal = prevVal + 1));
                                    }
                                }}
                                icon={isLiked ? Heart : LikeIcon}
                                alt="Like"
                                width="15px"
                            />
                            <p onClick={()=>setLikedList(true)} className='cursor-pointer'>{commentLikes}</p>
                        </div>
                        {likedList && (
                            <LikeModal
                                lModal={likedList}
                                lModalControl={likeListHandle}
                                data={comment}
                                filter={ContentFilter.Like}
                            />
                        )}
                        <div className='relative'>
                            <Icons
                                iconClick={() => setIsCommentOption(true)}
                                icon={Option}
                                alt="Option"
                                width="7px"
                            />
                            {isCommentOption && (<CustomPopUp setIsPopUp={setIsCommentOption}>
                                <button
                                    onClick={() => {
                                        deleteCommentItem(comment, parent, lavel, index);
                                    }}
                                    className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'>
                                    Remove
                                </button>
                            </CustomPopUp>)}
                        </div>
                    </div>)}
                </div>
                {replyDetails.id && (<div className={`flex items-center space-x-2 mt-[40px] ml-${lavel >= 2 ? 0 : 10}`}>
                    <div className="w-[40px] h-[40px] text-center text-white rounded-[50%] bg-jrnyfy-themeColor text-[25px]">
                        <Thumbnail
                            showName={false}
                            btnFollow={false}
                            data={auth.user}
                            PhotoSize={PhotoSize.Small}
                        />
                    </div>
                    <div className="w-full">
                        <input
                            ref={commentInputRef}
                            type="text"
                            className="focus:outline-none border-b-[2px] border-solid border-jrnyfy-border w-full"
                            placeholder="Write a reply"
                            value={replyDetails.text}
                            onChange={(e) => {
                                setReplyDetails({ ...replyDetails, text: e.target.value });
                            }}
                        />
                    </div>
                    <div>
                        <button
                            onClick={() => {
                                setReplyDetails({})
                                addComment(replyDetails.text, comment?.id, parent)
                            }}
                            className="bg-jrnyfy-themeColor p-[5px_8px] text-[15px] font-semibold rounded-[50px] text-white font-SourceSansPro"
                        >
                            comment
                        </button>
                    </div>
                </div>)}
            </div>
            {comment?.children?.length > 0 && (<>
                {comment.children.map((item, index) => (
                    <div className={`ml-${lavel >= 2 ? 0 : 10}`}>
                        <RenderComment
                            comment={item}
                            lavel={lavel + 1}
                            index={index}
                            parent={[...parent, { ...item }]}
                            itemDetails={itemDetails}
                            addComment={addComment}
                            deleteCommentItem={deleteCommentItem}
                            addReplies={addReplies}
                            navigation={navigation}
                            auth={auth}
                        />
                    </div>
                ))}
            </>)}
        </div>
    )
}
