import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountPrivatePublic,
  authSelector,
} from '../../redux/slices/mobile/authSlice';
import { AccountPrivacyMode } from '../utils/subjects';
import Seo from '../Seo/Seo';
import { useNavigate } from 'react-router-dom';
import BackButton from '../BackButton/BackButton';
import DeleteAccountModal from './DeleteAccountModal';

const PrivacySharingSetting = () => {
  const { auth } = useSelector(authSelector);
  const navigate = useNavigate();
  const [accountModal, setAccountModal] = useState(false);
  const [privacyMode, setPrivacyMode] = useState(
    auth?.user?.accountPrivacy == AccountPrivacyMode.Private ? true : false
  );
  const dispatch = useDispatch();
  console.log('acccount private value', auth);
  const toggleAccountPrivate = async (e) => {
    setPrivacyMode((prevState) => !prevState);

    const privacy = await dispatch(
      accountPrivatePublic({
        data: {
          privacy:
            privacyMode == true
              ? AccountPrivacyMode.Public
              : AccountPrivacyMode.Private,
        },
        token: auth.token,
      })
    );
  };

  const handleAccountModal = (action) => {
    setAccountModal(action);
  };
  return (
    <>
      <Seo
        title={` Privacy & Sharing | Control what you share and who sees it`}
        description=''
        type='webapp'
        name='jrnyfy crop.'
        canonicalHref='/setting/privacy-sharing'
      />
      <div className='max-w-[660px] pb-[80px] mx-auto px-5 mt-3 space-y-10'>
        <div className='flex items-start gap-1'>
          <BackButton callback={() => navigate(-1)} />
          <div className='w-fit space-y-3'>
            <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit leading-3'>
              Privacy & Sharing
            </h4>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              Control what you share and who sees it
            </p>
          </div>
        </div>

        <div className='pb-5 flex justify-between'>
          <div>
            <h5 className='text-start text-jrnyfy-textprimary  text-xl w-fit'>
              Make your account private
            </h5>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              Enabling this means no one will be able to view your account
              details or the journeys you create
            </p>
          </div>
          <div className='flex flex-col items-center'>
            <label class='relative inline-flex items-center cursor-pointer'>
              <input
                onChange={(e) => toggleAccountPrivate(e)}
                type='checkbox'
                checked={privacyMode == true ? true : false}
                value={privacyMode}
                class='sr-only peer'
              />
              <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
            <p class='ml-3 text-sm font-medium text-gray-900 dark:text-gray-300'>
              {privacyMode ? 'private' : 'public'}
            </p>
          </div>
        </div>
        <div className='space-y-3'>
          <div>
            <h5 className='text-start text-jrnyfy-textprimary  text-xl w-fit'>
              Delete your account
            </h5>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              This will deactivate your account immediately and delete your
              account automatically within 30 days
            </p>
          </div>

          <button
            onClick={() => handleAccountModal(true)}
            className='bg-jrnyfy-border rounded-[50px] p-[5px_20px] text-[15px] font-bold text-jrnyfy-textprimary'
          >
            Delete Account
          </button>
        </div>
        <DeleteAccountModal
          accountModal={accountModal}
          handleAccountModal={handleAccountModal}
        />
        {/* <button
          className='bg-jrnyfy-border rounded-[50px] mt-[40px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
          onClick={() => navigate(-1)}
        >
          back
        </button> */}
      </div>
    </>
  );
};

export default PrivacySharingSetting;
