import React from 'react'
import ImageVideoView from './ImageVideoView'

export default function DynamicGridGallery({
    items,
    autoPlay,
    fileCount,
    showVidThumb,
    openGallery
}) {
    console.log("DynamicGalleryItems", items)
    return (
        <div>
            {items.fileCount == 1 && (<div className='grid grid-cols-1'>
                <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[0]} autoPlay={false} index={0} openGallery={openGallery}/>
            </div>)}
            {items.fileCount == 2 && (<div className='grid grid-cols-2 gap-2'>
                <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[0]} autoPlay={false} index={0} openGallery={openGallery}/>
                <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[1]} autoPlay={false} index={1} openGallery={openGallery}/>
            </div>)}
            {items.fileCount == 3 && (
                <div className='grid grid-cols-2 gap-2'>
                    <div className='col-span-2'>
                        <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[0]} autoPlay={false} index={0} openGallery={openGallery}/>
                    </div>
                    <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[1]} autoPlay={false} index={1} openGallery={openGallery}/>
                    <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[2]} autoPlay={false} index={2} openGallery={openGallery}/>

                </div>)}
            {items.fileCount >= 4 && (<div className='grid grid-cols-2 gap-2'>
                <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[0]} autoPlay={false} index={0} openGallery={openGallery}/>
                <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[1]} autoPlay={false} index={1} openGallery={openGallery}/>
                <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[2]} autoPlay={false} index={2} openGallery={openGallery}/>
                <div onClick={()=>openGallery(3)} className='relative '>
                    <ImageVideoView containerStyle='max-h-[600px]' mediaStyle='object-cover max-h-[600px]' item={items.files[3]} autoPlay={false} index={3} openGallery={openGallery}/>
                    {items.fileCount > 4 &&(<div className='absolute top-0 flex flex-col justify-center items-center w-full h-full bg-[rgba(0,0,0,0.5)] rounded-[12px]'>
                    <h4 className='text-3xl text-white'>+{(items.fileCount - 4)}</h4>
                    <h4 className='text-3xl text-white'>More</h4>
                    </div>)}
                </div>
            </div>)}

        </div>
    )
}
