import React, { useEffect } from 'react';
import { useGeolocated } from 'react-geolocated';

const GeoLocation = ({ callback }) => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      watchPosition: false,
      userDecisionTimeout: null,
      suppressLocationOnMount: false,
      geolocationProvider: navigator.geolocation,
      isOptimisticGeolocationEnabled: false,
    });
  console.log('GeoLocation Aviable ', isGeolocationEnabled);
  if (callback) {
    callback(isGeolocationEnabled, coords);
  }
  return <></>;
};

export default GeoLocation;
