import { buildUrlToSize } from "../../utils/build-image-url";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import {
  activitySelector,
  addLocationIntoActivity,
} from "../../redux/slices/mobile/activitySlice";
import { PhotoSize, PhotoSource, YesNo } from "../utils/subjects";
import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import {
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import Cross from "../../assets/images/xmark-solid.svg";
import ImageGallery from "react-image-gallery";
import cancelBtnSvg from "../../assets/images/Cancel.svg";
import Thumbnail from "../thumbnails/Thumbnail";
import JrnyListModal from "./JrnyListModal";
import Login from "../Login/Login";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import Icons from "../Icons/Icons";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";
import { timelineDataContext } from "../jrnyTimline/JrnyTimline";
import MapIcon from '../../assets/icons/map-icon.svg'

const LocationAdded = ({ isEditable, index, data }) => {
  const { activity } = useSelector(activitySelector);
  const journeyName = data?.activity?.name ? data?.activity?.name : activity?.name;
  let [mState, setMstate] = useState(false);
  let modalCondition = () => {
    setMstate((state) => !state);
  };
  console.log("JourneyUserB", data);
  const { auth } = useSelector(authSelector);
  const location = data?.feed?.task?.location
    ? data?.feed?.task?.location
    : data?.task?.location;
  const user = data?.feed?.user ? data?.feed?.user : data?.user;
  console.log("JourneyUserA", user);
  let avatar = buildUrlToSize(
    data?.feed ? data?.feed?.user?.thumbnail : data?.user?.thumbnail,
    PhotoSize.Large
  );
  let thumbnail = buildUrlToSize( data?.task?.location?.photo,PhotoSize.Large );
  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();
  let fName = data?.feed?.user?.name.slice(0, 1);
  let mNamePos = data?.feed?.user?.name.search(" ");
  let mName = data?.feed?.user?.name.slice(mNamePos + 1, mNamePos + 2);
  let altTitle = fName + mName;
  let navigate = useNavigate();
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  const [jrnyListModal, setJrnyListModal] = useState(false);

  const [showImageGallery, setShowImageGallery] = useState(false);
  const [isBrokenThumbnail,setIsBrokenThumbnail] = useState(data?.task?.location?.photo)
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);
  const [signUpModal, setSignUpModal] = useState(false);
  let [login, setLogin] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  let loginControl = () => {
    setLogin((state) => !state);
  };

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = "hidden";
  };

  const jrnyListModalHandle = (action) => {
    if (action) {
      window.history.pushState(null, "", location.pathname);
    } else {
      navigate(-1);
    }
    setJrnyListModal(action);
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = "scroll";
  };
  console.log("Locatoin", data);
  useEffect(() => {
    setGalleryImages([]);

    setGalleryImages((prevState) => [
      ...prevState,
      { original: thumbnail, thumbnail: thumbnail },
    ]);
  }, [thumbnail]);

  const addThisToYourJourney = (activity) => {
    console.log("Activity55", activity);
    dispatch(
      addLocationIntoActivity({
        data: {
          latitude: location.coordinates.lat,
          longitude: location.coordinates.lon,
          name: location.name,
          locationId: data.id,
          photo: location.photo ? location.photo : undefined,
          sk: activity.sk,
          createdAt: activity.createdAt,
        },
        token: authinfo.token,
        id: activity.id,
        user: authinfo.user,
      })
    );
  };

  window.addEventListener("popstate", () => {
    if (jrnyListModal) {
      // navigate(null, { replace: true });
      jrnyListModalHandle(false);
    }
  });

  return (
    <div>
      <Popup
        open={mState}
        closeOnDocumentClick={false}
        contentStyle={{
          width: "100%",
          backgroundColor: "transparent",
          border: "none",
        }}
      >
        <div className="h-full max-h-screen overflow-auto">
          <div className="text-right">
            <button
              className="text-[35px] font-bold text-white translate-x-[-260px]"
              onClick={modalCondition}
            >
              &#10006;
            </button>
          </div>
          <div className="flex justify-center">
            <img src={thumbnail} alt="Place" />
          </div>
        </div>
      </Popup>

      <div className="flex justify-between items-start">
        <div className="relative">
          <div className="absolute top-0 left-[-24px]">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>

          {data?.feed?.user ? (
            <p
              className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] w-fit text-jrny font-semibold cursor-pointer"
              onClick={() => {
                // navigate(`/jrny/${data.id}`);
                navigate(getJourneyNavOption(data));
              }}
            >
              <span className="text-jrnyfy-title">
                {data?.feed?.user?.name} added{" "}
                {data?.feed?.task?.location?.name || data?.task?.location?.name}
              </span>{" "}
              to journey: {journeyName}
            </p>
          ) : (
            <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] w-fit text-jrny font-semibold">
              <span className="text-jrnyfy-title">
                {data?.feed?.user?.name} added{" "}
                {data?.feed?.task?.location?.name || data?.task?.location?.name}
              </span>{" "}
              to journey: {journeyName}
            </p>
          )}

          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {data?.feed?.user?.name}, created{" "}
            <Moment fromNow>{data?.feed?.createdAt}</Moment>
          </p>
        </div>
        {isEditable == true && !data?.feed?.user && (
          <div className="ml-[10px] md:ml-[30px]">
            <Icons
              iconClick={() => deleteModalHandle(true)}
              icon={Option}
              alt="Option"
              width="25px"
            />
          </div>
        )}
      </div>

      {deleteModal && (
        <TimelineOptionsModal
          index={index}
          data={data}
          actionStates={actionStates}
          deleteModal={deleteModal}
          deleteModalHandle={deleteModalHandle}
        />
      )}
      {isBrokenThumbnail ?(<div className="flex items-center mt-4 ml-[20px] w-auto">
        {thumbnail &&(
          <img
            src={thumbnail}
            alt="Place"
            className=" w-[120px] h-[120px] rounded-[50%] cursor-pointer object-cover"
            onClick={() => {
              openImageGallery();
            }}
            onError={()=>setIsBrokenThumbnail(false)}
          />
        )}
        <div className="pl-[15px] md:pl-[25px]">
          <h4 className="pb-[5px] leading-[25px] text-jSecTitle font-bold">
            {data?.feed?.task?.description || data?.task?.location?.name}
          </h4>
        </div>
      </div>):(<div className="py-20 border rounded-3xl flex flex-col justify-center items-center ml-5">
        <img src={MapIcon} alt='map icon' />
        <h4 className="block pb-[5px] leading-[25px] text-jSecTitle font-bold">
          {data?.feed?.task?.description || data?.task?.location?.name}
        </h4>
      </div>)}
      
      {data?.task?.location?.name ? null : (
        <button
          onClick={() => {
            if (auth?.token) {
              jrnyListModalHandle(true);
            } else {
              loginControl();
            }
          }}
          className="p-[8px_15px] pr-[20px] md:pr-[30px] mt-[20px] md:mt-[30px] bg-jrnyfy-themeColor rounded-[50px] text-[12px] md:text-[18px] text-white font-semibold"
        >
          add this to your journey
        </button>
      )}
      {/* Shows the current user available journeys */}
      {jrnyListModal && (
        <JrnyListModal
          data={data}
          jrnyListModalHandle={jrnyListModalHandle}
          jrnyListModal={jrnyListModal}
          addThisToYourJourney={addThisToYourJourney}
        />
      )}

      {login && (
        <Login
          setSignUpModal={setSignUpModal}
          mState={login}
          setMState={loginControl}
        />
      )}
    </div>
  );
};

export default LocationAdded;
