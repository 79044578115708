// import { getPhotosUrl } from '../../res/commonFunctions';


export const sortGallery = gallery => {
    const array = [];
    let temp = gallery.map(item => item);
    // let temp2 = temp.sort(
    //   (a, b) => a.position + ''.localeCompare(b.position + ''),
    // );

    let temp2 = temp.sort((a, b) => {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
    });
    // temp2.forEach(p => {
    //   array.push(getPhotosUrl(p.thu));
    // });
    return temp2;
};
