import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl, goggleAPIKey } from '../../../environments/proxyUrl';
import { parse_SourceObjectWithInnerHits_Leads } from './elasticParser';

/**
 * takes on latitude and longitude as parameter and returns
 * nearby locations of that place.
 */
export const getNearbyLocation = createAsyncThunk(
  'GoogleMap/getNearbyLocation',
  async ({ data, latitude, longitude, callback }, { rejectWithValue }) => {
    try {
      const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${latitude},${longitude}&radius=20000&keyword=things%20to%20do&rankby=prominence&key=${goggleAPIKey}`;
      const { data: res } = await axios.get(url, {
        params: { ...data },
        headers: {},
      });
      if (callback) {
        callback(res);
      }
      console.log('raw data getNearbyLocation', res);
      const sortedBytUserRating = res?.results?.sort(function (a, b) {
        return b?.user_ratings_total - a?.user_ratings_total;
      });
      return sortedBytUserRating;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return getNearbyLocation;
    }
  }
);

// Get top sights Places based on text search
export const getTopLocationByTextSearch = createAsyncThunk(
  'GoogleMap/getTopLocationByTextSearch',
  async ({ data, token, callback }, { rejectWithValue }) => {
    try {
      const url = `${baseUrl}/api/tags/v1/place`;
      const { data: res } = await axios.get(url, {
        params: data,
        headers: {
          Authorization: token,
        },
      });
      if (callback) {
        callback(res);
      }
      console.log('raw data getNearbyLocation', res);
      const sortedBytUserRating = res?.results?.sort(function (a, b) {
        return b?.user_ratings_total - a?.user_ratings_total;
      });
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return getNearbyLocation;
    }
  }
);

/**
 * taken place ID as perimeters and return location data
 */
export const getPlaceData = createAsyncThunk(
  'GoogleMap/getPlaceData',
  async ({ data, placeId, callback }, { rejectWithValue }) => {
    try {
      const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${goggleAPIKey}`;
      const { data: res } = await axios.get(url, {
        params: { ...data },
        headers: {},
      });
      if (callback) {
        callback(res.result);
      }
      console.log('raw data getPlaceData', res);
      return res.result;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return getNearbyLocation;
    }
  }
);

// export const getPlaceDetails = createAsyncThunk(
//   'GoogleMap/getPlaceDetails',
//   async ({data, placeId, callback}, {rejectWithValue}) => {
//     try {
//       const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${goggleAPIKey}`;
//       const {data: res} = await axios.get(url, {
//         params: {...data},
//         headers: {},
//       });
//       if (callback) {
//         callback(res.result);
//       }
//       console.log('raw data getPlaceDetails', res);
//       return res.result;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return getNearbyLocation;
//     }
//   },
// );

const goggleMapSlice = createSlice({
  name: 'goggleMapSlice',
  initialState: {
    loadingMapData: false,
    errors: undefined,
    currentLocation: [],
    placeData: undefined,
    userLocation: undefined,
    pageToken: undefined,
    clickedLocation: '',
  },
  reducers: {
    setUserLocation: (state, action) => {
      state.userLocation = {
        ...action.payload,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      };
    },
  },
  extraReducers: {
    [getNearbyLocation.pending]: (state) => {
      state.errors = [];
      state.currentLocation = [];
      state.loadingMapData = true;
    },
    [getNearbyLocation.fulfilled]: (state, action) => {
      state.errors = [];
      state.currentLocation = action.payload;
      state.loadingMapData = false;
    },
    [getNearbyLocation.rejected]: (state, action) => {
      state.loadingMapData = false;
      state.errors = action.payload;
    },
    [getTopLocationByTextSearch.pending]: (state) => {
      state.errors = [];
      state.currentLocation = [];
      state.loadingMapData = true;
      state.pageToken = undefined;
    },
    [getTopLocationByTextSearch.fulfilled]: (state, action) => {
      state.errors = [];
      state.currentLocation = action.payload.results;
      state.pageToken = action.payload.next_page_token;
      state.loadingMapData = false;
    },
    [getTopLocationByTextSearch.rejected]: (state, action) => {
      state.loadingMapData = false;
      state.errors = action.payload;
    },
    [getPlaceData.pending]: (state) => {
      state.errors = [];
      state.placeData = [];
      state.loadingMapData = true;
    },
    [getPlaceData.fulfilled]: (state, action) => {
      state.errors = [];
      state.placeData = action.payload;
      state.loadingMapData = false;
    },
    [getPlaceData.rejected]: (state, action) => {
      state.loadingMapData = false;
      state.errors = action.payload;
    },
  },
});

export const { setUserLocation } = goggleMapSlice.actions;

export default goggleMapSlice.reducer;

export const goggleMapSelector = (state) => state.goggleMapSlice;
