import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { lockNow, locknow } from '../../redux/slices/mobile/bookingSlice';
import { generateServiceDetailsUrl } from '../../utils/get-nav-option';
import { generateSlug } from '../../utils/generate-slug';
import momentTZ from 'moment-timezone';

const SetupForm = ({
  selectedDate,
  selectedPeople,
  selectTime,
  clientSecret,
  activity,
  product,
  paymentCompleteModalHandle,
  paymentModalHandle,
}) => {
  const { auth } = useSelector(authSelector);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const date = new Date();
  console.log('Activity55555', activity);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  console.log('selected Data', selectedDate, 'Selected People', selectedPeople);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/service-details/${generateSlug(
          product.name
        )}?id=${product.id}&createdAt=${product.createdAt}&sk=${product.sk}`,
      },
      redirect: 'if_required',
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      if (activity) {
        console.log(
          'getUTCDate',
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        );
        dispatch(
          lockNow({
            token: auth.token,
            productId: product.id,
            timeSlotId: selectTime.id,
            data: {
              spots: selectedPeople,
              amount: product.mrp * selectedPeople,
              discountType: 0,
              customerId: clientSecret.customer,
              customOfferAvailable: 0,
              year: selectedDate.getFullYear(),
              month: selectedDate.getMonth(),
              day: selectedDate.getDate(),
              createdAt: product.createdAt,
              sk: product.sk,
              activity: {
                id: activity.id,
                name: activity.name,
                createdAt: activity.createdAt,
                sk: activity.sk,
              },
            },
            callback: async (results) => {
              console.log('results', results);
              if ((results.success = true)) {
                paymentModalHandle();
                paymentCompleteModalHandle(activity);
              } else {
                console.log('results from server', results);
              }
            },
          })
        );
      } else {
        dispatch(
          lockNow({
            token: auth.token,
            productId: product.id,
            timeSlotId: selectTime.id,
            data: {
              spots: selectedPeople,
              amount: product.mrp * selectedPeople,
              discountType: 0,
              customerId: clientSecret.customer,
              customOfferAvailable: 0,
              year: selectedDate.getUTCFullYear(),
              month: selectedDate.getUTCMonth(),
              day: selectedDate.getUTCDate(),
              createdAt: product.createdAt,
              sk: product.sk,
              userTimeZone: momentTZ.tz.guess(),
            },
            callback: async (results) => {
              if ((results.success = true)) {
                paymentModalHandle();
                paymentCompleteModalHandle(results?.activity);
              } else {
                console.log('results from server', results);
              }
            },
          })
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        className={`${
          stripe
            ? 'cursor-pointer bg-jrnyfy-themeColor '
            : 'cursor-not-allowed disabled:opacity-30 bg-jrnyfy-textprimary'
        } p-[6px_15px_6px_15px] mt-5 text-[14px] w-full italic rounded-[50px] text-white font-semibold text-jSecTitle font-SourceSansPro`}
        disabled={!stripe}
      >
        Confirm Payment
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
      {successMsg && <div>{successMsg}</div>}
    </form>
  );
};

export default SetupForm;
