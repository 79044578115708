import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelector,
  updateProfile,
} from "../../redux/slices/mobile/authSlice";
import Seo from "../Seo/Seo";
import { useNavigate } from "react-router-dom";
import BackButton from "../BackButton/BackButton";

const NotificationSetting = () => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pushNotificationStatus, setPushNotificationStatus] = useState(
    !!auth.user.pushNotify
  );

  const togglePushNotification = async (toggleStatus) => {
    console.log("ToggleStatus", toggleStatus.target.checked);
    setPushNotificationStatus(toggleStatus.target.checked);

    const resultAction = await dispatch(
      updateProfile({
        data: { pushNotify: +toggleStatus.target.checked },
        token: auth.token,
      })
    );
    //auth.user.pushNotify=oggleStatus.target.checked
  };
  return (
    <>
      <Seo
        title={` Notifications | Choose notifications preferences`}
        description=""
        type="webapp"
        name="jrnyfy crop."
        canonicalHref="/setting/notification"
      />
      <div className="max-w-[660px] pb-[80px] mx-auto px-5 mt-3 space-y-10">
        <div className="flex items-start gap-1">
          <BackButton callback={() => navigate(-1)} />
          <div className="w-fit space-y-3">
            <h4 className="text-start text-jrnyfy-textprimary text-2xl w-fit leading-3">
              Notifications
            </h4>
            <p className="text-start text-jrnyfy-textprimary w-fit">
              Choose notifications preferences
            </p>
          </div>
        </div>

        <div className="pb-5 flex justify-between">
          <div>
            <h5 className="text-start text-jrnyfy-textprimary  text-xl w-fit">
              Receive Push Notifications
            </h5>
            <p className="text-start text-jrnyfy-textprimary w-fit">
              you are now able to see notifications as they appear
            </p>
          </div>
          <div className="flex flex-col items-center">
            <label class="relative inline-flex items-center cursor-pointer">
              <input
                onChange={(val) => togglePushNotification(val)}
                type="checkbox"
                checked={
                  pushNotificationStatus && pushNotificationStatus == true
                    ? true
                    : false
                }
                value={
                  auth.user && auth.user.pushNotification === 1 ? true : false
                }
                class="sr-only peer"
              />
              <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
            <p class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              public
            </p>
          </div>
        </div>
        {/* <button
          className='bg-jrnyfy-border rounded-[50px] mt-[40px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
          onClick={() => navigate(-1)}
        >
          back
        </button> */}
      </div>
    </>
  );
};

export default NotificationSetting;
