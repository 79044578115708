import { useNavigate } from 'react-router-dom';
import { getUserNavOption } from '../../utils/get-nav-option';
import ReactStars from 'react-rating-stars-component';

const Persons = ({
  user,
  avater,
  name,
  rating,
  userid,
  className,
  nameStyle,
  id,
}) => {
  let navigate = useNavigate();
  let fName = name?.slice(0, 1);
  let mNamePos = name?.search(' ');
  let mName = name?.slice(mNamePos + 1, mNamePos + 2);
  let altTitle = fName + mName;

  let clickControl = () => {
    if (id) {
      // navigate(`/user/${id}`);
      navigate(getUserNavOption(user));
    }
  };

  return (
    <div className='flex items-center cursor-pointer hover:bg-gray-100 rounded-[5px] p-[5px]'>
      <div
        className={`text-center text-[22px] bg-jrnyfy-themeColor font-SourceSansPro italic ${className}`}
        onClick={clickControl}
      >
        {avater === undefined ? (
          altTitle
        ) : (
          <img src={avater} alt={altTitle} className={className} />
        )}
      </div>
      <div className='ml-[10px] overflow-hidden max-w-[245px]'>
        <h4
          className={`${nameStyle} max-[500px]:text-[14px] md:overflow-hidden md:whitespace-nowrap md:text-ellipsis`}
        >
          {name}
        </h4>
        {rating && (
          <div className='flex items-center gap-1'>
            <ReactStars
              count={5}
              size={18}
              edit={false}
              value={rating}
              isHalf={true}
              emptyIcon={<i className='far fa-star'></i>}
              halfIcon={<i className='fa fa-star-half-alt'></i>}
              fullIcon={<i className='fa fa-star'></i>}
              activeColor='#ffd700'
            />
            <span className='text-[14px] mt-1'>{rating}</span>
          </div>
        )}
        <p className='text-jrnyfy-textprimary leading-[18px] text-[14px] font-medium '>
          {userid}
        </p>
      </div>
    </div>
  );
};

export default Persons;
