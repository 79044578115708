import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactStars from 'react-rating-stars-component';
import { FreeMode } from 'swiper';
import { buildUrlToSize } from '../../res/commonFunctions';
import { useNavigate } from 'react-router-dom';
import { generateServiceDetailsUrl } from '../../utils/get-nav-option';
import { PhotoSize } from '../utils/subjects';

const SliderServices = ({ slidesPerView, spaceBetween, services }) => {
  const navigate = useNavigate();
  const productClickHandle = (val) => {
    navigate(generateServiceDetailsUrl(val));
  };
  return (
    <div>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        freeMode={true}
        modules={[FreeMode]}
      >
        {services.map((val, ind) => (
          <SwiperSlide key={ind}>
            <div
              className='cursor-pointer'
              onClick={() => productClickHandle(val)}
            >
              <div>
                <img
                  src={buildUrlToSize(val.thumbnail, PhotoSize.Large)}
                  alt='places'
                  className='rounded-[39px] h-[180px] w-full  cursor-pointer object-cover'
                />
              </div>
              <div className='space-y-1 pb-5'>
                <span className='text-[14px] text-gray-500'>Paris France</span>
                <p className='text-[20px] min-h-[50px] leading-[1.5rem] text-ellipsis overflow-hidden ...'>
                  {val.name}
                </p>
                <div className='flex items-center justify-between'>
                  <div>
                    {val.rating != undefined ? (
                      <ReactStars
                        count={5}
                        size={25}
                        edit={false}
                        value={val.rating}
                        isHalf={true}
                        emptyIcon={<i className='far fa-star'></i>}
                        halfIcon={<i className='fa fa-star-half-alt'></i>}
                        fullIcon={<i className='fa fa-star'></i>}
                        activeColor='#ffd700'
                      />
                    ) : (
                      <span>No Ratings Yet</span>
                    )}
                  </div>
                  <div>
                    {val.mrp == 0 ? (
                      <p className='font-medium text-[20px] text-jrnyfy-themeColor'>
                        Free
                      </p>
                    ) : (
                      <p className='font-medium text-[20px] text-jrnyfy-themeColor'>
                        <span className='text-[14px]'>from </span>
                        {val.mrp}
                      </p>
                    )}
                  </div>
                </div>
                <div className='flex justify-center pt-3'>
                  <button
                    onClick={() => productClickHandle(val)}
                    className='p-[3px_12px_6px_10px] italic rounded-[50px] text-[20px] border border-jrnyfy-themeColor text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white hover:bg-jrnyfy-themeColor transition-all font-SourceSansPro'
                  >
                    book now
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderServices;
