export function convertIntToHourAndMinute(value) {
  return {
    hour: Math.floor(value / 100),
    minute: value % 100,
  };
}

export const convertIntToHourAndMinuteTwelveHourFormat = (value) => {
  let H = Math.floor(value / 100);
  var h = H % 12 || 12;
  // h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
  var ampm = H < 12 ? 'AM' : 'PM';
  // return h +' '+ value % 100+ampm
  return {
    hour: h,
    minute: value % 100,
    ampm,
  };
};

export const convertIntTo12HourFormat = (value) => {
  const hour = Math.floor(value / 100);
  let amPm = 'AM';
  let h = hour;
  if (h >= 12) {
    h = hour - 12;
    amPm = 'PM';
  }
  return {
    hour: h,
    minute: value % 100,
    amPm,
  };
};

export function convertHourAndMinuteToInt(date) {
  return parseInt(
    `${date.getHours()}${
      date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
    }`
  );
}
