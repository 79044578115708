import React, { useEffect, useRef, useState } from 'react'
import playButton from '../../assets/icons/play-button-arrowhead.png'
import pause from '../../assets/icons/pause.png'
import { convertSeconds } from '../utils/static/global_functions'
import scrollHandle from '../../assets/icons/handlescroll-icon.svg'
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { MediaType, PhotoSource } from '../utils/subjects'

export const Trimmer = ({ video, setIsVideo, progressPics,
    setProgressPics }) => {
    console.log('SeleVideoddfddjdlkjdlfkj', video)
    const [isLoading, setIsLoading] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [frames, setFrames] = useState([]);
    const [steps, setSteps] = useState(1)
    const [isTrim, setIsTrim] = useState(false)
    const [firstFrame, setFirstFrame] = useState()
    const leftThumbRef = useRef()
    const rightThumbRef = useRef()
    const sliderContainerRef = useRef()
    const leftMaskRef = useRef()
    const rightMaskRef = useRef()
    const startRef = useRef()
    const endRef = useRef()
    const timelineContainerRef = useRef()
    const selectedTimeRef = useRef()
    const previewVideoRef = useRef()
    const [startTime, setStartTime] = useState('00:00:00')
    const [endTime, setEndTime] = useState('00:00:20')
    const items = Array.from({ length: 10 }, (_, index) => index + 1);
    const [isOverSize, setIsOverSize] = useState(false)

    const [loaded, setLoaded] = useState(false);
    const ffmpegRef = useRef(new FFmpeg());

    const getFrames = (ctx, canvas, video) => {
        return new Promise((resolve, reject) => {
            video.onseeked = () => {
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const frameData = canvas.toDataURL('image/png');
                resolve(frameData)
            };
        })
    }

    const handleExtractFrames = async () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const fps = 1; // Frames per second
        const duration = video.duration; // Video duration in seconds
        const totalFrames = Math.floor(duration * fps);
        const frameInterval = Math.ceil(totalFrames / 10)
        const extractedFrames = [];
        let i = 0;
        while (i < totalFrames) {
            const time = i;
            video.currentTime = time; // Set the video to the current time
            let frame = await getFrames(ctx, canvas, video)
            extractedFrames.push(frame);
            if (i == 0) {
                setFirstFrame(frame)
            }
            i += frameInterval
        }
        setFrames(extractedFrames);
    };
    let prevLeftX = 0
    let prevRightX = 0
    const handleOnMouseDownLeft = (e) => {
        e.preventDefault();
        let isMouseDown = true
        let duration = videoRef.current.duration
        let rect = leftThumbRef.current.getBoundingClientRect()
        let videoTimelineRect = timelineContainerRef.current.getBoundingClientRect()
        let perFrameDuration = videoTimelineRect.width / duration
        prevLeftX = Number(sliderContainerRef.current.style.left.replace('px', ''))
        let offsetX = rect.left - prevLeftX
        const handleOnMouseMove = (e) => {
            if (isMouseDown) {
                e.preventDefault();
                let newClientX = Math.max(0, Math.min((e.clientX - offsetX), videoTimelineRect.width))
                let time = convertSeconds(newClientX / perFrameDuration);
                previewVideoRef.current.currentTime = newClientX / perFrameDuration
                startRef.current.innerHTML = `${time.hours}:${time.minutes}:${time.seconds}`
                sliderContainerRef.current.style.left = `${newClientX}px`
                leftMaskRef.current.style.width = `${newClientX}px`
            }
        }
        const handleOnMouseUp = (e) => {
            let sliderContainerRect = sliderContainerRef.current.getBoundingClientRect()
            let duration = sliderContainerRect.width / perFrameDuration
            setIsOverSize(duration > 60 ? true : false)
            let selectedTime = convertSeconds(duration)
            selectedTimeRef.current.innerHTML = `${selectedTime.hours}:${selectedTime.minutes}:${selectedTime.seconds}`
            document.removeEventListener('mousemove', handleOnMouseMove)
            document.removeEventListener('mouseup', handleOnMouseUp)
            isMouseDown = false
            console.log('handleOnMouseUp', e)
        }
        document.addEventListener('mousemove', handleOnMouseMove)
        document.addEventListener('mouseup', handleOnMouseUp)
    }
    let prevLeftOffsetX = 0
    const handleOnMouseDownRight = (e) => {
        e.preventDefault();
        let isMouseDown = true
        let duration = videoRef.current.duration
        let rect = rightThumbRef.current.getBoundingClientRect()
        let videoTimelineRect = timelineContainerRef.current.getBoundingClientRect()
        let perFrameDuration = videoTimelineRect.width / duration
        prevRightX = Number(sliderContainerRef.current.style.right.replace('px', ''))
        let offsetX = rect.right + prevRightX
        const handleOnMouseMove = (e) => {
            if (isMouseDown) {
                let newClientX = Math.max(0, Math.min((offsetX - e.clientX), videoTimelineRect.width))
                let rightDuratoin = (videoTimelineRect.width - newClientX)
                let time = convertSeconds(rightDuratoin / perFrameDuration);
                previewVideoRef.current.currentTime = rightDuratoin / perFrameDuration
                endRef.current.innerHTML = `${time.hours}:${time.minutes}:${time.seconds}`
                sliderContainerRef.current.style.right = `${newClientX}px`
                rightMaskRef.current.style.width = `${newClientX}px`
            }
        }

        const handleOnMouseUp = (e) => {
            let sliderContainerRect = sliderContainerRef.current.getBoundingClientRect()
            let duration = sliderContainerRect.width / perFrameDuration
            setIsOverSize(duration > 60 ? true : false)
            let selectedTime = convertSeconds(duration)
            selectedTimeRef.current.innerHTML = `${selectedTime.hours}:${selectedTime.minutes}:${selectedTime.seconds}`
            document.removeEventListener('mousemove', handleOnMouseMove)
            document.removeEventListener('mouseup', handleOnMouseUp)
            isMouseDown = false
        }
        document.addEventListener('mousemove', handleOnMouseMove)
        document.addEventListener('mouseup', handleOnMouseUp)
    }

    let prevX = 0
    const handleOnMouseDownSelectedRange = (e) => {
        e.preventDefault();
        let isMouseDown = true
        let duration = videoRef.current.duration
        let sliderContainerRect = timelineContainerRef.current.getBoundingClientRect()
        let perFrameDuration = sliderContainerRect.width / duration
        let selectedRect = sliderContainerRef.current.getBoundingClientRect()
        prevX = Number(sliderContainerRef.current.style.left.replace('px', ''))
        let offsetX = selectedRect.left - prevX
        let selectedOffset = selectedRect.width
        let timelineWidth = sliderContainerRect.width - selectedOffset
        const handleOnMouseMove = (e) => {
            if (isMouseDown) {
                e.preventDefault()
                let newClientX = Math.max(0, Math.min((e.clientX - offsetX), timelineWidth))
                let newClientRightX = (sliderContainerRect.width - newClientX - selectedOffset)
                let rightDuratoin = (newClientX + selectedRect.width)
                sliderContainerRef.current.style.left = `${newClientX}px`
                leftMaskRef.current.style.width = `${newClientX}px`
                sliderContainerRef.current.style.right = `${newClientRightX}px`
                rightMaskRef.current.style.width = `${newClientRightX}px`

                let time = convertSeconds(newClientX / perFrameDuration);
                previewVideoRef.current.currentTime = newClientX / perFrameDuration
                startRef.current.innerHTML = `${time.hours}:${time.minutes}:${time.seconds}`

                let timeRight = convertSeconds(rightDuratoin / perFrameDuration);
                endRef.current.innerHTML = `${timeRight.hours}:${timeRight.minutes}:${timeRight.seconds}`
            }
        }

        const handleOnMouseUp = (e) => {
            document.removeEventListener('mouseup', handleOnMouseUp)
            isMouseDown = false
        }
        document.addEventListener('mousemove', handleOnMouseMove)
        document.addEventListener('mouseup', handleOnMouseUp)
    }

    const playVideo = () => {
        setIsPlaying(true)
        previewVideoRef.current.play()
    }

    const pauseVideo = () => {
        setIsPlaying(false)
        previewVideoRef.current.pause()
    }

    const initialize = () => {
        let duration = videoRef.current.duration
        let videoTimelineRect = timelineContainerRef.current.getBoundingClientRect()
        let frameSize = videoTimelineRect.width / duration
        let rightX = duration > 60 ? videoTimelineRect.width - (frameSize * 60) : 0
        sliderContainerRef.current.style.right = `${rightX}px`
        rightMaskRef.current.style.width = `${rightX}px`
        let sliderContainerRect = sliderContainerRef.current.getBoundingClientRect()
        let selectedTime = convertSeconds(sliderContainerRect.width / frameSize)
        selectedTimeRef.current.innerHTML = `${selectedTime.hours}:${selectedTime.minutes}:${selectedTime.seconds}`
    }

    const load = async () => {
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
        const ffmpeg = ffmpegRef.current;
        await ffmpeg.load({
            coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
        });
    }

    const handleTrim = async (e) => {
        const ffmpeg = ffmpegRef.current;
        const videoURL = { path: URL.createObjectURL(video[0]) }
        const startTime = startRef.current.innerHTML;
        const endTime = endRef.current.innerHTML
        setIsLoading(true);
        const fileName = video[0].name;
        await ffmpeg.writeFile(fileName, await fetchFile(video[0]));
        await ffmpeg.exec(
            ['-i',
                fileName,
                '-ss',
                startTime,
                '-to',
                endTime,
                '-acodec',
                'copy',
                '-vcodec',
                'copy',
                `output_1${fileName}`,]
        )
        const data = await ffmpeg.readFile(`output_1${fileName}`);
        const videofile = new Blob([data.buffer], { type: 'video/mp4' })
        const fileObj = { url: URL.createObjectURL(videofile), mediaType: MediaType.Video, file: videofile, photoSource: PhotoSource.GooglePlace }
        setProgressPics([...progressPics, fileObj])
        setIsVideo(false)
        setIsLoading(false);
    };

    useEffect(() => {
        load()
    }, [])

    return (
        <div>
            <canvas ref={canvasRef} width="50" height="50" style={{ display: 'none' }} />
            <video style={{ display: 'none' }} ref={videoRef} preload={'auto'} onLoadedData={() => {
                console.log('Isloadvideo')
                handleExtractFrames()
            }} className='w-[200px] rounded-[10px] h-[50vh] w-full object-cover' controls>
                <source src={URL.createObjectURL(video[0])} />
            </video>
            <div>
                <div>
                    <video onLoadedData={() => {
                        console.log('Isloadvideo')
                        initialize()
                    }} ref={previewVideoRef} preload={'auto'} className='w-[200px] rounded-[10px] h-[40vh] w-full object-cover'>
                        <source src={URL.createObjectURL(video[0])} />
                    </video>
                </div>
                {isLoading && (<div className='absolute top-0 left-0  flex flex-col justify-center items-center w-full h-full z-10 rounded-[20px] bg-[rgba(0,0,0,0.4)] rounded-[12px]'>
                    <div>
                        <div className='spinner'></div>
                    </div>
                    <p className='text-white'>Video Trimming, Please Wait ...</p>
                </div>)}
                {/* <button onClick={() => {
                    setIsTrim(prevState => !prevState)
                }}>Trim Video</button> */}
                <div className='flex justify-center mt-3'>
                    <div className='flex gap-3 items-center'>
                        {isPlaying ? (<img onClick={pauseVideo} src={pause} className='w-[20px] h-[20px]' alt='pause button' />) : (<img onClick={playVideo} src={playButton} className='w-[20px] h-[20px]' alt='play button' />)}

                    </div>
                </div>
                <div>
                    <p className='flex justify-between items-center'>
                        <span ref={startRef} className='block w-[60px]'>{startTime}</span>
                        <span ref={selectedTimeRef} className='block w-[60px]'>0.0.0</span>
                        <span ref={endRef} className='block w-[60px]'>{endTime}</span>
                    </p>
                </div>
                <div ref={timelineContainerRef} draggable={false} className='relative h-[50px]'>
                    <div className='relative overflow-hidden rounded-md'>
                        {frames.length > 0 ? (<div draggable={false} className='flex'>
                            {frames.map((frame, index) => (
                                <img draggable={false} key={index} src={frame} alt={`Frame ${index}`} width="50" />
                            ))}
                        </div>) : (<div className='w-full flex bg-black rounded-md'>
                            {items.map((_, index) => (
                                <img key={index} src={firstFrame} alt={`Frame ${index}`} width="50" />
                            ))}
                        </div>)}
                        <div ref={leftMaskRef} className='absolute top-0 left-0 h-full bg-black opacity-50'>

                        </div>
                        <div ref={rightMaskRef} className='absolute top-0 right-0 h-full bg-black opacity-50'>

                        </div>
                    </div>
                    <div ref={sliderContainerRef} draggable={false} className='absolute top-0 h-full right-0 left-0'>
                        {/* <div
                            dir='ltr'
                            draggable={false}
                            ref={leftThumbRef}
                            onMouseDown={handleOnMouseDownLeft}
                            className='absolute -left-[8px] w-[15px] h-full bg-jrnyfy-themeColor  flex items-center justify-center rounded-s-md cursor-ew-resize'>
                            <div draggable={false} className='bg-white w-[2px] h-[15px] rounded-xl' />
                        </div> */}
                        <div
                            dir='ltr'
                            draggable={false}
                            ref={leftThumbRef}
                            onMouseDown={handleOnMouseDownLeft}
                            className='absolute top-0 -left-[15px] w-[15px] h-full bg-jrnyfy-themeColor  flex items-center justify-center rounded-s-md cursor-ew-resize'>
                            <div draggable={false} className='bg-white w-[2px] h-[15px] rounded-xl' />
                        </div>
                        <div
                            onMouseDown={handleOnMouseDownSelectedRange}
                            draggable={false} className=' w-full h-full'>
                            <div draggable={false} className='border-y-2 h-full border-jrnyfy-themeColor'></div>
                        </div>
                        <div
                            dir='rtl'
                            draggable={false}
                            ref={rightThumbRef}
                            onMouseDown={handleOnMouseDownRight}
                            className='absolute top-0 -right-[15px] w-[15px] h-full bg-jrnyfy-themeColor  flex items-center justify-center rounded-s-md cursor-ew-resize'>
                            <div draggable={false} className='bg-white w-[2px] h-[15px] rounded-xl' />
                        </div>
                    </div>

                </div>
                <div className="text-end mt-3">
                    {isOverSize && (<p className='text-jrnyfy-themeColor'>Video cannot exceed 60 seconds.</p>)}
                    <button
                        onClick={() => setIsVideo(false)}
                        className="bg-gray-300 p-[5px_20px] rounded-[50px] font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleTrim}
                        className={`${isOverSize ? 'cursor-not-allowed opacity-30' : 'cursor-pointer opacity-100'} bg-jrnyfy-themeColor p-[5px_20px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic`}
                    >
                        Save
                    </button>
                </div>
            </div>

        </div>
    )
}
