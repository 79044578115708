import React, { useEffect, useRef, useState } from 'react';
import { Calendar, DateRange } from 'react-date-range';
import { format, addDays, addMonths } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { productSelector } from '../../redux/slices/mobile/productSlice';
import { Month } from '../utils/subjects';
import {
  getBooking,
  getDatesOfMonth,
} from '../../redux/slices/mobile/bookingSlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';

const ServiceDetailsSidebar = ({ showHideAvailableTimeHandle }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateSelect, setDateSelect] = useState(false);
  const { auth } = useSelector(authSelector);
  const { product } = useSelector(productSelector);
  const dateRangeRef = useRef();
  const peopleSelectRef = useRef();
  const [showPeopleSelection, setShowPeopleSelection] = useState();
  const [peopleCount, setPeopleCount] = useState(2);
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();

  // const checkDateAvailability = () => {
  //   let isDateAvailable = false;
  //   let n = 1;
  //   let newDate = addMonths(new Date(), 0);

  //   for (let i = 0; i < n; i++) {
  //     let year = format(newDate, 'M');
  //     let month = format(newDate, 'M');
  //     let day = format(newDate, 'e');
  //     let date = format(newDate, 'yyy-MM-dd');

  //     if (product.offMonths.includes(month)) {
  //       if (product.onDates.includes()) {
  //         return false;
  //       } else {
  //         newDate = addMonths(new Date(month,1), );
  //       }
  //     } else {
  //       if (product.offMonths.includes(day)) {
  //         if (product.onDates.includes()) {
  //           return false;
  //         } else {
  //           return true;
  //         }
  //       }
  //       if (product.offMonths.includes(date)) {
  //         if (product.onDates.includes()) {
  //           return false;
  //         } else {
  //           return true;
  //         }
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   checkDateAvailability();
  // }, []);

  const showTimeHandle = () => {
    if (showHideAvailableTimeHandle) {
      showHideAvailableTimeHandle(true, date, peopleCount);
    }
  };

  useEffect(() => {
    const hideModalOutSideClickHandle = (e) => {
      if (!peopleSelectRef?.current?.contains(e.target)) {
        setShowPeopleSelection(false);
      }
      if (!dateRangeRef?.current?.contains(e.target)) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener('mousedown', hideModalOutSideClickHandle);
  });
  let availDates = [];
  function customDayContent(day) {
    let mrp = '';
    let dateMark = true;
    product?.offMonths?.map((item) => {
      if (format(day, 'M') - 1 == item) {
        dateMark = false;
        availDates.indexOf(day) == -1
          ? availDates.push(day)
          : console.log('Not exist');
      }
    });

    product?.offDays?.map((item) => {
      if (format(day, 'e') - 1 == item) {
        dateMark = false;
        availDates.indexOf(day) == -1
          ? availDates.push(day)
          : console.log('Not exist');
      }
    });

    product?.offDates?.map((item) => {
      if (format(day, 'yyy-MM-dd') == item) {
        dateMark = false;
        availDates.indexOf(day) == -1
          ? availDates.push(day)
          : console.log('Not exist');
      }
    });
    mrp = dateMark ? product.mrp : 0;
    product?.monthMrps?.map((item) => {
      if (format(day, 'M') - 1 == item.month) {
        mrp = item.mrp;
      }
    });

    product?.dayMrps?.map((item) => {
      if (format(day, 'e') - 1 == item.day) {
        mrp = item.mrp;
      }
    });

    product?.dateMrps?.map((item) => {
      if (format(day, 'yyy-MM-dd') == item.date) {
        mrp = item.mrp;
      }
    });

    // product.onDates.map((item) => {
    //   if (availDates.includes(item)) {
    //     console.log('Yes INcluds');
    //     availDates.splice(availDates.indexOf(item), 1);
    //   }
    // });
    return (
      <div className='flex flex-col items-center justify-center'>
        <span
          className={`${
            dateMark ? 'text-red-400' : '!text-gray-400'
          } leading-[18px] text-[16px] font-bold`}
        >
          {format(day, 'd')}
        </span>
        {mrp > 0 && (
          <span className='leading-[12px] text-[10px]  !text-jrnyfy-themeColor'>
            ${mrp}
          </span>
        )}
      </div>
    );
  }
  console.log('Available Date', availDates);

  const getAvailableTimeSlot = () => {
    dispatch(
      getBooking({
        data: {
          createdAt: product.createdAt,
          sk: product.sk,
          year: format(date, 'y'),
          month: format(date, 'M') - 1,
          day: format(date, 'd'),
        },
        token: auth.token,
        productId: product.id,
      })
    );
  };
  return (
    <div className='space-y-5 max-w-[330px]'>
      <div>
        {product.mrp == 0 ? (
          <p>
            <span className='text-2xl text-jrnyfy-themeColor font-bold'>
              Free
            </span>
          </p>
        ) : (
          <p>
            from
            <span className='text-2xl text-gray-600 font-bold'>
              {product.mrp} CAD
            </span>
          </p>
        )}
      </div>
      <div className='relative' ref={dateRangeRef}>
        <input
          onClick={() => setShowDatePicker((prevState) => !prevState)}
          className='rounded-[60px] p-[10px_30px] border focus:outline-none w-full text-gray-400'
          type='text'
          value={format(date, 'LLL-dd-yyyy')}
        />
        {showDatePicker && (
          <Calendar
            minDate={new Date()}
            disabledDates={availDates}
            className='absolute top-[50px] left-0 z-40 rounded-xl shadow-xl'
            dayContentRenderer={customDayContent}
            onChange={(item) => setDate(item)}
            date={date}
          />
        )}
      </div>
      <div className='relative' ref={peopleSelectRef}>
        <input
          onClick={() => setShowPeopleSelection((prevState) => !prevState)}
          className='rounded-[60px] p-[10px_30px] border focus:outline-none w-full text-gray-400'
          type='text'
          value={`${peopleCount} People`}
          placeholder='2 People'
        />
        {showPeopleSelection && (
          <div className='absolute top-[50px] bg-white rounded-xl w-full p-[15px] drop-shadow-2xl z-40 space-y-3'>
            <span>You can select up to 13 travelers in total.</span>
            <div className='flex justify-between items-center'>
              <div className='flex flex-col'>
                <span> People</span>
                <span className='text-[12px]'>Minimum: 1,Maximum:13</span>
              </div>
              <div className='flex gap-3'>
                <button
                  onClick={() =>
                    setPeopleCount((prevState) =>
                      prevState > 1 ? prevState - 1 : prevState
                    )
                  }
                  className={`${
                    peopleCount == 1
                      ? 'cursor-not-allowed disabled:opacity-30'
                      : 'cursor-pointer'
                  } rounded-[50%] text-[20px] flex items-center justify-center font-bold text-jrnyfy-themeColor border-jrnyfy-themeColor w-[25px] h-[25px] border hover:bg-jrnyfy-themeColor hover:text-white`}
                  disabled={peopleCount == 1}
                >
                  <span className='mb-[5px]'>-</span>
                </button>
                <span>{peopleCount}</span>
                <button
                  onClick={() =>
                    setPeopleCount((prevState) =>
                      prevState < 13 ? prevState + 1 : prevState
                    )
                  }
                  className={`${
                    peopleCount >= 13
                      ? 'cursor-not-allowed disabled:opacity-30'
                      : 'cursor-pointer'
                  } rounded-[50%]  text-[20px] flex items-center justify-center font-bold text-jrnyfy-themeColor border-jrnyfy-themeColor w-[25px] h-[25px] border hover:bg-jrnyfy-themeColor hover:text-white`}
                  disabled={peopleCount == 13}
                >
                  <span className='mb-[3px]'>+</span>
                </button>
              </div>
            </div>
            <button
              onClick={() => {
                showTimeHandle(true);
                setShowPeopleSelection(false);
              }}
              className={`p-[3px_12px_6px_10px] w-full italic rounded-[50px] border border-jrnyfy-themeColor text-white bg-jrnyfy-themeColor hover:scale-[1.1] hover:text-white hover:bg-jrnyfy-textprimary hover:border-jrnyfy-textprimary transition-all font-SourceSansPro`}
            >
              Apply
            </button>
          </div>
        )}
      </div>
      <div>
        <button
          onClick={() => {
            getAvailableTimeSlot();
            showTimeHandle(true);
          }}
          className='p-[10px_15px_10px_15px] italic rounded-[50px] text-[14px] border border-jrnyfy-themeColor text-white bg-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white hover:bg-jrnyfy-themeColor hover:border-jrnyfy-textprimary transition-all font-SourceSansPro'
        >
          check availability
        </button>
      </div>
      <div>
        <button className='p-[10px_30px_10px_30px] italic rounded-[50px] text-[14px] border border-jrnyfy-themeColor text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white hover:bg-jrnyfy-themeColor transition-all font-SourceSansPro'>
          add it to wishlist
        </button>
      </div>
    </div>
  );
};

export default ServiceDetailsSidebar;
