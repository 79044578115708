import { configureStore } from '@reduxjs/toolkit';

import authSlice from '../slices/mobile/authSlice';
import productSlice from '../slices/mobile/productSlice';
import tagSliceV2 from '../slices/mobile/tagSliceV2';
import interestSlice from '../slices/mobile/interestSlice';

import goggleMapSlice from '../slices/mobile/goggleMapSlice';

import staticApisReducer from '../slices/mobile/staticApis';
import paymentReducer from '../slices/mobile/paymentSlice';
import taskReducer from '../slices/mobile/taskSlice';
import activityReducerV2 from '../slices/mobile/activitySlice';

import appReducer from '../slices/mobile/app';
import storeReducer from '../slices/mobile/storeSlice';
import feedReducer from '../slices/mobile/feedSlice';
import profileReducer from '../slices/mobile/profileSlice';
import bookingReducer from '../slices/mobile/bookingSlice';
import viewProfileReducer from '../slices/mobile/viewProfileSlice';

import notificationReducer from '../slices/mobile/notificationSlice';
import messagingReducer from '../slices/mobile/messagingSlice';

import stripeReducers from '../slices/mobile/stripeSlice';
import networkStatusReducers from '../slices/web/networkStatusSlice';

import topTagSlice from '../slices/web/topTagSlice';
import serviceSlice from '../slices/web/serviceSlice';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import reportSlice from '../slices/mobile/reportSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, authSlice);

let store = configureStore({
  reducer: {
    message: messagingReducer,
    notification: notificationReducer,
    profile: profileReducer,
    viewProfile: viewProfileReducer,
    app: appReducer,
    booking: bookingReducer,
    store: storeReducer,
    feed: feedReducer,
    activity: activityReducerV2,
    task: taskReducer,
    report: reportSlice,
    payment: paymentReducer,
    apis: staticApisReducer,
    tagV2: tagSliceV2,
    interest: interestSlice,
    product: productSlice,
    auth: persistedReducer,
    stripe: stripeReducers,
    networkStatus: networkStatusReducers,
    service: serviceSlice,
    goggleMapSlice,
    topTagSlice,
  },
});

export default store;

export const persistor = persistStore(store);
