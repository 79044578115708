import React from 'react';
import backBtn from '../../assets/icons/back.png';

const BackButton = ({ callback }) => {
  return (
    <button
      className='flex justify-center items-center -m-[5px] w-[30px] h-[30px] rounded-[50%] hover:bg-jrnyfy-colorLightGray'
      onClick={() => callback()}
    >
      <img src={backBtn} alt='back button' className='w-[10px] mr-[3px]' />
    </button>
  );
};

export default BackButton;
