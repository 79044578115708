import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { JourneyPrivacy } from "../utils/subjects";
import { useDispatch, useSelector } from "react-redux";
import {
  activitySelector,
  changeActivityPrivacyMode,
  deleteParticipantFromActivity,
  removeActivity,
} from "../../redux/slices/mobile/activitySlice";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { removeFromReduxAfterDelete_activity } from "../../redux/slices/mobile/profileSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ShareModal from "./ShareModal";
import UpdateStoryPopUp from "../successStory/UpdateStoryPopUp";
import CompeleteJourneyModal from "../jrnyTimline/CompleteJourneyModal";
import CompleteJourneyModal from "../jrnyTimline/CompleteJourneyModal";
import Cross from "../../assets/images/xmark-solid.svg";
import backBtn from "../../assets/icons/back.png";
import FunctionalButton from "../FunctionalButton/FunctionalButton";
import { getMessages } from "../../redux/slices/mobile/messagingSlice";

const ActivityMangeModal = ({
  setSuccessStoryDescription,
  activityManageModalHandle,
  activityManageModal,
  timeLineContent,
  setTimelineContent,
}) => {
  const navigate = useNavigate();
  const { activity } = useSelector(activitySelector);
  const { auth } = useSelector(authSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [privacy, setPrivacy] = useState(activity.privacy);
  const [shareModal, setShareModal] = useState(false);
  const [showLeaveJourney, setShowLeaveJourney] = useState(false);
  const [completeYourJourneyModal, setcompleteYourJourneyModal] =
    useState(false);

  const [participantLeaveStates, setParticipantLeaveStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const shareModalHandle = (action) => {
    if (action) {
      window.history.pushState(null, "", location.pathname);
    } else {
      navigate(-1);
    }
    setShareModal(action);
  };

  const setShowLeaveJourneyHandle = () => {
    setShowLeaveJourney((prevState) => !prevState);
  };

  const completeYourJourneyModalHandle = () => {
    setcompleteYourJourneyModal((prevState) => !prevState);
  };
  const privacyHandle = () => {
    setShowPrivacy((prevState) => !prevState);
  };

  const showDeleteHandle = () => {
    setShowDelete((prevState) => !prevState);
  };
  console.log("activity", activity.privacy);

  const removeParticipant = (data) => {
    setParticipantLeaveStates({ ...participantLeaveStates, loading: true });
    dispatch(
      deleteParticipantFromActivity({
        data: {
          participantId: auth?.user?.id,
          sk: activity.sk,
          createdAt: activity.createdAt,
          skParticipant: auth?.user?.sk,
        },

        token: auth.token,
        id: activity.id,
      })
    ).then((res) => {
      console.log("Response", res.meta.requestStatus);

      if (res.meta.requestStatus == "fulfilled") {
        setParticipantLeaveStates({
          ...participantLeaveStates,
          loading: false,
          fulfilled: true,
        });
        activityManageModalHandle();
      }
      if (res.meta.requestStatus == "rejected") {
        setParticipantLeaveStates({
          ...participantLeaveStates,
          loading: false,
          rejected: true,
        });
        setTimeout(() => {
          setParticipantLeaveStates({
            ...participantLeaveStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };

  const createMessageChatGroup = () => {
    if (!activity.chat) {
      dispatch(
        getMessages({
          data: {
            journey: {
              id: activity?.id,
              sk: activity?.sk,
              createdAt: activity?.createdAt,
            },
          },
          callback: (res) => {
            navigate("/messenger", { state: res });
          },
          token: auth.token,
        })
      );
    } else {
      navigate("/messenger", { state: activity.chat });
    }
  };

  return (
    <Popup
      open={activityManageModal}
      closeOnDocumentClick={false}
      onClose={() => {
        setShareModal(false);
      }}
      contentStyle={{
        width: "100%",
        height: "100vh",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      <div className="relative bg-white w-[500px] rounded-[20px] p-10  max-[767px]:p-5 max-[767px]:h-screen  max-[767px]:overflow-y-scroll max-[600px]:rounded-none">
        <div className="flex flex-col items-start gap-5">
          {/* <img
            src={Cross}
            alt='cross'
            className='cursor-pointer w-[25px] absolute top-[15px] left-[20px]'
            onClick={activityManageModalHandle}
          /> */}
          <div className="flex gap-3 ">
            <button
              onClick={() =>
                showDelete
                  ? showDeleteHandle()
                  : showLeaveJourney
                  ? setShowLeaveJourneyHandle()
                  : activityManageModalHandle()
              }
            >
              <img
                src={showDelete ? backBtn : showLeaveJourney ? backBtn : Cross}
                alt="back button"
                className="w-[15px] h-[25px]"
              />
            </button>

            <p className="text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary">
              Manage Your Timeline
            </p>
          </div>
          {!showDelete && !showLeaveJourney && (
            <button
              onClick={shareModalHandle}
              className="bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              share on other apps
            </button>
          )}
          {!showDelete && !showLeaveJourney && (
            <button
              onClick={showDeleteHandle}
              className="bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              Delete
            </button>
          )}
          {!showDelete && !showLeaveJourney && activity.groupSize > 1 && (
            <button
              onClick={createMessageChatGroup}
              className="bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              Create Message Chat Group
            </button>
          )}
          {!showDelete && !showLeaveJourney && (
            <button
              onClick={completeYourJourneyModalHandle}
              className="bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              complete your journey
            </button>
          )}
          {!showDelete &&
            !showLeaveJourney &&
            activity?.user?.id != auth?.user?.id && (
              <button
                onClick={setShowLeaveJourneyHandle}
                className="bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
              >
                Leave This Journey
              </button>
            )}
          {completeYourJourneyModal && (
            <CompleteJourneyModal
              setSuccessStoryDescription={setSuccessStoryDescription}
              completeYourJourneyModal={completeYourJourneyModal}
              completeYourJourneyModalHandle={completeYourJourneyModalHandle}
              timeLineContent={timeLineContent}
              setTimelineContent={setTimelineContent}
              activityManageModalHandle={activityManageModalHandle}
            />
          )}
          {showDelete && !showLeaveJourney && (
            <div className="space-y-3">
              <p>Delete Journey?</p>
              <p>
                you took this journey for a reason. you can Archive and come
                back to it later.
              </p>
              <div className="space-x-3">
                <button
                  onClick={() => {
                    dispatch(
                      removeActivity({
                        data: {
                          createdAt: activity.createdAt,
                          sk: activity.sk,
                        },
                        token: auth.token,
                        id: activity.id,
                        callback: (res) => {
                          console.log("open res xxx", res.results);
                          dispatch(
                            removeFromReduxAfterDelete_activity(res.results)
                          );

                          activityManageModalHandle();
                          navigate(-1);
                        },
                      })
                    );
                  }}
                  className={`${"bg-jrnyfy-themeColor text-white border-jrnyfy-themeColor"} border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro`}
                >
                  Delete
                </button>
                <button
                  onClick={showDeleteHandle}
                  className={`${"bg-jrnyfy-themeColor text-white border-jrnyfy-themeColor"} border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro`}
                >
                  Archive
                </button>
              </div>
            </div>
          )}
          {!showDelete && !showLeaveJourney && (
            <button
              onClick={privacyHandle}
              className="bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              Change who can see your journey
            </button>
          )}
          {!showDelete && !showLeaveJourney && (
            <div>
              {showPrivacy && (
                <div className="flex flex-wrap gap-2">
                  {console.log(privacy)}
                  {Object.keys(JourneyPrivacy).map((key) => {
                    return (
                      <button
                        onClick={() => {
                          dispatch(
                            changeActivityPrivacyMode({
                              data: {
                                privacy: parseInt(JourneyPrivacy[key]),
                                createdAt: activity.createdAt,
                                sk: activity.sk,
                              },
                              token: auth.token,
                              id: activity.id,
                            })
                          );
                          setPrivacy(JourneyPrivacy[key]);
                        }}
                        className={`${
                          privacy == JourneyPrivacy[key]
                            ? "bg-jrnyfy-themeColor text-white border-jrnyfy-themeColor"
                            : "bg-white text-jrnyfy-textprimary border-jrnyfy-textprimary"
                        } border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro`}
                      >
                        {key}
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          {shareModal && (
            <ShareModal
              data={activity}
              shareModalHandle={shareModalHandle}
              shareModal={shareModal}
            />
          )}

          {showLeaveJourney && (
            <div className="space-y-3">
              <p>Leave Journey?</p>
              <p>
                you took this journey for a reason. you can Archive and come
                back to it later.
              </p>
              <div className="space-x-3 flex">
                <FunctionalButton
                  actionStates={participantLeaveStates}
                  callback={() => removeParticipant()}
                  actionStatesTexts={{
                    default: "Leave",
                    loading: "Leaving",
                    fulfilled: "Done",
                    rejected: "Failed",
                  }}
                  padding={"5px_10px"}
                  text={{ size: 12, weight: "normal" }}
                />
                <button
                  onClick={setShowLeaveJourneyHandle}
                  className={`${"bg-jrnyfy-themeColor text-white border-jrnyfy-themeColor"} border-2 p-[3px_20px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro`}
                >
                  No
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default ActivityMangeModal;
