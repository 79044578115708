import React from 'react'
import { buildUrlToSize, buildVideoUrl } from '../../res/commonFunctions';
import { MediaType, PhotoSize } from '../utils/subjects';
import VideoPlayer from './VideoPlayer';

export default function ImageVideoView({
  item,
  autoPlay,
  fileCount,
  showVidThumb,
  openGallery,
  containerStyle,
  index,
  mediaStyle
}) {
  console.log("VideoImageView", item)
  return (
    <div className={`${containerStyle} h-full`}>
      {item.mediaType == MediaType.Video ? (
        <VideoPlayer 
        containerStyle={containerStyle} 
        mediaStyle={mediaStyle} 
        item={item} 
        autoPlay={autoPlay} 
        mute={true} 
        loop={true} 
        control={false} 
        callback={()=>{
          if(openGallery){
            openGallery(index)
          }
        }} />
      ) : (<img
        src={buildUrlToSize(item, PhotoSize.Large)}
        alt="places"
        className={`w-full h-full rounded-[12px] ${mediaStyle}`}
        onClick={() => {
          if(openGallery){
            openGallery(index)
          }
        }}
      />)}

    </div>
  )
}
