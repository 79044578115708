import React from 'react';
import Popup from 'reactjs-popup';

const PopUpImageGallery = ({ popUpImage, modalCondition }) => {
  return (
    <Popup
      open={popUpImage.status}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        backgroundColor: 'transparent',
        border: 'none',
      }}
    >
      <div className='h-full max-h-screen overflow-auto'>
        <div className='text-right'>
          <button
            className='text-[35px] font-bold text-white translate-x-[-260px]'
            onClick={() => modalCondition(null)}
          >
            &#10006;
          </button>
        </div>
        <div className='flex justify-center '>
          <img src={popUpImage.value} alt='Place' />
        </div>
      </div>
    </Popup>
  );
};

export default PopUpImageGallery;
