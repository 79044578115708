import React, { useState } from 'react';
import Cross from '../../assets/images/xmark-solid.svg';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, deleteUser } from '../../redux/slices/mobile/authSlice';
import FunctionalButton from '../FunctionalButton/FunctionalButton';

const DeleteAccountModal = ({ accountModal, handleAccountModal }) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [password, setPassword] = useState();
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const removeMyAccount = () => {
    setActionStates({ ...actionStates, loading: true });
    dispatch(
      deleteUser({
        data: { password: password },
        id: auth.user.id,
        callback: (res) => {
          console.log('Delete User Response', res);
        },
        token: auth.token,
      })
    ).then((res) => {
      console.log('Response', res.meta.requestStatus);
      if (res.meta.requestStatus == 'fulfilled') {
        setActionStates({ ...actionStates, loading: false, fulfilled: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
      if (res.meta.requestStatus == 'rejected') {
        setActionStates({ ...actionStates, loading: false, rejected: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };
  return (
    <Popup
      open={accountModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='space-y-5 bg-white w-[420px] rounded-[20px] p-10 max-[500px]:p-5 max-[500px]:h-screen max-[500px]:overflow-y-scroll max-[500px]:rounded-none'>
        <div className='flex items-start gap-3'>
          <button onClick={() => handleAccountModal(false)}>
            <img src={Cross} alt='back button' className='w-[15px] w-[20px]' />
          </button>
        </div>
        <p>To delete your account you have to enter your current password</p>
        <input
          onChange={(e) => setPassword(e.target.value)}
          type='password'
          placeholder='your current password'
          className='w-full bg-jrnyfy-colorLightGray px-5 py-3 rounded-[30px]'
        />
        <div className='pb-5 space-y-6'>
          <FunctionalButton
            actionStates={actionStates}
            callback={() => removeMyAccount()}
            actionStatesTexts={{
              default: 'Delete account',
              loading: 'Deleting account',
              fulfilled: 'Account deleted Successfully',
              rejected: 'Account deletion Failed',
            }}
          />
        </div>
      </div>
    </Popup>
  );
};

export default DeleteAccountModal;
