import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { FreeMode } from 'swiper';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';
import FriendsIcon from '../../assets/images/Friends_Icon.svg';
import Cross from '../../assets/images/xmark-solid.svg';
import activitySlice, {
  activitySelector,
  addParticipantIntoActivity,
  getJourneyContent,
  getParticipantsOfJourney,
} from '../../redux/slices/mobile/activitySlice';
import { authSelector, getUsers } from '../../redux/slices/mobile/authSlice';
import { buildUrlToSize } from '../../utils/build-image-url';
import Persons from '../Persons/Persons';
import {
  ParticipantStatus,
  PhotoSize,
  UserContentFilter,
  UserFilter,
} from '../utils/subjects';
import { onTypeChange } from '../utils/typing-change';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import FollowerList from './FollowerList';

const JrnyTimlineAddPeople = ({
  setTimelineContent,
  timeLineContent,
  modalState,
  modalControl,
  jrnyActivity,
}) => {
  let authinfo = useSelector(authSelector).auth;
  const { activity, participant } = useSelector(activitySelector);

  const [searchText, setSearchText] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  let [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [peopleFilter, setPeopleFilter] = useState(0);
  const inputRef = useRef();
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);

  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  let [userArr, userAdd] = useState([]);

  console.log('userAdd', users);

  const peopleFilterHandle = (filterType) => {
    setPeopleFilter(filterType);
  };

  useEffect(() => {
    dispatch(
      getUsers({
        data: {
          from: currentPage,
          size: 10,
          value: searchText && searchText.length > 0 ? searchText : undefined,
          filter: UserFilter.Participant,
          filterId: activity?.id,
          journeyCreatorId: activity?.user?.id,
        },
        token: authinfo?.token,
        callback: (results) => {
          userAdd(
            results.filter(
              (item) => item?.participant?.status == ParticipantStatus?.Accepted
            )
          );
          if (currentPage == 0) {
            setUsers(results);
          } else {
            setUsers([...users, ...results]);
          }
        },
      })
    );
  }, [searchText, currentPage]);

  useEffect(() => {
    dispatch(
      getJourneyContent({
        data: {
          from: 0,
          size: 25,
          filterId: activity.id,
          filter: UserContentFilter.GetParticipantsOfAJourney,
          participantStatus: ParticipantStatus.Accepted,
        },
        token: authinfo.token,
        activityId: activity.id,
      })
    );
  }, []);

  return (
    <Popup
      open={modalState}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='relative space-y-5 w-[500px] bg-white mx-auto rounded-[20px] p-[40px]  max-[767px]:p-5 max-[767px]:overflow-y-scroll max-[600px]:h-full  max-[600px]:rounded-none '>
        {/* -------cross button-------- */}
        {/* <img
          src={Cross}
          alt='cross'
          className='cursor-pointer w-[25px] absolute top-[20px] left-[25px]'
          onClick={modalControl}
        /> */}

        <div className='flex items-start gap-3'>
          <img
            src={Cross}
            alt='cross'
            className='cursor-pointer w-[20px] '
            onClick={() => modalControl(false)}
          />

          <div className='flex justify-center items-center'>
            <img src={FriendsIcon} alt='place' className='max-w-[45px]' />
            <p className='text-jrny font-semibold text-center'>
              who will you taken on your journey
            </p>
          </div>
        </div>

        {/* ------- main body start---- */}

        <div className='space-y-5'>
          {/* <div className='mb-[20px] '>
            <Swiper
              slidesPerView={6}
              spaceBetween={0}
              freeMode={true}
              modules={[FreeMode]}
            >
              {participant.map((val, ind) => (
                <SwiperSlide key={ind}>
                  <div className='relative text-center mt-[7px] w-[55px]'>
                    <img
                      src={Cross}
                      alt='remove'
                      className='w-[10px] absolute top-[-9px] right-0 cursor-pointer'
                      // onClick={() => {
                      //    (val);
                      // }}
                    />
                    <img
                      src={
                        val?.user?.thumbnail === undefined
                          ? FriendsIcon
                          : buildUrlToSize(
                              val?.user?.thumbnail,
                              PhotoSize.Large
                            )
                      }
                      alt={val.name}
                      className='w-[45px] h-[45px] rounded-[50%] inline-block'
                    />
                    <p className='text-[10px] font-semibold leading-[10px] max-w-[55px]'>
                      {val.name}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}

          <div className='relative w-full'>
            <input
              type='search'
              ref={inputRef}
              placeholder='find people'
              className='bg-gray-100 w-full p-[10px_20px] rounded-[50px]'
              onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                setCurrentPage(0);
                setUsers([]);
                setSearchText(value);
              })}
            />
            {/* <button className="w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]"></button> */}
          </div>
          <div className='space-x-3 flex '>
            <button
              onClick={() => peopleFilterHandle(0)}
              className={`${
                peopleFilter == 0
                  ? ' bg-jrnyfy-themeColor '
                  : 'bg-jrnyfy-textprimary'
              } p-[10px_15px] rounded-[50px] text-white font-semibold italic hover:scale-[1.1] max-[767px]:text-[14px] max-[767px]:p-[5px_10px] transition-all font-SourceSansPro `}
            >
              Add new people
            </button>
            <button
              onClick={() => peopleFilterHandle(1)}
              className={`${
                peopleFilter == 1
                  ? ' bg-jrnyfy-themeColor'
                  : 'bg-jrnyfy-textprimary'
              } p-[10px_15px]  rounded-[50px] text-white  font-semibold italic hover:scale-[1. 1] max-[767px]:text-[14px] max-[767px]:p-[5px_10px] transition-all font-SourceSansPro `}
            >
              People in your Journey
            </button>
          </div>

          <div className='space-y-2 max-h-[400px] pr-3 overflow-y-auto'>
            {peopleFilter == 0
              ? users.map((val, ind) => (
                  <FollowerList
                    users={users}
                    setUsers={setUsers}
                    jrnyActivity={jrnyActivity}
                    userArr={userArr}
                    userAdd={userAdd}
                    val={val}
                    ind={ind}
                  />
                ))
              : participant.map((val, ind) => (
                  <FollowerList
                    users={users}
                    setUsers={setUsers}
                    jrnyActivity={jrnyActivity}
                    userArr={userArr}
                    userAdd={userAdd}
                    val={val}
                    ind={ind}
                  />
                ))}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default JrnyTimlineAddPeople;
