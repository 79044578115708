import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { tagPaginationOffset } from '../../../res/dimens';
import { baseUrl } from '../../../environments/proxyUrl';
import {
  parse_SourceArray,
  parse_SourceObjectWithInnerHitsTags,
} from './elasticParser';
import moment from 'moment';
import { MessageType, YesNo } from '../../../components/utils/subjects';
import { getUnseenChats } from '../../../components/utils/static/global_functions';

export const getUserFilter = createAsyncThunk(
  'messaging/filters',
  async ({ token }, { rejectWithValue }) => {
    console.log('get messages filter2');

    return {
      filters: [
        {
          name: 'all',
          label: 'all',
        },
        {
          name: 'mutual',
          label: 'mutual',
        },
        {
          name: 'follower',
          label: 'follower',
        },
        {
          name: 'following',
          label: 'following',
        },
      ],
    };
  },
);

export const getMessages = createAsyncThunk(
  'messages/getMessages',
  async ({ data, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/sockets/v1/message/`,
        {
          params: data,
          headers: { Authorization: token },
        },
      );
      console.log('GetMessages', res);
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const addUserToGroup = createAsyncThunk(
  'messages/addUserToGroup',
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/sockets/v1/group/`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getChats = createAsyncThunk(
  'messages/getChats',
  async ({ token, data, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(`${baseUrl}/api/sockets/v1/chat`, {
        params: data,
        headers: { Authorization: token },
      });
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const uploadPhoto = createAsyncThunk(
  'messages/uploadPhoto',
  async ({ formData, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/sockets/v1/uploader`,
        formData,
        {
          headers: {
            Authorization: token,
            'Content-Type': `multipart/form-data;`,
          },
        },
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);
export const findMessages = createAsyncThunk(
  'messaging/findMessages',
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/sockets/v1/search`,
        data,
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const seenMessage = createAsyncThunk(
  'messaging/seenMessage',
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/sockets/v1/message/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const deleteMessage = createAsyncThunk(
  'messaging/deleteMessage',
  async ({ data, id, callBack, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/sockets/v1/message/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callBack) {
        callBack(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const editMessage = createAsyncThunk(
  'messaging/editMessage',
  async ({ data, id, callback, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/sockets/v1/message/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res.results);
      }

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const editGroupName = createAsyncThunk(
  'messaging/editGroupName',
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/sockets/v1/chat/${id}`,
        data,
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const removeMember = createAsyncThunk(
  'messaging/removeMember',
  async ({ data, callback, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/sockets/v1/remove-member`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const muteMember = createAsyncThunk(
  'messaging/muteMember',
  async ({ data, chatId, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/sockets/v1/member-mute/${chatId}`,
        data,
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const blockChatGroup = createAsyncThunk(
  'messaging/blockChatGroup',
  async ({ data, chatId, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/sockets/v1/member-block/${chatId}`,
        data,
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const unmuteMember = createAsyncThunk(
  'messaging/unmuteMember',
  async ({ data, chatId, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/sockets/v1/member-mute/${chatId}`,
        data,
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// export const deleteChat = createAsyncThunk(
//   'messaging/deleteChat',
//   async ({data, token}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.post(
//         `${baseUrl}/api/sockets/v1/messages/group/delete-chat`,
//         data,
//         {
//           headers: {Authorization: token},
//         },
//       );

//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
export const leaveFromGroup = createAsyncThunk(
  'messaging/leaveFromGroup',
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/sockets/v1/left-member`,
        data,
        {
          headers: { Authorization: token },
        },
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const sendMessage = createAsyncThunk(
  'messaging/sendMessage',
  async ({ data, callback, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/sockets/v1/message`,
        data,
        {
          headers: { Authorization: token },
        },
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const messagingSlice = createSlice({
  name: 'message',
  initialState: {
    messages: {
      messages: [],
      chat: undefined,
    },
    chats: {
      chats: [],
      messages: [],
    },
    errors: [],
    filters: [],
    users: [],
    updatedSelectedChat: '',
    loading: false,
  },
  reducers: {
    pushMessage: (state, action) => {
      const { message, callBack } = action.payload;
      console.log('MEssage Last', message);
      if (state.messages.chat) {
        if (state.messages.chat.id == message.chat.id) {
          if (
            message.message.type == MessageType.Photo ||
            state.messages?.messages?.length == 0
          ) {
            state.messages.messages[0] = message.message;
          } else {
            state.messages.messages.unshift(message.message);
          }
        } else {
          if (callBack && message.message.notify == 1) {
            callBack(1);
          }
        }
      } else {
        if (callBack && message.message.notify == 1) {
          callBack(1);
        }
      }
    },
    memberConnectionStatus: (state, action) => {
      const { id, status } = action.payload;
      state.chats.chats.forEach((item, index) => {
        if (item.members[0].id == id && item.group != YesNo.Yes) {
          state.chats.chats[index].members[0].connected = status;
          if (state.chats.chats[index].id == state?.messages?.chat?.id) {
            state.messages.chat = state.chats.chats[index];
          }
        } else if (item.members[1].id == id && item.group != YesNo.Yes) {
          state.chats.chats[index].members[1].connected = status;
          if (state.chats.chats[index].id == state?.messages?.chat?.id) {
            state.messages.chat = state.chats.chats[index];
          }
        }
      });
      console.log('ConnectedStatus', JSON.stringify(state.chats));
    },
    updateChat: (state, action) => {
      const { chat } = action.payload;
      console.log('update chat5555555', chat);
      state.chats.chats.forEach((item, index) => {
        if (item.id == chat.chat.id) {
          console.log('Matched Chat');
          state.chats.chats[index].members = chat?.chat?.members;
          state.updatedSelectedChat = [index, Math.floor(Math.random() * 100)];
        }
      });
      console.log(
        'After Remove Memeber Chat obejct',
        state.updatedSelectedChat,
      );
      // let array = state.chats.chats.map((item, index) => {
      //   if (item.id == chat.chat.id) {
      //     return { ...item, ...chat.chat };
      //   } else {
      //     return item;
      //   }
      // });
      // state.chats = { ...state.chats, chats: array };

      // if (state.messages.chat) {
      //   if (chat.chat.id == state.messages.chat.id) {
      //     state.messages = {
      //       ...state.messages,
      //       chat: { ...state.messages.chat, ...chat.chat },
      //     };
      //   }
      // }
    },
    addMessage: (state, action) => {
      const { message } = action.payload;
      state.messages.messages.unshift(message);
    },
    replaceMessage: (state, action) => {
      state.messages.messages[0] = action.payload;
    },
    pushChat: (state, action) => {
      const { chat } = action.payload;
      let found = false;
      for (let i = 0; i < state.chats.chats.length; i++) {
        if (chat.id == state.chats.chats[i].id) {
          found = true;
          break;
        }
      }
      if (found) {
        let array = state.chats.chats.map((item, index) => {
          if (chat.id == item.id) {
            return { ...item, ...chat };
          } else {
            return item;
          }
        });
        state.chats.chats = array;
      } else {
        state.chats.chats.unshift(chat);
      }
    },
    updateMessage: (state, action) => {
      const { message } = action.payload;
      console.log('update messge', message);
      let array = state.messages.messages.map((item, index) => {
        if (
          state.messages.chat.id == message.chat.id &&
          message.message.id == item.id
        ) {
          return { ...item, ...message.message };
        } else {
          return item;
        }
      });

      state.messages = { ...state.messages, messages: array };
    },
    resetChatMessage: (state, action) => {
      const { amISender, chat, message, updatedAt } = action.payload;
      let item = state.chats.chats.find((item, index) => chat.id == item.id);
      console.log('REsetChatMessage', JSON.stringify(item));
      let array = state.chats.chats.filter(item => item.id != chat.id);
      array.unshift({
        ...item,
        message: message,
        updatedAt: updatedAt,
        isSeen: amISender
          ? undefined
          : {
            _id: { chatId: chat.id },
            count: item?.isSeen?.count ? item?.isSeen?.count + 1 : 1,
          },
      });
      state.chats.chats = array;
    },
    resetChat: (state, action) => {
      state.chats = {
        chats: [],
        messages: [],
      };
    },
    resetMessages: (state, action) => {
      state.messages = {
        messages: [],
        chat: undefined,
      };
    },
  },
  extraReducers: {
    [getMessages.pending]: state => {
      state.errors = [];
      // state.messages = [];
      state.messages = {
        messages: [],
        chat: undefined,
      };
      state.loading = true;
    },
    [getMessages.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload) {
        const { messages, chat, ...rest } = action.payload;
        state.chats.chats.every((item, index) => {
          console.log('GetMessageChatItem', JSON.stringify(item), chat.id);
          if (item.id == chat.id) {
            state.chats.chats[index] = chat;
            return false;
          }
          return true;
        });
        let msgs = [];
        if (messages && messages.length > 0) {
          for (let i = 0; i < messages.length; i++) {
            let current = messages[i];
            if (i < messages.length - 1) {
              let d1 = moment(messages[i + 1].createdAt).format('dddd');
              let d2 = moment(messages[i].createdAt).format('dddd');
              if (d1 !== d2) {
                // comparing date of two dates, if they are diffrent then storing nextDate as string for rendering.
                current = {
                  ...current,
                  nextDate: moment(messages[i].createdAt).format(
                    'dddd, MMMM Do YYYY',
                  ),
                };
              } else {
                current = { ...current };
              }
            }
            msgs.push(current);
          }
        }
        state.messages = {
          ...rest,
          chat: chat,
          messages: [...state.messages.messages, ...msgs],
        };

        let found = false;
        for (let i = 0; i < state.chats.chats.length; i++) {
          if (chat.id == state.chats.chats[i].id) {
            found = true;
            break;
          }
        }
        if (found) {
          let array = state.chats.chats.map((item, index) => {
            if (chat.id == item.id) {
              return { ...item, ...chat };
            } else {
              return item;
            }
          });
          state.chats.chats = array;
        } else {
          state.chats.chats.unshift(chat);
        }
      } else {
        state.messages = {};
      }

      state.loading = false;
    },
    [getMessages.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getChats.pending]: state => {
      state.errors = [];
      // state.messages = [];
      state.chats = [];
      state.loading = true;
    },
    [getChats.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload && action.payload.chats) {
        let chats = action.payload.chats.map(item => {
          return {
            ...item,
            isSeen: getUnseenChats(item.id, action.payload.unseenMessages),
          };
        });
        state.chats = {
          chats: chats,
          messages: [],
        };
      } else {
        state.chats = { chats: [], messages: [] };
      }
      state.loading = false;
    },
    [getChats.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [uploadPhoto.pending]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [uploadPhoto.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload) {
        // if (action.payload.chat.id == state.messages.chat.id) {
        //   let maxLength = state.messages.messages.length;
        //   let array = state.messages.messages.map((item, i) => {
        //     if (i == maxLength - 2) {
        //       // n-1 element
        //       let d1 = moment(action.payload.message.createdAt).format('dddd');
        //       let d2 = moment(state.messages.messages[i].createdAt).format(
        //         'dddd'
        //       );
        //       if (d1 !== d2) {
        //         //if new message arrived on a new date
        //         return {
        //           ...item,
        //           nextDate: moment(state.messages.messages[i].createdAt).format(
        //             'dddd, MMMM Do YYYY'
        //           ),
        //         };
        //       } else {
        //         return { ...item };
        //       }
        //     } else {
        //       if (item.token) {
        //         //comparing token only for new messages
        //         if (item.token == action.payload.token) {
        //           return { ...action.payload.message, uploading: false };
        //         } else {
        //           return item;
        //         }
        //       } else {
        //         return item;
        //       }
        //     }
        //   });
        //   state.messages = { ...state.messages, messages: array };
        // }
      }
      state.loading = false;
    },
    [uploadPhoto.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [getUserFilter.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getUserFilter.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload.filters) {
        state.filters = action.payload.filters;
      }
      state.loading = false;
    },
    [getUserFilter.rejected]: (state, action) => {
      state.loading = false;
    },
    [findMessages.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [findMessages.fulfilled]: (state, action) => {
      state.errors = [];
      // console.log('find messages payload', action.payload);
      const { chats, messages } = action.payload;
      state.chats = {
        chats: chats ? chats : [],
        messages: messages ? messages : [],
      };
      state.loading = false;
    },
    [findMessages.rejected]: (state, action) => {
      state.loading = false;
    },
    [seenMessage.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [seenMessage.fulfilled]: (state, action) => {
      state.errors = [];
      // console.log('seen message payload', action.payload);
      if (action.payload) {
        let array = state.messages.messages.map(item => {
          if (item.id == action.payload.id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        });

        state.messages = { ...state.messages, messages: array };
      }
      state.loading = false;
    },
    [seenMessage.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteMessage.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [deleteMessage.fulfilled]: (state, action) => {
      state.errors = [];
      // console.log('delete message payload', action.payload);
      if (action.payload) {
        let array = state.messages.messages.filter(item => {
          if (item.id != action.payload.id) {
            return item;
          }
        });

        state.messages = { ...state.messages, messages: array };
      }
      state.loading = false;
    },
    [deleteMessage.rejected]: (state, action) => {
      state.loading = false;
    },
    [editMessage.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [editMessage.fulfilled]: (state, action) => {
      state.errors = [];
      // console.log('edit message payload', action.payload);
      if (action.payload) {
        let array = state.messages.messages.map(item => {
          if (item.id == action.payload.id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        });

        state.messages = { ...state.messages, messages: array };
      }
      state.loading = false;
    },
    [editMessage.rejected]: (state, action) => {
      state.loading = false;
    },
    [editGroupName.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [editGroupName.fulfilled]: (state, action) => {
      state.errors = [];
      state.chats.chats.every((item, index) => {
        console.log('UpdatedNameFullfilled', item);
        if (item.id == action.payload.id) {
          state.chats.chats[index].name = action.payload.name;
          return false;
        }
        return true;
      });
      state.loading = false;
    },
    [editGroupName.rejected]: (state, action) => {
      state.loading = false;
    },
    [sendMessage.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [sendMessage.fulfilled]: (state, action) => {
      state.errors = [];
      // console.log('send message payload', action.payload);
      if (action.payload.chat.id == state.messages.chat.id) {
        let maxLength = state.messages.messages.length;
        let array = state.messages.messages.map((item, i) => {
          if (i == maxLength - 2) {
            // n-1 element
            let d1 = moment(action.payload.message.createdAt).format('dddd');
            let d2 = moment(state.messages.messages[i].createdAt).format(
              'dddd',
            );
            if (d1 !== d2) {
              return {
                ...item,
                nextDate: moment(state.messages.messages[i].createdAt).format(
                  'dddd, MMMM Do YYYY',
                ),
              };
            } else {
              return { ...item };
            }
          } else {
            // nth element
            if (item.token) {
              if (item.token == action.payload.token) {
                return { ...action.payload.message };
              } else {
                return item;
              }
            } else {
              return item;
            }
          }
        });

        //state.messages = {...state.messages, messages: array};
        // state.messages.messages.push(action.payload.message);
      }
      state.loading = false;
    },
    [sendMessage.rejected]: (state, action) => {
      state.loading = false;
    },
    [addUserToGroup.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [addUserToGroup.fulfilled]: (state, action) => {
      state.errors = [];
      // console.log('addUserToGroup message payload', action.payload);
      // if (action.payload.chat.id == state.messages.chat.id) {
      //   let maxLength = state.messages.messages.length;
      //   state.messages.messages.push(action.payload.message);

      //   let array = state.messages.messages.map((item, i) => {
      //     if (i == maxLength - 2) {
      //       // n-1 element
      //       let d1 = moment(action.payload.message.createdAt).format('dddd');
      //       let d2 = moment(state.messages.messages[i].createdAt).format(
      //         'dddd',
      //       );
      //       if (d1 !== d2) {
      //         return {
      //           ...item,
      //           nextDate: moment(state.messages.messages[i].createdAt).format(
      //             'dddd, MMMM Do YYYY',
      //           ),
      //         };
      //       } else {
      //         return {...item};
      //       }
      //     } else {
      //       return item;
      //     }
      //   });
      //   state.messages = {
      //     ...state.messages,
      //     messages: array,
      //     chat: {...state.messages.chat, ...action.payload.chat},
      //   };
      // }

      // let arrayChats = state.chats.chats.map((item, index) => {
      //   if (item.id == action.payload.chat.id) {
      //     return {...item, ...action.payload.chat};
      //   } else {
      //     return item;
      //   }
      // });

      // state.chats = {...state.chats, chats: arrayChats};

      state.loading = false;
    },
    [addUserToGroup.rejected]: (state, action) => {
      state.loading = false;
    },
    [removeMember.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [removeMember.fulfilled]: (state, action) => {
      state.errors = [];
      console.log('removeMember message payload', action.payload);
      if (action.payload.chat.id == state.messages.chat.id) {
        let maxLength = state.messages.messages.length;
        state.messages.messages.push(action.payload.message);

        let array = state.messages.messages.map((item, i) => {
          if (i == maxLength - 2) {
            // n-1 element
            let d1 = moment(action.payload.message.createdAt).format('dddd');
            let d2 = moment(state.messages.messages[i].createdAt).format(
              'dddd',
            );
            if (d1 !== d2) {
              return {
                ...item,
                nextDate: moment(state.messages.messages[i].createdAt).format(
                  'dddd, MMMM Do YYYY',
                ),
              };
            } else {
              return { ...item };
            }
          } else {
            return item;
          }
        });
        state.messages = {
          ...state.messages,
          messages: array,
          chat: { ...state.messages.chat, ...action.payload.chat },
        };
      }

      let arrayChats = state.chats.chats.map((item, index) => {
        if (item.id == action.payload.chat.id) {
          return { ...item, ...action.payload.chat };
        } else {
          return item;
        }
      });

      state.chats = { ...state.chats, chats: arrayChats };

      state.loading = false;
    },
    [removeMember.rejected]: (state, action) => {
      state.loading = false;
    },
    [leaveFromGroup.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [leaveFromGroup.fulfilled]: (state, action) => {
      state.errors = [];
      console.log('leaveFromGroup message payload', action.payload);
      if (action.payload.chat.id == state.messages.chat.id) {
        let maxLength = state.messages.messages.length;
        let array = state.messages.messages.map((item, i) => {
          if (i == maxLength - 2) {
            // n-1 element
            let d1 = moment(action.payload.message.createdAt).format('dddd');
            let d2 = moment(state.messages.messages[i].createdAt).format(
              'dddd',
            );
            if (d1 !== d2) {
              return {
                ...item,
                nextDate: moment(state.messages.messages[i].createdAt).format(
                  'dddd, MMMM Do YYYY',
                ),
              };
            } else {
              return { ...item };
            }
          } else {
            return item;
          }
        });

        state.messages = {
          ...state.messages,
          messages: array,
          chat: { ...state.messages.chat, ...action.payload.chat },
        };
      }

      let arrayChats = state.chats.chats.map((item, index) => {
        if (item.id == action.payload.chat.id) {
          return { ...item, ...action.payload.chat };
        } else {
          return item;
        }
      });

      state.chats = { ...state.chats, chats: arrayChats };

      state.loading = false;
    },
    [leaveFromGroup.rejected]: (state, action) => {
      state.loading = false;
    },
    // [deleteChat.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [deleteChat.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   console.log('delete chat payload', action.payload);
    //   let arrayChats = state.chats.chats.filter(
    //     (item, index) => item.id !== action.payload.chat.id,
    //   );
    //   state.chats = {...state.chats, chats: arrayChats};
    //   state.loading = false;
    // },
    // [deleteChat.rejected]: (state, action) => {
    //   state.loading = false;
    // },
    [muteMember.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [muteMember.fulfilled]: (state, action) => {
      state.errors = [];
      let arrayChats = state.chats.chats.map((item, index) => {
        if (item.id == action.payload.chat.id) {
          return { ...item, ...action.payload.chat };
        } else {
          return item;
        }
      });

      state.chats = { ...state.chats, chats: arrayChats };

      if (action.payload.chat.id == state.messages.chat.id) {
        state.messages = {
          ...state.messages,
          chat: { ...state.messages.chat, ...action.payload.chat },
        };
      }
      state.loading = false;
    },
    [muteMember.rejected]: (state, action) => {
      state.loading = false;
    },
    [unmuteMember.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [unmuteMember.fulfilled]: (state, action) => {
      state.errors = [];
      let arrayChats = state.chats.chats.map((item, index) => {
        if (item.id == action.payload.chat.id) {
          return { ...item, ...action.payload.chat };
        } else {
          return item;
        }
      });

      state.chats = { ...state.chats, chats: arrayChats };

      if (action.payload.chat.id == state.messages.chat.id) {
        state.messages = {
          ...state.messages,
          chat: { ...state.messages.chat, ...action.payload.chat },
        };
      }
      state.loading = false;
    },
    [unmuteMember.rejected]: (state, action) => {
      state.loading = false;
    },
    [blockChatGroup.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [blockChatGroup.fulfilled]: (state, action) => {
      state.errors = [];

      state.loading = false;
    },
    [blockChatGroup.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  memberConnectionStatus,
  pushMessage,
  resetChatMessage,
  resetMessages,
  resetChat,
  updateMessage,
  pushChat,
  addMessage,
  replaceMessage,
  updateChat,
} = messagingSlice.actions;

export default messagingSlice.reducer;

export const messageSelector = state => state.message;
