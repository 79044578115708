import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UserFilter } from "../../../components/utils/subjects";
import { baseUrl } from "../../../environments/proxyUrl";
import {
  parse_SourceArray,
  parse_userListObjectWithInnerHits_Feed,
  parse_userListObjectWithInnerHits_fofo,
} from "./elasticParser";

export const getUserFilters = createAsyncThunk(
  "profile/filters",
  async ({ token }, { rejectWithValue }) => {
    console.log("get feeds filter2");

    return {
      filters: [
        {
          name: "all",
          label: "all",
        },
        {
          name: "mutual",
          label: "mutual",
        },
        {
          name: "following",
          label: "following",
        },
        {
          name: "followers",
          label: "followers",
        },
        {
          name: "inspired by",
          label: "inspired by",
        },
        {
          name: "inspired",
          label: "inspired",
        },
      ],
    };
  }
);

export const followRequest = createAsyncThunk(
  "profile/followRequest",
  async ({ data, token, callback, fallback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/ff`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      console.log("profile users....", res);
      if (callback) {
        callback(res.results);
      }
      return res.results.users;
    } catch (err) {
      if (fallback) {
        fallback(err);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const unfollowRequest = createAsyncThunk(
  "profile/unfollowRequest",
  async ({ data, token, fallback, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/unf`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (fallback) {
        fallback(err);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRequest = createAsyncThunk(
  "profile/updateRequest",
  async ({ data, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/ff`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res.results);
      }
      console.log(res.results);
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const blockUser = createAsyncThunk(
  "profile/blockUser",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      console.log("block user result", data, token, id);
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/block`,
        { ...data, id },
        {
          headers: { Authorization: token },
        }
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const unblockUser = createAsyncThunk(
  "profile/unblockUser",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/unblock-request`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      console.log("unblock user restul", res);
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFollowers = createAsyncThunk(
  "profile/getFollowers",
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/users`, {
        params: { ...data, filter: UserFilter.Follower },
        headers: { Authorization: token },
      });
      console.log("raw response from getFollowers", response);
      return {
        size: data.size,
        from: data.from,
        users: await parse_userListObjectWithInnerHits_fofo(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFollowings = createAsyncThunk(
  "profile/getFollowings",
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/users`, {
        params: { ...data, filter: UserFilter.Following },
        headers: { Authorization: token },
      });
      console.log("raw response from getFollowings", response);
      return {
        size: data.size,
        from: data.from,
        users: await parse_userListObjectWithInnerHits_fofo(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInspired = createAsyncThunk(
  "profile/getInspired",
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/users`, {
        params: { ...data, filter: UserFilter.Inspire },
        headers: { Authorization: token },
      });
      console.log("raw response from getInspired", response);
      return {
        size: data.size,
        from: data.from,
        users: await parse_userListObjectWithInnerHits_fofo(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getActiveActivitiesOfUser = createAsyncThunk(
  "profile/getActiveActivitiesOfUser",
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/journey/`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      console.log("raw data getActiveActivitiesOfUser", response);
      return {
        size: data.size,
        from: data.from,
        activeActivities: await parse_userListObjectWithInnerHits_Feed(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDraftActivitiesOfUser = createAsyncThunk(
  "profile/getDraftActivitiesOfUser",
  async ({ data, id, token, callback }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/activities/v1/draft/`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      const draftActivities = response.data.results;
      if (callback) {
        callback(draftActivities);
      }
      return {
        size: data.size,
        from: data.from,
        draftActivities,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMoreActiveActivitiesOfUser = createAsyncThunk(
  "profile/getMoreActiveActivitiesOfUser",
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/users`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      console.log("response response", response);
      return {
        size: data.size,
        from: data.from,
        activeActivities: await parse_userListObjectWithInnerHits_Feed(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFinishedActivitiesOfUser = createAsyncThunk(
  "profile/getFinishedActivitiesOfUser",
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/activities/v1/user/`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      return {
        size: data.size,
        from: data.from,
        finishedActivities: response.data.results,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMoreFinishedActivitiesOfUser = createAsyncThunk(
  "profile/getMoreFinishedActivitiesOfUser",
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/activities/v1/user/`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      return {
        size: data.size,
        from: data.from,
        finishedActivities: response.data.results,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

// export const getSharedActivitiesOfUser = createAsyncThunk(
//   'profile/getSharedActivitiesOfUser',
//   async ({data, id, token}, {rejectWithValue}) => {
//     try {
//       const response = await axios.get(
//         `${baseUrl}/api/search/v1/activities/shared`,

//         {params: {...data}, headers: {Authorization: token}},
//       );
//       return {
//         size: data.size,
//         from: data.from,
//         activities: await parse_SourceArray(response.data.results),
//       };
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const getUserWithFilter = createAsyncThunk(
  "profile/getUserWithFilter",
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/users`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      console.log("raw response from getUserWithFilter", response);
      return {
        size: data.size,
        from: data.from,
        users: await parse_userListObjectWithInnerHits_fofo(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const profileSlice = createSlice({
  name: "profile",
  initialState: {
    users: [],
    followers: [],
    followings: [],
    inspired: [],
    contentRangeFinishedActivities: undefined,
    contentRangeActiveActivities: undefined,
    contentRangeDraftActivities: undefined,
    activeActivities: [],
    finishedActivities: [],
    draftActivities: [],
    filters: [],
    loading: false,
  },

  reducers: {
    setActivityStatusProfileSlice: (state, action) => {
      const { id, finished } = action.payload;
      if (finished == 1) {
        let act = state.activeActivities.find((a) => a.id == id);
        let acts = state.activeActivities.filter((a) => a.id != id);
        console.log("act", act, " ID == ", id);
        state.activeActivities = acts;
        let fActivities = state.finishedActivities;
        fActivities.unshift(act);
        state.finishedActivities = fActivities;
      } else {
        let act = state.finishedActivities.find((a) => a.id == id);
        let acts = state.finishedActivities.filter((a) => a.id != id);
        console.log("act", act, " ID == ", id);
        state.finishedActivities = acts;
        let aActivities = state.activeActivities;
        aActivities.unshift(act);
        state.activeActivities = aActivities;
      }
    },
    setActivityThumbnailProfileSlice: (state, action) => {
      console.log("Set thumbnail");
      const { thumbnail, id } = action.payload;
      let array1 = state.activeActivities.map((activity) => {
        console.log("ActivitySetThumbnail", activity.id);
        console.log("Activity2", id);
        if (activity.id + "" == id + "") {
          console.log("found act==", activity);
          const temp = activity;
          activity.thumbnail = thumbnail;
          //return temp;
          // activity.thumbnail = thumbnail;
        }
        return activity;
      });
      state.activeActivities = array1;
      console.log("activeActivities==", JSON.stringify(state.activeActivities));
      for (let i = 0; i < state.activeActivities.length; i++) {
        if (state.activeActivities[i].id + "" == id) {
          console.log(
            "state.activeActivities i",
            state.activeActivities[i].thumbnail
          );
        }
      }
      let array2 = state.finishedActivities.map((activity) => {
        if (activity.id + "" === id + "") {
          activity.thumbnail = thumbnail;
        }
        return activity;
      });
      state.finishedActivities = array2;
    },
    addNewlyCreatedActivity: (state, action) => {
      const { thumbnail, id, name, user, sk, createdAt } = action.payload;
      state.activeActivities.unshift(action.payload);
    },
    addNewlyCreatedDraft: (state, action) => {
      const { thumbnail, id, name, user } = action.payload;
      state.draftActivities.unshift({ thumbnail, id, name, user });
    },
    removeFromReduxAfterDelete_activity: (state, action) => {
      const { id } = action.payload;
      let array1 = state.activeActivities.filter((a) => a.id != id);
      state.activeActivities = array1;
    },
    removeFromReduxAfterDelete_activity_draft: (state, action) => {
      const { id } = action.payload;
      let array1 = state.draftActivities.filter((a) => a.id != id);
      state.draftActivities = array1;
    },
    addToWishListFromFeed: (state, action) => {
      const { id } = action.payload.activity;
      const temp = state.activeActivities;
      temp.forEach((ele) => {
        if (ele.id == id) {
          ele.wishList.push({ productId: action.payload.productId });
        }
      });
      state.activeActivities = temp;
      console.log("Active act", temp);
    },
    addLocationToActivityFromFeed: (state, action) => {
      const { id } = action.payload.activity;
      const temp = state.activeActivities;
      temp.forEach((ele) => {
        if (ele.id == id) {
          ele.locations.push(action.payload.location);
        }
      });
      state.activeActivities = temp;
      console.log("Active act", temp);
    },
  },
  extraReducers: {
    [getUserFilters.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getUserFilters.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload.filters) {
        state.filters = action.payload.filters;
      }
      state.loading = false;
    },
    [getUserFilters.rejected]: (state, action) => {
      state.loading = false;
    },
    [followRequest.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [followRequest.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload) {
        let newSet = [];
        for (let i = 0; i < state.users.length; i++) {
          if (state.users[i].id === action.payload.following.id) {
            newSet.push({
              ...state.users[i],
              follower: [action.payload.following],
            });
          } else {
            newSet.push(state.users[i]);
          }
        }
        state.users = newSet;
      }
      state.loading = false;
    },
    [followRequest.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateRequest.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateRequest.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload) {
        let newSet = [];
        for (let i = 0; i < state.users.length; i++) {
          if (state.users[i].id === action.payload.following.id) {
            newSet.push({
              ...state.users[i],
              follower: [action.payload.following],
            });
          } else {
            newSet.push(state.users[i]);
          }
        }
        state.users = newSet;
      }
      state.loading = false;
    },
    [updateRequest.rejected]: (state, action) => {
      state.loading = false;
    },
    [unfollowRequest.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [unfollowRequest.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload) {
        let newSet = [];
        for (let i = 0; i < state.users.length; i++) {
          if (state.users[i].id === action.payload.unfollowing.id) {
            newSet.push({ ...state.users[i], follower: [] });
          } else {
            newSet.push(state.users[i]);
          }
        }
        state.users = newSet;
      }
      state.loading = false;
    },
    [unfollowRequest.rejected]: (state, action) => {
      state.loading = false;
    },
    [blockUser.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [blockUser.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload) {
        let newSet = [];
        for (let i = 0; i < state.users.length; i++) {
          if (state.users[i].id === action.payload.blocking.id) {
            newSet.push({
              ...state.users[i],
              follower: [],
              blocker: [action.payload.blocking],
            });
          } else {
            newSet.push(state.users[i]);
          }
        }
        state.users = newSet;
      }
      state.loading = false;
    },
    [blockUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [unblockUser.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [unblockUser.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload) {
        let newSet = [];
        for (let i = 0; i < state.users.length; i++) {
          if (state.users[i].id === action.payload.unblocking.id) {
            newSet.push({ ...state.users[i], blocker: [] });
          } else {
            newSet.push(state.users[i]);
          }
        }
        state.users = newSet;
      }
      state.loading = false;
    },
    [unblockUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [getFollowings.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getFollowings.fulfilled]: (state, action) => {
      state.errors = [];
      state.followings = action.payload.users;
      state.loading = false;
    },
    [getFollowings.rejected]: (state, action) => {
      state.loading = false;
    },
    [getInspired.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getInspired.fulfilled]: (state, action) => {
      state.errors = [];
      state.inspired = action.payload.users;
      state.loading = false;
    },
    [getInspired.rejected]: (state, action) => {
      state.loading = false;
    },
    [getFollowers.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getFollowers.fulfilled]: (state, action) => {
      state.errors = [];
      state.followers = action.payload.users;
    },
    [getFollowers.rejected]: (state, action) => {
      state.loading = false;
    },
    [getActiveActivitiesOfUser.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.activeActivities = [];
      state.contentRangeActiveActivities = undefined;
    },
    [getActiveActivitiesOfUser.fulfilled]: (state, action) => {
      state.errors = [];
      state.activeActivities = action.payload.activeActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload?.activeActivities?.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeActiveActivities = `${from}-${sum}/false`;
      } else {
        state.contentRangeActiveActivities = `${from}-${sum}/true`;
      }
      state.loading = false;
    },
    [getActiveActivitiesOfUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    //Draft
    [getDraftActivitiesOfUser.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.draftActivities = [];
      state.contentRangeDraftActivities = undefined;
    },
    [getDraftActivitiesOfUser.fulfilled]: (state, action) => {
      state.errors = [];
      console.log("paylodxxx", action.payload);
      state.draftActivities = action.payload.draftActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.draftActivities.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeDraftActivities = `${from}-${sum}/false`;
      } else {
        state.contentRangeDraftActivities = `${from}-${sum}/true`;
      }
      state.loading = false;
    },
    [getDraftActivitiesOfUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreActiveActivitiesOfUser.pending]: (state) => {
      state.loading = true;
      state.errors = [];
      state.contentRangeActiveActivities = undefined;
    },
    [getMoreActiveActivitiesOfUser.fulfilled]: (state, action) => {
      state.loading = false;
      const activeActivities = state.activeActivities.concat(
        action.payload.activeActivities
      );
      state.activeActivities = activeActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload?.activeActivities?.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeActiveActivities = `${from}-${sum}/false`;
      } else {
        state.contentRangeActiveActivities = `${from}-${sum}/true`;
      }
      console.log(
        "contentRangeActiveActivities",
        state.contentRangeActiveActivities
      );
    },
    [getMoreActiveActivitiesOfUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [getFinishedActivitiesOfUser.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.finishedActivities = [];
      state.contentRangeFinishedActivities = undefined;
    },
    [getFinishedActivitiesOfUser.fulfilled]: (state, action) => {
      state.errors = [];
      state.finishedActivities = action.payload.finishedActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.finishedActivities.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeFinishedActivities = `${from}-${sum}/false`;
      } else {
        state.contentRangeFinishedActivities = `${from}-${sum}/true`;
      }
      state.loading = false;
    },
    [getFinishedActivitiesOfUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreFinishedActivitiesOfUser.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [getMoreFinishedActivitiesOfUser.fulfilled]: (state, action) => {
      state.loading = false;
      const finishedActivities = state.finishedActivities.concat(
        action.payload.finishedActivities
      );
      state.finishedActivities = finishedActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.finishedActivities.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeFinishedActivities = `${from}-${sum}/false`;
      } else {
        state.contentRangeFinishedActivities = `${from}-${sum}/true`;
      }
    },
    [getMoreFinishedActivitiesOfUser.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  setActivityThumbnailProfileSlice,
  setActivityStatusProfileSlice,
  addNewlyCreatedActivity,
  removeFromReduxAfterDelete_activity,
  removeFromReduxAfterDelete_activity_draft,
  addNewlyCreatedDraft,
  addToWishListFromFeed,
  addLocationToActivityFromFeed,
} = profileSlice.actions;

export default profileSlice.reducer;

export const profileSelector = (state) => state.profile;
