import React from 'react';

const InfoMessageLayout = ({ item, chat }) => {
  return (
    <div key={item?.id}>
      <p className='text-[14px] text-jrnyfy-textprimary text-center'>
        {item.message}
      </p>

      {item?.nextDate && (
        <p className='text-[12px] text-jrnyfy-textprimary text-center'>
          {item?.nextDate}
        </p>
      )}
    </div>
  );
};

export default InfoMessageLayout;
