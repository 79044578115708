import React, { useEffect, useRef, useState } from 'react'
import ImageVideoView from './ImageVideoView'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGalleryItem, getGalleryItems } from '../../redux/slices/mobile/activitySlice';
import { authSelector, likeAnActivity } from '../../redux/slices/mobile/authSlice';
import { ContentFilter, LikeOrCommentType, MediaType, PhotoSize, UserContentFilter, YesNo } from '../utils/subjects';
import { commentParser, idsCommentParser } from '../../redux/slices/mobile/elasticParser';
import Thumbnail from '../thumbnails/Thumbnail';
import Moment from 'react-moment';
import LikeIcon from "../../assets/images/feed_component_like.svg";
import Share from "../../assets/images/feed_component_share.svg";
import Comment from "../../assets/images/feed_component_comment.svg";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import Icons from "../Icons/Icons";
import LikeModal from '../FeedCard/likeModal';
import Heart from "../../assets/images/heart_icon.svg";
import rotateIcon from '../../assets/icons/rotate-icon.svg';
import trashIcon from '../../assets/icons/trash-icon.svg';
import cancelBtnSvg from '../../assets/images/Cancel.svg';
import LeftArrow from '../../assets/icons/left-arrow.svg';
import RightArrow from '../../assets/icons/right-arrow.svg';
import ZoomInIcon from '../../assets/icons/zoomin-icon.svg';
import ZoomOutIcon from '../../assets/icons/zoomout-icon.svg';
import Spinner from '../spinner/spinner';
import Comments from '../Comments/Comments';
import { transform } from 'framer-motion';
import FeedOptionModal from '../FeedCard/FeedOptionModal';
import ReportItem from './ReportItem';
import Popup from 'reactjs-popup';
import CustomPopUp from './CustomPopUp';
import { buildUrlToSize } from '../../res/commonFunctions';

export default function MediaDetails() {
    const [showPopUp, setShowPopUp] = useState(true)
    console.log("This is a modal")
    const navigate = useNavigate();
    const { state } = useLocation();
    const { galleryIndex, item, activity, removeTimelineItem, timelineId, isOpenSingle } = state;
    console.log("userPrams", state)
    const dispatch = useDispatch()
    const [comment, setComment] = useState('');
    const [commentsList, setCommentsList] = useState([]);
    const [showOptionModal, setShowOptionModal] = useState(false);
    const [isOption, setIsOption] = useState(false)
    const [loading, setLoading] = useState(false)
    const { auth } = useSelector(authSelector)
    const [liked, setLiked] = useState(false);
    const [likedCount, setLikedCount] = useState(0);
    const [detailGalleryItems, setDetailGalleryItems] = useState([])
    const [commentCount, setCommentCount] = useState(0);
    const [isReport, setIsReport] = useState(false)
    const galleryModal = useRef();
    const reportModalRef = useRef()
    const mediaOptionsRef = useRef()
    const flatListRef = useRef();
    const [gallery, setGallery] = useState([]);
    const [activeIndex, setActiveIndex] = useState(galleryIndex)
    const [caption, setCaption] = useState(detailGalleryItems[activeIndex]?.file.caption)
    const [captionLoading, setCaptionLoading] = useState(false)
    const captionUpdateRef = useRef();
    const [currPage, setCurrPage] = useState(20)
    const [isEnd, setIsEnd] = useState(false)
    const [isCommentEnd, setIsCommentEnd] = useState(false)
    const [isMute, setIsMute] = useState(true)
    let [lModal, setLModal] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const [scale, setScale] = useState(1)
    const [imageRotation, setImageRotation] = useState(0);
    const imageRef = useRef(null)
    const commentRef = useRef(null)
    const [top, setTop] = useState(0)
    const [left, setLeft] = useState(0)
    const imageContainerRef = useRef()

    const showOptionModalHandle = (action) => {
        // if (action) {
        //   window.history.pushState(null, "", location.pathname);
        // } else {
        //   navigate(-1);
        // }
        //setShowOptionModal(action);
    };
    const reportModalHandle = (action) => {
        setIsReport(action);
    };

    useEffect(() => {
        setLiked(detailGalleryItems[activeIndex]?.userLike)
        setLikedCount(detailGalleryItems[activeIndex]?.likes)
        setCommentCount(detailGalleryItems[activeIndex]?.commentCount)
    }, [activeIndex])

    useEffect(() => {
        setLiked(detailGalleryItems[activeIndex]?.userLike)
        setLikedCount(detailGalleryItems[activeIndex]?.likes)
        setCommentCount(detailGalleryItems[activeIndex]?.commentCount)
    }, [detailGalleryItems])

    console.log("UrlsParameter", item, galleryIndex, activity, detailGalleryItems[activeIndex])
    const getGalleryLists = (page, ids) => {
        console.log('Idss', ids)
        dispatch(
            getGalleryItems({
                data: {
                    activityId: activity.id,
                    sk: activity.sk,
                    skip: page,
                    limit: 20,
                    timelineId: timelineId,
                    ids: ids,
                    cmtIds: ids,
                    withCmt: YesNo.Yes,
                    cmtDocId: item?.clickedItem?.id,
                    cmtDocSk: item?.data?.doc?.sk,
                },
                callback: async (res, rawComments) => {
                    setLoading(false)
                    if (res.length < 20) {
                        setIsEnd(true);
                    } else {
                        setCurrPage(currPage + page);
                    }
                    console.log('issss11', res);
                    if (page == 0) {
                        setDetailGalleryItems(res);
                    } else {
                        let tempDetailsGallery = [...detailGalleryItems]
                        tempDetailsGallery.pop()
                        setDetailGalleryItems([...detailGalleryItems, ...res]);
                    }
                    let comments = []
                    if (ids) {
                        comments = await idsCommentParser(rawComments)
                    } else {
                        comments = await commentParser(rawComments)
                    }
                    console.log("commentsDetails", comments)
                    setCommentsList(comments)
                },
                token: auth.token,
            }),
        );
    };

    useEffect(() => {
        setLoading(true)
        console.log("items", item)
        if (item?.data?.cmt) {
            let ids = []
            ids.push(item.data.cmt.id)
            if (item.data.comment) {
                ids.push(item.data.comment.id)
            }
            if (item.data.subComment) {
                ids.push(item.data.subComment.id)
            }
            getGalleryLists(0, ids)
        } else {
            getGalleryLists(0)
        }
    }, [])
    console.log('DetailGallleryItem', detailGalleryItems[activeIndex])

    const LikeUnlikeHandle = (likeStatus) => {
        console.log('LIkeunlinke', liked)
        setLiked(prevState => !prevState)
        setLikedCount(prevState => prevState + likeStatus);
        dispatch(
            likeAnActivity({
                data: {
                    operator: likeStatus,
                    createdAt: detailGalleryItems[activeIndex].createdAt,
                    sk: detailGalleryItems[activeIndex].sk,
                    galleryId: detailGalleryItems[activeIndex].id,
                    timelineId: detailGalleryItems[activeIndex].timelineId,
                    type: LikeOrCommentType.Gallery
                },
                token: auth.token,
                id: detailGalleryItems[activeIndex].activityId,
                callback: () => {

                },
            }),
        );
    }

    let lModalControl = (action) => {
        // if (auth?.user?.verified) {
        //   setLModal(action);
        // } else {
        //   setCondLogin(!condLogin);
        // }
        setLModal(action);
    };

    // zoom in the image
    const zoomInHandle = () => {
        if (scale < 10) {
            setScale((prevScale) => prevScale + 1)
        }
    }

    // zoom out the image
    const zoomOutHandle = () => {
        if (scale > 1) {
            setScale((prevScale) => prevScale - 1)
        }
        if (scale == 1) {
            setPosition({
                x: 0,
                y: 0
            })
        }
    }

    //rotate image
    // const imageRotationHandle = () => {
    //     if (imageRotation < 270) {
    //         setImageRotation(imageRotation + 90);
    //     } else {
    //         setImageRotation(0);
    //     }
    // };

    useEffect(() => {
        let isDraging = false;
        let prevPosition = { x: 0, y: 0 }
        const handleMouseDown = (e) => {
            e.preventDefault()
            isDraging = true;
            prevPosition = { x: e.clientX, y: e.clientY }
        }
        const handleMouseMove = (e) => {
            if (isDraging && scale != 1) {
                console.log('first')
                let rect = imageRef.current.getBoundingClientRect()
                let imageContrect = imageContainerRef.current.getBoundingClientRect()
                console.log("RectSize", rect, imageContrect.width)
                let deltaX = (e.clientX - prevPosition.x) + left;
                let deltaY = (e.clientY - prevPosition.y) + top;
                // deltaX = Math.max(Math.min(deltaX, 0), (imgProps.current.scaledDimensions.width - imgProps.current.bounds.width) * -1);
                // deltaY = Math.max(Math.min(deltaY, 0), (imgProps.current.scaledDimensions.height - imgProps.current.bounds.height) * -1);
                console.log('Calculation', e.clientX + deltaX, (rect.width - imageContrect.width) / 2)
                if ((deltaX) <= ((rect.width - imageContrect.width) / 2) && (deltaX) >= -((rect.width - imageContrect.width) / 2)) {
                    setLeft(deltaX)
                }
                if ((deltaY) <= (rect.height - imageContrect.height) / 2 && (deltaY) >= -((rect.height - imageContrect.height) / 2)) {
                    console.log("ImageRef", prevPosition, e.clientX, e.clientY, deltaX, deltaY)
                    setTop(deltaY)
                }
                //prevPosition = { x: deltaX, y: deltaY }
                // setPosition({
                //     x: e.clientX + deltaX - 500,
                //     y: e.clientY + deltaY - 500
                // })
            }
        }
        const handleMouseUp = (e) => {
            console.log("Mouseup")
            isDraging = false;
        }
        if (imageRef.current) {

            imageRef.current.addEventListener('mousedown', handleMouseDown)
            imageRef.current.addEventListener('mousemove', handleMouseMove)
            imageRef.current.addEventListener('mouseup', handleMouseUp)
        }

        return () => {
            if (imageRef.current) {
                imageRef.current.removeEventListener('mousedown', handleMouseDown)
                imageRef.current.removeEventListener('mousedmove', handleMouseMove)
                imageRef.current.removeEventListener('mouseup', handleMouseUp)
            }
        }
    }, [imageRef, scale])

    console.log("detailGalleryItems[activeIndex]?.file", detailGalleryItems[activeIndex]?.file)

    const deleteGalleryPhoto = () => {
        let tempGal = [...detailGalleryItems]
        console.log('DeleteGallery', detailGalleryItems.length, detailGalleryItems)
        let len = detailGalleryItems.length
        if (0 < activeIndex) {
            setActiveIndex(activeIndex - 1)
        } else if (len === 1) {
            navigate(-1)
        }
        dispatch(
            deleteGalleryItem({
                photoSource: detailGalleryItems[activeIndex]?.file.photoSource,
                id: detailGalleryItems[activeIndex]?.activityId,
                token: auth.token,
                callback: result => {
                    console.log("DeleteItemsres", result)
                    if (removeTimelineItem) {
                        removeTimelineItem(detailGalleryItems[activeIndex].id, result)
                    }

                    tempGal.splice(activeIndex, 1)
                    setDetailGalleryItems(tempGal)
                },
                sk: detailGalleryItems[activeIndex].sk,
                gallerySk: detailGalleryItems[activeIndex].sk,
                galleryId: detailGalleryItems[activeIndex].id
            }),
        );
    };
    return (
        <Popup
            open={showPopUp}
            closeOnDocumentClick={false}
            contentStyle={{
                width: '100%',
                height: '100vh',
                backgroundColor: 'white',
                border: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px',
            }}
        >
            <div className='h-[100vh] w-full'>

                {detailGalleryItems.length > 0 ? (<div className='flex h-full'>
                    <div className='flex-1 relative bg-black overflow-hidden'>
                        <button
                            className='cursor-pointer w-[25px] absolute top-[20px] left-[25px] z-50'
                            onClick={() => { navigate(-1) }}
                        >
                            <img src={cancelBtnSvg} alt='cross' />
                        </button>

                        <div ref={imageContainerRef} className={`flex-1 h-full cursor-move w-full transform transition duration-150 ease-out`}
                        // style={{ transform: `scale(${scale}) translate(${position.x}px,${position.y}px)` }}
                        >
                            {scale > 1 ? (<img
                                src={buildUrlToSize(detailGalleryItems[activeIndex]?.file, PhotoSize.XLarge)}
                                alt="places"
                                ref={imageRef}
                                className={`w-full h-full absolute top-0 left-0`}
                                style={{ transform: `scale(${scale})`, top: top, left: left }}
                            />) : (<ImageVideoView
                                navigate={navigate}
                                containerStyle='h-full'
                                mediaStyle='object-contain'
                                item={detailGalleryItems[activeIndex]?.file}
                                autoPlay={true} />)}

                        </div>
                        {detailGalleryItems.length > 1 && (<button onClick={() => {

                            if (0 < activeIndex) {
                                setActiveIndex(activeIndex - 1)
                                commentRef.current.getComments(0, detailGalleryItems[activeIndex - 1])
                            } else {
                                setActiveIndex(detailGalleryItems.length - 1)
                                commentRef.current.getComments(0, detailGalleryItems[detailGalleryItems.length - 1])
                            }
                        }} className='absolute left-3 top-0 bottom-0 m-auto w-[60px] h-[60px] bg-gray-600 rounded-full p-3'>
                            <img className='w-full h-full' src={LeftArrow} alt='left-arrow' />
                        </button>)}
                        {detailGalleryItems.length > 1 && (<button
                            onClick={() => {
                                if (
                                    detailGalleryItems.length - 1 >
                                    activeIndex
                                ) {
                                    console.log('getGal', activeIndex, isEnd, currPage)
                                    if (!isEnd && activeIndex > currPage - 6) {
                                        setDetailGalleryItems([...detailGalleryItems, { file: { isLoading: true } }]);
                                        getGalleryLists(20)
                                    }
                                    setActiveIndex(activeIndex + 1)
                                    commentRef.current.getComments(0, detailGalleryItems[activeIndex + 1])
                                } else {
                                    setActiveIndex(0)
                                    commentRef.current.getComments(0, detailGalleryItems[0])
                                    // setGalleryIndex(0);
                                }
                            }} className='absolute right-3 top-0 bottom-0 m-auto w-[60px] h-[60px] bg-gray-600 rounded-full p-3'>
                            <img className='w-full h-full' src={RightArrow} alt='left-arrow' />
                        </button>)}
                        <div className='w-[25px] absolute bottom-20 right-3 z-5 flex flex-col items-center gap-5 z-10'>
                            {detailGalleryItems[activeIndex]?.file.mediaType == MediaType.Photo && (<div className='space-y-5'>
                                <button onClick={zoomInHandle} className=' w-[30px] h-[30px] '>
                                    <img src={ZoomInIcon} alt='zoomin-icon' />
                                </button>
                                <button onClick={zoomOutHandle} className='w-[30px] h-[30px]'>
                                    <img src={ZoomOutIcon} alt='zoomout-icon' />
                                </button>
                            </div>)}
                            {/* <button
                            className='cursor-pointer w-[25px]'
                            onClick={imageRotationHandle}
                        >
                            {imageRotation > 0 && <span className='text-white'>{imageRotation} Deg</span>}
                            <img className='rotate' src={rotateIcon} alt='rotate' />
                        </button> */}
                            {auth.user.id == detailGalleryItems[activeIndex].user.id && (<button
                                className='cursor-pointer w-[25px] w-[fit-content]'
                                onClick={deleteGalleryPhoto}
                            >
                                <img src={trashIcon} alt='trash-icon' />
                            </button>)}
                        </div>
                    </div>
                    <div className='w-[30%] flex flex-col p-5'>
                        <div className='pb-3 mb-3 border-b border-jrnyfy-colorLightGray'>
                            <div className="flex">
                                <Thumbnail
                                    ShowName={false}
                                    btnFollow={false}
                                    data={detailGalleryItems[activeIndex]?.user}
                                    PhotoSize={PhotoSize.Small}
                                />
                                <div className='pl-5'>
                                    <p className="max-[767px]:text-[14px] text-jrny font-semibold">
                                        <span className="text-jrnyfy-title">
                                            {detailGalleryItems[activeIndex]?.user.name}
                                        </span>
                                    </p>
                                    <p className="text-[10px] font-semibold text-jrnyfy-textprimary">
                                        added by {detailGalleryItems[activeIndex]?.user.name}, created{" "}
                                        <Moment fromNow>{detailGalleryItems[activeIndex]?.createdAt}</Moment>
                                    </p>
                                </div>

                            </div>
                            <div className='flex justify-between items-center mt-5'>
                                <div className='flex'>
                                    <Icons
                                        icon={liked ? Heart : LikeIcon}
                                        alt="Like"
                                        content={{ amount: likedCount, name: "likes" }}
                                        width="15px"
                                        textClick={() => lModalControl(true)}
                                        iconClick={() => LikeUnlikeHandle(liked ? -1 : 1)}
                                    />
                                    {lModal && (
                                        <LikeModal
                                            lModal={lModal}
                                            lModalControl={lModalControl}
                                            data={detailGalleryItems[activeIndex]}
                                            filter={ContentFilter.Like}
                                        />
                                    )}
                                    <Icons
                                        icon={Comment}
                                        alt="Share"
                                        width="15px"
                                        content={{ amount: commentCount, name: "Comment" }}

                                    />
                                    {/* {cModal && (
                                <CommentsModal
                                    cModal={cModal}
                                    cModalControl={cModalControl}
                                    data={data}
                                />
                            )} */}
                                </div>
                                <div className="relative">
                                    <Icons
                                        iconClick={() => setIsOption(prevState => !prevState)}
                                        icon={Option}
                                        alt="Option"
                                        width="7px"
                                    />
                                    {isOption && (
                                        <CustomPopUp setIsPopUp={setIsOption}>
                                            <button
                                                className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'>
                                                Remove
                                            </button>

                                            <button
                                                onClick={() => {
                                                    setIsReport(true)
                                                }}
                                                className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'>
                                                Report
                                            </button>
                                        </CustomPopUp>)}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col  flex-1 h-[calc(100%-123px)]'>
                            <Comments ref={commentRef} type={LikeOrCommentType.Gallery} comments={commentsList} commentItem={detailGalleryItems[activeIndex]} />
                        </div>
                        {isReport && (<ReportItem
                            data={detailGalleryItems[activeIndex]}
                            isModal={isReport}
                            controlFunction={reportModalHandle}
                        />)}
                    </div>
                </div>) : (
                    <div className='flex justify-center items-center h-full'>
                        <Spinner />
                    </div>)}

                {showOptionModal && (
                    <FeedOptionModal
                        data={detailGalleryItems[activeIndex]}
                        showOptionModal={showOptionModal}
                        showOptionModalHandle={showOptionModalHandle}
                    />
                )}
            </div>
        </Popup>
    )
}
