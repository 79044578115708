import React, { useEffect, useState } from 'react';
import Seo from '../Seo/Seo';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPayments_vendor,
  paymentSelector,
} from '../../redux/slices/mobile/paymentSlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { CurrencySupported } from '../utils/subjects';
import CurrencySelectModal from './CurrencySelectModal';
import backBtn from '../../assets/icons/back.png';
import BackButton from '../BackButton/BackButton';

const PaymentsBalanceSetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector(authSelector);
  const { payment } = useSelector(paymentSelector);
  const [showCurrencyChangeModal, setShowCurrencyChangeModal] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState(
    auth.user && auth.user.currency ? auth.user.currency : CurrencySupported.USD
  );

  const ShowCurrencyChangeModalHandle = () => {
    setShowCurrencyChangeModal((prevState) => !prevState);
  };
  console.log('Payment Selector', payment);
  useEffect(() => {
    dispatch(
      getPayments_vendor({
        data: { skip: 0, limit: 50 },
        token: auth.token,
      })
    );
  }, []);

  useEffect(() => {
    setSelectedCurrency(
      auth.user && auth.user.currency
        ? auth.user.currency
        : CurrencySupported.USD
    );
  }, [auth?.user?.currency]);

  return (
    <>
      <Seo
        title={` Login & Security | update password & secure your account`}
        description=''
        type='webapp'
        name='jrnyfy crop.'
        canonicalHref='/setting/payments-balance'
      />
      <div className='max-w-[660px] pb-[80px] mx-auto px-5 mt-3 space-y-10'>
        <div className='flex items-start gap-1'>
          <BackButton callback={() => navigate(-1)} />
          <div className='w-fit space-y-3'>
            <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit leading-3'>
              Payment & Balance
            </h4>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              Review payments and currency
            </p>
          </div>
        </div>
        {/* <div className='w-fit'>
          <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
            Payment & Balance
          </h4>
          <p className='text-start text-jrnyfy-textprimary w-fit'>
            Review payments and currency
          </p>
        </div> */}
        <div className='border-b-2 border-jrnyfy-textprimary pb-5 space-y-3'>
          <div className='flex justify-between items-center'>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              Currency: {selectedCurrency}
            </p>
            <button
              onClick={ShowCurrencyChangeModalHandle}
              className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
            >
              Update
            </button>
          </div>
        </div>
        {/* <button
          className='bg-jrnyfy-border rounded-[50px] mt-[40px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
          onClick={() => navigate(-1)}
        >
          back
        </button> */}
        <div className='pb-5 space-y-5'>
          <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
            Transaction History
          </h4>
          <div className='pb-5 space-y-3'>
            {payment ? (
              payment?.map((item) => {
                <div className='flex justify-between items-center'>
                  <div>
                    <p className='text-start text-jrnyfy-textprimary'>
                      {item?.product?.name}
                    </p>
                    <p className='text-start text-jrnyfy-textprimary text-[14px]'>
                      purchased on {item?.date}
                    </p>
                  </div>
                  <p className='text-start text-jrnyfy-textprimary'>
                    ${item?.amount}
                  </p>
                  <div className='flex flex-col gap-1'>
                    <span className='block w-[5px] h-[5px] rounded-[50%] bg-jrnyfy-textprimary'></span>
                    <span className='block w-[5px] h-[5px] rounded-[50%] bg-jrnyfy-textprimary'></span>
                    <span className='block w-[5px] h-[5px] rounded-[50%] bg-jrnyfy-textprimary'></span>
                  </div>
                </div>;
              })
            ) : (
              <div>
                <span className='text-jrnyfy-textprimary'>No Transaction</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <CurrencySelectModal
        ShowCurrencyChangeModalHandle={ShowCurrencyChangeModalHandle}
        showCurrencyChangeModal={showCurrencyChangeModal}
      />
    </>
  );
};

export default PaymentsBalanceSetting;
