import React from 'react';
import NotificationList from './NotificationList';
import ChatList from '../messages/ChatList';
import Seo from '../Seo/Seo';

const Notification = () => {
  return (
    <>
      <Seo
        title='Notification | control your notifications '
        description=''
        type='webapp'
        name='jrnyfy crop.'
        canonicalHref='/notification'
      />
      <div className='max-w-[1500px] mx-auto px-5'>
        <div className='flex justify-center gap-10 mt-5'>
          <div className='max-w-[545px] w-full'>
            <NotificationList />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
