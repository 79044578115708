import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { viewProfileSelector } from '../../redux/slices/mobile/viewProfileSlice';
import UserListPopup from '../userListPopup/UserListPopup';
import { UserFilter } from '../utils/subjects';

const Count = ({ user }) => {
  let {
    auth: { token },
  } = useSelector(authSelector);
  let { profileSelected } = useSelector(viewProfileSelector);

  const [followerPopupStatus, setFollowerPopupStatus] = useState(false);
  const [inspirePopupStatus, setInspirePopupStatus] = useState(false);
  const [followingPopupStatus, setFollowingPopupStatus] = useState(false);

  const toggleFollowerPopup = () => {
    if(profileSelected.followerCount){
      setFollowerPopupStatus(!followerPopupStatus);
    }
  };
  const toggleFollowingPopup = () => {
    if(profileSelected.followingCount){
      setFollowingPopupStatus(!followingPopupStatus);
    }
  };
  const toggleInspirePopup = () => {
   if(profileSelected.inspireCount){
    setInspirePopupStatus(!inspirePopupStatus);
   }
  };

  useEffect(() => {}, [profileSelected]);
  return (
    <main className=''>
      <div className='w-[90%] min-[1100px]:w-[75%] mx-auto flex justify-center min-[3000px]:w-1/2'>
        {profileSelected && (
          <div className='flex justify-center space-x-16 text-jSecTitle font-semibold text-jrnyfy-textprimary max-[550px]:space-x-5'>
            <p
              className='cursor-pointer flex flex-col items-center max-[550px]:text-[14px]'
              onClick={toggleFollowerPopup}
            >
              <span>{profileSelected.followerCount || 0}</span>{' '}
              <span>followers</span>
            </p>
            {followerPopupStatus == true && (
              <UserListPopup
                toggle={toggleFollowerPopup}
                popupStatus={followerPopupStatus}
                showAddBtn={false}
                filter={UserFilter.Follower}
                filterId={user.id}
              />
            )}
            <p
              className='cursor-pointer flex flex-col items-center max-[550px]:text-[14px]'
              onClick={toggleFollowingPopup}
            >
              <span>{profileSelected.followingCount || 0}</span>
              <span>following</span>
            </p>
            {followingPopupStatus == true && (
              <UserListPopup
                toggle={toggleFollowingPopup}
                popupStatus={followingPopupStatus}
                showAddBtn={false}
                filter={UserFilter.Following}
                filterId={user.id}
              />
            )}
            <p
              className='cursor-pointer flex flex-col items-center max-[550px]:text-[14px]'
              onClick={toggleInspirePopup}
            >
              <span>{profileSelected.inspireCount || 0}</span>
              <span>inspired</span>
            </p>
            {inspirePopupStatus == true && (
              <UserListPopup
                toggle={toggleInspirePopup}
                popupStatus={inspirePopupStatus}
                showAddBtn={false}
                filter={UserFilter.Inspire}
                filterId={user.id}
              />
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default Count;
