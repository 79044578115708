import { FcmType, NotificationType, YesNo } from "../components/utils/subjects";

import {
  logOut,
  restNotificationCount,
} from "../redux/slices/mobile/authSlice";
import {
  memberConnectionStatus,
  pushMessage,
  resetChatMessage,
  updateChat,
} from "../redux/slices/mobile/messagingSlice";
import { addNotification } from "../redux/slices/mobile/notificationSlice";

export const setSocketChannels = (socket, dispatch, auth) => {
  socket.on("notification", (notification) => {
    console.log("notification recived from server", notification);
    const socketMessage = JSON.parse(notification);

    if ((socketMessage.type = FcmType.Notificaton)) {
      console.log("notification parse", socketMessage);
      const obj = socketMessage.payload;
      dispatch(restNotificationCount({ notification: 1 }));
      dispatch(
        addNotification({
          notification: obj,
        })
      );
    } else if (socketMessage.type == FcmType.AccountDisable) {
      // logout
    }
  });

  socket.on("new-message", (message) => {
    const obj = JSON.parse(message);
    if (
      obj?.message?.notify === YesNo.Yes &&
      obj?.message?.owner?.id != auth.user.id
    ) {
      dispatch(restNotificationCount({ message: 1 }));
    }
    dispatch(
      pushMessage({
        message: obj,
      })
    );

    dispatch(
      resetChatMessage({
        amISender:
          obj?.message?.notify === YesNo.Yes &&
          obj?.message?.owner?.id != auth.user.id
            ? false
            : true,
        chat: obj.chat,
        message: obj.message.message,
        updatedAt: obj.message.createdAt,
      })
    );

    console.log("new message", obj);
  });

  socket.on("new-message-chat-update", (message) => {
    const obj = JSON.parse(message);
    console.log("SocketMessageUpdate", obj);
    dispatch(
      pushMessage({
        message: obj,
      })
    );
    dispatch(
      updateChat({
        chat: obj,
      })
    );
  });
  socket.on("connection-info", (message) => {
    const obj = JSON.parse(message);
    dispatch(memberConnectionStatus(obj));
    console.log("connectedMembers", obj);
  });
};
