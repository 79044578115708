import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import { createActivity } from '../../redux/slices/mobile/activitySlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { networkStatusSelector } from '../../redux/slices/web/networkStatusSlice';
import {
  BTN_ADD_MORE_DETAILS,
  PLACEHOLDER_TEXT_FIELD_NAME_CREATE_SUCCESS_STORY,
} from '../../utils/static';
import { RecordStatus, YesNo } from '../utils/subjects';

const CreateStoryPopUp = ({
  createSuccessStoryModal,
  createSuccessStory,
  updateSuccessStoryHandle,
}) => {
  let dispatch = useDispatch();
  let { auth } = useSelector(authSelector);
  const inputRef = useRef();
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);
  const [name, setName] = useState({
    vissible: true,
    value: undefined,
  });
  let { connection } = useSelector(networkStatusSelector);

  const createStory = () => {
    dispatch(
      createActivity({
        data: {
          name: name.value,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          recordStatus: RecordStatus.Draft,
          finished: YesNo.Yes,
        },
        token: auth.token,
        callback: (data) => {
          updateSuccessStoryHandle();
          createSuccessStory();
        },
      })
    );
  };
  useEffect(() => {
    console.log('connection', connection);
  }, [connection]);
  return (
    <Popup
      open={createSuccessStoryModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <AnimatePresence>
        <div className='bg-white p-10  w-[470px] rounded-[20px] max-[767px]:overflow-y-scroll max-[600px]:h-screen  max-[600px]:rounded-none max-[600px]:p-5'>
          {/* -------cross button-------- */}
          <img
            src={Cross}
            alt='cross'
            className='cursor-pointer w-[20px]'
            onClick={createSuccessStory}
          />
          {/* <div className="my-[20px] text-gray-400 font-SourceSansPro">
            <input
              type="text"
              className="w-[100%] text-[20px] focus:outline-none"
              onChange={(e) => setName(e.target.value)}
              placeholder={PLACEHOLDER_TEXT_FIELD_NAME_CREATE_SUCCESS_STORY}
            />
          </div> */}
          <div className='flex flex-col mt-[20px] relative'>
            <textarea
              ref={inputRef}
              onClick={(e) => {
                setName((state) => ({ ...state, vissible: true }));
              }}
              onChange={(e) => {
                setName((state) => ({
                  vissible: true,
                  value: e.target.value,
                }));
              }}
              spellCheck='true'
              className='resize-none  min-h-[100px] z-40 break-words text-[20px] focus:outline-none'
              value={name.value}
            />

            <span
              className='absolute top-0 pointer-events-none w-full text-[20px] min-[350px]:max-[460px]:text-[17px] min-[350px]:max-[460px]:leading-[21px] text-jrnyfy-textprimary font-Montserrat z-[999]'
              style={
                name.vissible && name.value === undefined
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              {PLACEHOLDER_TEXT_FIELD_NAME_CREATE_SUCCESS_STORY}
            </span>
          </div>
          <div className='text-center'>
            <button
              className='bg-jrnyfy-themeColor p-[10px_20px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro'
              onClick={() => {
                createStory();
              }}
            >
              {BTN_ADD_MORE_DETAILS}
            </button>
          </div>
        </div>
      </AnimatePresence>
    </Popup>
  );
};

export default CreateStoryPopUp;
