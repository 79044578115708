export const parse_SourceArray = async data => {
  const results = data.map((item, index) => {
    return {...item._source, id: item._id};
  });
  return results;
};
export const parse_SuggestedTask = async data => {
  const results = data.map((item, index) => {
    return {...item._source, id: item._id, routing: item._routing};
  });
  return results;
};
export const parse_SourceNotification = async data => {
  const results = data.map((item, index) => {
    return {...item._source, id: item._id, sk: item._routing};
  });
  return results;
};

export const parse_SourceArray_suggestions = data => {
  const results = data?.map((item, index) => {
    return item._source;
  });
  console.log('Final parse_SourceArray_suggestions', results);
  return results;
};

export const parse_SourceObjectWithInnerHitsTags = async data => {
  const results = data.map((item, index) => {
    let tags = undefined;
    if (
      item.inner_hits &&
      item.inner_hits.tags &&
      item.inner_hits.tags.hits &&
      item.inner_hits.tags.hits.hits &&
      item.inner_hits.tags.hits.hits.length > 0
    ) {
      tags = item.inner_hits.tags.hits.hits.map(tag => {
        return {...tag._source, id: tag?._id};
      });
    }
    return {...item._source, tags, id: item._id};
  });
  return results;
};

export const parseFeed = async data => {
  const results = data.results.map((item, index) => {
    //let activity = undefined;
    let liked = undefined;
    let rating = undefined;
    let alreadyParticipants = false;
    // if (item?.inner_hits?.activity != undefined) {
    //   activity = {
    //     ...item.inner_hits.activity.hits.hits[0]._source,
    //     id: item.inner_hits.activity.hits.hits[0]._id,
    //     sk: item.inner_hits.activity.hits.hits[0]._routing,
    //   };
    // }
    if (item?.inner_hits?.like != undefined) {
      liked = item.inner_hits.like.hits.hits[0]._source;
    }
    if (item?.inner_hits?.rating != undefined) {
      rating = item.inner_hits.rating.hits.hits[0]._source;
    }
    // if (item?.inner_hits?.participants != undefined) {
    //   alreadyParticipants = 'alreadyParticipants';
    // }
    // if (item?.inner_hits?.jpar != undefined) {
    //   alreadyParticipants = 'requestSent';
    // }
    // if (alreadyParticipants) {
    //   return {
    //     ...item._source,
    //     //activity,
    //     liked,
    //     rating,
    //     alreadyParticipants,
    //     id: item._id,
    //     sk: item._routing,
    //   };
    // }
    return {
      ...item._source,
      //activity,
      liked,
      rating,
      id: item._id,
      sk: item._routing,
    };
  });

  return results;
};

export const pasrseGetGalleryItem = async data=>{
  return data?.hits.map((item,index)=>{
    return {
      ...item?.inner_hits?.like?.hits.hits[0]._source,
      id:item._id,
      sk:item._routing,
      ...item._source
    }
  })
}
export const pasrseGetJourneyTimeline = async data=>{
  const timelines= data.timelines.map((item,index)=>{
    return {
      activity:{id:data.id,sk:data.sk,createdAt:data.createdAt},
      liked:item?.inner_hits?.like?.hits?.hits[0]._source,
      id:item._id,
      sk:item._routing,
      ...item._source
    }
  })
  return {...data,timelines:timelines}
}

export const parse_SourceObjectWithInnerHits_Leads = async data => {
  const results = data.results.map((item, index) => {
    let user = undefined;
    let liked = undefined;
    let rating = undefined;
    let alreadyParticipants = false;
    if (item?.inner_hits?.user != undefined) {
      user = {
        ...item.inner_hits.user.hits.hits[0]._source,
        id: item.inner_hits.user.hits.hits[0]._id,
        sk: item.inner_hits.user.hits.hits[0]._routing,
      };
    }
    if (item?.inner_hits?.like != undefined) {
      liked = item.inner_hits.like.hits.hits[0]._source;
    }
    if (item?.inner_hits?.rating != undefined) {
      rating = item.inner_hits.rating.hits.hits[0]._source;
    }
    if (item?.inner_hits?.participants != undefined) {
      alreadyParticipants = 'alreadyParticipants';
    }
    if (item?.inner_hits?.jpar != undefined) {
      alreadyParticipants = 'requestSent';
    }
    if (alreadyParticipants) {
      return {
        ...item._source,
        user,
        liked,
        rating,
        alreadyParticipants,
        id: item._id,
        sk: item._routing,
      };
    }
    return {
      ...item._source,
      user,
      liked,
      rating,
      id: item._id,
      sk: item._routing,
    };
  });

  return results;
};

export const parse_userListObjectWithInnerHits_fofo = async data => {
  const results = data.map((item, index) => {
    let user = undefined;
    let liked = undefined;
    let following = undefined;
    let participant = undefined;
    let returnValue = [];
    if (item?.inner_hits?.following != undefined) {
      following = item.inner_hits.following.hits.hits[0]._source;
    }
    if (item?.inner_hits?.upar != undefined) {
      participant = item.inner_hits.upar.hits.hits[0]._source;
    }
    return {
      ...item._source,
      participant,
      following,
      id: item._id,
      sk: item._routing,
    };
  });
  return results;
};

export const parse_feedListObjectWithInnerHits_likes_comments_inspirers =
  async data => {
    const results = data.map((item, index) => {
      let user = undefined;
      let liked = undefined;
      let following = undefined;
      let returnValue = [];
      // data.results[0].inner_hits.user.hits.hits[0].inner_hits.following.hits.hits[0]._source
      if (
        item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
          ?.hits[0]?._source != undefined
      ) {
        following =
          item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
            ?.hits[0]?._source;
      }
      if (item?.inner_hits?.user != undefined) {
        user = {
          ...item.inner_hits.user.hits.hits[0]._source,
          id: item.inner_hits.user.hits.hits[0]._id,
        };
      }
      if (item._source) {
        return {...item._source, ...user, following, id: item._id};
      }
      return returnValue;
    });
    return results;
  };
export const parse_Participant = async data => {
  const results = data.map((item, index) => {
    let user = undefined;
    let liked = undefined;
    let following = undefined;
    let returnValue = [];
    // data.results[0].inner_hits.user.hits.hits[0].inner_hits.following.hits.hits[0]._source
    if (
      item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
        ?.hits[0]?._source != undefined
    ) {
      following =
        item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
          ?.hits[0]?._source;
    }
    if (item?.inner_hits?.user != undefined) {
      user = {
        ...item.inner_hits.user.hits.hits[0]._source,
        id: item.inner_hits.user.hits.hits[0]._id,
      };
    }
    if (item._source) {
      return {...item._source, ...user, following};
    }
    return returnValue;
  });
  return results;
};
export const parse_users = async data => {
  const results = data.map((item, index) => {
    let user = undefined;
    let liked = undefined;
    let following = undefined;
    let returnValue = [];
    // data.results[0].inner_hits.user.hits.hits[0].inner_hits.following.hits.hits[0]._source
    if (
      item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
        ?.hits[0]?._source != undefined
    ) {
      following =
        item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
          ?.hits[0]?._source;
    }
    // if (item?.inner_hits?.user != undefined) {
    //   user = {
    //     ...item.inner_hits.user.hits.hits[0]._source,
    //     id: item.inner_hits.user.hits.hits[0]._id,
    //   };
    // }
    if (item._source) {
      return {
        ...item._source,
        following,
        id: item._id,
        sk: item._routing,
      };
    }
    return returnValue;
  });
  return results;
};
export const parse_UserWithContent = async data => {
  const results = data.map((item, index) => {
    let user = undefined;
    let liked = undefined;
    let following = undefined;
    let returnValue = [];
    // data.results[0].inner_hits.user.hits.hits[0].inner_hits.following.hits.hits[0]._source
    if (
      item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
        ?.hits[0]?._source != undefined
    ) {
      following =
        item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
          ?.hits[0]?._source;
    }
    if (item?.inner_hits?.user != undefined) {
      user = {
        ...item.inner_hits.user.hits.hits[0]._source,
        id: item.inner_hits.user.hits.hits[0]._id,
        sk: item.inner_hits.user.hits.hits[0]._routing,
      };
    }
    if (item._source) {
      return {...item._source, ...user, following};
    }
    return returnValue;
  });
  return results;
};
// export const commentParser = async data => {
//   const results = data.map((item, index) => {
//     let user = undefined;
//     let cmtlike = undefined;
//     let liked = undefined;
//     let following = undefined;

//     if (
//       item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
//         ?.hits[0]?._source != undefined
//     ) {
//       following =
//         item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
//           ?.hits[0]?._source;
//     }
//     if (item?.inner_hits?.user != undefined) {
//       user = {
//         ...item.inner_hits.user.hits.hits[0]._source,
//         id: item.inner_hits.user.hits.hits[0]._id,
//       };
//     }
//     if (item?.inner_hits?.cmtlike != undefined) {
//       cmtlike = item.inner_hits.cmtlike.hits.hits[0]._source.userCommentLike;
//     }
//     if (item._source) {
//       return {
//         commentId: item._id,
//         ...item._source,
//         ...user,
//         following,
//         cmtlike,
//       };
//     }

//     return [];
//   });
//   return results;
// };
export const locationParser = (res)=>{
  let locations = res.locations.map(item=>{
    return item.task.location
  })
  return {locations}
}

export const commentParser = async data => {
  const results = data.map((item, index) => {
    let user = undefined;
    let cmtlike = undefined;
    let liked = undefined;
    let following = undefined;

    if (
      item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
        ?.hits[0]?._source != undefined
    ) {
      following =
        item?.inner_hits?.user?.hits?.hits[0]?.inner_hits?.following?.hits
          ?.hits[0]?._source;
    }
    if (item?.inner_hits?.user != undefined) {
      user = {
        ...item.inner_hits.user.hits.hits[0]._source,
        id: item.inner_hits.user.hits.hits[0]._id,
      };
    }
    if (item?.inner_hits?.like != undefined) {
      cmtlike = item.inner_hits.like.hits.hits[0]._source.userLike;
    }
    // if (item?.inner_hits?.scmtlike != undefined) {
    //   cmtlike =
    //     item.inner_hits.scmtlike.hits.hits[0]._source.userSubCommentLike;
    // }
    // if (item?.inner_hits?.sscmtlike != undefined) {
    //   cmtlike =
    //     item.inner_hits.sscmtlike.hits.hits[0]._source.userSubSubCommentLike;
    // }
    if (item._source) {
      return {
        id: item._id,
        sk: item._routing,
        ...item._source,
        user,
        following,
        cmtlike,
        children:[]
      };
    }

    return [];
  });
  return results;
};

export const idsCommentParser = async data => {
  let comments = await commentParser(data);
  let nestedComments=[]
  if(comments.length==1){
    nestedComments=[
      {
        ...comments[0],
        isFocus:true,
        replyCount:0,
        children:[]
      }
    ]
  }else if(comments.length==2){
    nestedComments=[
      {
        ...comments[1],
        children:[
          {
            ...comments[0],
            isFocus:true,
            replyCount:0,
            children:[]
          }
        ]
      }
    ]
  }else if(comments.length==3){
    nestedComments=[
      {
        ...comments[2],
        replyCount:comments[2].replyCount-1,
        children:[
          {
            ...comments[1],
            replyCount:comments[1].replyCount-1,
            children:[
              {
                ...comments[0],
                isFocus:true,
                replyCount:comments[0].replyCount-1,
                children:[]
              }
            ]
          }
        ]
      }
    ]
  }

 
  return nestedComments;
};

export const getSingleComment = (data)=>{
  const comments = data.map((item)=>{
    return {
      id: item._id,
      sk: item._routing,
      ...item._source,
    };
  })
  return comments
}

export const parse_userListObjectWithInnerHits_Feed = async data => {
  const results = data.map((item, index) => {
    let user = undefined;
    let likes = undefined;
    let jpar = undefined;
    let rating = undefined;
    let following = undefined;

    if (item?.inner_hits?.user != undefined) {
      user = {
        ...item.inner_hits.user.hits.hits[0]._source,
        id: item.inner_hits.user.hits.hits[0]._id,
      };
    }
    if (item?.inner_hits?.like != undefined) {
      likes = item.inner_hits.like.hits.hits[0]._source;
    }
    if (item?.inner_hits?.jpar != undefined) {
      jpar = item.inner_hits.jpar.hits.hits[0]._source;
    }
    if (item?._source) {
      return {...item?._source, user, id: item._id, likes, jpar,sk:item._routing};
    }
    return {user};
  });
  return results;
};
export const userProfileActivityParser = async data => {
  const results = data.map((item, index) => {
    //let user = undefined;
    let likes = undefined;
    let jpar = undefined;
    let rating = undefined;
    let following = undefined;

    // if (item?.inner_hits?.user != undefined) {
    //   user = {
    //     ...item.inner_hits.user.hits.hits[0]._source,
    //     id: item.inner_hits.user.hits.hits[0]._id,
    //   };
    // }
    if (item?.inner_hits?.like != undefined) {
      likes = item.inner_hits.like.hits.hits[0]._source;
    }
    if (item?.inner_hits?.jpar != undefined) {
      jpar = item.inner_hits.jpar.hits.hits[0]._source;
    }
    return {...item?._source, id: item._id, likes, jpar,sk:item._routing};
  });
  return results;
};

export const parse_SourceObject = async data => {
  const results = {...data._source, id: data._id};
  return results;
};
