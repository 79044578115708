import React from "react";
import Popup from "reactjs-popup";
import Cross from "../../assets/images/xmark-solid.svg";
import {
  deleteComment,
  getJourneyContent,
  updateComment,
} from "../../redux/slices/mobile/activitySlice";
import { useDispatch, useSelector } from "react-redux";
import { YesNo, UserActionComment, UserContentFilter } from "../utils/subjects";
import { authSelector } from "../../redux/slices/mobile/authSlice";
const CommentOptions = ({
  item,
  activity,
  setCommentOptions,
  commentOptions,
}) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  console.log("Comment data", item);
  const deleteComm = () => {
    dispatch(
      deleteComment({
        data: {
          description: item.description,
          id: activity.id,
          sk: activity.sk,
          createdAt: activity.createdAt,
          action: UserActionComment.Delete,
        },
        id: item.commentId,
        token: auth.token,
        callback: () => {
          setCommentOptions(false);
        },
      })
    );
  };
  const updateComm = (status) => {
    console.log("getJourneyContent");
    // dispatch(
    // getJourneyContent({
    //   data: {
    //     from: 0,
    //     size: 25,
    //     filterId: item.commentId,
    //     filter: UserContentFilter.GetLikesOfAComment,
    //   },
    //   token: auth.token,
    //   activityId: activity.id,
    // }))
    dispatch(
      updateComment({
        data: {
          id: activity.id,
          sk: activity.sk,
          createdAt: activity.createdAt,
          action: status,
        },
        id: item.commentId,
        token: auth.token,
        callback: () => {
          setCommentOptions(false);
        },
      })
    );
  };
  return (
    <Popup
      open={commentOptions}
      closeOnDocumentClick={false}
      contentStyle={{
        width: "100%",
        height: "100vh",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      <div className="relative bg-white w-[500px] rounded-[20px] p-10  max-[767px]:p-5 max-[767px]:h-screen  max-[767px]:overflow-y-scroll max-[600px]:rounded-none">
        <div className="flex items-center">
          <div className="w-fit">
            <img
              src={Cross}
              alt="cross"
              className="cursor-pointer w-[20px]"
              onClick={() => setCommentOptions(false)}
            />
          </div>
          {/* <div className="w-full flex justify-center items-center">
            <p className="text-jrny font-semibold">people like your journey</p>
          </div> */}
        </div>

        {/* ------- main body start---- */}
        <div className="space-x-3">
          {!item.cmtlike ? (
            <button
              className="bg-jrnyfy-themeColor p-[5px_8px] text-[15px] font-semibold rounded-[50px] text-white font-SourceSansPro"
              onClick={() => updateComm(UserActionComment.Like)}
            >
              Like
            </button>
          ) : (
            <button
              className="bg-jrnyfy-themeColor p-[5px_8px] text-[15px] font-semibold rounded-[50px] text-white font-SourceSansPro"
              onClick={() => updateComm(UserActionComment.Dislike)}
            >
              Dislike
            </button>
          )}
          <button
            className="bg-jrnyfy-themeColor p-[5px_8px] text-[15px] font-semibold rounded-[50px] text-white font-SourceSansPro"
            onClick={deleteComm}
          >
            Remove
          </button>
          <button className="bg-jrnyfy-themeColor p-[5px_8px] text-[15px] font-semibold rounded-[50px] text-white font-SourceSansPro">
            Report
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default CommentOptions;
