import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {tagPaginationOffset} from '../../../res/dimens';
import {baseUrl} from '../../../environments/proxyUrl';
import {parse_SourceArray} from './elasticParser';

//TODO: do not exist on server side
export const payNow = createAsyncThunk(
  'stripe/payNow',
  async ({data, id, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/s-pay-now/`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const accountCreate = createAsyncThunk(
  'stripe/accountCreate',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.post(
        `${baseUrl}/api/payments/v1/s-account`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

//new
export const accountUpdate = createAsyncThunk(
  'stripe/accountUpdate',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/s-account`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// TODO: do not exist on server side
export const tosAcceptance = createAsyncThunk(
  'stripe/tosAcceptance',
  async ({token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/s-tos-acceptance/`,
        {},
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
// TODO: do not exist on server side
export const updatePersonalAccount = createAsyncThunk(
  'stripe/updatePersonalAccount',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/s-personal`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
//TODDO: do not exist on server side
export const updateCompanyAccount = createAsyncThunk(
  'stripe/updateCompanyAccount',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/s-company`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const addExternalBankInfo = createAsyncThunk(
  'stripe/addExternalBankInfo',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.post(
        `${baseUrl}/api/payments/v1/s-bank-info`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateExternalBankInfo = createAsyncThunk(
  'stripe/updateExternalBankInfo',
  async ({data, token, id}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/s-bank-info/${id}`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateAccountRepresentative = createAsyncThunk(
  'stripe/updateAccountRepresentative',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/s-person`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createAccountRepresentative = createAsyncThunk(
  'stripe/updateAccountRepresentative',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.post(
        `${baseUrl}/api/payments/v1/s-person`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getVendorStripAccountInformation = createAsyncThunk(
  'stripe/getVendorStripAccount',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.get(
        `${baseUrl}/api/payments/v1/s-account`,
        {
          params: {...data},
          headers: {Authorization: token},
        },
      );

      // const filterItems = response.data.payload.products.map((item, index)=>{
      //   return {...item._source}
      // });
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getPersonStripAccountRepresentative = createAsyncThunk(
  'stripe/getPersonStripAccountInformation',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.get(
        `${baseUrl}/api/payments/v1/s-person`,
        {
          params: {...data},
          headers: {Authorization: token},
        },
      );

      // const filterItems = response.data.payload.products.map((item, index)=>{
      //   return {...item._source}
      // });
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRepresentative = createAsyncThunk(
  'stripe/getRepresentative',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.get(
        `${baseUrl}/api/payments/v1/s-person/`,
        {
          params: {...data},
          headers: {Authorization: token},
        },
      );
      // const filterItems = response.data.payload.products.map((item, index)=>{
      //   return {...item._source}
      // });
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const uploadIdentityPhoto = createAsyncThunk(
  'product/uploadIdentityPhoto',
  async ({formData, token}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/payments/v1/s-identity-doc`,
        formData,
        {
          headers: {
            Authorization: token,
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          },
        },
      );

      return response;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

const stripeSlice = createSlice({
  name: 'stripe',
  initialState: {
    loading: false,
    setupBegin: false,
    account: undefined,
    representativeInformation: undefined,
  },
  reducers: {},
  extraReducers: {
    [payNow.pending]: state => {
      state.loading = true;
    },
    [payNow.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [payNow.rejected]: (state, action) => {
      state.loading = false;
    },
    [accountCreate.pending]: state => {
      state.loading = true;
    },
    [accountCreate.fulfilled]: (state, action) => {
      state.loading = false;
      state.account = action.payload.account;
    },
    [accountCreate.rejected]: (state, action) => {
      state.loading = false;
    },
    [updatePersonalAccount.pending]: state => {
      state.loading = true;
    },
    [updatePersonalAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.account = action.payload.account;
    },
    [updatePersonalAccount.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateCompanyAccount.pending]: state => {
      state.loading = true;
    },
    [updateCompanyAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.account = action.payload.account;
    },
    [updateCompanyAccount.rejected]: (state, action) => {
      state.loading = false;
    },
    [addExternalBankInfo.pending]: state => {
      state.loading = true;
    },
    [addExternalBankInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.account = action.payload.account;
    },
    [addExternalBankInfo.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateExternalBankInfo.pending]: state => {
      state.loading = true;
    },
    [updateExternalBankInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.account = action.payload.account;
    },
    [updateExternalBankInfo.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateAccountRepresentative.pending]: state => {
      state.loading = true;
    },
    [updateAccountRepresentative.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateAccountRepresentative.rejected]: (state, action) => {
      state.loading = false;
    },
    [createAccountRepresentative.pending]: state => {
      state.loading = true;
    },
    [createAccountRepresentative.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createAccountRepresentative.rejected]: (state, action) => {
      state.loading = false;
    },
    [getVendorStripAccountInformation.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.setupBegin = false;
    },
    [getVendorStripAccountInformation.fulfilled]: (state, action) => {
      state.errors = [];
      // state.setupBegin =  action.payload.setupBegin;
      state.account = action.payload.account;
      state.loading = false;
    },
    [getVendorStripAccountInformation.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
      state.account = undefined;
    },

    [getRepresentative.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.representativeInformation = undefined;
    },
    [getRepresentative.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      state.representativeInformation = action.payload.person;
    },
    [getRepresentative.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {} = stripeSlice.actions;

export default stripeSlice.reducer;

export const paymentSelector = state => state.stripe;
