import React from 'react';
import Popup from 'reactjs-popup';
import {
  authSelector,
  uploadUserPhoto,
  uploadVendorBusinessReg,
} from '../../redux/slices/mobile/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploadFor, UserUploadedImageType } from '../utils/subjects';
import moment from 'moment';
import Cross from '../../assets/images/xmark-solid.svg';

const DocumentUploadModal = ({
  showDocumentUploadModal,
  showDocumentUploadModalHandle,
}) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const uploadVendorRegistrationDocument = (photos) => {
    let formData = new FormData();
    for (let i = 0; i < photos.length; i++) {
      formData.append('photos', photos[i]);
    }
    formData.append('id', auth.user.id);
    formData.append('type', 'gallery');
    formData.append('date', moment(new Date()).unix());
    formData.append('uploadFor', FileUploadFor.Product); // 0 for activity , 1 for product
    dispatch(
      uploadVendorBusinessReg({
        formData: formData,
        token: auth.token,
        callback: (data) => {},
      })
    );
  };
  const uploadVendorDocument = (photo, type) => {
    let formData = new FormData();
    formData.append('photo', photo);
    formData.append('imageType', type);
    dispatch(
      uploadUserPhoto({
        formData: formData,
        token: auth.token,
      })
    );
  };
  return (
    <Popup
      open={showDocumentUploadModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='space-y-5 bg-white w-[420px] rounded-[20px] p-10 max-[500px]:p-5 max-[500px]:h-screen max-[500px]:overflow-y-scroll max-[500px]:rounded-none'>
        <div className='flex items-start gap-3'>
          <button onClick={showDocumentUploadModalHandle}>
            <img src={Cross} alt='back button' className='w-[15px] w-[20px]' />
          </button>
        </div>
        <p>
          We just need few more details to get you on your way to helping your
          community accomplishing their journey.You can skip this for now but it
          will take longer to approve your service
        </p>
        <div className='pb-5 space-y-6'>
          <div>
            <label>
              <input
                type='file'
                accept='image/*'
                className='hidden'
                multiple
                onChange={(e) =>
                  uploadVendorRegistrationDocument(e.target.files)
                }
              />
              <span className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[12px] font-bold text-jrnyfy-textprimary'>
                Add your business registration documents
              </span>
            </label>
          </div>
          <div>
            <label>
              <input
                type='file'
                accept='image/*'
                className='hidden'
                onChange={(e) =>
                  uploadVendorDocument(
                    e.target.files[0],
                    UserUploadedImageType.VendorIdFront
                  )
                }
              />
              <span className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[12px] font-bold text-jrnyfy-textprimary'>
                Add a government issued photo ID (front)
              </span>
            </label>
          </div>
          <div>
            <label>
              <input
                type='file'
                accept='image/*'
                className='hidden'
                onChange={(e) =>
                  uploadVendorDocument(
                    e.target.files[0],
                    UserUploadedImageType.VendorIdBack
                  )
                }
              />
              <span className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[12px] font-bold text-jrnyfy-textprimary'>
                Add a government issued photo ID (back)
              </span>
            </label>
          </div>

          <button className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[12px] font-bold text-jrnyfy-textprimary'>
            SUBMIT YOUR SERVICE FOR APPROVAL
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default DocumentUploadModal;
