import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { tagPaginationOffset } from '../../../res/dimens';
import { baseUrl } from '../../../environments/proxyUrl';
import {
  parse_SourceArray,
  parse_SourceObjectWithInnerHitsTags,
  parse_userListObjectWithInnerHits_fofo,
  parse_userListObjectWithInnerHits_Feed,
  userProfileActivityParser,
} from './elasticParser';
import { UserFilter } from '../../../components/utils/subjects';

export const getUserInformation = createAsyncThunk(
  'viewProfile/view-profile',
  async ({ id, token, data, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/users/v1/user/${id}`,
        {
          params: { ...data },
          headers: { Authorization: token },
        }
      );
      if (callback) callback();
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const getActiveActivitiesOfUser = createAsyncThunk(
  'viewProfile/getActiveActivitiesOfUser',
  async ({ data, id, token, callback }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/journey`, {
        params: { ...data, id },
        headers: { Authorization: token },
      });
      console.log('raw data getActiveActivitiesOfUser', response);
      const activeActivities = await userProfileActivityParser(
        response.data.results
      );
      if (callback) {
        callback(activeActivities);
      }
      return {
        size: data.size,
        from: data.from,
        activeActivities,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMoreActiveActivitiesOfUser = createAsyncThunk(
  'viewProfile/getMoreActiveActivitiesOfUser',
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/journey/`, {
        params: { ...data, id },
        headers: { Authorization: token },
      });
      return {
        // contentRange: response.headers['content-range'],
        size: data.size,
        from: data.from,
        activeActivities: await parse_SourceArray(response.data.results),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

//TODO: do not exist on server side
export const getFinishedActivitiesOfUser = createAsyncThunk(
  'viewProfile/getFinishedActivitiesOfUser',
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/search/v1/activities/user/${id}`,

        { params: { ...data }, headers: { Authorization: token } }
      );
      // const users = await parse_SourceArray(response.data.results)
      return {
        // contentRange: response.headers['content-range'],
        size: data.size,
        from: data.from,
        finishedActivities: await parse_SourceArray(response.data.results),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
//TODO: do not exist on server side
export const getMoreFinishedActivitiesOfUser = createAsyncThunk(
  'viewProfile/getMoreFinishedActivitiesOfUser',
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/search/v1/activities/user/${id}`,
        {
          params: { ...data },
          headers: { Authorization: token },
        }
      );
      return {
        // contentRange: response.headers['content-range'],
        size: data.size,
        from: data.from,
        finishedActivities: await parse_SourceArray(response.data.results),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const ViewProfileGetFollowers = createAsyncThunk(
  'ViewProfile/ViewProfileGetFollowers',
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/users`, {
        params: { ...data, filter: UserFilter.Follower },
        headers: { Authorization: token },
      });
      console.log('raw response from getFollowers', response);
      return {
        size: data.size,
        from: data.from,
        users: await parse_userListObjectWithInnerHits_fofo(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const ViewProfileGetFollowings = createAsyncThunk(
  'ViewProfile/ViewProfileGetFollowings',
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/users`, {
        params: { ...data, filter: UserFilter.Following },
        headers: { Authorization: token },
      });
      console.log('raw response from getFollowings', response);
      return {
        size: data.size,
        from: data.from,
        users: await parse_userListObjectWithInnerHits_fofo(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const ViewProfileGetInspired = createAsyncThunk(
  'ViewProfile/ViewProfileGetInspired',
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/users`, {
        params: { ...data, filter: UserFilter.Inspire },
        headers: { Authorization: token },
      });
      console.log('raw response from getInspired', response);
      return {
        size: data.size,
        from: data.from,
        users: await parse_userListObjectWithInnerHits_fofo(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const viewProfileSlice = createSlice({
  name: 'viewProfile',
  initialState: {
    id: undefined,
    name: undefined,
    thumbnail: undefined,
    banner: undefined,
    followers: [],
    followings: [],
    inspirers: [],
    contentRangeFinishedActivities: undefined,
    contentRangeActiveActivities: undefined,
    activeActivities: [],
    finishedActivities: [],
    loading: false,
    profileSelected: undefined,
    sk: undefined,
  },
  reducers: {
    changeFollowerCount: (state, action) => {
      const { value } = action.payload;

      console.log('incFollowerCount', state.profileSelected.followerCount);
      if (state.profileSelected) {
        state.profileSelected.followerCount =
          state.profileSelected.followerCount + value;
      }
      console.log('incFollowerCount', state.profileSelected.followerCount);
    },
    changeFollowingCount: (state, action) => {
      const { value } = action.payload;

      console.log('incFollowerCount', state.profileSelected.followerCount);
      if (state.profileSelected) {
        state.profileSelected.followingCount =
          state.profileSelected.followingCount + value;
      }
      console.log('incFollowerCount', state.profileSelected.followerCount);
    },
  },
  extraReducers: {
    [getUserInformation.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.id = undefined;
      state.name = undefined;
      state.thumbnail = undefined;
      state.banner = undefined;
      // state.followers = undefined;
      // state.followings = undefined;
      // state.inspirers = undefined;
      state.profileSelected = undefined;
      state.sk = undefined;
    },
    [getUserInformation.fulfilled]: (state, action) => {
      state.errors = [];
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.thumbnail = action.payload.thumbnail;
      state.banner = action.payload.banner;
      // state.followers = action.payload.follower;
      // state.followings = action.payload.following;
      // state.inspirers = action.payload.inspirers;
      state.profileSelected = action.payload;
      state.sk = action.payload.sk;
      state.loading = false;
    },
    [getUserInformation.rejected]: (state, action) => {
      state.loading = false;
    },
    [getActiveActivitiesOfUser.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.activeActivities = [];
      state.contentRangeActiveActivities = undefined;
    },
    [getActiveActivitiesOfUser.fulfilled]: (state, action) => {
      state.errors = [];
      state.activeActivities = action.payload.activeActivities;
      console.log('SliceActivity', state.activeActivities);
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.activeActivities.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeActiveActivities = `${from}-${sum}/false`;
      } else {
        state.contentRangeActiveActivities = `${from}-${sum}/true`;
      }
      state.loading = false;
    },
    [getActiveActivitiesOfUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreActiveActivitiesOfUser.pending]: (state) => {
      state.loading = true;
      state.errors = [];
      state.contentRangeActiveActivities = undefined;
    },
    [getMoreActiveActivitiesOfUser.fulfilled]: (state, action) => {
      state.loading = false;
      const activeActivities = state.activeActivities.concat(
        action.payload.activeActivities
      );
      state.activeActivities = activeActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.activeActivities.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeActiveActivities = `${from}-${sum}/false`;
      } else {
        state.contentRangeActiveActivities = `${from}-${sum}/true`;
      }
    },
    [getMoreActiveActivitiesOfUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [getFinishedActivitiesOfUser.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.finishedActivities = [];
      state.contentRangeFinishedActivities = undefined;
    },
    [getFinishedActivitiesOfUser.fulfilled]: (state, action) => {
      state.errors = [];
      state.finishedActivities = action.payload.finishedActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.finishedActivities.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeFinishedActivities = `${from}-${sum}/false`;
      } else {
        state.contentRangeFinishedActivities = `${from}-${sum}/true`;
      }
      state.loading = false;
    },
    [getFinishedActivitiesOfUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreFinishedActivitiesOfUser.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [getMoreFinishedActivitiesOfUser.fulfilled]: (state, action) => {
      state.loading = false;
      const finishedActivities = state.finishedActivities.concat(
        action.payload.finishedActivities
      );
      state.finishedActivities = finishedActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.finishedActivities.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeFinishedActivities = `${from}-${sum}/false`;
      } else {
        state.contentRangeFinishedActivities = `${from}-${sum}/true`;
      }
    },
    [getMoreFinishedActivitiesOfUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [ViewProfileGetFollowings.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [ViewProfileGetFollowings.fulfilled]: (state, action) => {
      state.errors = [];
      state.followings = action.payload.users;
      state.loading = false;
    },
    [ViewProfileGetFollowings.rejected]: (state, action) => {
      state.loading = false;
    },
    [ViewProfileGetInspired.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [ViewProfileGetInspired.fulfilled]: (state, action) => {
      state.errors = [];
      state.inspirers = action.payload.users;
      state.loading = false;
    },
    [ViewProfileGetInspired.rejected]: (state, action) => {
      state.loading = false;
    },
    [ViewProfileGetFollowers.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [ViewProfileGetFollowers.fulfilled]: (state, action) => {
      state.errors = [];
      state.followers = action.payload.users;
    },
    [ViewProfileGetFollowers.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { changeFollowerCount, changeFollowingCount } =
  viewProfileSlice.actions;

export default viewProfileSlice.reducer;

export const viewProfileSelector = (state) => state.viewProfile;
