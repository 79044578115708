import { createSlice } from '@reduxjs/toolkit';

const networkStatusSlice = createSlice({
  name: 'networkStatus',
  initialState: {
    connection: true,
  },
  reducers: {
    setNetworkStatus: (state, action) => {
      const { status } = action.payload;
      state.connection = status;
    },
  },
  extraReducers: {},
});

export const { setNetworkStatus } = networkStatusSlice.actions;

export default networkStatusSlice.reducer;

export const networkStatusSelector = (state) => state.networkStatus;
