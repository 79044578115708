import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import {
  CONFIRM_PASSWORD_REQUIRE_ERROR,
  NEW_CONFIRM_PASS_NOT_MATCHED,
  NEW_PASSWORD_REQUIRE_ERROR,
  OLD_CONFIRM_PASS_CAN_NOT_SAME,
  OLD_PASSWORD_REQUIRE_ERROR,
} from '../utils/static/error-messages';
import {
  authSelector,
  changePassword,
} from '../../redux/slices/mobile/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import Cross from '../../assets/images/xmark-solid.svg';

const PasswordChangeModal = ({
  showLoadingHandle,
  showPasswordChange,
  showPasswordChangeHandle,
}) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  //password change validation state
  const [errorMessages, setErrorMessages] = useState({
    current_pass_err: '',
    new_pass_err: '',
    confirm_pass_err: '',
  });
  //Password change state
  const [passwordBtn, setPasswordBtn] = useState({
    current_pass: '',
    new_pass: '',
    confirm_pass: '',
  });

  //set the password change to state like current_pass new_pass and confirm_pass
  const handlePassUpdateBtn = (text, type) => {
    setPasswordBtn((preState) => ({ ...preState, [type]: text.target.value }));
  };

  //Change password validation /Old password new password and confirm password valid or not
  let isValidationErr = false;
  const updatePasswordHandle = () => {
    if (!passwordBtn.current_pass) {
      setErrorMessages((prevState) => ({
        ...prevState,
        current_pass_err: OLD_PASSWORD_REQUIRE_ERROR,
      }));
      isValidationErr = true;
    }
    if (!passwordBtn.new_pass) {
      setErrorMessages((prevState) => ({
        ...prevState,
        new_pass_err: NEW_PASSWORD_REQUIRE_ERROR,
      }));
      isValidationErr = true;
    }
    if (passwordBtn.current_pass == passwordBtn.new_pass) {
      setErrorMessages((prevState) => ({
        ...prevState,
        new_pass_err: OLD_CONFIRM_PASS_CAN_NOT_SAME,
      }));
      isValidationErr = true;
    }
    if (!passwordBtn.confirm_pass) {
      setErrorMessages((prevState) => ({
        ...prevState,
        confirm_pass_err: CONFIRM_PASSWORD_REQUIRE_ERROR,
      }));
      isValidationErr = true;
    } else if (passwordBtn.new_pass != passwordBtn.confirm_pass) {
      setErrorMessages((prevState) => ({
        ...prevState,
        confirm_pass_err: NEW_CONFIRM_PASS_NOT_MATCHED,
      }));
      isValidationErr = true;
    }
    //If every field is valid then dispatch the changePassword slice
    if (!isValidationErr) {
      setActionStates({ ...actionStates, loading: true });
      dispatch(
        changePassword({
          data: {
            oldPassword: passwordBtn.current_pass,
            newPassword: passwordBtn.new_pass,
          },
          token: auth.token,
        })
      ).then((res) => {
        console.log('Response', res.meta.requestStatus);
        if (res.meta.requestStatus == 'fulfilled') {
          setActionStates({ ...actionStates, loading: false, fulfilled: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
            setPasswordBtn({
              current_pass: '',
              new_pass: '',
              confirm_pass: '',
            });
          }, 3000);
        }
        if (res.meta.requestStatus == 'rejected') {
          setActionStates({ ...actionStates, loading: false, rejected: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
            setPasswordBtn({
              current_pass: '',
              new_pass: '',
              confirm_pass: '',
            });
          }, 3000);
        }
      });
    }
  };

  return (
    <>
      <ToastContainer autoClose={5000} />
      <Popup
        open={showPasswordChange}
        closeOnDocumentClick={false}
        contentStyle={{
          width: '100%',
          height: '100vh',
          backgroundColor: 'transparent',
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px',
        }}
      >
        <div className='space-y-5 bg-white w-[420px] rounded-[20px] p-10 max-[500px]:p-5 max-[500px]:h-screen max-[500px]:overflow-y-scroll max-[500px]:rounded-none'>
          <div className='flex items-start gap-3'>
            <button onClick={() => showPasswordChangeHandle(false)}>
              <img
                src={Cross}
                alt='back button'
                className='w-[15px] w-[25px]'
              />
            </button>
          </div>
          <p className='text-jrnyfy-textprimary'>
            to update your password you will need to existing password and
            password
          </p>
          <div className='pb-5 space-y-6'>
            <div>
              <input
                type='password'
                name='current password'
                className='text-jrnyfy-textprimary border-2 border-jrnyfy-colorLightGray rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                placeholder='Current Password'
                onChange={(text) => {
                  handlePassUpdateBtn(text, 'current_pass');
                  setErrorMessages({ ...errorMessages, current_pass_err: '' });
                }}
                value={passwordBtn.current_pass}
              />
              {errorMessages.current_pass_err && (
                <p className='text-red-500'>{errorMessages.current_pass_err}</p>
              )}
            </div>
            <div>
              <input
                type='password'
                name='new password'
                className='text-jrnyfy-textprimary border-2 border-jrnyfy-colorLightGray rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                placeholder='New Password'
                onChange={(text) => {
                  handlePassUpdateBtn(text, 'new_pass');
                  setErrorMessages({ ...errorMessages, new_pass_err: '' });
                }}
                value={passwordBtn.new_pass}
              />
              {errorMessages.new_pass_err && (
                <p className='text-red-500'>{errorMessages.new_pass_err}</p>
              )}
            </div>
            <div>
              <input
                type='password'
                name='confirm password'
                className='text-jrnyfy-textprimary border-2 border-jrnyfy-colorLightGray rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                placeholder='Confirm Password'
                onChange={(text) => {
                  handlePassUpdateBtn(text, 'confirm_pass');
                  setErrorMessages({ ...errorMessages, confirm_pass_err: '' });
                }}
                value={passwordBtn.confirm_pass}
              />
              {errorMessages.confirm_pass_err && (
                <p className='text-red-500'>{errorMessages.confirm_pass_err}</p>
              )}
            </div>
            {/* <button
              onClick={updatePasswordHandle}
              className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
            >
              Update
            </button> */}
            <FunctionalButton
              actionStates={actionStates}
              callback={() => updatePasswordHandle()}
              actionStatesTexts={{
                default: 'Update',
                loading: 'Updating',
                fulfilled: 'Updated Successfully',
                rejected: 'Update Failed',
              }}
            />
          </div>
        </div>
      </Popup>
    </>
  );
};

export default PasswordChangeModal;
