import { useSelector } from "react-redux";
import { buildUrlToSize } from "../../utils/build-image-url";
import Thumbnail from "../thumbnails/Thumbnail";
import { PhotoSize, PhotoSource } from "../utils/subjects";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import { getJourneyNavOption } from "../../utils/get-nav-option";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Icons from "../Icons/Icons";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import LightIcon from '../../assets/icons/light-icon.svg'
import { useState } from "react";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";
const InspirerAdded = ({ index, data }) => {
  const { activity } = useSelector(activitySelector);
  const navigate = useNavigate();
  let avater = buildUrlToSize(data?.feed?.user?.thumbnail, PhotoSize.Large);
  let altTitle = data?.feed?.user?.name.slice(0, 1);
  const user = data?.feed ? data?.feed?.user : data?.user;
  const dataDetails = data;
  const photos = data?.thumbnail ? data.thumbnail : activity?.thumbnail;
  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };
  console.log("Inspirer Added", data);
  return (
    <>
      <div className="flex justify-between items-start">
        <div className="relative">
          <div className="absolute top-0 left-[-24px]">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>
          {data?.feed?.user ? (
            <p
              onClick={() => {
                // navigate(`/jrny/${data.id}`);
                navigate(getJourneyNavOption(data));
              }}
              className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold cursor-pointer "
            >
              <span className="text-jrnyfy-title">
                {dataDetails.inspirer.name} inspired {dataDetails.user.name} to
                start a journey:
              </span>
              {data?.activity?.name ? data?.activity?.name : activity?.name}
            </p>
          ) : (
            <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold ">
              <span className="text-jrnyfy-title">
                {dataDetails.inspirer.name} inspired {dataDetails.user.name} to
                start a journey:
              </span>
              {data?.activity?.name ? data?.activity?.name : activity?.name}
            </p>
          )}
          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {user.name}, created{" "}
            <Moment fromNow>{data?.createdAt}</Moment>
          </p>
        </div>
      </div>

      <div className="ml-[20px] flex justify-center items-end py-10  mt-4">
        <div className="mr-[-30px] z-10">
          <Thumbnail
            showName={false}
            btnFollow={false}
            data={dataDetails?.inspirer}
            thumbnailContainerStyle={'!w-[80px] !h-[80px] !border-4 !border-gray-200'}
            PhotoSize={PhotoSize.Large}
            
          />
        </div>
        <div className="flex items-end">
          <Thumbnail
            showName={false}
            btnFollow={false}
            data={dataDetails?.user}
            thumbnailContainerStyle={'!w-[140px] !h-[140px] !border-4 !border-gray-200 !text-[50px] !font-bold'}
            PhotoSize={PhotoSize.Large}
          />
          <img src={LightIcon} className="ml-[-30px] z-10" alt="ligth icon" />
        </div>
      </div>
      <div className="ml-[20px]">
        <button className="p-[8px_20px] md:p-[8px_30px] bg-jrnyfy-themeColor rounded-[50px] text-[12px] md:text-[18px] text-white font-bold md:font-semibold italic mt-[20px] ">
          get inspired
        </button>
      </div>
    </>
  );
};

export default InspirerAdded;
