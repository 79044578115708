import React, { useEffect, useState } from 'react';
import Thumbnail from '../thumbnails/Thumbnail';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNotifications,
  notificationSelector,
  notificationViewed,
} from '../../redux/slices/mobile/notificationSlice';
import {
  authSelector,
  restNotificationCount,
} from '../../redux/slices/mobile/authSlice';
import { Facebook } from 'react-content-loader';
import Moment from 'react-moment';
import {
  FFUserAction,
  NotificationType,
  ParticipantStatus,
  TaskAssigneeStatus,
} from '../utils/subjects';
import {
  acceptOrRejectParticipant,
  acceptOrRejectParticipantRequestByOwner,
} from '../../redux/slices/mobile/activitySlice';
import { updateRequest } from '../../redux/slices/mobile/profileSlice';
import { confirmOrder } from '../../redux/slices/mobile/bookingSlice';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import NotificationListItem from './NotificationListItem';
import { notificationFilters } from '../../utils/filters';
import HorizontalScrollButtons from '../utils/HorizontalScrollButtons';

const NotificationList = () => {
  const { auth } = useSelector(authSelector);
  const { notifications } = useSelector(notificationSelector);
  const dispatch = useDispatch();
  const [currPage, setCurrPage] = useState(0);
  const [size, setSize] = useState(30);
  const [contents, setContents] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [filter, setFilter] = useState(notificationFilters[0].value)

  document.onscroll = () => {
    console.log(
      'Scroll On Notification',
      window.innerHeight + window.scrollY,
      window.innerHeight + document.body.scrollHeight / 2
    );
    console.log('Pagination', isEnd)
    if (
      window.innerHeight + window.scrollY >=
      window.innerHeight + document.body.scrollHeight / 2 &&
      !isEnd
    ) {
      if (!isCalling) {
        setIsCalling(true);
        getNotification(filter, currPage);
      }
    }
  };

  const getNotification = (filter, page) => {
    setLoading(true)
    console.log("GetNOtificatonndkfjdfkd", filter)
    let data = {
      from: page,
      size: size,
      value: ''
    }
    if (filter != -1) {
      data['filter'] = filter
    }
    dispatch(
      getNotifications({
        data,
        token: auth.token,
        callback: (data) => {
          data.then((d) => {
            //dispatch(restNotificationCount({ notification: 0 }));
            if (d.length < 30) {
              setIsEnd(true);
            }
            if (page == 0) {
              setContents(d);
            } else {
              setContents([...contents, ...d]);
            }
            setCurrPage(currPage + size)
            setLoading(false);
            setIsCalling(false)
          });
        },
      })
    );
  };

  useEffect(() => {
    getNotification(notificationFilters[0].value, 0);
    setLoading(true);
  }, []);

  console.log('resultFilters', contents);
  return (
    <div className='space-y-5 mb-10 max-[767px]:mb-28'>
      <HorizontalScrollButtons buttons={notificationFilters} callback={(item) => {
        setFilter(item)
        getNotification(item, 0);
      }} />
      {contents.length > 0 ?
        contents.map((items, index) => {
          return <NotificationListItem items={items} index={index} />;
        }) : (loading ? (<Facebook className='max-w-[545px]' viewBox='0 0 200 124' />) : (<p className='text-center'>No notification yet</p>))}

    </div>
  );
};

export default NotificationList;
