import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { getMessages } from '../../redux/slices/mobile/messagingSlice';
import backBtn from '../../assets/icons/back.png';
import UserThumbsWithCounting from '../UsersThumbsWithCounting/UserThumbsWithCounting';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import Thumbnail from '../thumbnails/Thumbnail';
import { onTypeChange } from '../utils/typing-change';
import Cross from '../../assets/images/xmark-solid.svg';
import { PhotoSize } from '../utils/subjects';

const ManageModalSelectedContents = ({
  headerText,
  manageSelectedData,
  setManageSelectedDataHandle,
  dataList,
  selectUser,
  selectedUsers,
  setSelectedUsers,
  showStartConversationHandle,
  getMessageHandle,
  showChatRbSheetHandle,
  setUserLists,
  users,
  isFollower,
  setCurrentPage,
  setSearchText,
  startActionText,
  showCross,
  showSuggestedText,
  isLoading,
  startActionCallback,
  backBtnCallback,
  addedText,
}) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const chat = undefined;
  const [isShowCross, setIsShowCross] = useState(showCross);
  const inputRef = useRef();

  useEffect(() => {
    inputRef?.current?.blur();
  }, []);

  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  let userSelect = (data) => {
    let check = selectedUsers.some((val) => val.id === data.id);
    if (check) {
      let checked = selectedUsers.filter((val) => val.id !== data.id);
      setSelectedUsers((state) => checked);
    } else {
      setSelectedUsers((state) => state.concat(data));
    }
  };

  useEffect(() => {
    if (selectedUsers.length <= 0) {
      setManageSelectedDataHandle(false);
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (showCross) {
      setIsShowCross(manageSelectedData ? false : true);
    }
  }, [manageSelectedData]);

  console.log('manageSelectedData', dataList);
  return (
    <div className='space-y-3 h-full flex flex-col items-stretch content-stretch'>
      <div className='flex items-center gap-3'>
        <div className='h-fit'>
          <img
            src={isShowCross ? Cross : backBtn}
            alt='back-btn'
            className='cursor-pointer w-[15px] '
            onClick={() =>
              !manageSelectedData
                ? backBtnCallback && backBtnCallback()
                : setManageSelectedDataHandle(false)
            }
          />
        </div>

        {!manageSelectedData ? (
          headerText ? (
            <p className='text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary'>
              {headerText}
            </p>
          ) : (
            <div className='relative w-full'>
              <input
                type='search'
                placeholder='find someone'
                ref={inputRef}
                className='bg-gray-100 w-full w-full p-[8px_15px] rounded-[50px] focus:outline-none'
                onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                  setCurrentPage(0);
                  setUserLists([]);
                  setSelectedUsers([]);
                  setSearchText(value);
                })}
              />
              {/* <button className="w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]"></button> */}
            </div>
          )
        ) : (
          <p className='text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary'>
            Manage Your Selected Items
          </p>
        )}
      </div>

      {headerText && (
        <div className='relative w-full'>
          <input
            type='search'
            placeholder='find someone'
            className='bg-gray-100 w-full p-[10px_20px] rounded-[50px]'
            onChange={onTypeChange(typeInput, setTypeInput, (value) => {
              setCurrentPage(0);
              setUserLists([]);
              setSelectedUsers([]);
              setSearchText(value);
            })}
          />
          <button className='w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]'></button>
        </div>
      )}
      {selectedUsers?.length > 0 && (
        <div className='mb-5 max-[767px]:mb-3 flex justify-between items-center'>
          <UserThumbsWithCounting data={selectedUsers} />
          <div className='flex gap-1'>
            <button
              onClick={() => {
                setManageSelectedDataHandle(true);
              }}
              type='button'
              className='text-white bg-jrnyfy-themeColor p-[3px_10px] italic rounded-[50px] font-semibold text-jSecTitle border border-jrnyfy-themeColor hover:bg-white hover:text-jrnyfy-themeColor hover:scale-[1.1] max-[767px]:font-normal max-[767px]:text-[14px] transition-all font-SourceSansPro'
            >
              Manage
            </button>
            <button
              onClick={() => {
                if (startActionCallback) {
                  startActionCallback();
                }
              }}
              type='button'
              className='text-white bg-jrnyfy-themeColor p-[3px_10px] italic rounded-[50px] font-semibold text-jSecTitle border border-jrnyfy-themeColor hover:bg-white hover:text-jrnyfy-themeColor hover:scale-[1.1] max-[767px]:font-normal max-[767px]:text-[14px] transition-all font-SourceSansPro'
            >
              {startActionText}
            </button>
          </div>
        </div>
      )}
      {!isFollower && showSuggestedText && !manageSelectedData && (
        <div>
          <p className='text-jrnyfy-textprimary text-center'>
            only people who follow you can be added to your journey follow some
            people so they can follow you back
          </p>
          <p className='text-jrnyfy-textprimary text-start'>Suggestions</p>
        </div>
      )}
      {isLoading ? (
        <div className='flex justify-center'>
          <div className='spinner'></div>
        </div>
      ) : dataList?.length > 0 ? (
        <div className=' grow overflow-y-scroll'>
          <div className='space-y-2'>
            <div className='pr-3 flex flex-col justify-between gap-5 '>
              <div className='space-y-3'>
                {dataList?.length >= 1
                  ? dataList.map((val, ind) => (
                      <div
                        key={ind}
                        className='flex justify-between items-center w-full'
                      >
                        <Thumbnail
                          showName={true}
                          data={val}
                          PhotoSize={PhotoSize.Small}
                        />

                        <div className=''>
                          <button
                            onClick={() => userSelect(val)}
                            className={`p-[5px_15px] font-SourceSansPro rounded-[50px] font-semibold ${
                              selectedUsers.some(
                                (selectedUser) => selectedUser.id === val.id
                              )
                                ? 'bg-jrnyfy-themeColor text-white'
                                : 'border-jrnyfy-textprimary border border-solid text-jrnyfy-textprimary'
                            }`}
                          >
                            {selectedUsers.some(
                              (selectedUser) => selectedUser.id === val.id
                            )
                              ? addedText
                                ? addedText
                                : 'remove'
                              : 'add'}
                          </button>
                        </div>
                      </div>
                    ))
                  : ''}
              </div>
              {users.length >= 10 && (
                <div className='flex justify-center items-center'>
                  <button
                    onClick={() => {
                      setCurrentPage((prevState) => prevState + 10);
                    }}
                    type='button'
                    className='cursor-pointer border border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor  p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro'
                  >
                    show more
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center w-full'>No Result Found</div>
      )}
    </div>
  );
};

export default ManageModalSelectedContents;
