import FeedTags from '../FeedTags/FeedTags';
import FeedCard from '../FeedCard/FeedCard';
import { useSelector, useDispatch } from 'react-redux';
import { feedSelector, getFeeds } from '../../redux/slices/mobile/feedSlice';
import { useEffect, useId, useRef, useState, useTransition } from 'react';
import Spinner from '../spinner/spinner';
import CreateSuccessStory from '../FeedCard/CreateSuccessStory';
import { HorizontalTapView } from '../utils/HorizontalTabView';
import { feedFilters } from '../../utils/filters';
import CreateStoryPopUp from './CreateStoryPopUp';
import UpdateStoryPopUp from './UpdateStoryPopUp';
import {
  FeedCategoryFilter,
  FeedFilter,
  FeedSourceFilter,
} from '../utils/subjects';
import { authSelector, getUsers } from '../../redux/slices/mobile/authSlice';
import { onTypeChange } from '../utils/typing-change';
import Aside from '../Aside/Aside';
import UserListRenderer from '../userList/UserListRenderer';
import { BTN_SHARE_YOUR_STORY } from '../../utils/static';
import { Facebook } from 'react-content-loader';
import Cross from '../../assets/images/delete_image.svg';
import Seo from '../Seo/Seo';

const StoryPage = () => {
  let dispatch = useDispatch();
  const { auth } = useSelector(authSelector);
  const [filter, setFilter] = useState(FeedFilter.Story);
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  const [currPage, setCurrPage] = useState(0);
  const [contents, setContents] = useState([]);
  const [searchText, setSearchText] = useState(undefined);
  const [loadingState, setLoadingState] = useState(false);
  const [createSuccessStoryModal, setCreateSuccessStoryModal] = useState(false);
  const [updateSuccessStoryModal, setUpdateSuccessStoryModal] = useState(false);
  const [journey, setJourney] = useState('');

  const updateSuccessStoryHandle = () => {
    setCreateSuccessStoryModal((prevState) => !prevState);
    setUpdateSuccessStoryModal((prevState) => !prevState);
  };

  const createSuccessStory = () => {
    setCreateSuccessStoryModal((prevState) => !prevState);
  };

  document.onscroll = () => {
    if (
      window.innerHeight + window.scrollY + 1 >=
      document.body.scrollHeight / 2
    ) {
      setCurrPage(currPage + 10);
    }
  };

  useEffect(() => {
    const fetchData = () => {
      if (filter == -1) {
        dispatch(
          getUsers({
            data: {
              from: 0,
              size: 10,
              value:
                searchText && searchText.length > 0 ? searchText : undefined,
            },
          })
        ).then((res) => {
          setContents([...contents, ...res.payload.users]);
          setLoadingState(false);
        });
      } else {
        dispatch(
          getFeeds({
            data: {
              from: currPage,
              value:
                searchText && searchText.length > 0 ? searchText : undefined,
              filter,
              categoryFilter: FeedCategoryFilter.Story,
              size: 10,
            },
            token: auth?.token && auth?.token,
          })
        ).then((res) => {
          console.log('StoryFeedResult', res);
          setContents([...contents, ...res.payload.feeds]);
          console.log('StoryFeedContents', contents);
          setLoadingState(false);
        });
      }
    };

    fetchData();
    setLoadingState(true);
  }, [currPage, filter, searchText]);

  console.log('ContentFeed', contents);

  return (
    <>
      <Seo
        title='Success Stories'
        description=' Get ideas, tips and inspiration on how to accomplish journeys you always wanted to do'
        type='webapp'
        name='jrnyfy crop'
        canonicalHref='/story'
      />
      <main className='pt-4'>
        <div className='w-[90%] min-[1100px]:w-[75%] mx-auto flex justify-center min-[3000px]:w-1/2'>
          <aside className='hidden md:block md:w-[30%] mt-[-127px]'>
            <Aside />
          </aside>
          <div className='w-full md:w-[70%] md:px-[20px]'>
            <div>
              {/* <div className='max-w-[545px]'>
              <form>
                <div className='relative'>
                  <input
                    type='text'
                    placeholder='search journeys, get inspired'
                    className='bg-gray-100 w-full p-[10px_20px] rounded-[50px]'
                    value={typeInput.text}
                    onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                      setCurrPage(0);
                      setContents([]);
                      setSearchText(value);
                    })}
                  />
                  {searchText && (
                    <div className='absolute m-auto top-0 bottom-0 right-5 w-[25px] h-[25px] rounded-[50px]'>
                      <img
                        src={Cross}
                        alt='cross'
                        className='w-[25px] h-[25px]'
                        onClick={() => {
                          const value = '';
                          setTypeInput((state) => ({ ...state, text: '' }));
                          setSearchText('');
                        }}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div> */}

              <div className={'max-w-[545px] my-6 border-jrnyfy-border'}>
                <HorizontalTapView
                  items={feedFilters}
                  callback={(value) => {
                    setFilter(value);
                    setCurrPage(0);
                    setContents([]);
                  }}
                />
                {auth.token && (
                  <CreateSuccessStory
                    btnText={BTN_SHARE_YOUR_STORY}
                    callback={createSuccessStory}
                  />
                )}
              </div>
              {createSuccessStoryModal && (
                <CreateStoryPopUp
                  createSuccessStoryModal={createSuccessStoryModal}
                  createSuccessStory={createSuccessStory}
                  updateSuccessStoryHandle={updateSuccessStoryHandle}
                />
              )}
              {updateSuccessStoryModal && (
                <UpdateStoryPopUp
                  updateSuccessStoryModal={updateSuccessStoryModal}
                  updateSuccessStoryHandle={updateSuccessStoryHandle}
                />
              )}
              {/* <div className='flex flex-wrap space-x-2 my-6 px-2 md:hidden'>
              <FeedTags to='/' content='New Journeys' />
              <FeedTags to='/' content='Find Tours' />
              <FeedTags to='/' content='Find People' />
            </div> */}
              <div className='mt-5'>
                {filter == -1 ? (
                  <>
                    <UserListRenderer users={contents} />
                  </>
                ) : (
                  contents.map((data, ind) => (
                    <FeedCard data={data} key={ind} />
                  ))
                )}
                {loadingState && (
                  <Facebook className='max-w-[545px]' viewBox='0 0 200 124' />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default StoryPage;
