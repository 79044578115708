import RedeemBg from "../../assets/images/ticket_background.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import { buildUrlToSize } from "../../utils/build-image-url";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import {
  PhotoSize,
  TimelineProductEntryStatus,
  YesNo,
} from "../utils/subjects";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import {
  generateServiceDetailsUrl,
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import JrnyListModal from "./JrnyListModal";
import { useEffect, useState } from "react";
import { getActiveActivitiesOfUser } from "../../redux/slices/mobile/profileSlice";
import { UserJourneyFilter } from "../../utils/filters";
import { viewProfileSelector } from "../../redux/slices/mobile/viewProfileSlice";
import Thumbnail from "../thumbnails/Thumbnail";
import ShowTicketsModal from "../jrnyTimline/ShowTicketsModal";
import { addIntoWishList } from "../../redux/slices/mobile/bookingSlice";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import Icons from "../Icons/Icons";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";

const OrderedProduct = ({ index, data }) => {
  const { activity } = useSelector(activitySelector);
  const journeyName = data?.activity?.name ? data?.activity?.name : activity?.name;
  let avatar = buildUrlToSize(
    data?.feed ? data?.feed?.user?.thumbnail : data?.user?.thumbnail,
    PhotoSize.Large
  );
  const orderDate = data?.feed?.order
    ? data?.feed?.order?.date
    : data?.order?.date;

  const user = data?.feed ? data?.feed?.user : data?.user;
  const product = data?.feed?.product || data?.order?.product;
  const { activeActivities } = useSelector(viewProfileSelector);
  const [jrnyListModal, setJrnyListModal] = useState(false);
  let thumbnail =
    data?.feed?.product?.thumbnail || data?.order?.product?.thumbnail;
  let authinfo = useSelector(authSelector).auth;
  const { auth } = useSelector(authSelector);
  let dispatch = useDispatch();
  const name = data?.feed ? data?.feed?.user?.name : data?.user?.name;
  let fName = name.slice(0, 1);
  let mNamePos = name.search(" ");
  let mName = name.slice(mNamePos + 1, mNamePos + 2);
  let altTitle = fName + mName;
  let navigate = useNavigate();
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  const [showTickets, setShowTickets] = useState();

  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  const showTicketsHandle = () => {
    setShowTickets((prevState) => !prevState);
  };

  const jrnyListModalHandle = () => {
    setJrnyListModal((prevState) => !prevState);
  };

  useEffect(() => {
    console.log("ActiveActivitesddddd", activeActivities);
  }, [activeActivities]);

  const addThisToYourJourney = (activity) => {
    dispatch(
      addIntoWishList({
        data: {
          sk: activity.sk,
          createdAt: activity.createdAt,
          createdAtProduct: product.createdAt,
          skProduct: product.sk,
        },
        productId: product.id,
        activityId: activity.id,
        token: auth.token,
        callback: (result) => {},
      })
    );
  };

  return (
    <>
      <div className="flex justify-between items-start">
        <div className="relative">
          <div className="absolute top-0 left-[-24px]">
            <Thumbnail
              where={"orderded"}
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>
          {data?.feed?.user ? (
            <p
              className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold cursor-pointer"
              onClick={() => {
                // navigate(`/jrny/${data.id}`);
                navigate(getJourneyNavOption(data));
              }}
            >
              <span className="text-jrnyfy-title">
                {data?.feed?.user?.name} booked on{" "}
                <Moment format="YYYY/MM/DD">{orderDate}</Moment>
                {data?.feed?.product?.name} for journey:{" "}
              </span>
              {journeyName}
            </p>
          ) : (
            <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold ">
              <span className="text-jrnyfy-title">
                {data?.feed?.user?.name} booked on{" "}
                <Moment format="YYYY/MM/DD">{orderDate}</Moment>{" "}
                {data?.feed?.product?.name} for journey:{" "}
              </span>
              {journeyName}
            </p>
          )}

          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {user.name}, created{" "}
            <Moment fromNow>{data?.createdAt}</Moment>
          </p>
        </div>
      </div>

      <div className="mt-10 ">
        <div className="ml-[20px] w-auto relative">
          <img
            src={RedeemBg}
            alt="Ticket"
            className="min-[500px]:max-md:h-[200px]"
          />
          <div className="w-4/5 absolute left-0 top-0 rotate-[-5deg] drop-shadow-[0_4px_3px_black] rounded-[10px]">
            <div>
              <Swiper
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                style={{
                  "--swiper-pagination-color": "white",
                  "--swiper-pagination-bullet-inactive-color": "#999999",
                  "--swiper-pagination-bullet-inactive-opacity": "1",
                }}
              >
                <SwiperSlide>
                  <img
                    src={buildUrlToSize(thumbnail, PhotoSize.Large)}
                    alt="Ticket"
                    className="rounded-t-[10px] w-full max-h-[160px] min-[350px]:max-[450px]:max-h-[110px] object-cover"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="bg-white rounded-b-[10px] p-[10px_20px] md:p-[15px_20px]">
              <p className="leading-[15px] text-[12px] md:text-[16px] min-[350px]:max-[450px]:overflow-hidden min-[350px]:max-[450px]:text-ellipsis min-[350px]:max-[450px]:whitespace-nowrap">
                {data?.feed?.product?.name || data?.order?.product?.name}
              </p>
            </div>
          </div>
        </div>
        {!data?.feed?.user && (
          <div className="flex space-x-9 ml-[20px] mt-[15px] md:mt-[20px] leading-[10px] md:leading-[15px]">
            {activity?.isEditable == 0 ? (
              <div className="space-x-3">
                <p className="mb-6">
                  This service booked by {data?.user?.name}
                </p>
                <button
                  onClick={jrnyListModalHandle}
                  className="p-[10px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[14px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
                >
                  add this to your journey
                </button>
                <button
                  onClick={() => navigate(generateServiceDetailsUrl(product))}
                  className="p-[10px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[14px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
                >
                  book now
                </button>
              </div>
            ) : data?.order?.status == TimelineProductEntryStatus.Confirmed ? (
              <button
                onClick={showTicketsHandle}
                className="p-[10px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[14px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
              >
                Show Tickets
              </button>
            ) : (
              <button
                onClick={() => navigate(generateServiceDetailsUrl(product))}
                className="p-[10px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[14px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
              >
                Request Sent
              </button>
            )}
          </div>
        )}
        {/* Shows the current user available journeys */}
        {jrnyListModal && (
          <JrnyListModal
            jrnyListModalHandle={jrnyListModalHandle}
            jrnyListModal={jrnyListModal}
            addThisToYourJourney={addThisToYourJourney}
          />
        )}
        {/* This modal show all tickets status */}
        {showTickets && (
          <ShowTicketsModal
            data={data}
            showTickets={showTickets}
            showTicketsHandle={showTicketsHandle}
          />
        )}
      </div>
    </>
  );
};

export default OrderedProduct;
