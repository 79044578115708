import React from 'react';
import featuredProductImage from '../../assets/images/service-page-featured-image.png';
import userImage from '../../assets/images/user-image.png';
import LikeIcon from '../../assets/images/feed_component_like.svg';
import Share from '../../assets/images/feed_component_share.svg';
import Comment from '../../assets/images/feed_component_comment.svg';
import Trophy from '../../assets/images/feed_component_trophy.svg';
import Ideas from '../../assets/images/feed_component_idea.svg';
import Option from '../../assets/images/ellipsis-vertical-solid.svg';
import Icons from '../Icons/Icons';

const FeaturedProduct = () => {
  return (
    <div>
      <div className='flex'>
        <div>
          <p className='indent-[-30px] text-[25px] max-w-[450px] pl-[15px] ml-[30px] leading-[25px]'>
            See the magic of Paris in one day
          </p>
          <div className='flex'>
            <div className='relative py-5'>
              <div className='absolute m-auto top-0 bottom-0 right-0 left-0 w-[4px] border-l-4 h-[100%] -z-40'></div>
              <img
                src={userImage}
                alt='userThumb'
                className='rounded-[50%] border-4'
              />
            </div>
            <div className='py-5 pl-[15px]'>
              <p className='max-w-[393px] text-[14px] text-gray-600'>
                See the Mona Lisa in the Louvre Museum and check the other art
                work from Mone, check out the Arc de Triumph and have a 4 course
                Parisian
              </p>
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center  ml-[85px]'>
          <img
            className='rounded-[50%]'
            src={featuredProductImage}
            alt='featured product'
          />
          <button className='p-[8px_38px_9px_32px] mt-5 rounded-[50px] text-[12px] border border-jrnyfy-themeColor text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white hover:bg-jrnyfy-themeColor transition-all font-SourceSansPro'>
            add it to your journey
          </button>
        </div>
      </div>
      <div className='ml-[15px] border-b'>
        <div className='flex justify-between'>
          <div className='flex space-x-10'>
            <div className='flex space-x-3'>
              <img className='w-[20px] h-[20px]' src={LikeIcon} alt='like' />
              <span>253 likes</span>
            </div>
            <div className='flex space-x-3'>
              <img className='w-[20px] h-[20px]' src={Share} alt='like' />
              <span>45 Shares</span>
            </div>
            <div className='flex space-x-3'>
              <img className='w-[20px] h-[20px]' src={Comment} alt='like' />
              <span>53 Comments</span>
            </div>
          </div>
          <div className='flex'>
            <img className='w-[30px] h-[30px]' src={Option} alt='like' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProduct;
