import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { buildUrlToSize } from "../../utils/build-image-url";
import { useNavigate } from "react-router-dom";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { getUserNavOption } from "../../utils/get-nav-option";
import {
  followRequest,
  unfollowRequest,
} from "../../redux/slices/mobile/profileSlice";
import { FollowerFollowingStatus, PhotoSize, YesNo } from "../utils/subjects";

const Thumbnail = ({
  nameIconWordCount,
  data,
  btnFollow = true,
  activeIcon,
  showName,
  where,
  localUrl,
  thumbnailContainerStyle,
  border,
  isRedirect = true,
  PhotoSize,
  activeIconStatus,
}) => {
  let dispatch = useDispatch();
  let {
    auth: { token },
  } = useSelector(authSelector);
  const [following, setFollowing] = useState(data?.following);
  let navigate = useNavigate();
  let fName = data?.name?.slice(0, 1);
  let mNamePos = data?.name?.search(" ");
  let mName =
    mNamePos != -1 ? data?.name?.slice(mNamePos + 1, mNamePos + 2) : "";

  let altTitle = nameIconWordCount == 1 ? fName : fName + mName;
  const [isThumbnail, setIsThumbnail] = useState(
    data?.thumbnail ? true : false
  );

  let clickControl = (e) => {
    e.stopPropagation();
    navigate(getUserNavOption(data));
  };

  useEffect(() => {
    setIsThumbnail(data?.thumbnail ? true : false);
  }, [data?.thumbnail]);

  console.log("ShowName", data);
  useEffect(() => { }, [following]);
  let onClickFollow = () => {
    console.log("Click");
    if (token) {
      if (!data?.following) {
        dispatch(
          followRequest({
            token,
            data: { sk: data?.sk, id: data?.id },
            callback: (results) => {
              console.log("FOllow Request", results);
              setFollowing({
                id: results.following.id,
                status: FollowerFollowingStatus.Pending,
              });
            },
          })
        );
      } else {
        dispatch(
          unfollowRequest({
            token,
            data: { sk: data?.sk, id: data?.id },
            callback: (results) => {
              setFollowing(undefined);
            },
          })
        );
      }
    }
  };

  return (
    <div className="flex items-center cursor-pointer  w-fit rounded-[5px]">
      <div
        className={`relative 
          flex 
          items-center 
          justify-center 
          text-center 
          ${!isThumbnail && "bg-jrnyfy-themeColor"} 
          font-SourceSansPro 
          italic rounded-[50%] 
          text-white
          h-[40px]
          w-[40px]
        ${thumbnailContainerStyle}
        `}
        onClick={(e) => isRedirect && clickControl(e)}
      >
        {!isThumbnail ? (
          <p className="uppercase">{altTitle}</p>
        ) : (
          <img
            onError={() => setIsThumbnail(false)}
            src={
              localUrl
                ? localUrl
                : buildUrlToSize(data?.thumbnail, PhotoSize && PhotoSize)
            }
            alt={altTitle}
            className="w-full h-full rounded-[50%] text-white object-cover"
          />
        )}
        {activeIcon && (
          <span
            className={`absolute -top-1 -right-1 border-2 border-white rounded-[50%] block w-5 h-5 ${activeIconStatus
              ? "bg-jrnyfy-chatActive"
              : "bg-jrnyfy-textprimary"
              } `}
          ></span>
        )}
      </div>
      {((btnFollow && token) || showName) && (
        <div className="ml-[10px] overflow-hidden max-w-[245px]">
          {showName && (
            <h4
              onClick={clickControl}
              className={`max-[500px]:text-[14px] min-[501px]:text-jSecTitle font-semibold md:overflow-hidden md:whitespace-nowrap md:text-ellipsis`}
            >
              {data?.name}
            </h4>
          )}
          {btnFollow && token && (
            <button
              onClick={onClickFollow}
              className="bg-jrnyfy-themeColor p-[2px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              {following == undefined
                ? "follow"
                : following.status == YesNo.Yes
                  ? "Following"
                  : "Request Sent"}
            </button>
          )}
          {/* <p className='text-jrnyfy-textprimary leading-[18px] text-[14px] font-medium '>
          {userid}
        </p> */}
        </div>
      )}
    </div>
  );
};

export default Thumbnail;
