import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css/free-mode";
import { buildUrlToSize } from "../../utils/build-image-url";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import Cross from "../../assets/images/xmark-solid.svg";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import { PhotoSize, YesNo } from "../utils/subjects";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import {
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import PopUpImageGallery from "../popUpImageGallery/PopUpImageGallery";
import ImageGallery from "react-image-gallery";
import cancelBtnSvg from "../../assets/images/Cancel.svg";
import Thumbnail from "../thumbnails/Thumbnail";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import Icons from "../Icons/Icons";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";
import ImageGalleryRenderer from "../ImageGallery/ImageGalleryRenderer";

const PhotoAdded = ({ index, data }) => {
  let [popUpImage, setpopUpImage] = useState({
    status: false,
    value: null,
  });

  let modalCondition = (imageUrl) => {
    setpopUpImage((state) => ({ status: !state.status, value: imageUrl }));
  };
  let gallery = data?.gallery || data?.photos;
  let avater = buildUrlToSize(
    data?.feed ? data?.feed?.user?.thumbnail : data?.user?.thumbnail,
    PhotoSize.Large
  );
  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();
  const user = data?.feed ? data?.feed?.user : data?.user;
  let fName = data?.feed?.user?.name.slice(0, 1);
  let mNamePos = data?.feed?.user?.name.search(" ");
  let mName = data?.feed?.user?.name.slice(mNamePos + 1, mNamePos + 2);
  let altTitle = fName + mName;
  let navigate = useNavigate();
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  const [showImageGallery, setShowImageGallery] = useState(false);

  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = "hidden";
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = "scroll";
  };

  return (
    <div>
      <div className="flex justify-between items-start">
        <div className="relative">
          <div className="absolute top-0 left-[-24px]">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>

          {data?.feed?.user ? (
            <p
              className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold cursor-pointer"
              onClick={() => {
                // navigate(`/jrny/${data.id}`);
                navigate(getJourneyNavOption(data));
              }}
            >
              <span className="text-jrnyfy-title">
                {user.name} added some pictures to their journey:{" "}
              </span>{" "}
              {data.name}
            </p>
          ) : (
            <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold">
              <span className="text-jrnyfy-title">
                {user.name} added some pictures to their journey:{" "}
              </span>{" "}
              {data.name}
            </p>
          )}

          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {user.name}, created{" "}
            <Moment fromNow>{data?.feed?.createdAt}</Moment>
          </p>
        </div>
        {authinfo?.user?.id == data?.user?.id &&
          !data?.feed?.user &&
          (data.loading ? (
            <div className="spinner"></div>
          ) : (
            <div className="ml-[10px] md:ml-[30px]">
              <Icons
                iconClick={() => deleteModalHandle(true)}
                icon={Option}
                alt="Option"
                width="25px"
              />
            </div>
          ))}
      </div>
      {deleteModal && (
        <TimelineOptionsModal
          index={index}
          data={data}
          actionStates={actionStates}
          deleteModal={deleteModal}
          deleteModalHandle={deleteModalHandle}
        />
      )}
      <div className="mt-4 ml-[20px] w-auto">
        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          freeMode={true}
          modules={[FreeMode]}
        >
          {gallery.map((val, ind) => (
            <SwiperSlide key={ind}>
              <img
                onClick={() => {
                  setGalleryCurrentIndex(ind);
                  openImageGallery();
                }}
                src={buildUrlToSize(val, PhotoSize.Large)}
                alt="places"
                className="w-[200px] rounded-[10px] h-[100px] md:h-[120px] 2xl:h-[140px] cursor-pointer object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {showImageGallery && (
        <ImageGalleryRenderer
          timelineIndex={index}
          closeImageGallery={closeImageGallery}
          galleryCurrentIndex={galleryCurrentIndex}
          photos={gallery}
        />
      )}
    </div>
  );
};

export default PhotoAdded;
