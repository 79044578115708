import React from 'react'
import { useLocation } from 'react-router-dom';
import TimelineItemDetails from '../components/FeedCard/TimelineItemDetails';
import { useSelector } from 'react-redux';
import { authSelector } from '../redux/slices/mobile/authSlice';

export default function TimelineDetailsPage() {
    const { state } = useLocation();
    const { item, auth } = state;
    console.log("NotificaitonItem", item)
    return (
        <div className='max-w-[1500px] mx-auto px-5'>
            <div className='flex justify-center gap-10 mt-5'>
                <div className='max-w-[545px] w-full'>
                    {item && (<TimelineItemDetails item={item} auth={auth} />)}
                </div>
            </div>
        </div>
    )
}
