import React, { useEffect } from 'react';
import { parseTypeHeadServicesUrlParams } from '../../utils/get-nav-option';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getServices,
  serviceSelector,
} from '../../redux/slices/web/serviceSlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import FilteredProduct from './FilteredProduct';
import FilterSidebar from './FilterSidebar';
import { activitySelector } from '../../redux/slices/mobile/activitySlice';
import Seo from '../Seo/Seo';

const ServiceFilter = () => {
  const [searchParams] = useSearchParams();
  const { activity } = useSelector(activitySelector);
  const { auth, userType, firstTimeLogIn } = useSelector(authSelector);
  const { services } = useSelector(serviceSelector);
  const paramData = parseTypeHeadServicesUrlParams(searchParams);
  console.log('services', paramData.location);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log('Activity Tags', paramData.tags);
    dispatch(
      getServices({
        data:
          paramData.tags.length > 0
            ? { tags: paramData.tags, from: 0, size: 10 }
            : {
                from: 0,
                size: 10,
                location: paramData.lat
                  ? { lat: paramData.lat, lon: paramData.lon }
                  : undefined,
                value: paramData.value ? paramData.value : undefined,
                startDate: paramData.startDate
                  ? paramData.startDate
                  : undefined,
                endDate: paramData.endDate ? paramData.endDate : undefined,
              },
        token: auth.token,
      })
    );
  }, []);
  return (
    <>
      <Seo
        title={`Filter Services | JRNYFY`}
        description=''
        type='webapp'
        name='jrnyfy crop'
      />
      <main className='pt-8'>
        <div className='max-w-[1200px] mx-auto'>
          <div className='flex gap-10'>
            <FilteredProduct services={services} />
            <FilterSidebar />
          </div>
        </div>
      </main>
    </>
  );
};

export default ServiceFilter;
