import { Swiper, SwiperSlide } from 'swiper/react';
import Cross from '../../assets/images/delete_image.svg';
import editIcon from '../../assets/icons/edit.png';
import NoPicture from '../../assets/images/image_upload.svg';
import { useCallback, useEffect, useState } from 'react';
import { FreeMode } from 'swiper';
import Popup from 'reactjs-popup';
import { useDispatch } from 'react-redux';
import { ImageUploadLimit } from '../utils/subjects';
import RangeSlider from 'react-range-slider-input';

import Cropper from 'react-easy-crop';
import getCroppedImg from '../userProfile/getCroppedImg';

const ProgressPicThumb = ({
  setIsEditEnable,
  isEditEnable,
  btnText,
  selectImg,
  setSelectImg,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(1);
  const [zoomChange, setZoomChange] = useState([0, 1]);
  const [rotationChange, setRotationChange] = useState([0, 0]);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  let [imageUrl, setImageUrl] = useState({ url: '', index: '' });
  const [imageFile, setImageFile] = useState();
  // let [selectImg, setSelectImg] = useState(
  //   storyImages ? storyImages : undefined
  // );

  // useEffect(() => {
  //   callback(selectImg);
  // }, [selectImg]);
  console.log('setEditEnable', isEditEnable);
  const onImageChange = (event) => {
    console.log('onImageChange', event.target.files);
    const files = [...event.target.files];
    const filesUrls = files.map((item) => {
      return { url: URL.createObjectURL(item), file: item };
    });
    setSelectImg([...filesUrls]);
  };

  const removeImage = (ind) => {
    setSelectImg(selectImg.filter((images, index) => index != ind));
  };

  console.log('Selected Images', selectImg);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const setCroppedImageHandle = () => {
    try {
      let tempSelectedImage = [...selectImg];
      getCroppedImg(imageUrl?.url, croppedAreaPixels, rotation).then((res) => {
        {
          console.log('donee', res);
          tempSelectedImage[imageUrl.index] = {
            url: URL.createObjectURL(res),
            file: res,
          };
          setSelectImg(tempSelectedImage);
          setImageUrl({ ...imageUrl, url: URL.createObjectURL(res) });
          setImageFile(res);

          setIsEditEnable(false);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setZoom(zoomChange[1]);
  }, [zoomChange]);

  useEffect(() => {
    setRotation(rotationChange[1]);
  }, [rotationChange]);

  return (
    <div className='relative w-full'>
      {!isEditEnable &&
        (selectImg && selectImg.length >= ImageUploadLimit ? (
          <span>
            You cannot upload more than {ImageUploadLimit} image. Please remove{' '}
            {selectImg.length - ImageUploadLimit} images
          </span>
        ) : (
          <label className='p-[10px] bg-jrnyfy-border rounded-[30px] flex justify-center items-center'>
            <input
              type='file'
              accept="image/*,video/*"
              className='hidden'
              multiple
              onChange={onImageChange}
            />
            {!selectImg ? btnText : 'Add image or video'}
            <img
              src={NoPicture}
              alt='select_image_thumbnail'
              className='w-[40px]'
            />
          </label>
        ))}
      {isEditEnable ? (
        <div className='min-h-[400px] p-10 relative'>
          <div className='w-full h-[300px] absolute top-0 left-0 right-0 bottom-0'>
            <Cropper
              image={imageUrl.url}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              showGrid={true}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              classes={{ containerClassName: 'rounded-xl' }}
            />
          </div>

          <div className='mt-[300px] space-y-3'>
            <div>
              <label className='block font-medium text-jrnyfy-textprimary dark:text-white my-3'>
                Zoom : {parseInt(zoom)}%
              </label>

              <RangeSlider
                min={1}
                className='single-thumb'
                defaultValue={[0, 1]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                step={0.01}
                value={zoomChange}
                onInput={setZoomChange}
              />
            </div>
            <div>
              <label className='block font-medium text-jrnyfy-textprimary dark:text-white my-3'>
                Rotation : {parseInt(rotation)} deg
              </label>

              <RangeSlider
                className='single-thumb'
                defaultValue={[0, 0]}
                min={0}
                max={360}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                step={0.01}
                value={rotationChange}
                onInput={setRotationChange}
              />
            </div>

            <div className='flex justify-between'>
              <button
                onClick={() => setIsEditEnable(false)}
                className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
              >
                Back
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCroppedImageHandle();
                }}
                className='z-40 bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
              >
                Done
              </button>
            </div>
          </div>
        </div>
      ) : (
        selectImg && (
          <div className='mt-4 max-w-full'>
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              freeMode={true}
              modules={[FreeMode]}
            >
              {selectImg.map((val, ind) => (
                <SwiperSlide key={ind}>
                  <div>
                    {1 == 1 ? (
                      <video className='w-[200px] rounded-[10px] h-[100px] md:h-[120px] 2xl:h-[140px] object-cover' controls>
                        <source src={val?.url} />
                      </video>) : (
                      <img
                        src={val?.url}
                        alt='places'
                        className='w-[200px] rounded-[10px] h-[100px] md:h-[120px] 2xl:h-[140px] object-cover'
                        onClick={true}
                      />)}

                    <div className='absolute top-0 right-0 rounded-[50px] cursor-pointer'>
                      <img
                        src={Cross}
                        alt='cross'
                        className='w-[25px] h-[25px]'
                        onClick={() => {
                          removeImage(ind);
                        }}
                      />
                    </div>
                    <div className='absolute top-0 left-0 rounded-[50px] cursor-pointer'>
                      <img
                        src={editIcon}
                        alt='cross'
                        className='w-[25px] h-[25px]'
                        onClick={() => {
                          setImageUrl({
                            url: val?.url,
                            index: ind,
                          });
                          setImageFile(val?.file);
                          setIsEditEnable(true);
                        }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )
      )}
    </div>
  );
};

export default ProgressPicThumb;
