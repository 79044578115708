import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {tagPaginationOffset} from '../../../res/dimens';
import {baseUrl} from '../../../environments/proxyUrl';
import {
  parse_SourceArray,
  parse_SourceObject,
  parse_SourceArray_suggestions,
  parse_userListObjectWithInnerHits_fofo,
} from './elasticParser';

export const createNewTag = createAsyncThunk(
  'tag/createNewTag',
  async ({formData, token, callback}, {rejectWithValue}) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/worker/v1/upload`,
        formData,
        {
          headers: {
            Authorization: token,
            'Content-Type': `multipart/form-data; `,
          },
        },
      );
      console.log('uploadGalleryPhotos', response);
      if (callback) {
        callback(response);
      }
      return response.data.results;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

// export const createNewTag = createAsyncThunk(
//   'tag/createNewTag',
//   async ({formData, token, callback}, {rejectWithValue}) => {
//     console.log('createNewTag');
//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/worker/v1/img-uploader`,
//         formData,
//         {
//           headers: {
//             Authorization: token,
//             'Content-Type': `multipart/form-data; `,
//           },
//         },
//       );
//       console.log('Response tags create', response);
//       if (callback) {
//         callback(response.data.results);
//       }
//       return response.data.results;
//     } catch (err) {
//       console.log('UploadTagPhotoERR', err);
//       if (!err) {
//         throw err;
//       }
//       return rejectWithValue(err);
//     }
//   },
// );

export const tagsFromText = createAsyncThunk(
  'tags/FromText',
  async ({token, value, callback}) => {
    if (value.length > 0) {
      const {data} = await axios.get(`${baseUrl}/api/search/v1/parse-tags/`, {
        params: {name: value},
        headers: {
          Authorization: token,
        },
      });
      console.log('results ==', data);
      const {tags, suggestions,followers} = data.results;
      let users = []
      let suggestion = []
      if(followers?.length>0){
        users = await parse_userListObjectWithInnerHits_fofo(followers);
      }
      console.log('FollowersList',followers)
      if(suggestions){
        suggestion = await parse_SourceArray(suggestions) 
      }
      if (callback) {
        callback(suggestion,users);
      }

      return parse_SourceArray(tags);
    } else {
      return [];
    }
  },
);

// export const placesFromText = createAsyncThunk(
//   'tags/placesFromText',
//   async ({token, value, callback}) => {
//     const {data} = await axios.get(`${baseUrl}/api/search/v1/places/parse-text/`, {
//       params: {name: value},
//       headers: {
//         Authorization: token,
//       },
//     });
//     if (callback) {
//       callback(parse_SourceArray(data.results));
//     }

//     return parse_SourceArray(data.results);
//   },
// );

export const getMatchedTags = createAsyncThunk(
  'tags/getMatchedTags',
  async ({token, data}, {rejectWithValue}) => {
    try {
      console.log('response redux getMatchedTags');

      const {data: res} = await axios.get(`${baseUrl}/api/search/v1/tags`, {
        params: {...data},
        headers: {
          Authorization: token,
        },
      });
      console.log('response redux getMatchedTags', res);
      return parse_SourceArray(res.results);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMoreMatchedTags = createAsyncThunk(
  'tags/getNextMatchedTags',
  async ({token, data}) => {
    const {data: res} = await axios.get(`${baseUrl}/api/search/v1/tags`, {
      params: {...data},
      headers: {
        Authorization: token,
      },
    });

    return parse_SourceArray(res.results);
  },
);
// export const getAdminMoreMatchedTags = createAsyncThunk(
//   'tags/getAdminMoreMatchedTags',
//   async ({token, data}) => {
//     const {data: res} = await axios.post(
//       `${baseUrl}/api/search/v1/tags/get-all-matched-tags`,
//       data,
//       {
//         headers: {
//           Authorization: token,
//         },
//       },
//     );

//     return parse_SourceArray(res.results);
//   },
// );
// export const getSuggestedTags = createAsyncThunk(
//   'tags/getSuggestedTags',
//   async ({token, data}) => {
//     const {data: res} = await axios.get(
//       `${baseUrl}/api/search/v1/tags/suggest?activityId=${
//         data.activityId ? data.activityId : null
//       }&productId=${data.productId ? data.productId : null}&size=${data.size}`,
//       {
//         validateStatus: false,
//         headers: {
//           Authorization: token,
//         },
//       },
//     );

//     return parse_SourceArray(res.results);
//   },
// );
// export const createTag = createAsyncThunk(
//   'tags/createTag',
//   async ({token, data, callback}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.post(`${baseUrl}/api/tags/v1`, data, {
//         headers: {
//           Authorization: token,
//         },
//       });
//       if (callback) {
//         callback(res.results);
//       }
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       console.log('errors.......', err.response.data);
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
// export const updateTag = createAsyncThunk(
//   'tags/updateTag',
//   async ({token, data, name}) => {
//     const {data: res} = await axios.put(`${baseUrl}/api/tags/v1/${name}`, data, {
//       headers: {
//         Authorization: token,
//       },
//     });

//     return res.results;
//   },
// );

export const addLocationIntoTag = createAsyncThunk(
  'tag/addLocation',
  async ({data, token, name}, {rejectWithValue}) => {
    console.log('data tag', data);
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/tags/v1/location`,
        {...data, tagName: name},
        {
          headers: {Authorization: token},
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTagByName = createAsyncThunk(
  'activity/getTagByName',
  async ({data, token, id, readOnly = true}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.get(`${baseUrl}/api/tags/v1/tag/`, {
        params: data,
        headers: {Authorization: token},
      });
      // const results = parse_SourceArray(data.results);
      console.log('data ', res.data);
      return {results: res.results, readOnly};
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
const tagSliceV2 = createSlice({
  name: 'tagV2',
  initialState: {
    tags: [],
    tag: {},
    selectedTags: [],
    suggestedTags: [],
    loading: false,
    newlyCreateTag: {},
    uploadingPhoto: false,
    errors: [],
    tagCheckingComplete: false,
    deletedTagsName: [],
    contentRange: undefined,
  },
  reducers: {
    refreshTagSlice: (state, action) => {
      state.tags = [];
      state.selectedTags = [];
      state.deletedTagsName = [];
      state.newlyCreateTag = {};
      state.uploadingPhoto = false;
      state.errors = [];
    },
    clearTags: (state, action) => {
      state.tags = [];
      state.newlyCreateTag = {};
      state.uploadingPhoto = false;
      state.errors = [];
      state.selectedTags = [];
    },
    clearErrors: (state, action) => {
      // state.errors = [];
    },
    resetTagChecking: (state, action) => {
      state.tagCheckingComplete = false;
    },
    addTag: (state, action) => {
      const {tag} = action.payload;
      let selectedTags = state.selectedTags.map(item => item);
      selectedTags.push({...tag, addon: 1});
      state.selectedTags = selectedTags;
      state.tags = state.tags.filter(t => t.name !== tag.name);
    },

    removeTag: (state, action) => {
      console.log('Removing tag: state.payload ', action.payload);
      const {name} = action.payload;
      state.selectedTags = state.selectedTags.filter(function (el) {
        return el.name != name;
      });
      state.deletedTagsName.push(name);
    },
    removeTagAfterSelection: (state, action) => {
      const {name} = action.payload;
      state.tags = state.tags.filter(function (el) {
        return el.name != name;
      });
      console.log('tags', state.tags);
    },
  },
  extraReducers: {
    [tagsFromText.pending]: state => {
      // state.loading = true;
      state.errors = [];
      //state.selectedTags = [];
      state.tagCheckingComplete = false;
    },
    [tagsFromText.fulfilled]: (state, action) => {
      // let newTags = action.payload.filter(function (item) {
      //   return (
      //     state.deletedTagsName.find(function (item2) {
      //       return item.name == item2;
      //     }) == undefined
      //   );
      // });
      // state.selectedTags = newTags.concat(
      //   state.selectedTags.filter(sT => sT.addon === 1),
      // );
      // state.tagCheckingComplete = true;
      state.selectedTags = action.payload;
    },
    [tagsFromText.rejected]: (state, action) => {
      // state.loading = false;
      state.tagCheckingComplete = true;
    },
    [getMatchedTags.pending]: state => {
      state.loading = true;
      state.tags = [];
      state.errors = [];
    },
    [getMatchedTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.tags = action.payload;
    },
    [getMatchedTags.rejected]: (state, action) => {
      state.loading = false;

      state.errors = action.payload;
      console.log('state.errors', state.errors);
    },
    [getMoreMatchedTags.pending]: state => {
      state.loading = true;
      state.errors = [];
    },
    [getMoreMatchedTags.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.length == 0) {
        state.contentRange = 'max';
      }
      const aTags = state.tags.concat(action.payload);
      state.tags = aTags;
    },
    [getMoreMatchedTags.rejected]: (state, action) => {
      state.loading = false;
    },

    // [createTag.pending]: state => {
    //   state.loading = true;
    //   state.errors = [];
    // },
    // [createTag.fulfilled]: (state, action) => {
    //   state.newlyCreateTag = action.payload;
    //   let tags = state.selectedTags.map(item => item);
    //   tags.push({...action.payload, addon: 1});
    //   state.selectedTags = tags;
    //   state.loading = false;
    // },
    // [createTag.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },

    // [updateTag.pending]: state => {
    //   state.loading = true;
    //   state.errors = [];
    // },
    // [updateTag.fulfilled]: (state, action) => {
    //   state.loading = false;
    // },
    // [updateTag.rejected]: (state, action) => {
    //   state.loading = false;
    // },

    [createNewTag.fulfilled]: (state, action) => {
      state.errors = [];
      state.uploadingPhoto = false;
    },
    [createNewTag.rejected]: (state, action) => {
      state.uploadingPhoto = false;
      state.errors = action.payload;
    },
    [createNewTag.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getTagByName.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.tag = {};
    },
    [getTagByName.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload) {
        state.tag = {
          ...action.payload.results,
          readOnly: action.payload.readOnly,
        };
      }
      state.loading = false;
    },
    [getTagByName.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  refreshTagSlice,
  removeTag,
  addTag,
  clearTags,
  resetTagChecking,
  clearDeletedTags,
  clearErrors,
  removeTagAfterSelection,
} = tagSliceV2.actions;

export default tagSliceV2.reducer;

export const tagSelectorV2 = state => state.tagV2;
