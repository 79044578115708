import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Popup from "reactjs-popup";
import { Swiper, SwiperSlide } from "swiper/react";
import { buildUrlToSize } from "../../utils/build-image-url";
import {
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import { useNavigate } from "react-router-dom";
import { FreeMode } from "swiper";
import { PhotoSize } from "../utils/subjects";
import ImageGallery from "react-image-gallery";
import cancelBtnSvg from "../../assets/images/Cancel.svg";
import Thumbnail from "../thumbnails/Thumbnail";
import Seo from "../Seo/Seo";
//new comments

function Story({ data }) {
  const navigate = useNavigate();
  let avater = buildUrlToSize(
    data?.feed ? data?.feed?.user?.thumbnail : data?.user?.thumbnail,
    PhotoSize.Large
  );
  const user = data?.feed ? data?.feed?.user : data?.user;
  let [mState, setMstate] = useState(false);
  let fName = data?.feed?.user
    ? data?.feed?.user?.name.slice(0, 1)
    : data?.user?.name.slice(0, 1);
  let mNamePos = data?.feed?.user
    ? data?.feed?.user?.name.search(" ")
    : data?.user?.name.search(" ");
  let mName = data?.feed?.user
    ? data?.feed?.user?.name.slice(mNamePos + 1, mNamePos + 2)
    : data?.user?.name.slice(mNamePos + 1, mNamePos + 2);
  let altTitle = fName + mName;

  let modalCondition = () => {
    setMstate((state) => !state);
  };

  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = "hidden";
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = "scroll";
  };

  useEffect(() => {
    setGalleryImages([]);
    data?.gallery?.forEach((item) => {
      const imgSrc = buildUrlToSize(item, PhotoSize.XLarge);
      console.log(imgSrc);
      setGalleryImages((prevState) => [
        ...prevState,
        {
          original: imgSrc,
          thumbnail: imgSrc,
        },
      ]);
    });
  }, [data?.gallery]);
  return (
    <>
      <div className="relative">
        <div className="absolute top-0 left-[-24px]">
          <Thumbnail
            ShowName={false}
            btnFollow={false}
            data={user}
            PhotoSize={PhotoSize.Small}
          />
        </div>
        <p
          className="pl-[30px] md:px-[30px] text-jrny font-semibold cursor-pointer"
          onClick={() => {
            // navigate(`/jrny/${data.id}`);
            navigate(getJourneyNavOption(data));
          }}
        >
          <span className="text-jrnyfy-title">
            {data?.user?.name}'s success story:{" "}
          </span>
          {data?.activity?.name}
        </p>

        <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
          added by {user?.name}, created{" "}
          <Moment fromNow>{data.createdAt}</Moment>
        </p>
      </div>
      <div className="mt-4 ml-[20px] w-auto">
        <div className="mb-[20px] mt-[10px]">
          <p className="text-ellipsis overflow-hidden ...">
            {data?.description}
          </p>
        </div>
        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          freeMode={true}
          modules={[FreeMode]}
        >
          {data?.gallery && data?.gallery.length > 0
            ? data?.gallery?.map((val, ind) => (
                <SwiperSlide key={ind}>
                  <img
                    src={buildUrlToSize(val, PhotoSize.Large)}
                    alt="places"
                    className="w-[200px] rounded-[10px] h-[100px] md:h-[120px] 2xl:h-[140px] cursor-pointer object-cover"
                    onClick={() => {
                      setGalleryCurrentIndex(ind);
                      openImageGallery();
                    }}
                  />

                  {/* <Popup
                    open={mState}
                    closeOnDocumentClick={false}
                    contentStyle={{
                      width: '100%',
                      backgroundColor: 'transparent',
                      border: 'none',
                    }}
                  >
                    <div className='h-full max-h-screen overflow-auto'>
                      <div className='text-right'>
                        <button
                          className='text-[35px] font-bold text-white translate-x-[-260px]'
                          onClick={modalCondition}
                        >
                          &#10006;
                        </button>
                      </div>
                      <div className='flex justify-center'>
                        <img
                          src={buildUrlToSize(val, PhotoSize.Large)}
                          alt='Place'
                        />
                      </div>
                    </div>
                  </Popup> */}
                </SwiperSlide>
              ))
            : null}
        </Swiper>
      </div>
      {showImageGallery && (
        <div className="fixed flex items-center bg-black top-0 left-0 right-0 bottom-0 m-auto z-40">
          <button onClick={closeImageGallery}>
            <img
              src={cancelBtnSvg}
              alt="cross"
              className="cursor-pointer w-[25px] absolute top-[20px] left-[25px] z-50"
            />
          </button>
          <ImageGallery
            startIndex={galleryCurrentIndex}
            showIndex={true}
            items={galleryImages}
          />
        </div>
      )}
    </>
  );
}

export default Story;
