import { AnimatePresence } from "framer-motion";
import Popup from "reactjs-popup";
import Cross from "../../assets/images/xmark-solid.svg";
import Comment from "../../assets/images/comments.svg";
import {
  activitySelector,
  createComment,
  getJourneyContent,
} from "../../redux/slices/mobile/activitySlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { buildUrlToSize } from "../../utils/build-image-url";
import { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import OptionIcon from "../../assets/images/ellipsis-vertical-solid.svg";
import { PhotoSize, UserContentFilter } from "../utils/subjects";
import Thumbnail from "../thumbnails/Thumbnail";
import CommentOptions from "./CommentOptions";
import LikeModal from "./likeModal";
import TimelineItemDetails from "./TimelineItemDetails";

const CommentsModal = ({ cModal, cModalControl, data }) => {
  let authInfo = useSelector(authSelector);
  const { auth } = useSelector(authSelector);
  let activity = useSelector(activitySelector);
  const [commentsLikesUserList, setCommentsLikesUserList] = useState(false);
  let dispatch = useDispatch();
  let [commentText, setCommentText] = useState("");
  const [commentOptions, setCommentOptions] = useState(false);
  const [commentData, setCommentData] = useState(false);
  const user = auth?.user;
  console.log("Data on Comments", activity);


  return (
    <Popup
      open={cModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: "100%",
        height: "90vh",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",

        padding: "0px",
      }}
    >
      <div className="relative bg-white w-[700px] flex flex-col rounded-[20px] p-10  max-[767px]:p-5 max-[767px]:h-screen  max-[767px]:overflow-y-scroll max-[600px]:rounded-none">
        {/* -------cross button-------- */}
        {/* <img
          src={Cross}
          alt='cross'
          className='cursor-pointer w-[20px] '
          onClick={cModalControl}
        /> */}
        <div className="flex items-center">
          <div className="w-fit">
            <img
              src={Cross}
              alt="cross"
              className="cursor-pointer w-[20px]"
              onClick={() => cModalControl(false)}
            />
          </div>
          <div className="w-full flex justify-center items-center">
            <img src={Comment} alt="place" className="max-w-[50px]" />
            <p className="text-jrny font-semibold">See comments on this post</p>
          </div>
        </div>
        <div className="flex flex-col  flex-1 h-[calc(100%-50px)]">
          <TimelineItemDetails item={{
            data: {
              doc: {
                id: data.id,
                sk: data.sk
              }
            }
          }} auth={auth} />
        </div>
      </div>
    </Popup>
  );
};

export default CommentsModal;
