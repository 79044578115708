import { NavLink, useLocation } from 'react-router-dom';

const NavMenu = () => {
  const activeStyle = 'activeStyle';
  const location = useLocation();

  return (
    <ul className='flex space-x-7 text-jrnyfy-textprimary'>
      <li>
        <NavLink
          to='/'
          className={({ isActive }) =>
            isActive || location.pathname == '/' ? activeStyle : undefined
          }
        >
          JOURNEYS
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          to='/service'
          className={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          FIND SERVICES AND GUIDE
        </NavLink>
      </li> */}
      <li>
        <NavLink
          to='/story'
          className={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          SUCCESS STORIES
        </NavLink>
      </li>
    </ul>
  );
};

export default NavMenu;
