// import { StorageBucketName } from "../static";
import { PhotoSource } from '../components/utils/subjects';
import { constructPhotoUrl } from '../res/commonFunctions';

export const buildUrlToSize = (obj, size) => {
  if (
    obj?.photoSource == PhotoSource.UserUploaded ||
    obj?.photoSource == PhotoSource.UserUploadedPublicRecord
  ) {
    const newRef = constructPhotoUrl(obj?.photoReference, size);
    return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET_NAME}/${newRef}`;
    // if (obj.updatedAt) {
    //   return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET_NAME}/${newRef}?updatedAt=${obj.updatedAt}`;
    // } else {
    //   return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET_NAME}/${newRef}`;
    // }
  } else if (obj?.photoSource == PhotoSource.GooglePlace) {
    return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${obj?.photoReference}&key=AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE`;
  } else if (obj?.photoSource == PhotoSource.GooglePlaceFullURL) {
    return obj?.photoReference;
  } else if (obj?.photoSource == PhotoSource.GoogleUser) {
    return obj?.photoReference;
  } else if (obj?.photoSource == PhotoSource.FacebookUser) {
    return obj?.photoReference;
  } else if (obj?.photoSource == PhotoSource.LocalStorage) {
    return obj?.photoReference;
  }
};

export const buildDocumentUrl = (doc) => {
  return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET_NAME}/${doc?.docReference}`;
};

export const convertThumbnailToLarge = (obj) => {
  const parts = obj?.photoReference?.split('.');
  const newRef = parts[0] + `-xl.` + parts[1];
  if (
    obj?.photoSource == PhotoSource.UserUploaded ||
    obj?.photoSource == PhotoSource.UserUploadedPublicRecord
  ) {
    return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET_NAME}/${newRef}`;
  } else if (obj?.photoSource == PhotoSource.GooglePlace) {
    return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${obj?.photoReference}&key=AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE`;
  } else if (obj?.photoSource == PhotoSource.GooglePlaceFullURL) {
    return obj?.photoReference;
  } else if (obj?.photoSource == PhotoSource.GoogleUser) {
    return obj?.photoReference;
  } else if (obj?.photoSource == PhotoSource.FacebookUser) {
    return obj?.photoReference;
  }
};
