import LikeIcon from "../../assets/images/feed_component_like.svg";
import Share from "../../assets/images/feed_component_share.svg";
import Comment from "../../assets/images/feed_component_comment.svg";
import Trophy from "../../assets/images/feed_component_trophy.svg";
import Ideas from "../../assets/images/feed_component_idea.svg";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import Icons from "../Icons/Icons";
import TaskCreate from "../CardComponents/TaskCreate";
import ParticipantAdded from "../CardComponents/ParticipantAdded";
import LocationAdded from "../CardComponents/LocationAdded";
import OrderedProduct from "../CardComponents/OrderedProduct";
import WishedProduct from "../CardComponents/WishedProduct";
import InspirerAdded from "../CardComponents/InspirerAdded";
import PhotoAdded from "../CardComponents/PhotoAdded";
import CardComponents8 from "../CardComponents/CardComponents8";
import ActivityCreate from "../CardComponents/ActivityCreate";
import Progress from "../CardComponents/Progress";
import BadgeAdded from "../CardComponents/BadgeAdded";
import StoryAdded from "../CardComponents/StoryAdded";
import { ContentFilter, TimelineType, UserContentFilter } from "../utils/subjects";
import JrnyPageParticipantAdd from "../CardComponents/jrnyPageParticipantAdd";
import Visible from "../../assets/images/visibility.svg";
import { useSelector, useDispatch } from "react-redux";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import { useEffect, useState } from "react";
import LikeModal from "./likeModal";
import CommentsModal from "./commentsModal";
import BadegsModal from "./badgeModal";
import {
  likeAnActivity,
  authSelector,
} from "../../redux/slices/mobile/authSlice";
import Heart from "../../assets/images/heart_icon.svg";
import Login from "../Login/Login";
import {
  feedSelector,
  getLikesOfActivity,
} from "../../redux/slices/mobile/feedSlice";
import axios from "axios";
import { useBeforeUnload, useLocation, useNavigate } from "react-router-dom";
import Story from "../successStory/Story";
import FinihsedAt from "../CardComponents/FinihsedAt";
import ShareModal from "./ShareModal";
import FeedOptionModal from "./FeedOptionModal";
import { getNumberFormat } from "../utils/static/global_functions";

const FeedCard = ({ isEditable,isTimeline, data, index, length }) => {
  console.log("Feed", data);
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  let authInfo = useSelector(authSelector);
  let Feed = useSelector(feedSelector);
  let dispatch = useDispatch();
  let type = data?.feed ? data?.feed?.type : data?.type;
  console.log("Type", type);
  let [likesAmount, setLikesAmount] = useState(data?.likes);
  let elements;
  let [condLogin, setCondLogin] = useState(false);
  let [lModal, setLModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname.split("/", 2)[1];
  console.log("location.pathname", location.pathname);
  const [shareModal, setShareModal] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(false);

  const showOptionModalHandle = (action) => {
    if (action) {
      window.history.pushState(null, "", location.pathname);
    } else {
      navigate(-1);
    }
    setShowOptionModal(action);
  };

  console.log("data Type", TimelineType.Create);

  const shareModalHandle = (action) => {
    if (action) {
      window.history.pushState(null, "", location.pathname);
    } else {
      navigate(-1);
    }
    setShareModal(action);
  };

  let lModalControl = (action) => {
    if (action) {
      window.history.pushState(null, "", location.pathname);
    } else {
      navigate(-1);
    }
    if (authInfo?.auth?.user?.verified) {
      setLModal(action);
    } else {
      setCondLogin(!condLogin);
    }
  };
  let [cModal, setCModal] = useState(false);
  let cModalControl = (action) => {
    if (action) {
      window.history.pushState(null, "", location.pathname);
    } else {
      navigate(-1);
    }
    if (authInfo?.auth?.user?.verified) {
      setCModal(action);
    } else {
      setCondLogin((state) => !state);
    }
  };
  let [bModal, setBModal] = useState(false);
  let bModalControl = () => {
    setBModal((state) => !state);
  };

  let [like, setLike] = useState(false);
  let likeControl = () => {
    if (authInfo?.auth?.user?.verified) {
      if (like) {
        setLike(false);
        dispatch(
          likeAnActivity({
            data: {
              operator: -1,
              createdAt: data.createdAt,
              sk: data.sk,
            },
            token: authInfo?.auth?.token,
            id: data.id,
          })
        );
        setLikesAmount((state) => state - 1);
      } else {
        setLike(true);
        dispatch(
          likeAnActivity({
            data: {
              operator: 1,
              createdAt: data.createdAt,
              sk: data.sk,
            },
            token: authInfo?.auth?.token,
            id: data.id,
          })
        );
        setLikesAmount((state) => state + 1);
      }
    } else {
      setCondLogin((state) => !state);
    }
  };

  let [userListLikes, setUserListLikes] = useState([]);

  useEffect(() => {
    // dispatch(
    //     getLikesOfActivity(
    //         {
    //             data: {
    //                 from: 0,
    //                 size: 15,
    //                 filterId: data.id,
    //                 value: ''
    //             },
    //             token: authInfo.auth.token,
    //             // activityId: data.id,
    //             callback: e => { },
    //         }
    //     )
    // );

    // axios
    //   .get(`${process.env.REACT_APP_BASE_URL}/api/search/v1/content/`, {
    //     params: {
    //       from: 0,
    //       size: 1000,
    //       filterId: data.id,
    //       filter: UserContentFilter.GetLikesOfAJourney,
    //     },
    //     headers: { Authorization: authInfo.auth.token },
    //   })
    //   .then((res) => {
    //     if (res.data.results.length > 0) {
    //       setUserListLikes(res.data?.results);
    //     }
    //   });
  }, []);

  useEffect(() => {
    if (userListLikes.length && authInfo.auth.user) {
      const isLiked = userListLikes.some(
        (res) =>
          res?.inner_hits?.user.hits.hits[0]._source.id ===
          authInfo?.auth?.user.id
      );
      if (isLiked) {
        setLike(true);
      } else {
        setLike(false);
      }
    } else {
      setLike(false);
    }
  }, [userListLikes]);

  // console.log(data.id, ':', Feed.userListLikes);
  // console.log(userListLikes);

    if (
      type === TimelineType.TaskCreate ||
      data.type === TimelineType.TaskCreate
    ) {

      elements = (
        <TaskCreate isEditable={isEditable} index={index} data={data} />
      );

    } else if (
      type === TimelineType.InspirerAdded ||
      data.type === TimelineType.InspirerAdded
    ) {
      elements = (
        <InspirerAdded isEditable={isEditable} index={index} data={data} />
      );
    } else if (
      type === TimelineType.WishedProduct ||
      data.type === TimelineType.WishedProduct
    ) {
      console.log("WishedProduct");
      elements = (
        <WishedProduct isEditable={isEditable} index={index} data={data} />
      );
    } else if (
      type === TimelineType.Create ||
      data.type === TimelineType.Create
    ) {
      console.log("ActivityCreate");
      elements = (
        <ActivityCreate isEditable={isEditable} index={index} data={data} />
      );
    } else if (
      type === TimelineType.Progress ||
      data.type === TimelineType.Progress
    ) {
      console.log("Progress");
      elements = <Progress isTimeline={isTimeline} isEditable={isEditable} index={index} data={data} />;
    } else if (
      type === TimelineType.OrderedProduct ||
      data.type === TimelineType.OrderedProduct
    ) {
      console.log("OrderedProduct");
      elements = (
        <OrderedProduct isEditable={isEditable} index={index} data={data} />
      );
    } else if (type === TimelineType.ParticipantAdded && data?.feed?.user) {
      console.log("ParticipantAdded");
      elements = (
        <ParticipantAdded isEditable={isEditable} index={index} data={data} />
      );
    } else if (data.type === TimelineType.ParticipantAdded) {
      console.log('JrnyPageParticipantAdd');
      elements = <JrnyPageParticipantAdd index={index} data={data} />;
    } 
  

  //    else if (type === TimelineType.InspirerAdded) {
  //     elements = <InspirerAdded data={data} />
  // }   else if (type === TimelineType.BadgeAdded) {
  //     elements = <BadgeAdded data={data} />
  // }  else if (type === 20) {
  //     elements = <CardComponents8 data={data} />
  // }.

  window.addEventListener("popstate", () => {
    console.log("Poping satte");
    if (showOptionModal) {
      // navigate(null, { replace: true });
      // showOptionModalHandle(false);
      setShowOptionModal(false);
    } else if (shareModal) {
      // navigate(null, { replace: true });

      setShareModal(false);
    } else if (lModal) {
      // navigate(null, { replace: true });
      if (authInfo?.auth?.user?.verified) {
        setLModal(false);
      } else {
        setCondLogin(false);
      }
    } else if (cModal) {
      if (authInfo?.auth?.user?.verified) {
        setCModal(false);
      } else {
        setCondLogin(false);
      }
    }
  });

  useBeforeUnload();

  return (
    <>
      {data?.type != TimelineType.ParticipantAdded &&
        data?.type != TimelineType.PhotoAdded && (
          <div
            className={`max-w-[525px] ml-[20px] ${currentRoute == "journey" ? "pb-5" : "mb-6 border-b-2"
              } border-l-[4px] ${length} ${index} ${length - 1 == index
                ? "border-transparent"
                : "border-solid border-jrnyfy-border"
              }`}
          >
            {elements}
            <div className="flex justify-between mt-[20px] mb-[15px] ml-[15px]">
              {condLogin && (
                <Login
                  mState={condLogin}
                  setMState={() => {
                    setCondLogin((state) => !state);
                  }}
                />
              )}
              <div>
                <Icons
                  icon={like ? Heart : LikeIcon}
                  alt="Like"
                  content={{ amount: likesAmount, name: "likes" }}
                  textClick={() => lModalControl(true)}
                  iconClick={likeControl}
                  width="15px"
                />
                {lModal && (
                  <LikeModal
                    lModal={lModal}
                    lModalControl={lModalControl}
                    data={data}
                    filter={ContentFilter.Like}
                  />
                )}
              </div>
              {/* <div className="">
                <Icons
                  icon={Share}
                  alt="Like"
                  width="15px"
                  content={{ amount: data?.shareCount, name: "Share" }}
                  textClick={shareModalHandle}
                  iconClick={shareModalHandle}
                />
              </div> */}
              <div className="">
                <Icons
                  icon={Comment}
                  alt="Share"
                  width="15px"
                  content={{ amount: data?.commentCount, name: "Comment" }}
                  textClick={() => cModalControl(true)}
                  iconClick={() => cModalControl(true)}
                />
                {cModal && (
                  <CommentsModal
                    cModal={cModal}
                    cModalControl={cModalControl}
                    data={data}
                  />
                )}
              </div>
              {/* <div className=''>
                  <Icons
                    icon={Trophy}
                    alt='Trophy'
                    width='15px'
                    content={{
                      amount: data?.inspireCount || 0,
                      name: 'Badges',
                    }}
                    textClick={bModalControl}
                  />
                  {bModal && (
                    <BadegsModal
                      bModal={bModal}
                      bModalControl={bModalControl}
                    />
                  )}
                </div> */}
              {/* <div className=''>
                  <Icons
                    icon={Ideas}
                    alt='Ideas'
                    width='15px'
                    content={{ amount: 0, name: 'Inspired' }}
                  />
                </div> */}
              <div className="">
                <Icons
                  icon={Visible}
                  alt="views"
                  width="15px"
                  content={{
                    amount: getNumberFormat(data?.viewCount),
                    name: "Views",
                  }}
                />
              </div>
              {/* <div className=''>
                  <Icons
                    icon={Ideas}
                    alt='Ideas'
                    width='15px'
                    content={{ amount: 0, name: 'Inspired' }}
                  />
                </div> */}
              <div className="">
                <Icons
                  iconClick={() => showOptionModalHandle(true)}
                  icon={Option}
                  alt="Option"
                  width="7px"
                />
              </div>
              {showOptionModal && (
                <FeedOptionModal
                  data={data}
                  showOptionModal={showOptionModal}
                  showOptionModalHandle={showOptionModalHandle}
                />
              )}
            </div>

          </div>
        )}

      {shareModal && (
        <ShareModal
          data={data}
          shareModalHandle={shareModalHandle}
          shareModal={shareModal}
        />
      )}
    </>
  );
};

export default FeedCard;
