import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import {
  getAllProducts,
  productSelector,
} from '../../redux/slices/mobile/productSlice';
import { PhotoSize, pageSize } from '../utils/subjects';
import { buildUrlToSize } from '../../utils/build-image-url';
import ReactStars from 'react-rating-stars-component';
import {
  getServices,
  serviceSelector,
} from '../../redux/slices/web/serviceSlice';
import { Link, useNavigate } from 'react-router-dom';
import { generateServiceDetailsUrl } from '../../utils/get-nav-option';
import SliderServices from '../slider services/SliderServices';

const TopTours = () => {
  const { auth, userType, firstTimeLogIn } = useSelector(authSelector);
  const { services } = useSelector(serviceSelector);
  const { startDate, endDate } = useSelector(serviceSelector);
  console.log('Service FIlter', startDate, endDate);

  const profileScreenActivitySize = pageSize.profileScreenActivitySize;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getServices({
        data: {
          from: 0,
          size: 10,
        },
        token: auth.token,
      })
    );
  }, []);

  console.log('services', services);
  return (
    <div className='mt-[10px]'>
      <div className='text-[20px] text-center text-gray-400'>
        <p>tried of planning trips?</p>
        <p>spend less time planning your trip, spend time enjoying your trip</p>
      </div>
      <div className='inline-flex items-center justify-center w-full mt-[10px]'>
        <hr className='w-full h-px my-8 bg-gray-200 border-0 dark:bg-gray-700' />
        <span className='absolute bg-white italic mb-2 px-3 text-gray-400 font-extrabold text-[25px] -translate-x-1/2 left-1/2'>
          top tours
        </span>
      </div>
      {/* ------Slider------ */}
      <div className='mt-4 w-full mt-[10px] mx-[-150px]'>
        <SliderServices
          slidesPerView={6}
          spaceBetween={10}
          services={services}
        />
      </div>
    </div>
  );
};

export default TopTours;
