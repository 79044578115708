import Logo from '../../assets/images/Jrnyfy_logo.png';
import LogoService from '../../assets/images/logo-service-page.png';
import NavMenu from '../NavMenu/NavMenu';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Login from '../Login/Login';
import { useDispatch, useSelector } from 'react-redux';
import {
  authSelector,
  getNotificationCount,
  getUsers,
  switchToUser,
} from '../../redux/slices/mobile/authSlice';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format, addDays } from 'date-fns';
import magnifyingGlass from '../../assets/images/search-magnifying-glass-svgrepo-com.svg';
import tagImage from '../../assets/images/tag-svgrepo-com.svg';
import locationPin from '../../assets/images/location-pin-svgrepo-com.svg';

import Geolocation from './GeoLocation';

import { buildUrlToSize } from '../../utils/build-image-url';
import JrnyCreate from '../jrnyCreate/jrnyCreate';
import { serviceSelector } from '../../redux/slices/web/serviceSlice';
import axios from 'axios';
import { onTypeChange } from '../utils/typing-change';
import { parse_SourceArray } from '../../redux/slices/mobile/elasticParser';
import {
  generateServiceDetailsUrl,
  getTypeHeadServicesNavOption,
  getUserNavOption,
} from '../../utils/get-nav-option';
import { FeedCategoryFilter, FeedFilter, PhotoSize } from '../utils/subjects';
import Thumbnail from '../thumbnails/Thumbnail';
import SearchBar from '../search/SearchBar';
import { getNumberFormat } from '../utils/static/global_functions';

const Header = ({
  setContents,
  setIsLogoClick,
  setSearchedText,
  tagSearchText,
  setTagSearchText,
}) => {
  let [login, setLogin] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname.split('/');
  const {
    auth: { token },
    messageCount,
    notificationCount,
  } = useSelector(authSelector);
  let loginControl = () => {
    setLogin((state) => !state);
  };
  let [jrnyModal, setJrnyModal] = useState(false);
  let jrnyModalControl = () => {
    setJrnyModal((state) => !state);
  };
  let authinfo = useSelector(authSelector).auth;
  let userThumb = buildUrlToSize(authinfo?.user?.thumbnail, PhotoSize.Large);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showCitySearch, setShowCitySearch] = useState(false);
  const locationRef = useRef();
  const settingRef = useRef();
  const { startDate, endDate } = useSelector(serviceSelector);
  const dateRangeRef = useRef();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(undefined);
  const [currPage, setCurrPage] = useState(0);
  const [dateSelect, setDateSelect] = useState(false);
  const [resultContents, setResultContents] = useState([]);
  const [tags, setTags] = useState([]);
  const [services, setServices] = useState([]);
  const [searchedProduct, setSearchedProduct] = useState('');
  const [redirectTo, setRedirectTo] = useState('');

  const navigate = useNavigate();

  const [searchBtnClick, setSearchBtnClick] = useState(false);
  let [userArr, setUserArr] = useState([]);
  let [placeArr, setPlaceArr] = useState([]);
  let [postValue, setPostValue] = useState({
    vissible: true,
    value: undefined,
  });
  let loginRequireControl = () => {
    setLogin(true);
  };
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
    searchType: '',
  });
  console.log('TypeInput', typeInput);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    dispatch(
      getNotificationCount({
        token: authinfo?.token,
      })
    );
  }, []);

  const showUserMenuHandle = () => {
    setShowUserMenu((prevState) => !prevState);
  };
  console.log('date Val', date);
  useEffect(() => {
    if (searchText) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/search/v1/typehead/`, {
          params: {
            from: 0,
            size: 10,
            value: searchText,
          },
          headers: { Authorization: authinfo.token },
        })
        .then((res) => {
          const result = parse_SourceArray(res.data.results.services);
          parse_SourceArray(res.data.results.services).then((res) => {
            setServices(res);
          });

          parse_SourceArray(res.data.results.tags).then((res) => {
            setTags(res);
          });
        });
    } else {
      setServices('');
      setTags('');
    }
  }, [searchText]);
  useEffect(() => {
    const hideModalOutSideClickHandle = (e) => {
      if (!locationRef?.current?.contains(e.target)) {
        setShowCitySearch(false);
      }
      if (!dateRangeRef?.current?.contains(e.target)) {
        setShowDatePicker(false);
      }
      if (!settingRef?.current?.contains(e.target)) {
        setShowUserMenu(false);
      }
    };
    document.addEventListener('mousedown', hideModalOutSideClickHandle);
  });

  useEffect(() => {
    if (services) {
      setShowCitySearch(true);
    }
  }, [services.length]);
  useEffect(() => {
    if (tags) {
      setShowCitySearch(true);
    }
  }, [tags.length]);

  const onSearchButtonClick = (coords) => {
    if (typeInput.searchType == 'service') {
      navigate(generateServiceDetailsUrl(searchedProduct), {
        state: searchedProduct,
      });
    } else {
      let sp = {};
      if (coords) {
        sp = { lat: coords?.latitude, lon: coords?.longitude };
      }
      if (typeInput.searchType == 'user' && typeInput.text.length > 0) {
        sp = { ...sp, text: typeInput.text };
      }
      if (dateSelect) {
        sp = {
          ...sp,
          startDate: format(date[0].startDate, 'yyyy-MM-dd'),
          endDate: format(date[0].endDate, 'yyyy-MM-dd'),
        };
      }
      navigate(
        getTypeHeadServicesNavOption(sp, typeInput?.text, tags?.destinationName)
      );
    }
  };

  useEffect(() => {}, [typeInput.text], [showCitySearch]);

  const userLocationHandle = (locationRequest, coords) => {
    if (locationRequest) {
      onSearchButtonClick(coords);
      setSearchBtnClick(false);
    }
  };

  useEffect(() => {
    document.body.style.overflowY = 'scroll';
  }, [location.pathname]);

  return (
    <>
      {currentRoute[1] == 'service' ? (
        <div className="max-w-[1000px] mx-auto">
          <div className='mx-auto h-[330px] bg-[url("/public/service-page-header.png")] bg-cover bg-center rounded-b-lg'>
            <nav className="flex items-start justify-between px-[10px] p-[27px_25px_25px_25px]">
              <div className="mt-[23px] ml-[75px]">
                <NavLink to="/">
                  <img
                    onClick={() => {
                      setContents([]);
                      setIsLogoClick(Math.random() * 100);
                    }}
                    src={LogoService}
                    alt="Logo"
                    className="w-[100px] max-[500px]:w-[50px]"
                  />
                </NavLink>
              </div>
              <div className="flex gap-x-2">
                <div className="">
                  <p
                    className="rounded-[41px]  p-[5px_32px_6px_23px] bg-white text-gray-500 text-[18px]"
                    onClick={loginControl}
                  >
                    {authinfo?.user?.verified === 1 ? (
                      <span className="flex items-center gap-1">
                        <div>
                          <Thumbnail
                            nameIconWordCount={1}
                            ShowName={false}
                            btnFollow={false}
                            data={authinfo?.user}
                            PhotoSize={PhotoSize.Small}
                            thumbnailContainerStyle={'!w-[25px] !h-[25px] !text-[14px]'}
                          />
                        </div>
                        {authinfo?.user?.name.slice(0, 5)}...
                      </span>
                    ) : (
                      'login'
                    )}
                  </p>
                  {login && (
                    <Login
                      redirectTo={redirectTo}
                      mState={login}
                      setMState={loginControl}
                    />
                  )}
                </div>
                <div>
                  {authinfo?.user?.role == 50 ? (
                    <p
                      onClick={() => {
                        if (authinfo.token) {
                          dispatch(
                            switchToUser({
                              data: {
                                callback: (data) => {
                                  if (data?.user?.lastRole == 50) {
                                    navigate('/vendor');
                                  } else if (data?.user?.lastRole == 100) {
                                    navigate(getUserNavOption(data?.user));
                                  }
                                },
                              },
                              token: authinfo.token,
                            })
                          );
                        }
                      }}
                      className="cursor-pointer rounded-[41px]  p-[5px_32px_6px_23px] bg-white text-gray-500 text-[18px]"
                    >
                      {authinfo.user.lastRole == 50
                        ? 'Switch to vendor'
                        : 'Switch to User Profile'}
                    </p>
                  ) : (
                    <p
                      onClick={() => {
                        navigate('/partner');
                      }}
                      className="cursor-pointer rounded-[41px]  p-[5px_32px_6px_23px] bg-white text-gray-500 text-[18px]"
                    >
                      Partner with us
                    </p>
                  )}
                </div>
              </div>
            </nav>
            <div className="relative flex items-center rounded-[60px] bg-white mt-[50px] max-w-[600px] mx-auto gap-0.5">
              <div
                ref={locationRef}
                className="flex-auto w-[70%] rounded-[60px] hover:shadow-[0_0_10px_0_rgba(0,0,0,.25)] hover:bg-gray-500"
              >
                <input
                  onClick={() => setShowCitySearch((prevState) => !prevState)}
                  onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                    setCurrPage(0);
                    setResultContents([]);
                    setSearchText(value);
                  })}
                  value={typeInput.text}
                  className="rounded-[60px] p-[18px_50px] focus:outline-none w-full text-gray-400"
                  placeholder="Where does your journey take place?"
                />
                {showCitySearch && (
                  <div className="absolute top-[65px] bg-white rounded-xl w-full p-[10px_50px] drop-shadow-2xl z-40 space-y-5">
                    {(!services || !tags) && (
                      <button
                        onClick={() => {
                          setShowCitySearch((prevState) => !prevState);
                          setTypeInput((prevState) => ({
                            ...prevState,
                            text: 'Nearby',
                            searchType: 'nearby',
                          }));
                        }}
                        className="flex items-center gap-2 "
                      >
                        <img
                          src={locationPin}
                          alt="location-pin"
                          className="w-[25px] h-[25px]"
                        />
                        <span>Nearby</span>
                      </button>
                    )}
                    {services.length > 0 && (
                      <div className="space-y-3">
                        {services.map((item) => {
                          return (
                            <button
                              onClick={() => {
                                setSearchedProduct(item);
                                setShowCitySearch((prevState) => !prevState);
                                setTypeInput((prevState) => ({
                                  ...prevState,
                                  text: item.name,
                                  searchType: 'service',
                                }));
                              }}
                              className="flex items-center gap-3"
                            >
                              <img
                                className="w-[30px] h-[30px] rounded-[3px]"
                                src={buildUrlToSize(
                                  item.thumbnail,
                                  PhotoSize.Large
                                )}
                                alt="service-search"
                              />
                              <span>{item.name}</span>
                            </button>
                          );
                        })}
                      </div>
                    )}
                    {tags.length > 0 && (
                      <div className="space-y-3">
                        {tags.map((item) => {
                          return (
                            <button
                              onClick={() => {
                                setShowCitySearch((prevState) => !prevState);
                                setTypeInput((prevState) => ({
                                  ...prevState,
                                  text: item.name,
                                  searchType: 'tag',
                                }));
                              }}
                              className="flex items-center gap-3"
                            >
                              <img
                                className="w-[30px] h-[30px] rounded-[3px]"
                                src={tagImage}
                                alt="service-search"
                              />

                              <span>{item.name}</span>
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="border-l-2 border-gray-300 h-[30px]"></div>
              <div
                ref={dateRangeRef}
                className="relative flex-auto w-[30%] rounded-[60px] hover:shadow-[0_0_10px_0_rgba(0,0,0,.25)] hover:bg-gray-500"
              >
                <input
                  onClick={() => setShowDatePicker((prevState) => !prevState)}
                  className="rounded-[60px] p-[18px_30px] focus:outline-none w-full text-gray-400"
                  placeholder="when?"
                  value={
                    dateSelect
                      ? `${format(date[0].startDate, 'LLL-dd')} to ${format(
                          date[0].endDate,
                          'LLL-dd'
                        )}`
                      : 'select-dates'
                  }
                />
                {showDatePicker && (
                  <DateRange
                    minDate={new Date()}
                    maxDate={addDays(new Date(), 10)}
                    editableDateInputs={true}
                    onChange={(item) => {
                      setDateSelect(true);
                      setDate([item.selection]);
                    }}
                    moveRangeOnFirstSelection={false}
                    className="absolute top-[65px] left-0 z-40 rounded-xl shadow-xl"
                    ranges={date}
                  />
                )}
                <div className="absolute bg-jrnyfy-themeColor rounded-[50%] p-[5px] top-0 bottom-0 m-auto right-4  w-[30px] h-[30px]">
                  <button
                    onClick={() => {
                      typeInput.searchType == 'nearby'
                        ? setSearchBtnClick(true)
                        : onSearchButtonClick();
                    }}
                  >
                    <img
                      src={magnifyingGlass}
                      className="w-full"
                      alt="magnifying-glass"
                    />
                  </button>
                </div>
              </div>
            </div>
            {searchBtnClick && <Geolocation callback={userLocationHandle} />}
          </div>
        </div>
      ) : (
        <nav className="px-6 py-[10px] border-b-2 border-solid border-jrnyfy-border md:space-y-0 sticky top-0 bg-white z-30">
          <div className="flex items-center max-[889px]:justify-between min-[890px]:justify-start">
            <div className="max-[890px]:w-fit w-[15%] md:w-[22%] text-right">
              <NavLink to="/">
                <img
                  onClick={() => setIsLogoClick(Math.random() * 100)}
                  src={Logo}
                  alt="Logo"
                  className="w-[90px] max-[500px]:w-[70px] inline-block"
                />
              </NavLink>
            </div>

            <div className="max-[767px]:w-[100%] w-[45%] md:w-[50%] md:space-y-3">
              <div className=" md:flex justify-center">
                <SearchBar
                  setContents={setContents}
                  setSearchedText={setSearchedText}
                  tagSearchText={tagSearchText}
                  setTagSearchText={setTagSearchText}
                />
              </div>
              {(currentRoute[1] == 'services' ||
                currentRoute[1] == 'service-details') && (
                <div className="relative flex items-center rounded-[60px] bg-white mt-[50px] max-w-[500px] mx-auto gap-0.5 border">
                  <div
                    ref={locationRef}
                    className="flex-auto w-[70%] rounded-[60px] hover:shadow-[0_0_10px_0_rgba(0,0,0,.25)] hover:bg-gray-500"
                  >
                    <input
                      onClick={() =>
                        setShowCitySearch((prevState) => !prevState)
                      }
                      onChange={onTypeChange(
                        typeInput,
                        setTypeInput,
                        (value) => {
                          setCurrPage(0);
                          setResultContents([]);
                          setSearchText(value);
                        }
                      )}
                      value={typeInput.text}
                      className="rounded-[60px] p-[10px_40px] focus:outline-none w-full text-gray-400"
                      placeholder="Select a place or thing to do"
                    />
                    {showCitySearch && (
                      <div className="absolute top-[50px] bg-white rounded-xl w-full p-[10px_50px] drop-shadow-2xl z-40 space-y-5">
                        {(!services || !tags) && (
                          <button
                            onClick={() => {
                              setShowCitySearch((prevState) => !prevState);
                              setTypeInput((prevState) => ({
                                ...prevState,
                                text: 'Nearby',
                                searchType: 'nearby',
                              }));
                            }}
                            className="flex items-center gap-2 text-gray-400"
                          >
                            <img
                              src={locationPin}
                              alt="location-pin"
                              className="w-[20px] h-[20px]"
                            />
                            <span>Nearby</span>
                          </button>
                        )}
                        {services.length > 0 && (
                          <div className="space-y-3">
                            {services.map((item) => {
                              return (
                                <button
                                  onClick={() => {
                                    setShowCitySearch(
                                      (prevState) => !prevState
                                    );
                                    setTypeInput((prevState) => ({
                                      ...prevState,
                                      text: item.name,
                                      searchType: 'service',
                                    }));
                                  }}
                                  className="flex items-center gap-3"
                                >
                                  <img
                                    className="w-[30px] h-[30px] rounded-[3px]"
                                    src={buildUrlToSize(
                                      item.thumbnail,
                                      PhotoSize.Large
                                    )}
                                    alt="service-search"
                                  />
                                  <span>{item.name}</span>
                                </button>
                              );
                            })}
                          </div>
                        )}
                        {tags.length > 0 && (
                          <div className="space-y-3">
                            {tags.map((item) => {
                              return (
                                <button
                                  onClick={() => {
                                    setShowCitySearch(
                                      (prevState) => !prevState
                                    );
                                    setTypeInput((prevState) => ({
                                      ...prevState,
                                      text: item.name,
                                      searchType: 'tag',
                                    }));
                                  }}
                                  className="flex items-center gap-3"
                                >
                                  <img
                                    className="w-[30px] h-[30px] rounded-[3px]"
                                    src={tagImage}
                                    alt="service-search"
                                  />

                                  <span>{item.name}</span>
                                </button>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="border-l-2 border-gray-300 h-[30px]"></div>
                  <div
                    ref={dateRangeRef}
                    className="relative flex-auto w-[30%] rounded-[60px] hover:shadow-[0_0_10px_0_rgba(0,0,0,.25)] hover:bg-gray-500"
                  >
                    <input
                      onClick={() =>
                        setShowDatePicker((prevState) => !prevState)
                      }
                      className="rounded-[60px] p-[10px_15px] focus:outline-none w-full text-gray-400"
                      placeholder="when?"
                      value={
                        dateSelect
                          ? `${format(date[0].startDate, 'LLL-dd')} to ${format(
                              date[0].endDate,
                              'LLL-dd'
                            )}`
                          : 'select-dates'
                      }
                    />
                    {showDatePicker && (
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => {
                          setDateSelect(true);
                          setDate([item.selection]);
                        }}
                        moveRangeOnFirstSelection={false}
                        className="absolute top-[50px] left-0 z-40 rounded-xl shadow-xl"
                        ranges={date}
                      />
                    )}
                    <div className="absolute bg-jrnyfy-themeColor rounded-[50%] p-[5px] top-0 bottom-0 m-auto right-3  w-[30px] h-[30px]">
                      <button onClick={() => onSearchButtonClick()}>
                        <img
                          src={magnifyingGlass}
                          className="w-full"
                          alt="magnifying-glass"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="max-[767px]:hidden max-[889px]:w-full  md:w-[20%] flex justify-end md:max-[1095px]:flex-wrap space-x-2 md:space-x-4 min-[3000px]:justify-start">
              <div className="relative md:max-[1095px]:mb-[5px]">
                <p className="border-2 border-solid border-jrnyfy-border p-[5px_7px] md:p-[5px_7px] rounded-[30px] text-jrnyfy-textprimary text-[15px] md:text-[15px] font-bold max-[525px]:text-[14px] md:max-[1095px]:text-[12px] md:max-[1095px]:font-[600] text-center cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap">
                  {authinfo?.user?.verified === 1 ? (
                    <div className="flex items-center gap-1">
                      <button className="flex gap-2" onClick={loginControl}>
                        <div>
                          <Thumbnail
                            nameIconWordCount={1}
                            ShowName={false}
                            btnFollow={false}
                            data={authinfo?.user}
                            PhotoSize={PhotoSize.Small}
                            thumbnailContainerStyle={'!w-[25px] !h-[25px] !text-[14px]'}
                          />
                        </div>
                        {authinfo?.user?.name.slice(0, 5)}...
                      </button>

                      <button
                        onClick={showUserMenuHandle}
                        className="space-y-1"
                      >
                        <span className="block w-5 h-1 bg-black rounded-lg"></span>
                        <span className="block w-5 h-1 bg-black rounded-lg"></span>
                        <span className="block w-5 h-1 bg-black rounded-lg"></span>
                        {notificationCount + messageCount > 0 && (
                          <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-jrnyfy-themeColor border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                            {getNumberFormat(notificationCount + messageCount)}
                          </div>
                        )}
                      </button>

                      {showUserMenu && (
                        <div
                          ref={settingRef}
                          className="absolute top-10 space-y-3 p-5 bg-white rounded-2xl flex flex-col shadow-[0px_20px_20px_10px_#00000024]"
                        >
                          <Link
                            onClick={showUserMenuHandle}
                            to={'/messenger'}
                            className="text-start relative w-fit"
                          >
                            <span> messages</span>
                            {messageCount > 0 && (
                              <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-jrnyfy-themeColor border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                                {getNumberFormat(messageCount)}
                              </div>
                            )}
                          </Link>
                          <Link
                            onClick={showUserMenuHandle}
                            to={'notification'}
                            className="text-start w-fit relative"
                          >
                            <span>notification</span>
                            {notificationCount > 0 && (
                              <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-jrnyfy-themeColor border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                                {getNumberFormat(notificationCount)}
                              </div>
                            )}
                          </Link>
                          <Link
                            onClick={showUserMenuHandle}
                            to={'/setting'}
                            className="text-start"
                          >
                            settings
                          </Link>
                          <Link
                            onClick={showUserMenuHandle}
                            to={'/vendor'}
                            className="text-start"
                          >
                            switch to vendor
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : (
                    <button onClick={loginControl}>login</button>
                  )}
                </p>
                {login && (
                  <Login
                    redirectTo={redirectTo}
                    postValue={postValue}
                    setPostValue={setPostValue}
                    setPlaceArr={setPlaceArr}
                    setUserArr={setUserArr}
                    placeArr={placeArr}
                    userArr={userArr}
                    mState={login}
                    setMState={loginControl}
                  />
                )}
              </div>
              <div>
                {authinfo?.user?.role == 50 ? (
                  <p
                    onClick={() => {
                      if (authinfo.token) {
                        dispatch(
                          switchToUser({
                            data: {
                              callback: (data) => {
                                if (data?.user?.lastRole == 50) {
                                  navigate('/vendor');
                                } else if (data?.user?.lastRole == 100) {
                                  navigate(getUserNavOption(data?.user));
                                }
                              },
                            },
                            token: authinfo.token,
                          })
                        );
                      }
                    }}
                    className="cursor-pointer border-2 border-solid border-jrnyfy-border p-[5px_7px] md:p-[5px_10px] rounded-[30px] text-jrnyfy-textprimary text-[15px] md:text-[15px] font-bold max-[525px]:text-[14px] md:max-[1095px]:text-[12px] md:max-[1095px]:font-[600] text-center"
                  >
                    {authinfo.user.lastRole == 50
                      ? 'Switch to User Profile'
                      : 'Switch to Vendor'}
                  </p>
                ) : (
                  <p
                    onClick={() => {
                      navigate('/partner');
                    }}
                    className="cursor-pointer border-2 border-solid border-jrnyfy-border p-[5px_7px] md:p-[5px_10px] rounded-[30px] text-jrnyfy-textprimary text-[15px] md:text-[15px] font-bold max-[525px]:text-[14px] md:max-[1095px]:text-[12px] md:max-[1095px]:font-[600] text-center"
                  >
                    Partner with us
                  </p>
                )}
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default Header;
