//Nahid ---- static variables holds the error messages
export const FORGET_ERROR_PASSWORD = `This email address doesn't exist. if you don't have an account. create one. it's free!`;
export const EMAIL_INPUT_ERROR = `Please input your email`;
export const EMAIL_VALIDATION_ERROR = `Invalid email format`;
export const EMAIL_VALIDATION_REGEX = `/\S+@\S+\.\S+/`;
export const PASSWORD_INPUT_ERROR = `Please input your password`;
export const PASSWORD_VALIDATION_ERROR = `Password length must be between 4 to 18 character long`;
export const OLD_PASSWORD_REQUIRE_ERROR = `Old password can not be emtpy`;
export const NEW_PASSWORD_REQUIRE_ERROR = `New password can not be emtpy`;
export const CONFIRM_PASSWORD_REQUIRE_ERROR = `Confirm password can not be emtpy`;
export const NEW_CONFIRM_PASS_NOT_MATCHED = `New Password and confirm password dose not matched`;
export const OLD_CONFIRM_PASS_CAN_NOT_SAME = `Your new password cannot be the same as your old password`;
export const NAME_INPUT_ERROR = `Please input your fullname`;
export const NAME_VALIDATION_ERROR = `Fullname must include firstname and lastname e.g."Darth Vader"`;
export const DOB_INPUT_ERROR = `Please input your date of birth`;
export const DOB_VALIDATION_ERROR = `Age must be more than 7 years`;
