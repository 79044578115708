import React, { useState } from "react";
import FeedSVG from "../../assets/images/Button_NavBar_Trending.svg";
import ProfileBtn from "../../assets/images/Button_NavBar_Profile.svg";
import MessageBtn from "../../assets/images/Button_NavBar_Messages.svg";
import NotificationBtn from "../../assets/images/Button_NavBar_Notification.svg";
import PlusButton_jrny from "../../assets/images/button_nav_start_a_jrny.svg";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import Login from "../Login/Login";
import JrnyCreate from "../jrnyCreate/jrnyCreate";
import { getUserNavOption } from "../../utils/get-nav-option";
import { getNumberFormat } from "../utils/static/global_functions";

const BottomNavMenu = () => {
  const { auth, notificationCount, messageCount } = useSelector(authSelector);
  const navigate = useNavigate();
  const location = useLocation();
  let [userArr, setUserArr] = useState([]);
  let [placeArr, setPlaceArr] = useState([]);
  let [postValue, setPostValue] = useState({
    vissible: true,
    value: undefined,
  });
  let [jrnyModal, setJrnyModal] = useState(false);
  let jrnyModalControl = () => {
    setJrnyModal((state) => !state);
  };
  let [login, setLogin] = useState(false);
  let loginControl = () => {
    setLogin((state) => !state);
  };
  let loginRequireControl = () => {
    setLogin(true);
  };

  const isHideBottomLogin =
    location.pathname == "/privacy-policy" || location.pathname == "/terms"
      ? true
      : false;

  return (
    <>
      {auth.token ? (
        <div className="min-[767px]:hidden fixed border-top bottom-0 w-full bg-white z-40 p-5 shadow-[0px_0px_20px_18px_#00000024]">
          <ul className="flex justify-between items-end">
            <li>
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  navigate(getUserNavOption(auth.user));
                }}
              >
                <img
                  className="w-[40px] h-[40px]"
                  src={ProfileBtn}
                  alt={"menu buttons"}
                />
              </NavLink>
            </li>
            <li>
              <NavLink to="/">
                <img
                  className="w-[40px] h-[40px]"
                  src={FeedSVG}
                  alt={"menu buttons"}
                />
              </NavLink>
            </li>

            <li className="w-[70px]">
              <button onClick={jrnyModalControl}>
                <img
                  className="w-[70px] h-[70px] absolute bottom-0 left-0 right-0 -top-5 m-auto"
                  src={PlusButton_jrny}
                  alt={"menu buttons"}
                />
              </button>
            </li>
            <li className="relative">
              <NavLink to="/notification">
                <img
                  className="w-[40px] h-[40px]"
                  src={NotificationBtn}
                  alt={"menu buttons"}
                />
                {notificationCount > 0 && (
                  <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-jrnyfy-themeColor border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                    {getNumberFormat(notificationCount)}
                  </div>
                )}
              </NavLink>
            </li>
            <li className="relative">
              <NavLink to="/messenger">
                <img
                  className="w-[40px] h-[40px]"
                  src={MessageBtn}
                  alt={"menu buttons"}
                />
                {messageCount > 0 && (
                  <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-jrnyfy-themeColor border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                    {getNumberFormat(messageCount)}
                  </div>
                )}
              </NavLink>
            </li>
          </ul>
          {jrnyModal && (
            <JrnyCreate
              setPostValue={setPostValue}
              postValue={postValue}
              setPlaceArr={setPlaceArr}
              placeArr={placeArr}
              setUserArr={setUserArr}
              userArr={userArr}
              jrnyModal={jrnyModal}
              jrnyModalControl={jrnyModalControl}
              loginRequireControl={loginRequireControl}
            />
          )}
        </div>
      ) : (
        !isHideBottomLogin && (
          <div className="min-[767px]:hidden fixed border-top bottom-0 w-full bg-white z-40 p-5 shadow-[0px_0px_20px_18px_#00000024] rounded-t-[20px] space-y-3">
            <p className="text-center text-[14px] text-jrnyfy-textprimary">
              Collaborate, Plan, and Embark on Life's Journeys
            </p>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={loginControl}
                className="z-20 cursor-pointer border bg-white border-jrnyfy-textprimary hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro"
              >
                login
              </button>
              <button
                type="button"
                className="z-20 cursor-pointer border bg-white border-jrnyfy-textprimary hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro"
              >
                use app
              </button>
              <button
                onClick={() => {
                  navigate("/partner");
                }}
                type="button"
                className="z-20 cursor-pointer border bg-white border-jrnyfy-textprimary hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro"
              >
                become a guide
              </button>
            </div>
            {login && (
              <Login
                redirectTo={"none"}
                mState={login}
                setMState={loginControl}
              />
            )}
          </div>
        )
      )}
    </>
  );
};

export default BottomNavMenu;
