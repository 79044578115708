import React, { useEffect, useRef, useState } from 'react';
import Thumbnail from '../thumbnails/Thumbnail';
import { buildUrlToSize } from '../../utils/build-image-url';
import {
  MessageType,
  MessageUpdateRequestStatus,
  PhotoSize,
} from '../utils/subjects';
import Option from '../../assets/images/ellipsis-vertical-solid.svg';
import Icons from '../Icons/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMessage } from '../../redux/slices/mobile/messagingSlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import ImageGalleryRenderer from '../ImageGallery/ImageGalleryRenderer';

const ReceivedMessageLayout = ({ setMobileMessageOptions, item, chat }) => {
  const [messageOptions, setMessageOptions] = useState();
  const { auth } = useSelector(authSelector);
  const [owner, setOwner] = useState();
  const messageOptionRef = useRef();
  const dispatch = useDispatch();
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);
  const [mobileOptions, setMobileOptions] = useState();
  const pressTimeRef = useRef();
  const mobileOptionsRef = useRef();

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = 'hidden';
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = 'scroll';
  };

  useEffect(() => {
    if (chat) {
      const owner = chat.members.find((member) => member.id == item.owner.id);
      setOwner(owner);
    }
  }, [chat]);

  const messageOptionsHandle = () => {
    setMessageOptions((prevState) => !prevState);
  };

  useEffect(() => {
    const hideModalOutSideClickHandle = (e) => {
      if (!messageOptionRef?.current?.contains(e.target)) {
        setMessageOptions(false);
      }
      if (!mobileOptionsRef?.current?.contains(e.target)) {
        setMobileOptions(false);
      }
    };
    document.addEventListener('mousedown', hideModalOutSideClickHandle);
  });

  const removeMessage = () => {
    setMessageOptions(false);
    dispatch(
      deleteMessage({
        data: {
          status: MessageUpdateRequestStatus.Deleted,
          sk: item.sk,
          createdAt: item.createdAt,
        },
        id: item.id,
        token: auth.token,
      })
    );
  };

  const handleOnMouseUp = () => {
    console.log('mouseUp');
    clearTimeout(pressTimeRef.current);
  };
  const handleOnMouseDown = () => {
    console.log('mouseDown');
    startPress();
  };
  const handleOnTouchStart = () => {
    console.log('TouchUp');
    startPress();
  };
  const handleOnTouchEnd = () => {
    console.log('TouchDown');
    clearTimeout(pressTimeRef.current);
  };

  const startPress = () => {
    console.log('Loog PressCalled');
    pressTimeRef.current = setTimeout(() => {
      console.log('Loog Press');
      setMobileOptions(true);
    }, 500);
  };

  return (
    <div key={item?.id} className='space-y-3'>
      <div className='group flex items-center justify-end gap-3'>
        <div className=' relative'>
          <div
            className={`${
              messageOptions ? 'visible' : 'invisible'
            } group-hover:visible`}
          >
            <Icons
              icon={Option}
              alt='Option'
              width='10px'
              iconClick={messageOptionsHandle}
            />
          </div>
          {messageOptions && (
            <div
              ref={messageOptionRef}
              className='p-2 w-[150px] absolute bottom-10 left-0 flex items-start flex-col bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg'
            >
              <button
                className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'
                onClick={removeMessage}
              >
                Remove
              </button>
              {/* <button className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'>
                Forward
              </button> */}
              {/* <button className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'>
                Edit
              </button> */}
              <div class='w-0 h-0 z-0 absolute -bottom-[19px] left-[18px] border-t-[20px] border-t-transparent border-l-[30px] border-l-white border-b-[20px] border-b-transparent'></div>
            </div>
          )}
        </div>
        {item.type == MessageType.Text && (
          <button
            onmouseup={handleOnMouseUp}
            onmousedown={handleOnMouseDown}
            onTouchStart={handleOnTouchStart}
            onTouchEnd={handleOnTouchEnd}
            className='text-[14px]  bg-jrnyfy-colorLightGray p-[8px_10px] rounded-[18px] select-none'
          >
            {item.message}
          </button>
        )}
        {mobileOptions && (
          <div
            ref={mobileOptionsRef}
            className='absolute bottom-0 left-0 right-0 m-auto rounded-t-3xl bg-white z-20 w-full p-5 border space-y-3 max-w-[600px]'
          >
            <button
              className='w-full text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md z-40'
              onClick={removeMessage}
            >
              Remove
            </button>
            {/* <button className='w-full z-10 text-start hover:bg-jrnyfy-colorLightGray p-[3px_10px] rounded-md'>
                Forward
              </button> */}
          </div>
        )}
        {item?.photos?.length > 0 && (
          <div
            onmouseup={handleOnMouseUp}
            onmousedown={handleOnMouseDown}
            onTouchStart={handleOnTouchStart}
            onTouchEnd={handleOnTouchEnd}
            className='flex flex-wrap gap-3 max-[550px]:gap-1 select-none justify-end'
          >
            {item.type == MessageType.Photo &&
              item.photos.map((photo, index) => {
                return (
                  <img
                    onClick={() => {
                      setGalleryCurrentIndex(index);
                      openImageGallery();
                    }}
                    src={buildUrlToSize(photo, PhotoSize.Medium)}
                    alt='chat-picture'
                    className='w-[120px] h-[120px] max-[550px]:w-[80px] max-[550px]:h-[80px]  rounded-xl object-cover select-none'
                  />
                );
              })}
          </div>
        )}

        <Thumbnail btnFollow={false} data={owner} PhotoSize={PhotoSize.Small} />
      </div>
      {item?.nextDate && (
        <p className='text-[12px] text-jrnyfy-textprimary text-center'>
          {item?.nextDate}
        </p>
      )}
      {showImageGallery && (
        <ImageGalleryRenderer
          closeImageGallery={closeImageGallery}
          galleryCurrentIndex={galleryCurrentIndex}
          setGalleryCurrentIndex={setGalleryCurrentIndex}
          photos={item?.photos}
        />
      )}
    </div>
  );
};

export default ReceivedMessageLayout;
