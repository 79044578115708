import React, { useEffect, useRef, useState } from 'react'
import playButton from '../../assets/icons/play-button.svg'
import { buildUrlToSize, buildVideoUrl } from '../../res/commonFunctions'
import { PhotoSize } from '../utils/subjects'

export default function VideoPlayer({
    containerStyle,
    mediaStyle,
    item,
    autoPlay,
    mute,
    loop,
    control,
    callback
}) {
    const [videoControl, setVideoControl] = useState(autoPlay)
    const videoRef = useRef(null);
    useEffect(() => {
        if (autoPlay) {
            if (videoRef.current) {
                videoRef.current.play();
            }
        }
    }, [])

    return (
        <div onClick={callback} className={`${containerStyle} h-full w-full relative`}>
            <video ref={videoRef} className={`w-full h-full rounded-[12px] ${mediaStyle}`} controls={videoControl}
                poster={buildUrlToSize(item, PhotoSize.Large)}
                muted
            >
                <source src={buildVideoUrl(item)} />
            </video>
            {!autoPlay && (<div className='absolute top-0 flex flex-col justify-center items-center w-full h-full bg-[rgba(0,0,0,0.5)] rounded-[12px]'>
                <img src={playButton} className='w-[50px] h-[50px]' alt='play button' />
            </div>)}
        </div>
    )
}
