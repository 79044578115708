import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import { authSelector, getUsers } from '../../redux/slices/mobile/authSlice';
import {
  followRequest,
  profileSelector,
  unfollowRequest,
} from '../../redux/slices/mobile/profileSlice';
import Thumbnail from '../thumbnails/Thumbnail';
import { onScrollEndTrigger } from '../utils/on-scroll-end-trigger';
import { PhotoSize, YesNo } from '../utils/subjects';
import { onTypeChange } from '../utils/typing-change';

const UserListPopup = ({
  toggle,
  popupStatus,
  filter,
  filterId,
  showAddBtn,
}) => {
  const dispatch = useDispatch();
  const { auth, users } = useSelector(authSelector);
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  const itemPerPage = 10;
  const [searchText, setSearchText] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [keepScrolling, setKeepScrolling] = useState(YesNo.Yes);
  const [usersList, setUsersList] = useState([]);
  const inputRef = useRef();

  // const getDataLength = () => {
  //   return usersList.length;
  // };
  // const isKeepScrolling = () => {
  //   return keepScrolling;
  // };

  // onScrollEndTrigger(setCurrentPage, isKeepScrolling, getDataLength);

  useEffect(() => {
    dispatch(
      getUsers({
        data: {
          from: currentPage,
          size: itemPerPage,
          value: searchText && searchText.length > 0 ? searchText : undefined,
          filter,
          filterId,
        },
        token: auth?.token,
        callback: (results) => {
          setUsersList([...usersList, ...results]);
        },
      })
    );
  }, [searchText, currentPage]);
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);
  return (
    <Popup
      open={popupStatus}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='relative bg-white h-[500px] flex flex-col items-stretch content-stretch w-[500px] rounded-[20px] p-10 max-[500px]:p-5  max-[500px]:h-full  max-[500px]:rounded-none'>
        {/* ---------cross mark---------- */}

        <div className='flex items-center gap-3'>
          <img
            src={Cross}
            alt='cross'
            className='cursor-pointer w-[20px] '
            onClick={toggle}
          />
          <input
            type='text'
            ref={inputRef}
            placeholder='find someone'
            className='w-full focus:outline-none border border-solid border-jrnyfy-textprimary rounded-[50px] p-[10px_10px] text-jrnyfy-textprimary'
            value={typeInput.value}
            onChange={onTypeChange(typeInput, setTypeInput, (value) => {
              setCurrentPage(0);
              setUsersList([]);
              setSearchText(value);
            })}
          />
        </div>
        {usersList && usersList.length > 0 && (
          <div className='max-w-[545px] grow overflow-y-scroll'>
            <div className='my-6 space-y-2'>
              {usersList.map((val, ind) => (
                <div className='flex justify-between items-center' key={ind}>
                  <Thumbnail
                    showName={true}
                    data={val}
                    PhotoSize={PhotoSize.Small}
                  />
                  <div>
                    {showAddBtn && showAddBtn == true && (
                      <button className='p-[5px_15px] font-SourceSansPro rounded-[50px] font-semibold border-jrnyfy-textprimary border border-solid'>
                        add
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {users.length >= 10 && (
              <div className='flex justify-center items-center'>
                <button
                  onClick={() => {
                    setCurrentPage((prevState) => prevState + 10);
                  }}
                  type='button'
                  className='cursor-pointer border border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor  p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro'
                >
                  show more
                </button>
              </div>
            )}
          </div>
        )}

        {/* {data.map((val, ind) => (
          <div key={ind} className="flex items-center space-x-2 mb-[15px]">
            <div className="w-[30px] h-[30px] text-center text-white rounded-[50%] bg-jrnyfy-themeColor">
              {val.name.slice(0, 1)}
            </div>
            <div>
              <p className="leading-[10px] text-[15px] font-semibold">
                {val.name}
              </p>

              {followingList.some((fol) => fol.id === val.id) ? (
                <button
                  onClick={() => {}}
                  className="13px text-jrnyfy-themeColor"
                >
                  following
                </button>
              ) : (
                <button
                  onClick={() => {
                    followControl(val.id, val.sk);
                    setFollowState(true);
                  }}
                  className="13px text-jrnyfy-textprimary"
                >
                  {followState ? 'follow request sent' : 'follow'}
                </button>
              )}
            </div>
          </div>
        ))} */}
      </div>
    </Popup>
  );
};

export default UserListPopup;
