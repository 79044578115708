import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { commentParser, idsCommentParser } from '../../redux/slices/mobile/elasticParser';
import { getTimelineItemsDetails } from '../../redux/slices/mobile/feedSlice';
import { YesNo } from '../utils/subjects';
import FeedCard from './FeedCard';
import Comments from '../Comments/Comments';
import Spinner from '../spinner/spinner';

export default function TimelineItemDetails({ item, auth }) {
  console.log('CommentItem', item)
  const [comment, setComment] = useState('');
  const dispatch = useDispatch()
  const galleryModal = useRef();
  const [itemDetails, setItemDetails] = useState()
  const [commentCount, setCommentCount] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(true);
  const [commentLoading, setCommentLoading] = useState(false)
  const [newComment, setNewComment] = useState({});
  const [isEnd, setIsEnd] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [commentsList, setCommentsList] = useState([]);
  const [replyDetails, setReplyDetails] = useState({ comment: {}, parent: [] });
  const [replySubDetails, setReplySubDetails] = useState({});
  const commentInputRef = useRef();
  const commentRef = useRef(null)
  const [rerender, setrerender] = useState(false)


  const getItemsDetails = (ids) => {
    dispatch(getTimelineItemsDetails({
      data: {
        ...item.data.doc,
        cmtIds: ids,
        withCmt: YesNo.Yes
      },
      token: auth.token,
      callback: async (res) => {
        console.log('itemDetails', res)
        setItemDetails(res)
        let comments = []
        if (ids) {
          comments = await idsCommentParser(res.comments)
        } else {
          comments = await commentParser(res.comments)
        }
        console.log('comments', comments)
        setCommentsList(comments);
        // if(ids){
        //   setTimeout(() => {
        //     if (comments[0]?.children[0]?.children[0]) {
        //       comments[0].children[0].children[0].isFocus = false
        //     } else if (comments[0]?.children[0]) {
        //       comments[0].children[0].isFocus = false
        //     }else{
        //       comments[0].isFocus = false
        //     }
        //     console.log("commetingnndkldjskld",comments)
        //     setCommentsList(comments)
        //     setrerender(prevState => !prevState)
        //   }, 5000);
        // }
        setItemLoading(false)
      }
    }))
  }

  useEffect(() => {
    if (item?.data?.cmt) {
      setCommentLoading(true);
      let ids = []
      ids.push(item.data.cmt.id)
      if (item.data.comment) {
        ids.push(item.data.comment.id)
      }
      if (item.data.subComment) {
        ids.push(item.data.subComment.id)
      }
      getItemsDetails(ids)
    } else {
      getItemsDetails()
    }
  }, [])

  return (
    <div className='flex flex-col flex-1 h-full'>
      {itemLoading ? 
      (
        <div className='flex justify-center items-center h-full'>
          <Spinner />
        </div>
      ):(
      <Comments
        ref={commentRef}
        comments={commentsList}
        commentItem={itemDetails}
        content={
          <FeedCard
            data={itemDetails}
            key={0} />
        }
      />
      )}
    </div>
  )
}
