import { buildUrlToSize } from "../../utils/build-image-url";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import { PhotoSize, YesNo } from "../utils/subjects";
import Moment from "react-moment";
import Thumbnail from "../thumbnails/Thumbnail";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import Icons from "../Icons/Icons";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";
import { useContext, useState } from "react";
import { timelineDataContext } from "../jrnyTimline/JrnyTimline";

const BadgeAdded = ({ isEditable, index, data }) => {
  const user = data?.feed ? data?.feed?.user : data?.user;
  let avater = buildUrlToSize(data?.feed?.user?.thumbnail, PhotoSize.Large);
  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();
  let altTitle = data?.feed?.user?.name.slice(0, 1);
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  return (
    <>
      <div className="flex justify-between items-start">
        <div className="relative">
          <div className="absolute top-0 left-[-24px]">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>
          <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold hover:underline">
            Jacob S. added Louvre Museum to journey: See the magic of Paris in
            one day
          </p>

          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {user.name} created{" "}
            <Moment fromNow>{data?.feed?.createdAt}</Moment>
          </p>
        </div>
        {!data?.feed?.user && isEditable == true && (
          <div className="ml-[10px] md:ml-[30px]">
            <Icons
              iconClick={() => deleteModalHandle(true)}
              icon={Option}
              alt="Option"
              width="25px"
            />
          </div>
        )}
      </div>
      {deleteModal && (
        <TimelineOptionsModal
          index={index}
          data={data}
          actionStates={actionStates}
          deleteModal={deleteModal}
          deleteModalHandle={deleteModalHandle}
        />
      )}
      <div className="flex ml-[20px] max-w-[425px] mt-4">
        <img
          src="https://nomadparadise.com/wp-content/uploads/2021/04/bangladesh-places-01-1024x683.jpg"
          alt="Place"
          className=" w-[115px] h-[115px] md:w-[180px] md:h-[180px] rounded-[50%] object-cover"
        />
        <div className="pl-[15px] md:pl-[25px]">
          <h4 className="text-[20px] font-medium md:text-[25px] md:font-semibold pb-[5px]">
            Louvre Museum
          </h4>
          <p className="leading-[20px] text-[12px] md:text-[16px]">
            The Louvre, or the Louvre Museum, is the world's most-visited
            museum, and an historic landmark in Paris, France. It is the home of
            some of the best-known works of art,
          </p>
        </div>
      </div>
    </>
  );
};

export default BadgeAdded;
