import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import RenderComment from './RenderComment';
import { Facebook } from 'react-content-loader';
import { createComment, deleteComment, getJourneyContent } from '../../redux/slices/mobile/activitySlice';
import { CommentParentType, ContentFilter, LikeOrCommentType, PhotoSize, SortElastic, UserActionComment } from '../utils/subjects';
import { v4 as uuidv4 } from 'uuid';
import { commentParser, idsCommentParser } from '../../redux/slices/mobile/elasticParser';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import Thumbnail from '../thumbnails/Thumbnail';
import { useNavigate } from 'react-router-dom';

const Comments = forwardRef(({
    comments,
    commentItem,
    content
}, ref) => {
    const { auth } = useSelector(authSelector)
    const [loading, setLoading] = useState(false)
    const [comment, setComment] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [commentCurrentPage, setCommentCurrentPage] = useState(0);
    const [commentsList, setCommentsList] = useState(comments);
    const [replyDetails, setReplyDetails] = useState({ comment: {}, parent: [] });
    const [commentLoading, setCommentLoading] = useState(false)
    const commentInputRef = useRef();
    const dispatch = useDispatch()
    const [isCommentEnd, setIsCommentEnd] = useState(false)
    const [rerender, setrerender] = useState(false)
    const navigate = useNavigate();

    useImperativeHandle(ref, () => {
        return {
            getComments,
        }
    })

    useEffect(() => {
        setCommentsList(comments)
    }, [comments])



    const createNewComment = () => {
        return {
            cmtlike: undefined,
            description: comment,
            following: undefined,
            likes: 0,
            id: uuidv4(),
            replyCount: 0,
            status: 0,
            user: auth.user,
            children: []
        }
    }

    const addReply = (comment, parent) => {
        console.log('addreply', comment)
        setReplyDetails({ comment, parent })
        commentInputRef.current.focus();
    }

    let createNestedArray = (tempCommentList, newComment, parentId, update = false) => {
        if (parentId) {
            for (let i = 0; i < tempCommentList.length; i++) {
                console.log("Templist", tempCommentList[i], parentId)
                if (tempCommentList[i].id == parentId) {
                    console.log('ValueoF i', update)
                    if (update) {
                        tempCommentList[i].children[0] = newComment
                        setrerender(prevState => !prevState)
                    } else {
                        tempCommentList[i].children.unshift(newComment)
                    }
                }
                console.log('TemplistA', tempCommentList)
            }
            for (let i = 0; i < tempCommentList.length; i++) {
                createNestedArray(tempCommentList[i].children, newComment, parentId, update)
            }
        } else {
            setCommentsList([
                newComment,
                ...commentsList,
            ]);
        }
    }


    const addComment = (comment, parentId, parent) => {
        console.log('CrateComment')
        setReplyDetails({ comment: {}, parent: [] })
        setComment('');
        let newComment = createNewComment()
        let tempCommentList = [...commentsList];
        if (comment) {
            createNestedArray(tempCommentList, newComment, parentId)
            let data = {
                description: comment,
                createdAt: commentItem.createdAt,
                sk: commentItem.sk,
                timelineId: commentItem?.timelineId,
                galleryId: commentItem.id,
                type: LikeOrCommentType.Gallery
            };

            if (parent.length > 0) {
                data['comment'] = {
                    id: parent[0].id,
                    sk: parent[0].sk,
                };
            }
            if (parent.length > 1) {
                data['subComment'] = {
                    id: parent[1].id,
                    sk: parent[1].sk,
                };
            }

            dispatch(
                createComment({
                    data: data,
                    token: auth.token,
                    activityId: commentItem?.activityId,
                    user: auth.user,
                    callback: res => {
                        console.log('NewCOmmentfdfdfdfd', res)
                        let parentList = createNestedArray(tempCommentList, { ...res, children: [] }, parentId, true)
                        //setCommentsList(tempCommentList);
                        console.log('resCom', res);
                    },
                }),
            );
        }

    };

    const getComments = (page, item, ids) => {
        setLoading(true)
        console.log('getting comments', commentItem)
        dispatch(
            getJourneyContent({
                data: {
                    from: page,
                    size: 15,
                    ids: ids,
                    sortOrder: SortElastic.ASC,
                    filterId: item.id,
                    filter: ContentFilter.Comment,
                },
                callback: async (res) => {
                    console.log('commentsResponse', res);
                    let comments = []
                    if (ids) {
                        comments = await idsCommentParser(res)
                    } else {
                        comments = await commentParser(res)
                    }

                    console.log('comments', comments, page)

                    setCommentLoading(false);
                    setCommentCurrentPage(page + 10);
                    if (res?.length < 10) {
                        setIsCommentEnd(true);
                    }
                    if (page == 0) {
                        setCommentsList(comments);
                    } else {
                        console.log('IsMOreCOmment')
                        setCommentsList([...commentsList, ...comments]);
                    }
                    setLoading(false)
                },
                token: auth.token,
            }),
        );
    };

    // useEffect(() => {
    //     setLoading(true)
    //     console.log("items", commentItem)
    //     if (commentItem?.data?.cmt) {
    //         let ids = []
    //         ids.push(commentItem.data.cmt.id)
    //         if (commentItem.data.comment) {
    //             ids.push(commentItem.data.comment.id)
    //         }
    //         if (commentItem.data.subComment) {
    //             ids.push(commentItem.data.subComment.id)
    //         }
    //         getGalleryLists(0, ids)
    //     } else {
    //         getGalleryLists(0)
    //     }
    // }, [])

    const removeCommentFromTree = (tempCommentList, id, lavel, index) => {
        for (let i = 0; i < tempCommentList.length; i++) {
            console.log("DeletedCommentBefore", tempCommentList[i].id, id, lavel)
            if (tempCommentList[i].id == id) {
                console.log("DeletedComment", tempCommentList[i].children, id, lavel)
                let tempChildren = [...tempCommentList[i].children]
                console.log("DeleteIndex", i, tempChildren)
                tempChildren.splice(index, 1)
                tempCommentList[i].children = tempChildren;
                console.log('CHildred', tempCommentList)
            }
        }
        for (let i = 0; i < tempCommentList.length; i++) {
            removeCommentFromTree(tempCommentList[i].children, id, lavel, index)
        }
    }

    const deleteCommentItem = (comment, parent, lavel, index) => {
        let tempCommentList = [...commentsList];
        console.log('COmmentId', comment, comment.docId, tempCommentList, index)
        if (lavel == 0) {
            tempCommentList.splice(index, 1)
        } else {
            removeCommentFromTree(tempCommentList, comment.docId, lavel, index)
        }
        setCommentsList(tempCommentList)
        console.log('COmmentIdA', comment, comment.docId, tempCommentList,)
        let data = {
            docSk: comment.sk,
            description: comment.description,
            timelineId: commentItem.timelineId,
            id: commentItem.activityId,
            sk: commentItem.sk,
            createdAt: commentItem.createdAt,
            action: UserActionComment.Delete,
        };
        console.log('parentList', parent, commentItem, comment);
        if (parent?.length == 1) {
            data['parent'] = {
                id: commentItem.id,
                sk: commentItem.sk,
            };
            data['parentType'] = LikeOrCommentType.Gallery
        }
        if (parent?.length == 2) {
            data['parent'] = {
                id: parent[1].id,
                sk: parent[1].sk,
            };
            data['parentType'] = CommentParentType.Comment
            data['rootParent'] = {
                id: commentItem.id,
                sk: commentItem.sk,
            };
            data['rootParentType'] = LikeOrCommentType.Gallery
        }
        if (parent?.length == 3) {
            data['parent'] = {
                id: parent[2].id,
                sk: parent[2].sk,
            };
            data['parentType'] = CommentParentType.Comment
            data['rootParent'] = {
                id: commentItem.id,
                sk: commentItem.sk,
            };
            data['rootParentType'] = LikeOrCommentType.Gallery
        }
        if (parent?.length > 1) {
            data['comment'] = {
                id: parent[1].id,
                sk: parent[1].sk,
            };
        }
        if (parent?.length > 2) {
            data['subComment'] = {
                id: parent[2].id,
                sk: parent[2].sk,
            };
        }
        dispatch(
            deleteComment({
                data: data,
                id: comment.id,
                token: auth.token,
                user: auth.user,
                callback: () => {

                },
            }),
        );
    }

    const addRepliesToTree = (tempCommentList, comments, parentId) => {
        for (let i = 0; i < tempCommentList.length; i++) {
            if (tempCommentList[i].id == parentId) {
                console.log("getRepliesCommentB", tempCommentList[i], comments)
                tempCommentList[i].children.unshift(...comments)
                console.log("getRepliesCommentA", tempCommentList, comments)
                //setCommentsList(tempCommentList)
            }
        }
        for (let i = 0; i < tempCommentList.length; i++) {
            addRepliesToTree(tempCommentList[i].children, comments, parentId)
        }
    }
    const addReplies = (comments, parentId) => {
        console.log("COmmentsss", comments)
        let tempCommentList = [...commentsList];
        addRepliesToTree(tempCommentList, comments, parentId)
    }
    console.log('commentsList55', comments)
    return (
        <div className='flex flex-col flex-1 justify-between h-full'>
            <div className='flex flex-col flex-1 overflow-auto scrollbar-thin scrollbar-thin-webkit'>
                {content &&(<div>
                    {content}
                </div>)}
                {loading ? (<Facebook className="max-w-[545px]" viewBox="0 0 200 124" />) : (<div className='flex-1'>
                    {commentsList.map((item, index) => (<RenderComment
                        comment={item}
                        lavel={0}
                        index={index}
                        parent={[item]}
                        itemDetails={{
                            activity: {
                                id: commentItem?.activityId,
                                sk: commentItem?.sk
                            },
                            id: commentItem?.timelineId
                        }}
                        addComment={addComment}
                        deleteCommentItem={deleteCommentItem}
                        addReplies={addReplies}
                        navigate={navigate}
                        auth={auth}
                    />))}
                </div>)}
            </div>
            <div className="flex items-center space-x-2 mt-2">
                <div className="w-[40px] h-[40px] text-center text-white rounded-[50%] bg-jrnyfy-themeColor text-[25px]">
                    <Thumbnail
                        showName={false}
                        btnFollow={false}
                        data={auth.user}
                        PhotoSize={PhotoSize.Small}
                    />
                </div>
                <div className="w-full">
                    <input
                        type="text"
                        className="focus:outline-none border-b-[2px] border-solid border-jrnyfy-border w-full"
                        placeholder="add your comments here"
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                    />
                </div>
                <div>
                    <button
                        onClick={() => addComment(comment, '', [])}
                        className="bg-jrnyfy-themeColor p-[5px_8px] text-[15px] font-semibold rounded-[50px] text-white font-SourceSansPro"
                    >
                        comment
                    </button>
                </div>
            </div>
        </div>
    )
})

export default Comments