import React, { useCallback, useEffect, useState } from 'react';
import { onTypeChange } from '../utils/typing-change';
import Cross from '../../assets/images/delete_image.svg';
import { FeedCategoryFilter, FeedFilter } from '../utils/subjects';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, getUsers } from '../../redux/slices/mobile/authSlice';
import { feedSelector } from '../../redux/slices/mobile/feedSlice';
import { searchDebounce } from '../utils/static/global_functions';

const SearchBar = ({
  contents,
  setContents,
  setSearchedText,
  searchedText,
  tagSearchText,
  setTagSearchText,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { token },
  } = useSelector(authSelector);
  const { searchedTagList } = useSelector(feedSelector);
  const [isEnd, setIsEnd] = useState(false);
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  const [filter, setFilter] = useState(FeedFilter.Trending);
  const [currPage, setCurrPage] = useState(0);

  const [searchText, setSearchText] = useState(undefined);
  const [loadingState, setLoadingState] = useState(false);

  // const fetchData = () => {
  //   if (searchText) {
  //     console.log('Yes Called');
  //     dispatch(
  //       getFeeds({
  //         data: {
  //           from: currPage,
  //           value: searchText && searchText.length > 0 ? searchText : undefined,

  //           filter,
  //           categoryFilter: FeedCategoryFilter.Feed,
  //           size: 10,
  //         },
  //         token: token ? token : undefined,
  //       })
  //     ).then((res) => {
  //       if (res.payload.feeds.length < 10) {
  //         setIsEnd(true);
  //         dispatch(feedElementsArray(res.payload.feeds));
  //       }

  //       setLoadingState(false);
  //     });
  //   }
  // };

  useEffect(() => {
    // fetchData();
    // setLoadingState(true);
  }, [currPage, searchText]);

  useEffect(() => {
    // let tagSearchStrings = tagSearchText.map((item) => [item.name]);
    // setTypeInput({ ...typeInput, text: tagSearchStrings.toString() });
    // setSearchedText(tagSearchStrings.toString())
    setTypeInput({ ...typeInput, text: searchedText });
  }, [searchedText]);

  // useEffect(() => {
  //   setSearchedText(searchText);
  // }, [searchText]);

  const startSerach = (value) => {
    console.log("OnserachVal", value)
    setCurrPage(0);
    setContents([]);
    setIsEnd(false);
    setSearchedText(value);
  }

  const onSearchDebounce = useCallback(searchDebounce(startSerach, 1000), [])

  return (
    <form className='max-w-[545px] w-full  md:px-5'>
      <div className='relative w-full'>
        <input
          type='text'
          placeholder='search journeys, get inspired'
          className='bg-gray-100 w-full p-[10px_20px] rounded-[50px]'
          value={typeInput?.text}
          onChange={(e) => {
            setTypeInput(e.target.value)
            //setSearchText(e.target.value);
            onSearchDebounce(e.target.value)
          }}
        />
        {/* <button
              className="w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]"
              type="button"
            ></button> */}

        {(searchText || typeInput.text) && (
          <div className='absolute m-auto top-0 bottom-0 right-5 w-[25px] h-[25px] rounded-[50px]'>
            <img
              src={Cross}
              alt='cross'
              className='w-[25px] h-[25px]'
              onClick={() => {
                const value = '';
                setTypeInput((state) => ({ ...state, text: '' }));
                setSearchedText('')
              }}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchBar;
