import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authSelector, logOut } from '../../redux/slices/mobile/authSlice';
import PasswordChangeModal from './PasswordChangeModal';
import Seo from '../Seo/Seo';
import EmailChangeModal from './EmailChangeModal';
import backBtn from '../../assets/icons/back.png';
import BackButton from '../BackButton/BackButton';

const LoginSecuritySetting = () => {
  const { auth } = useSelector(authSelector);
  const [showPasswordChange, setShowPasswordChange] = useState();
  const [showEmailChangeModal, setShowEmailChangeModal] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const logout = () => {
    navigate('/');
    dispatch(logOut());
  };
  const showPasswordChangeHandle = (action) => {
    console.log('Pathname', action);
    if (action) {
      window.history.pushState(null, '', window.location.href);
    } else {
      // navigate(-1);
    }
    setShowPasswordChange(action);
  };
  const showEmailChangeModalHandle = (action) => {
    if (action) {
      window.history.pushState(null, '', window.location.href);
    } else {
      // navigate(-1);
    }
    setShowEmailChangeModal(action);
  };

  window.addEventListener('popstate', (e) => {
    console.log('PopState', e);
    if (showPasswordChange) {
      // navigate(null, { replace: true });
      showPasswordChangeHandle(false);
    } else if (showEmailChangeModal) {
      showEmailChangeModalHandle(false);
    }
  });
  return (
    <>
      <Seo
        title={` Login & Security | update password & secure your account`}
        description=''
        type='webapp'
        name='jrnyfy crop.'
        canonicalHref='/setting/login-security'
      />
      <div className='max-w-[660px] pb-[80px] mx-auto px-5 mt-3 space-y-10'>
        <div className='flex items-start gap-1'>
          <BackButton callback={() => navigate(-1)} />
          <div className='w-fit space-y-3'>
            <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit leading-3'>
              Login & Security
            </h4>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              update password & secure your account
            </p>
          </div>
        </div>
        <div className='border-b-2 border-jrnyfy-textprimary pb-5 space-y-3'>
          <div className='flex justify-between items-center'>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              {auth?.user?.email}
            </p>
            <button
              onClick={() => showEmailChangeModalHandle(true)}
              className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
            >
              Update
            </button>
          </div>
          <div className='flex justify-between items-center'>
            <p className='text-start text-jrnyfy-textprimary w-fit'>password</p>
            <button
              onClick={() => showPasswordChangeHandle(true)}
              className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
            >
              Update
            </button>
          </div>
        </div>
        <div className='flex justify-between'>
          <button
            className='bg-jrnyfy-border rounded-[50px] mt-[40px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary max-[600px]:w-full'
            onClick={logout}
          >
            Logout
          </button>
        </div>
        {showPasswordChange && (
          <PasswordChangeModal
            showPasswordChange={showPasswordChange}
            showPasswordChangeHandle={showPasswordChangeHandle}
          />
        )}
        {showEmailChangeModal && (
          <EmailChangeModal
            showEmailChangeModal={showEmailChangeModal}
            showEmailChangeModalHandle={showEmailChangeModalHandle}
          />
        )}
      </div>
    </>
  );
};

export default LoginSecuritySetting;
