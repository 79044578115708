import React, { useEffect, useState } from 'react';
import Login from '../Login/Login';
import { useLocation, useNavigate } from 'react-router-dom';

export const HorizontalTapView = ({ items, callback }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filterTags, setFilterTags] = useState(items);
  let [login, setLogin] = useState(false);
  let loginControl = () => {
    setLogin((state) => !state);
  };

  const handleActive = (item) => {
    setFilterTags(
      filterTags.map((tag) => {
        if (tag.name === item.name) {
          return { name: tag.name, value: tag.value, status: true };
        } else {
          return { name: tag.name, value: tag.value, status: false };
        }
      })
    );
  };

  console.log("Locationddddd",location)
  
  useEffect(()=>{
    if(location?.state){
      handleActive(location.state)
    }
  },[location.state])

  console.log('FilterTags', filterTags);
  return (
    <div className='flex space-x-4 mb-[20px]  mt-[10px] md:mt-[20px] leading-[10px] md:leading-[15px] overflow-x-auto py-3 scrollbar '>
      {filterTags.map((item, index) => (
 
          <div>
            <button
              key={item.name}
              onClick={() => {
                if (item.name == 'SUCCESS STORIES' && location.pathname!='/story') {
                  navigate('/story',{state:item});
                }else if(item.name == 'TRENDING' && location.pathname=='/story'){
                  navigate('/',{state:item});
                }else if(item.name=='MY CIRCLE' && location.pathname=='/story'){
                  navigate('/',{state:item});
                }
                 else {
                  callback(item.value);
                }
                handleActive(item);
              }}
              className={
                (item.status === true
                  ? 'bg-jrnyfy-themeColor'
                  : 'bg-gray-500') +
                ' p-[8px_15px] rounded-[50px] text-jrny whitespace-nowrap text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[15px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]'
              }
            >
              {item.name}
            </button>
          </div>
        
      ))}
      {login && <Login mState={login} setMState={loginControl} />}
    </div>
  );
};
