import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { addUserToGroup } from '../../redux/slices/mobile/messagingSlice';
import Thumbnail from '../thumbnails/Thumbnail';
import { PhotoSize } from '../utils/subjects';
import FunctionalButton from '../FunctionalButton/FunctionalButton';

const UserLIst = ({ selectedChat, val, ind, addNewUserToGroupModalHandle }) => {
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  const dispatch = useDispatch();
  console.log('selectedChatNewMemeber', selectedChat);

  const { auth } = useSelector(authSelector);
  const addNewMemberToGroup = (val) => {
    setActionStates({ ...actionStates, loading: true });
    dispatch(
      addUserToGroup({
        data: {
          chatId: selectedChat?.id,
          sk: selectedChat?.sk,
          createdAt: selectedChat?.createdAt,
          userId: val?.id,
          createdAtUser: val?.createdAt,
          skUser: val?.sk,
        },
        token: auth.token,
      })
    ).then((res) => {
      console.log('Response', res.meta.requestStatus);
      if (res.meta.requestStatus == 'fulfilled') {
        addNewUserToGroupModalHandle(false);
        setActionStates({ ...actionStates, loading: false, fulfilled: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
      if (res.meta.requestStatus == 'rejected') {
        setActionStates({ ...actionStates, loading: false, rejected: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };
  return (
    <div className='flex justify-between items-center' key={ind}>
      <Thumbnail showName={true} data={val} PhotoSize={PhotoSize.Small} />
      <div>
        <FunctionalButton
          actionStates={actionStates}
          callback={() => addNewMemberToGroup(val)}
          actionStatesTexts={{
            default: 'Add',
            loading: 'Adding',
            fulfilled: 'Added Successfully',
            rejected: 'Add Failed',
          }}
        />
      </div>
    </div>
  );
};

export default UserLIst;
