import { useEffect, useId, useRef, useState, useTransition } from 'react';

import Aside from '../Aside/Aside';
import { Facebook } from 'react-content-loader';
import Seo from '../Seo/Seo';
import CreateSuccessStory from '../FeedCard/CreateSuccessStory';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { BTN_CREATE_A_NEW_SERVICE } from '../../utils/static';
import ServiceCreateModal from './ServiceCreateModal';
import Myjrny from '../userProfile/myjrnycomp';
import {
  getAllProducts,
  productSelector,
} from '../../redux/slices/mobile/productSlice';

const VendorPage = () => {
  const { auth, userType } = useSelector(authSelector);
  const { products } = useSelector(productSelector);
  const [loadingState, setLoadingState] = useState(false);
  const [serviceCreateModal, setServiceCreateModal] = useState(false);
  const dispatch = useDispatch();
  const serviceCreateModalHandle = () => {
    setServiceCreateModal((prevState) => !prevState);
  };
  useEffect(() => {
    if (userType == 'vendor') {
      setLoadingState(true);
      dispatch(
        getAllProducts({
          data: { from: 0, size: 10, value: '' },
          token: auth.token,
        })
      );
    }
  }, []);
  useEffect(() => {
    setLoadingState(false);
  }, [products]);
  return (
    <>
      <Seo
        title='Success Stories'
        description=' Get ideas, tips and inspiration on how to accomplish journeys you always wanted to do'
        type='webapp'
        name='jrnyfy crop'
        canonicalHref='/vendor'
      />
      <main className='pt-4'>
        <div className='w-[90%] min-[1100px]:w-[75%] mx-auto flex justify-center min-[3000px]:w-1/2'>
          <aside className='hidden md:block md:w-[30%] mt-[-127px]'>
            <Aside />
          </aside>
          <div className='w-full max-w-[1045px] md:px-[20px]'>
            <div className='max-w-[545px] space-y-4'>
              <div>
                {auth?.token && (
                  <CreateSuccessStory
                    btnText={BTN_CREATE_A_NEW_SERVICE}
                    callback={serviceCreateModalHandle}
                  />
                )}
              </div>
              <div className='flex items-center'>
                <p className='text-jrnyfy-textprimary'>
                  In order to pushlish your services, you will need to upload
                  some documents your services will be only available to
                  customers once you have verified your page
                </p>
                <button className='bg-jrnyfy-border rounded-[50px] whitespace-nowrap p-[10px_20px] text-[12px] text-jrnyfy-textprimary'>
                  verify your page
                </button>
              </div>
              <div className='flex justify-between flex-wrap px-3 grid grid-cols-3 gap-4 pb-40'>
                {products.length > 0
                  ? products.map((val, ind) => <Myjrny data={val} key={ind} />)
                  : ''}
                {loadingState && (
                  <Facebook className='max-w-[545px]' viewBox='0 0 200 124' />
                )}
              </div>
            </div>
          </div>
        </div>
        <ServiceCreateModal
          serviceCreateModal={serviceCreateModal}
          setServiceCreateModal={setServiceCreateModal}
          serviceCreateModalHandle={serviceCreateModalHandle}
        />
      </main>
    </>
  );
};

export default VendorPage;
