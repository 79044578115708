import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import starFull from '../../assets/images/star-solid.svg';
import starHalf from '../../assets/images/star-half-stroke-regular.svg';
import starEmpty from '../../assets/images/star-emty.svg';

const CardComponents8 = ({ rating }) => {
  let starRating = Array.from({ length: 5 }, (_, ind) => {
    const halfNum = ind + 0.5;

    return (
      <div key={ind}>
        {rating >= ind + 1 ? (
          <img src={starFull} alt='star' className='w-[30px]' />
        ) : rating >= halfNum ? (
          <img src={starHalf} alt='star' className='w-[30px]' />
        ) : (
          <img src={starEmpty} alt='star' className='w-[30px]' />
        )}
      </div>
    );
  });

  return (
    <>
      <div className='relative'>
        <div className='w-[40px] h-[40px] rounded-[50%] absolute top-0 left-[-24px] bg-jrnyfy-themeColor text-white text-[25px] text-center'>
          {/* {avater === undefined ? altTitle : <img src={avater} alt={data?.feed?.user?.name.slice(0, 1)} className="w-[40px] h-[40px] rounded-[50%]" />} */}
        </div>
        <p className='pl-[30px] md:px-[30px] text-jrny font-semibold hover:underline'>
          Jacob S. added Louvre Museum to journey: See the magic of Paris in one
          day
        </p>
      </div>
      <div className='mt-4 ml-[20px] max-w-[425px]'>
        <div>
          <div className='flex mt-[30px] mb-[20px]'>{starRating}</div>
          <div className='mb-[20px]'>
            <p>
              Value for money the tour one day hop on hop off!! You had the tour
              twice with in 24 hours with stops. The bus was clean and the staff
              very kind and helpful.... learn more
            </p>
          </div>
          <div>
            <Swiper
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              style={{
                '--swiper-pagination-color': 'white',
                '--swiper-pagination-bullet-inactive-color': '#999999',
                '--swiper-pagination-bullet-inactive-opacity': '1',
              }}
            >
              <SwiperSlide>
                <img
                  src='https://cdn.cnn.com/cnnnext/dam/assets/220530082628-mona-lisa-lourve-france-cake-052922-super-169.jpg'
                  alt='Ticket'
                  className='rounded-t-[10px] h-[120px] md:h-[150px] w-full'
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src='https://static.foxnews.com/foxnews.com/content/uploads/2022/07/0282cc5d-mona-lisa-louvre.jpg'
                  alt='Ticket'
                  className='rounded-t-[10px] h-[120px] md:h-[150px] w-full'
                />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className='bg-jrnyfy-textprimary text-white rounded-b-[10px] px-[30px] pb-[25px] pt-[10px]'>
            <p className='leading-[15px] text-[12px] md:text-[16px]'>
              see the mona lisa in a private tour with Leonardo DaVinci expert
              guide
            </p>
          </div>
        </div>

        <div className='flex space-x-9 mt-[10px] md:mt-[20px]'>
          <button className='p-[8px_20px] md:p-[8px_30px] bg-jrnyfy-themeColor rounded-[50px] text-[12px] md:text-[18px] text-white font-bold md:font-semibold italic'>
            add this to your journey
          </button>
          <button className='p-[8px_20px] md:p-[8px_30px] bg-jrnyfy-themeColor rounded-[50px] text-[12px] md:text-[18px] text-white font-bold md:font-semibold italic'>
            book now
          </button>
        </div>
      </div>
    </>
  );
};

export default CardComponents8;
