//placeholders
export const PLACEHOLDER_TEXT_FIELD_NAME_CREATE_SUCCESS_STORY =
  'What was your journey about in one sentence?';
export const PLACEHOLDER_TEXT_STORY_DESCRIPTION =
  'share your success story. what did you learn on this journey?\nwhat are some tips or advice do you have for others who would like to embark on a similar journey?';

//buttons
export const BTN_ADD_MORE_DETAILS = 'add more details';
export const BTN_JOURNEY_TAKE_PLACE = 'where did your journey take place?';
export const BTN_WHO_CAME_WITH_YOU = 'who came along with you?';
export const BTN_WHO_INSPIRED_YOU = 'who inspired you take this journey?';
export const BTN_TAG_SUCCESS_STORY = 'tag your success story';
export const BTN_SHARE_YOUR_STORY = 'share your story';
export const BTN_CREATE_A_NEW_JOURNEY = 'create a new journey';
export const BTN_CREATE_A_NEW_SERVICE =
  'Set up your services. Example: Walking tour of downtown Toronto';

// const values
export const MAX_SIZE_TOP_INSPIRER_ASIDE_VIEW = 5;
