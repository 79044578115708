// import React from 'react';
// // import Toast from 'react-native-simple-toast';

// // const showToastMessage = (
// //     message,
// //     lenght = Toast.SHORT,
// //     gravity = Toast.CENTER,
// // ) => {
// //     Toast.showWithGravity(message, lenght, gravity);
// // };

// export default showToastMessage;
