import React, { useEffect, useState } from 'react';
import {
  activitySelector,
  addInspirer,
} from '../../redux/slices/mobile/activitySlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { productSelector } from '../../redux/slices/mobile/productSlice';
import { authSelector, getUsers } from '../../redux/slices/mobile/authSlice';
import { tagSelectorV2 } from '../../redux/slices/mobile/tagSliceV2';
import addPlaceIcon from '../../assets/images/button_menu_places.svg';
import { FreeMode } from 'swiper';
import FriendsIcon from '../../assets/images/Friends_Icon.svg';
import Cross from '../../assets/images/xmark-solid.svg';
import { buildUrlToSize } from '../../utils/build-image-url';
import Persons from '../Persons/Persons';
import { PhotoSize } from '../utils/subjects';
import Thumbnail from '../thumbnails/Thumbnail';
import BackButton from '../BackButton/BackButton';

const InspiredList = (props) => {
  const { activity, inspirers } = useSelector(activitySelector);
  const { product } = useSelector(productSelector);
  const { auth, users } = useSelector(authSelector);
  const { tags } = useSelector(tagSelectorV2);
  const [tag, setTag] = useState([]);
  const dispatch = useDispatch();

  console.log('getUsers', auth);

  const addInspirerHandle = (user) => {
    dispatch(
      addInspirer({
        data: {
          inspirerId: user.id,
          skInspirer: user.sk,
          createdAt: activity.createdAt,
          sk: activity.sk,
        },
        token: auth.token,
        id: activity.id,
      })
    );
  };

  return (
    <motion.div
      key='friends'
      initial={{ x: '-100vw', scale: 0 }}
      animate={{ x: 0, scale: 1 }}
      exit={{ x: '-100vw', scale: 0, opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      <div className='flex items-center gap-3'>
        <BackButton callback={() => props.inspirerHandle()} />
        <div className='flex justify-center items-center'>
          <img src={FriendsIcon} alt='place' className='max-w-[30px]' />
          <p className='text-jrny font-semibold text-center'>
            who will you taken on your journey
          </p>
        </div>
      </div>

      <div className='mb-[20px] '>
        <Swiper
          slidesPerView={6}
          spaceBetween={0}
          freeMode={true}
          modules={[FreeMode]}
        >
          {activity?.inspirers?.map((val) => (
            <SwiperSlide key={val.id}>
              <div className='relative text-center mt-[7px] w-[55px]'>
                <img
                  src={Cross}
                  alt='remove'
                  className='w-[10px] absolute top-[-9px] right-0 cursor-pointer'
                  onClick={() => {
                    setTag(val);
                  }}
                />
                <Thumbnail
                  btnFollow={false}
                  showName={false}
                  data={val}
                  PhotoSize={PhotoSize.Small}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className='relative w-full'>
        <input
          type='search'
          placeholder='Search'
          className='bg-gray-100 w-full p-[10px_20px] rounded-[50px]'
        />
        {/* <button className="w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]"></button> */}
      </div>

      <div className='py-10 space-y-2'>
        {users.map((user, ind) => (
          <div className='flex justify-between items-center' key={ind}>
            <Persons
              avater={buildUrlToSize(user.thumbnail, PhotoSize.Large)}
              name={user.name}
              className='w-[40px] h-[40px] rounded-[50%] text-white'
              nameStyle='text-jSecTitle font-semibold'
            />
            <div>
              <button
                className='p-[5px_15px] font-SourceSansPro rounded-[50px] font-semibold border-jrnyfy-textprimary border border-solid'
                onClick={() => {
                  addInspirerHandle(user);
                }}
              >
                add
              </button>
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default InspiredList;
