import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../../environments/proxyUrl';
import { parse_SourceArray } from './elasticParser';

// export const createBookingStripeIntent = createAsyncThunk(
//   'booking/createBookingStripeIntent',
//   async ({data, token, activityId, callback}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/payments/v1/user/booking/stripe/intent/${activityId}`,
//         data,
//         {headers: {Authorization: token}},
//       );
//       callback(res.results);
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
// export const buyNowBookingStripeIntent = createAsyncThunk(
//   'booking/buyNowBookingStripeIntent',
//   async ({data, token, activityId, callback}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/payments/v1/user/booking/stripe/intent/buy-now/${activityId}`,
//         data,
//         {headers: {Authorization: token}},
//       );
//       callback(res.results);
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

// export const buyProductWithCustomOffer = createAsyncThunk(
//   'booking/buyProductWithCustomOffer',
//   async ({data, token, activityId, callback}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/payments/v1/user/booking/stripe/intent/custom-price-offer/${activityId}`,
//         data,
//         {headers: {Authorization: token}},
//       );
//       callback(res.results);
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

// export const chargeNow = createAsyncThunk(
//   'booking/chargeNow',
//   async ({data, token, productId, activityId, callback}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/payments/v1/user/stripe/charge/product/${productId}/${activityId}`,
//         data,
//         {headers: {Authorization: token}},
//       );
//       if (callback) {
//         callback(res.results);
//       }
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const confirmOrder = createAsyncThunk(
  'booking/confirmOrder',
  async ({ data, token, orderId, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/payments/v1/c-order/${orderId}`,
        data,
        { headers: { Authorization: token } }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const lockNow = createAsyncThunk(
  'booking/lockNow',
  async (
    { data, token, productId, callback, activityId, timeSlotId },
    { rejectWithValue }
  ) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/payments/v1/lock/${productId}`,
        { ...data, activityId, timeSlotId },
        { headers: { Authorization: token } }
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBooking = createAsyncThunk(
  'booking/getBooking',
  async ({ token, productId, data, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/payments/v1/booking/${productId}`,
        {
          params: { ...data },
          headers: { Authorization: token },
        }
      );
      // const results = parse_SourceArray(data.results);
      if (callback) {
        callback();
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

// export const getBookingBeforeCheckout = createAsyncThunk(
//   'booking/getBookingBeforeCheckout',
//   async (
//     {token, year, month, day, activityId, readOnly = false, callback},
//     {rejectWithValue},
//   ) => {
//     try {
//       const {data: res} = await axios.get(
//         `${baseUrl}/api/booking/user/before/checkout/${activityId}/${year}/${month}/${day}`,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       // const results = parse_SourceArray(data.results);
//       if (callback) {
//         callback();
//       }
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const getDatesOfMonth = createAsyncThunk(
  'booking/getDatesOfMonth',
  async ({ token, productId, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/payments/v1/calendar/${productId}`,
        {
          params: data,
          headers: { Authorization: token },
        }
      );
      console.log('Result Booking Slice', res.results);
      const results = parse_SourceArray(res.results);
      return results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

// export const updateBooking = createAsyncThunk(
//   'booking/updateBooking',
//   async ({data, token, productId, activityId}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/booking/user/${activityId}/${productId}`,
//         data,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
// export const deleteTimeSlot = createAsyncThunk(
//   'booking/deleteTimeSlot',
//   async (
//     {token, bookingId, cartId, timeSlotId, activityId},
//     {rejectWithValue},
//   ) => {
//     try {
//       const {data: res} = await axios.delete(
//         `${baseUrl}/api/booking/user/delete-slot/${activityId}/${cartId}/${timeSlotId}`,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
// export const updateTimeSlot = createAsyncThunk(
//   'booking/updateTimeSlot',
//   async (
//     {token, cartId, timeSlotId, productId, activityId, data},
//     {rejectWithValue},
//   ) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/booking/user/update-slot/${activityId}/${productId}/${cartId}/${timeSlotId}`,
//         data,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
// export const getWishList = createAsyncThunk(
//   'booking/getWishList',
//   async (
//     {token, activityId, readOnly = false, callback},
//     {rejectWithValue},
//   ) => {
//     try {
//       const {data: res} = await axios.get(
//         `${baseUrl}/api/payments/v1/wish/${activityId}`,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       // const results = parse_SourceArray(data.results);
//       if (callback) {
//         callback();
//       }
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
// export const getWishListWithPurchasedList = createAsyncThunk(
//   'booking/getWishListAndPurchasedList',
//   async ({token, activityId, userId}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.get(
//         `${baseUrl}/api/payments/v1/wish/${activityId}/${userId}`,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
export const addIntoWishList = createAsyncThunk(
  'booking/addIntoWishList',
  async (
    { token, activityId, productId, callback, data },
    { rejectWithValue }
  ) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/wish/${activityId}`,
        { ...data, productId },
        {
          headers: { Authorization: token },
        }
      );
      console.log('wish list raw data1', res);
      if (callback) {
        callback(res.results);
      }
      console.log('wish list raw data', res);
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteFromWishList = createAsyncThunk(
  'booking/deleteFromWishList',
  async (
    { token, activityId, timelineId, callback, data },
    { rejectWithValue }
  ) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/activities/v1/wish/${activityId}`,
        {
          params: { ...data, timelineId },
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

// export const updateOfferType = createAsyncThunk(
//   'booking/updateOfferType',
//   async ({data, token, productId, activityId}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/booking/user/use-offer/${activityId}/${productId}`,
//         data,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    screenStack: [],
    booking: undefined,
    calendar: undefined,
    datesOfMonth: undefined,
    date: undefined,
    stripeIntent: {},
    tokenStatus: false,
    loadingStrip: false,
    errors: [],
    wishList: [],
    customPriceOffers: undefined,
    selectedOffer: 0,
    selectedOfferPrice: undefined,
    totalSpots: 0,
    wishListWithPurchasedList: undefined,
    dayDiscounts: undefined,
    groupDiscounts: undefined,
    earlyBirdDiscounts: undefined,
    insideServiceDetail: true,
    selected_timeSlots: [],
    purchasedActivity: [],
  },
  reducers: {
    pushToStack: (state, action) => {
      const { screen } = action.payload;
      let found = state.screenStack.includes(screen);
      if (!found) {
        state.screenStack.push(screen);
      }
    },
    setDate: (state, action) => {
      const { date } = action.payload;
      console.log('action.payload date', date);
      state.date = date;
    },
    pullFromStack: (state, action) => {
      const { count } = action.payload;
      for (let i = 0; i < count; i++) {
        state.screenStack.pop();
      }
    },
    initBookingStack: (state, action) => {
      state.screenStack = ['store'];
    },
    setSelectedOffer_information: (state, action) => {
      const { selectedOffer, selectedOfferPrice } = action.payload;
      state.selectedOffer = selectedOffer;
      state.selectedOfferPrice = selectedOfferPrice;
    },
    setTotalSpotsRedux: (state, action) => {
      const { spots } = action.payload;
      state.totalSpots = spots;
    },
    setInsideServiceDetail: (state, action) => {
      const { value } = action.payload;
      state.insideServiceDetail = value;
    },
    setSelected_timeSlots: (state, action) => {
      const { value } = action.payload;
      state.selected_timeSlots = value;
    },
  },
  extraReducers: {
    [lockNow.pending]: (state) => {
      state.loading = true;
      state.purchasedActivity = [];
    },
    [lockNow.fulfilled]: (state, action) => {
      state.purchasedActivity = action.payload.activity;
    },
    [lockNow.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getBooking.pending]: (state) => {
      state.loading = true;
      state.booking = undefined;
      state.calendar = [];
      state.selectedOffer = 0;
      state.totalSpots = 0;
      state.selectedOfferPrice = 0;
      state.selected_timeSlots = [];
    },
    [getBooking.fulfilled]: (state, action) => {
      state.booking = action.payload.booking;
      state.calendar = action.payload.calendar;
      state.customPriceOffers = action.payload.customOffer;
      state.dayDiscounts = action.payload.dayDiscounts;
      state.groupDiscounts = action.payload.groupDiscounts;
      state.earlyBirdDiscounts = action.payload.earlyBirdDiscounts;
      state.loading = false;
    },
    [getBooking.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    // [getBookingBeforeCheckout.pending]: state => {
    //   state.loading = true;
    //   state.booking = undefined;
    // },
    // [getBookingBeforeCheckout.fulfilled]: (state, action) => {
    //   state.booking = action.payload.booking;
    //   state.loading = false;
    // },
    // [getBookingBeforeCheckout.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [updateBooking.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [updateBooking.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   if (action.payload.slotDeleted) {
    //     const cart = state.booking.cart;

    //     const filteredCart = cart.find(
    //       cart => cart.id === action.payload.cart.id,
    //     );
    //     if (filteredCart) {
    //       if (filteredCart.timeSlots) {
    //         filteredCart.timeSlots = filteredCart.timeSlots.filter(
    //           slot => slot.id !== action.payload.cart.timeSlot.id,
    //         );
    //       }
    //       state.booking.cart = state.booking.cart.map(cart => {
    //         if (cart.id === filteredCart.id) {
    //           return filteredCart;
    //         }
    //         return cart;
    //       });
    //     }
    //   } else if (action.payload.cartDeleted) {
    //     const cart = state.booking.cart;

    //     const filteredCart = cart.filter(c => c.id !== action.payload.cart.id);
    //     state.booking.cart = filteredCart ? filteredCart : [];
    //   } else {
    //     const cart = state.booking.cart;

    //     const filteredCart = cart.find(
    //       cart => cart.id === action.payload.cart.id,
    //     );
    //     if (filteredCart) {
    //       console.log('filteredCart found', filteredCart);
    //       if (filteredCart.timeSlots) {
    //         console.log('filteredCart has timeslots');

    //         const filteredSlot = filteredCart.timeSlots.filter(
    //           slot => slot.id === action.payload.cart.timeSlot.id,
    //         );
    //         if (filteredSlot && filteredSlot.length > 0) {
    //           console.log('filteredSlot found');

    //           filteredCart.timeSlots = filteredCart.timeSlots.map(slot => {
    //             if (slot.id == action.payload.cart.timeSlot.id) {
    //               return {...slot, spots: action.payload.cart.timeSlot.spots};
    //             }
    //             return slot;
    //           });
    //         } else {
    //           filteredCart.timeSlots.push(action.payload.cart.timeSlot);
    //         }
    //       }
    //       filteredCart.product = action.payload.cart.product;
    //       state.booking.cart = state.booking.cart.map(cart => {
    //         if (cart.id === filteredCart.id) {
    //           return filteredCart;
    //         }
    //         return cart;
    //       });
    //     } else {
    //       const {timeSlot, ...rest} = action.payload.cart;
    //       state.booking.cart.push({...rest, timeSlots: [timeSlot]});
    //     }
    //   }
    //   state.loading = false;
    // },
    // [updateBooking.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [deleteTimeSlot.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [deleteTimeSlot.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   const cart = state.booking.cart;

    //   if (action.payload.deletedCart) {
    //     const filteredCart = cart.filter(
    //       c => c.id !== action.payload.deletedCart.id,
    //     );
    //     state.booking.cart = filteredCart;
    //   } else if (action.payload.deletedSlotCart) {
    //     const filteredCart = cart.find(
    //       cart => cart.id === action.payload.deletedSlotCart.id,
    //     );
    //     if (filteredCart) {
    //       if (filteredCart.timeSlots) {
    //         filteredCart.timeSlots = filteredCart.timeSlots.filter(
    //           slot => slot.id !== action.payload.deletedSlotCart.timeSlot.id,
    //         );
    //       }
    //       state.booking.cart = state.booking.cart.map(cart => {
    //         if (cart.id === filteredCart.id) {
    //           return filteredCart;
    //         }
    //         return cart;
    //       });
    //     }
    //   }
    //   state.booking = action.payload.booking;

    //   state.loading = false;
    // },
    // [deleteTimeSlot.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [updateTimeSlot.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [updateTimeSlot.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   const cart = state.booking.cart;

    //   if (action.payload.deletedCart) {
    //     const filteredCart = cart.filter(
    //       c => c.id !== action.payload.deletedCart.id,
    //     );
    //     state.booking.cart = filteredCart;
    //   } else if (action.payload.updatedSlotCart) {
    //     const filteredCart = cart.find(
    //       cart => cart.id === action.payload.updatedSlotCart.id,
    //     );
    //     if (filteredCart) {
    //       if (filteredCart.timeSlots) {
    //         filteredCart.timeSlots = filteredCart.timeSlots.filter(
    //           slot => slot.id !== action.payload.updatedSlotCart.timeSlot.id,
    //         );
    //       }
    //       state.booking.cart = state.booking.cart.map(cart => {
    //         if (cart.id === filteredCart.id) {
    //           return {
    //             ...cart,
    //             spots: action.payload.updatedSlotCart.timeSlot.spots,
    //           };
    //         }
    //         return cart;
    //       });
    //     }
    //   }

    //   state.booking = action.payload.booking;
    //   state.loading = false;
    // },
    // [updateTimeSlot.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [createBookingStripeIntent.pending]: state => {
    //   state.stripeIntent = {};
    //   state.loadingStrip = true;
    // },
    // [createBookingStripeIntent.fulfilled]: (state, action) => {
    //   const {booking, success, ...rest} = action.payload;
    //   state.tokenStatus = success;
    //   if (success) {
    //     state.stripeIntent = rest;
    //     if (state.booking && state.booking.id == booking.id) {
    //       state.booking = {...state.booking, ...booking};
    //     }
    //     state.loadingStrip = false;
    //   } else {
    //     if (state.booking && state.booking.id == booking.id) {
    //       state.booking = {...state.booking, ...booking};
    //     }
    //   }
    // },
    // [createBookingStripeIntent.rejected]: (state, action) => {
    //   state.loadingStrip = false;
    // },
    // [buyNowBookingStripeIntent.pending]: state => {
    //   state.stripeIntent = {};
    //   state.loadingStrip = true;
    // },
    // [buyNowBookingStripeIntent.fulfilled]: (state, action) => {
    //   const {booking, success, ...rest} = action.payload;
    //   state.tokenStatus = success;
    //   if (success) {
    //     state.stripeIntent = rest;
    //     if (state.booking && state.booking.id == booking.id) {
    //       state.booking = {...state.booking, ...booking};
    //     }
    //     state.loadingStrip = false;
    //   } else {
    //     if (state.booking && state.booking.id == booking.id) {
    //       state.booking = {...state.booking, ...booking};
    //     }
    //   }
    // },
    // [buyNowBookingStripeIntent.rejected]: (state, action) => {
    //   state.loadingStrip = false;
    // },
    [getDatesOfMonth.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.datesOfMonth = {};
    },
    [getDatesOfMonth.fulfilled]: (state, action) => {
      state.errors = [];
      state.datesOfMonth = action.payload.datesOfMonth;
      state.loading = false;
    },
    [getDatesOfMonth.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    // [getWishList.pending]: state => {
    //   state.loading = true;
    //   state.booking = undefined;
    // },
    // [getWishList.fulfilled]: (state, action) => {
    //   state.wishList = action.payload.wishList;
    //   state.loading = false;
    // },
    // [getWishList.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    [addIntoWishList.pending]: (state) => {
      state.loading = true;
    },
    [addIntoWishList.fulfilled]: (state, action) => {
      let wishList = state.wishList ? state.wishList.map((item) => item) : [];
      const found = wishList.find(
        (item) =>
          item.product.id == action.payload.product.id &&
          item.activityId == action.payload.activityId
      );
      if (!found) {
        wishList.push(action.payload);
        state.wishList = wishList;
      } else {
        wishList = wishList.map((item) => {
          if (
            item.product.id == action.payload.product.id &&
            item.activityId == action.payload.activityId
          ) {
            return action.payload;
          } else {
            return item;
          }
        });
        state.wishList = wishList;
      }

      state.loading = false;
    },
    [addIntoWishList.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteFromWishList.pending]: (state) => {
      state.loading = true;
    },
    [deleteFromWishList.fulfilled]: (state, action) => {
      let wishList = state.wishList ? state.wishList.map((item) => item) : [];
      // wishList = wishList.filter(
      //   item =>
      //     item.product.id != action.payload.product.id ||
      //     item.activityId != action.payload.activityId,
      // );
      state.wishList = wishList;
      state.loading = false;
    },
    [deleteFromWishList.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    //new updateOfferType
    // [updateOfferType.pending]: state => {
    //   state.loading = true;
    // },
    // [updateOfferType.fulfilled]: (state, action) => {
    //   if (state.booking.cart && state.booking.cart > 0) {
    //     state.booking.cart = state.booking.cart.map(cart => {
    //       if (cart.product.id == action.payload.cart.product.id) {
    //         return {...cart, useOffer: action.payload.cart.useOffer};
    //       } else {
    //         return cart;
    //       }
    //     });
    //   }

    //   state.loading = false;
    // },
    // [updateOfferType.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [getWishListWithPurchasedList.pending]: state => {
    //   state.loading = true;
    //   state.wishListWithPurchasedList = undefined;
    // },
    // [getWishListWithPurchasedList.fulfilled]: (state, action) => {
    //   state.wishListWithPurchasedList = action.payload;
    //   state.loading = false;
    // },
    // [getWishListWithPurchasedList.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [chargeNow.pending]: state => {
    //   state.loading = true;
    // },
    // [chargeNow.fulfilled]: (state, action) => {
    //   console.log('data after purchase', action.payload);
    //   const {product} = action.payload;
    //   if (state.wishList && state.wishList.length > 0) {
    //     let array1 = state.wishList.filter(a => a.product.id != product);

    //     console.log('array1 ==', array1);
    //     state.wishList = array1;
    //   }

    //   state.loading = false;
    // },
    // [chargeNow.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
  },
});

export const {
  pushToStack,
  pullFromStack,
  initBookingStack,
  setDate,
  setSelectedOffer_information,
  setTotalSpotsRedux,
  setInsideServiceDetail,
  setSelected_timeSlots,
} = bookingSlice.actions;

export default bookingSlice.reducer;

export const bookingSelector = (state) => state.booking;
