import React, { useEffect, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import starFill from '../../assets/images/star-fill.svg';
import starEmpty from '../../assets/images/star-empty.svg';
import FilterRatings from './FilterRatings';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getServices } from '../../redux/slices/web/serviceSlice';
import { useSearchParams } from 'react-router-dom';
import { parseTypeHeadServicesUrlParams } from '../../utils/get-nav-option';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { Duration, TimeOfDay } from '../utils/subjects';

const FilterSidebar = () => {
  const topTopics = [
    { topTopics: 'pyramid of giza' },
    { topTopics: 'Eiffel Tower' },
    { topTopics: 'fit body' },
    { topTopics: 'amstradam' },
    { topTopics: 'ice bucket challenge' },
  ];
  const [priceRange, setPriceRange] = useState([0, 500]);
  const [searchParams] = useSearchParams();
  const { auth, userType, firstTimeLogIn } = useSelector(authSelector);
  const paramData = parseTypeHeadServicesUrlParams(searchParams);
  const [filterData, setFilterData] = useState({
    from: 0,
    size: 10,
    location: paramData.lat
      ? { lat: paramData.lat, lon: paramData.lon }
      : undefined,
    value: paramData.value ? paramData.value : undefined,
    startDate: paramData.startDate ? paramData.startDate : undefined,
    endDate: paramData.endDate ? paramData.endDate : undefined,
    priceRange: undefined,
    durations: [],
    ratingRange: [],
    timeSlots: [],
    deals: [],
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getServices({
        data: filterData,
        token: auth.token,
      })
    );
  }, [filterData]);

  const productFilterHandle = (e) => {
    console.log('Checkbox Data', e.target.checked);
    if (e.target.name == 'durations') {
      if (e.target.checked) {
        setFilterData((prevState) => ({
          ...prevState,
          [e.target.name]: [...prevState.durations, Duration[e.target.value]],
        }));
      } else {
        filterData[e.target.name].splice(
          filterData[e.target.name].indexOf(Duration[e.target.value]),
          1
        );
        setFilterData((prevState) => ({
          ...prevState,
          [e.target.name]: [...prevState.durations],
        }));
      }
    }
    if (e.target.name == 'minRating') {
      if (e.target.checked) {
        setFilterData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      } else {
      }
    }
    if (e.target.name == 'timeSlots') {
      if (e.target.checked) {
        setFilterData((prevState) => ({
          ...prevState,
          [e.target.name]: [...prevState.timeSlots, TimeOfDay[e.target.value]],
        }));
      } else {
        console.log('Target Data', TimeOfDay[e.target.value]);
        filterData[e.target.name].splice(
          filterData[e.target.name].indexOf(TimeOfDay[e.target.value]),
          1
        );
        setFilterData((prevState) => ({
          ...prevState,
          [e.target.name]: [...prevState.timeSlots],
        }));
      }
    }
    if (e.target.name == 'deals') {
      if (e.target.checked) {
        setFilterData((prevState) => ({
          ...prevState,
          [e.target.name]: [...prevState.deals, e.target.value],
        }));
      } else {
        filterData[e.target.name].splice(
          filterData[e.target.name].indexOf(e.target.value),
          1
        );
        setFilterData((prevState) => ({
          ...prevState,
          [e.target.name]: [...prevState.deals],
        }));
      }
    }
  };

  console.log('Filtered Data', filterData);

  const changeThePriceRange = () => {
    setFilterData((prevState) => ({
      ...prevState,
      priceRange: { max: priceRange[1], min: priceRange[0] },
    }));
  };

  return (
    <div className='max-w-[310px] space-y-8 border-l pl-6'>
      <div className='space-y-3'>
        <span>Price</span>
        <div>
          <RangeSlider
            max={500}
            value={priceRange}
            onInput={setPriceRange}
            onThumbDragEnd={changeThePriceRange}
          />
        </div>
        <div className='flex justify-between'>
          <span>${priceRange[0]}</span>
          <span>
            ${priceRange[1]}
            {priceRange[1] == 500 ? '+' : ''}
          </span>
        </div>
      </div>
      <div className='space-y-3'>
        <span>Duration</span>
        <div className='space-y-2'>
          <div className='flex items-center'>
            <input
              id='1hour'
              name='durations'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={0}
              onChange={productFilterHandle}
            />
            <label for='1hour'>1 hour or less</label>
          </div>
          <div className='flex items-center'>
            <input
              id='1hour2'
              name='durations'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={1}
              onChange={productFilterHandle}
            />
            <label for='1hour2'>1 hour to 2 hours</label>
          </div>
          <div className='flex items-center'>
            <input
              id='1hour3'
              name='durations'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={2}
              onChange={productFilterHandle}
            />
            <label for='1hour3'>2 hour to 3 hours</label>
          </div>
          <div className='flex items-center'>
            <input
              id='1day'
              name='durations'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={3}
              onChange={productFilterHandle}
            />
            <label for='1day'>1 day</label>
          </div>
          <div className='flex items-center'>
            <input
              id='1day+'
              name='durations'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={4}
              onChange={productFilterHandle}
            />
            <label for='1day+'>more than 1 day</label>
          </div>
        </div>
      </div>
      <div className='space-y-3'>
        <span>Time of Day</span>
        <div className='space-y-2'>
          <div className='flex items-center'>
            <input
              id='6am12pm'
              name='timeSlots'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={0}
              onChange={productFilterHandle}
            />
            <label for='6am12pm'>6am-12pm</label>
          </div>
          <div className='flex items-center'>
            <input
              id='12pm5pm'
              name='timeSlots'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={1}
              onChange={productFilterHandle}
            />
            <label for='12pm5pm'>12pm-5pm</label>
          </div>
          <div className='flex items-center'>
            <input
              id='5am12'
              name='timeSlots'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={2}
              onChange={productFilterHandle}
            />
            <label for='5am12'>5pm-12am</label>
          </div>
        </div>
      </div>
      <div className='space-y-3'>
        <span>Rating</span>
        <div className='space-y-3'>
          <FilterRatings
            showCheckbox={true}
            callback={productFilterHandle}
            totalCount={5}
            value={5}
          />
          <FilterRatings
            showCheckbox={true}
            callback={productFilterHandle}
            totalCount={5}
            value={4}
          />
          <FilterRatings
            showCheckbox={true}
            callback={productFilterHandle}
            totalCount={5}
            value={3}
          />
          <FilterRatings
            showCheckbox={true}
            callback={productFilterHandle}
            totalCount={5}
            value={2}
          />
          <FilterRatings
            showCheckbox={true}
            callback={productFilterHandle}
            totalCount={5}
            value={1}
          />
        </div>
      </div>
      <div className='space-y-3'>
        <span>Deals</span>
        <div className='space-y-2'>
          <div className='flex items-center'>
            <input
              id='dealsDiscount'
              name='deals'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={0}
              onChange={productFilterHandle}
            />
            <label for='dealsDiscount'>Deal & Discount</label>
          </div>
          <div className='flex items-center'>
            <input
              id='freeCancelation'
              name='deals'
              type='checkbox'
              className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
              value={1}
              onChange={productFilterHandle}
            />
            <label for='freeCancelation'>Free Cancelation</label>
          </div>
        </div>
      </div>
      <div className='space-y-5'>
        <span>popular locations</span>
        <div className='flex flex-wrap gap-3'>
          {topTopics &&
            topTopics.map((item) => (
              <button className='p-[8px_20px_9px_15px] rounded-[50px] text-[12px] border border-jrnyfy-textprimary text-jrnyfy-textprimary font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white hover:bg-jrnyfy-themeColor transition-all font-SourceSansPro'>
                {item.topTopics}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;
