import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';

const PaymentStatus = () => {
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();

  return <>Success</>;
};

export default PaymentStatus;
