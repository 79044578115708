import React, { useState, useCallback, useEffect } from 'react';
import Popup from 'reactjs-popup';
import NoPicture from '../../assets/images/image_upload.svg';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg';
import {
  authSelector,
  uploadUserPhoto,
} from '../../redux/slices/mobile/authSlice';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { UserUploadedImageType } from '../utils/subjects';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import Cross from '../../assets/images/xmark-solid.svg';

const UploadUserProfileImageModal = ({
  setUploadedBannerOrProfile,
  userProfileUploadModalHandle,
  userProfileUploadModal,
  imageType,
}) => {
  const { auth } = useSelector(authSelector);
  const [userProfileImage, setUserProfileImage] = useState('');
  const [profileImageFile, setProfileImageFile] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(1);
  const [zoomChange, setZoomChange] = useState([0, 1]);
  const [rotationChange, setRotationChange] = useState([0, 0]);
  const [isEditEnable, setIsEditEnable] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const dispatch = useDispatch();
  const uploadUserProfileHandle = (e) => {
    setUserProfileImage(URL.createObjectURL(e.target.files[0]));
    setProfileImageFile(e.target.files[0]);
  };

  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const setCroppedImageHandle = () => {
    try {
      getCroppedImg(userProfileImage, croppedAreaPixels, rotation).then(
        (res) => {
          {
            console.log('donee', { res });
            setUserProfileImage(URL.createObjectURL(res));
            setProfileImageFile(res);
            setIsEditEnable(false);
          }
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const uploadUserImageHandle = () => {
    console.log('Called', userProfileImage, imageType);
    setActionStates({ ...actionStates, loading: true });
    if (imageType == UserUploadedImageType.Profile) {
      setUploadedBannerOrProfile({ profileRUL: userProfileImage });
    } else {
      setUploadedBannerOrProfile({ bannerURL: userProfileImage });
    }
    const formData = new FormData();
    console.log('ImageType', imageType);
    formData.append('photo', profileImageFile);
    formData.append('imageType', imageType);
    dispatch(uploadUserPhoto({ formData: formData, token: auth.token })).then(
      (res) => {
        console.log('Response', res.meta.requestStatus);
        if (res.meta.requestStatus == 'fulfilled') {
          setActionStates({ ...actionStates, loading: false, fulfilled: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
            setUserProfileImage();
            setProfileImageFile();
          }, 3000);
        }
        if (res.meta.requestStatus == 'rejected') {
          setActionStates({ ...actionStates, loading: false, rejected: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
            setUserProfileImage();
            setProfileImageFile();
          }, 3000);
        }
      }
    );
  };

  useEffect(() => {
    setZoom(zoomChange[1]);
  }, [zoomChange]);

  useEffect(() => {
    setRotation(rotationChange[1]);
  }, [rotationChange]);

  return (
    <Popup
      open={userProfileUploadModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='bg-white p-10 space-y-5 w-[470px] rounded-[20px] max-[767px]:overflow-y-scroll max-[600px]:h-screen  max-[600px]:rounded-none max-[600px]:p-5'>
        <div className='flex items-start gap-3'>
          <img
            src={Cross}
            alt='cross'
            className='cursor-pointer w-[20px] '
            onClick={() => {
              setUserProfileImage();
              userProfileUploadModalHandle();
            }}
          />

          <p className='text-jrnyfy-textprimary font-bold text-center w-full'>
            {imageType == UserUploadedImageType.Profile
              ? 'Upload Profile Picture'
              : 'Upload Banner Image'}
          </p>
        </div>
        {isEditEnable && userProfileImage ? (
          <div className='min-h-[465px] relative'>
            <div className='w-full h-[300px] absolute top-0 left-0 right-0 bottom-0'>
              <Cropper
                image={userProfileImage}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                cropShape={
                  imageType == UserUploadedImageType.Profile ? 'round' : ''
                }
                showGrid={
                  imageType == UserUploadedImageType.Profile ? false : true
                }
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                classes={{ containerClassName: 'rounded-xl' }}
              />
            </div>

            <div className='absolute bottom-0 w-full space-y-3'>
              <div>
                <label className='block font-medium text-jrnyfy-textprimary dark:text-white my-3'>
                  Zoom : {parseInt(zoom)}%
                </label>
                {/* <input
                  id='default-range'
                  type='range'
                  defaultValue={zoom}
                  min='1'
                  max='100'
                  onChange={(e) => setZoom(e.target.value)}
                  class='w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700'
                /> */}
                <RangeSlider
                  min={1}
                  className='single-thumb'
                  defaultValue={[0, 1]}
                  thumbsDisabled={[true, false]}
                  rangeSlideDisabled={true}
                  step={0.01}
                  value={zoomChange}
                  onInput={setZoomChange}
                />
              </div>
              <div>
                <label className='block font-medium text-jrnyfy-textprimary dark:text-white my-3'>
                  Rotation : {parseInt(rotation)} deg
                </label>
                {/* <input
                  id='default-range'
                  type='range'
                  defaultValue={rotation}
                  min='1'
                  max='360'
                  onChange={(e) => setRotation(e.target.value)}
                  class='w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700'
                /> */}

                <RangeSlider
                  className='single-thumb'
                  defaultValue={[0, 0]}
                  min={0}
                  max={360}
                  thumbsDisabled={[true, false]}
                  rangeSlideDisabled={true}
                  step={0.01}
                  value={rotationChange}
                  onInput={setRotationChange}
                />
              </div>

              <div className='flex justify-between'>
                <button
                  onClick={() => setIsEditEnable(false)}
                  className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
                >
                  Back
                </button>
                <button
                  onClick={setCroppedImageHandle}
                  className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className='space-y-3'>
            <label className='relative p-[10px] border max-w-[378px] h-[200px]  border-jrnyfy-border rounded-[30px] flex justify-center items-center'>
              <input
                type='file'
                accept='image/*'
                className='hidden'
                onChange={uploadUserProfileHandle}
              />
              <img
                src={userProfileImage ? userProfileImage : NoPicture}
                alt='profile picture'
                className={`${
                  userProfileImage
                    ? imageType == UserUploadedImageType.Profile
                      ? 'w-[100px] h-[100px] rounded-[50%]'
                      : 'w-full h-full rounded-[30px]'
                    : 'w-[100px]'
                }`}
              />
              {!userProfileImage && (
                <span className='absolute bottom-3 text-jrnyfy-textprimary'>
                  {imageType == UserUploadedImageType.Profile
                    ? ' Upload Profile Picture'
                    : 'Upload Banner Image'}
                </span>
              )}
            </label>

            {userProfileImage && (
              <div className='flex justify-between'>
                <FunctionalButton
                  actionStates={actionStates}
                  callback={() => uploadUserImageHandle()}
                  actionStatesTexts={{
                    default: 'Upload',
                    loading: 'Uploading',
                    fulfilled: 'Uploaded Successfully',
                    rejected: 'Upload Failed',
                  }}
                />
                {!actionStates.loading &&
                  !actionStates.fulfilled &&
                  !actionStates.rejected && (
                    <button
                      onClick={() => setIsEditEnable(true)}
                      className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
                    >
                      Edit
                    </button>
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    </Popup>
  );
};

export default UploadUserProfileImageModal;
