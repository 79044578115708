import React from 'react';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { buildUrlToSize } from '../../utils/build-image-url';
import Persons from '../Persons/Persons';
import Thumbnail from '../thumbnails/Thumbnail';
import { PhotoSize } from '../utils/subjects';

const UserListRenderer = ({ users, showAddBtn,thumbnailContainerStyle }) => {
  return (
    <div className='max-w-[545px]'>
      <div className='my-6 space-y-2'>
        {users.map((val, ind) => (
          <div className='flex justify-between items-center py-2' key={ind}>
            <div>
              <Thumbnail
                showName={true}
                data={val}
                PhotoSize={PhotoSize.Medium}
                thumbnailContainerStyle={thumbnailContainerStyle}
              />
            </div>
            <div>
              {/* <button
              className={`p-[5px_15px] font-SourceSansPro rounded-[50px] font-semibold ${
                userArr.some((res) => res.sk === val.sk)
                  ? 'bg-jrnyfy-themeColor text-white'
                  : 'border-jrnyfy-textprimary border border-solid'
              }`}
              onClick={() => {
                userSelect(val);
              }}
            >
              {userArr.some((res) => res.sk === val.sk) ? 'added' : 'add'}
            </button> */}
              {showAddBtn && showAddBtn == true && (
                <button className='p-[5px_15px] font-SourceSansPro rounded-[50px] font-semibold border-jrnyfy-textprimary border border-solid'>
                  add
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserListRenderer;
