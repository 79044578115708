import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import {
  authSelector,
  changeEmail,
  emailChangeRequest,
} from '../../redux/slices/mobile/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  EMAIL_INPUT_ERROR,
  EMAIL_VALIDATION_ERROR,
  EMAIL_VALIDATION_REGEX,
  OLD_PASSWORD_REQUIRE_ERROR,
} from '../utils/static/error-messages';
import { YesNo } from '../utils/subjects';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import Cross from '../../assets/images/xmark-solid.svg';

const EmailChangeModal = ({
  showEmailChangeModal,
  showEmailChangeModalHandle,
}) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [nextRequestTime, setNextRequestTime] = useState();
  const [time, setTime] = useState('');
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  const [emailChangeBtn, setEmailChangeBtn] = useState({
    new_email: '',
    pass: '',
  });
  const [emailError, setEmailError] = useState({
    new_email_err: '',
    current_pass_err: '',
  });

  const handleEmailChangeBtn = (text, type) => {
    setEmailChangeBtn((preState) => ({
      ...preState,
      [type]: text.target.value,
    }));
  };
  const emailChangeHandle = () => {
    let isEmailValid = true;
    if (emailChangeBtn.new_email == '') {
      setEmailError((preState) => ({
        ...preState,
        new_email_err: EMAIL_INPUT_ERROR,
      }));
      isEmailValid = false;
    } else if (emailChangeBtn.new_email.match(EMAIL_VALIDATION_REGEX)) {
      //Checking with regular expresion is email format is correct or not
      setEmailError((preState) => ({
        ...preState,
        new_email_err: EMAIL_VALIDATION_ERROR,
      }));
      isEmailValid = false;
    }
    if (emailChangeBtn.pass == '') {
      setEmailError((prevState) => ({
        ...prevState,
        current_pass_err: OLD_PASSWORD_REQUIRE_ERROR,
      }));
      isEmailValid = false;
    }
    console.log(emailChangeBtn);
    if (isEmailValid) {
      setActionStates({ ...actionStates, loading: true });
      dispatch(
        emailChangeRequest({
          data: {
            email: emailChangeBtn.new_email,
            password: emailChangeBtn.pass,
            verifyUrl: YesNo.Yes,
          },
          token: auth.token,
        })
      ).then((res) => {
        console.log('Response', res);
        if (res.meta.requestStatus == 'fulfilled') {
          setNextRequestTime(60);
          localStorage.setItem('waitTime', new Date());
          setActionStates({ ...actionStates, loading: false, fulfilled: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
          }, 3000);
        }
        if (res.meta.requestStatus == 'rejected') {
          let errors;
          res?.payload?.errors?.map((item) => {
            if (item?.param == 'email') {
              errors = { ...errors, new_email_err: item.msg };
            } else if (item?.param == 'password') {
              errors = { ...errors, current_pass_err: item.msg };
            }
          });
          setEmailError(errors);

          setActionStates({ ...actionStates, loading: false, rejected: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
          }, 3000);
        }
      });
    }
  };
  useEffect(() => {
    const waitTime = localStorage.getItem('waitTime');
    if (waitTime) {
      const timeDifference =
        (new Date(waitTime).getTime() - new Date().getTime()) / 1000;
      console.log('Time Difference', Math.abs(Math.round(timeDifference)));
      setNextRequestTime(60 - Math.abs(Math.round(timeDifference)));
      if (60 - Math.abs(Math.round(timeDifference)) < 0) {
        localStorage.removeItem('waitTime');
      }
    }
  }, []);

  useEffect(() => {
    console.log('Yes Tim diff', nextRequestTime);
    if (nextRequestTime <= 60 && nextRequestTime > 0) {
      let time = nextRequestTime;
      const interval = setInterval(() => {
        time -= 1;
        setTime(time);
      }, 1000);
      if (nextRequestTime <= 0) {
        clearInterval(interval);
      }
    }
  }, [nextRequestTime]);

  return (
    <Popup
      open={showEmailChangeModal}
      onClose={showEmailChangeModalHandle}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='space-y-5 bg-white w-[420px] rounded-[20px] p-10 max-[500px]:p-5 max-[500px]:h-screen max-[500px]:overflow-y-scroll max-[500px]:rounded-none'>
        <div className='flex items-start gap-3'>
          <button onClick={() => showEmailChangeModalHandle(false)}>
            <img src={Cross} alt='back button' className='w-[15px] w-[25px]' />
          </button>
        </div>
        <p>to update email you will need to enter your password</p>
        <div className='pb-5 space-y-6'>
          <div>
            <input
              type='text'
              name='email'
              className='text-jrnyfy-textprimary border-2 border-jrnyfy-colorLightGray rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder='add email address'
              onChange={(text) => {
                handleEmailChangeBtn(text, 'new_email');
                setEmailError({ ...emailError, new_email_err: '' });
              }}
            />
            {emailError.new_email_err && (
              <p className='text-red-500'>{emailError.new_email_err}</p>
            )}
          </div>
          <div>
            <input
              type='password'
              name='current password'
              onChange={(text) => {
                handleEmailChangeBtn(text, 'pass');
                setEmailError({ ...emailError, current_pass_err: '' });
              }}
              className='text-jrnyfy-textprimary border-2 border-jrnyfy-colorLightGray rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder='enter your password'
            />
            {emailError.current_pass_err && (
              <p className='text-red-500'>{emailError.current_pass_err}</p>
            )}
          </div>
          {time <= 60 && time > 0 ? (
            <div>
              <p>
                Wait {time}s Before Next Request or Refresh your email to get
                the email change verification link
              </p>
            </div>
          ) : (
            <FunctionalButton
              actionStates={actionStates}
              callback={() => emailChangeHandle()}
              actionStatesTexts={{
                default: 'Update',
                loading: 'Updating',
                fulfilled: 'Email reset link sent to your email',
                rejected: 'Update Failed',
              }}
            />
          )}
        </div>
      </div>
    </Popup>
  );
};

export default EmailChangeModal;
