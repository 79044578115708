import React from 'react';
import starFill from '../../assets/images/star-fill.svg';
import starEmpty from '../../assets/images/star-empty.svg';
import Count from '../userProfile/count';

const FilterRatings = ({ showCheckbox, callback, totalCount, value }) => {
  const stars = Array.from({ length: totalCount }, (elem, index) => {
    return value > index ? (
      <span>
        <img src={starFill} alt='star-fill' />
      </span>
    ) : (
      <span>
        <img src={starEmpty} alt='star-empty' />
      </span>
    );
  });

  const callCallBack = (val) => {
    console.log('Rating', val.target.name);
    if (callback) {
      callback(val);
    }
  };

  return (
    <div>
      <div className='flex items-center'>
        {showCheckbox && (
          <input
            type='radio'
            id={value}
            value={value}
            onChange={callCallBack}
            name='minRating'
            className='mr-2 w-5 h-5 accent-jrnyfy-themeColor'
          />
        )}
        <label for={value} className='flex space-x-2'>
          {stars}
        </label>
      </div>
    </div>
  );
};

export default FilterRatings;
