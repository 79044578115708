import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import { buildUrlToSize } from "../../utils/build-image-url";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import { PhotoSize, YesNo } from "../utils/subjects";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import {
  generateServiceDetailsUrl,
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import Thumbnail from "../thumbnails/Thumbnail";
import { useContext, useState } from "react";
import JrnyListModal from "./JrnyListModal";
import Login from "../Login/Login";
import Icons from "../Icons/Icons";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";
import { timelineDataContext } from "../jrnyTimline/JrnyTimline";

const WishedProduct = ({ isEditable, index, data }) => {
  const user = data?.feed ? data?.feed?.user : data?.user;
  const { auth } = useSelector(authSelector);
  const { activity } = useSelector(activitySelector);
  let avater =
    buildUrlToSize(data?.feed?.user?.thumbnail, PhotoSize.Large) ||
    buildUrlToSize(data?.user?.thumbnail, PhotoSize.Large);
  let gellary = data?.gallery || data?.order?.product?.tasks;
  const journeyName = data?.activity?.name ? data?.activity?.name : activity?.name;
  let productThumb =
    data?.feed?.product?.thumbnail || data?.order?.product?.thumbnail;
  let product = data?.feed?.product || data?.order?.product;
  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();
  let fName = data?.feed?.user?.name.slice(0, 1);
  let mNamePos = data?.feed?.user?.name.search(" ");
  let mName = data?.feed?.user?.name.slice(mNamePos + 1, mNamePos + 2);
  let altTitle = fName + mName;
  let navigate = useNavigate();
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  const [jrnyListModal, setJrnyListModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  let [login, setLogin] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  console.log("Wished product", data);

  const jrnyListModalHandle = () => {
    setJrnyListModal((prevState) => !prevState);
  };

  let loginControl = () => {
    setLogin((state) => !state);
  };

  return (
    <div>
      <div className="flex justify-between items-start">
        <div className="relative">
          <div className="absolute top-0 left-[-24px]">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>
          {data?.feed?.user ? (
            <p
              className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold cursor-pointer"
              onClick={() => {
                // navigate(`/jrny/${data.id}`);
                navigate(getJourneyNavOption(data));
              }}
            >
              <span className="text-jrnyfy-title">
                {data?.feed?.user?.name} wished {data?.feed?.product?.name} for
                journey:{" "}
              </span>{" "}
              {journeyName}
            </p>
          ) : (
            <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold ">
              <span className="text-jrnyfy-title">
                {data?.feed?.user?.name} wished {data?.feed?.product?.name} for
                journey:{" "}
              </span>{" "}
              {journeyName}
            </p>
          )}

          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {user.name}, created{" "}
            <Moment fromNow>{data?.createdAt}</Moment>
          </p>
        </div>
        {!data?.feed?.user && isEditable == true && (
          <div className="ml-[10px] md:ml-[30px]">
            <Icons
              iconClick={() => deleteModalHandle(true)}
              icon={Option}
              alt="Option"
              width="25px"
            />
          </div>
        )}
      </div>

      {deleteModal && (
        <TimelineOptionsModal
          index={index}
          actionStates={actionStates}
          deleteModal={deleteModal}
          deleteModalHandle={deleteModalHandle}
        />
      )}
      <div className="mt-4 ml-[20px] w-auto">
        <div>
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            style={{
              "--swiper-pagination-color": "white",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "1",
            }}
          >
            {/* {gellary.map((val, ind) => (
                            <SwiperSlide key={ind}>
                                <img
                                    src={buildUrlToSize(data?.feed?.product?.thumbnail)}
                                    alt="Ticket"
                                    className='rounded-t-[10px] h-[120px] min-[550px]:h-[150px] min-[900px]:h-[185px] w-full'
                                />
                            </SwiperSlide>
                        ))} */}
            <SwiperSlide>
              <img
                src={buildUrlToSize(productThumb, PhotoSize.Large)}
                alt="Ticket"
                className="rounded-t-[10px] max-h-[160px] w-full object-cover"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="bg-jrnyfy-textprimary text-white rounded-b-[10px] px-[30px] py-[15px]">
          <p className="leading-[15px] text-[12px] md:text-[16px]">
            {data?.feed?.product?.name || data?.order?.product?.name}
          </p>
        </div>

        <div className="flex space-x-4 mt-[10px] md:mt-[20px] leading-[10px] md:leading-[15px]">
          <button
            onClick={() => {
              if (auth?.token) {
                jrnyListModalHandle();
              } else {
                loginControl();
              }
            }}
            className="p-[10px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[15px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
          >
            add this to your journey
          </button>
          <button
            onClick={() => navigate(generateServiceDetailsUrl(product))}
            className="p-[10px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[15px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
          >
            book now
          </button>
        </div>
      </div>
      {jrnyListModal && (
        <JrnyListModal
          jrnyListModalHandle={jrnyListModalHandle}
          jrnyListModal={jrnyListModal}
          product={product}
        />
      )}
      {login && (
        <Login
          setSignUpModal={setSignUpModal}
          mState={login}
          setMState={loginControl}
        />
      )}
    </div>
  );
};

export default WishedProduct;
