import React from 'react';

const FunctionalButton = ({
  actionStates,
  callback,
  params,
  bgColor,
  cursor,
  text,
  border,
  padding,
  actionStatesTexts,
  tooltip,
}) => {
  const error = (
    <svg viewBox='0 0 24 24' width='100%' height='100%' fill='#e74c3c'>
      <path d='M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z'></path>
    </svg>
  );
  const success = (
    <svg viewBox='0 0 24 24' width='100%' height='100%' fill='#07bc0c'>
      <path d='M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z'></path>
    </svg>
  );
  return (
    <button
      title={`${tooltip}`}
      onClick={() => callback && callback()}
      className={`flex gap-2 rounded-[50px]    ${
        (cursor ? ' cursor-' + cursor : 'pointer ') +
        ' ' +
        (bgColor ? ' bg-' + bgColor : 'bg-jrnyfy-border') +
        ' ' +
        (text?.color ? 'text-' + text?.color : 'text-jrnyfy-textprimary') +
        ' ' +
        (text?.weight ? 'font-' + text?.weight : 'font-bold') +
        ' ' +
        (text?.size ? 'text-[' + text?.size + ']' : 'text-[15px]') +
        ' ' +
        (text?.font ? 'font-' + text?.font : 'text-[15px]') +
        ' ' +
        (text?.style ? text?.style : 'text-normal') +
        ' ' +
        (padding ? 'p-[' + padding + ']' : 'p-[10px_20px] w-[100%]')
      } max-[767px]:text-[12px] max-[767px]:p-[5px_10px]`}
      data-tooltip-target={`${actionStatesTexts.default}-tooltip-light`}
      data-tooltip-style='light'
      type='button'
    >
      {!actionStates.loading &&
        !actionStates.fulfilled &&
        !actionStates.rejected &&
        actionStatesTexts.default}
      {actionStates.loading && (
        <>
          <div className='spinner'></div> {actionStatesTexts.loading}
        </>
      )}
      {actionStates.fulfilled && (
        <>
          <div className='w-[20px]'>{success} </div>
          {actionStatesTexts.fulfilled}
        </>
      )}
      {actionStates.rejected && (
        <>
          <div className='w-[20px]'>{error} </div> {actionStatesTexts.rejected}
        </>
      )}
    </button>
  );
};

export default FunctionalButton;
