import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import Cross from "../../assets/images/xmark-solid.svg";
import NoPicture from "../../assets/images/NoPicture.png";
import ProgressPicThumb from "./progressPicThumb";
import { useDispatch, useSelector } from "react-redux";
import { FileUploadFor, MediaType, PhotoSource, YesNo } from "../utils/subjects";

import moment from "moment";
import {
  activitySelector,
  getTimeLineForJourney,
  pushIntoSortedGallery,
  setActivityThumbnail,
  uploadGalleryPhotos,
} from "../../redux/slices/mobile/activitySlice";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { Trimmer } from "../VideoTrimmer/Trimmer";

const AddProgress = ({
  setDataLoading,
  setTimelineContent,
  timeLineContent,
  modalState,
  modalControl,
  jrnyActivity,
  showFloatingMenuHandle,
}) => {
  let [postValue, setPostValue] = useState();
  const { auth } = useSelector(authSelector);
  let dispatch = useDispatch();
  let formData = new FormData();
  let authinfo = useSelector(authSelector).auth;
  const [progressPics, setProgressPics] = useState([]);
  const { activity } = useSelector(activitySelector);
  const [isEditEnable, setIsEditEnable] = useState(false);
  const [video, setVideo] = useState();
  const [isVideo, setIsVideo] = useState()
  const inputRef = useRef();
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);

  let uploadProgressPics = () => {
    modalControl();

    let galleryItems = [];
    if (progressPics) {
      for (let i = 0; i < progressPics.length; i++) {
        formData.append("photos", progressPics[i]?.file);
        galleryItems.push({
          caption: "",
          position: "",
          mediaType: progressPics[i].mediaType,
          photoSource: PhotoSource.LocalStorage,
          photoReference: progressPics[i]?.url,
        });
      }
    }
    if (postValue != undefined) {
      formData.append("text", postValue);
    }
    dispatch(
      pushIntoSortedGallery({
        galleryItems: galleryItems,
      })
    );
    setTimelineContent([
      { loading: true, files: [...galleryItems], type: 16, user: auth?.user, fileCount: progressPics.length, },
      ...timeLineContent,
    ]);
    let tempTimelineContent = [
      { photos: [...galleryItems], type: 16, user: auth?.user },
      ...timeLineContent,
    ];
    formData.append("name", jrnyActivity.name);
    formData.append("date", moment(new Date()).unix());
    formData.append("uploadFor", FileUploadFor.Activity); // 0 for activity , 1 for product
    formData.append("multiple", YesNo.Yes); // 0 for activity , 1 for product
    formData.append("progress", YesNo.Yes); // 0 for activity added , 1 for progress added
    formData.append("sk", jrnyActivity.sk);
    formData.append("createdAt", jrnyActivity.createdAt);
    formData.append("id", jrnyActivity.id);
    // setDataLoading(true);
    if (showFloatingMenuHandle) {
      showFloatingMenuHandle(false);
    }
    const onUploadProgress = (progressEvent) => {
      const percentCompleted = Math.round(
        ((progressEvent.loaded * 100) / progressEvent.total) / 100
      );
      //SetProgress(percentCompleted);
    };
    dispatch(
      uploadGalleryPhotos({
        formData: formData,
        onUploadProgress,
        token: authinfo.token,
        callback: (res) => {
          console.log("Progress Pic Upload", timeLineContent);
          // setDataLoading(false);
          tempTimelineContent[0] = res?.data?.results?.timeline;
          setTimelineContent(tempTimelineContent);
        },
      })
    );
  };

  return (
    <Popup
      open={modalState}
      closeOnDocumentClick={false}
      contentStyle={{
        width: "100%",
        height: "100vh",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      <div className="relative bg-white w-[500px] rounded-[20px] p-10 max-[767px]:p-5 max-[767px]:h-full  max-[767px]:overflow-y-scroll max-[600px]:rounded-none">
        {/* -------cross button-------- */}
        {/* <img
          src={Cross}
          alt='cross'
          className='cursor-pointer w-[25px] absolute top-[20px] left-[25px]'
          onClick={modalControl}
        /> */}

        {isVideo ? (
          <div>
            <Trimmer
              video={video}
              setIsVideo={setIsVideo}
              progressPics={progressPics}
              setProgressPics={setProgressPics}
            />
          </div>) : (<div>
            <div className="flex items-start gap-3">
              <img
                src={Cross}
                alt="cross"
                className="cursor-pointer w-[20px] "
                onClick={() => modalControl(false)}
              />

              <p className="font-semibold text-jrnyfy-textprimary w-full text-center">
                Add Progress Pic
              </p>
            </div>

            {/* ------- main body start---- */}
            <div className="space-y-6">
              <div>
                <div className="flex flex-col mt-[20px] relative text-center">
                  <textarea
                    ref={inputRef}
                    onChange={(e) => {
                      setPostValue(e.target.value);
                    }}
                    spellCheck="true"
                    className="resize-none border-b-2 border-solid border-jrnyfy-textprimary min-h-[100px] z-40 break-words text-[20px] focus:outline-none"
                    value={postValue}
                  />

                  {!postValue && (
                    <span className="absolute top-0 pointer-events-none w-full text-[20px] min-[350px]:max-[460px]:text-[17px] min-[350px]:max-[460px]:leading-[21px] text-jrnyfy-textprimary font-Montserrat z-[999]">
                      how is your journey going?..
                      <br />
                      update everyone and keep a log for yourself
                    </span>
                  )}
                </div>
                <p className="text-jrnyfy-color2">
                  picture captures a thousand, consider adding a few to remind
                  yourself where things were
                </p>
              </div>
              <div className="space-x-2 space-y-5">
                <div className="flex justify-evenly">
                  <ProgressPicThumb
                    setIsEditEnable={setIsEditEnable}
                    isEditEnable={isEditEnable}
                    btnText="add progress pic"
                    selectImg={progressPics}
                    setSelectImg={setProgressPics}
                    isVideo={isVideo}
                    setIsVideo={setIsVideo}
                    video={video}
                    setVideo={setVideo}
                  />
                </div>
                {!isEditEnable && (
                  <div className="text-center">
                    <button
                      onClick={uploadProgressPics}
                      className="bg-jrnyfy-themeColor p-[10px_20px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic"
                    >
                      journey check in
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>)}
      </div>
    </Popup>
  );
};

export default AddProgress;
