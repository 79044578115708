import React, { useState } from "react";
import Cross from "../../assets/images/xmark-solid.svg";
import { JourneyPrivacy } from "../utils/subjects";
import backBtn from "../../assets/icons/back.png";
const PrivacyModeSelect = ({
  setShowCustomPrivacy,
  showPrivacy,
  privacyHandle,
  privacy,
  setPrivacy,
}) => {
  const privacyOptions = [
    "OnlyMe",
    "EveryOne",
    "my followers",
    "Specific people",
  ];
  return (
    <div className="space-y-5">
      {showPrivacy && (
        <div className="flex flex-wrap gap-2">
          {console.log(privacy)}
          {Object.keys(JourneyPrivacy).map((key) => {
            return JourneyPrivacy[key] == JourneyPrivacy.Custom ? (
              <button
                onClick={() => {
                  setPrivacy(JourneyPrivacy[key]);
                  setShowCustomPrivacy(true);
                }}
                className={`${
                  privacy == JourneyPrivacy[key]
                    ? "bg-jrnyfy-themeColor text-white border-jrnyfy-themeColor"
                    : "bg-white text-jrnyfy-textprimary border-jrnyfy-textprimary"
                } border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro transition-all duration-300`}
              >
                {privacyOptions[JourneyPrivacy[key]]}
              </button>
            ) : (
              <button
                onClick={() => {
                  setPrivacy(JourneyPrivacy[key]);
                }}
                className={`${
                  privacy == JourneyPrivacy[key]
                    ? "bg-jrnyfy-themeColor text-white border-jrnyfy-themeColor"
                    : "bg-white text-jrnyfy-textprimary border-jrnyfy-textprimary"
                } border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro transition-all duration-300`}
              >
                {privacyOptions[JourneyPrivacy[key]]}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PrivacyModeSelect;
