export const Subject = {
  RegistrationCreated: 'registration:created',
  UsersReset: 'users:reset',
  ProfileUpdated: 'profile:updated',
  EmailUpdated: 'email:updated',
  MessageSent: 'message:sent',

  ShopProfileAdded: 'business-profile:created',
  BusinessProfileUpdated: 'business-profile:updated',
  PaymentProfileCreated: 'payment-profile:created',
  PaymentProfileUpdated: 'payment-profile:updated',
  PasswordUpdated: 'password:updated',
  UserImageProcessed: 'user-image:processed',
  UserImageSaved: 'user-image:saved',
  UserVerification: 'user:verification',
  UserFollow: 'user:follow',
  UserUnfollow: 'user:unfollow',
  UserBlock: 'user:block',
  UserUnblock: 'user:unblock',
  UserLogout: 'user:logout',
  UserRecordChange: 'user:deleted',

  TaskCreated: 'task:created',
  TaskUpdated: 'task:updated',
  TaskDeleted: 'task:deleted',
  TaskAssigneeAdded: 'task-assignee:added',

  TagCreated: 'tag:created',
  TagToggleStatus: 'tag:status',
  RecordChangeNotifyUsers: 'tag-toggle-status:notify',

  TagUpdated: 'tag:updated',
  TagDeleted: 'tag:deleted',
  TagImageProcessed: 'tag-image:processed',
  ShopImageProcessed: 'shop-image:processed',

  ActivityCreated: 'activity:created',
  ActivityUpdated: 'activity:updated',
  ActivityRecordChange: 'activity:deleted',
  ActivityTagDeleted: 'activity-tag:deleted',
  ActivityTagAdded: 'activity-tag:added',
  ActivityTagCreated: 'activity-tag:created',
  ActivityTagUpdated: 'activity-tag:updated',
  ActivityNameUpdated: 'activity-name:updated',
  ActivityTaskCreated: 'activity-task:created',
  ActivityTaskUpdated: 'activity-task:updated',
  ActivityTaskAssigneeAccepted: 'activity-task-assignee:accepted',

  ActivityTaskDeleted: 'activity-task:deleted',
  ActivityCuratedTaskDeleted: 'activity-curated-task:deleted',

  ActivityImageProcessed: 'activity-image:processed',
  ActivityParticipantAdded: 'activity-participant:added',
  ActivityParticipantAccepted: 'activity-participant:accepted',
  ActivityParticipantDeleted: 'activity-participant:deleted',
  LinkActivityCreated: 'link_activity:created',
  ActivityChildAdded: 'activity-child:added',
  ActivityChangeParent: 'activity-parent:change',

  ShopCreated: 'shop:created',
  ShopUpdated: 'shop:updated',
  ShopDeleted: 'shop:deleted',

  ProductCreated: 'product:created',
  ProductUpdated: 'product:updated',
  ProductRecordChange: 'product:deleted',
  ProductTagDeleted: 'product-tag:deleted',
  ProductTagAdded: 'product-tag:added',
  ProductTagCreated: 'product-tag:created',
  ProductTagUpdated: 'product-tag:updated',
  ProductNameUpdated: 'product-name:updated',
  ProductTaskCreated: 'product-task:created',
  ProductTaskUpdated: 'product-task:updated',
  ProductTaskDeleted: 'product-task:deleted',
  ProductImageProcessed: 'product-image:processed',
  ProductEnding: 'product:ending',
  ProductEndDateAdded: 'product-end-date:added',

  ProductPromotionCreated: 'product-promotion:created',
  ProductPromotionUpdated: 'product-promotion:updated',
  ProductPromotionCancelled: 'product-promotion:cancelled',
  ProductPromotionDeleted: 'product-promotion:deleted',
  ProductPromotionEnded: 'product-promotion:ended',
  ProductPromotionStarted: 'product-promotion:started',
  PromotionStartedFutureDate: 'promotion-future-date:started',

  ProductPromotionADayEnding: 'product-promotion-a-day:ending',

  PaymentCreated: 'payment:created',
  PaymentUpdated: 'payment:updated',

  RefundRequestResponse: 'refund-request:response',

  TicketCreated: 'ticket:created',
  TicketUpdated: 'ticket:updated',

  OrderCreated: 'order:created',
  OrderCancelled: 'order:cancelled',
  ExpirationComplete: 'expiration:complete',

  ChatImageProcessed: 'chat-image:processed',
  NotificationCreated: 'notification:created',
  NotificationViewed: 'notification:viewed',

  AdminAuditLogCreated: 'admin-audit-log:created',

  AppealUpdated: 'appeal:updated',
  NewOrder: 'order:new',
};

export const ContentFilter = {
  Like:0,
  Comment:1,
  Participant:2
}

export const  LikeOrCommentType= {
  Journey:0,
  Timeline:1,
  Gallery:2,
}

export const MilestoneRequestType = {
  Places: 0,
  ThingsToDo: 1,
};

export const CommentParentType= {
  Journey:0,
  Timeline:1,
  Gallery:2,
  Comment:3
}

export const SortElastic= {
  ASC : "asc",
  DESC : "desc",
}

export const TaskType= {
  Created:0,
  Location:1,
  ThingsToDo:2,
}
export const ViewGroupType= {
  Participant:0,
  TaskCustom:1,
  TaskLocation:2,
  TaskThingsToDo:3,
}

export const ElasticRelationType = {
  UserComment: 'ucomment',
  UserCommentLike: 'ucmtlk', // a user liked a comment
  UserSubComment: 'uscmt',
  UserSubCommentLike: 'uscmtlk', // a user liked a comment
  UserSubSubComment: 'usscmt',
  UserSubSubCommentLike: 'usscmtlk', // TODO: not implete mented yet
};

export const SuggestedTaskType = {
  UserWritten: 0,
  GooglePlace: 1,
  ChatGpt: 2,
};
export const MilestoneSource = {
  OwnDB: 1,
  OwnDBLastItems: 2,
  ChatGpt: 3,
};
export const UserActionComment = {
  Delete: 0,
  Update: 1,
  Like: 2,
  Dislike: 3,
};
export const EmbeddedGalleryMaxSize = 7; // with feed or jorney document max 10 image will be embeded, rest will be found Gallery Database
export const JourneyFilter = {
  All: 0,
  Current: 1,
  Past: 2,
  OnlyMe: 3,
  Draft: 4, //Does not exist in the server
};

export const SignupType = {
  Registration: 0,
  Facebook: 1,
  Google: 2,
  Apple: 3,
};
export const UnlinkType = {
  Signout: 0,
  FacebookAccount: 1,
  GoogleAccount: 2,
};

export const UserAccountNotificationType = {
  AccountDisable: 0,
  PushNotifyStatusChanged: 1,
};

//Used for background api type
export const BackgroundUploadApiType = {
  UploadGalleryPhotos: 0,
  RemoveTimelineItem: 1,
};

// Used for background api status
export const BackgroundUploadApiStatus = {
  pending: 0,
  processing: 1,
  done: 2,
};
// Used for background api status
export const ActionType = {
  Create: 0,
  Update: 1,
  Delete: 2,
};

export const FcmType = {
  Notificaton: 0,
  Message: 1,
  VendorStatus: 2,
  AccountDisable: 3,
  PushNotify: 4,
};

export const ImageUploadLimit = 10;

// export const NotificationType = {
//   ActivityRecordChange: 0,
//   ActivityParticipantAdded: 1,
//   ActivityParticipantAccepted: 2,
//   TaskAssigneeAdded: 3,
//   TaskAssigneeAccepted: 4,
//   MessageSent: 5,
//   NewOrder: 6,
//   OrderConfirmed: 7,
//   ProductPromotionADayEnding: 8,
//   ProductPromotionCreated: 9,
//   ProductPromotionDeleted: 10,
//   ProductPromotionUpdated: 11,
//   ProductRecordChange: 12,
//   TaskDeleted: 13,
//   TicketShared: 14,
//   UserRecordChange: 15,
//   RefundRequestResponse: 16,
//   UserFollow: 17,
//   CustomPriceOffer: 18,
//   ProductStarted: 19,
// };
export const USER_TYPE = {
  USER: 'user',
  VENDOR: 'vendor',
};

export const UPLOAD_STATUS = {
  PENDING: 0,
  PROCESSING: 1,
  DONE: 2,
};

export const NotificationType = {
  ActivityRecordChange:0,// user notified when a journey gets delete, suspend or reinstated by admin.
    ActivityParticipantAdded:1,// All participants of a journey will notified if a new participant added to this journey
    TaskAssigneeAdded:3,// A participant of a journey will notify if a task assigned on him
    MessageSent:5,// admin sending a message to a user
    NewOrder:6,// vendor will receive a notification if there is new booking request
    OrderConfirmed:7,// customer will receive a notification if order is confirmed by the vendor
    ADayBeforeBookingDate:8,// customer will receive notification a day before booking date
    ProductRecordChange:13,// user notified when a product gets delete, suspend or reinstated by admin.
    TicketShared:15,// User B will notified if User A shared a ticket with him
    DisputeRequest:17,// If  a user make a dispute request
    UserFollow:18,// A will notify if B wants to follow him.
    ProductStarted:20,// Vendor will notify if service is approved by admin.
    TicketRedeemed:22,// vendor will notify if a user redeemed a ticket
    OrderCancelled:23,// vendor will notify if booked order get cancelled
    ParticipantAccepted:25,// A user accepted a participant request, requested by the owner of the journey
    ParticipantRemoved:26,// A user will get notified, if he gets removed from a journey
    LocationAdded:28,// location added by a participant in a journey
    JourneyLiked:30,// All participants of a journey will notified a journey liked by any user in the platform
    CustomPriceOfferAdded:32,// vendor offering a user with a custom price
    TaskDueDate:33,// All participants of a journey will notified when a task due date is near by.
    VendorProfileActive:34,// user will notified when admin approved him as a vendro
    ParticipantRequest:36,// User A receive a notfication from a journy to become part of that journey.
    ParticipantRequestAccepted:37,// if owner of a journey accept ParticipantRequest then requester gets notify
    ActivityAdminAdded:38,// if owner of a journey accept ParticipantRequest then requester gets notify
    JourneyCreated:39,
    JourneyCommented:40
};

export const ParticipantStatus = {
  Pending: 0,
  Accepted: 1,
  Rejected: 2,
  Deleted: 3,
};

export const MessageStatus = {
  Created: 0,
  Edited: 1,
  Deleted: 2,
};

export const MessageType = {
  GroupUpdate: 0,
  GroupUpdateUserLeft: 1,
  GroupUpdateUserAdded: 2,
  Text: 3,
  Photo: 4,
  File: 5,
  GroupUpdateMemberRemoved: 6,
};

export const TaskAssigneeStatus = {
  Pending: 0,
  Accepted: 1,
  Rejected: 2,
  Deleted: 3,
};
export const Day = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
export const Month = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const TaskStatus = {
  NotCompleted: 0,
  Completed: 1,
};
export const TaskDueDateStatus = {
  OverDue: 0,
  AtRisk: 1,
  OnTrack: 2,
};

export const TicketStatus = {
  NotRedeemed: 0,
  Expired: 1,
  Redeemed: 2,
};

export const RecordStatus = {
  Removed: 0,
  Suspended: 1,
  Pending: 2,
  Inactive: 3,
  Active: 4,
  Deleted: 5,
  Draft: 6,
};

// export const TicketStatus = {
//   Redeemed: 0,
//   NotRedeemed: 1,
//   Expired: 2,
// };

// export const TaskType = {
//   Created: 0,
//   Booked: 0,
//   Wished: 0,
// };

export const JourneyPrivacy = {
  OnlyMe: 0,
  EveryOne: 1,
  MyCircle: 2,
  Custom: 3,
};
export const AccountPrivacyMode = {
  Private: 0,
  Public: 1,
};

export const Gender = {
  Male: 0,
  Female: 1,
  Other: 2,
};

export const OfferType = {
  None: 0,
  CustomPriceOffer: 1,
  EarlyBirdDiscount: 2,
  GroupDiscount: 3,
  DayDiscount: 4,
  SalesPromotion: 5,
};

export const CurrencySupported = {
  USD: 'usd',
  // AUD: 'aud',
  CAD: 'cad',
  // CNY: 'cny',
  // EUR: 'eur',
};
export const ThumbnailSuffix = '-thumb';

export const GalleryMaxSize = 7;

export const PhotoSuffix = '-xl';

export const PaymentStatus = {
  Charged: 0,
  Transferred: 1,
  Cancelled: 2,
  Disputed: 3, // when a dispute placed
  Refunded: 4,
};

export const PaymentStatus_reverse = {
  status_0: 'PendingTransfer',
  status_1: 'Transferred',
  status_2: 'Cancelled',
  status_3: 'Disputed', // when a dispute placed
  status_4: 'Refunded',
};

export const TagSubGroup = {
  Tag: 0,
  Challenge: 1,
  Mission: 2,
};
export const TagGroup = {
  Unspecified: 0,
  Location: 1,
};

export const TagType = {
  Tag: 0,
  Place: 1,
  ThingsToDo: 2,
  Interest: 3,
};
export const FileUploadFor = {
  Activity: 0,
  Product: 1,
  Tag: 2,
  Place: 3,
  Badge: 4,
};

export const PhotoSource = {
  UserUploaded: 0,
  GooglePlace: 1,
  GoogleUser: 2,
  FacebookUser: 3,
  UserUploadedPublicRecord: 4, // such as user uploaded an image for tag,
  GooglePlaceFullURL: 5,
  LocalStorage: 1000,
};

export const VideoSource = {
  UserUploaded: 0, // user uploaded this image and  document owned by him
  Youtube: 1,
};

export const PhotoSize = {
  Small: '-sm',
  Medium: '-me',
  Large: '-lr',
  XLarge: '-xlr',
};

export const MediaType = {
  Photo: 0,
  Video: 1,
};

export const TimelineType = {
  Create: 0, // Activity create, name of th journey, time of creation, owner, owner thumbnail,
  TaskCreate: 1, //task created  ,
  TagAdded: 2, //tag added
  ParticipantAdded: 3, //user added to journeys
  BudgetAdded: 4, // don't show on timeline
  EndDateAdded: 5,
  StartDateAdded: 6,
  FinishedAt: 7,
  PhotoAdded: 8,
  GuideAdded: 9,
  InspirerAdded: 10,
  InterestAdded: 11,
  OrderedProduct: 12,
  WishedProduct: 13,
  BadgeAdded: 14,
  Progress: 16,
};
export const TimelineProductEntryStatus = {
  AddedInWishList: 0,
  RequestSent: 1,
  Declined: 2,
  Confirmed: 3,
};

export const MessageUpdateRequestStatus = {
  // for front end
  Edited: 0,
  Deleted: 1,
  Seen: 2,
};

export const pageSize = {
  profileScreenActivitySize: 5,
};

export const FollowerFollowingStatus = {
  Pending: 0,
  Accepted: 1,
  Rejected: 2,
};

export const YesNo = {
  No: 0,
  Yes: 1,
};

export const ScoreFilter = {
  Location: 0,

  Tags: 1,

  LocationTags: 2,
};
export const FollowFollowing = {
  RquestSent: 0,
  Following: 1,
  Follow: undefined,
  UserFollowing: 1,
  UserFollow: undefined,
  Bloked: 1,
  NotBlocked: undefined,
};

export const ReportedDocumentType = {
  Journey: 0,
  Service: 1,
  Tag: 2,
  User: 3,
  Comments: 4,
};
export const ReportedContentType = {
  Document: 0,
  Timeline: 1,
  Photo: 2,
  UserProfilePhoto: 3,
  UserProfileBanner: 4,
};

export const UserUploadedImageType = {
  Profile: 0,
  Banner: 1,
  VendorIdFront: 2,
  VendorIdBack: 3,
  Badge: 4,
  Tag: 5,
};

export const VendorStatus = {
  NotRequested: 1,
  Requested: 2,
  Approved: 3,
  Rejected: 4,
};

export const FFUserAction = {
  Accepted: 0,
  Rejected: 1,
  Unfollowed: 2,
  Removed: 3,
  CancelRequest: 4,
};

export const FeedType = {
  ParticipantAdded: 0,
  TaskCreate: 1,
  LocationAdded: 2,
  WishedProduct: 3,
  OrderedProduct: 4,
  PhotoAdded: 5,
  GuideAdded: 6,
  InspirerAdded: 7,
  RecordCreate: 8,
};

export const RelationType = {
  User: 'user',
  Activity: 'activity',
  Promotion: 'promotion',
  ServiceCustomer: 'servcustomer',
  Service: 'service',
};

export const NotificationRelation = {
  User: 0,
  Journey: 1,
  Service: 2,
};

export const feedSourceType = {
  Journey: 0,
  Service: 1,
};

export const FeedFilter = {
  Trending: 0,
  MyCircle: 1,
  SuccessStory: 2,
};
export const NotificationFilter = {
  FollowRrquests: 0,
  JrnyInvites: 1,
  Others: 2,
};
export const FeedCategoryFilter = {
  Feed: 0,
  Story: 1,
};

export const UserContentFilter = {
  GetLikesOfAJourney: 0,
  GetRatingsOfAService: 1,
  GetParticipantsOfAJourney: 2,
  GetCommentsOfAJourney: 3,
  GetInspiresOfAJourney: 4,
  GetLikesOfAComment: 5,
  GetSubCommentsOfAComment: 6,
  GetLikesOfASubComment: 7,
  GetSubCommentsOfASubComment: 8,
  GetLikesOfASubSubComment: 9,
  GetUsers: 10,
};
export const UserFilter = {
  // get followers of a user
  Follower: 0,
  // get followings of a user
  Following: 1,
  // get inspires of a user
  Inspire: 2,
  //when a user click on add inspirer button, use this filter, this filter will send information
  //along if a user already added along with that user info
  GetUsersForAddingInspirer: 3,
  Participant: 4,
};

export const CreateFor = {
  Journey: 0,
  Service: 1,
};

export const NtfAction = {
  OpenUserProfile: 0,
  OpenJourney: 1,
  OpenService: 2,
  OpenServiceViewCustomOffer: 3,
  OpenLikeList: 4,
  OpenChat: 5,
  OpenJourneyTickets: 6,
  OpenCommentList: 7,
};

export const Duration = [
  {
    min: 0,
    max: 60,
  },
  {
    min: 60,
    max: 120,
  },
  {
    min: 120,
    max: 180,
  },
  {
    min: 1440,
    max: 1440,
  },
  {
    min: 1440,
    max: undefined,
  },
];

export const TimeOfDay = [
  {
    startTime: 600,
    endTime: 1200,
  },
  {
    startTime: 1200,
    endTime: 1700,
  },
  {
    startTime: 1700,
    endTime: 2400,
  },
];
