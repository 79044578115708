import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tagPaginationOffset } from "../../../res/dimens";
import { baseUrl } from "../../../environments/proxyUrl";
import {
  parse_SourceArray,
  parse_SourceObjectWithInnerHits_Leads,
  parse_userListObjectWithInnerHits_fofo,
} from "./elasticParser";
import {
  UserUploadedImageType,
  YesNo,
} from "../../../components/utils/subjects";
import showToastMessage from "../../../components/utils/toast";

// export const postBusinessProfile = createAsyncThunk(
//   'auth/businessProfile',
//   async ({data, token}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.post(
//         `${baseUrl}/api/users/v1/business/profile/`,
//         data,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

// export const postPaymentProfile = createAsyncThunk(
//   'auth/paymentProfile',
//   async ({data, token}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.post(
//         `${baseUrl}/api/users/v1/payment/profile/`,
//         data,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async ({ data, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/user`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "auth/deleteUser",
  async ({ data, id, callback, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/users/v1/user/`,
        {
          params: data,
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const userSignup = createAsyncThunk(
  "auth/userSignup",
  async ({ data, successCallback, rejectCallback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/user`,
        data
      );
      if (successCallback) {
        successCallback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      if (rejectCallback) {
        rejectCallback(err.response.data);
      }
      return rejectWithValue(err.response.data);
    }
  }
);
//TODO: do not exist on server side
export const partnerSignup = createAsyncThunk(
  "auth/partnerSignup",
  async ({ formData }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/signup/partner`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data;`,
          },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNotificationCount = createAsyncThunk(
  "auth/getNotificationCount",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/sockets/v1/app-meta`,
        {
          headers: { Authorization: token },
        }
      );
      console.log("Get Notification", res);
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

// export const userFacebookSignin = createAsyncThunk(
//   'auth/userFacebookSignin',
//   async (data, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.post(
//         `${baseUrl}/api/users/v1/signin-fb`,
//         data,
//       );
//       console.log('FbSignInResponse', res);
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

// export const userGoogleSignin = createAsyncThunk(
//   'auth/userGoogleSignin',
//   async ({data, callback}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.post(
//         `${baseUrl}/api/users/v1/signin-gg`,
//         data,
//       );
//       if (callback) {
//         callback(res.results);
//       }
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
//TODO: do not exist on server side
// export const partnerGoogleSignin = createAsyncThunk(
//   'auth/partnerGoogleSignin',
//   async (data, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.post(
//         `${baseUrl}/api/users/v1/signin/partner/google`,
//         data,
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
export const addGoogleAccount = createAsyncThunk(
  "auth/addGoogleAccount",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/add-google`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
//TODO: do not exist on server side
// export const partnerFacebookSignin = createAsyncThunk(
//   'auth/partnerFacebookSignin',
//   async (data, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.post(
//         `${baseUrl}/api/users/v1/signin/partner/facebook`,
//         data,
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const userVerification = createAsyncThunk(
  "auth/userVerification",
  async (
    { data, id, successCallback, rejectCallback },
    { rejectWithValue }
  ) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/otp/${id}`,
        data,
        {
          headers: {},
        }
      );
      if (successCallback) {
        successCallback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      if (rejectCallback) {
        rejectCallback(err.response.data);
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkOtp = createAsyncThunk(
  "auth/checkOtp",
  async (
    { data, id, successCallback, rejectCallback },
    { rejectWithValue }
  ) => {
    console.log("Check Otp", data);
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/users/v1/otp/`,

        {
          params: data,
        }
      );

      if (successCallback) {
        successCallback(res);
      }
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      if (rejectCallback) {
        rejectCallback(err.response.data);
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const emailOtpSend = createAsyncThunk(
  "auth/emailOtpSend",
  async ({ data, callback }, { rejectWithValue }) => {
    try {
      console.log("Data", data);
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/otp`,
        data
      );
      if (callback) {
        callback();
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePasswordByEmail = createAsyncThunk(
  "auth/forgotPassword",
  async ({ data, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/password`,
        data
      );
      if (callback) {
        callback();
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/password`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const changeEmail = createAsyncThunk(
  "auth/changeEmail",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      console.log("data", data);
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/email/`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("res", res);
      return res.results;
    } catch (err) {
      if (!err.response) {
        console.log("eeres", err);
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const resendCode = createAsyncThunk(
  "auth/resendCode",
  async ({ data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/otp/`,
        data,
        {
          headers: {},
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const uploadUserPhoto = createAsyncThunk(
  "auth/uploadUserPhoto",
  async (
    { formData, token, vendorID, UserUploadedImageType },
    { rejectWithValue }
  ) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/worker/v1/img-uploader`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data;`,
            Authorization: token,
          },
        }
      );
      console.log("UserPhoto Upload", res);
      return {
        ...res.results,
        vendorID: vendorID,
        UserUploadedImageType: UserUploadedImageType,
      };
    } catch (err) {
      if (!err) {
        throw err;
      }
      console.log("uploadUserPhoto error", err);
      return rejectWithValue(err);
    }
  }
);
export const uploadVendorDocuments = createAsyncThunk(
  "auth/uploadVendorDocuments",
  async ({ formData, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/worker/v1/document`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data;`,
            Authorization: token,
          },
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err) {
        throw err;
      }
      console.log("errrrr", err);
      return rejectWithValue(err);
    }
  }
);

export const uploadVendorBusinessReg = createAsyncThunk(
  "auth/uploadVendorDocuments",
  async ({ formData, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/worker/v1/document`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data;`,
            Authorization: token,
          },
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err) {
        throw err;
      }
      console.log("error uploadVendorBusinessReg", err);
      return rejectWithValue(err);
    }
  }
);

export const uploadShopPhoto = createAsyncThunk(
  "auth/uploadShopPhoto",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/worker/v1/shop-image-uploader`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data;`,
            Authorization: token,
          },
        }
      );

      return res.results;
    } catch (err) {
      if (!err) {
        throw err;
      }
      console.log("errrrr", err);
      return rejectWithValue(err);
    }
  }
);

export const signin = createAsyncThunk(
  "auth/signin",
  async (data, { rejectWithValue }) => {
    const { callback, errorMessage, ...rest } = data;
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/signin`,
        rest
      );
      console.log("RES-----", res);
      if (callback) {
        callback(res.results);
      }

      return res.results;
    } catch (err) {
      console.log("Error Signin", err.response.data);
      if (errorMessage) {
        errorMessage(err);
      }
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const signout = createAsyncThunk(
  "auth/signout",
  async ({ data, callback, token, errorMessage }, { rejectWithValue }) => {
    console.log("Singout");
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/signin`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      console.log("RES-----", res);
      if (callback) {
        callback(res.results);
      }

      return res.results;
    } catch (err) {
      console.log("Error signout", err.response.data);
      if (errorMessage) {
        errorMessage(err);
      }
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const emailChangeRequest = createAsyncThunk(
  "auth/EmailChangeRequest",
  async ({ data, token, callback, errorMessage }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/email`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("RES-----", res);
      if (callback) {
        callback(res.results);
      }

      return res.results;
    } catch (err) {
      console.log("Error Signin", err.response.data);
      if (errorMessage) {
        errorMessage(err);
      }
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const emailChange = createAsyncThunk(
  "auth/EmailChange",
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/email/${id}`,
        data
      );
      if (callback) {
        callback();
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  'auth/getUsers',
  async ({data, token, callback}, {rejectWithValue}) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/user`, {
        params: data,
        headers: {Authorization: token},
      });
      console.log('raw data getUsers', response);
      const users = await parse_userListObjectWithInnerHits_fofo(
        response.data.results,
      );
      if (callback) {
        callback(users);
      }
      return {
        size: data.size,
        from: data.from,
        users,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMoreUsers = createAsyncThunk(
  "auth/getMoreUsers",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/users`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      return {
        // contentRange: response.headers['content-range'],
        size: data.size,
        from: data.from,
        users: await parse_SourceArray(response.data.results),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
//TODO: do not exist on server side
export const getGuides = createAsyncThunk(
  "auth/getGuides",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/guides/`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      // const users = await parse_SourceArray(response.data.results)
      return {
        // contentRange: response.headers['content-range'],
        size: data.size,
        from: data.from,
        users: await parse_SourceArray(response.data.results),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
//TODO: do not exist on server side

export const getMoreGuideUsers = createAsyncThunk(
  "auth/getMoreGuideUsers",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/guides/`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      return {
        // contentRange: response.headers['content-range'],
        size: data.size,
        from: data.from,
        users: await parse_SourceArray(response.data.results),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getIdenticalUsers = createAsyncThunk(
  "auth/getIdenticalUsers",
  async ({ data, token, serviceId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/search/v1/s-users/`,

        { params: { ...data, serviceId }, headers: { Authorization: token } }
      );
      // const users = await parse_SourceArray(response.data.results)
      console.log("response redux", response.data);
      return {
        // contentRange: response.headers['content-range'],
        size: data.size,
        from: data.from,
        users: await parse_SourceObjectWithInnerHits_Leads(response.data),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMoreIdenticalUsers = createAsyncThunk(
  "auth/getMoreIdenticalUsers",
  async ({ data, token, serviceId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/s-users`, {
        params: { ...data, serviceId },
        headers: { Authorization: token },
      });
      return {
        // contentRange: response.headers['content-range'],
        size: data.size,
        from: data.from,
        users: await parse_SourceArray(response.data.results),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
// export const getSuggestedUsers = createAsyncThunk(
//   'activities/getSuggestedUsers',
//   async ({data, token}, {rejectWithValue}) => {
//     try {
//       const response = await axios.get(
//         `${baseUrl}/api/search/v1/users/suggested`,
//         {
//           params: {...data},
//           headers: {Authorization: token},
//         },
//       );
//       return {
//         // contentRange: response.headers['content-range'],
//         size: data.size,
//         from: data.from,
//         users: await parse_SourceArray(response.data.results),
//       };
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
//TODO: do not exist on server side
export const getMoreSuggestedUsers = createAsyncThunk(
  "activities/getMoreSuggestedUsers",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/search/v1/users-suggested`,
        {
          params: { ...data },
          headers: { Authorization: token },
        }
      );
      return {
        // contentRange: response.headers['content-range'],
        size: data.size,
        from: data.from,
        users: await parse_SourceArray(response.data.results),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const checkAuthorizedUser = createAsyncThunk(
  "activities/checkAuthorizedUser",
  async ({ token, callback }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/users/v1/token`, {
        headers: { Authorization: token },
      });
      return response;
    } catch (err) {
      if (callback) {
        callback(err.response);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

// export const becomeVendor = createAsyncThunk(
//   'auth/becomeVendor',
//   async ({data, token}, {rejectWithValue}) => {
//     const {callback, ...rest} = data;
//     try {
//       const {data: res} = await axios.post(
//         `${baseUrl}/api/users/v1/update/bvendor`,
//         {},
//         {
//           headers: {
//             Authorization: token,
//           },
//         },
//       );

//       if (callback) {
//         callback(res.results);
//       }
//       console.log('becomeVendor RES-----', res);
//       return res.results;
//     } catch (err) {
//       console.log('becomeVendor error', err);

//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
export const switchToUser = createAsyncThunk(
  "auth/switchToUser",
  async ({ data, token }, { rejectWithValue }) => {
    const { callback, ...rest } = data;
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/users/v1/switch-account`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (callback) {
        callback(res.results);
      }
      console.log("becomeVendor RES-----", res);
      return res.results;
    } catch (err) {
      console.log("becomeVendor error", err);

      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const getUserFilters = createAsyncThunk(
  "profile/filters",
  async ({ token }, { rejectWithValue }) => {
    console.log("get feeds filter2");

    return {
      filters: [
        {
          name: "all",
          label: "all",
        },
        {
          name: "mutual",
          label: "mutual",
        },
        {
          name: "following",
          label: "following",
        },
        {
          name: "followers",
          label: "followers",
        },
        {
          name: "inspired by",
          label: "inspired by",
        },
        {
          name: "inspired",
          label: "inspired",
        },
      ],
    };
  }
);
export const likeAnActivity = createAsyncThunk(
  "auth/likeAnActivity",
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/activities/v1/like/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback();
      }
      return {
        operator: data.operator,
        id: res.results.id,
        likes: res.results.likes,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const changePrivacySettings = createAsyncThunk(
  "auth/changePrivacySettings",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/privacy`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const accountPrivatePublic = createAsyncThunk(
  "auth/accountPrivatePublic",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/users/v1/acc-privacy`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
// export const changeAccountPrivacySettings = createAsyncThunk(
//   'auth/changeAccountPrivacySettings',
//   async ({data, token}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/users/v1/account/privacy-update`,
//         data,
//         {
//           headers: {Authorization: token},
//         },
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const vendorProfile_basic_information = createAsyncThunk(
  "auth/vendorProfile_basic_information",
  async ({ data, token, callback, errorHandle }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/users/v1/v-profile/`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(response.data.results);
      }
      return response.data.results;
    } catch (err) {
      if (errorHandle) {
        errorHandle(err);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: {},
    tabHostProperty: {
      enableTabHost: false,
    },
    errors: [],
    loading: false,
    uploadingPhoto: false,
    contentRangeIdenticalUsers: undefined,
    contentRangeGuideUsers: undefined,
    contentRangeUsers: undefined,
    contentRangeSuggestedUsers: undefined,
    suggestedUsers: [],
    users: [],
    guides: [],
    identicalUsers: [],
    productUsers: [],
    filters: [],
    likes: [],
    loadingUser: undefined,
    userType: undefined,
    firstTimeLogIn: false,
    messageCount: "",
    notificationCount: "",
  },
  reducers: {
    changePushNotificationStatus: (state, action) => {
      state.auth.user.pushNotify = action.payload.status;
    },

    resetErrorByField: (state, action) => {
      state.errors = state.errors.filter(function (err) {
        return err.field !== action.payload.field;
      });

      console.log("errors....***", state.errors);
    },

    restNotificationCount: (state, action) => {
      if (action?.payload?.notification != undefined) {
        state.notificationCount =
          state.notificationCount + action?.payload?.notification;
      }

      if (action?.payload?.message != undefined) {
        console.log(
          "Notification COunt",
          action?.payload?.notification,
          action?.payload?.message
        );
        state.messageCount = state.messageCount + action?.payload?.message;
      }
    },
    resetErrors: (state, action) => {
      state.errors = [];
    },
    resetUsers: (state, action) => {
      state.users = [];
    },
    setTabHostProperty: (state, action) => {
      const { enableTabHost } = action.payload;
      state.tabHostProperty = { enableTabHost };
    },
    logOut: (state, action) => {
      console.log("logout from auth slice");
      state.auth = {};
      state.tabHostProperty = { enableTabHost: false };
      state.errors = [];
    },
    updateUser: (state, action) => {
      const { user } = action.payload;
      let array = state.users.map((item, index) => {
        if (item.id === user.user.id) {
          return { ...item, ...user.user };
        } else {
          return item;
        }
      });
      state.users = array;
    },
    getFirstTimeLogIn: (state, action) => {
      console.log("called getFirstTimeLogIn", action);
      state.firstTimeLogIn = action.payload.value;
      state.errors = [];
    },
  },

  extraReducers: {
    [getNotificationCount.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getNotificationCount.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      console.log("ActionPayloadUnseen", action.payload);
      state.messageCount = action.payload.messages.unseen;
      state.notificationCount = action.payload.notification.unseen;
    },
    [getNotificationCount.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [changePrivacySettings.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [changePrivacySettings.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      console.log(" action.payload.privacy", action.payload);
      state.auth.privacy = action.payload.privacy;
    },
    [changePrivacySettings.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },

    [accountPrivatePublic.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [accountPrivatePublic.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      state.auth.user.accountPrivacy = action.payload.accountPrivacy;
    },
    [accountPrivatePublic.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },

    // [changeAccountPrivacySettings.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [changeAccountPrivacySettings.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.loading = false;
    //   console.log(' action.payload.privacy', action.payload);
    //   state.auth.account = action.payload.account;
    // },
    // [changeAccountPrivacySettings.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload.errors;
    // },

    [addGoogleAccount.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [addGoogleAccount.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      console.log("add google account payload", action.payload);
      state.auth = {
        user: {
          ...state.auth.user,
          ...action.payload,
        },
        token: state.auth.token,
      };
    },
    [addGoogleAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [updatePasswordByEmail.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updatePasswordByEmail.fulfilled]: (state, action) => {
      state.auth = action.payload;
      if (state.auth.user.lastRole && state.auth.user.lastRole == 50) {
        state.userType = "vendor";
      } else if (state.auth.user.lastRole && state.auth.user.lastRole == 100) {
        state.userType = "user";
      }
      state.loading = false;
    },
    [updatePasswordByEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [checkOtp.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [checkOtp.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
    },
    [checkOtp.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [emailOtpSend.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [emailOtpSend.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
    },
    [emailOtpSend.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [changePassword.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
    },
    [changePassword.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [changeEmail.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [changeEmail.fulfilled]: (state, action) => {
      state.errors = [];
      state.auth = action.payload;
      state.loading = false;
    },
    [changeEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [userVerification.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [userVerification.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload.user.lastRole && action.payload.user.lastRole == 50) {
        state.userType = "vendor";
      } else if (
        action.payload.user.lastRole &&
        action.payload.user.lastRole == 100
      ) {
        state.userType = "user";
      }
      state.auth = action.payload;
      state.loading = false;
    },
    [userVerification.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [resendCode.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [resendCode.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
    },
    [resendCode.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },

    [partnerSignup.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [partnerSignup.fulfilled]: (state, action) => {
      state.errors = [];
      state.auth = action.payload;
      state.loading = false;
    },
    [partnerSignup.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
      state.auth = {};
    },
    [deleteUser.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.errors = [];
      // state.auth = {};
      state.loading = false;
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [userSignup.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [userSignup.fulfilled]: (state, action) => {
      state.errors = [];
      state.auth = action.payload;
      if (action.payload.user.lastRole && action.payload.user.lastRole == 50) {
        state.userType = "vendor";
      } else if (
        action.payload.user.lastRole &&
        action.payload.user.lastRole == 100
      ) {
        state.userType = "user";
      }
      state.loading = false;
    },
    [userSignup.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
      state.auth = {};
    },
    // [userGoogleSignin.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [userGoogleSignin.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.auth = action.payload;
    //   if (action.payload.user.lastRole && action.payload.user.lastRole == 50) {
    //     state.userType = 'vendor';
    //   } else if (
    //     action.payload.user.lastRole &&
    //     action.payload.user.lastRole == 100
    //   ) {
    //     state.userType = 'user';
    //   }
    //   state.loading = false;
    // },
    // [userGoogleSignin.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload.errors;
    //   state.auth = {};
    // },

    // [partnerGoogleSignin.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [partnerGoogleSignin.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.auth = action.payload;
    //   state.loading = false;
    // },
    // [partnerGoogleSignin.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload.errors;
    //   state.auth = {};
    // },

    // [userFacebookSignin.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [userFacebookSignin.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.auth = action.payload;
    //   if (action.payload.user.lastRole && action.payload.user.lastRole == 50) {
    //     state.userType = 'vendor';
    //   } else if (
    //     action.payload.user.lastRole &&
    //     action.payload.user.lastRole == 100
    //   ) {
    //     state.userType = 'user';
    //   }
    //   state.loading = false;
    // },
    // [userFacebookSignin.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload.errors;
    //   state.auth = {};
    // },
    // [partnerFacebookSignin.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [partnerFacebookSignin.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.auth = action.payload;
    //   state.loading = false;
    // },
    // [partnerFacebookSignin.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload.errors;
    //   state.auth = {};
    // },
    [uploadUserPhoto.pending]: (state) => {
      state.errors = [];
      state.uploadingPhoto = true;
    },
    [uploadUserPhoto.fulfilled]: (state, action) => {
      state.errors = [];
      // state.auth = action.payload.auth;
      state.uploadingPhoto = false;

      if (state.auth.user.id == action.payload.id && !action.payload.vendorID) {
        if (action.payload.banner) {
          state.auth.user.banner = action.payload.banner;
        }
        if (action.payload.thumbnail) {
          state.auth.user.thumbnail = action.payload.thumbnail;
        }
        if (action.payload.photo) {
          state.auth.user.photo = action.payload.photo;
        }
      } else {
        if (
          action.payload.UserUploadedImageType ==
          UserUploadedImageType.VendorIdFront
        ) {
          state.auth.user.vendorProfile.idFront = action.payload.thumbnail;
        } else if (
          action.payload.UserUploadedImageType ==
          UserUploadedImageType.VendorIdBack
        ) {
          state.auth.user.vendorProfile.idBack = action.payload.thumbnail;
        }
      }
    },
    [uploadUserPhoto.rejected]: (state, action) => {
      state.uploadingPhoto = false;
      state.errors = action.payload.errors;
    },
    [uploadShopPhoto.pending]: (state) => {
      state.errors = [];
      state.uploadingPhoto = true;
    },
    [uploadShopPhoto.fulfilled]: (state, action) => {
      state.errors = [];
      const { thumbnail } = action.payload;
      const shop = state.auth.user.shop;
      state.auth.user.shop = { ...shop, thumbnail };
      state.uploadingPhoto = false;
      console.log("shop image auth", state.auth);
    },
    [uploadShopPhoto.rejected]: (state, action) => {
      state.uploadingPhoto = false;
      state.errors = action.payload.errors;
    },
    [signin.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [signin.fulfilled]: (state, action) => {
      state.errors = [];
      state.auth = action.payload;
      state.loading = false;
      if (state.auth.user.lastRole && state.auth.user.lastRole == 50) {
        state.userType = "vendor";
      } else if (state.auth.user.lastRole && state.auth.user.lastRole == 100) {
        state.userType = "user";
      }
    },
    [signin.rejected]: (state, action) => {
      state.loading = false;
      console.log("Singin Errors", action.payload?.errors);
      state.errors = action.payload?.errors;
      state.auth = {};
    },
    [signout.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [signout.fulfilled]: (state, action) => {
      state.auth = {};
    },
    [signout.rejected]: (state, action) => {
      state.loading = false;
      console.log("Singin Errors", action.payload?.errors);
      state.errors = action.payload?.errors;
      state.auth = {};
    },
    [emailChangeRequest.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [emailChangeRequest.fulfilled]: (state, action) => {
      state.errors = [];
    },
    [emailChangeRequest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors;
    },
    [emailChange.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [emailChange.fulfilled]: (state, action) => {
      state.errors = [];
      state.auth = action.payload;
      state.loading = false;
      if (state.auth.user.lastRole && state.auth.user.lastRole == 50) {
        state.userType = "vendor";
      } else if (state.auth.user.lastRole && state.auth.user.lastRole == 100) {
        state.userType = "user";
      }
    },
    [emailChange.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors;
    },

    // [postBusinessProfile.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [postBusinessProfile.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.loading = false;
    // },
    // [postBusinessProfile.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload.errors;
    // },
    // [postPaymentProfile.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [postPaymentProfile.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.loading = false;
    //   state.auth = {
    //     ...state.auth,
    //     user: {
    //       ...state.auth.user,
    //       pConfig: 1, // after submiting payment profile our auth service have a value of 1 for pConfig, syncing with auth service.
    //     },
    //   };
    // },
    // [postPaymentProfile.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload.errors;
    //   console.log('errors..', state.errors);
    // },
    [updateProfile.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      state.auth = {
        ...state.auth,
        user: {
          ...state.auth.user,
          ...action.payload,
        },
      };
    },
    [updateProfile.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [getUsers.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      //state.users = [];
      state.contentRangeUsers = undefined;
      state.loadingUser = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loadingUser = false;
      state.errors = [];
      state.users = action.payload.users;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.users.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeUsers = `${from}-${sum}/false`;
      } else {
        state.contentRangeUsers = `${from}-${sum}/true`;
      }
      state.loading = false;
    },
    [getUsers.rejected]: (state, action) => {
      state.loadingUser = false;
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreUsers.pending]: (state) => {
      state.loading = true;
      state.errors = [];
      state.contentRangeUsers = undefined;
    },
    [getMoreUsers.fulfilled]: (state, action) => {
      state.loading = false;
      const aUsers = state.users.concat(action.payload.users);
      state.users = aUsers;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.users.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeUsers = `${from}-${sum}/false`;
      } else {
        state.contentRangeUsers = `${from}-${sum}/true`;
      }
    },
    [getMoreUsers.rejected]: (state, action) => {
      state.loading = false;
    },

    [getGuides.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.guides = [];
      state.contentRangeGuideUsers = undefined;
    },
    [getGuides.fulfilled]: (state, action) => {
      state.errors = [];
      state.guides = action.payload.users;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.users.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeGuideUsers = `${from}-${sum}/false`;
      } else {
        state.contentRangeGuideUsers = `${from}-${sum}/true`;
      }
      state.loading = false;
    },
    [getGuides.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreGuideUsers.pending]: (state) => {
      state.loading = true;
      state.errors = [];
      state.contentRangeGuideUsers = undefined;
    },
    [getMoreGuideUsers.fulfilled]: (state, action) => {
      state.loading = false;
      const aUsers = state.users.concat(action.payload.users);
      state.guides = aUsers;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.users.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeGuideUsers = `${from}-${sum}/false`;
      } else {
        state.contentRangeGuideUsers = `${from}-${sum}/true`;
      }
    },
    [getMoreGuideUsers.rejected]: (state, action) => {
      state.loading = false;
    },

    [getIdenticalUsers.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.identicalUsers = [];
      state.contentRangeIdenticalUsers = undefined;
    },
    [getIdenticalUsers.fulfilled]: (state, action) => {
      state.errors = [];
      state.identicalUsers = action.payload.users;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.users.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeIdenticalUsers = `${from}-${sum}/false`;
      } else {
        state.contentRangeIdenticalUsers = `${from}-${sum}/true`;
      }
      state.loading = false;
    },
    [getIdenticalUsers.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreIdenticalUsers.pending]: (state) => {
      state.loading = true;
      state.errors = [];
      state.contentRangeIdenticalUsers = undefined;
    },
    [getMoreIdenticalUsers.fulfilled]: (state, action) => {
      state.loading = false;
      const aUsers = state.users.concat(action.payload.users);
      state.identicalUsers = aUsers;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.users.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeIdenticalUsers = `${from}-${sum}/false`;
      } else {
        state.contentRangeIdenticalUsers = `${from}-${sum}/true`;
      }
    },
    [vendorProfile_basic_information.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [vendorProfile_basic_information.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [vendorProfile_basic_information.fulfilled]: (state, action) => {
      state.loading = false;
      state.auth.user.vendorProfile = action.payload.vendorProfile;
    },
    [getMoreIdenticalUsers.rejected]: (state, action) => {
      state.loading = false;
    },
    // [getSuggestedUsers.pending]: state => {
    //   state.loading = true;
    //   state.errors = [];
    //   state.suggestedUsers = [];
    // },
    // [getSuggestedUsers.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.suggestedUsers = action.payload.users;
    //   let size = action.payload.size;
    //   let from = action.payload.from;
    //   let aSize = action.payload.users.length;
    //   let sum = parseInt(size) + parseInt(from);
    //   if (aSize < size) {
    //     state.contentRangeSuggestedUsers = `${from}-${sum}/false`;
    //   } else {
    //     state.contentRangeSuggestedUsers = `${from}-${sum}/true`;
    //   }
    // },
    // [getSuggestedUsers.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [getMoreSuggestedUsers.pending]: state => {
    //   state.loading = true;
    //   state.errors = [];
    // },
    // [getMoreSuggestedUsers.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   if (state.suggestedUsers) {
    //     const suggestedUsers = state.suggestedUsers.concat(
    //       action.payload.users,
    //     );
    //     state.suggestedUsers = suggestedUsers;
    //   } else {
    //     state.suggestedUsers = action.payload.user;
    //   }
    //   let size = action.payload.size;
    //   let from = action.payload.from;
    //   let aSize = action.payload.users.length;
    //   let sum = parseInt(size) + parseInt(from);
    //   if (aSize < size) {
    //     state.contentRangeSuggestedUsers = `${from}-${sum}/false`;
    //   } else {
    //     state.contentRangeSuggestedUsers = `${from}-${sum}/true`;
    //   }
    // },
    // [getMoreSuggestedUsers.rejected]: (state, action) => {
    //   state.loading = false;
    // },
    [checkAuthorizedUser.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [checkAuthorizedUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [checkAuthorizedUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [getUserFilters.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getUserFilters.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload.filters) {
        state.filters = action.payload.filters;
      }
      state.loading = false;
    },
    [getUserFilters.rejected]: (state, action) => {
      state.loading = false;
    },
    // [becomeVendor.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    // },
    // [becomeVendor.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   state.auth = action.payload;
    //   state.loading = false;
    //   if (action.payload.user.lastRole && action.payload.user.lastRole == 50) {
    //     state.userType = 'vendor';
    //   } else if (
    //     action.payload.user.lastRole &&
    //     action.payload.user.lastRole == 100
    //   ) {
    //     state.userType = 'user';
    //   }
    // },
    // [becomeVendor.rejected]: (state, action) => {
    //   state.loading = false;
    // },
    [switchToUser.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [switchToUser.fulfilled]: (state, action) => {
      state.errors = [];
      state.auth = action.payload;
      state.loading = false;
      if (action.payload.user.lastRole && action.payload.user.lastRole == 50) {
        state.userType = "vendor";
      } else if (
        action.payload.user.lastRole &&
        action.payload.user.lastRole == 100
      ) {
        state.userType = "user";
      }
    },
    [switchToUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [likeAnActivity.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [likeAnActivity.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload.operator == -1) {
        if (state.auth.user.likes) {
          let likes = state.auth.user.likes.filter(
            (l) => l != action.payload.id
          );
          state.auth.user.likes = likes;
        }
      } else {
        if (state.auth.user.likes) {
          let likes = state.auth.user.likes;
          likes.push(action.payload.id);
          state.auth.user.likes = likes;
        } else {
          state.auth.user.likes = [action.payload.id];
        }
      }
      state.loading = false;
    },
    [likeAnActivity.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  changePushNotificationStatus,
  resetErrors,
  resetErrorByField,
  setTabHostProperty,
  logOut,
  updateUser,
  getFirstTimeLogIn,
  restNotificationCount,
} = authSlice.actions;

export default authSlice.reducer;

export const authSelector = (state) => state.auth;
