import { io } from "socket.io-client";
export let socketGlobal;
export let isSocketConnected = false;
export const configureSocket = (auth) => {
  console.log("auth-socket---", auth, process.env.REACT_APP_BASE_URL);
  const socket = io(process.env.REACT_APP_BASE_URL, {
    query: {
      id: auth.user.id,
      sk: auth.user.sk,
      createdAt: auth.user.createdAt,
    },
    path: "/api/sockets/socket.io",
    transports: ["websocket"],
    withCredentials: true,
  });
  socket.on("connect", (message) => {
    console.log("socket connected", message);
    isSocketConnected = true;
  });
  socketGlobal = socket;
  return socket;
};
