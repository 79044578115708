import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { getJourneyNavOption } from '../../utils/get-nav-option';
import { bookingSelector } from '../../redux/slices/mobile/bookingSlice';
import { useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';

const CompletePaymentModal = ({
  paymentCompleteModal,
  paymentCompleteModalHandle,
}) => {
  const { purchasedActivity } = useSelector(bookingSelector);
  const { auth } = useSelector(authSelector);
  console.log('Auth Info', auth);
  let navigate = useNavigate();

  return (
    <Popup
      open={paymentCompleteModal}
      closeOnDocumentClick
      onClose={paymentCompleteModalHandle}
      contentStyle={{
        maxWidth: '520px',

        borderRadius: '20px',
        padding: '50px',
      }}
    >
      <div className='flex flex-col items-center  justify-between gap-10'>
        <h3>Payment Successful!</h3>
        <h3>Thank you!</h3>
        <button
          onClick={() =>
            navigate(
              getJourneyNavOption({
                id: purchasedActivity.id,
                sk: purchasedActivity.sk,
                createdAt: purchasedActivity.createdAt,
                name: purchasedActivity.name,
                user: auth.user,
              })
            )
          }
          className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
        >
          see my reservation
        </button>
      </div>
    </Popup>
  );
};

export default CompletePaymentModal;
