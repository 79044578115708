import React, { useEffect, useState } from 'react';
import Thumbnail from '../thumbnails/Thumbnail';
import Moment from 'react-moment';
import { PhotoSize, YesNo } from '../utils/subjects';
import { getUnseenChats } from '../utils/static/global_functions';
import { restNotificationCount } from '../../redux/slices/mobile/authSlice';
import { useDispatch } from 'react-redux';

const ChatListItem = ({
  setIsEnd,
  showChatRbSheetHandle,
  getMessageHandle,
  item,
  isSeen,
  auth,
  selectedChat,
}) => {
  const [members, setMembers] = useState([]);
  const [isMessageSeen, setIsMessageSeen] = useState(isSeen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (item && item.members) {
      let chatMembers = item.members.filter(
        (item) => item.id !== auth?.user?.id
      );
      let OnlyMembers = chatMembers.filter((item) => item.removed != YesNo.Yes);
      //   if (chatMembers.length > 1) {
      //     chatMembers = chatMembers.filter(
      //       (item) => item.removed == 0 && item.recordStatus == 1
      //     );
      //     setMembers(chatMembers);
      //   }
      setMembers(OnlyMembers);
      console.log('SeenCount', item.isSeen);
      if (item.isSeen) {
        setIsMessageSeen(true);
      } else {
        setIsMessageSeen(false);
      }
    }
  }, [item]);

  return (
    <div
      className={`${
        selectedChat?.id == item?.id
          ? 'min-[767px]:bg-jrnyfy-colorLightGray'
          : 'min-[767px]:hover:bg-jrnyfy-colorLightGray'
      } p-2 rounded-md`}
    >
      {item?.members?.removed != 0 &&
        (item.group == 0 ? (
          <div
            onClick={() => {
              dispatch(
                restNotificationCount({ message: -item?.isSeen?.count })
              );
              setIsEnd(false);
              setIsMessageSeen(false);
              getMessageHandle(item);
              showChatRbSheetHandle(true);
            }}
            className='flex justify-between items-center cursor-pointer'
          >
            <div className='flex items-center gap-3'>
              <Thumbnail
                isRedirect={false}
                btnFollow={false}
                activeIcon={true}
                activeIconStatus={members[0]?.connected}
                data={members[0]}
                PhotoSize={PhotoSize.Small}
              />
              <div>
                <p className={`${!isMessageSeen && 'text-jrnyfy-textprimary'}`}>
                  {members[0]?.name}
                </p>
                <p
                  className={`${
                    !isMessageSeen
                      ? 'text-jrnyfy-textprimary'
                      : 'text-jrnyfy-themeColor'
                  } text-[14px] truncate ... max-w-[150px]`}
                >
                  {item.message}
                </p>
              </div>
            </div>
            <div className='flex flex-col items-end'>
              <p
                className={`text-[12px] font-semibold text-end ${
                  !isMessageSeen && 'text-jrnyfy-textprimary'
                }`}
              >
                <Moment fromNow>{item.updatedAt}</Moment>
              </p>
              <p
                className={`text-[12px] font-semibold ${
                  !isMessageSeen && 'text-jrnyfy-textprimary'
                }`}
              >
                {members[0]?.connected ? 'Active' : 'Offline'}
              </p>
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              getMessageHandle(item);
              showChatRbSheetHandle(true);
            }}
            className='flex justify-between items-center cursor-pointer'
          >
            <div className='flex items-center gap-2'>
              <div className='flex items-center'>
                <div className=''>
                  <Thumbnail
                    isRedirect={false}
                    btnFollow={false}
                    data={
                      members?.length > 0
                        ? members[0]
                        : { ...members[0], name: item?.name }
                    }
                    PhotoSize={PhotoSize.Small}
                  />
                </div>
                {members?.length > 1 && (
                  <div className='-ml-[20px]'>
                    <Thumbnail
                      isRedirect={false}
                      btnFollow={false}
                      data={members[1]}
                      PhotoSize={PhotoSize.Small}
                    />
                  </div>
                )}

                {members?.length > 2 && (
                  <div className='-ml-[20px]'>
                    <Thumbnail
                      isRedirect={false}
                      btnFollow={false}
                      data={members[2]}
                      PhotoSize={PhotoSize.Small}
                    />
                  </div>
                )}
              </div>
              <div>
                <p className='text-jrnyfy-textprimary truncate ... overflow-hidden max-w-[100px] capitalize'>
                  {item?.name}
                </p>
                {members.length > 0 ? (
                  <p className='text-[14px] text-jrnyfy-textprimary'>
                    {members[0]?.name.split(' ', 1)},{' '}
                    {members[1]?.name.split(' ', 1)}
                    {members?.length > 2 && (
                      <span>,+ {members?.length - 2}</span>
                    )}
                  </p>
                ) : (
                  <p className='text-[14px] text-jrnyfy-textprimary'>
                    no members left
                  </p>
                )}
              </div>
            </div>
            <div className='flex flex-col items-end'>
              <p className='text-[12px] font-semibold text-jrnyfy-textprimary text-end'>
                <Moment fromNow>{item.updatedAt}</Moment>
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ChatListItem;
