import React, { useEffect, useState } from 'react';
import Thumbnail from '../thumbnails/Thumbnail';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNotifications,
  notificationSelector,
  notificationViewed,
} from '../../redux/slices/mobile/notificationSlice';
import {
  authSelector,
  restNotificationCount,
} from '../../redux/slices/mobile/authSlice';
import { Facebook } from 'react-content-loader';
import Moment from 'react-moment';
import {
  FFUserAction,
  NotificationType,
  ParticipantStatus,
  TaskAssigneeStatus,
} from '../utils/subjects';
import {
  acceptOrRejectParticipant,
  acceptOrRejectParticipantRequestByOwner,
} from '../../redux/slices/mobile/activitySlice';
import { updateRequest } from '../../redux/slices/mobile/profileSlice';
import { confirmOrder } from '../../redux/slices/mobile/bookingSlice';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import NotificationListItem from './NotificationListItem';

const NotificationList = () => {
  const { auth } = useSelector(authSelector);
  const { notifications } = useSelector(notificationSelector);
  const dispatch = useDispatch();
  const [currPage, setCurrPage] = useState(0);
  const [size, setSize] = useState(30);
  const [contents, setContents] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCalling, setIsCalling] = useState(false);

  document.onscroll = () => {
    console.log(
      'Scroll On Notification',
      window.innerHeight + window.scrollY,
      window.innerHeight + document.body.scrollHeight / 2
    );
    if (
      window.innerHeight + window.scrollY >=
        window.innerHeight + document.body.scrollHeight / 2 &&
      !isEnd
    ) {
      if (!isCalling) {
        setIsCalling(true);
        setCurrPage(currPage + size);
      }
    }
  };

  const getNotification = () => {
    dispatch(
      getNotifications({
        data: { from: currPage, size: size, value: '' },
        token: auth.token,
        callback: (data) => {
          data.then((d) => {
            dispatch(restNotificationCount({ notification: 0 }));
            if (d.length < size) {
              setLoading(false);
              setIsEnd(true);
              setContents([...contents, ...d]);
            } else {
              if (size == 30) {
                setSize(10);
              }
              setLoading(false);
              setIsCalling(false);
              setContents([...contents, ...d]);
            }
          });
        },
      })
    );
  };

  useEffect(() => {
    getNotification();
    setLoading(true);
  }, [currPage]);

  console.log('resultFilters', contents);
  return (
    <div className='space-y-5 mb-10 max-[767px]:mb-28'>
      {contents &&
        contents.map((items, index) => {
          return <NotificationListItem items={items} index={index} />;
        })}
      {loading && <Facebook className='max-w-[545px]' viewBox='0 0 200 124' />}
    </div>
  );
};

export default NotificationList;
