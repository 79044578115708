import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import {
  followRequest,
  updateRequest,
} from "../../redux/slices/mobile/profileSlice";
import {
  changeFollowerCount,
  changeFollowingCount,
  viewProfileSelector,
} from "../../redux/slices/mobile/viewProfileSlice";
import { FFUserAction, FollowerFollowingStatus } from "../utils/subjects";

const FfButtons = ({ user }) => {
  let dispatch = useDispatch();
  let {
    auth: { token },
  } = useSelector(authSelector);
  let { profileSelected } = useSelector(viewProfileSelector);

  const [following, setFollowing] = useState(undefined);
  const [follower, setFollower] = useState(undefined);

  const onClickFollowing = () => {
    console.log("following user", following);
    if (following) {
      let userAction = FFUserAction.CancelRequest;
      if (following.status == FollowerFollowingStatus.Accepted) {
        userAction = FFUserAction.Unfollowed;
      }
      dispatch(
        updateRequest({
          data: {
            userAction,
            sk: user.sk,
            createdAt: user.createdAt,
            id: user.id,
          },

          token: token,
          callback: (results) => {
            setFollowing(undefined);
            if (userAction == FFUserAction.Unfollowed) {
              dispatch(changeFollowerCount({ value: -1 }));
            }
          },
        })
      );
    } else {
      dispatch(
        followRequest({
          token: token,
          data: { sk: user.sk, id: user.id },
          callback: (results) => {
            setFollowing({ status: results.following.status });
            dispatch(changeFollowerCount({ value: 1 }));
          },
        })
      );
    }
  };
  const onClickAcceptFollowRequest = () => {
    dispatch(
      updateRequest({
        data: {
          userAction: FFUserAction.Accepted,
          sk: user.sk,
          createdAt: user.createdAt,
          id: user.id,
        },

        token: token,
        callback: (results) => {
          setFollower({ status: FollowerFollowingStatus.Accepted });
          dispatch(changeFollowingCount({ value: 1 }));
        },
      })
    );
  };
  const onClickRejectFollowRequest = () => {
    dispatch(
      updateRequest({
        data: {
          userAction: FFUserAction.Rejected,
          sk: user.sk,
          createdAt: user.createdAt,
          id: user.id,
        },

        token: token,
        callback: (results) => {
          setFollower(undefined);
        },
      })
    );
  };
  const onClickRemoveFollower = () => {
    dispatch(
      updateRequest({
        data: {
          userAction: FFUserAction.Removed,
          sk: user.sk,
          createdAt: user.createdAt,
          id: user.id,
        },

        token: token,
        callback: (results) => {
          setFollower(undefined);
          dispatch(changeFollowingCount({ value: -1 }));
        },
      })
    );
  };
  useEffect(() => {
    console.log(profileSelected);

    if (profileSelected) {
      if (profileSelected.following) {
        setFollowing(profileSelected.following);
      }
      if (profileSelected.follower) {
        setFollower(profileSelected.follower);
      }
    }
  }, [profileSelected]);
  useEffect(() => {}, [following]);
  return (
    <main className="">
      <div className="px-3 flex justify-center">
        <div className="flex justify-center gap-3 text-jSecTitle font-semibold text-jrnyfy-textprimary">
          <button
            onClick={onClickFollowing}
            className={
              (following
                ? following.status == FollowerFollowingStatus.Pending
                  ? "bg-gray-500"
                  : "bg-jrnyfy-themeColor"
                : "bg-jrnyfy-themeColor") +
              " p-[8px_15px] rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[15px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
            }
          >
            {following
              ? following.status == FollowerFollowingStatus.Pending
                ? `Request Sent`
                : "unfollow"
              : "follow"}
          </button>
          {follower && follower.status == FollowerFollowingStatus.Pending && (
            <div className="flex justify-between items-center gap-3">
              <button
                onClick={onClickAcceptFollowRequest}
                className={
                  "bg-jrnyfy-themeColor p-[8px_15px] rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[15px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
                }
              >
                accept
              </button>
              <button
                onClick={onClickRejectFollowRequest}
                className={
                  "bg-jrnyfy-themeColor p-[8px_15px] rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[15px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
                }
              >
                reject
              </button>
            </div>
          )}
          {follower && follower.status == FollowerFollowingStatus.Accepted && (
            <button
              onClick={onClickRemoveFollower}
              className={
                "bg-jrnyfy-themeColor p-[8px_15px] rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[15px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
              }
            >
              remove
            </button>
          )}
        </div>
      </div>
    </main>
  );
};

export default FfButtons;
