import React, { useEffect, useRef } from 'react'

export default function CustomPopUp({ children, setIsPopUp }) {
    const popUpRef = useRef()
    useEffect(() => {
        const hideModalOutSideClickHandle = (e) => {
            if (!popUpRef?.current?.contains(e.target)) {
                setIsPopUp(false);
            }
        };
        document.addEventListener('mousedown', hideModalOutSideClickHandle);
        return () => {
            document.removeEventListener('mousedown', hideModalOutSideClickHandle);
        }
    });

    return (
        <div ref={popUpRef} className='p-2 w-[150px] absolute top-10 right-0 flex items-start flex-col bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg z-10'>
            {/* <div class='w-0 h-0 absolute -top-[19px] right-[18px] border-t-[20px] border-t-transparent border-r-[30px] border-r-white border-b-[20px] border-b-transparent shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg'></div> */}

            <svg aria-hidden="true" height="12" viewBox="0 0 21 12" width="21" className='absolute -top-3 right-5 drop-shadow-[3px_-5px_4px_rgba(0,0,0,0.3)]' fill="white" style={{ transform: "scale(-1) translate(0px)" }}><path d="M21 0c-2.229.424-4.593 2.034-6.496 3.523L5.4 10.94c-2.026 2.291-5.434.62-5.4-2.648V0h21Z"></path></svg>
            {children}
        </div>
    )
}
