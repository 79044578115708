import React, { useEffect, useState } from 'react';
import partnerWithUsBg from '../../assets/images/partner-with-us.svg';
import PartnerSignupModal from './PartnerSignupModal';
import partnerL1 from '../../assets/partner/partnerL1.png';
import partnerL2 from '../../assets/partner/partnerL2.png';
import partnerL3 from '../../assets/partner/partnerL3.png';
import partnerL4 from '../../assets/partner/partnerL4.png';
import partnerR1 from '../../assets/partner/partnerR1.png';
import partnerR2 from '../../assets/partner/partnerR2.png';
import partnerR3 from '../../assets/partner/partnerR3.png';
import partnerR4 from '../../assets/partner/partnerR4.png';
import partnerBL1 from '../../assets/partner/partnerBL1.png';
import partnerBR1 from '../../assets/partner/partnerBR1.png';
import partnerBR2 from '../../assets/partner/partnerBR2.png';
import partnerBR3 from '../../assets/partner/partnerBR3.png';
import partnerMobileB from '../../assets/partner/partnerMobileB.png';
import Login from '../Login/Login';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { useSelector } from 'react-redux';
import Seo from '../Seo/Seo';
// import partnerL1 from '../../assets/partner/partnerL1.svg';
// import partnerL2 from '../../assets/partner/partnerL2.svg';
// import partnerL3 from '../../assets/partner/partnerL3.svg';
// import partnerL4 from '../../assets/partner/partnerL4.svg';
// import partnerR1 from '../../assets/partner/partnerR1.svg';
// import partnerR2 from '../../assets/partner/partnerR2.svg';
// import partnerR3 from '../../assets/partner/partnerR3.svg';
// import partnerR4 from '../../assets/partner/partnerR4.svg';
// import partnerBL1 from '../../assets/partner/partnerBL1.svg';
// import partnerBR1 from '../../assets/partner/partnerBR1.svg';
// import partnerBR2 from '../../assets/partner/partnerBR2.svg';
// import partnerBR3 from '../../assets/partner/partnerBR3.svg';
// import partnerMobileB from '../../assets/partner/partnerMobileB.svg';

export const PartnerWithUsPage = () => {
  const [vendorSignUpModal, setVendorSignUpModal] = useState(false);
  let [login, setLogin] = useState(false);
  const { auth } = useSelector(authSelector);
  const [redirectTo, setRedirectTo] = useState('');

  const vendorSignUpModalHandle = () => {
    setVendorSignUpModal((prevState) => !prevState);
  };

  let loginControl = () => {
    setLogin((state) => !state);
  };

  return (
    <>
      <Seo
        title='Become Partner | start your business with us'
        description=''
        type='webapp'
        name='jrnyfy crop'
        canonicalHref='/partner'
      />
      <div className='relative overflow-hidden'>
        <div className=' mx-auto min-h-[1030px] relative max-w-[1500px] max-[1100px]:bg-white/[0.8]'>
          <div className='flex flex-col items-center gap-10 max-[500px]:gap-5'>
            <div className='text-center space-y-8 max-w-[700px] m-5 z-20 flex flex-col items-center'>
              <p className='text-[25px] text-jrnyfy-textprimary max-[500px]:text-[14px]'>
                help your community complete their journeys and earn a little
                extra while doing something you love
              </p>
              <h2 className='text-4xl text-jrnyfy-themeColor max-[500px]:text-[25px] max-[500px]:hidden'>
                become a jrnyfy partner
              </h2>
              <p className='text-[25px] text-jrnyfy-textprimary max-[500px]:text-[12px] max-[500px]:max-w-[150px]'>
                it could be as simple as showing somebody around your town
              </p>
              <h2 className='text-4xl text-jrnyfy-themeColor max-[500px]:text-[20px] max-[500px]:leading-[20px] min-[501px]:hidden max-[500px]:max-w-[270px]'>
                3 easy steps to become a jrnyfy partner
              </h2>
            </div>
            <div className='flex gap-10 w-full justify-center px-5 max-[767px]:flex-col max-[500px]:items-start max-[500px]:gap-5'>
              <div className='flex flex-col gap-5 items-center justify-center max-[500px]:items-start max-[500px]:gap-3'>
                <span className='text-[25px] text-jrnyfy-textprimary max-[500px]:text-[16px]'>
                  Step 1
                </span>
                <button
                  onClick={() => {
                    if (auth?.token) {
                      vendorSignUpModalHandle();
                    } else {
                      setRedirectTo('/partner');
                      loginControl();
                    }
                  }}
                  type='button'
                  className='z-20 cursor-pointer border bg-white border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro max-[500px]:text-[14px]'
                >
                  Sign Up as a vendor
                </button>
              </div>
              <div className='flex flex-col gap-5 items-center justify-center max-[500px]:items-start max-[500px]:gap-3'>
                <span className='text-[25px] text-jrnyfy-textprimary max-[500px]:text-[16px]'>
                  Step 2
                </span>
                <button
                  type='button'
                  className='z-20 cursor-pointer border bg-white border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro max-[500px]:text-[14px]'
                >
                  set up your service
                </button>
              </div>
              <div className='flex flex-col gap-5 items-center justify-center max-[500px]:items-start max-[500px]:gap-3'>
                <span className='text-[25px] text-jrnyfy-textprimary max-[500px]:text-[16px]'>
                  Step 3
                </span>
                <button
                  type='button'
                  className='z-20 cursor-pointer border bg-white border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro max-[500px]:text-[14px]'
                >
                  create memories and earn
                </button>
              </div>
            </div>
          </div>

          <div className='z-[-1] absolute top-[2%]  left-0 h-[315px] w-[290px] max-[1250px]:w-[220px] max-[1250px]:h-[245px]  max-[550px]:w-[170px] max-[550px]:h-[185px] max-[500px]:-left-[12%] max-[500px]:top-[4%]'>
            <img
              className='absolute right-0 top-[10%] w-[62%] left-image1'
              src={partnerL1}
              alt={'banner image'}
            />
            <img
              className='absolute left-0 top-[50%] w-[55%] left-image2'
              src={partnerL2}
              alt={'banner image'}
            />
            <img
              className='absolute left-[10%] w-[40%] left-image3'
              src={partnerL3}
              alt={'banner image'}
            />
            <img
              className='absolute left-[50%] bottom-[5%] w-[37%] left-image4'
              src={partnerL4}
              alt={'banner image'}
            />
          </div>
          <div className='z-[-1] absolute top-[2%] right-0  h-[315px] w-[290px] max-[1250px]:w-[220px] max-[1250px]:h-[245px]  max-[550px]:w-[170px] max-[550px]:h-[185px] max-[500px]:-right-[15%] max-[500px]:top-[4%]'>
            <img
              className='absolute right-0 top-[15%] w-[58%] right-image1'
              src={partnerR1}
              alt={'banner image'}
            />
            <img
              className='absolute left-0 bottom-[5%] w-[58%] right-image2'
              src={partnerR2}
              alt={'banner image'}
            />
            <img
              className='absolute right-[45%] w-[45%] right-image3'
              src={partnerR3}
              alt={'banner image'}
            />
            <img
              className='absolute right-[20%] bottom-0 w-[31%] right-image4'
              src={partnerR4}
              alt={'banner image'}
            />
          </div>
          <div className='z-[-1] absolute top-[350px] left-0 right-0 m-auto h-[700px]  max-w-[700px]'>
            <img
              className='absolute top-20 left-5 w-[21%] bottom-left-image1 max-[500px]:hidden'
              src={partnerBL1}
              alt={'banner image'}
            />
            <img
              className='absolute right-16 top-24 w-[21%] bottom-right-image1 max-[500px]:top-52 max-[500px]:left-10 max-[500px]:right-[unset]'
              src={partnerBR1}
              alt={'banner image'}
            />
            <img
              className='absolute right-4 top-12 w-[21%] bottom-right-image2  max-[500px]:top-44 max-[500px]:left-20 max-[500px]:right-[unset]'
              src={partnerBR2}
              alt={'banner image'}
            />
            <img
              className='absolute -right-6 top-32 w-[21%] bottom-right-image3 max-[500px]:top-56 max-[500px]:left-24 max-[500px]:right-[unset]'
              src={partnerBR3}
              alt={'banner image'}
            />
            <img
              className='absolute left-[20%] top-[50%] w-[65%] h-auto bottom-mobile max-[500px]:w-[unset] max-[500px]:left-[unset] max-[500px]:-right-[70%]'
              src={partnerMobileB}
              alt={'banner image'}
            />
          </div>
        </div>
        {vendorSignUpModal && (
          <PartnerSignupModal
            vendorSignUpModalHandle={vendorSignUpModalHandle}
            vendorSignUpModal={vendorSignUpModal}
          />
        )}
        {login && (
          <Login
            setVendorSignUpModal={setVendorSignUpModal}
            mState={login}
            setMState={loginControl}
          />
        )}
      </div>
    </>
  );
};
