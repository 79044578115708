import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, getUsers } from '../../redux/slices/mobile/authSlice';
import { MAX_SIZE_TOP_INSPIRER_ASIDE_VIEW } from '../../utils/static';
import Thumbnail from '../thumbnails/Thumbnail';
import {
  ElasticSortOrder,
  ElasticUserSortField,
  PhotoSize,
} from '../utils/subjects';

const TopInspirers = () => {
  let dispatch = useDispatch();
  let {
    auth: { token },
  } = useSelector(authSelector);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    dispatch(
      getUsers({
        data: {
          from: 0,
          sortField: ElasticUserSortField.InspireCount,
          sortOrder: ElasticSortOrder.Descending,
          size: MAX_SIZE_TOP_INSPIRER_ASIDE_VIEW,

        },
        token,
        callback: (results) => {
          setUsers(results);
        },
      })
    );
  }, []);

  // console.log(topTags);

  return (
    <div>
      <p className='text-jSecTitle font-semibold'>top inspirers</p>
      <div className=' py-3 space-y-4'>
        {users &&
          users.length > 0 &&
          users.map(
            (elem, ind) =>
              ind < 5 && (
                <Thumbnail
                  showName={true}
                  data={elem}
                  btnFollow={false}
                  PhotoSize={PhotoSize.Small}
                />
              )
          )}
      </div>
    </div>
  );
};

export default TopInspirers;
