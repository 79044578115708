import React, { useContext, useState } from "react";
import Popup from "reactjs-popup";
import Cross from "../../assets/images/xmark-solid.svg";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  activitySelector,
  updateActivityTask,
} from "../../redux/slices/mobile/activitySlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import FunctionalButton from "../FunctionalButton/FunctionalButton";
import { timelineDataContext } from "../jrnyTimline/JrnyTimline";

export default function DueDateModal({
  index,
  item,
  isDueDateModal,
  setIsDueDateModal,
}) {
  const [dueDateTime, setDueDateTime] = useState(new Date());
  const { activity } = useSelector(activitySelector);
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const { timeLineContent, setTimelineContent } =
    useContext(timelineDataContext);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  console.log("TaskDueDate", dueDateTime);

  const taskDueDateHandle = () => {
    setActionStates({ ...actionStates, loading: true });
    dispatch(
      updateActivityTask({
        data: {
          dueDate: dueDateTime,
          sk: activity.sk,
          createdAt: activity.createdAt,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          taskId: item?.task?.id,
          timelineId: item.id,
          tCreatedAt: item.createdAt,
        },
        callback: (res) => {
          let tempTimeline = timeLineContent;
          tempTimeline[index] = res.results.timelines[0];
          setTimelineContent(tempTimeline);
        },
        token: auth.token,
        activityId: activity.id,
      })
    ).then((res) => {
      console.log("Response", res);
      setIsDueDateModal(false);
      if (res.meta.requestStatus == "fulfilled") {
        setActionStates({
          ...actionStates,
          loading: false,
          fulfilled: true,
        });
      }
      if (res.meta.requestStatus == "rejected") {
        setActionStates({
          ...actionStates,
          loading: false,
          rejected: true,
        });
      }
    });
  };

  return (
    <Popup
      open={isDueDateModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: "100%",
        height: "100vh",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      <div className="space-y-5 relative bg-white w-[400px] rounded-[20px] p-10  max-[500px]:p-5 max-[500px]:h-screen max-[500px]:w-full  max-[500px]:overflow-y-scroll max-[500px]:rounded-none">
        <div className="flex items-center gap-3">
          <img
            src={Cross}
            alt="cross"
            className={`cursor-pointer 
               w-[20px]
            `}
            onClick={() => {
              setIsDueDateModal(false);
            }}
          />

          <p className="text-jrnyfy-textprimary font-semibold w-full text-center">
            Delete TimeLine
          </p>
        </div>
        <div className="w-full">
          <DateTimePicker onChange={setDueDateTime} value={dueDateTime} />
        </div>

        <FunctionalButton
          actionStates={actionStates}
          callback={() => taskDueDateHandle()}
          actionStatesTexts={{
            default: "Set Time",
            loading: "Setting Time",
            fulfilled: "Time Set Successfull",
            rejected: "Time Set Failed",
          }}
          text={{ size: "12px", weight: "bold" }}
          padding={"5px_15px"}
        />
      </div>
    </Popup>
  );
}
