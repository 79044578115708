import React from 'react';
import Popup from 'reactjs-popup';

const ToastModal = ({ text, toastModal, toastModalHandle }) => {
  return (
    <Popup
      open={toastModal}
      closeOnDocumentClick
      contentStyle={{
        maxWidth: '420px',
        borderRadius: '20px',
        padding: '50px',
      }}
    >
      <div>
        <p>{text}</p>
        <div className='flex justify-end'>
          <button
            onClick={() => toastModalHandle(false, '')}
            className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
          >
            Ok
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default ToastModal;
