import React, { useState } from "react";
import {
  FFUserAction,
  NotificationType,
  ParticipantStatus,
  PhotoSize,
  TaskAssigneeStatus,
  YesNo,
} from "../utils/subjects";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptOrRejectParticipant,
  acceptOrRejectParticipantRequestByOwner,
} from "../../redux/slices/mobile/activitySlice";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { updateRequest } from "../../redux/slices/mobile/profileSlice";
import { confirmOrder } from "../../redux/slices/mobile/bookingSlice";
import Thumbnail from "../thumbnails/Thumbnail";
import Moment from "react-moment";
import FunctionalButton from "../FunctionalButton/FunctionalButton";

const NotificationListItem = ({ items, index }) => {
  const { auth } = useSelector(authSelector);
  let notificationStates;

  const dispatch = useDispatch();
  const [acceptActionStates, setAcceptActionStates] = useState({
    loading: false,
    fulfilled: items?.accepted == YesNo.Yes ? true : false,
    rejected: false,
  });
  const [declineActionStates, setDeclineActionStates] = useState({
    loading: false,
    fulfilled: items?.accepted == YesNo.No ? true : false,
    rejected: false,
  });
  const acceptNotification = async (item, data) => {
    console.log("Calabck Items", item, data);
    // Task Notifications Start
    if (data) {
      setAcceptActionStates({ ...acceptActionStates, loading: true });
    } else {
      setDeclineActionStates({ ...declineActionStates, loading: true });
    }
    if (item.type === NotificationType.ParticipantRequest) {
      console.log("NotificationType ParticipantRequest", item);
      dispatch(
        acceptOrRejectParticipantRequestByOwner({
          data: {
            status:
              data == true
                ? TaskAssigneeStatus.Accepted
                : TaskAssigneeStatus.Rejected,
            notificationId: item.id,
            accepted: data == true ? YesNo.Yes : YesNo.No,
            skParticipant: item?.data?.user?.sk,
            sk: item?.data?.activity.sk,
            createdAt: item?.data?.activity.createdAt,
          },
          token: auth.token,
          userId: item?.data?.user.id,
          activityId: item?.data?.activity.id,
        })
      ).then((res) => {
        console.log("Response", res.meta.requestStatus);
        if (res.meta.requestStatus == "fulfilled") {
          if (data) {
            setAcceptActionStates({
              ...acceptActionStates,
              loading: false,
              fulfilled: true,
            });
          } else {
            setDeclineActionStates({
              ...declineActionStates,
              loading: false,
              fulfilled: true,
            });
          }
        }
        if (res.meta.requestStatus == "rejected") {
          if (data) {
            setAcceptActionStates({
              ...acceptActionStates,
              loading: false,
              rejected: true,
            });
          } else {
            setDeclineActionStates({
              ...declineActionStates,
              loading: false,
              fulfilled: true,
            });
          }
        }
      });
    }
    //Task Notifications End
    //Journey participant Notifications Start
    else if (item.type == NotificationType.ActivityParticipantAdded) {
      console.log("NotificationType ActivityParticipantAdded", item);

      dispatch(
        acceptOrRejectParticipant({
          data: {
            sk: item?.data?.sk,
            createdAt: item?.data?.createdAt,
            status:
              data == true
                ? ParticipantStatus.Accepted
                : ParticipantStatus.Rejected,
            notificationId: item.id,
            accepted: data == true ? YesNo.Yes : YesNo.No,
            requester: {
              id: item.initiator.id,
              sk: item.initiator.sk,
              createdAt: item.initiator.createdAt,
            },
          },
          token: auth.token,
          id: item.data?.id,
        })
      ).then((res) => {
        console.log("Response", res.meta.requestStatus);
        if (res.meta.requestStatus == "fulfilled") {
          if (data) {
            setAcceptActionStates({
              ...acceptActionStates,
              loading: false,
              fulfilled: true,
            });
          } else {
            setDeclineActionStates({
              ...declineActionStates,
              loading: false,
              fulfilled: true,
            });
          }
        }
        if (res.meta.requestStatus == "rejected") {
          if (data) {
            setAcceptActionStates({
              ...acceptActionStates,
              loading: false,
              rejected: true,
            });
          } else {
            setDeclineActionStates({
              ...declineActionStates,
              loading: false,
              fulfilled: true,
            });
          }
        }
      });
    }
    //Journey participant Notifications End
    //User Follow Notifications Start
    else if (item.type == NotificationType.UserFollow) {
      console.log("NotificationType UserFollow");

      dispatch(
        updateRequest({
          data: {
            userAction:
              data == true ? FFUserAction.Accepted : FFUserAction.Rejected,
            notificationId: item.id,
            accepted: data == true ? YesNo.Yes : YesNo.No,
            sk: item?.data?.sk,
            createdAt: item?.data?.createdAt,
            id: item?.data.id,
          },

          token: auth.token,
        })
      ).then((res) => {
        console.log("Response", res.meta.requestStatus);
        if (res.meta.requestStatus == "fulfilled") {
          if (data) {
            setAcceptActionStates({
              ...acceptActionStates,
              loading: false,
              fulfilled: true,
            });
          } else {
            setDeclineActionStates({
              ...declineActionStates,
              loading: false,
              fulfilled: true,
            });
          }
        }
        if (res.meta.requestStatus == "rejected") {
          if (data) {
            setAcceptActionStates({
              ...acceptActionStates,
              loading: false,
              rejected: true,
            });
          } else {
            setDeclineActionStates({
              ...declineActionStates,
              loading: false,
              fulfilled: true,
            });
          }
        }
      });
    }
    //User Follow Notifications End
    //Vendor Order Notifications Start
    else if (item.type == NotificationType.NewOrder) {
      console.log("New order");
      dispatch(
        confirmOrder({
          data: {
            status: data == true ? 1 : 0,
            notificationId: item.id,
            accepted: data == true ? YesNo.Yes : YesNo.No,
            createdAtCustomer: item?.data?.createdAt,
            skCustomer: item?.data?.sk,
            idCustomer: item?.data?.id,
            sk: item?.data?.oSk,
            createdAt: item?.data?.oCAt,
          },
          token: auth.token,
          orderId: item?.data?.oId,
        })
      ).then((res) => {
        console.log("Response", res.meta.requestStatus);
        if (res.meta.requestStatus == "fulfilled") {
          if (data) {
            setAcceptActionStates({
              ...acceptActionStates,
              loading: false,
              fulfilled: true,
            });
          } else {
            setDeclineActionStates({
              ...declineActionStates,
              loading: false,
              fulfilled: true,
            });
          }
        }
        if (res.meta.requestStatus == "rejected") {
          if (data) {
            setAcceptActionStates({
              ...acceptActionStates,
              loading: false,
              rejected: true,
            });
          } else {
            setDeclineActionStates({
              ...declineActionStates,
              loading: false,
              fulfilled: true,
            });
          }
        }
      });
    }
    //Vendor Order Notifications End
  };
  return (
    <div className="flex justify-between items-center" key={index}>
      <div className="flex items-start gap-3">
        <Thumbnail
          btnFollow={false}
          showName={false}
          data={items?.initiator}
          PhotoSize={PhotoSize.Large}
          thumbnailContainerStyle={'!w-[50px] !h-[50px]'}
        />

        {!items.cancelled ? (
          <div className="flex flex-col space-y-3">
            <div>
              <span>{items.message}</span>
              <p className="text-[14px] font-semibold text-jrnyfy-textprimary">
                Sent <Moment fromNow>{items.createdAt}</Moment> ,
                {items?.initiator?.name}
              </p>
            </div>
            {(items?.type === NotificationType.CustomPriceOffer ||
              items?.type === NotificationType.UserFollow ||
              items?.type === NotificationType.ActivityParticipantAdded ||
              items?.type === NotificationType.NewOrder ||
              items?.type === NotificationType.ParticipantRequest) && (
              <div className="flex gap-3">
                <FunctionalButton
                  actionStates={acceptActionStates}
                  callback={() => {
                    if (!items?.viewed) {
                      acceptNotification(items, true);
                    }
                  }}
                  bgColor={"jrnyfy-themeColor"}
                  text={{ color: "white" }}
                  cursor={`${items?.viewed ? "not-allowed" : "pointer"}`}
                  actionStatesTexts={{
                    default: "Accept",
                    loading: "Accepting",
                    fulfilled: "Accepted",
                    rejected: "Accept Failed",
                  }}
                  tooltip={"You already accepted this notification"}
                />

                <FunctionalButton
                  actionStates={declineActionStates}
                  callback={() => {
                    if (!items?.viewed) {
                      acceptNotification(items, false);
                    }
                  }}
                  cursor={`${items?.viewed ? "not-allowed" : "pointer"}`}
                  actionStatesTexts={{
                    default: "Decline",
                    loading: "Declining",
                    fulfilled: "Declined",
                    rejected: "Decline Failed",
                  }}
                  tooltip={"You already declined this notification"}
                />
              </div>
            )}
          </div>
        ) : (
          <div>This request canceled</div>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <span className="block w-[5px] h-[5px] rounded-[50%] bg-black"></span>
        <span className="block w-[5px] h-[5px] rounded-[50%] bg-black"></span>
        <span className="block w-[5px] h-[5px] rounded-[50%] bg-black"></span>
      </div>
    </div>
  );
};

export default NotificationListItem;
