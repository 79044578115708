import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {UserContentFilter} from '../../../components/utils/subjects';
import {baseUrl} from '../../../environments/proxyUrl';
import {
  parse_feedListObjectWithInnerHits_likes_comments_inspirers,
  parse_SourceObjectWithInnerHits_Leads,
  parse_userListObjectWithInnerHits_Feed,
  parse_userListObjectWithInnerHits_fofo,
  parseFeed,
} from './elasticParser';

export const getFeeds = createAsyncThunk(
  'feeds/getFeeds',
  async ({data, token, callback}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.get(`${baseUrl}/api/search/v1/feeds/`, {
        params: {...data},
        headers: {Authorization: token},
      });
      console.log('raw data getFeeds', res);
      const result = await parseFeed(res)
      console.log("ParseData",result)
      if (callback) {
        callback(result);
      }
      
      return {
       
        feeds: result,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMoreFeeds = createAsyncThunk(
  'feeds/getMoreFeeds',
  async ({data, token, callback}, {rejectWithValue}) => {
    try {
      const {data: res, headers} = await axios.get(
        `${baseUrl}/api/search/v1/feeds/`,
        {
          params: {...data},
          headers: {Authorization: token},
        },
      );
      if (callback) {
        callback(res);
      }
      return {
        skip: data.from,
        limit: data.size,
        feeds: await parse_SourceObjectWithInnerHits_Leads(res),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTimelineItemsDetails = createAsyncThunk(
  'feeds/getTimelineItemsDetails',
  async ({data, token, callback}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.get(`${baseUrl}/api/activities/v1/timeline/${data.id}`, {
        params: data,
        headers: {Authorization: token},
      });
      console.log('raw data getTimelineItemsDetails', res)
      if (callback) {
        callback(res.results);
      }
      
      return res.results
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// export const getMoreLeadsVendor = createAsyncThunk(
//   'feed/getMoreLeadsVendor',
//   async ({data, token}, {rejectWithValue}) => {
//     try {
//       const response = await axios.get(`${baseUrl}/api/search/v1/leads/feed/`, {
//         params: {...data},
//         headers: {Authorization: token},
//       });
//       return {
//         size: data.size,
//         from: data.from,
//         activities: await parse_SourceObjectWithInnerHits_Leads(response.data),
//       };
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
export const getBadgesOfActivity = createAsyncThunk(
  'feed/getBadgesOfActivity',
  async ({data, token, activityId}, {rejectWithValue}) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/activities/v1/badge/${activityId}/`,
        {
          params: {...data},
          headers: {Authorization: token},
        },
      );
      return await response.data.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getLikesOfActivity = createAsyncThunk(
  'feed/getLikesOfActivity',
  async ({data, token, callback}, {rejectWithValue}) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/content/`, {
        params: data,

        headers: {Authorization: token},
      });
      console.log('raw response getLikesOfActivity', response);
      if (callback) {
        callback();
      }
      return {
        size: data.size,
        from: data.from,
        likes: await parse_feedListObjectWithInnerHits_likes_comments_inspirers(
          response.data.results,
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSharesOfActivity = createAsyncThunk(
  'feed/getSharesOfActivity',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/content/`, {
        params: {
          ...data,
          filter: UserContentFilter.GetRatingsOfAService,
        },
        headers: {Authorization: token},
      });
      console.log('raw response getSharesOfActivity', response);

      return {
        size: data.size,
        from: data.from,
        likes: await parse_userListObjectWithInnerHits_fofo(
          response.data.results,
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getInspiredFeed = createAsyncThunk(
  'feed/getInspiredFeed',
  async ({data, token, callback}, {rejectWithValue}) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/content/`, {
        params: {
          ...data,
          filter: UserContentFilter.GetInspiresOfAJourney,
        },
        headers: {Authorization: token},
      });
      console.log('raw response getInspiredFeed', response);
      if (callback) {
        callback();
      }
      return {
        size: data.size,
        from: data.from,
        inspired: await parse_userListObjectWithInnerHits_Feed(
          response.data.results,
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const requestToJoinJourney = createAsyncThunk(
  'feed/requestToJoinJourney',
  async ({data, token, activityId}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.post(
        `${baseUrl}/api/activities/v1/preq/${activityId}`,
        data,
        {
          headers: {Authorization: token},
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getActiveActivitiesOfUser_feed = createAsyncThunk(
  'feed/getActiveActivitiesOfUser_feed',
  async ({data, id, token}, {rejectWithValue}) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/activities/v1/user/`,

        {params: {...data}, headers: {Authorization: token}},
      );
      return {
        size: data.size,
        from: data.from,
        activeActivities: response.data.results,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMoreActiveActivitiesOfUser_Feed = createAsyncThunk(
  'feed/getMoreActiveActivitiesOfUser_Feed',
  async ({data, id, token}, {rejectWithValue}) => {
    try {
      const response = await axios.get(`${baseUrl}/api/activities/v1/user/`, {
        params: {...data},
        headers: {Authorization: token},
      });
      console.log('raw res getMoreActiveActivitiesOfUser_Feed', response);
      return {
        size: data.size,
        from: data.from,
        activeActivities: response.data.results,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const feedSlice = createSlice({
  name: 'feed',
  initialState: {
    feeds: [],
    vendorLeads: [],
    // activitiesTrending: [],
    journeysUnderBudgetOrEnded: [],
    contentRangeVendorLeads: undefined,
    contentRangeFeeds: undefined,
    contentRangeUserListLikes: undefined,
    contentRangeInspiredFeed: undefined,
    contentRangeShareListLikes: undefined,
    errors: [],
    filters: [],
    activity: {},
    loading: false,
    userListLikes: [],
    badgesList: [],
    contentRangeActiveActivities_feed: undefined,
    activeActivities_feed: [],
    inspiredFeed: [],
    sharesFeed: [],
    feedArray: [],
    searchedTagList: [],
  },
  reducers: {
    increaseCommentCount: (state, action) => {
      const index = action.payload;
      console.log(
        'activityId',

        state.feeds[index].commentCount,
      );

      state.feeds[index].commentCount =
        parseInt(state.feeds[index].commentCount) + 1;

      console.log(
        'Comment Count',

        state.feeds[index].commentCount,
      );
      // const data = state.feeds.map(feed => {
      //   console.log('state.feeds.commentCountsssss', JSON.stringify(feed));
      // });
      // console.log('state.feeds.commentCount', state.feeds);
    },
    feedElementsArray: (state, action) => {
      console.log('actionPayload', action);
      state.feedArray = action.payload;
    },
    getSearchedTagLists: (state, action) => {
      console.log('TagFIlter', action.payload);
      const searchTagArray = action.payload.map(item => {
        return [item.name];
      });
      state.searchedTagList = searchTagArray;
    },
  },
  extraReducers: {
    [getFeeds.pending]: state => {
      state.errors = [];
      state.feeds = [];
      state.loading = true;
    },
    [getFeeds.fulfilled]: (state, action) => {
      // state.errors = [];
      // let aSize = action.payload?.feeds?.length;
      // let limit = action.payload.limit;
      // let skip = action.payload.skip;
      // let sum = parseInt(limit) + parseInt(skip);
      // if (aSize < limit) {
      //   state.contentRangeFeeds = `${skip}-${sum}/false`;
      // } else {
      //   state.contentRangeFeeds = `${skip}-${sum}/true`;
      // }
      // state.feeds = action.payload.feeds;
      // console.log('state.feeds', state.feeds);
      // state.loading = false;
    },
    [getFeeds.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreFeeds.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getMoreFeeds.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      const feeds = state.feeds.concat(action.payload.feeds);
      const temp = [...state.feeds, ...action.payload.feeds];
      state.feeds = feeds;
      let aSize = action.payload?.feeds?.length;
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSize < limit) {
        state.contentRangeFeeds = `${skip}-${sum}/false`;
      } else {
        state.contentRangeFeeds = `${skip}-${sum}/true`;
      }
    },
    [getMoreFeeds.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getTimelineItemsDetails.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getTimelineItemsDetails.fulfilled]: (state, action) => {
      state.errors = [];
      
    },
    [getTimelineItemsDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    // [getMoreLeadsVendor.pending]: state => {
    //   state.errors = [];
    //   state.loading = true;
    //   state.contentRangeVendorLeads = undefined;
    // },
    // [getMoreLeadsVendor.fulfilled]: (state, action) => {
    //   state.errors = [];
    //   if (action.payload.activities) {
    //     state.vendorLeads = state.vendorLeads.concat(action.payload.activities);
    //     let size = action.payload.size;
    //     let from = action.payload.from;
    //     let aSize = action.payload.activities.length;
    //     let sum = parseInt(size) + parseInt(from);
    //     if (aSize < size) {
    //       state.contentRangeVendorLeads = `${from}-${sum}/false`;
    //     } else {
    //       state.contentRangeVendorLeads = `${from}-${sum}/true`;
    //     }
    //   }
    //   state.loading = false;
    // },
    // [getMoreLeadsVendor.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    [getBadgesOfActivity.pending]: state => {
      state.errors = [];
      state.badgesList = [];
      state.loading = true;
    },
    [getBadgesOfActivity.fulfilled]: (state, action) => {
      state.errors = [];
      state.badgesList = action.payload;
      state.loading = false;
    },
    [getBadgesOfActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getLikesOfActivity.pending]: state => {
      state.errors = [];
      state.userListLikes = [];
      state.loading = true;
    },
    [getLikesOfActivity.fulfilled]: (state, action) => {
      state.errors = [];
      let aSize = action.payload?.likes?.length;
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSize < limit) {
        state.contentRangeUserListLikes = `${skip}-${sum}/false`;
      } else {
        state.contentRangeUserListLikes = `${skip}-${sum}/true`;
      }
      state.userListLikes = action.payload.likes;
      state.loading = false;
    },
    [getLikesOfActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getSharesOfActivity.pending]: state => {
      state.errors = [];
      state.sharesFeed = [];
      state.loading = true;
    },
    [getSharesOfActivity.fulfilled]: (state, action) => {
      state.errors = [];
      let aSize = action.payload?.likes?.length;
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSize < limit) {
        state.contentRangeShareListLikes = `${skip}-${sum}/false`;
      } else {
        state.contentRangeShareListLikes = `${skip}-${sum}/true`;
      }
      state.sharesFeed = action.payload.likes;
      state.loading = false;
    },
    [getSharesOfActivity.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    //
    [getInspiredFeed.pending]: state => {
      state.errors = [];
      state.sharesFeed = [];
      state.loading = true;
    },
    [getInspiredFeed.fulfilled]: (state, action) => {
      state.errors = [];
      let aSize = action.payload?.likes?.length;
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSize < limit) {
        state.contentRangeInspiredFeed = `${skip}-${sum}/false`;
      } else {
        state.contentRangeInspiredFeed = `${skip}-${sum}/true`;
      }
      state.inspiredFeed = action.payload.inspired;
      state.loading = false;
    },
    [getInspiredFeed.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getActiveActivitiesOfUser_feed.pending]: state => {
      state.errors = [];
      state.loading = true;
      state.contentRangeInspiredFeed = undefined;
    },
    [getActiveActivitiesOfUser_feed.fulfilled]: (state, action) => {
      state.errors = [];
      state.activeActivities_feed = action.payload.activeActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload?.activeActivities?.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeActiveActivities_feed = `${from}-${sum}/false`;
      } else {
        state.contentRangeActiveActivities_feed = `${from}-${sum}/true`;
      }
      state.loading = false;
    },
    [getActiveActivitiesOfUser_feed.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreActiveActivitiesOfUser_Feed.pending]: state => {
      state.loading = true;
      state.errors = [];
      state.contentRangeActiveActivities_feed = undefined;
    },
    [getMoreActiveActivitiesOfUser_Feed.fulfilled]: (state, action) => {
      state.loading = false;
      const activeActivities = state.activeActivities_feed.concat(
        action.payload.activeActivities,
      );
      state.activeActivities_feed = activeActivities;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload?.activeActivities?.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeActiveActivities_feed = `${from}-${sum}/false`;
      } else {
        state.contentRangeActiveActivities_feed = `${from}-${sum}/true`;
      }
    },
    [getMoreActiveActivitiesOfUser_Feed.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {increaseCommentCount, feedElementsArray, getSearchedTagLists} =
  feedSlice.actions;

export default feedSlice.reducer;

export const feedSelector = state => state.feed;
