import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Cross from "../../assets/images/xmark-solid.svg";
import addPlaceIcon from "../../assets/images/button_menu_places.svg";
import {
  activitySelector,
  createActivity,
} from "../../redux/slices/mobile/activitySlice";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { buildUrlToSize } from "../../utils/build-image-url";
import { getJourneyNavOption } from "../../utils/get-nav-option";
import Login from "../Login/Login";
import { JourneyPrivacy, PhotoSize, PhotoSource } from "../utils/subjects";
import { getStatus } from "../utils/static/global_functions";
import PrivacyModeSelect from "../PrivacyMode/PrivacyModeSelect";
import AddFriends from "../AddFriends/AddFriends";

const JrnyBody = ({
  placeControl,
  friendControl,
  userList,
  placeList,
  jrnyModalControl,
  postValue,
  setPostValue,
  loginRequireControl,
  jrnyCreateActivity,
  privacyHandle,
  privacy,
  setPrivacy,
  showPrivacy,
  selectedCustomPrivacyPeople,
  setSelectedCustomPrivacyPeople,
}) => {
  let dispatch = useDispatch();
  let { auth } = useSelector(authSelector);
  let { activity } = useSelector(activitySelector);
  const inputRef = useRef();
  let navigate = useNavigate();
  let location = useLocation();
  const [showCustomPrivacy, setShowCustomPrivacy] = useState(false);
  const showCustomPrivacyHandle = () => {
    setShowCustomPrivacy((prevState) => !prevState);
  };

  // useEffect(() => {
  //   if (activity.id) {
  //     if (location.pathname === `/jrny/${activity.id}`) {
  //     } else {
  //       jrnyModalControl();
  //       navigate(getJourneyNavOption(activity));
  //     }
  //   }
  // }, [activity]);
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);
  
  console.log('PlaceList',placeList)
  return (
    <motion.div className="space-y-5 text-center">
      {/* -------cross button-------- */}
      {!showCustomPrivacy ? (
        <>
          <div className="flex items-start">
            <img
              src={Cross}
              alt="cross"
              className="cursor-pointer w-[20px] "
              onClick={jrnyModalControl}
            />

            <p className="text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary">
              start a journey
            </p>
          </div>
          <div className="flex flex-col relative ">
            <textarea
              ref={inputRef}
              onClick={(e) => {
                setPostValue((state) => ({ ...state, vissible: true }));
              }}
              onChange={(e) => {
                setPostValue((state) => ({
                  vissible: true,
                  value: e.target.value,
                }));
              }}
              spellCheck="true"
              className="resize-none max-[500px]:border-b min-[501px]:border-b border-solid border-jrnyfy-textprimary min-h-[50px] z-40 break-words max-[500px]:text-[14px] min-[350px]:max-[460px]:leading-[21px] text-jrnyfy-textprimary font-Montserrat z-[999] focus:outline-none"
              value={postValue?.value}
            />

            <span
              className=" absolute text-left top-0 pointer-events-none w-full max-[500px]:text-[14px] min-[350px]:max-[460px]:leading-[21px] text-jrnyfy-textprimary font-Montserrat z-[999]"
              style={
                postValue?.vissible && postValue?.value === undefined
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              describe your journey here..
              <br />
              for example: backpacking in Europe
            </span>
          </div>

          <div className="space-y-3">
            <div className="flex flex-wrap-reverse gap-3">
              <button
                className="bg-white text-jrnyfy-textprimary border-jrnyfy-textprimary border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro transition-all duration-300"
                onClick={placeControl}
              >
                where will this journey take place?
              </button>
              <div className="flex flex-wrap">
                {placeList &&
                  placeList.length >= 1 &&
                  placeList.map((val, ind) => (
                    <img
                      key={ind}
                      src={
                        val?.photos || val.photo
                          ? 
                           buildUrlToSize(val.photo?val.photo:{
                              photoReference: val?.photos[0].photo_reference
                                ? val?.photos[0].photo_reference
                                : val?.photos[0].getUrl(),
                              photoSource: val?.photos[0].photo_reference
                                ? PhotoSource.GooglePlace
                                : PhotoSource.GooglePlaceFullURL,
                            },PhotoSize.Large):addPlaceIcon
                      }
                      alt={val.name}
                      className="w-[35px] h-[35px] max-w-none rounded-[50%] ring-2 ring-white ml-[-7px]"
                    />
                  ))}
              </div>
            </div>
            <div className="flex items-center flex-wrap-reverse gap-3">
              <button
                className="bg-white text-jrnyfy-textprimary border-jrnyfy-textprimary border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro transition-all duration-300"
                onClick={friendControl}
              >
                who is coming along with you?
              </button>
              <div className="flex flex-wrap">
                {userList &&
                  userList.length >= 1 &&
                  userList.map((val, ind) => (
                    <div className="text-center text-[22px] overflow-hidden bg-jrnyfy-themeColor text-white w-[35px] h-[35px] rounded-[50%] ring-2 ring-white ml-[-7px]">
                      {buildUrlToSize(val.thumbnail) === undefined ? (
                        val.name.slice(0, 1)
                      ) : (
                        <img
                          key={ind}
                          src={buildUrlToSize(val.thumbnail, PhotoSize.Large)}
                          alt={val.name}
                          className="w-[35px] h-[35px] rounded-[50%]"
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex items-center flex-wrap-reverse gap-3">
              <button
                className="bg-white text-jrnyfy-textprimary border-jrnyfy-textprimary border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro transition-all duration-300"
                onClick={privacyHandle}
              >
                Who can see your journey?
              </button>
              <div className="flex flex-wrap">
                {privacy == JourneyPrivacy.Custom &&
                selectedCustomPrivacyPeople ? (
                  selectedCustomPrivacyPeople.map((val, ind) => (
                    <div className="text-center text-[22px] overflow-hidden bg-jrnyfy-themeColor text-white w-[35px] h-[35px] rounded-[50%] ring-2 ring-white ml-[-7px]">
                      {buildUrlToSize(val.thumbnail) === undefined ? (
                        val.name.slice(0, 1)
                      ) : (
                        <img
                          key={ind}
                          src={buildUrlToSize(val.thumbnail, PhotoSize.Large)}
                          alt={val.name}
                          className="w-[35px] h-[35px] rounded-[50%]"
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <span className="text-jrnyfy-textprimary italic font-SourceSansPro">
                    {getStatus(JourneyPrivacy, privacy)}
                  </span>
                )}
              </div>
            </div>

            {showPrivacy && (
              <PrivacyModeSelect
                setShowCustomPrivacy={setShowCustomPrivacy}
                showPrivacy={showPrivacy}
                privacyHandle={privacyHandle}
                privacy={privacy}
                setPrivacy={setPrivacy}
              />
            )}
          </div>

          <div className="text-end">
            <button
              className="bg-jrnyfy-themeColor p-[5px_10px] italic rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro"
              onClick={() => {
                jrnyCreateActivity();
              }}
            >
              START Journey
            </button>
          </div>
        </>
      ) : (
        showCustomPrivacy && (
          <AddFriends
            control={showCustomPrivacyHandle}
            userAdd={setSelectedCustomPrivacyPeople}
            userArr={selectedCustomPrivacyPeople}
            friendState={selectedCustomPrivacyPeople}
            placeControl={placeControl}
            friendControl={showCustomPrivacyHandle}
            headerText={"Who can see your journey?"}
          />
        )
      )}
    </motion.div>
  );
};

export default JrnyBody;
