export const onTypeChange = (typeInput, setTypeInput, callback) => (e) => {
  if (typeInput.typingTimeout) {
    clearTimeout(typeInput.typingTimeout);
  }
  setTypeInput({
    typing: true,
    text: e.target.value,
    searchType: 'user',
    typingTimeout: setTimeout(function () {
      callback(e.target.value);
    }, 300),
  });
};
