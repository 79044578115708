import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { buildUrlToSize } from "../../utils/build-image-url";
import { getJourneyNavOption } from "../../utils/get-nav-option";
import PopUpImageGallery from "../popUpImageGallery/PopUpImageGallery";
import Cross from "../../assets/images/xmark-solid.svg";
import ImageGallery from "react-image-gallery";
import cancelBtnSvg from "../../assets/images/Cancel.svg";
import { PhotoSize } from "../utils/subjects";
import Icons from "../Icons/Icons";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";
import { timelineDataContext } from "../jrnyTimline/JrnyTimline";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";

const StoryAdded = ({ isEditable, index, data }) => {
  const { activity } = useSelector(activitySelector);
  const journeyName = journeyName ? journeyName : activity?.name;
  let [popUpImage, setpopUpImage] = useState({
    status: false,
    value: null,
  });
  let modalCondition = (imageUrl) => {
    setpopUpImage((state) => ({ status: !state.status, value: imageUrl }));
  };

  let [storyState, setStoryState] = useState(false);
  let story;
  if (data?.feed?.description && data?.feed?.description.length >= 175) {
    story = storyState ? (
      <p>
        {data?.feed?.description}
        <span
          className="cursor-pointer font-bold text-jrnyfy-themeColor"
          onClick={() => setStoryState((state) => !state)}
        >
          see less
        </span>
      </p>
    ) : (
      <p>
        {data?.feed?.description.slice(0, 175)} ...{" "}
        <span
          className="cursor-pointer font-bold text-jrnyfy-themeColor"
          onClick={() => setStoryState((state) => !state)}
        >
          see more...
        </span>
      </p>
    );
  } else {
    story = <p>{data?.feed?.description}</p>;
  }
  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = "hidden";
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = "scroll";
  };

  useEffect(() => {
    setGalleryImages([]);

    setGalleryImages((prevState) => [
      ...prevState,
      { original: data.thumbnail, thumbnail: data.thumbnail },
    ]);
  }, [data.thumbnail]);

  return (
    <div>
      <div className="flex justify-between items-start">
        <>
          {data?.feed?.user ? (
            <p
              className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold text-jrnyfy-title cursor-pointer"
              onClick={() => {
                // navigate(`/jrny/${data.id}`);
                navigate(getJourneyNavOption(data));
              }}
            >
              {data?.feed?.user?.name} shared a reflection for journey{" "}
              {journeyName}
            </p>
          ) : (
            <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold text-jrnyfy-title ">
              {data?.feed?.user?.name} shared a reflection for journey{" "}
              {journeyName}
            </p>
          )}
          <div className="mt-4 ml-[20px] max-w-[425px]">
            <div className=" pt-[10px]">
              {data?.thumbnail === undefined ? null : (
                <div className="taskimg">
                  <img
                    onClick={openImageGallery}
                    src={buildUrlToSize(data?.thumbnail, PhotoSize.Large)}
                    alt="Place"
                    className="h-[195px] rounded-3xl w-full cursor-pointer object-cover"
                  />
                  <div className="absolute z-20 text-white bottom-[15px] left-0 px-[10px] md:px-[20px] text-[11px] md:text-[18px] font-semibold md:font-medium leading-[17px]">
                    <p>{journeyName}</p>
                  </div>
                </div>
              )}

              {story}
              <div className="ml-[10px] mt-[20px]">
                <button className="p-[8px_15px] bg-jrnyfy-themeColor rounded-[50px] text-white font-medium md:font-semibold font-SourceSansPro">
                  get inspired
                </button>
              </div>
            </div>
            {/* <div className="float-right">
                    <div className="taskimg">
                        <img src={buildUrlToSize(data?.thumbnail)} alt="Place" className="h-[195px] rounded-3xl w-full" />
                        <div className="absolute z-20 text-white bottom-[15px] left-0 px-[10px] md:px-[20px] text-[11px] md:text-[18px] font-semibold md:font-medium leading-[17px]">
                            <p>{journeyName}</p>
                        </div>
                    </div>
                </div> */}
          </div>
        </>
        {isEditable == true && !data?.feed?.user && (
          <div className="ml-[10px] md:ml-[30px]">
            <Icons
              iconClick={() => deleteModalHandle(true)}
              icon={Option}
              alt="Option"
              width="25px"
            />
          </div>
        )}
      </div>
      {deleteModal && (
        <TimelineOptionsModal
          index={index}
          data={data}
          actionStates={actionStates}
          deleteModal={deleteModal}
          deleteModalHandle={deleteModalHandle}
        />
      )}
      {showImageGallery && (
        <div className="fixed flex items-center bg-black top-0 left-0 right-0 bottom-0 m-auto z-40 ">
          <button onClick={closeImageGallery}>
            <img
              src={cancelBtnSvg}
              alt="cross"
              className="cursor-pointer w-[25px] absolute top-[20px] left-[25px] z-50"
            />
          </button>
          <ImageGallery showIndex={true} items={galleryImages} />
        </div>
      )}
    </div>
  );
};

export default StoryAdded;
