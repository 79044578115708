import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../../environments/proxyUrl';
import { parse_SourceArray } from '../mobile/elasticParser';

export const getServices = createAsyncThunk(
  'service/getServices',
  async ({ data, token }, { rejectWithValue }) => {
    console.log('get services res');
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/search/v1/service/`,
        {
          params: { ...data },
          headers: { Authorization: token },
        }
      );
      console.log('get services res', res.results);
      return parse_SourceArray(res.results);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    services: [],
    startDate: '',
    endDate: '',
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getServices.pending]: (state) => {
      state.loading = true;
    },
    [getServices.fulfilled]: (state, action) => {
      state.services = action.payload;
      state.loading = false;
    },
    [getServices.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {} = serviceSlice.actions;

export default serviceSlice.reducer;

export const serviceSelector = (state) => state.service;
