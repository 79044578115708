import React from 'react';
import UserSettingsMenu from './UserSettingsMenu';
import Seo from '../Seo/Seo';

const UserSettingPage = () => {
  return (
    <>
      <Seo
        title={`Settings | update your settings`}
        description=''
        type='webapp'
        name='jrnyfy crop.'
        canonicalHref='/setting'
      />
      <div className='max-w-[660px] pb-[80px] mx-auto px-5 mt-3'>
        <UserSettingsMenu />
      </div>
    </>
  );
};

export default UserSettingPage;
