import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import jrnyfyGrayLogo from '../../assets/images/JRNY-gray-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import Cross from '../../assets/images/xmark-solid.svg';
import Autocomplete from 'react-google-autocomplete';
import {
  authSelector,
  switchToUser,
  vendorProfile_basic_information,
} from '../../redux/slices/mobile/authSlice';
import { useNavigate } from 'react-router-dom';

const PartnerSignupModal = ({ vendorSignUpModalHandle, vendorSignUpModal }) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState();
  const inputRef = useRef();
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);
  const [partnerSteps, setPartnerSteps] = useState({
    step1: true,
    step2: false,
    step3: false,
  });
  const [data, setData] = useState({
    companyDescription: undefined,
    type: { key: undefined },
    theme: { name: undefined },
    name: undefined,
  });
  console.log('Vendor Data', data);
  useEffect(() => {
    if (auth?.user?.vendorProfile) {
      console.log('test', auth?.user?.vendorProfile);
      setData({
        companyDescription: auth?.user?.vendorProfile?.companyDescription,
        type: { key: auth?.user?.vendorProfile?.singleUser },
        theme: { name: auth?.user?.vendorProfile?.theme },
        name: auth?.user?.vendorProfile?.name,
        businessEmail: auth?.user?.vendorProfile?.businessEmail,
        phone: auth?.user?.vendorProfile?.phone,
        address: auth?.user?.vendorProfile?.address?.address,
        city: auth?.user?.vendorProfile?.address?.city,
        country: auth?.user?.vendorProfile?.address?.country,
        unit: auth?.user?.vendorProfile?.address?.unit,
      });
    }
  }, [auth.user]);
  return (
    <Popup
      open={vendorSignUpModal}
      closeOnDocumentClick={false}
      onClose={() => {
        setPartnerSteps((prevState) => ({
          step1: true,
          step2: false,
          step3: false,
        }));
        vendorSignUpModalHandle();
      }}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='space-y-5 bg-white w-[420px] rounded-[20px] p-10 max-[500px]:p-5 max-[500px]:h-screen max-[500px]:overflow-y-scroll max-[500px]:rounded-none'>
        {partnerSteps.step1 && (
          <div className='space-y-5 pt-5'>
            <img
              src={Cross}
              alt='cross'
              className='cursor-pointer w-[25px] absolute top-[20px] left-[25px]'
              onClick={vendorSignUpModalHandle}
            />
            <p className='text-jrnyfy-textprimary'>
              Congratulations! you have signed up to become a partner and help
              your community of users embark and accomplish their journey
            </p>
            <p className='text-center text-jrnyfy-textprimary'>
              Tell us a bit more about services you will provide to help..
            </p>
            <div className='space-y-5'>
              <input
                type='text'
                name='company'
                ref={inputRef}
                className='text-jrnyfy-textprimary border border-jrnyfy-textprimary rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                placeholder='What is the name of your company?'
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name}
              />
              <div className='flex flex-col relative'>
                <textarea
                  spellCheck='true'
                  placeholder='Describe your company'
                  className='text-jrnyfy-textprimary resize-none border border-jrnyfy-textprimary rounded-[15px] min-h-[150px] z-40 break-words focus:outline-none p-2.5'
                  onChange={(e) =>
                    setData({
                      ...data,
                      companyDescription: e.target.value,
                    })
                  }
                  value={data.companyDescription}
                />
              </div>
              {console.log('Data11111', data.theme.name)}
              {/* <input
              type='text'
              name='theme'
              className='text-jrnyfy-textprimary border border-jrnyfy-textprimary rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder='What is the theme of your company?'
            /> */}
              <select
                value={
                  data.theme.name
                    ? data.theme.name
                    : 'What is the theme of your company?'
                }
                onChange={(e) =>
                  setData({ ...data, theme: { name: e.target.value } })
                }
                className='w-full p-[10px] rounded-[64px] text-jrnyfy-textprimary bg-transparent border border-jrnyfy-textprimary'
              >
                <option
                  value=''
                  className='hover:text-jrnyfy-themeColor'
                  disabled
                >
                  What is the theme of your company?
                </option>
                <option
                  value='Tour Guide'
                  className='hover:text-jrnyfy-themeColor'
                >
                  Tour Guide
                </option>
                <option
                  value='Life Coach'
                  className='hover:text-jrnyfy-themeColor'
                >
                  Life Coach
                </option>
              </select>
              <div>
                <span className=' text-jrnyfy-textprimary'>
                  Select the type of services you will offer
                </span>
                <div className='flex items-center gap-5'>
                  <div className='flex items-center'>
                    <input
                      className='mr-2 w-5 h-5'
                      id='company'
                      type='radio'
                      value={0}
                      name='offerType'
                      checked={data.type.key == 0 ? true : false}
                      onChange={(e) =>
                        setData({ ...data, type: { key: e.target.value } })
                      }
                    />
                    <label for='company' className='text-jrnyfy-textprimary'>
                      Company
                    </label>
                  </div>

                  <div className='flex items-center'>
                    <input
                      className='mr-2 w-5 h-5'
                      id='individual'
                      type='radio'
                      name='offerType'
                      value={1}
                      checked={data.type.key == 1 ? true : false}
                      onChange={(e) =>
                        setData({ ...data, type: { key: e.target.value } })
                      }
                    />
                    <label for='individual' className='text-jrnyfy-textprimary'>
                      Individual
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-center'>
              <button
                onClick={() => {
                  dispatch(
                    vendorProfile_basic_information({
                      data: {
                        basicInfo: {
                          companyDescription: data.companyDescription,
                          singleUser: data.type?.key,
                          theme: data.theme?.name,
                          name: data.name,
                        },
                      },
                      token: auth.token,
                      callback: () => {
                        setPartnerSteps((prevState) => ({
                          ...prevState,
                          step2: true,
                          step1: false,
                        }));
                      },
                      errorHandle: (data) => {},
                    })
                  );
                }}
                className='bg-jrnyfy-themeSecondary p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
              >
                NEXT
              </button>
            </div>
          </div>
        )}
        {partnerSteps.step2 && (
          <div className='space-y-5'>
            <p className='text-jrnyfy-textprimary'>
              Tell us how we can get in touch with you?
            </p>
            <div className='space-y-5'>
              <input
                type='text'
                name='theme'
                className='text-jrnyfy-textprimary border border-jrnyfy-textprimary rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                placeholder='Business Email'
                onChange={(e) =>
                  setData({ ...data, businessEmail: e.target.value })
                }
                value={data.businessEmail}
              />
              <input
                type='text'
                name='theme'
                className='text-jrnyfy-textprimary border border-jrnyfy-textprimary rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                placeholder='Phone'
                onChange={(e) => setData({ ...data, phone: e.target.value })}
                value={data.phone}
              />
              <div className='flex gap-3'>
                <input
                  type='text'
                  name='company'
                  className='w-[70%] text-jrnyfy-textprimary border border-jrnyfy-textprimary rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                  placeholder='Street Address'
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                  value={data.address}
                />
                <input
                  type='text'
                  name='company'
                  className='w-[30%] text-jrnyfy-textprimary border border-jrnyfy-textprimary rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                  placeholder='Unit #'
                  onChange={(e) => setData({ ...data, unit: e.target.value })}
                  value={data.unit}
                />
              </div>

              <Autocomplete
                apiKey={'AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE'}
                className='text-jrnyfy-textprimary border border-jrnyfy-textprimary rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                onPlaceSelected={(place) => {
                  const placeData = place.formatted_address.split(',');
                  setData({
                    ...data,
                    city: placeData[0],
                    country: placeData[placeData.length - 1],
                  });
                }}
                options={{
                  types: ['locality', 'country'],
                }}
                value={data.city}
                defaultValue='City'
              />
              <input
                type='text'
                name='theme'
                className='text-jrnyfy-textprimary border border-jrnyfy-textprimary rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                placeholder='Country'
                value={data.country}
              />
            </div>
            <div className='flex justify-center'>
              <button
                onClick={() => {
                  dispatch(
                    vendorProfile_basic_information({
                      data: {
                        address: {
                          address: data.address,
                          city: data?.city,
                          state: data?.state?.name,
                          country: data?.country,
                          unit: data.unit || '',
                        },
                        businessEmail: data?.businessEmail,
                        phone: data?.phone,
                      },
                      token: auth.token,
                      callback: () => {
                        setPartnerSteps((prevState) => ({
                          ...prevState,
                          step2: false,
                          step3: true,
                        }));
                      },
                      errorHandle: (data) => {},
                    })
                  );
                }}
                className='bg-jrnyfy-themeSecondary p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
              >
                DONE
              </button>
            </div>
          </div>
        )}

        {partnerSteps.step3 && (
          <div className='space-y-5 flex flex-col justify-center items-center'>
            <p className='text-jrnyfy-textprimary text-[20px] max-w-[300px] text-center'>
              creating your very own Jrnyfy Partner Profile
            </p>
            <div>
              <img src={jrnyfyGrayLogo} alt='logo' loading='lazy' />
            </div>
            <button
              onClick={() => {
                dispatch(
                  switchToUser({
                    data: {
                      callback: (data) => {
                        navigate('/vendor');
                      },
                    },
                    token: auth.token,
                  })
                );
              }}
              className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
            >
              Ok
            </button>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default PartnerSignupModal;
