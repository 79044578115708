import React, { useState } from 'react'

export default function HorizontalScrollButtons({ buttons, callback }) {
    const [selectedItem,setSelectedItem] = useState(buttons[0])

    return (
        <div className='flex space-x-4 mb-[20px]  mt-[10px] md:mt-[20px] leading-[10px] md:leading-[15px] overflow-x-auto py-3 scrollbar '>
            {buttons.map((item) => (

                <div>
                    <button
                        key={item.name}
                        onClick={() => {
                            setSelectedItem(item)
                            callback(item.value)
                        }}
                        className={
                            (item.id == selectedItem.id
                                ? 'bg-jrnyfy-themeColor'
                                : 'bg-gray-500') +
                            ' p-[8px_15px] rounded-[50px] text-jrny whitespace-nowrap text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[15px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]'
                        }
                    >
                        {item.name}
                    </button>
                </div>

            ))}
        </div>
    )
}
