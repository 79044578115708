import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { commentParser, idsCommentParser } from '../../redux/slices/mobile/elasticParser';
import { getTimelineItemsDetails } from '../../redux/slices/mobile/feedSlice';
import { LikeOrCommentType, YesNo } from '../utils/subjects';
import FeedCard from './FeedCard';
import Comments from '../Comments/Comments';
import Spinner from '../spinner/spinner';

export default function TimelineItemDetails({ item, type, auth }) {
  console.log('CommentItem', item)
  const dispatch = useDispatch()
  const [itemDetails, setItemDetails] = useState()
  const [itemLoading, setItemLoading] = useState(true);
  const [commentLoading, setCommentLoading] = useState(false)
  const [commentsList, setCommentsList] = useState([]);
  const commentRef = useRef(null)



  const getItemsDetails = (ids) => {
    let data = {
      ...item.data.doc,
      withCmt: YesNo.Yes
    }
    if (ids) {
      data['cmtIds'] = ids
    }
    dispatch(getTimelineItemsDetails({
      data,
      token: auth.token,
      callback: async (res) => {
        console.log('itemDetails', res)
        setItemDetails({ ...res, activityId: item.activityId, timelineId: item.timelineId })
        let comments = []
        if (ids) {
          comments = await idsCommentParser(res.comments)
        } else {
          comments = await commentParser(res.comments)
        }
        console.log('comments', comments)
        setCommentsList(comments);
        setItemLoading(false)
      }
    }))
  }

  useEffect(() => {
    if (item?.data?.cmt) {
      setCommentLoading(true);
      let ids = []
      ids.push(item.data.cmt.id)
      if (item.data.comment) {
        ids.push(item.data.comment.id)
      }
      if (item.data.subComment) {
        ids.push(item.data.subComment.id)
      }
      getItemsDetails(ids)
    } else {
      getItemsDetails()
    }
  }, [])

  return (
    <div className='flex flex-col flex-1 h-full'>
      {itemLoading ?
        (
          <div className='flex justify-center items-center h-full'>
            <Spinner />
          </div>
        ) : (
          <Comments
            ref={commentRef}
            comments={commentsList}
            commentItem={itemDetails}
            type={type}
            content={
              <FeedCard
                data={itemDetails}
                key={0} />
            }
          />
        )}
    </div>
  )
}
