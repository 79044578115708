import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { tagPaginationOffset } from '../../../res/dimens';
import { baseUrl } from '../../../environments/proxyUrl';
import { parse_SourceArray } from './elasticParser';

export const getNotificationFilters = createAsyncThunk(
  'notification/filters',
  async ({ token }, { rejectWithValue }) => {
    console.log('get notifications filter2');

    return {
      filters: [
        {
          name: 'all',
          label: 'all',
        },
        {
          name: 'following',
          label: 'following',
        },
        {
          name: 'activities',
          label: 'activities',
        },
        {
          name: 'tasks',
          label: 'tasks',
        },
      ],
    };
  }
);

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async ({ data, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(`${baseUrl}/api/search/v1/ntfs/`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      if (callback) {
        callback(parse_SourceArray(res.results));
      }
      return parse_SourceArray(res.results);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
    errors: [],
    filters: [],
    notification: {},
    loading: false,
  },
  reducers: {
    notificationViewed: (state, action) => {
      const { id } = action.payload;
      let newSet = [];
      for (let i = 0; i < state.notifications.length; i++) {
        if (state.notifications[i].id === id) {
          newSet.push({ ...state.notifications[i], viewed: 1 });
        } else {
          newSet.push(state.notifications[i]);
        }
      }
      state.notifications = newSet;
    },
    addNotification: (state, action) => {
      const { notification } = action.payload;
      if (notification) {
        state.notifications.unshift(notification);
        //state.notifications = state.notifications.unshift(notification);
      }
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.errors = [];
      // state.notifications = [];
      state.loading = true;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload && action.payload.length > 0) {
        state.notifications = action.payload;
      } else {
        state.notifications = [];
      }
      state.loading = false;
    },
    [getNotifications.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getNotificationFilters.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getNotificationFilters.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload.filters) {
        state.filters = action.payload.filters;
      }
      state.loading = false;
    },
    [getNotificationFilters.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.paynotificationSelectorload;
    },
  },
});

export const { notificationViewed, addNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;

export const notificationSelector = (state) => state.notification;
