import { buildUrlToSize } from '../../utils/build-image-url';
import JrnyfyLogo from '../../assets/images/Jrnyfy_Logo.svg';
import { useNavigate } from 'react-router-dom';
import { getJourneyNavOption } from '../../utils/get-nav-option';
import { PhotoSize } from '../utils/subjects';
import { hash } from '../utils/static/global_functions';
import { useEffect, useState } from 'react';

const Myjrny = ({ data, templatePhotoCount }) => {
  let [isThumbnail, setIsThumbnail] = useState(data?.thumbnail || false);
  let navigate = useNavigate();
  const journeyNameToNumber = hash(data.name) % templatePhotoCount || 1;
  console.log('JRNY Data', data);

  return (
    <div>
      <div
        className='max-w-[230px] h-[200px] relative  min-[550px]:taskimg cursor-pointer max-[767px]:max-w-[200px] max-[767px]:h-[170px] max-[550px]:max-w-[150px] max-[550px]:h-[120px] max-[450px]:max-w-[120px] max-[450px]:h-[100px]'
        onClick={() => {
          navigate(getJourneyNavOption(data));
        }}
      >
        {isThumbnail ? (
          <img
            src={buildUrlToSize(data?.thumbnail, PhotoSize.Large)}
            alt='Place'
            className='h-full w-full rounded-3xl object-cover'
            onError={() => setIsThumbnail(false)}
          />
        ) : (
          <img
            src={`https://storage.googleapis.com/jrnyfy/mobile/template-${journeyNameToNumber}-xlr.jpeg`}
            alt='Logo'
            className='h-full w-full rounded-3xl object-cover'
          />
        )}

        <div className='absolute z-20 text-white bottom-[15px] left-0 px-[20px] text-[17px] leading-[17px] max-[550px]:hidden'>
          <p>{data.name.slice(0, 30)}...</p>
        </div>
      </div>
      <div className='min-[550px]:hidden text-[12px] text-jrnyfy-textprimary'>
        <p>{data.name.slice(0, 30)}...</p>
      </div>
    </div>
  );
};

export default Myjrny;
