import React, { useEffect, useState } from 'react';
import Aside from '../Aside/Aside';
import ServicePageSidebar from './ServicePageSidebar';
import { useDispatch, useSelector } from 'react-redux';
import feedSlice, { feedSelector } from '../../redux/slices/mobile/feedSlice';
import { getFeeds } from '../../redux/slices/mobile/feedSlice';
import { FeedCategoryFilter, FeedFilter, FeedType } from '../utils/subjects';
import FeedCard from '../FeedCard/FeedCard';
import stories1 from '../../assets/images/stories1.png';
import stories2 from '../../assets/images/stories2.png';
import stories3 from '../../assets/images/stories3.png';
import userImage from '../../assets/images/user-image.png';
import ServicePageSuccessStory from './ServicePageSuccessStory';
import Progress from '../CardComponents/Progress';
import { TimelineType } from '../utils/subjects';

const ServicePageSuccessStories = () => {
  const { feeds } = useSelector(feedSelector);

  return (
    <div className='mt-10'>
      <div className='inline-flex items-center justify-center w-full mt-[30px]'>
        <hr className='w-full h-px my-4 bg-gray-200 border-0 dark:bg-gray-700' />
        <span className='absolute bg-white italic mb-2 px-3 text-gray-400 font-extrabold text-[25px] -translate-x-1/2 left-1/2'>
          Success Stories
        </span>
      </div>
      <div className='max-w-[1200px] mx-auto flex justify-between'>
        <ServicePageSidebar />
        <div className='space-y-10 max-w-[544px] mt-[50px]'>
          {feeds.map((item) => {
            if (item?.feed?.type == TimelineType.Progress) {
              return <Progress data={item} />;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ServicePageSuccessStories;
