import Popup from "reactjs-popup";
import Cross from "../../assets/images/xmark-solid.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import AddFriends from "../AddFriends/AddFriends";
import AddPlaces from "../AddPlaces/AddPlaces";
import JrnyBody from "./jrnyBody";
import { AnimatePresence } from "framer-motion";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { usePlacesWidget } from "react-google-autocomplete";
import {
  activitySelector,
  createActivity,
} from "../../redux/slices/mobile/activitySlice";
import Login from "../Login/Login";
import { DocTypeElastic, PhotoSource, YesNo } from "../utils/subjects";
import { useNavigate } from "react-router-dom";
import { getJourneyNavOption } from "../../utils/get-nav-option";

const JrnyCreate = ({
  setPostValue,
  postValue,
  setPlaceArr,
  placeArr,
  setUserArr,
  userArr,
  jrnyModal,
  jrnyModalControl,
  loginRequireControl,
}) => {
  usePlacesService({
    apiKey: "AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE",
  });
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPrivacy, setShowPrivacy] = useState(false);
  let [friendState, setFriendState] = useState(false);
  const [privacy, setPrivacy] = useState();
  const [selectedCustomPrivacyPeople, setSelectedCustomPrivacyPeople] =
    useState([]);
  let friendControl = () => {
    setFriendState((state) => !state);
  };
  let [locState, setLocState] = useState(false);
  let locationControl = () => {
    setLocState((state) => !state);
  };

  const privacyHandle = () => {
    setShowPrivacy((prevState) => !prevState);
  };

  console.log("PlaceControl", privacy);

  let jrnyCreateActivity = () => {
    console.log("PlaceList", placeArr);
    console.log("Create Jrny");
    jrnyModalControl();
    if (auth?.user?.verified === 1) {
      dispatch(
        createActivity({
          data: {
            name: postValue.value,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            recordStatus: 4,
            finished: YesNo.No,
            docType: DocTypeElastic.Journey,
            tagChecked: false,
            participants: userArr,
            privacy: privacy,
            customUsers: selectedCustomPrivacyPeople,
            locations: placeArr
              ? placeArr.map((item) => {
                return {
                  locationId: item?.place_id ? item.place_id : item.id,
                  latitude: item?.coordinates.lat
                    ? item?.coordinates.lat
                    : item?.geometry.location.lat(),
                  longitude: item?.coordinates.lon
                    ? item?.coordinates.lat
                    : item?.geometry.location.lat(),
                  name: item.name,
                  photo: item?.photo ? item?.photo : item?.thumbnail ? item?.thumbnail : item.photos && {
                    photoReference: item.photos[0]?.photo_reference, photoSource: PhotoSource.GooglePlace,
                  }
                };
              })
              : undefined,
          },
          callback: (res) => {
            console.log("Res", res);
            navigate(getJourneyNavOption(res));
          },
          auth: auth,
          token: auth.token,
        })
      );
    } else {
      loginRequireControl();
      console.log("Login requires");
    }
  };
  console.log("Place arry", placeArr);
  let Elements;
  if (friendState) {
    Elements = (
      <AddFriends
        headerText={"who will you taken on your journey?"}
        control={friendControl}
        userAdd={setUserArr}
        userArr={userArr}
        placeControl={locationControl}
        friendControl={friendControl}
        jrnyCreateActivity={jrnyCreateActivity}
        friendState={friendState}
      />
    );
  } else if (locState) {
    Elements = (
      <AddPlaces
        placeControl={locationControl}
        friendControl={friendControl}
        showBackBtn={true}
        control={locationControl}
        placeAdd={setPlaceArr}
        placeArr={placeArr}
        jrnyCreateActivity={jrnyCreateActivity}
        locState={locState}
      />
    );
  } else {
    Elements = (
      <JrnyBody
        friendControl={friendControl}
        placeControl={locationControl}
        userList={userArr}
        loginRequireControl={loginRequireControl}
        jrnyModalControl={jrnyModalControl}
        placeList={placeArr}
        postValue={postValue}
        setPostValue={setPostValue}
        jrnyCreateActivity={jrnyCreateActivity}
        privacyHandle={privacyHandle}
        privacy={privacy}
        setPrivacy={setPrivacy}
        showPrivacy={showPrivacy}
        selectedCustomPrivacyPeople={selectedCustomPrivacyPeople}
        setSelectedCustomPrivacyPeople={setSelectedCustomPrivacyPeople}
      />
    );
  }

  return (
    <Popup
      open={jrnyModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      <AnimatePresence>
        <div className="relative w-[600px] h-[500px] bg-white mx-auto space-y-5 py-[40px] rounded-[20px] px-[55px] max-[767px]:p-[16px] max-[767px]:h-full  max-[767px]:overflow-y-scroll max-[600px]:rounded-none ">
          {/* -------cross button-------- */}
          {/* <div className='flex items-start'>
            {friendState || locState ? null : (
              <img
                src={Cross}
                alt='cross'
                className='cursor-pointer w-[20px] '
                onClick={jrnyModalControl}
              />
            )}
            <p className='text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary'>
              start a journey
            </p>
          </div> */}

          {/* ------- main body start---- */}

          {Elements}
        </div>
      </AnimatePresence>
    </Popup>
  );
};

export default JrnyCreate;
