import React, { useEffect, useState } from 'react';
import { createDynamicLink } from '../../components/utils/static/global_functions';
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  FacebookMessengerShareButton,
} from 'react-share';

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from 'react-share';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import backBtn from '../../assets/icons/back.png';

const ShareModal = ({ data, shareModal, shareModalHandle }) => {
  const [shareAbleLink, setShareAbleLink] = useState('');
  console.log('DataActivity', data);
  useEffect(() => {
    createDynamicLink(data).then((res) => {
      console.log('Shared Link', res);
      setShareAbleLink(res);
    });
  }, []);
  console.log('App SHare Link', shareAbleLink);
  return (
    <Popup
      open={shareModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='space-y-3 bg-white rounded-[10px] p-10 max-[500px]:p-5 max-[500px]:h-screen max-[500px]:w-full   max-[500px]:overflow-y-scroll max-[500px]:rounded-none '>
        <div className='flex gap-3 '>
          <button onClick={() => shareModalHandle(false)}>
            <img src={Cross} alt='back button' className='w-[15px] h-[25px]' />
          </button>

          <p className='text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary'>
            Share to show your recent activity
          </p>
        </div>
        <div className='space-x-3'>
          <FacebookShareButton url={shareAbleLink}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={shareAbleLink}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <WhatsappShareButton url={shareAbleLink}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <RedditShareButton url={shareAbleLink}>
            <RedditIcon size={32} round />
          </RedditShareButton>
          <FacebookMessengerShareButton url={shareAbleLink}>
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
        </div>
      </div>
    </Popup>
  );
};

export default ShareModal;
