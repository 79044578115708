import React, { useEffect, useState } from 'react';
import { buildUrlToSize } from '../../res/commonFunctions';
import {
  ParticipantStatus,
  PhotoSize,
  PhotoSource,
  UserContentFilter,
} from '../utils/subjects';
import Thumbnail from '../thumbnails/Thumbnail';
import { getJourneyContent } from '../../redux/slices/mobile/activitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import ParticipantListModal from '../ParticipantListModal/ParticipantListModal';

const UserThumbsWithCounting = ({ groupSize, data }) => {
  console.log('UserThumbWithCounting', data);
  return (
    <div className='flex flex-wrap'>
      {data &&
        data?.map(
          (val, ind) =>
            ind <= 3 && (
              <div className={`${ind != 0 && '-ml-5'}`}>
                <Thumbnail
                  isRedirect={false}
                  btnFollow={false}
                  showName={false}
                  data={val}
                  size={{ width: 30, height: 30 }}
                  border={{ color: 'white', width: 2 }}
                  PhotoSize={PhotoSize.Small}
                  thumbnailContainerStyle={'!w-[50px] !h-[50px] !border-2 !border-white !text-[14px]'}
                />
              </div>
            )
        )}
      {groupSize > 4 && (
        <p className='text-jrnyfy-textprimary'>+{groupSize - 4}</p>
      )}
    </div>
  );
};

export default UserThumbsWithCounting;
