import React, { useState } from "react";
import Popup from "reactjs-popup";
import Thumbnail from "../thumbnails/Thumbnail";
import { format, addDays, addMonths } from "date-fns";
import { convertIntTo12HourFormat } from "../utils/convertIntToHourAndMinute";
import { useDispatch, useSelector } from "react-redux";
import {
  activitySelector,
  cancelSharedTicket,
  shareTicket,
} from "../../redux/slices/mobile/activitySlice";
import QrCodeSvg from "../../assets/images/QRsvg.svg";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { PhotoSize, TicketStatus, YesNo } from "../utils/subjects";
import { QRCodeSVG } from "qrcode.react";
import Cross from "../../assets/images/delete_image.svg";

const ShowTicketsModal = ({ data, showTickets, showTicketsHandle }) => {
  const { activity } = useSelector(activitySelector);
  const { auth } = useSelector(authSelector);
  const [ticket, setTicket] = useState();
  const [showTicketRedeem, setShowTicketRedeem] = useState(false);
  const [showTicketShare, setShowTicketShare] = useState(false);
  const dispatch = useDispatch();
  const participants = activity?.participants?.filter(
    (ele) => ele.id != auth?.user?.id
  );
  console.log("Participants", participants);
  const showTicketShareHandle = (action) => {
    setShowTicketShare(action);
  };

  const ticketRedeemHandle = (action) => {
    setShowTicketRedeem(action);
  };

  return (
    <Popup
      open={showTickets}
      closeOnDocumentClick
      onClose={showTicketsHandle}
      contentStyle={{
        maxWidth: "520px",
        maxHeight: "700px",
        borderRadius: "20px",
      }}
    >
      <div className="p-[40px_20px] relative">
        {/* show the ticket share and redeem options */}
        {!showTicketShare && (
          <div className="px-5 overflow-hidden max-h-[600px] overflow-y-scroll">
            {data?.order?.tickets?.map((item) => {
              return (
                <div className="space-y-5 mb-5">
                  {console.log("Item22", item)}
                  <div className="flex items-start gap-2">
                    <Thumbnail
                      ShowName={false}
                      btnFollow={false}
                      data={item.owner}
                      PhotoSize={PhotoSize.Small}
                    />

                    <div className="flex flex-col">
                      <div className="flex">
                        <div>
                          {item?.serviceName} on{" "}
                          {/* {format(data.order.date, 'dd-LLL-yyyy')} */}
                          {`${
                            convertIntTo12HourFormat(item?.timeSlot?.startTime)
                              .hour
                          }:${
                            convertIntTo12HourFormat(item?.timeSlot?.startTime)
                              .minute
                          } ${
                            convertIntTo12HourFormat(item?.timeSlot?.startTime)
                              .amPm
                          } - ${
                            convertIntTo12HourFormat(item?.timeSlot?.endTime)
                              .hour
                          }:${
                            convertIntTo12HourFormat(item?.timeSlot?.endTime)
                              .minute
                          } ${
                            convertIntTo12HourFormat(item?.timeSlot?.endTime)
                              .amPm
                          }`}
                        </div>
                        <img
                          src={QrCodeSvg}
                          alt="qr code image"
                          className="w-[50px] h-[50px]"
                        />
                      </div>
                      <div className="space-x-2 mt-5">
                        {item.status == TicketStatus.NotRedeemed ? (
                          auth?.user?.id == item?.owner?.id &&
                          item?.owner?.id != item?.user?.id ? (
                            <>
                              {" "}
                              <button
                                onClick={() => {
                                  dispatch(
                                    cancelSharedTicket({
                                      token: auth.token,
                                      data: {
                                        cancelByOwner: YesNo.Yes,
                                        createdAt: item.createdAt,
                                        sk: item.sk,
                                        ticketId: item?.id,
                                      },
                                      timelineId: data.id,
                                      callback: function () {
                                        // setShareStatus(true);
                                      },
                                    })
                                  );
                                }}
                                className="p-[5px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[14px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
                              >
                                stop sharing this ticket
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => {
                                  if (item?.user?.id == auth?.user?.id) {
                                    ticketRedeemHandle(true);
                                    setTicket(item);
                                  }
                                }}
                                className="p-[5px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[14px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
                              >
                                {item.user?.id == auth?.user?.id
                                  ? "Redeem"
                                  : item.user?.id != auth?.user?.id
                                  ? `you don't own this ticket`
                                  : item.status == TicketStatus.Expired &&
                                    "Expired"}
                              </button>

                              <button
                                onClick={() => {
                                  setTicket(item);
                                  showTicketShareHandle(true);
                                }}
                                className="p-[5px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[14px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
                              >
                                Share
                              </button>
                            </>
                          )
                        ) : (
                          <button className="p-[5px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[14px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]">
                            Ticket
                            {item.status == TicketStatus.Redeemed
                              ? "Redeemed"
                              : "Expired"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {/* when click on share this portions is being visible. Show the shareable user list whom with current user can share ticket */}
        {showTicketShare && (
          <div className="px-5 overflow-hidden max-h-[600px] overflow-y-scroll relative">
            <img
              src={Cross}
              alt="cross"
              className="w-[25px] h-[25px] absolute top-5 right-5 z-20"
              onClick={() => showTicketShareHandle(false)}
            />
            {participants &&
              participants.map((item) => {
                return (
                  <div className="space-y-5 mb-5">
                    {console.log("Item22", item)}
                    <div className="flex items-start gap-2">
                      <Thumbnail
                        ShowName={false}
                        btnFollow={false}
                        data={item}
                        PhotoSize={PhotoSize.Small}
                      />

                      <div className="flex flex-col">
                        <div className="flex">
                          <div>{item?.name}</div>
                        </div>
                        <div className="space-x-2 mt-5">
                          <button
                            onClick={() => {
                              dispatch(
                                shareTicket({
                                  data: {
                                    skUser: item.sk,
                                    createdAtUser: item.createdAt,
                                    createdAt: ticket.createdAt,
                                    activityId: activity.id,
                                    userId: item.id,
                                    sk: ticket.sk,
                                    timelineId: data.id,
                                    ticketId: ticket.id,
                                  },
                                  token: auth.token,
                                  timelineId: data.id,
                                  callback: function () {},
                                })
                              );
                            }}
                            className="p-[5px_15px] bg-jrnyfy-themeColor rounded-[50px] text-jrny text-white font-semibold italic hover:scale-[1.1] transition-all font-SourceSansPro min-[350px]:max-[415px]:text-[14px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]"
                          >
                            Share booking
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        {/* Shows the QR code when redeem option clicked */}
        {showTicketRedeem && (
          <>
            <div
              className={`absolute w-fit h-fit m-auto left-0 right-0 bottom-0 top-0 z-20`}
            >
              <QRCodeSVG
                bgColor="#FFFFFF"
                fgColor="#000000"
                size={300}
                level="M"
                style={{ width: 256 }}
                value={`${ticket?.id}_${ticket?.sk}_${ticket?.user?.id}`}
              />
            </div>
            <div className="absolute w-full h-full bg-white top-0 left-0 z-10"></div>
            <img
              src={Cross}
              alt="cross"
              className="w-[25px] h-[25px] absolute top-5 right-5 z-20"
              onClick={() => ticketRedeemHandle(false)}
            />
          </>
        )}
      </div>
    </Popup>
  );
};

export default ShowTicketsModal;
