import React, { useEffect, useRef, useState } from 'react';
import clip from '../../assets/icons/file-upload-icon.svg';
import camera from '../../assets/icons/image-upload-icon.svg';
import sendBtn from '../../assets/icons/send-icon.svg';
import Thumbnail from '../thumbnails/Thumbnail';
import SendMessageLayout from './SendMessageLayout';
import ReceivedMessageLayout from './ReceivedMessageLayout';
import InfoMessageLayout from './InfoMessageLayout';
import editIcon from '../../assets/icons/edit.png';
import checkIcon from '../../assets/icons/checked.png';
import Cross from '../../assets/images/xmark-solid.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMessage,
  blockChatGroup,
  editGroupName,
  messageSelector,
  muteMember,
  pushMessage,
  removeMember,
  replaceMessage,
  resetChatMessage,
  sendMessage,
  updateChat,
  uploadPhoto,
} from '../../redux/slices/mobile/messagingSlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import {
  MessageStatus,
  MessageType,
  MessageUpdateRequestStatus,
  PhotoSize,
  PhotoSource,
  YesNo,
} from '../utils/subjects';
import backBtn from '../../assets/icons/back.png';
import infoIcon from '../../assets/icons/infoIcon.png';
import emojiIcon from '../../assets/icons/emoji.svg';
import EmojiPicker from 'emoji-picker-react';
import AddNewUserToGroup from './AddNewUserToGroup';
import { Link } from 'react-router-dom';
import MembersModal from './MembersModal';

const SendMessages = ({
  isCalling,
  setIsCalling,
  isEnd,
  currentPage,
  setCurrentPage,
  setSelectedChat,
  selectedChat,
  showChatRbSheetHandle,
  isMobileScreen,
}) => {
  const { auth } = useSelector(authSelector);
  const { messages } = useSelector(messageSelector);
  const [typedMessage, setTypedMessage] = useState();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [addNewUserToGroupModal, setAddNewUserToGroupModal] = useState(false);
  const [sendImages, setSendImages] = useState([]);
  const [chatMembers, setChatMembers] = useState();
  const [isRemovedMember, setRemovedMember] = useState();
  const [isMutedMember, setIsMutedMember] = useState();
  const [isBlockedMember, setIsBlockedMember] = useState();
  const bottomDivRef = useRef();
  const [mobileMessageOptions, setMobileMessageOptions] = useState();
  const [editGroupNameEnable, setEditGroupNameEnable] = useState(false);
  const [updatedGroupName, setUpdateGroupName] = useState(selectedChat?.name);
  const [isNewMessage, setIsNewMessage] = useState(true);

  // document.onscroll = () => {
  //   console.log(
  //     'Scrolling ChatMessages',
  //     window.innerHeight + window.scrollY,
  //     document.body.scrollHeight / 2 + window.innerHeight
  //   );
  //   if (
  //     window.innerHeight + window.scrollY >=
  //       document.body.scrollHeight / 2 + window.innerHeight &&
  //     !isEnd
  //   ) {
  //     if (!isCalling) {
  //       setIsCalling(true);
  //       setCurrentPage(currentPage + 30);
  //     }
  //   }
  // };

  const dispatch = useDispatch();
  console.log('selectedChat', selectedChat);
  console.log('MessagesShow', messages);
  const sendMessageHandle = (e) => {
    if (e.repeat) return;
    if (typedMessage) {
      if (e.code == 'Enter' || e.type == 'click') {
        const token = Date.now();

        dispatch(
          addMessage({
            message: {
              message: typedMessage,
              type: MessageType.Text,
              createdAt: new Date(),
              seen: 0,
              token,
              owner: {
                id: auth.user.id,
                name: auth.user.name,
                thumbnail: auth.user.thumbnail,
              },
            },
          })
        );

        dispatch(
          sendMessage({
            data: {
              id: messages?.chat?.id,
              sk: messages?.chat?.sk,
              createdAt: messages?.chat?.createdAt,
              message: typedMessage,
              token,
            },
            token: auth.token,
          })
        );

        setTypedMessage('');

        // dispatch(
        //   resetChatMessage({
        //     chat: { id: messages.chat.id },
        //     message: typedMessage,
        //     updatedAt: new Date(),
        //   })
        // );
      }
    }
  };

  const addNewUserToGroupModalHandle = (action) => {
    setAddNewUserToGroupModal(action);
  };

  const showEmojiPickerHandle = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  const showMembersModalHandle = () => {
    setShowMembersModal((prevState) => !prevState);
  };

  const sendImagesHandle = (e) => {
    let path = [];
    let photoMessage = 'image';
    const formData = new FormData();
    [...e?.target?.files].forEach((item) => {
      console.log('Photo Imtes', item);
      path.push({
        photoReference: URL.createObjectURL(item),
        photoSource: PhotoSource.LocalStorage,
      });
      formData.append('photos', item);
    });
    setSendImages(path);

    formData.append('chatId', selectedChat.id);
    formData.append('sk', selectedChat.sk);
    formData.append('createdAt', selectedChat.createdAt);
    formData.append('message', photoMessage);
    const token = Date.now();
    formData.append('token', token);
    formData.append('id', auth.user.id);

    dispatch(
      addMessage({
        message: {
          message: photoMessage,
          type: MessageType.Photo,
          createdAt: new Date(),
          photos: path,
          seen: 0,
          uploading: true,
          token,
          owner: {
            id: auth.user.id,
            name: auth.user.name,
            thumbnail: auth.user.thumbnail,
          },
        },
      })
    );
    console.log('Phots', path);
    dispatch(
      uploadPhoto({
        formData: formData,
        callback: (res) => {
          console.log('SendMessageResponse', res.message)
          dispatch(
            replaceMessage(res.message)
          );
        },
        token: auth.token
      })
    );
  };
  useEffect(() => {
    console.log('SelectedImages', messages);
  }, [messages]);

  const onMessageScroll = (e) => {
    // console.log(
    //   'onMessageScroll',
    //   Math.abs(e.target.scrollTop + e.target.offsetHeight),
    //   Math.abs(e.target.scrollHeight) / 2,
    //   isCalling,
    //   isEnd
    // );
    if (
      Math.abs(e.target.scrollTop + e.target.offsetHeight) >=
      Math.abs(e.target.scrollHeight) / 2 &&
      !isEnd
    ) {
      if (!isCalling) {
        setIsNewMessage(false);
        setIsCalling(true);
        setCurrentPage(currentPage + 30);
      }
    }
  };

  useEffect(() => {
    if (selectedChat) {
      console.log('Selected Chat', selectedChat);
      //Finding that this user muted this group or not
      let mutedMember = selectedChat?.members?.some(
        (item) => item.muted == YesNo.Yes && item.id == auth?.user?.id
      );
      setIsMutedMember(mutedMember);
      console.log('Muted member', mutedMember);

      //Finding that this user muted this group or not
      let blockedMember = selectedChat?.members?.some(
        (item) => item.blocked == YesNo.Yes && item.id == auth?.user?.id
      );
      setIsBlockedMember(blockedMember);
      console.log('blockedMember', blockedMember);

      //Finding that this user removed from this group or not
      let removedMembers = selectedChat?.members?.filter(
        (item) => item.removed == YesNo.Yes
      );
      let isRemovedMember = removedMembers?.some(
        (item) => item.id == auth?.user?.id
      );
      setRemovedMember(isRemovedMember);

      //Finding only chat members they are not removed
      let chatMembers = selectedChat?.members?.filter(
        (item) => item.id !== auth?.user?.id
      );
      let OnlyMembers = chatMembers?.filter(
        (item) => item.removed != YesNo.Yes
      );
      console.log('Members Only', OnlyMembers);
      setChatMembers(OnlyMembers);
      setCurrentPage(0);
    }
    setEditGroupNameEnable(false);
    setUpdateGroupName(selectedChat?.name);
  }, [selectedChat]);

  const muteChatGroup = (muteStatus) => {
    dispatch(
      muteMember({
        data: {
          status: muteStatus, //pass 1 to mute this chat group or 0 to unmute
          sk: messages.chat.sk,
          createdAt: messages.chat.createdAt,
        },
        chatId: messages.chat.id,
        token: auth.token,
      })
    ).then((res) => {
      console.log('Mute Unmute Status', res);
      if (res?.meta?.requestStatus == 'fulfilled') {
        setIsMutedMember(!isMutedMember);
      }
    });
  };

  const blockChatGroupHandle = (blockStatus) => {
    dispatch(
      blockChatGroup({
        data: {
          status: blockStatus, //pass 1 to mute this chat group or 0 to unmute
          sk: messages.chat.sk,
          createdAt: messages.chat.createdAt,
        },
        chatId: messages.chat.id,
        token: auth.token,
      })
    ).then((res) => {
      console.log('Mute Unmute Status', res);
      if (res?.meta?.requestStatus == 'fulfilled') {
        setIsBlockedMember(!isBlockedMember);
      }
    });
  };

  console.log('isMutedMember', isMutedMember);

  useEffect(() => {
    if (messages?.messages?.length && isNewMessage) {
      const lastChildElement = bottomDivRef.current?.firstElementChild;
      lastChildElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages?.messages?.length]);

  const handleUpdateGroupName = () => {
    setEditGroupNameEnable(false);
    setSelectedChat({ ...selectedChat, name: updatedGroupName });
    dispatch(
      editGroupName({
        data: {
          name: updatedGroupName,
          sk: selectedChat?.sk,
          createdAt: selectedChat?.createdAt,
        },
        id: selectedChat?.id,
        token: auth.token,
      })
    );
  };

  console.log('Mesagetgessdf', messages)

  return (
    <div
      className={`max-w-[745px]  h-full flex flex-col items-stretch content-stretch w-full border-x border-jrnyfy-colorLightGray max-[767px]:border-none max-[767px]:pb-5 max-[767px]:fixed top-0 left-0 bg-white z-40 `}
    >
      <div className='flex items-center justify-between flex-wrap shadow-md px-5 py-5'>
        <button
          onClick={() => showChatRbSheetHandle(false)}
          className='min-[768px]:hidden'
        >
          <img src={backBtn} alt='back button' className='w-[15px] h-[25px]' />
        </button>
        {selectedChat?.group == 0 ? (
          <div className='flex items-center gap-3'>
            <Thumbnail
              btnFollow={false}
              activeIcon={true}
              activeIconStatus={selectedChat?.members[0]?.connected}
              data={selectedChat.members[0]}
              PhotoSize={PhotoSize.Small}
            />
            <div>
              {chatMembers && (
                <div className=''>
                  <p className='font-bold text-jrnyfy-textprimary'>
                    {chatMembers[0]?.name}
                  </p>
                  <p className='text-[12px] text-jrnyfy-textprimary'>
                    {chatMembers[0]?.connected ? 'Active' : 'Offline'}
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='flex items-center gap-2'>
            <div className='flex items-center'>
              {chatMembers?.length >= 0 && (
                <div className=''>
                  <Thumbnail
                    btnFollow={false}
                    data={
                      chatMembers?.length == 0
                        ? { ...auth?.user, name: selectedChat?.name }
                        : chatMembers[0]
                    }
                    PhotoSize={PhotoSize.Small}
                  />
                </div>
              )}
              {chatMembers?.length > 1 && (
                <div className='-ml-[20px]'>
                  <Thumbnail
                    btnFollow={false}
                    data={chatMembers[1]}
                    PhotoSize={PhotoSize.Small}
                  />
                </div>
              )}
            </div>
            <div>
              <div className='flex items-center'>
                {editGroupNameEnable ? (
                  <input
                    type='text'
                    className='bg-gray-100 rounded-sm p-[3px_10px]'
                    onChange={(e) => setUpdateGroupName(e.target.value)}
                    value={updatedGroupName}
                  />
                ) : (
                  <p className='text-jrnyfy-textprimary truncate ... overflow-hidden max-w-[200px] capitalize'>
                    {selectedChat?.name}
                  </p>
                )}
                {editGroupNameEnable ? (
                  <div>
                    <button
                      className='p-2'
                      onClick={() => handleUpdateGroupName()}
                    >
                      <img
                        src={checkIcon}
                        alt='okay icon'
                        className='w-[15px] h-[15px]'
                      />
                    </button>
                    <button
                      className='p-2'
                      onClick={() => setEditGroupNameEnable(false)}
                    >
                      <img
                        src={Cross}
                        alt='cancel icon'
                        className='w-[15px] h-[15px]'
                      />
                    </button>
                  </div>
                ) : (
                  <button
                    className='p-2'
                    onClick={() => setEditGroupNameEnable(true)}
                  >
                    <img
                      src={editIcon}
                      alt='edit icon'
                      className='w-[15px] h-[15px]'
                    />
                  </button>
                )}
              </div>
              {chatMembers?.length > 0 ? (
                <p className='text-[14px] text-jrnyfy-textprimary'>
                  {chatMembers[0]?.name.split(' ', 1)},{' '}
                  {chatMembers[1]?.name.split(' ', 1)}
                  {chatMembers?.length > 2 && (
                    <span>,+ {chatMembers?.length - 2}</span>
                  )}
                </p>
              ) : (
                <p className='text-[14px] text-jrnyfy-textprimary'>
                  no members left
                </p>
              )}
            </div>
          </div>
        )}
        <div className='flex items-center flex-wrap gap-3'>
          {selectedChat?.group == YesNo.Yes && (
            <button onClick={() => addNewUserToGroupModalHandle(true)}>
              Add new
            </button>
          )}
          {selectedChat?.group == YesNo.Yes &&
            (isBlockedMember ? (
              <button onClick={() => blockChatGroupHandle(YesNo.No)}>
                Unblock
              </button>
            ) : (
              <button onClick={() => blockChatGroupHandle(YesNo.Yes)}>
                Block
              </button>
            ))}

          {selectedChat?.group == YesNo.Yes &&
            (isMutedMember == true ? (
              <button onClick={() => muteChatGroup(YesNo.No)}>Unmute</button>
            ) : (
              <button onClick={() => muteChatGroup(YesNo.Yes)}>Mute</button>
            ))}

          <button onClick={() => showMembersModalHandle()}>
            <img
              src={infoIcon}
              alt='info button'
              className='w-[30px] h-[30px]'
            />
          </button>
        </div>
      </div>
      {showMembersModal && (
        <MembersModal
          showMembersModal={showMembersModal}
          showMembersModalHandle={showMembersModalHandle}
          chatMembers={chatMembers}
          selectedChat={selectedChat}
        />
      )}
      {addNewUserToGroupModal && (
        <AddNewUserToGroup
          selectedChat={selectedChat}
          addNewUserToGroupModal={addNewUserToGroupModal}
          addNewUserToGroupModalHandle={addNewUserToGroupModalHandle}
        />
      )}
      <div
        ref={bottomDivRef}
        onScroll={onMessageScroll}
        className='h-full flex flex-col-reverse gap-5 overflow-y-auto px-5 flex-grow'
      >
        {messages?.messages.length > 0 ? (
          messages?.messages.map((item) => {
            console.log('MEssageId', item.id, item);
            return item.type === MessageType.GroupUpdateUserAdded ||
              item.type === MessageType.GroupUpdateUserLeft ||
              item.type === MessageType.GroupUpdate ||
              item.type === MessageType.GroupUpdateMemberRemoved ? (
              <InfoMessageLayout item={item} chat={messages.chat} />
            ) : item?.owner.id == auth.user.id ? (
              item?.status != MessageStatus.Deleted && (
                <SendMessageLayout
                  mobileMessageOptions={mobileMessageOptions}
                  setMobileMessageOptions={setMobileMessageOptions}
                  item={item}
                  chat={messages.chat}
                />
              )
            ) : (
              item?.receivers?.deleted != YesNo.Yes &&
              item?.status != MessageStatus.Deleted && (
                <ReceivedMessageLayout
                  setMobileMessageOptions={setMobileMessageOptions}
                  item={item}
                  chat={messages.chat}
                />
              )
            );
          })
        ) : (
          <p className='text-[14px] text-jrnyfy-textprimary text-center'>
            Be first to send a welcome message or sticker
          </p>
        )}
      </div>
      {isRemovedMember || isBlockedMember ? (
        <div className='bg-jrnyfy-themeColor p-3 text-center pt-3'>
          <p className='text-white'>
            You can't reply this conversation.{' '}
            <Link to={'#'} className=''>
              Learn More
            </Link>
          </p>
        </div>
      ) : (
        <div className='flex justify-between items-center px-5 pt-3 gap-3 max-[767px]:flex-row-reverse'>
          {typedMessage && isMobileScreen ? (
            ''
          ) : (
            <div className='flex items-center gap-2'>
              <div>
                <label className='w-[30px] block max-[550px]:w-[30px]'>
                  <input
                    onChange={sendImagesHandle}
                    type='file'
                    accept='image/*'
                    className='hidden'
                    multiple
                  />

                  <img
                    src={camera}
                    alt='select_image_thumbnail'
                    className='w-[30px]'
                  />
                </label>
              </div>
              <div>
                <label className='w-[30px] block max-[550px]:w-[30px]'>
                  <input
                    type='file'
                    accept='image/*'
                    className='hidden'
                    multiple
                  />

                  <img
                    src={clip}
                    alt='select_image_thumbnail'
                    className='w-[30px]'
                  />
                </label>
              </div>
            </div>
          )}

          <div className='w-full flex items-center gap-3 relative'>
            <input
              className={`bg-gray-100 absolute p-[10px_20px] rounded-[50px] ${typedMessage
                ? 'w-[89.9%] max-[1050px]:w-[85%] max-[767px]:w-[81%]'
                : 'w-full'
                } transition-width duration-1000 ease-out z-10 text-jrnyfy-textprimary`}
              type='text'
              placeholder='type a message....'
              onChange={(e) => setTypedMessage(e.target.value)}
              value={typedMessage}
              onKeyDown={sendMessageHandle}
            />

            {typedMessage && (
              <img
                onClick={sendMessageHandle}
                src={sendBtn}
                alt='select_image_thumbnail'
                className='w-[50px] absolute right-0'
              />
            )}
          </div>
          <div>
            {showEmojiPicker && (
              <div className='absolute bottom-20 left-0 right-0 px-3 flex justify-center'>
                <EmojiPicker
                  onEmojiClick={(e) => {
                    setTypedMessage(typedMessage + e.emoji);
                    setShowEmojiPicker(false);
                  }}
                  width={320}
                />
              </div>
            )}
            <button
              className='min-[767px]:hidden w-[40px] max-[550px]:w-[35px]'
              onClick={showEmojiPickerHandle}
            >
              <img
                src={emojiIcon}
                alt='select_image_thumbnail'
                className='w-full'
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendMessages;
