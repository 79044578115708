import React, { useEffect, useState } from "react";
import { buildUrlToSize } from "../../utils/build-image-url";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import {
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import Moment from "react-moment";
import { Swiper, SwiperSlide } from "swiper/react";
import PopUpImageGallery from "../popUpImageGallery/PopUpImageGallery";
import { FreeMode } from "swiper";
import Cross from "../../assets/images/xmark-solid.svg";
import ImageGallery from "react-image-gallery";
import cancelBtnSvg from "../../assets/images/Cancel.svg";
import { PhotoSize } from "../utils/subjects";
import Thumbnail from "../thumbnails/Thumbnail";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import Icons from "../Icons/Icons";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";

const FinihsedAt = ({ index, data }) => {
  let [popUpImage, setpopUpImage] = useState({
    status: false,
    value: null,
  });
  const { activity } = useSelector(activitySelector);
  const journeyName = data?.activity?.name ? data?.activity?.name : activity?.name;
  let modalCondition = (imageUrl) => {
    setpopUpImage((state) => ({ status: !state.status, value: imageUrl }));
  };
  const user = data?.feed ? data?.feed?.user : data?.user;

  const finishedAtData = data?.feed ? data?.feed : data;

  let userName = data?.feed ? data?.feed?.user?.name : data?.user?.name;
  let photos = data?.gallery || data?.photos;

  let dispatch = useDispatch();

  let navigate = useNavigate();
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;

  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = "hidden";
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = "scroll";
  };

  useEffect(() => {
    setGalleryImages([]);
    photos?.forEach((item) => {
      const imgSrc = buildUrlToSize(item, PhotoSize.Large);
      console.log(imgSrc);
      setGalleryImages((prevState) => [
        ...prevState,
        { original: imgSrc, thumbnail: imgSrc },
      ]);
    });
  }, [photos]);
  return (
    <>
      <div className="flex justify-between items-start">
        <div className="relative">
          <div className="absolute top-0 left-[-24px]">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>
          {data?.feed?.user ? (
            <p
              className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold cursor-pointer"
              onClick={() => {
                // navigate(`/jrny/${data.id}`);
                navigate(getJourneyNavOption(data));
              }}
            >
              {finishedAtData.finishedAt && finishedAtData.description ? (
                <span className="text-jrnyfy-title">
                  {userName} finished story with description:
                </span>
              ) : (
                <>
                  {finishedAtData.finishedAt && (
                    <span className="text-jrnyfy-title">
                      {userName} finished story:
                    </span>
                  )}
                  {finishedAtData.description && (
                    <span className="text-jrnyfy-title">
                      {userName} added story with description:
                    </span>
                  )}
                </>
              )}
              {journeyName}
            </p>
          ) : (
            <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold ">
              {finishedAtData.finishedAt && finishedAtData.description ? (
                <span className="text-jrnyfy-title">
                  {userName} finished story with description:
                </span>
              ) : (
                <>
                  {finishedAtData.finishedAt && (
                    <span className="text-jrnyfy-title">
                      {userName} finished story:
                    </span>
                  )}
                  {finishedAtData.description && (
                    <span className="text-jrnyfy-title">
                      {userName} added story with description:
                    </span>
                  )}
                </>
              )}
              {journeyName}
            </p>
          )}

          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {user.name}, created{" "}
            <Moment fromNow>{data?.createdAt}</Moment>
          </p>
        </div>
      </div>

      <div className="mt-4 ml-[20px] w-auto">
        <div className="mb-[20px] mt-[10px]">
          <p className="text-justify max-[767px]:text-[14px]">
            {finishedAtData.description}
          </p>
        </div>
        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          freeMode={true}
          modules={[FreeMode]}
        >
          {data.gallery &&
            data.gallery.map((val, ind) => (
              <SwiperSlide key={ind}>
                <img
                  src={buildUrlToSize(val, PhotoSize.Large)}
                  alt="places"
                  className="w-[200px] rounded-[10px] h-[100px] md:h-[120px] 2xl:h-[140px] cursor-pointer object-cover"
                  onClick={() => {
                    setGalleryCurrentIndex(ind);
                    openImageGallery();
                  }}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      {showImageGallery && (
        <div className="fixed bg-black flex items-center top-0 left-0 right-0 bottom-0 m-auto z-40 ">
          <button onClick={closeImageGallery}>
            <img
              src={cancelBtnSvg}
              alt="cross"
              className="cursor-pointer w-[25px] absolute top-[20px] left-[25px] z-50"
            />
          </button>
          <ImageGallery
            startIndex={galleryCurrentIndex}
            showIndex={true}
            items={galleryImages}
          />
        </div>
      )}
    </>
  );
};

export default FinihsedAt;
