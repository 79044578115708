import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNetworkStatus } from '../../redux/slices/web/networkStatusSlice';

function NetworkStatus() {
  // Online state
  let dispatch = useDispatch();
  const handleStatusChange = () => {
    console.log('navigator.onLine', navigator.onLine);
    dispatch(setNetworkStatus({ status: navigator.onLine }));
  };

  useEffect(() => {
    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [navigator.onLine]);

  return <></>;
}

export default NetworkStatus;
