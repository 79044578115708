import React from 'react';
import eiffelTower from '../../assets/images/eiffel-tower.png';
import ReactStars from 'react-rating-stars-component';
import checkCircle from '../../assets/images/check-circle-svgrepo-com.svg';
import clock from '../../assets/images/time-svgrepo-com.svg';
import { buildUrlToSize } from '../../utils/build-image-url';
import { useNavigate } from 'react-router-dom';
import { generateServiceDetailsUrl } from '../../utils/get-nav-option';
import { PhotoSize } from '../utils/subjects';

const FilteredProduct = ({ services }) => {
  const navigate = useNavigate();
  const productClickHandle = (val) => {
    navigate(generateServiceDetailsUrl(val), { state: val });
  };
  console.log('Service Name', services);
  return (
    <div className='flex-1 max-w-[850px] space-y-5'>
      {services.length > 0 ? (
        services.map((item) => {
          const Days = parseInt(item.duration / 1440);
          const Hours = parseInt((item.duration % 1440) / 60);
          const Minutes = parseInt(((item.duration % 1440) % 60) % 60);
          return (
            <div className='flex gap-8 border-b pb-5'>
              <div className='flex flex-col items-center'>
                <div className='w-[229px] h-[158px]'>
                  <img
                    className='w-full h-full rounded-[39px]'
                    src={buildUrlToSize(item.thumbnail, PhotoSize.Large)}
                    alt='eiffel tower'
                  />
                </div>
                <p>
                  {item.city},{item.country}
                </p>
                <div className='text-center w-fit'>
                  {item.rating != undefined ? (
                    <ReactStars
                      classNames='leading-[25px]'
                      count={5}
                      size={25}
                      value={item.rating}
                      edit={false}
                      isHalf={true}
                      emptyIcon={<i className='far fa-star'></i>}
                      halfIcon={<i className='fa fa-star-half-alt'></i>}
                      fullIcon={<i className='fa fa-star'></i>}
                      activeColor='#ffd700'
                    />
                  ) : (
                    <span>No Ratings Yet</span>
                  )}
                </div>
              </div>
              <div className='space-y-3 w-full flex flex-col justify-between'>
                <div className='flex justify-between'>
                  <div className='space-y-5'>
                    <div className='flex items-center justify-between'>
                      <h4 class='text-2xl text-gray-600'>{item.name}</h4>
                    </div>
                    <p>{item.description}</p>
                  </div>
                  <div>
                    {item.maxDiscountedMrp == undefined ? (
                      item.mrp == 0 ? (
                        <span className='whitespace-nowrap  text-jrnyfy-themeColor'>
                          <span className='text-[23px]'>Free</span>
                        </span>
                      ) : (
                        <span className='whitespace-nowrap  text-jrnyfy-themeColor'>
                          <span className='text-[23px]'>{item.mrp}</span>
                        </span>
                      )
                    ) : (
                      <div className='flex flex-col items-end'>
                        <span className='whitespace-nowrap  text-jrnyfy-themeColor'>
                          from{' '}
                          <span className='text-[23px]'>
                            {item.maxDiscountedMrp}
                          </span>
                        </span>
                        <span className='text-[17px] line-through'>
                          {item.mrp}
                        </span>
                        <span>
                          save ${parseInt(item.mrp - item.maxDiscountedMrp)}
                        </span>
                        {item.groupDiscountAvailable && (
                          <span className='text-[14px] text-right max-w-[150px]'>
                            Price varies by
                            {item.groupDiscountAvailable && ' group size,'}
                            {item.earlyBirdDiscountAvailable && ' early birds'}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center justify-between gap-3'>
                    {item.duration && (
                      <div className='flex items-center gap-2'>
                        <img src={clock} alt='service-time' />
                        <span>
                          {Days > 0 && Days + ' Days '}
                          {Hours > 0 && Hours + ' Hours '}
                          {Minutes > 0 && Minutes + ' Minutes'}
                        </span>
                      </div>
                    )}
                    {item.freeCancellation ? (
                      <div className='flex items-center gap-2'>
                        <img src={checkCircle} alt='check-circle' />
                        <span>Free Cancelation</span>
                      </div>
                    ) : (
                      <div>
                        {item.cancellationCharges &&
                          item.cancellationCharges.map((item) => {
                            return (
                              <p>
                                if customer cancel before {item.Days} days,
                                {item.percentage}% will be refunded
                              </p>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div className='flex items-center justify-between gap-3'>
                    <button
                      onClick={() => productClickHandle(item)}
                      className='p-[8px_12px_10px_10px] italic rounded-[50px]  border border-jrnyfy-themeColor text-white bg-jrnyfy-themeColor  hover:scale-[1.1] hover:text-jrnyfy-themeColor hover:bg-transparent transition-all font-SourceSansPro'
                    >
                      book now
                    </button>
                    <button className='p-[8px_12px_10px_10px] italic rounded-[50px]  border border-jrnyfy-themeColor text-jrnyfy-themeColor  hover:scale-[1.1] hover:text-white hover:bg-jrnyfy-themeColor transition-all font-SourceSansPro'>
                      add it to your journey
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className='h-[400px] flex items-center justify-center'>
          <div className='max-w-[850px]'>
            <p className='text-[25px] max-w-[500px] text-center text-gray-400'>
              oops... we didn't find anything that matches our search criteria.
              please try again
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilteredProduct;
