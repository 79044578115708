import React from 'react';
import TopTours from './TopTours';
import Aside from '../Aside/Aside';
import ServiceFeatures from './ServiceFeatures';
import ServicePageSuccessStories from './ServicePageSuccessStories';
import Seo from '../Seo/Seo';

const ServicePage = () => {
  return (
    <>
      <Seo
        title='Services'
        description='Book amazing tours, excursions or simply get expert advice for your journey'
        type='webapp'
        name='jrnyfy crop'
        canonicalHref='/service'
      />
      <main className='pt-4'>
        <div className='w-full mx-auto'>
          <div className='w-full'>
            <TopTours />
            <ServiceFeatures />
            <ServicePageSuccessStories />
          </div>
        </div>
      </main>
    </>
  );
};

export default ServicePage;
