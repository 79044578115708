import FeedTags from "../FeedTags/FeedTags";
import FeedCard from "../FeedCard/FeedCard";
import { useSelector, useDispatch } from "react-redux";
import {
  feedSelector,
  getFeeds,
  getSearchedTagLists,
} from "../../redux/slices/mobile/feedSlice";
import { useEffect, useId, useRef, useState, useTransition } from "react";
import Spinner from "../spinner/spinner";
import CreateSuccessStory from "../FeedCard/CreateSuccessStory";
import { HorizontalTapView } from "../utils/HorizontalTabView";
import { feedFilters } from "../../utils/filters";
import CreateStoryPopUp from "../successStory/CreateStoryPopUp";
import UpdateStoryPopUp from "../successStory/UpdateStoryPopUp";
import { FeedCategoryFilter, FeedFilter, ScoreFilter, YesNo } from "../utils/subjects";
import { authSelector, getUsers } from "../../redux/slices/mobile/authSlice";
import { onTypeChange } from "../utils/typing-change";
import UserListRenderer from "../userList/UserListRenderer";
import JrnyCreate from "../jrnyCreate/jrnyCreate";
import { BTN_CREATE_A_NEW_JOURNEY } from "../../utils/static";
import { Facebook } from "react-content-loader";
import Cross from "../../assets/images/delete_image.svg";
import Login from "../Login/Login";
import { useLocation, useNavigate } from "react-router-dom";

const FeedPage = ({
  contents,
  setContents,
  searchedText,
  isLogoClick,
  tagSearchText,
}) => {
  let dispatch = useDispatch();
  const {
    auth: { token },
  } = useSelector(authSelector);
  const [filter, setFilter] = useState(FeedFilter.Trending);
  const [isEnd, setIsEnd] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: "",
    typingTimeout: 0,
  });
  const [currPage, setCurrPage] = useState(0);
  const location = useLocation();
  console.log('Locaiton55',location)
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState(undefined);
  const [loadingState, setLoadingState] = useState(false);
  const [createSuccessStoryModal, setCreateSuccessStoryModal] = useState(false);
  const [updateSuccessStoryModal, setUpdateSuccessStoryModal] = useState(false);
  const [journey, setJourney] = useState("");
  let [userArr, setUserArr] = useState([]);
  let [placeArr, setPlaceArr] = useState([]);
  let [postValue, setPostValue] = useState({
    vissible: true,
    value: undefined,
  });

  let [login, setLogin] = useState(false);
  let loginControl = () => {
    setLogin((state) => !state);
  };

  const updateSuccessStoryHandle = (journey) => {
    setJourney(journey);
    setCreateSuccessStoryModal((prevState) => !prevState);
    setUpdateSuccessStoryModal((prevState) => !prevState);
  };

  let [jrnyModal, setJrnyModal] = useState(false);
  let jrnyModalControl = () => {
    if (jrnyModal) {
      navigate(-1);
    } else {
      window.history.pushState(null, "", location.pathname);
    }
    setJrnyModal((state) => !state);
  };
  const createSuccessStory = () => {
    setCreateSuccessStoryModal((prevState) => !prevState);
  };

  window.addEventListener("popstate", () => {
    if (jrnyModal) {
      // navigate(null, { replace: true });
      setJrnyModal(false);
    }
  });

  document.onscroll = () => {
    // console.log(
    //   'OnScrollValues',
    //   window.innerHeight + window.scrollY,
    //   '>=',
    //   document.body.scrollHeight / 2,
    //   isCalling,
    //   isEnd
    // );
    console.log("Scrollingdddddpage",isEnd,isCalling);
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight / 2 &&
      !isEnd
    ) {
      if (!isCalling) {
        setIsCalling(true);
        setCurrPage(currPage + 10);
      }
    }
  };

  // useEffect(() => {
  //   dispatch(getSearchedTagLists(tagSearchText));
  //   setSearchText(
  //     tagSearchText
  //       .map((item) => {
  //         return item.name;
  //       })
  //       .toString()
  //   );
  //   console.log('tagSearchText', tagSearchText);
  // }, [tagSearchText]);

  const fetchData = () => {
    if (currPage == 0) {
      setContents([]);
    }
    if (filter == -1) {
      dispatch(
        getUsers({
          data: {
            from: currPage,
            size: 10,
            value:
              searchedText && searchedText.length > 0
                ? searchedText
                : undefined,
            scoreFilter: ScoreFilter.LocationTags,
          },
          token,
        })
      ).then((res) => {
        console.log("res", res);
        // if (res.payload.users.length < 10) {
        //   setIsEnd(true);
        // }
        setIsCalling(false);
        if (res.payload.users.length < 10) {
          setIsEnd(true);
        } else {
          setIsEnd(false);
        }
        setContents([...contents, ...res.payload.users]);
        setLoadingState(false);
      });
    } else {
      dispatch(
        getFeeds({
          data: {
            from: currPage,
            size: 10,
            value:
              searchedText && searchedText.length > 0
                ? searchedText
                : undefined,
            filter: filter == FeedFilter.SuccessStory
              ? undefined
              : filter,
            categoryFilter: filter == FeedFilter.SuccessStory
            ? FeedCategoryFilter.Story
            : FeedCategoryFilter.Feed,
          },
          token: token ? token : undefined,
        })
      ).then((res) => {
        console.log('FeedData',res)
        setIsCalling(false);
        if (res.payload.feeds.length < 10) {
          setIsEnd(true);
        } else {
          setIsEnd(false);
        }
        if (currPage == 0) {
          setContents(res.payload.feeds);
        } else {
          setContents([...contents, ...res.payload.feeds]);
        }

        setLoadingState(false);
      });
    }
  };

  useEffect(() => {
    fetchData();
    setLoadingState(true);
  }, [filter, searchedText, tagSearchText, isLogoClick, token, currPage]);

  // useEffect(() => {
  //   setContents(feedArray);
  // }, [feedArray]);

  // useEffect(() => {
  //   setLoadingState(true);
  //   setContents([]);
  //   dispatch(
  //     getFeeds({
  //       data: {
  //         from: 0,
  //         value: searchText && searchText.length > 0 ? searchText : undefined,
  //         tags: tagSearchText ? tagSearchText : undefined,
  //         filter,
  //         categoryFilter: FeedCategoryFilter.Feed,
  //         size: 10,
  //       },
  //       token: token ? token : undefined,
  //     })
  //   ).then((res) => {
  //     setIsCalling(false);
  //     setCurrPage(0);
  //     if (res.payload.feeds.length < 10) {
  //       setIsEnd(true);
  //     } else {
  //       setIsEnd(false);
  //     }
  //     setContents(res.payload.feeds);

  //     setLoadingState(false);
  //   });
  // }, [token, isLogoClick]);

  return (
    <div className="">
      {/* <div className='max-[767px]:hidden max-w-[545px]'>
        <form>
          <div className='relative'> */}
      {/* <input
              type='text'
              placeholder='search journeys, get inspired'
              className='bg-gray-100 w-full p-[10px_20px] rounded-[50px]'
              value={typeInput?.text}
              onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                setCurrPage(0);
                setContents([]);
                setIsEnd(false);
                setSearchText(value);
              })}
            /> */}
      {/* <button
              className="w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]"
              type="button"
            ></button> */}

      {/* {searchText && (
              <div className='absolute m-auto top-0 bottom-0 right-5 w-[25px] h-[25px] rounded-[50px]'>
                <img
                  src={Cross}
                  alt='cross'
                  className='w-[25px] h-[25px]'
                  onClick={() => {
                    const value = '';
                    setTypeInput((state) => ({ ...state, text: '' }));
                    setSearchText('');
                    setTagSearchText([]);
                    setContents([]);
                  }}
                />
              </div>
            )} */}
      {/* </div>
        </form>
      </div> */}

      <div className={"max-w-[545px] my-2 border-jrnyfy-border"}>
        <HorizontalTapView
          items={feedFilters}
          callback={(value) => {
            if (token) {
              setFilter(value);
              setCurrPage(0);
              setContents([]);
            } else {
              if (value == 1) {
                loginControl();
              } else {
                setFilter(value);
                setCurrPage(0);
                setContents([]);
              }
            }
          }}
        />
        {token && (
          <CreateSuccessStory
            btnText={BTN_CREATE_A_NEW_JOURNEY}
            callback={jrnyModalControl}
          />
        )}
      </div>

      {jrnyModal && (
        <JrnyCreate
          setPostValue={setPostValue}
          postValue={postValue}
          setPlaceArr={setPlaceArr}
          placeArr={placeArr}
          setUserArr={setUserArr}
          userArr={userArr}
          jrnyModal={jrnyModal}
          jrnyModalControl={jrnyModalControl}
        />
      )}

      {/* <div className='flex flex-wrap space-x-2 my-6 px-2 md:hidden'>
        <FeedTags to='/' content='New Journeys' />
        <FeedTags to='/' content='Find Tours' />
        <FeedTags to='/' content='Find People' />
      </div> */}
      {contents?.length > 0 && (
        <div className="">
          {filter == -1 ? (
            <>
              <UserListRenderer users={contents} thumbnailContainerStyle={'!w-[100px] !h-[100px]'}  />
            </>
          ) : (
            contents.map((data, ind) => <FeedCard data={data} key={ind} />)
          )}

          {loadingState && (
            <Facebook className="max-w-[545px]" viewBox="0 0 200 124" />
          )}
        </div>
      )}
      {login && (
        <Login
          callback={(value) => {
            setFilter(value);
            setCurrPage(0);
            setContents([]);
          }}
          redirectTo={"none"}
          mState={login}
          setMState={loginControl}
        />
      )}
    </div>
  );
};

export default FeedPage;
