import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const JrnyFloatingMenu = ({ icon, mainText, secText, iconClass, action }) => {
  let [textState, setTextState] = useState(false);
  return (
    <motion.div
      className='flex items-center justify-end cursor-pointer hover:bg-gray-100 rounded-[5px] p-[5px] '
      onClick={action}
    >
      <AnimatePresence>
        <motion.div
          className='ml-[7px] '
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <h4 className={`font-Montserrat text-right`}>{mainText}</h4>
          <p className='text-jrnyfy-textprimary text-right leading-[13px] text-[11px] font-medium '>
            {secText}
          </p>
        </motion.div>
      </AnimatePresence>
      <img src={icon} alt='item' className={`${iconClass}`} />
    </motion.div>
  );
};

export default JrnyFloatingMenu;
