import React from "react";
import { Helmet } from "react-helmet-async";

const Seo = ({ title, description, type, name, canonicalHref }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://www.jrnyfy.com/" />
      <meta
        property="og:image"
        content="https://storage.googleapis.com/jrnyfy/a-memorable-weekend-in-quebec-city-3RRzUvvGcwKqnG1MEHpJdZ-lr.jpeg"
      />
      <link rel="canonical" href={canonicalHref} />
    </Helmet>
  );
};

export default Seo;
