import React, { useCallback, useEffect, useState } from "react";
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  LoginSocialApple,
  IResolveParams,
} from "reactjs-social-login";

import {
  FacebookLoginButton,
  GoogleLoginButton,
  GithubLoginButton,
  AmazonLoginButton,
  InstagramLoginButton,
  LinkedInLoginButton,
  MicrosoftLoginButton,
  TwitterLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  signin,
  userFacebookSignin,
} from "../../redux/slices/mobile/authSlice";
import { SignupType } from "../utils/subjects";

// const REDIRECT_URI =
//   'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
const REDIRECT_URI = "http://localhost:3000/account/login";

const FacebookLogin = () => {
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onLoginStart = useCallback(() => {
    setLoading(true);
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const fbSignIn = (data) => {
    dispatch(
      signin({
        signupType: SignupType.Facebook,
        data_access_expiration_time: data.data_access_expiration_time,
        accessToken: data.accessToken,
        id: data.id,
      })
    ).then((res) => {
      console.log("Response", res);
      if (res.meta.requestStatus == "fulfilled") {
        setLoading(false);
      }
      if (res.meta.requestStatus == "rejected") {
      }
    });
  };

  const onLogout = useCallback(() => {}, []);

  // useEffect(async () => {
  //   let res = await axios.get(
  //     `https://graph.facebook.com/${profile.userID}?fields=id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender&access_token=${profile.accessToken}`
  //   );

  //   console.log('fb data all', res);
  // }, [profile]);
  return (
    <>
      {/* {provider && profile && (
        // <User provider={provider} profile={profile} onLogout={onLogout} />
      )} */}
      <div className={`App ${provider && profile ? "hide" : ""}`}>
        <LoginSocialFacebook
          appId={2395756957250243 || ""}
          fieldsProfile={"id"}
          onLoginStart={onLoginStart}
          onLogoutSuccess={onLogoutSuccess}
          redirect_uri={REDIRECT_URI}
          onResolve={({ provider, data }) => {
            setProvider(provider);
            setProfile(data);
            fbSignIn(data);
            console.log("fb-data", data);
            console.log("fb-provider", provider);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <div className="w-[240px] relative">
            <FacebookLoginButton size="40px" className="!text-[14px]" />

            {loading && (
              <div className="absolute right-3 top-2 ">
                <div className="spinner"></div>
              </div>
            )}
          </div>
        </LoginSocialFacebook>
      </div>
    </>
  );
};

export default FacebookLogin;
