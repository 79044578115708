import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { updatePasswordByEmail } from '../../redux/slices/mobile/authSlice';
import Visible from '../../assets/images/visibility.svg';
import VisibleOff from '../../assets/images/visibility_off.svg';
import Cross from '../../assets/images/xmark-solid.svg';
const ResetPasswordModal = ({
  forgotPassParamsData,
  resetPassModalHandle,
  resetPassModalModal,
}) => {
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [passwordChangeMessage, setPasswordChangeMessage] = useState('');
  const [errors, setErrors] = useState({
    newPassword: '',
    confirmPassword: '',
    common: '',
  });

  const showNewPassHandle = () => {
    setShowNewPass((prevState) => !prevState);
  };

  const showConfirmPassHandle = () => {
    setShowConfirmPass((prevState) => !prevState);
  };

  const resetPassword = () => {
    if (newPassword == '') {
      setErrors({ ...errors, newPassword: 'new password required' });
    } else if (newPassword.length < 6) {
      setErrors({ ...errors, newPassword: 'new password is too short' });
    } else if (newPassword != confirmPassword) {
      setErrors({ ...errors, common: 'password not matched' });
    } else if (confirmPassword == '') {
      setErrors({ ...errors, confirmPassword: 'confirm password required' });
    } else {
      setErrors({ newPassword: true, confirmPassword: true, common: true });
    }
  };

  useEffect(() => {
    console.log('Clicked', errors);
    if (errors.newPassword && errors.confirmPassword && errors.common) {
      dispatch(
        updatePasswordByEmail({
          data: {
            email: forgotPassParamsData?.email,
            otp: forgotPassParamsData?.otp,
            password: newPassword,
          },
          callback: () => {
            setPasswordChangeMessage('Password Changed Successfully');
          },
        })
      );
    }
  }, [errors]);

  return (
    <Popup
      open={resetPassModalModal}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='rounded-[20px] bg-white w-[500px]  p-10 max-[500px]:h-screen  max-[500px]:rounded-none max-[500px]:p-5'>
        <div className='flex items-start gap-3'>
          <img
            src={Cross}
            alt='cross'
            className='cursor-pointer w-[20px] '
            onClick={() => {
              resetPassModalHandle();
            }}
          />

          <p className='w-full text-center text-jrnyfy-textprimary'>
            Reset Password
          </p>
        </div>
        {passwordChangeMessage ? (
          <div>{passwordChangeMessage}</div>
        ) : (
          <div className='p-2 space-y-3'>
            <div className='relative'>
              <input
                type={showNewPass ? 'text' : 'password'}
                placeholder='New password'
                onChange={(e) => setNewPassword(e.target.value)}
                className='border-[3px] border-solid border-jrnyfy-border rounded-[50px] p-[15px_30px] w-full max-[767px]:p-[10px]'
              />
              {!showNewPass ? (
                <img
                  src={VisibleOff}
                  alt='visibility'
                  className='absolute w-[30px] top-[15px] right-[25px]'
                  onClick={showNewPassHandle}
                />
              ) : (
                <img
                  src={Visible}
                  alt='visibility'
                  className='absolute w-[30px] top-[15px] right-[25px]'
                  onClick={showNewPassHandle}
                />
              )}
            </div>

            <div className='relative'>
              <input
                type={showConfirmPass ? 'text' : 'password'}
                placeholder='Confirm password'
                onChange={(e) => setConfirmPassword(e.target.value)}
                className='border-[3px] border-solid border-jrnyfy-border rounded-[50px] p-[15px_30px] w-full max-[767px]:p-[10px]'
              />
              {!showConfirmPass ? (
                <img
                  src={VisibleOff}
                  alt='visibility'
                  className='absolute w-[30px] top-[15px] right-[25px]'
                  onClick={showConfirmPassHandle}
                />
              ) : (
                <img
                  src={Visible}
                  alt='visibility'
                  className='absolute w-[30px] top-[15px] right-[25px]'
                  onClick={showConfirmPassHandle}
                />
              )}
            </div>
            <div className='flex justify-center'>
              <button
                onClick={() => resetPassword()}
                className='bg-jrnyfy-themeColor p-[10px_15px_10px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
              >
                Reset
              </button>
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default ResetPasswordModal;
