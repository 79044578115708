import React from 'react';
// import { Platform } from 'react-native';
// import { check, PERMISSIONS, RESULTS, request } from 'react-native-permissions';
import { PhotoSuffix, PhotoSource, VideoSource } from '../components/utils/subjects';
import { environment } from '../environments/proxyUrl';
//Validates input fields and removes decimals , alphabets , special characters

export const testFnc = (value) => {
  console.log('TEST SOMETHING', value);
};
export const inputValidation_integer_only = (value) => {
  let returnValue = value + '';
  var counter = 0;
  if (value < 0) {
    returnValue = value * -1;
  }
  if (parseInt(value) > 1000) {
    returnValue = '1000';
    return returnValue;
  }
  returnValue = returnValue.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, '');

  returnValue = returnValue.replace(/[^0-9.]|\./g, function ($0) {
    if ($0 == '.' && !counter++) return '.'; // that means we met first dot and we want to keep it
    return ''; // if we find anything else, let's erase it
  });
  console.log('inputValidation_integer_only = ', returnValue);
  return returnValue;
};

//Validates input fields and removes alphabets and special characters
export const inputValidation_discount = (value) => {
  let returnValue = value + '';
  var counter = 0;
  if (value < 0) {
    returnValue = value * -1;
  }
  if (parseInt(value) >= 100) {
    returnValue = '100';
    return returnValue;
  }

  returnValue = returnValue.replace(/[^0-9.]|\./g, function ($0) {
    if ($0 == '.' && !counter++) return '.'; // that means we met first dot and we want to keep it
    return ''; // if we find anything else, let's erase it
  });
  console.log('inputValidation_discount = ', returnValue);
  return returnValue;
};

export const inputValidation_only_float = (value) => {
  let returnValue = value + '';
  var counter = 0;
  if (value < 0) {
    returnValue = value * -1;
  }

  //returnValue = returnValue.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, '');

  returnValue = returnValue.replace(/[^0-9.]|\./g, function ($0) {
    if ($0 == '.' && !counter++) return '.'; // that means we met first dot and we want to keep it
    return ''; // if we find anything else, let's erase it
  });
  return returnValue;
};

export const inputValidation_only_int = (value) => {
  let returnValue = value + '';
  var counter = 0;

  if (value < 0) {
    returnValue = value * -1;
  }

  returnValue = returnValue.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, '');

  returnValue = returnValue.replace(/[^0-9.]|\./g, function ($0) {
    if ($0 == '.' && !counter++) return '.'; // that means we met first dot and we want to keep it
    return ''; // if we find anything else, let's erase it
  });
  return returnValue;
};

// export const askForCameraPermission = async callback_fnc => {
//     let platform = PERMISSIONS.ANDROID.CAMERA;
//     if (Platform.OS == 'ios') {
//         platform = PERMISSIONS.IOS.CAMERA;
//     }

//     check(platform)
//         .then(result => {
//             switch (result) {
//                 case RESULTS.UNAVAILABLE:
//                     console.log(
//                         'This feature is not available (on this device / in this context)',
//                     );
//                     break;
//                 case RESULTS.DENIED:
//                     console.log(
//                         'The permission has not been requested / is denied but requestable',
//                     );
//                     request(platform).then(result => {
//                         console.log('permission results', result);
//                         if (result === 'granted' && callback_fnc) {
//                             callback_fnc();
//                         }
//                     });
//                     break;
//                 case RESULTS.LIMITED:
//                     console.log('The permission is limited: some actions are possible');
//                     if (callback_fnc) {
//                         callback_fnc();
//                     }
//                     break;
//                 case RESULTS.GRANTED:
//                     console.log('The permission is granted');
//                     if (callback_fnc) {
//                         callback_fnc();
//                     }
//                     break;
//                 case RESULTS.BLOCKED:
//                     console.log('The permission is denied and not requestable anymore');
//                     request(platform).then(result => {
//                         console.log('permission results', result);
//                         if (result === 'granted' && callback_fnc) {
//                             callback_fnc();
//                         }
//                     });
//                     break;
//             }
//         })
//         .catch(error => {
//             // …
//             console.log('permission error =', error);
//         });
// };
//Low resulation to high resulation convertion
export const constructXLPhotoUrl = (url) => {
  try {
    const parts = url?.split('.');
    let t = '';
    if (parts?.length > 2) {
      for (let i = 0; i < parts.length - 1; i++) {
        t += parts[i];
        if (i < parts.length - 2) {
          t += '.';
        }
      }
    } else {
      t = parts[0];
    }
    return `${t}${PhotoSuffix}.${parts[parts?.length - 1]}`;
  } catch (e) {
    console.log(' error =', e);
  }
};

export const constructPhotoUrl = (photoReference, size) => {
  // photoReference formate must be: image_name-photo_suffix.jpg
  const parts = photoReference.split('.');
  const parts2 = parts[0].split('-');
  parts2.pop();
  return parts2.join('-') + size + '.' + parts[1];
};

export const changeUrlToSize = (url, size) => {
  const parts = url.split('.');
  const parts2 = parts[0].split('-');
  parts2.pop();
  return parts2.join('-') + '-' + size + '.' + parts[1];
};

export const generateImageUrl_fromGoogleMapReference = (ref) => {
  if (ref) {
    const temp = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${ref}&key=AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE`;
    return temp;
  } else {
    return 0;
  }
};

export const getPhotosUrl = (photoObject, debug) => {
  // if (debug) {
  //   console.log('getPhotosUrl', `${debug}`, photoObject);
  // }

  if (
    photoObject?.photoSource == PhotoSource.UserUploaded ||
    photoObject?.photoSource == PhotoSource.UserUploadedPublicRecord
  ) {
    // return `https://storage.googleapis.com/tag-images-dest/${photoObject?.photoReference}`; //old
    if (environment == 'dev') {
      if (photoObject.updatedAt) {
        return `https://storage.googleapis.com/dev-journifi/${photoObject?.photoReference}?${photoObject.updatedAt}`; //DEV Server
      } else {
        return `https://storage.googleapis.com/dev-journifi/${photoObject?.photoReference}`; //DEV Server
      }
    } else {
      if (photoObject.updatedAt) {
        return `https://storage.googleapis.com/prod-bucket-journifi-1/${photoObject?.photoReference}?${photoObject.updatedAt}`; //Prod Server
      } else {
        return `https://storage.googleapis.com/prod-bucket-journifi-1/${photoObject?.photoReference}`; //Prod Server
      }
    }
  } else if (photoObject?.photoSource == PhotoSource.GooglePlace) {
    return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${photoObject?.photoReference}&key=AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE`;
  } else if (photoObject?.photoSource == PhotoSource.GoogleUser) {
    return photoObject?.photoReference;
  } else if (photoObject?.photoSource == PhotoSource.FacebookUser) {
    return photoObject?.photoReference;
  } else if (photoObject?.photoSource == PhotoSource.LocalStorage) {
    return photoObject?.photoReference;
  }
};

export const buildVideoUrl = obj => {
  if (obj?.videoSource == VideoSource.UserUploaded) {
    const newRef = changeUrlToSize(obj?.videoReference, obj.videoMaxQuality);
    return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET_NAME}/video/${newRef}`;
  } else if (obj?.photoSource == PhotoSource.LocalStorage) {
    return obj?.photoReference;
  }
};

export const buildUrlToSize = (obj, size) => {
  if (
    obj?.photoSource == PhotoSource.UserUploaded ||
    obj?.photoSource == PhotoSource.UserUploadedPublicRecord
  ) {
    const newRef = constructPhotoUrl(obj?.photoReference, size);
    return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET_NAME}/${newRef}`;
  } else if (obj?.photoSource == PhotoSource.GooglePlace) {
    return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${obj?.photoReference}&key=AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE`;
  } else if (obj?.photoSource == PhotoSource.GooglePlaceFullURL) {
    return obj?.photoReference;
  } else if (obj?.photoSource == PhotoSource.GoogleUser) {
    return obj?.photoReference;
  } else if (obj?.photoSource == PhotoSource.FacebookUser) {
    return obj?.photoReference;
  }
  else if (obj?.photoSource == PhotoSource.LocalStorage) {
    return obj?.photoReference;
  }
};

export function timeDifference(previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;
  var elapsed = new Date().getTime() - new Date(previous).getTime();
  if (elapsed < msPerMinute) {
    return 'Created ' + Math.round(elapsed / 1000) + 's ago';
  } else if (elapsed < msPerHour) {
    return 'Created ' + Math.round(elapsed / msPerMinute) + 'm ago ';
  } else if (elapsed < msPerDay) {
    return 'Created ' + Math.round(elapsed / msPerHour) + 'h ago ';
  } else if (elapsed < msPerMonth) {
    return 'Created ' + Math.round(elapsed / msPerDay) + 'd ago ';
  } else if (elapsed < msPerYear) {
    return 'Created ' + Math.round(elapsed / msPerMonth) + 'm ago ';
  } else {
    return 'Created Just now';
  }
}

export function removeSpecialCharacters(str) {
  return str.replace(/[^a-zA-Z ]/g, '');
}

export function sliceText(item, length) {
  const temp = item?.length > length ? item?.slice(0, length) + '...' : item;
  return temp;
}

export function convertDateString(dateString) {
  const aSplit = dateString.split('T');
  const dSplit = aSplit[0].split('-');
  const hmSplit = aSplit[1].split(':');
  var hours = hmSplit[0];
  var minutes = hmSplit[1];
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return {
    year: dSplit[0],
    month: dSplit[1],
    date: dSplit[2],
    hours,
    minutes,
    ampm,
  };
}

export function showUTCHoursMins(date_string) {
  const d = new Date(date_string);
  var hours = d.getUTCHours();
  var minutes = d.getUTCMinutes();
  var am_pm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + am_pm;
  return strTime;
}

export function showLocalHoursMins(date_string) {
  const d = new Date(date_string);
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var am_pm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + am_pm;
  return strTime;
}

export function returnMonthFromDate(date) {
  const month = new Date(date).getUTCMonth;
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[month - 1];
}
