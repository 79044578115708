import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {tagPaginationOffset} from '../../../res/dimens';
import {baseUrl} from '../../../environments/proxyUrl';
import {parse_SourceArray} from './elasticParser';

//TODO: do not exist on server side
export const getIndustries = createAsyncThunk(
  'staticApi/getIndustries',
  async ({token, data}, {rejectWithValue}) => {
    console.log('get products ->1');

    try {
      const {data: res} = await axios.get(`${baseUrl}/api/search/v1/industries`, {
        params: {...data},
        headers: {Authorization: token},
      });
      return parse_SourceArray(res.results);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
//TODO: do not exist on server side

export const getBusinessTypes = createAsyncThunk(
  'staticApi/getBusinessTypes',
  async ({token, data}, {rejectWithValue}) => {
    console.log('get products ->1');

    try {
      const {data: res} = await axios.get(`${baseUrl}/api/search/v1/business`, {
        params: {...data},
        headers: {Authorization: token},
      });
      return parse_SourceArray(res.results);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const staticApiSlice = createSlice({
  name: 'apis',
  initialState: {
    countries: [],
    businessTypes: [],
    industries: [],
  },
  reducers: {},
  extraReducers: {
    [getBusinessTypes.pending]: state => {},
    [getBusinessTypes.fulfilled]: (state, action) => {
      state.businessTypes = action.payload;
    },
    [getBusinessTypes.rejected]: (state, action) => {},
    [getIndustries.pending]: state => {},
    [getIndustries.fulfilled]: (state, action) => {
      state.industries = action.payload;
    },
    [getIndustries.rejected]: (state, action) => {},
  },
});

export const {} = staticApiSlice.actions;

export default staticApiSlice.reducer;

export const staticApiSelector = state => state.apis;
