import React, { useState } from 'react';
import { CurrencySupported } from '../utils/subjects';
import { useDispatch, useSelector } from 'react-redux';
import {
  authSelector,
  updateProfile,
} from '../../redux/slices/mobile/authSlice';
import Popup from 'reactjs-popup';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import Cross from '../../assets/images/xmark-solid.svg';

const CurrencySelectModal = ({
  ShowCurrencyChangeModalHandle,
  showCurrencyChangeModal,
}) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [selectedCurrency, setSelectedCurrency] = useState(
    auth.user && auth.user.currency ? auth.user.currency : CurrencySupported.USD
  );

  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const updateUserCurrencyHandle = () => {
    setActionStates({ ...actionStates, loading: true });
    dispatch(
      updateProfile({
        data: { currency: selectedCurrency.toLowerCase() },
        token: auth.token,
      })
    ).then((res) => {
      console.log('Response', res.meta.requestStatus);
      if (res.meta.requestStatus == 'fulfilled') {
        setActionStates({ ...actionStates, loading: false, fulfilled: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
      if (res.meta.requestStatus == 'rejected') {
        setActionStates({ ...actionStates, loading: false, rejected: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };
  return (
    <Popup
      open={showCurrencyChangeModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='space-y-5 bg-white w-[420px] rounded-[20px] p-10 max-[500px]:p-5 max-[500px]:h-screen max-[500px]:overflow-y-scroll max-[500px]:rounded-none'>
        <div className='flex items-start gap-3'>
          <button onClick={ShowCurrencyChangeModalHandle}>
            <img src={Cross} alt='back button' className='w-[15px] w-[20px]' />
          </button>
        </div>
        <p>Default currency : {selectedCurrency ? selectedCurrency : ''}</p>
        <select
          onChange={(e) => setSelectedCurrency(e.target.value)}
          className='w-full p-[10px] rounded-[64px] text-jrnyfy-textprimary bg-transparent border-2 border-jrnyfy-colorLightGray'
        >
          <option
            value=''
            className='hover:text-jrnyfy-themeColor'
            disabled
            selected
          >
            Select Currency
          </option>
          {Object.keys(CurrencySupported).map((item) => (
            <option
              selected={
                selectedCurrency == CurrencySupported[item] ? true : false
              }
              value={CurrencySupported[item]}
              className='hover:text-jrnyfy-themeColor'
            >
              {item}
            </option>
          ))}
        </select>
        <div className='pb-5 space-y-6'>
          <FunctionalButton
            actionStates={actionStates}
            callback={() => updateUserCurrencyHandle()}
            actionStatesTexts={{
              default: 'Update',
              loading: 'Updating',
              fulfilled: 'Updated Successfully',
              rejected: 'Update Failed',
            }}
          />
        </div>
      </div>
    </Popup>
  );
};

export default CurrencySelectModal;
