import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activitySelector,
  deleteGalleryItem,
  deletePictureFromGallery,
  pushIntoSortedGallery,
  setActivityThumbnail,
} from "../../redux/slices/mobile/activitySlice";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { setActivityThumbnailProfileSlice } from "../../redux/slices/mobile/profileSlice";
import Popup from "reactjs-popup";
import FunctionalButton from "../FunctionalButton/FunctionalButton";
import { timelineDataContext } from "../jrnyTimline/JrnyTimline";

const GalleryImageDeleteModal = ({
  timelineIndex,
  showDeleteModal,
  imageGalleryRef,
  photos,
  currentGalleryIndex,
  showDeleteModalHandle,
  setGalleryCurrentIndex,
}) => {
  const { activity } = useSelector(activitySelector);
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  const { timeLineContent, setTimelineContent } = useContext(
    timelineDataContext && timelineDataContext
  );

  let tempTimelineContent = [...timeLineContent];
  let tempGalleryPhotos = [...photos];

  const deleteGalleryItemHandle = () => {
    setActionStates({ ...actionStates, loading: true });
    dispatch(
      deleteGalleryItem({
        photoReference: photos[currentGalleryIndex]?.photoReference,
        photoSource: photos[currentGalleryIndex]?.photoSource,
        id: activity.id,
        token: auth.token,

        callback: (result) => {
          const { id, thumbnail } = result;
          console.log("resultDeleteGalleryCallback", result);
          dispatch(
            setActivityThumbnail({
              thumbnail: thumbnail,
              id: id,
            })
          );
          dispatch(
            setActivityThumbnailProfileSlice({
              thumbnail: thumbnail,
              id: id,
            })
          );
        },
        sk: activity.sk,
        createdAt: activity.createdAt,
      })
    ).then((res) => {
      console.log("Response", res.meta.requestStatus);
      if (timelineIndex) {
        if (photos.length == 1) {
          tempTimelineContent.splice(timelineIndex, 1);
          setTimelineContent(tempTimelineContent);
          document.body.style.overflowY = "scroll";
        } else {
          console.log(
            "Current Index",
            currentGalleryIndex - 1,
            currentGalleryIndex
          );
          // imageGalleryRef?.current?.slideToIndex(4);
          setGalleryCurrentIndex(
            currentGalleryIndex == 0
              ? currentGalleryIndex
              : currentGalleryIndex - 1
          );
          tempGalleryPhotos.splice(currentGalleryIndex, 1);
          tempTimelineContent[timelineIndex] = {
            ...tempTimelineContent[timelineIndex],
            photos: tempGalleryPhotos,
          };

          setTimelineContent(tempTimelineContent);
          console.log("TimlineTEmp", timeLineContent[timelineIndex]);
        }
      } else {
        console.log(
          "tempGalleryPhotos",
          tempGalleryPhotos[currentGalleryIndex]
        );
        setGalleryCurrentIndex(
          currentGalleryIndex == 0
            ? currentGalleryIndex
            : currentGalleryIndex - 1
        );
        // tempGalleryPhotos.splice(currentGalleryIndex, 1);
        dispatch(
          deletePictureFromGallery({
            photoReference:
              tempGalleryPhotos[currentGalleryIndex].photoReference,
          })
        );
      }

      if (res.meta.requestStatus == "fulfilled") {
        setActionStates({ ...actionStates, loading: false, fulfilled: true });
        showDeleteModalHandle(false);
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
      if (res.meta.requestStatus == "rejected") {
        setActionStates({ ...actionStates, loading: false, rejected: true });
        showDeleteModalHandle(false);
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };
  return (
    <Popup
      open={showDeleteModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: "400px",
        borderRadius: "20px",
        border: "none",
      }}
    >
      <div>
        <div className="space-y-3 p-10">
          <p className="text-jrnyfy-textprimary font-bold">
            Are you sure want to delete this item?
          </p>
          <div className="flex gap-3">
            <FunctionalButton
              actionStates={actionStates}
              callback={() => deleteGalleryItemHandle()}
              actionStatesTexts={{
                default: "Yes",
                loading: "Deleting",
                fulfilled: "Deleted Successfully",
                rejected: "Delete Failed",
              }}
              text={{ size: "12px", weight: "bold" }}
              padding={"5px_15px"}
            />
            <button
              className="bg-jrnyfy-border rounded-[50px] p-[5px_15px] text-[12px] font-bold text-jrnyfy-textprimary"
              onClick={() => showDeleteModalHandle(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default GalleryImageDeleteModal;
