import React, { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { emailChange } from '../../redux/slices/mobile/authSlice';
import { emailChangeParams } from '../../utils/get-nav-option';
import ToastModal from '../ToastModal/ToastModal';

const EmailChangePage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const emailChangeParamsData = emailChangeParams(searchParams);
  const [toastModal, setToastModal] = useState({ status: false, value: '' });
  const toastModalHandle = (status, value) => {
    setToastModal({
      status: status,
      value: value,
    });
  };
  useEffect(() => {
    console.log('Response');
    dispatch(
      emailChange({
        data: {
          email: emailChangeParamsData?.email,
          sk: emailChangeParamsData?.sk,
          createdAt: emailChangeParamsData?.createdAt,
          otp: emailChangeParamsData?.otp,
        },
        id: emailChangeParamsData?.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus == 'fulfilled') {
        toastModalHandle(true, 'Email Updated Successfully');
      }
      if (res.meta.requestStatus == 'rejected') {
        toastModalHandle(true, 'Something went wrong! Try again.');
      }
    });
  }, []);

  return (
    <div>
      {toastModal.status && (
        <ToastModal
          text={toastModal.value}
          toastModal={toastModal.status}
          toastModalHandle={toastModalHandle}
        />
      )}
    </div>
  );
};

export default EmailChangePage;
