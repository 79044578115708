import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {tagPaginationOffset} from '../../../res/dimens';
import {baseUrl} from '../../../environments/proxyUrl';
import {parse_SourceArray} from './elasticParser';

export const createStripeIntent = createAsyncThunk(
  'payment/createStripeIntent',
  async ({data, id, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/user/promotion/stripe/create/intent/${id}`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const startPromotion = createAsyncThunk(
  'payment/startPromotion',
  async ({data, token, id}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.post(
        `${baseUrl}/api/payments/v1/start-promotion/${id}`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// export const getPayments_user = createAsyncThunk(
//   'payment/getPayments_user',
//   async ({data, token}, {rejectWithValue}) => {
//     try {
//       const response = await axios.get(
//         `${baseUrl}/api/search/v1/payments/history/user/`,
//         {
//           params: {...data},
//           headers: {Authorization: token},
//         },
//       );

//       // const filterItems = response.data.payload.products.map((item, index)=>{
//       //   return {...item._source}
//       // });
//       return {
//         contentRange: response.headers['content-range'],
//         payments: await parse_SourceArray(response.data.results),
//       };
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

// export const getPayments_vendor = createAsyncThunk(
//   'payment/getPayments_vendor',
//   async ({data, token}, {rejectWithValue}) => {
//     try {
//       const response = await axios.get(
//         `${baseUrl}/api/payments/v1/vendor/payments/`,
//         {
//           params: {...data},
//           headers: {Authorization: token},
//         },
//       );

//       return {
//         contentRange: response.headers['content-range'],
//         payments: await parse_SourceArray(response.data.results),
//       };
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const getPayments_vendor = createAsyncThunk(
  'payment/getPayments_vendor',
  async ({token, data, callback}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.get(
        `${baseUrl}/api/payments/v1/v-payments/`,
        {
          params: {...data},
          headers: {
            Authorization: token,
          },
        },
      );
      if (callback) {
        callback(res.results);
      }
      return {
        skip: data.skip,
        limit: data.limit,
        transactions: res.results,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

// export const cancelPaymentForBooking = createAsyncThunk(
//   'payment/cancelPaymentForBooking',
//   async ({token, activityId}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.put(
//         `${baseUrl}/api/payments/v1/user/booking/unlock/${activityId}`,
//         {},
//         {headers: {Authorization: token}},
//       );
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

//TODO: do not found on server side
export const refundByVendor = createAsyncThunk(
  'payment/refundByVendor',
  async ({data, id, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/v-refund/${id}`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const uploadIdentity = createAsyncThunk(
  'payment/uploadIdentity',
  async ({formData, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.post(
        `${baseUrl}/api/payments/v1/s-identity-doc`,
        formData,
        {
          headers: {
            Authorization: token,
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          },
        },
      );
      return res.results;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  },
);

export const getBalanceStatement = createAsyncThunk(
  'payment/getBalanceStatement',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.get(
        `${baseUrl}/api/payments/v1/s-balance`,
        {
          params: {...data},
          headers: {Authorization: token},
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export const getTransactions = createAsyncThunk(
  'payment/getTransactions',
  async ({data, productId, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.get(
        `${baseUrl}/api/payments/v1/r-chart/`,
        {
          params: {...data},
          headers: {Authorization: token},
        },
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const payoutByVendor = createAsyncThunk(
  'payment/payoutByVendor',
  async ({data, token}, {rejectWithValue}) => {
    try {
      const {data: res} = await axios.put(
        `${baseUrl}/api/payments/v1/s-payout/`,
        data,
        {headers: {Authorization: token}},
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    stripeIntent: {},
    loadingStrip: false,
    payments: [],
    balanceStatement: [],
  },
  reducers: {},
  extraReducers: {
    [createStripeIntent.pending]: state => {
      state.stripeIntent = {};
      state.loadingStrip = true;
    },
    [createStripeIntent.fulfilled]: (state, action) => {
      state.stripeIntent = action.payload;
      state.loadingStrip = false;
    },
    [createStripeIntent.rejected]: (state, action) => {
      state.loadingStrip = false;
    },

    [startPromotion.pending]: state => {},
    [startPromotion.fulfilled]: (state, action) => {},
    [startPromotion.rejected]: (state, action) => {},
    [getPayments_vendor.pending]: state => {
      state.errors = [];
      state.ideas = [];
      state.loading = true;
    },
    [getPayments_vendor.fulfilled]: (state, action) => {
      state.errors = [];
      state.payments = action.payload.payments;
      state.contentRangeIdea = action.payload.contentRange;
      state.loading = false;
    },
    [getPayments_vendor.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getBalanceStatement.pending]: state => {
      state.errors = [];
      state.balanceStatement = [];
      state.loading = true;
    },
    [getBalanceStatement.fulfilled]: (state, action) => {
      state.errors = [];
      state.balanceStatement = action.payload;
      state.contentRangeIdea = action.payload.contentRange;
      state.loading = false;
    },
    [getBalanceStatement.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    // [cancelPaymentForBooking.pending]: state => {},
    // [cancelPaymentForBooking.fulfilled]: (state, action) => {},
    // [cancelPaymentForBooking.rejected]: (state, action) => {},
  },
});

export const {} = paymentSlice.actions;

export default paymentSlice.reducer;

export const paymentSelector = state => state.payment;
