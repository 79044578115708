import FeedCard from '../FeedCard/FeedCard';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { createContext, useEffect, useState } from 'react';
import {
  activitySelector,
  getTimeLineForJourney,
  getActivityById,
  deleteTagFromActivity,
} from '../../redux/slices/mobile/activitySlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { buildUrlToSize } from '../../utils/build-image-url';
import { PhotoSize, YesNo } from '../utils/subjects';
import TimeLineSlider from '../timeLineSlider/timeLineSlider';
import { feedSelector } from '../../redux/slices/mobile/feedSlice';
import { useParams, useLocation, useNavigation } from 'react-router-dom';
import MyJrnyControl from './myJrnyControl';
import completeJrny from '../../assets/images/Complete_your_Jrny.svg';
import addThings from '../../assets/images/Add_things_to_do.svg';
import addProgress from '../../assets/images/add_journey_check_in.svg';
import addMilestone from '../../assets/images/add_milestones.svg';
import addPeople from '../../assets/images/Friends_Icon.svg';
import addPlace from '../../assets/images/button_menu_places.svg';
import AddMilestone from './addMilestone';
import AddProgress from './addProgress';
import JrnyTimlineAddPlace from './jrnyTimelineAddPlace';
import JrnyTimlineAddPeople from './jrnyTimelineAddPeople';
import Spinner from '../spinner/spinner';

import Aside from '../Aside/Aside';
import {
  getTypeHeadServicesNavOption,
  parseJourneyUrlParams,
} from '../../utils/get-nav-option';
import { Facebook } from 'react-content-loader';
import Cross from '../../assets/images/xmark-solid.svg';
import { deleteTagFromProduct } from '../../redux/slices/mobile/productSlice';
import tagSvg from '../../assets/images/tag-svgrepo-com.svg';
import TagList from '../TagList/TagList';
import TagListModal from './TagListModal';
import SliderServices from '../slider services/SliderServices';
import floating_button_menu_advance_jrny from '../../assets/images/button_menu_advance_jrny.svg';
import JrnyFloatingMenu from './JrnyFloatingMenu';
import Seo from '../Seo/Seo';
import AddProgressPics2 from './AddProgressPics2';
const timelineDataContext = createContext();

const JrnyTimline = () => {
  let authinfo = useSelector(authSelector).auth;

  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  const { loading, activity, ideas } = useSelector(activitySelector);
  let [timeLineContent, setTimelineContent] = useState();

  let avatar = buildUrlToSize(authinfo?.user?.thumbnail, PhotoSize.Large);
  let altTitle = authinfo?.user?.name.slice(0, 1);
  let feedItemArr = useSelector(feedSelector).feeds;
  let dispatch = useDispatch();
  // let { id } = useParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  let [milestoneModal, setMilestoneModal] = useState(false);
  let [progressPic, setProgressPic] = useState(false);
  let [progressPic2, setProgressPic2] = useState(false);
  let [addPlaceModal, setAddPlaceModal] = useState(false);
  let [addPeopleModal, setAddPeopleModal] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const navigate = useNavigate();
  const [isEnd, setIsEnd] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const type = 'journey';
  console.log('activity ddd', activity);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  const showFloatingMenuHandle = (action) => {
    if (action) {
      window.history.pushState(null, '', location.pathname);
    } else {
      navigate(-1);
    }
    setShowFloatingMenu(action);
  };

  const tagListModalHandle = (action) => {
    if (action) {
      window.history.pushState(null, '', location.pathname);
    } else {
      navigate(-1);
    }
    setShowTagsModal(action);
  };

  const progressPicHandle = (action) => {
    if (action) {
      window.history.pushState({ isFloatingMenu: true }, '', location.pathname);
    } else {
      navigate(-1);
    }
    setProgressPic(action);
  };

  const milestoneModalHandle = (action) => {
    if (action) {
      window.history.pushState({ isFloatingMenu: true }, '', location.pathname);
    } else {
      navigate(-1);
    }
    setMilestoneModal(action);
  };

  const addPeopleModalHandle = (action) => {
    if (action) {
      window.history.pushState({ isFloatingMenu: true }, '', location.pathname);
    } else {
      navigate(-1);
    }
    setAddPeopleModal(action);
  };

  const addPlaceModalHandle = (action) => {
    if (action) {
      window.history.pushState({ isFloatingMenu: true }, '', location.pathname);
    } else {
      navigate(-1);
    }
    setAddPlaceModal(action);
  };

  document.onscroll = () => {
    if (
      window.innerHeight + window.scrollY >=
        document.body.scrollHeight / 2 + window.innerHeight &&
      !isEnd
    ) {
      if (!isCalling) {
        setIsCalling(true);
        setCurrPage(currPage + 10);
      }
    }
  };
  console.log('Current Page', currPage,authinfo);
  const timeLine = () => {
    if (currPage >= 10) {
      setScrollLoading(true);
      dispatch(
        getTimeLineForJourney({
          data: {
            skip: currPage,
            limit: 10,
            isPublic: YesNo.Yes,
            createdAt: activity.createdAt,
            sk: activity.sk,
          },
          token: authinfo?.token,
          activityId: activity.id,
          callback: (res) => {
            setIsCalling(false);
            setScrollLoading(false);
            console.log('TimeLine Response', res);
            setTimelineContent([...timeLineContent, ...res]);
            if (res?.length < 10) {
              setIsEnd(true);
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    timeLine();
  }, [currPage]);

  const removeTag = (tag) => {
    if (type == 'journey') {
      dispatch(
        deleteTagFromActivity({
          data: {
            sk: activity.sk,
            createdAt: activity.createdAt,
          },
          token: authinfo.token,
          id: activity.id,
          tagName: tag.name,
          tagId: tag.id,
          callback: (result) => {
            console.log('Deleted Task Result', result);
          },
        })
      );
    }
  };

  useEffect(() => {
    setDataLoading(true);
    const jp = parseJourneyUrlParams(searchParams);
    if (jp) {
      dispatch(
        getActivityById({
          auth:authinfo,
          token: authinfo?.token,
          id: jp.id,
          templatePhotoCount: authinfo.templatePhotoCount,
          createdAt: jp.createdAt,
          sk: jp.sk,
          recordStatus: jp.recordStatus,
          callback: (res) => {
            setDataLoading(false);
            setTimelineContent(res.timelines);
            if (res?.timelines?.length < 10) {
              setIsEnd(true);
            }
          },
        })
      );
    }
  }, []);

  window.addEventListener('popstate', (e) => {
    console.log('PopstateCalled');
    if (showTagsModal) {
      setShowTagsModal(false);
    } else if (progressPic) {
      setProgressPic(false);
    } else if (milestoneModal) {
      setMilestoneModal(false);
    } else if (addPeopleModal) {
      setAddPeopleModal(false);
    } else if (addPlaceModal) {
      setAddPlaceModal(false);
    } else if (showFloatingMenu && !e.state?.isFloatingMenu) {
      setShowFloatingMenu(false);
    }
  });

  return (
    <timelineDataContext.Provider
      value={{
        timeLineContent,
        setTimelineContent,
      }}
    >
      {/* <div className="flex max-w-[540px] space-x-1">
                {authinfo.user ?
                    <div className='flex justify-center items-center w-[50px] h-[50px] rounded-[50%] bg-jrnyfy-themeColor text-white text-[30px] text-center'>
                        {avatar === undefined ? altTitle : <img src={avatar} alt={altTitle} className='inline-block w-[50px] h-[50px] rounded-[50%]' />}
                    </div> : ''}

                <div className='w-full'>
                    <form>
                        <div className="relative">
                            <input type='search' placeholder="search journeys" className="bg-gray-100 w-full p-[10px_20px] rounded-[50px]" />
                            <button className="w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]"></button>
                        </div>
                    </form>
                </div>
            </div> */}
      <Seo
        title={`${activity?.name?.slice(0, 15)}... | Timeline`}
        description=''
        type='webapp'
        name='jrnyfy crop.'
      />

      <main className=''>
        <div className='w-[100%] min-[1100px]:w-[75%] mx-auto flex justify-center min-[3000px]:w-1/2'>
          <aside className='hidden md:block md:w-[30%] mt-[-127px]'>
            <Aside />
          </aside>
          <div className='w-full md:w-[70%] md:px-[20px]'>
            {activity.sk ? (
              <div className='relative'>
                {activity && (
                  <TimeLineSlider
                    setTimelineContent={setTimelineContent}
                    timeLineContent={timeLineContent}
                    data={activity}
                  />
                )}

                {activity?.isEditable == 1 ? (
                  <div className='max-[767px]:hidden absolute top-0 left-[545px]'>
                    {/* ---complete your jrny--- */}
                    {/* <MyJrnyControl
                      icon={completeJrny}
                      mainText='complete your jrny'
                      secText='reflects review and share your experience to other'
                      iconClass='w-[50px]'
                    /> */}

                    {/* ---add things to do--- */}
                    {/* <MyJrnyControl
                      icon={addThings}
                      mainText='add things to do'
                      secText='add experience to your journey'
                      iconClass='w-[50px]'
                      action={() => {
                        navigate(
                          getTypeHeadServicesNavOption({
                            tag: activity?.tags.map((item) => item.name),
                          })
                        );
                      }}
                    /> */}

                    {/* ---add a progress pic--- */}
                    <MyJrnyControl
                      icon={addProgress}
                      mainText='add a progress pic or reflection'
                      secText='keep track on your journey progress'
                      iconClass='w-[50px]'
                      action={() => {
                        progressPicHandle(true);
                      }}
                    />
                    {progressPic && (
                      <AddProgress
                        setDataLoading={setDataLoading}
                        setTimelineContent={setTimelineContent}
                        timeLineContent={timeLineContent}
                        modalState={progressPic}
                        modalControl={progressPicHandle}
                        jrnyActivity={activity}
                      />
                    )}

                    {/* ---add a milestone--- */}
                    <MyJrnyControl
                      icon={addMilestone}
                      mainText='add a milestone'
                      secText='add milestones and tasks and set due dates'
                      iconClass='w-[50px]'
                      action={() => {
                        milestoneModalHandle(true);
                      }}
                    />
                    {milestoneModal && (
                      <AddMilestone
                        setDataLoading={setDataLoading}
                        modalState={milestoneModal}
                        setTimelineContent={setTimelineContent}
                        timeLineContent={timeLineContent}
                        modalControl={milestoneModalHandle}
                      />
                    )}

                    {/* ---add places--- */}
                    <MyJrnyControl
                      icon={addPlace}
                      mainText='add places to see'
                      secText='add place where journey will take place'
                      iconClass='w-[50px]'
                      action={() => {
                        addPlaceModalHandle(true);
                      }}
                    />
                    {addPlaceModal && (
                      <JrnyTimlineAddPlace
                        setDataLoading={setDataLoading}
                        setTimelineContent={setTimelineContent}
                        timeLineContent={timeLineContent}
                        modalState={addPlaceModal}
                        modalControl={addPlaceModalHandle}
                        jrnyActivity={activity}
                      />
                    )}

                    {/* ---add people--- */}
                    <MyJrnyControl
                      icon={addPeople}
                      mainText='add people'
                      secText='who will join on your journey'
                      iconClass='w-[50px]'
                      action={() => {
                        addPeopleModalHandle(true);
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <Facebook className='max-w-[545px]' viewBox='0 0 200 124' />
            )}
            {dataLoading && (
              <Facebook className='max-w-[545px]' viewBox='0 0 200 124' />
            )}

            <div className='mb-32 max-w-[545px] px-[15px]'>
              <div className='mt-5'>
                {timeLineContent?.map((data, ind) => (
                  <FeedCard
                    isEditable={activity.isEditable}
                    name={activity?.name}
                    data={data}
                    key={ind}
                    index={ind}
                    isTimeline = {true}
                    length={timeLineContent.length}
                  />
                ))}
                {scrollLoading && (
                  <Facebook className='max-w-[545px]' viewBox='0 0 200 124' />
                )}
                {/* {ideas?.length > 0 && (
                  <div className='pl-8'>
                    <div className='my-5'>
                      <h4 class='text-jSecTitle font-semibold md:overflow-hidden md:whitespace-nowrap md:text-ellipsis'>
                        Complete your journey faster!
                      </h4>
                      <p>
                        Services, experiences and guides can help you complete
                        your journey faster. it only takes a second to book
                      </p>
                    </div>
                    <SliderServices
                      slidesPerView={2}
                      spaceBetween={5}
                      services={ideas}
                    />
                  </div>
                )} */}
              </div>
              <div className='flex flex-wrap gap-3 pl-8'>
                {activity.isEditable == 1 && (
                  <button
                    onClick={() => tagListModalHandle(true)}
                    className='bg-jrnyfy-textprimary p-[5px_10px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic'
                  >
                    <div className='flex'>
                      <img
                        src={tagSvg}
                        alt='remove'
                        className='w-[25px] mr-2'
                      />
                      Add Tags
                    </div>
                  </button>
                )}
                {activity?.tags && (
                  <div className=' space-x-1 space-y-1'>
                    {activity?.tags.map((tag) => {
                      return (
                        <button className='bg-jrnyfy-textprimary p-[5px_10px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic'>
                          <div className='flex'>
                            {tag.name}
                            <img
                              src={Cross}
                              alt='remove'
                              className='w-[10px] ml-2'
                              onClick={() => {
                                removeTag(tag);
                              }}
                            />
                          </div>
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            {activity?.isEditable == 1 && (
              <div className='sticky bottom-0 z-40 flex justify-end min-[767px]:hidden'>
                <img
                  onClick={() => showFloatingMenuHandle(true)}
                  className='w-[70px] h-[70px] absolute right-1 bottom-24'
                  src={floating_button_menu_advance_jrny}
                  alt='floating-button'
                />
              </div>
            )}

            {showFloatingMenu && (
              <div className='fixed h-[100vh] top-0 pb-[220px] flex flex-col justify-end bg-white/[0.98] z-30 w-full h-screen pt-5'>
                <img
                  src={Cross}
                  alt='cross'
                  className='cursor-pointer w-[25px] absolute top-[19px] left-[25px]'
                  onClick={() => showFloatingMenuHandle(false)}
                />
                {/* ---complete your jrny--- */}
                {/* <JrnyFloatingMenu
                  icon={completeJrny}
                  mainText='complete your jrny'
                  secText='reflects review and share your experience to other'
                  iconClass='w-[50px]'
                /> */}

                {/* ---add things to do--- */}
                {/* <JrnyFloatingMenu
                  icon={addThings}
                  mainText='add things to do'
                  secText='add experience to your journey'
                  iconClass='w-[50px]'
                  action={() => {
                    navigate(
                      getTypeHeadServicesNavOption({
                        tag: activity?.tags.map((item) => item.name),
                      })
                    );
                  }}
                /> */}

                {/* ---add a progress pic--- */}
                <JrnyFloatingMenu
                  icon={addProgress}
                  mainText='add a progress pic or reflection'
                  secText='keep track on your journey progress'
                  iconClass='w-[50px]'
                  action={() => {
                    progressPicHandle(true);
                  }}
                />
                {progressPic && (
                  <AddProgress
                    setDataLoading={setDataLoading}
                    setTimelineContent={setTimelineContent}
                    timeLineContent={timeLineContent}
                    showFloatingMenuHandle={showFloatingMenuHandle}
                    modalState={progressPic}
                    modalControl={progressPicHandle}
                    jrnyActivity={activity}
                  />
                )}
                {/* ---add a progress pic--- */}
                {/* <JrnyFloatingMenu
                  icon={addProgress}
                  mainText='add a progress pic or reflection'
                  secText='keep track on your journey progress'
                  iconClass='w-[50px]'
                  action={() => {
                    setProgressPic2((state) => !state);
                  }}
                />
                {progressPic2 && (
                  <AddProgressPics2
                    setDataLoading={setDataLoading}
                    setTimelineContent={setTimelineContent}
                    timeLineContent={timeLineContent}
                    showFloatingMenuHandle={showFloatingMenuHandle}
                    modalState={progressPic2}
                    modalControl={() => {
                      setProgressPic2((state) => !state);
                    }}
                    jrnyActivity={activity}
                  />
                )} */}

                {/* ---add a milestone--- */}
                <JrnyFloatingMenu
                  icon={addMilestone}
                  mainText='add a milestone'
                  secText='add milestones and tasks and set due dates'
                  iconClass='w-[50px]'
                  action={() => {
                    milestoneModalHandle(true);
                  }}
                />
                {milestoneModal && (
                  <AddMilestone
                    setDataLoading={setDataLoading}
                    modalState={milestoneModal}
                    setTimelineContent={setTimelineContent}
                    timeLineContent={timeLineContent}
                    showFloatingMenuHandle={showFloatingMenuHandle}
                    modalControl={milestoneModalHandle}
                  />
                )}

                {/* ---add places--- */}
                <JrnyFloatingMenu
                  icon={addPlace}
                  mainText='add places to see'
                  secText='add place where journey will take place'
                  iconClass='w-[50px]'
                  action={() => {
                    addPlaceModalHandle(true);
                  }}
                />
                {addPlaceModal && (
                  <JrnyTimlineAddPlace
                    setDataLoading={setDataLoading}
                    timeLineContent={timeLineContent}
                    setTimelineContent={setTimelineContent}
                    modalState={addPlaceModal}
                    showFloatingMenuHandle={showFloatingMenuHandle}
                    modalControl={addPlaceModalHandle}
                    jrnyActivity={activity}
                  />
                )}

                {/* ---add people--- */}
                <JrnyFloatingMenu
                  icon={addPeople}
                  mainText='add people'
                  secText='who will join on your journey'
                  iconClass='w-[50px]'
                  action={() => {
                    addPeopleModalHandle(true);
                  }}
                />
              </div>
            )}
          </div>
          <TagListModal
            showTagsModal={showTagsModal}
            tagListModalHandle={tagListModalHandle}
          />
          {addPeopleModal && (
            <JrnyTimlineAddPeople
              setTimelineContent={setTimelineContent}
              timeLineContent={timeLineContent}
              modalState={addPeopleModal}
              modalControl={addPeopleModalHandle}
              jrnyActivity={activity}
            />
          )}
        </div>
      </main>
    </timelineDataContext.Provider>
  );
};

export default JrnyTimline;
export { timelineDataContext };
