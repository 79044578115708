import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import FriendsIcon from '../../assets/images/Friends_Icon.svg';
import { authSelector, getUsers } from '../../redux/slices/mobile/authSlice';
import { buildUrlToSize } from '../../utils/build-image-url';
import Persons from '../Persons/Persons';
import { PhotoSize, UserFilter } from '../utils/subjects';
import Thumbnail from '../thumbnails/Thumbnail';
import { onTypeChange } from '../utils/typing-change';
import { useEffect, useState } from 'react';
import backBtn from '../../assets/icons/back.png';
import ManageModalSelectedContents from '../MangeModalSelectedContents/ManageModalSelectedContents';

const AddFriends = ({
  headerText,
  control,
  userAdd,
  userArr,
  placeControl,
  friendControl,
  jrnyCreateActivity,
  friendState,
}) => {
  const { auth, users } = useSelector(authSelector);
  const [searchText, setSearchText] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);

  const [isFollower, setIsFollower] = useState(true);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [manageSelectedData, setManageSelectedData] = useState(false);
  const [userLists, setUserLists] = useState([]);

  const setManageSelectedDataHandle = (action) => {
    setManageSelectedData(action);
  };

  let userSelect = (data) => {
    let check = userArr.some((val) => val.id === data.id);
    if (check) {
      let checked = userArr.filter((val) => val.id !== data.id);
      userAdd((state) => checked);
    } else {
      userAdd((state) => state.concat(data));
    }
  };

  const getAllUsers = () => {
    dispatch(
      getUsers({
        data: {
          from: currentPage,
          size: 10,
          value: searchText && searchText.length > 0 ? searchText : undefined,
          filterId: auth?.user?.id,
        },
        token: auth?.token,
        callback: (results) => {
          setIsLoading(false);

          setUserLists([...userLists, ...results]);
        },
      })
    );
  };

  useEffect(() => {
    console.log('UserList After', userLists);
  }, [userLists]);

  useEffect(() => {
    setIsLoading(true);
    if (isFollower) {
      dispatch(
        getUsers({
          data: {
            from: currentPage,
            size: 10,
            value: searchText && searchText.length > 0 ? searchText : undefined,
            filter: UserFilter.Follower,
            filterId: auth?.user?.id,
          },
          token: auth?.token,
          callback: (results) => {
            if (results.length == 0 && !searchText) {
              getAllUsers();
              setIsFollower(false);
            } else {
              setIsLoading(false);

              setUserLists([...userLists, ...results]);
            }
          },
        })
      );
    } else {
      getAllUsers();
    }
  }, [searchText, currentPage]);

  return (
    <div className='space-y-5 h-full'>
      {/* <div className='flex items-center '>
          <img src={addPlaceIcon} alt='place' className='max-w-[45px]' />

          {control && (
            <div className='flex justify-center items-center'>
              <button onClick={() => control()}>
                <img
                  src={backBtn}
                  alt='back button'
                  className='w-[15px] h-[25px]'
                />
              </button>
            </div>
          )}
          <p className='text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary'>
            {headerText}
          </p>
        </div> */}

      <div className='space-y-5 h-full'>
        {/* <div className='relative w-full'>
            <input
              type='search'
              placeholder='find someone'
              className='bg-gray-100 w-full p-[10px_20px] rounded-[50px]'
              onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                setCurrentPage(0);
                setUsers([]);
                setSearchText(value);
              })}
            />
            <button className="w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]"></button>
          </div> */}
        {/* {friendState && (
            <div className='text-end space-x-3'>
              <button
                className='bg-jrnyfy-themeColor p-[5px_10px] max-[767px]:p-[3px_8px]  rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro'
                onClick={() => {
                  friendControl();
                  placeControl();
                }}
              >
                add places
              </button>
              <button
                className='bg-jrnyfy-themeColor p-[5px_10px] max-[767px]:p-[3px_8px]  rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro'
                onClick={() => {
                  control();
                  jrnyCreateActivity();
                }}
              >
                START Journey
              </button>
            </div>
          )} */}

        <ManageModalSelectedContents
          headerText={headerText}
          manageSelectedData={manageSelectedData}
          setManageSelectedDataHandle={setManageSelectedDataHandle}
          dataList={userLists}
          selectUser={userArr}
          selectedUsers={userArr}
          setSelectedUsers={userAdd}
          setUserLists={setUserLists}
          users={users}
          isFollower={isFollower}
          setCurrentPage={setCurrentPage}
          setSearchText={setSearchText}
          startActionText={'START Jrny'}
          showSuggestedText={true}
          addedText='Request Sent'
          isLoading={isLoading}
          startActionCallback={() => {
            control();
            jrnyCreateActivity();
          }}
          backBtnCallback={() => {
            control();
          }}
        />

        {/* <div className='space-y-2 max-h-[400px] pr-3 overflow-y-auto'>
            {users.map((val, ind) => (
              <div className='flex justify-between items-center' key={ind}>
                <Thumbnail btnFollow={false} showName={true} data={val} />
                <div>
                  <button
                    className={`p-[5px_10px] max-[500px]:text-[12px] min-[501px]:font-semibold font-SourceSansPro rounded-[50px] font-semibold ${
                      userArr.some((res) => res.id === val.id)
                        ? 'bg-jrnyfy-themeColor text-white'
                        : 'border-jrnyfy-textprimary border border-solid'
                    }`}
                    onClick={() => {
                      userSelect(val);
                    }}
                  >
                    {userArr.some((res) => res.id === val.id)
                      ? 'remove'
                      : 'add'}
                  </button>
                </div>
              </div>
            ))}
          </div> */}

        {/* {control === undefined ? (
          ''
        ) : (
          <div className='flex justify-center items-center'>
            <button
              className='p-[5px_10px] border border-solid border-jrnyfy-textprimary text-jrnyfy-color1 rounded-[50px] font-bold'
              onClick={() => {
                control();
              }}
            >
              back
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AddFriends;
