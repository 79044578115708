import React, { useState } from "react";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Body from "../components/Body/Body";
import Header from "../components/Header/Header";
import JrnyTimline from "../components/jrnyTimline/JrnyTimline";
import StoryPage from "../components/successStory/storyPage";
import UserProfile from "../components/userProfile/userProfile";
import PrivacyPolilcy from "../pages/PrivacyPolilcy";
import ServicePage from "../components/service/ServicePage";
import ServiceFilter from "../components/service/ServiceFilter";
import ServiceDetailsPage from "../components/service/ServiceDetailsPage";
import PaymentStatus from "../components/service/PaymentStatus";
import { PartnerWithUsPage } from "../components/partner/PartnerWithUsPage";
import VendorPage from "../components/Vendor/VendorPage";
import ForgetPassword from "../components/ForgetPassword/ForgetPassword";
import BottomNavMenu from "../components/BottomNavMenu/BottomNavMenu";
import Notification from "../components/Notification/Notification";
import Messenger from "../components/messages/Messenger";
import UserSettingPage from "../components/userSettings/UserSettingPage";
import PersonalInfoSetting from "../components/userSettings/PersonalInfoSetting";
import LoginSecuritySetting from "../components/userSettings/LoginSecuritySetting";
import PrivacySharingSetting from "../components/userSettings/PrivacySharingSetting";
import PaymentsSetting from "../components/userSettings/PaymentsSetting";
import NotificationSetting from "../components/userSettings/NotificationSetting";
import PaymentsBalanceSetting from "../components/userSettings/PaymentsBalanceSetting";
import LoginPage from "../components/Login/LoginPage";
import EmailChangePage from "../components/userSettings/EmailChangePage";
import Terms from "../pages/Terms";
import MediaDetails from "../components/SharedComponents/MediaDetails";
import TopPlaces from "../pages/TopPlaces";
import TopThings from "../pages/TopThings";
import TimelineItemDetails from "../components/FeedCard/TimelineItemDetails";
import TimelineDetailsPage from "../pages/TimelineDetailsPage";

const Router = () => {
  const [isLogoClick, setIsLogoClick] = useState(0);
  const [searchedText, setSearchedText] = useState();
  const [contents, setContents] = useState([]);
  const [tagSearchText, setTagSearchText] = useState([]);
  const location = useLocation();
  const currentRoute = location.pathname.split('/');
  const previousLocation = location.state?.previousLocation;
  console.log("currentLocation", tagSearchText)
  const tagSearchTextHandle = (val, isPlace) => {
    // setContents([]);
    // const isTagSelected = tagSearchText.some((item) => item.name == val.name);
    // if (!isTagSelected) {
    //   let values = tagSearchText
    //   if (isPlace) {
    //     values = tagSearchText.filter((item) => !item.place);
    //   }
    //   setTagSearchText([...values, val]);
    // } else {
    //   const values = tagSearchText.filter((item) => item.name != val.name);
    //   setTagSearchText(values);
    // }
    setSearchedText(val.name);
  };
  return (
    <>
      {currentRoute[1] != 'media-detials' && (<Header
        setContents={setContents}
        setIsLogoClick={setIsLogoClick}
        setSearchedText={setSearchedText}
        searchedText={searchedText}
        tagSearchText={tagSearchText}
        setTagSearchText={setTagSearchText}
      />)}
      <BottomNavMenu />
      <Routes location={previousLocation || location}>
        <Route
          path="/"
          element={
            <Body
              tagSearchTextHandle={tagSearchTextHandle}
              tagSearchText={tagSearchText}
              contents={contents}
              setContents={setContents}
              searchedText={searchedText}
              isLogoClick={isLogoClick}
            />
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/top-places" element={<TopPlaces />} />
        <Route path="/timeline-details/:userName/:journeyName" element={<TimelineDetailsPage />} />
        <Route path="/top-things" element={<TopThings />} />
        <Route path="/privacy-policy" element={<PrivacyPolilcy />} />
        <Route path="/terms" element={<Terms />} />
        <Route
          path="/journey/:userName/:journeyName"
          element={<JrnyTimline />}
        />
        <Route path="/user/:userName" element={<UserProfile />} />
        <Route path="/story" element={<StoryPage />} />
        <Route path="/service" element={<ServicePage />} />
        <Route
          path="/services/:destName/:tagName"
          element={<ServiceFilter />}
        />
        <Route
          path="/service-details/:destName/"
          element={<ServiceDetailsPage />}
        />
        <Route
          path="/service-details/payment-success"
          element={<PaymentStatus />}
        />
        <Route path="/notification" element={<Notification />} />
        <Route path="/messenger" element={<Messenger />} />
        <Route path="/partner" element={<PartnerWithUsPage />} />
        <Route path="/vendor" element={<VendorPage />} />
        <Route path="/identify" element={<ForgetPassword />} />
        <Route path="/setting" element={<UserSettingPage />} />
        <Route
          path="/setting/personal-info"
          element={<PersonalInfoSetting />}
        />
        <Route
          path="/setting/login-security"
          element={<LoginSecuritySetting />}
        />
        <Route
          path="/setting/privacy-sharing"
          element={<PrivacySharingSetting />}
        />
        <Route path="/setting/notification" element={<NotificationSetting />} />
        <Route path="/setting/payments" element={<PaymentsSetting />} />
        <Route
          path="/setting/payments-balance"
          element={<PaymentsBalanceSetting />}
        />
        <Route path="/setting/change-email" element={<EmailChangePage />} />
      </Routes>
      {previousLocation && (
        <Routes>
          <Route
            path="/media-detials"
            element={<MediaDetails />}
          />
        </Routes>
      )}
    </>
  );
};

export default Router;
