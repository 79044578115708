import React, { useState } from 'react';
import {
  authSelector,
  logOut,
  switchToUser,
} from '../../redux/slices/mobile/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Thumbnail from '../thumbnails/Thumbnail';
import { getUserNavOption } from '../../utils/get-nav-option';
import DocumentUploadModal from './DocumentUploadModal';
import PartnerSignupModal from '../partner/PartnerSignupModal';
import { PhotoSize } from '../utils/subjects';

const UserSettingsMenu = () => {
  const dispatch = useDispatch();
  const { auth, userType } = useSelector(authSelector);
  const navigate = useNavigate();
  const [showDocumentUploadModal, setShowDocumentUploadModal] = useState();
  const [vendorSignUpModal, setVendorSignUpModal] = useState(false);

  const showDocumentUploadModalHandle = () => {
    setShowDocumentUploadModal((prevState) => !prevState);
  };
  const vendorSignUpModalHandle = () => {
    setVendorSignUpModal((prevState) => !prevState);
  };

  return (
    <div className='flex flex-col pb-[80px] space-y-6'>
      <div className='flex items-start gap-3'>
        <Thumbnail
          btnFollow={false}
          data={auth?.user}
          size={{ width: 100, height: 100 }}
          PhotoSize={PhotoSize.Large}
          thumbnailContainerStyle={'!w-[100px] !h-[100px] !border-4 !border-jrnyfy-themeColor !text-[14px]'}
        />
        <div className='flex flex-col'>
          <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
            {auth?.user?.name}
          </h4>
          <p className='text-start text-jrnyfy-textprimary w-fit'>
            {auth?.user?.email}
          </p>
        </div>
      </div>
      <button
        onClick={() => navigate('/setting/personal-info')}
        className='w-fit'
      >
        <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
          Personal Info
        </h4>
        <p className='text-start text-jrnyfy-textprimary w-fit'>
          Provide details about you
        </p>
      </button>
      <button
        onClick={() => navigate('/setting/login-security')}
        className='w-fit'
      >
        <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
          Login & Security
        </h4>
        <p className='text-start text-jrnyfy-textprimary w-fit'>
          update password & secure your account
        </p>
      </button>
      <button
        onClick={() => navigate('/setting/privacy-sharing')}
        className='w-fit'
      >
        <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
          Privacy & Sharing
        </h4>
        <p className='text-start text-jrnyfy-textprimary w-fit'>
          Control what you share and who sees it
        </p>
      </button>
      <button
        onClick={() => navigate('/setting/notification')}
        className='w-fit'
      >
        <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
          Notifications
        </h4>
        <p className='text-start text-jrnyfy-textprimary'>
          Choose notifications preferences
        </p>
      </button>
      <button
        onClick={() => navigate('/setting/payments-balance')}
        className='w-fit'
      >
        <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
          Payments and Balance
        </h4>
        <p className='text-start text-jrnyfy-textprimary w-fit'>
          Review payment, payouts, coupons
        </p>
      </button>
      {userType == 'vendor' && (
        <>
          <button onClick={showDocumentUploadModalHandle} className='w-fit'>
            <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
              Upload Documents
            </h4>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              Upload and validate documents
            </p>
          </button>
          <button onClick={vendorSignUpModalHandle} className='w-fit'>
            <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
              Update Information
            </h4>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              Update company/individual information
            </p>
          </button>
          <DocumentUploadModal
            showDocumentUploadModal={showDocumentUploadModal}
            showDocumentUploadModalHandle={showDocumentUploadModalHandle}
          />
          {vendorSignUpModal && (
            <PartnerSignupModal
              vendorSignUpModalHandle={vendorSignUpModalHandle}
              vendorSignUpModal={vendorSignUpModal}
            />
          )}
        </>
      )}

      {auth?.user?.role == 50 ? (
        <button
          className='w-fit'
          onClick={() => {
            if (auth.token) {
              dispatch(
                switchToUser({
                  data: {
                    callback: (data) => {
                      if (data?.user?.lastRole == 50) {
                        navigate('/vendor');
                      } else if (data?.user?.lastRole == 100) {
                        navigate(getUserNavOption(data?.user));
                      }
                    },
                  },
                  token: auth.token,
                })
              );
            }
          }}
        >
          {auth.user.lastRole == 50 ? (
            <>
              <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
                Switch to User Profile
              </h4>
              <p className='text-start text-jrnyfy-textprimary w-fit'>
                Offer your services to help other
              </p>
            </>
          ) : (
            <>
              <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
                Switch to Vendor
              </h4>
              <p className='text-start text-jrnyfy-textprimary w-fit'>
                Offer your services to help other
              </p>
            </>
          )}
        </button>
      ) : (
        <button
          className='w-fit'
          onClick={() => {
            navigate('/partner');
          }}
        >
          <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit'>
            Become a service provider
          </h4>
          <p className='text-start text-jrnyfy-textprimary w-fit'>
            Offer your services to help other accomplish theri journey
          </p>
        </button>
      )}
    </div>
  );
};

export default UserSettingsMenu;
