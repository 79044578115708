import { buildUrlToSize } from '../../utils/build-image-url';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';

import { PhotoSize, YesNo } from '../utils/subjects';

const JrnyPageParticipantAdd = ({ data }) => {
  let arr = data?.participants;
  let thumbnail = buildUrlToSize(data?.thumbnail, PhotoSize.Large);
  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();

  return (
    <div className='flex ml-[20px] w-auto'>
      <div className='flex pt-[15px] pr-[15px] md:pr-[20px]'>
        <div className='flex items-center w-1/2'>
          {arr
            .map((val, ind) => (
              <div
                key={ind}
                className='text-center text-[25px] text-white w-[40px] h-[40px] rounded-[50%] ring-[3px] ring-white bg-jrnyfy-themeColor overflow-hidden prticipentimg italic font-SourceSansPro'
              >
                {val.thumbnail === undefined ? (
                  val.name.slice(0, 1) +
                  val.name.slice(
                    val.name.search(' ') + 1,
                    val.name.search(' ') + 2
                  )
                ) : (
                  <img
                    src={buildUrlToSize(val.thumbnail, PhotoSize.Large)}
                    alt={val.name.slice(0, 1)}
                    className='w-[40px] h-[40px] rounded-[50%] '
                  />
                )}
              </div>
            ))
            .slice(0, 4)}
          <p className='font-semibold ml-[10px] text-jrnyfy-textprimary'>
            + {arr.length}
          </p>
        </div>
        <div className='flex flex-wrap leading-[20px] text-jrny pb-[20px] font-semibold w-1/2'>
          {arr
            .map((val, ind) => (
              <h4 key={ind} className='mr-[2px]'>
                {val.name},
              </h4>
            ))
            .slice(0, 4)}
          <h4>and others has joined {data?.feed?.user.name}'s Jrny </h4>
        </div>
      </div>
    </div>
  );
};

export default JrnyPageParticipantAdd;
