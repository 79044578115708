import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
  },
  reducers: {
    logout: state => {
      // From here we can take action only at this "counter" state
      // But, as we have taken care of this particular "logout" action
      // in rootReducer, we can use it to CLEAR the complete Redux Store's state
    }
  }
});

export const {
  logout
} = appSlice.actions;


export default appSlice.reducer;
