import { useState } from 'react';
import Popup from 'reactjs-popup';
import 'swiper/css/free-mode';
import Cross from '../../assets/images/xmark-solid.svg';
import AddPlace from '../AddPlaces/AddPlaces';

const JrnyTimlineAddPlace = ({
  setDataLoading,
  setTimelineContent,
  timeLineContent,
  modalState,
  modalControl,
  jrnyActivity,
  showFloatingMenuHandle,
}) => {
  let [placeArr, setPlaceArr] = useState([]);

  return (
    <Popup
      open={modalState}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='relative bg-white w-[500px] rounded-[20px] p-10  max-[767px]:p-5 max-[767px]:h-full  max-[767px]:overflow-y-scroll max-[600px]:rounded-none'>
        {/* -------cross button-------- */}
        <img
          src={Cross}
          alt='cross'
          className='cursor-pointer w-[20px] '
          onClick={() => modalControl(false)}
        />

        {/* ------- main body start---- */}

        <AddPlace
          setDataLoading={setDataLoading}
          setTimelineContent={setTimelineContent}
          timeLineContent={timeLineContent}
          showBackBtn={false}
          placeAdd={setPlaceArr}
          placeArr={placeArr}
          showFloatingMenuHandle={showFloatingMenuHandle}
          fromJrny={true}
          jrnyActivity={jrnyActivity}
          control={modalControl}
        />
      </div>
    </Popup>
  );
};

export default JrnyTimlineAddPlace;
