import React, { useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import cancelBtnSvg from '../../assets/images/Cancel.svg';
import { buildUrlToSize } from '../../utils/build-image-url';
import { PhotoSize } from '../utils/subjects';
import { useDispatch, useSelector } from 'react-redux';
import rotateIcon from '../../assets/icons/rotate-icon.svg';
import trashIcon from '../../assets/icons/trash-icon.svg';
import {
  activitySelector,
  deleteGalleryItem,
  setActivityThumbnail,
} from '../../redux/slices/mobile/activitySlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { setActivityThumbnailProfileSlice } from '../../redux/slices/mobile/profileSlice';
import GalleryImageDeleteModal from './GalleryImageDeleteModal';
import { useLocation } from 'react-router-dom';

const ImageGalleryRenderer = ({
  timelineIndex,
  closeImageGallery,
  photos,
  galleryCurrentIndex,
  setGalleryCurrentIndex,
}) => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageRotation, setImageRotation] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const imageGalleryRef = useRef();
  const location = useLocation();
  const currentRoute = location.pathname.split('/', 2)[1];

  const showDeleteModalHandle = (action) => {
    setShowDeleteModal(action);
  };

  const imageRotationHandle = () => {
    if (imageRotation < 270) {
      setImageRotation(imageRotation + 90);
    } else {
      setImageRotation(0);
    }
  };

  console.log('Set Image Gallery', photos);
  useEffect(() => {
    setGalleryImages([]);
    photos?.forEach((item) => {
      const imgSrc = buildUrlToSize(item, PhotoSize.XLarge);
      console.log(item);
      setGalleryImages((prevState) => [
        ...prevState,
        { original: imgSrc, thumbnail: imgSrc },
      ]);
    });
  }, [photos]);
  return (
    <div
      className={`fixed bg-black flex items-center flex items-center top-0 left-0 right-0 bottom-0 m-auto z-40  ${
        imageRotation == 0 ? 'zero' : ''
      }  ${imageRotation == 90 ? 'ninety' : ''}  
      ${imageRotation == 180 ? 'hundred-eighty' : ''}  ${
        imageRotation == 270 ? 'two-seventy' : ''
      }
      `}
    >
      <button
        className='cursor-pointer w-[25px] absolute top-[20px] left-[25px] z-50'
        onClick={closeImageGallery}
      >
        <img src={cancelBtnSvg} alt='cross' />
      </button>
      <div className='space-x-3 flex items-center absolute bottom-[150px] text-white  right-[15px] z-50'>
        {imageRotation > 0 && <span>{imageRotation} Deg</span>}
        <button
          className='cursor-pointer w-[25px]'
          onClick={imageRotationHandle}
        >
          <img className='rotate' src={rotateIcon} alt='cross' />
        </button>
      </div>
      {currentRoute == 'journey' && (
        <button
          className='cursor-pointer w-[25px] absolute bottom-[190px] right-[15px] z-50'
          onClick={() => showDeleteModalHandle(true)}
        >
          <img src={trashIcon} alt='cross' />
        </button>
      )}
      <ImageGallery
        ref={imageGalleryRef}
        renderCustomControls={() => {
          return (
            <a href='' className='image-gallery-custom-action'>
              Custom Buttons
            </a>
          );
        }}
        startIndex={galleryCurrentIndex}
        showIndex={true}
        items={galleryImages}
      />
      {showDeleteModal && (
        <GalleryImageDeleteModal
          timelineIndex={timelineIndex}
          setGalleryCurrentIndex={setGalleryCurrentIndex}
          photos={photos}
          imageGalleryRef={imageGalleryRef}
          currentGalleryIndex={imageGalleryRef?.current?.getCurrentIndex()}
          showDeleteModal={showDeleteModal}
          showDeleteModalHandle={showDeleteModalHandle}
        />
      )}
    </div>
  );
};

export default ImageGalleryRenderer;
