import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, getUsers } from '../../redux/slices/mobile/authSlice';
import Thumbnail from '../thumbnails/Thumbnail';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { getMessages } from '../../redux/slices/mobile/messagingSlice';
import UserThumbsWithCounting from '../UsersThumbsWithCounting/UserThumbsWithCounting';
import ManageModalSelectedContents from '../MangeModalSelectedContents/ManageModalSelectedContents';
import { onTypeChange } from '../utils/typing-change';
import { YesNo } from '../utils/subjects';

const StartConversationModal = ({
  showStartConversationList,
  showStartConversationHandle,
  getMessageHandle,
  showChatRbSheetHandle = { showChatRbSheetHandle },
}) => {
  const { auth, users } = useSelector(authSelector);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [manageSelectedData, setManageSelectedData] = useState(false);
  const [userLists, setUserLists] = useState();
  const [currPage, setCurrPage] = useState(0);

  const [searchText, setSearchText] = useState(undefined);

  const chat = undefined;
  const dispatch = useDispatch();

  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });

  const setManageSelectedDataHandle = (action) => {
    setManageSelectedData(action);
  };

  // useEffect(() => {
  //   dispatch(
  //     getUsers({
  //       data: {
  //         from: currPage,
  //         size: 10,
  //       },
  //       token: auth.token,
  //       callback: (results) => {
  //         setCurrPage(currPage + 10);
  //         setUserLists(results);
  //       },
  //     })
  //   );
  // }, []);

  const selectUser = (val) => {
    const isItemSelected = selectedUsers.some((item) => item.id == val.id);
    if (!isItemSelected) {
      setSelectedUsers([...selectedUsers, val]);
    } else {
      const temUsers = selectedUsers.filter((item) => item.id != val.id);
      setSelectedUsers(temUsers);
    }
  };

  useEffect(() => {
    // if (showStartConversationList) {
    //   document.body.style.overflowY = 'hidden';
    // } else {
    //   document.body.style.overflowY = 'scroll';
    // }
  }, [showStartConversationList]);

  useEffect(() => {
    if (selectedUsers.length == 0) {
      setManageSelectedDataHandle(false);
    }
  }, [selectedUsers]);

  useEffect(() => {
    dispatch(
      getUsers({
        data: {
          from: currPage,
          size: 10,
          excludeReqUser: YesNo.Yes,
          value: searchText && searchText.length > 0 ? searchText : undefined,
          filterId: auth?.user?.id,
        },
        token: auth?.token,
        callback: (results) => {
          if (currPage == 0) {
            setUserLists(results);
          } else {
            if (searchText) {
              setUserLists(results);
            } else {
              setUserLists([...userLists, ...results]);
            }
          }
        },
      })
    );
  }, [searchText, currPage]);
  console.log('REsultsssss', users);
  return (
    <Popup
      open={showStartConversationList}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        maxHeight: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='p-5 w-[500px] h-[500px] bg-white rounded-3xl max-[600px]:h-screen  max-[600px]:rounded-none '>
        <div className='h-full'>
          <ManageModalSelectedContents
            manageSelectedData={manageSelectedData}
            setManageSelectedDataHandle={setManageSelectedDataHandle}
            dataList={manageSelectedData ? selectedUsers : userLists}
            selectUser={userLists}
            selectedUsers={selectedUsers}
            setCurrentPage={setCurrPage}
            setSelectedUsers={setSelectedUsers}
            setUserLists={setUserLists}
            users={users}
            setSearchText={setSearchText}
            startActionText={'START CONVERSATION'}
            showCross={true}
            startActionCallback={() => {
              // dispatch(
              //   getMessages({
              //     data: {
              //       chat: chat
              //         ? {
              //             id: chat.id,
              //             sk: chat.sk,
              //             createdAt: chat.createdAt,
              //           }
              //         : undefined,
              //       members: selectedUsers,
              //     },
              //     token: auth.token,
              //     callback: (res) => {
              //       console.log('StartConversation', res);
              //       getMessageHandle(res?.chat);
              //       showChatRbSheetHandle(true);
              //       showStartConversationHandle(false);
              //     },
              //   })
              // );

              getMessageHandle({ id: undefined, members: selectedUsers });
              showChatRbSheetHandle(true);
              showStartConversationHandle(false);
            }}
            backBtnCallback={() => {
              showStartConversationHandle(false);
              setSelectedUsers([]);
            }}
          />
        </div>
        {/* {selectedUsers?.length > 0 && (
          <div className='mb-5'>
            <Swiper
              slidesPerView={6}
              spaceBetween={0}
              freeMode={true}
              modules={[FreeMode]}
            >
              {selectedUsers.map((val) => (
                <SwiperSlide key={val.id}>
                  <div className='relative text-center mt-[7px] w-[55px]'>
                    <img
                      src={Cross}
                      alt='remove'
                      className='w-[10px] absolute top-[-9px] right-0 cursor-pointer'
                      onClick={() => {
                        selectUser(val);
                      }}
                    />
                    <Thumbnail btnFollow={false} data={val} />
                    <p className='text-[10px] font-semibold leading-[10px] max-w-[55px]'>
                      {val.name}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )} */}
        {/* {!manageSelectedData && (
          <div className='space-y-2 grow overflow-y-scroll '>
            <div className='overflow-y-scroll pr-3 flex flex-col justify-between gap-5 '>
              <div className='space-y-3'>
                {userLists?.length >= 1
                  ? userLists.map((val, ind) => (
                      <div
                        key={ind}
                        className='flex justify-between items-center w-full'
                      >
                        <Thumbnail showName={true} data={val} />

                        <div className=''>
                          <button
                            onClick={() => selectUser(val)}
                            className={`p-[5px_15px] font-SourceSansPro rounded-[50px] font-semibold ${
                              selectedUsers.some(
                                (selectedUser) => selectedUser.id === val.id
                              )
                                ? 'bg-jrnyfy-themeColor text-white'
                                : 'border-jrnyfy-textprimary border border-solid text-jrnyfy-textprimary'
                            }`}
                          >
                            {selectedUsers.some(
                              (selectedUser) => selectedUser.id === val.id
                            )
                              ? 'added'
                              : 'add'}
                          </button>
                        </div>
                      </div>
                    ))
                  : ''}
              </div>

              {users.length >= 10 && (
                <div className='flex justify-center items-center'>
                  <button
                    onClick={() => {
                      dispatch(
                        getUsers({
                          data: {
                            from: currPage,
                            size: 10,
                          },
                          token: auth.token,
                          callback: (results) => {
                            setCurrPage(currPage + 10);
                            setUserLists([...userLists, ...results]);
                          },
                        })
                      );
                    }}
                    type='button'
                    className='cursor-pointer border border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor  p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro'
                  >
                    show more
                  </button>
                </div>
              )}
            </div>
          </div>
        )} */}
      </div>
    </Popup>
  );
};

export default StartConversationModal;
