import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import JrnyCreate from '../jrnyCreate/jrnyCreate';
import { useDispatch, useSelector } from 'react-redux';
import {
  authSelector,
  switchToUser,
} from '../../redux/slices/mobile/authSlice';
import { getUserNavOption } from '../../utils/get-nav-option';

const SitemapInfo = () => {
  let authinfo = useSelector(authSelector).auth;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let [jrnyModal, setJrnyModal] = useState(false);
  let jrnyModalControl = () => {
    setJrnyModal((state) => !state);
  };
  let [userArr, setUserArr] = useState([]);
  let [placeArr, setPlaceArr] = useState([]);
  let [postValue, setPostValue] = useState({
    vissible: true,
    value: undefined,
  });
  return (
    <div className='ml-10 max-[767px]:hidden h-full max-h-screen sticky top-0 pt-[127px]'>
      <div className='space-y-5'>
        <h1 className='text-[25px] text-jrnyfy-textprimary'>
          Why{' '}
          <span className='text-[30px] font-black text-jrnyfy-themeColor italic font-SourceSansPro'>
            jrnyfy
          </span>
          ?
        </h1>
        <p className='text-jrnyfy-textprimary'>
          Planning a trip can be stressful and time consuming. With Jrnyfy, you
          can easily plan, collaborate and book excursions all in one place.
          Spend less time planning and more time creating beautiful lasting
          memories. <Link>Learn more</Link>
        </p>
        <ul className='list-disc ml-10 text-jrnyfy-textprimary'>
          <li>Create custom itineraries</li>
          <li>Get expert ideas and help from guides</li>
          <li>Safely book guides and excursions with cashless system</li>
          <li>Share your experiences and inspire others</li>
        </ul>
        <div className='space-y-5'>
          <button
            type='button'
            onClick={jrnyModalControl}
            className='z-20 cursor-pointer border bg-white border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro'
          >
            Start your journey
          </button>
          <p className='text-jrnyfy-textprimary'>
            See how Jrnyfy helped other accomplish their journeys
          </p>

          <button
            type='button'
            onClick={() => navigate('/story',{state:{name: 'SUCCESS STORIES',
            value: 0,
            status: false,}})}
            className={`${
              location.pathname == '/story'
                ? 'bg-jrnyfy-themeColor text-white'
                : 'bg-white'
            } z-20 cursor-pointer border border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro`}
          >
            See success stories
          </button>
          <p className='text-jrnyfy-textprimary'>
            Help others accomplish journeys and earn a little extra on the side
          </p>

          <div>
            {authinfo?.user?.role == 50 ? (
              <button
                type='button'
                className={`z-20 cursor-pointer border border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro`}
                onClick={() => {
                  if (authinfo.token) {
                    dispatch(
                      switchToUser({
                        data: {
                          callback: (data) => {
                            if (data?.user?.lastRole == 50) {
                              navigate('/vendor');
                            } else if (data?.user?.lastRole == 100) {
                              navigate(getUserNavOption(data?.user));
                            }
                          },
                        },
                        token: authinfo.token,
                      })
                    );
                  }
                }}
              >
                {authinfo.user.lastRole == 50
                  ? 'Switch to User Profile'
                  : 'Switch to Vendor'}
              </button>
            ) : (
              <button
                type='button'
                className={`z-20 cursor-pointer border border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor font-extrabold  p-[6px_15px_6px_15px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro`}
                onClick={() => {
                  navigate('/partner');
                }}
              >
                Partner with us
              </button>
            )}
          </div>
          <div className='space-x-3'>
            <Link className='text-[14px] text-jrnyfy-textprimary'>
              Privacy Policy
            </Link>
            <Link className='text-[14px] text-jrnyfy-textprimary'>
              Contact Us
            </Link>
            <Link className='text-[14px] text-jrnyfy-textprimary'>
              © Jrnyfy Corp.
            </Link>
          </div>
        </div>
      </div>
      {jrnyModal && (
        <JrnyCreate
          setPostValue={setPostValue}
          postValue={postValue}
          setPlaceArr={setPlaceArr}
          placeArr={placeArr}
          setUserArr={setUserArr}
          userArr={userArr}
          jrnyModal={jrnyModal}
          jrnyModalControl={jrnyModalControl}
        />
      )}
    </div>
  );
};

export default SitemapInfo;
