import { buildUrlToSize } from "../../utils/build-image-url";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import {
  PhotoSize,
  TaskDueDateStatus,
  TaskStatus,
  YesNo,
} from "../utils/subjects";
import Popup from "reactjs-popup";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import {
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import PopUpImageGallery from "../popUpImageGallery/PopUpImageGallery";
import ImageGallery from "react-image-gallery";
import cancelBtnSvg from "../../assets/images/Cancel.svg";
import Thumbnail from "../thumbnails/Thumbnail";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import Icons from "../Icons/Icons";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";
import DueDateModal from "../TimelineOptionsModal/DueDateModal";
import MapIcon from '../../assets/icons/map-icon.svg'
import FlagIcon from '../../assets/icons/flag-icon.svg'
import CheckCircleIcon from '../../assets/icons/check-circle.svg'

const TaskCreate = ({ isEditable, index, data, serverDate }) => {
  let [popUpImage, setpopUpImage] = useState({
    status: false,
    value: null,
  });
  let modalCondition = (imageUrl) => {
    setpopUpImage((state) => ({ status: !state.status, value: imageUrl }));
  };
  let avater = buildUrlToSize(
    data?.feed ? data?.feed?.user?.thumbnail : data?.user?.thumbnail,
    PhotoSize.Large
  );
  const user = data?.feed?.user ? data?.feed?.user : data?.user;

  let userName = data?.feed?.user?.name;
  let gallery = data?.gallery ? data.gallery : undefined;
  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();
  let fName = data?.feed?.user?.name.slice(0, 1);
  let mNamePos = data?.feed?.user?.name.search(" ");
  let mName = data?.feed?.user?.name.slice(mNamePos + 1, mNamePos + 2);
  let altTitle = fName + mName;
  let navigate = useNavigate();
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  const [isDueDateModal, setIsDueDateModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);
  const { activity } = useSelector(activitySelector);
  const [isBrokenThumbnail,setIsBrokenThumbnail] = useState(data?.task?.location?.photo?false:true)

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = "hidden";
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = "scroll";
  };

  useEffect(() => {
    setGalleryImages([]);
    gallery?.forEach((item) => {
      const imgSrc = buildUrlToSize(item, PhotoSize.Large);
      console.log(imgSrc);
      setGalleryImages((prevState) => [
        ...prevState,
        { original: imgSrc, thumbnail: imgSrc },
      ]);
    });
  }, [gallery]);
  console.log("dataLoading", data.loading, data);

  // useEffect(() => {
  //   if (data?.task?.dueDate?.date) {
  //     setTimeLeft(
  //       Moment(data?.task?.dueDate?.date).diff(
  //         Moment(serverDate ? serverDate : activity.serverDate),
  //         "hours",
  //         false
  //       )
  //     );
  //     setDaysLeft(
  //       Moment(data?.task?.dueDate?.date).diff(
  //         Moment(serverDate ? serverDate : activity.serverDate),
  //         "days",
  //         false
  //       )
  //     );
  //   }
  //   const dueDate = "2021-10-19T20:30:48Z";
  //   const firstDayOfYear = "2021-01-01T20:30:48Z";
  //   console.log(
  //     "TestDate",
  //     Moment(data?.task?.dueDate?.date).diff(
  //       Moment(serverDate ? serverDate : activity.serverDate),
  //       "hours",
  //       false
  //     )
  //   );
  // }, [data?.task?.dueDate?.date, serverDate, activity.serverDate]);

  const backgroundStyle = {
    backgroundImage: `url(${buildUrlToSize(data?.task?.location?.photo, PhotoSize.Medium)})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div onClick={() => {
      // navigate(`/jrny/${data.id}`);
      navigate(getJourneyNavOption(data));
    }}>
      <div className="flex justify-between items-start">
        <div className="relative">
          <div className="absolute top-0 left-[-24px]">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>


          <p
            className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold cursor-pointer"
            onClick={() => {
              // navigate(`/jrny/${data.id}`);
              navigate(getJourneyNavOption(data));
            }}
          >
            <span className="text-jrnyfy-title">
              {data?.task?.status == TaskStatus.Completed ? 'completed a milestone: ' : 'added a place to journey'}
            </span>
            {data?.activity.name}
          </p>


          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {user?.name}, created{" "}
            <Moment fromNow>{data?.createdAt}</Moment>
          </p>
        </div>


        {isEditable == true &&
          !data?.feed?.user &&
          (data.loading ? (
            <div className="spinner"></div>
          ) : (
            <div className="ml-[10px] md:ml-[30px]">
              <Icons
                iconClick={() => deleteModalHandle(true)}
                icon={Option}
                alt="Option"
                width="25px"
              />
            </div>
          ))}
      </div>
      <div className={`border relative rounded-3xl ml-5 mt-5 h-[300px] ${!isBrokenThumbnail &&'taskimg'}`}>
     
      <div className={`flex absolute left-0 h-full w-full flex-col ${!isBrokenThumbnail?'justify-end':'justify-center'}  items-center `}>
        <img src={data?.task?.status == TaskStatus.Completed ? CheckCircleIcon : FlagIcon} alt='map icon' />
        <div className="p-4 z-10">
          <h4 className={`block pb-[5px] leading-[25px] text-${!isBrokenThumbnail?'white':'jSecTitle'} font-bold`}>
            {data?.text}
          </h4>
          <p className={`block pb-[5px] leading-[18px] text-${!isBrokenThumbnail?'white':'jSecTitle'}`}>
            {data?.task?.description}
          </p>
        </div>
      </div>
      {!isBrokenThumbnail &&(
          <img 
          src={buildUrlToSize(data?.task?.location?.photo, PhotoSize.Medium)} 
          className=" w-full h-full top-0 rounded-3xl " 
          alt='place image' 
          onError={()=>setIsBrokenThumbnail(true)}/>)}
      </div>
      {deleteModal && (
        <TimelineOptionsModal
          indeTimelineOptionsModal
          data={data}
          actionStates={actionStates}
          deleteModal={deleteModal}
          deleteModalHandle={deleteModalHandle}
        />
      )}

      {/* {gallery && (
        <div className='mt-4 taskimg ml-[20px] w-auto'>
          <Swiper
            style={{ borderRadius: '1.5rem' }}
            autoplay={{
              delay: 2000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            slidesPerView={1}
            spaceBetween={10}
            modules={[Autoplay]}
          >
            {console.log('Gallery', gallery)}
            {gallery?.map((val, ind) => (
              <SwiperSlide key={ind}>
                <img
                  src={buildUrlToSize(val, PhotoSize.Large)}
                  alt='Place'
                  className='aspect-video rounded-3xl w-full cursor-pointer object-cover'
                  onClick={() => {
                    setGalleryCurrentIndex(ind);
                    openImageGallery();
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className='absolute z-20 text-white bottom-[15px] left-0 px-[20px] text-[12px] md:text-[18px]'>
            <p>{data?.name}</p>
          </div>
        </div>
      )} */}
      {!data?.feed?.user &&
        (data?.task?.dueDate?.date ? (
          <p
            className={`${data?.task?.status == TaskStatus.Completed
              ? "text-jrnyfy-boderColorGreen"
              : TaskDueDateStatus.OnTrack == data?.task?.dueDate?.status
                ? "text-jrnyfy-boderColorGray"
                : TaskDueDateStatus.AtRisk == data?.task?.dueDate?.status
                  ? "text-jrnyfy-boderColorYellow"
                  : "text-jrnyfy-boderColorRed"
              }`}
          >
            {/* {data?.task?.status == TaskStatus.Completed
              ? `milestone completed `
              : data?.task?.dueDate?.status == TaskDueDateStatus.OnTrack ||
                data?.task?.dueDate?.status == TaskDueDateStatus.AtRisk
                ? `${daysLeft > 0 ? daysLeft + " days" : timeLeft + " hours"
                } left to complete this milestone`
                : `milestone overdue by ${daysLeft} day`} */}
          </p>
        ) : (
          <button
            onClick={() => setIsDueDateModal(true)}
            className="ml-[24px] mt-3 bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            Set A Due Date
          </button> 
        ))}

      {isDueDateModal && (
        <DueDateModal
          item={data}
          index={index}
          isDueDateModal={isDueDateModal}
          setIsDueDateModal={setIsDueDateModal}
        />
      )}
      {showImageGallery && (
        <div className="fixed flex items-center bg-black top-0 left-0 right-0 bottom-0 m-auto z-40 ">
          <button onClick={closeImageGallery}>
            <img
              src={cancelBtnSvg}
              alt="cross"
              className="cursor-pointer w-[25px] absolute top-[20px] left-[25px] z-50"
            />
          </button>
          <ImageGallery
            startIndex={galleryCurrentIndex}
            showIndex={true}
            items={galleryImages}
          />
        </div>
      )}
    </div>
  );
};

export default TaskCreate;
