import { buildUrlToSize } from '../../utils/build-image-url';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { PhotoSize, YesNo } from '../utils/subjects';
import Thumbnail from '../thumbnails/Thumbnail';
import { useState } from 'react';
import { getJourneyNavOption } from '../../utils/get-nav-option';
import { useNavigate } from 'react-router-dom';
import LinkIcon from '../../assets/icons/link-icon.svg'

const ParticipantAdded = ({ index, data }) => {
  let participants = data?.feed?.participants
    ? data?.feed?.participants
    : data?.participants;
  let navigate = useNavigate();
  let thumbnail = buildUrlToSize(data?.activity.thumbnail, PhotoSize.Large);
  let userName = data?.user?.name;
  const [isThumbnailBroken, setIsThumbnailBroken] = useState(false)

  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const getTitle = () => {
    let title = data?.participants[0]?.name
    if (data?.participants?.length > 1) {
      title += ',' + data?.participants[1].name
    } else if (data?.participants?.length > 2) {
      title += 'and' + data?.participants[2].name
    }
    return title
  }
  const user = data?.user;
  console.log('ParticipantDta', data)
  return (
    <>
      <div className='flex justify-between items-start'>
        <div className='relative w-full'>
          <div className='flex items-start'>
            <div className='absolute left-[-25px]'>
              <Thumbnail
                btnFollow={false}
                showName={false}
                data={user}
                PhotoSize={PhotoSize.Small}
              />
            </div>
            <div className='flex flex-col justify-between'>
              <div>
                <div className='flex flex-wrap leading-[20px] text-jrny pb-[20px] font-semibold'>
                  {/* <h4>
                    User
                    {participants &&
                      participants
                        .map((val, ind) => (
                          <span key={ind} className='mr-[2px]'>
                            {val.name},
                          </span>
                        ))
                        .slice(0, 4)}
                    and others has joined {data?.feed?.user?.name}'s Jrny{' '}
                  </h4> */}
                  <p
                    className='pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold cursor-pointer'
                    onClick={() => {
                      // navigate(`/jrny/${data.id}`);
                      navigate(getJourneyNavOption(data));
                    }}
                  >
                    <span className='text-jrnyfy-title'>
                      {getTitle()} Joined journey :
                    </span>
                    {data?.activity?.name}
                  </p>
                </div>
              </div>
              {/* <div className='ml-[10px] mt-[20px]'>
                <button className='p-[8px_15px] bg-jrnyfy-themeColor rounded-[50px] text-white font-medium md:font-semibold hover:scale-[1.1] transition-all font-SourceSansPro italic min-[350px]:max-[415px]:text-[15px] min-[350px]:max-[415px]:leading-[12px] min-[350px]:max-[415px]:p-[5px_10px]'>
                  request to join
                </button>
              </div> */}
            </div>
          </div>
          <div className=' ml-[20px] w-auto'>
            <div className={`${(!isThumbnailBroken && data?.activity?.thumbnail ) ? 'taskimg' : ''} h-[300px]`}>
              {!isThumbnailBroken && data?.activity?.thumbnail && (<img
                onClick={() => {
                  // navigate(`/jrny/${data.id}`);
                  navigate(getJourneyNavOption(data));
                }}
                src={thumbnail}
                alt='Place'
                className='rounded-3xl w-full h-full object-cover cursor-pointer'
                onError={() => setIsThumbnailBroken(true)}
              />)}
              <div className='absolute top-0 bottom-0 left-0 right-0 m-auto w-fit h-fit'>
                <div className='flex items-end'>
                  {data.participants.map(user => (<Thumbnail
                    showName={false}
                    btnFollow={false}
                    data={user}
                    thumbnailContainerStyle={'!w-[50px] !h-[50px] !border-4 !border-white -ml-5 z-10'}
                    PhotoSize={PhotoSize.Small}
                  />))}
                 {!isThumbnailBroken && !data?.activity?.thumbnail &&( <>
                  <Thumbnail
                    showName={false}
                    btnFollow={false}
                    data={data.user}
                    thumbnailContainerStyle={'!w-[110px] !h-[110px] !border-4 !border-white -ml-10'}
                    PhotoSize={PhotoSize.Small}
                  />
                  <img className="ml-[-35px] z-10" src={LinkIcon} alt='link icon'/>
                  </>)}
                  {/* <p className='font-semibold ml-[10px] text-jrnyfy-textprimary'>
                    + {participants.length}
                  </p> */}
                </div>
              </div>
              <div className='absolute z-20 text-white bottom-[15px] max-[767px]:text-[14px] left-0 px-[10px] md:px-[20px] text-jrny font-medium leading-[17px]'>
                <p>{data?.name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticipantAdded;
