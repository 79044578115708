import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";


const MyJrnyControl = ({ icon, mainText, secText, iconClass, action }) => {
    let [textState, setTextState] = useState(false);
    return (
        <motion.div
            className="flex items-center cursor-pointer hover:bg-gray-100 rounded-[5px] p-[5px] "
            onHoverStart={() => { setTextState(true) }}
            onHoverEnd={() => { setTextState(false) }}
            onClick={action}>

            <img src={icon} alt='item' className={`${iconClass}`} />

            <AnimatePresence>

                {textState &&
                    <motion.div
                        className="ml-[7px] "
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <h4 className={`font-Montserrat`}>{mainText}</h4>
                        <p className="text-jrnyfy-textprimary leading-[13px] text-[11px] font-medium ">{secText}</p>
                    </motion.div>}

            </AnimatePresence>

        </motion.div>
    )
};

export default MyJrnyControl;