import { AnimatePresence } from "framer-motion";
import Popup from "reactjs-popup";
import Cross from "../../assets/images/xmark-solid.svg";
import Heart from "../../assets/images/likes.svg";
import { useEffect, useRef, useState } from "react";
import {
  getLikesOfActivity,
  feedSelector,
} from "../../redux/slices/mobile/feedSlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { buildUrlToSize } from "../../utils/build-image-url";
import {
  getUserInformation,
  viewProfileSelector,
  ViewProfileGetFollowers,
  ViewProfileGetFollowings,
  ViewProfileGetInspired,
} from "../../redux/slices/mobile/viewProfileSlice";
import {
  followRequest,
  unfollowRequest,
  profileSelector,
  getFollowings,
} from "../../redux/slices/mobile/profileSlice";
import { ContentFilter, PhotoSize } from "../utils/subjects";
import UserListRenderer from "../userList/UserListRenderer";
import { getContentUsers } from "../../redux/slices/mobile/activitySlice";
import { Facebook } from "react-content-loader";

const LikeModal = ({ lModal, lModalControl, data, filter, participantStatus }) => {
  let authInfo = useSelector(authSelector);
  let viewProfile = useSelector(viewProfileSelector);
  const [users, setUsers] = useState([])
  const [isEnd, setIsEnd] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  let dispatch = useDispatch();
  const inputRef = useRef();
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);

  let [followState, setFollowState] = useState(false);
  let followControl = (id, sk) => {
    dispatch(
      followRequest({
        token: authInfo.auth.token,
        data: { sk: sk, id },
      })
    );
  };
  console.log("CommentsDetails", data);

  const getUserList = (page) => {
    setIsloading(true);
    dispatch(
      getContentUsers({
        data: {
          from: page,
          size: 15,
          filterId: data.id,
          value: "",
          filter: filter,
          participantStatus,
        },
        token: authInfo.auth.token,
        // activityId: data.id,
        callback: (res) => {
          setIsloading(false);
          if (res?.length < 15) {
            setIsEnd(true);
          }
          setCurrentPage(currentPage + 15);
          if (currentPage == 0) {
            setUsers(res);
          } else {
            setUsers([...users, ...res]);
          }
        },
      })
    );
  }

  useEffect(() => {
    getUserList(0)
    dispatch(
      ViewProfileGetFollowings({
        data: { size: 100, from: 0, filterId: authInfo?.auth?.user?.id },
        token: authInfo?.auth?.token,
      })
    );
  }, []);

  // console.log(feedItem.userListLikes);
  // console.log(feedItem.userListLikes);

  return (
    <Popup
      open={lModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: "100%",
        height: "100vh",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      <div className="relative bg-white w-[500px] rounded-[20px] p-10  max-[767px]:p-5 max-[767px]:h-screen  max-[767px]:overflow-y-scroll max-[600px]:rounded-none">
        {/* -------cross button-------- */}
        {/* <img
          src={Cross}
          alt='cross'
          className='cursor-pointer w-[25px] absolute top-[20px] left-[25px]'
          onClick={lModalControl}
        /> */}
        <div className="flex items-center">
          <div className="w-fit">
            <img
              src={Cross}
              alt="cross"
              className="cursor-pointer w-[20px]"
              onClick={() => lModalControl(false)}
            />
          </div>
          <div className="flex justify-center items-center w-full">
            <img src={Heart} alt="place" className="max-w-[50px]" />
            <p className="text-jrny font-semibold">
              Who likes this post ?
            </p>
          </div>
        </div>
        {/* ------- main body start---- */}

        <div>
          {/* <div className='relative mb-8'>
            <input
              type='search'
              ref={inputRef}
              placeholder='search '
              className='bg-gray-100 w-full p-[10px_20px] rounded-[50px] focus:outline-none'
              onChange={(e) => {}}
            />
            
          </div> */}
         {isLoading?(<Facebook className="max-w-[545px]" viewBox="0 0 200 124" />):( <UserListRenderer 
          users={users} 
          thumbnailContainerStyle={'!w-[50px] !h-[50px]'} 
          />)}
          {/* {feedItem.userListLikes.length > 0
            ? feedItem.userListLikes.map((val, ind) => (
                <div
                  key={ind}
                  className="flex items-center space-x-2 mb-[15px]"
                >
                  <div className="w-[40px] h-[40px] text-center text-white rounded-[50%] bg-jrnyfy-themeColor text-[24px] font-SourceSansPro">
                    {val?.user?.thumbnail !== undefined ? (
                      <img
                        src={buildUrlToSize(
                          val?.user?.thumbnail,
                          PhotoSize.Large
                        )}
                        alt={val?.user?.name}
                        className="w-full h-full rounded-[50%] ring-4 ring-jrnyfy-border"
                      />
                    ) : val?.user?.name ? (
                      val?.user?.name.slice(0, 1)
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <p className="leading-[10px] text-[15px] font-semibold">
                      {val?.user?.name}
                    </p>
                    {val?.user?.id === authInfo?.auth?.user?.id ? (
                      ""
                    ) : viewProfile.followings.some(
                        (fol) => fol.id === val?.user?.id
                      ) ? (
                      <button
                        onClick={() => {}}
                        className="13px text-jrnyfy-themeColor"
                      >
                        following
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          followControl(val?.user?.id, val?.user?.sk);
                          setFollowState(true);
                        }}
                        className="13px text-jrnyfy-textprimary"
                      >
                        {followState ? "follow request sent" : "follow"}
                      </button>
                    )}
                  </div>
                </div>
              ))
            : ""} */}
        </div>
      </div>
    </Popup>
  );
};

export default LikeModal;
