import React from 'react';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const ServicePageSuccessStory = ({ stories }) => {
  console.log('Data2', stories);

  return (
    <div className='flex'>
      <div>
        <img
          src={stories?.thumbnail?.photoReference}
          alt='userThumb'
          className='rounded-[50%] border-4 w-[105px] h-[105px]'
        />
      </div>
      <div className='w-auto space-y-10'>
        <div className='ml-5'>
          <p className='leading-9 text-jrny font-semibold cursor-pointer text-[31px]'>
            {stories?.user?.name} added some progress pictures for their
            journey: <br />{' '}
            <span className='text-jrnyfy-title'>{stories?.name}'</span>
          </p>
        </div>
        <div className='w-[800px]'>
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            freeMode={true}
            modules={[FreeMode]}
          >
            {stories &&
              stories?.images?.map((val, ind) => (
                <SwiperSlide key={ind}>
                  <img
                    src={val.src}
                    alt='places'
                    className='w-[318px] h-[276px] rounded-[51px] object-cover'
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ServicePageSuccessStory;
