import { useEffect, useState } from 'react';
import { Facebook } from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { getDraftActivitiesOfUser } from '../../redux/slices/mobile/profileSlice';
import {
  getActiveActivitiesOfUser,
  getUserInformation,
  viewProfileSelector,
} from '../../redux/slices/mobile/viewProfileSlice';
import { buildUrlToSize } from '../../utils/build-image-url';
import {
  vierJourneyFilter,
} from '../../utils/filters';
import { parseUserUrlParams } from '../../utils/get-nav-option';
import Aside from '../Aside/Aside';
import { HorizontalTapView } from '../utils/HorizontalTabView';
import { onScrollEndTrigger } from '../utils/on-scroll-end-trigger';
import {
  JourneyFilter,
  PhotoSize,
  PhotoSource,
  UserUploadedImageType,
  YesNo,
} from '../utils/subjects';
import Count from './count';
import FfButtons from './FfButtons';
import Myjrny from './myjrnycomp';
import Thumbnail from '../thumbnails/Thumbnail';
import settingImage from '../../assets/icons/setting.png';
import Seo from '../Seo/Seo';
import UploadUserProfileImageModal from './UploadUserProfileImageModal';
import ImageGalleryRenderer from '../ImageGallery/ImageGalleryRenderer';
const UserProfile = () => {
  let {
    auth: { user, token,templatePhotoCount },
  } = useSelector(authSelector);
  let { profileSelected } = useSelector(viewProfileSelector);
  console.log('profile selected', profileSelected,templatePhotoCount);
  const [uploadedBannerOrProfile, setUploadedBannerOrProfile] = useState({
    profileRUL: '',
    bannerURL: '',
  });
  let avatar = buildUrlToSize(user?.thumbnail, PhotoSize.Large);
  const navigate = useNavigate();
  let altTitle = user?.name.slice(0, 1);
  let dispatch = useDispatch();
  let [journeyFilter, setJourneyFilter] = useState(
    JourneyFilter.All
  );
  const [userProfileUploadModal, setUserProfileUploadModal] = useState(false);

  const [galleryPhoto, setGalleryPhoto] = useState([]);
  const itemParPage = 15;
  const [currentPage, setCurrentPage] = useState(0);
  const [keepScrolling, setKeepScrolling] = useState(YesNo.Yes);
  const [journeys, setJourneys] = useState([]);
  const [searchParams] = useSearchParams();
  const userParams = parseUserUrlParams(searchParams);
  let [userFilter, setUserFilter] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);
  const [imageType, setImageType] = useState();
  const getDataLength = () => {
    return journeys.length;
  };
  const isKeepScrolling = () => {
    return keepScrolling;
  };

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = 'hidden';
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = 'scroll';
  };

  const userProfileUploadModalHandle = (type) => {
    setImageType(type);
    setUserProfileUploadModal((prevState) => !prevState);
  };

  onScrollEndTrigger(setCurrentPage, isKeepScrolling, getDataLength);

  const init = () => {
    setJourneys([]);
    setKeepScrolling(YesNo.Yes);
  };
  useEffect(() => {
    init();
    dispatch(
      getUserInformation({
        id: userParams?.id,
        token: token ? token : undefined,
        data: {
          sk: userParams?.sk,
          createdAt: userParams?.createdAt,
        },
        callback: () => {
          setLoadingState(false);
        },
      })
    );
    setLoadingState(true);
  }, [userParams.id]);

  useEffect(() => {
    console.log('journeys.......', journeys);
    if (
      journeyFilter == JourneyFilter.All ||
      journeyFilter == JourneyFilter.Current|| journeyFilter == JourneyFilter.Past
    ) {
      dispatch(
        getActiveActivitiesOfUser({
          data: {
            from: currentPage,
            size: itemParPage,
            filter:journeyFilter
          },
          token,
          id: userParams?.id,
          callback: (results) => {
            if (results.length < itemParPage) {
              setKeepScrolling(YesNo.No);
            }
            if (currentPage == 0) {
              setJourneys(results);
            } else {
              setJourneys([...journeys, ...results]);
            }
          },
        })
      );
    } else if (journeyFilter == JourneyFilter.Draft) {
      dispatch(
        getDraftActivitiesOfUser({
          data: {
            from: journeys.length,
            size: itemParPage,
          },
          token,
          id: userParams?.id,
          callback: (results) => {
            if (currentPage == 0) {
              setJourneys(results);
            } else {
              setJourneys([...journeys, ...results]);
            }
          },
        })
      );
    }
  }, [journeyFilter, currentPage, userParams.id]);

  useEffect(() => {
    // token trigger is necessary, if user viewing his own profile and sign-out, draft filter gets removed automatically
    setUserFilter(
      user?.id === userParams.id ? vierJourneyFilter : vierJourneyFilter.filter(item=>item.value!=JourneyFilter.Draft)
    );
  }, [userParams.id, token]);

  return (
    <>
      <Seo
        title={`${profileSelected?.name} | JRNYFY`}
        description=''
        type='webapp'
        name='jrnyfy crop'
      />
      <main className=''>
        <div className='w-full min-[1100px]:w-[75%] mx-auto flex justify-center min-[3000px]:w-1/2'>
          <aside className='hidden md:block md:w-[30%] mt-[-127px]'>
            <Aside />
          </aside>
          <div className='w-full md:w-[70%] md:px-[20px]'>
            <div className='max-w-[725px] space-y-4'>
              {/* -------search part start-------- */}
              {/* <div className='flex space-x-1'>
              {user && (
                <div className='max-w-[50px]'>
                  <Thumbnail ShowName={false} btnFollow={false} data={user} />
                </div>
              )}
              <div className='w-full'>
                <form>
                  <div className='relative'>
                    <input
                      type='search'
                      placeholder='start a journey! example take a hike at the yellow stone national park'
                      className='bg-gray-100 w-full p-[10px_20px] rounded-[50px]'
                    />
                  </div>
                </form>
              </div>
            </div> */}
              <div>
                <div className='relative w-full h-[250px] border-b-4 border-jrnyfy-themeColor'>
                  <img
                    onClick={() => {
                      if (profileSelected?.id == user?.id) {
                        userProfileUploadModalHandle(
                          UserUploadedImageType.Banner
                        );
                      } else {
                        if (uploadedBannerOrProfile?.bannerURL) {
                          setGalleryPhoto([uploadedBannerOrProfile?.bannerURL]);
                          openImageGallery();
                        } else if (profileSelected?.banner) {
                          setGalleryPhoto([profileSelected?.banner]);
                          openImageGallery();
                        }
                      }
                    }}
                    className='w-full h-full object-cover'
                    src={
                      uploadedBannerOrProfile.bannerURL
                        ? uploadedBannerOrProfile.bannerURL
                        : profileSelected?.banner
                        ? buildUrlToSize(
                            profileSelected?.banner,
                            PhotoSize.XLarge
                          )
                        : 'https://picsum.photos/1000/600.webp/?random?random&t=' +
                          new Date() +
                          ')'
                    }
                    alt='banner'
                  />
                  <div
                    onClick={() => {
                      if (profileSelected?.id == user?.id) {
                        userProfileUploadModalHandle(
                          UserUploadedImageType.Profile
                        );
                      } else {
                        if (uploadedBannerOrProfile.profileRUL) {
                          setGalleryPhoto([uploadedBannerOrProfile.profileRUL]);
                          openImageGallery();
                        } else if (profileSelected?.thumbnail) {
                          setGalleryPhoto([profileSelected?.thumbnail]);
                          openImageGallery();
                        }
                      }
                    }}
                    className='absolute -bottom-10 right-0 left-0 m-auto w-fit z-10'
                  >
                    <Thumbnail
                      showName={false}
                      btnFollow={false}
                      data={profileSelected}
                      localUrl={
                        uploadedBannerOrProfile.profileRUL &&
                        uploadedBannerOrProfile.profileRUL
                      }
                      PhotoSize={PhotoSize.Large}
                      thumbnailContainerStyle={'!w-[100px] !h-[100px] !border-4 !border-jrnyfy-themeColor !text-[14px]'}
                    />
                  </div>
                  {userProfileUploadModal && (
                    <UploadUserProfileImageModal
                      setUploadedBannerOrProfile={setUploadedBannerOrProfile}
                      imageType={imageType}
                      userProfileUploadModalHandle={
                        userProfileUploadModalHandle
                      }
                      userProfileUploadModal={userProfileUploadModal}
                    />
                  )}
                  {showImageGallery && (
                    <ImageGalleryRenderer
                      closeImageGallery={closeImageGallery}
                      galleryCurrentIndex={galleryCurrentIndex}
                      setGalleryCurrentIndex={setGalleryCurrentIndex}
                      photos={galleryPhoto}
                    />
                  )}
                </div>
                <div className='space-y-2 px-3 mt-2'>
                  <div className={`flex justify-end items-center`}>
                    <button onClick={() => navigate('/setting')}>
                      <img
                        src={settingImage}
                        alt='setting'
                        className='w-[30px]'
                      />
                    </button>
                  </div>
                  <h2 className='text-center text-4xl text-jrnyfy-textprimary max-[550px]:text-3xl'>
                    {profileSelected?.name}
                  </h2>
                </div>
              </div>
              <div>
                {profileSelected && user?.id != profileSelected?.id && (
                  <FfButtons
                    user={{
                      id: userParams.id,
                      sk: userParams.sk,
                      createdAt: userParams.createdAt,
                    }}
                  ></FfButtons>
                )}
              </div>
              {userParams.id && <Count user={{ id: userParams.id }}></Count>}

              {/* -------filter button-------- */}
              <div className='flex justify-center space-x-4 px-3'>
            
                  {userFilter?.length>0&&(<HorizontalTapView
                    items={userFilter}
                    callback={(value) => {
                      init();
                      setJourneyFilter(value);
                    }}
                  />)}
        
                
              </div>
              {/* -------follow count part-------- */}
              <div className='flex justify-between flex-wrap px-3 grid grid-cols-3 gap-4 pb-40'>
                {journeys.length
                  ? journeys.map((val, ind) => <Myjrny data={val} key={ind} templatePhotoCount={templatePhotoCount} />)
                  : ''}
                {loadingState && (
                  <Facebook className='max-w-[545px]' viewBox='0 0 200 124' />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserProfile;
