import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { parseServiceDetailsParams } from '../../utils/get-nav-option';
import ServiceDetails from './ServiceDetails';
import ServiceDetailsSidebar from './ServiceDetailsSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getProductById } from '../../redux/slices/mobile/productSlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { YesNo } from '../utils/subjects';

const ServiceDetailsPage = () => {
  const location = useLocation();
  const [showAvailableTime, setShowAvailableTime] = useState(false);
  const { auth } = useSelector(authSelector);
  const [searchParams] = useSearchParams();
  const paramData = parseServiceDetailsParams(searchParams);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [selectedPeople, setSelectedPeople] = useState(undefined);
  const showHideAvailableTimeHandle = (isEnable, date, people) => {
    setSelectedDate(date);
    setSelectedPeople(people);
    setShowAvailableTime(isEnable);
  };

  useEffect(() => {
    dispatch(
      getProductById({
        token: auth.token,
        id: paramData.id,
        data: {
          isPublic: YesNo.No,
          sk: paramData.sk,
          createdAt: paramData.createdAt,
          withReview: YesNo.Yes,
        },
      })
    );
  }, []);
  return (
    <main className='pt-8'>
      <div className='max-w-[1100px] mx-auto'>
        <div className='flex gap-5'>
          <ServiceDetails
            selectedDate={selectedDate}
            selectedPeople={selectedPeople}
            showHideTimeStatus={showAvailableTime}
          />
          <ServiceDetailsSidebar
            showHideAvailableTimeHandle={showHideAvailableTimeHandle}
          />
        </div>
      </div>
    </main>
  );
};

export default ServiceDetailsPage;
