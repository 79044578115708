import React, { useState } from 'react';
import Persons from '../Persons/Persons';
import { buildUrlToSize } from '../../res/commonFunctions';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import { ParticipantStatus, PhotoSize } from '../utils/subjects';
import { useDispatch, useSelector } from 'react-redux';
import {
  addParticipantIntoActivity,
  deleteParticipantFromActivity,
} from '../../redux/slices/mobile/activitySlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';

const FollowerList = ({
  users,
  setUsers,
  jrnyActivity,
  userArr,
  userAdd,
  val,
  ind,
}) => {
  console.log('Valddd', val);
  const dispatch = useDispatch();
  let authinfo = useSelector(authSelector).auth;
  const [participantAddStates, setParticipantAddStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  const [requestCancelState, setRequestCancelState] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const participantStatus =
    val?.participant?.status != undefined
      ? val?.participant?.status
      : val?.status;
  console.log('StatusParticipant', participantStatus);
  const removeParticipant = (data) => {
    setRequestCancelState({ ...requestCancelState, loading: true });
    dispatch(
      deleteParticipantFromActivity({
        data: {
          participantId: data.id,
          sk: jrnyActivity.sk,
          createdAt: jrnyActivity.createdAt,
          skParticipant: data.sk,
        },

        token: authinfo.token,
        id: jrnyActivity.id,
      })
    ).then((res) => {
      console.log('Response', res.meta.requestStatus);

      if (res.meta.requestStatus == 'fulfilled') {
        setRequestCancelState({
          ...requestCancelState,
          loading: false,
          fulfilled: true,
        });
        setTimeout(() => {
          let user = [...users];
          user[ind] = { ...data, participant: { status: undefined } };
          setUsers(user);
          setRequestCancelState({
            ...requestCancelState,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
      if (res.meta.requestStatus == 'rejected') {
        setRequestCancelState({
          ...requestCancelState,
          loading: false,
          rejected: true,
        });
        setTimeout(() => {
          setRequestCancelState({
            ...requestCancelState,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };

  const addParticipant = (data) => {
    setParticipantAddStates({ ...participantAddStates, loading: true });
    dispatch(
      addParticipantIntoActivity({
        data: {
          sk: jrnyActivity.sk,
          createdAt: jrnyActivity.createdAt,
          skParticipant: data.sk,
        },
        token: authinfo.token,
        id: jrnyActivity.id,
        participantId: data.id,
      })
    ).then((res) => {
      let user = [...users];

      console.log('Response', res);
      if (res.meta.requestStatus == 'fulfilled') {
        setParticipantAddStates({
          ...participantAddStates,
          loading: false,
          fulfilled: true,
        });
        setTimeout(() => {
          setParticipantAddStates({
            ...participantAddStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
          user[ind] = {
            ...data,
            participant: { status: res?.payload?.participant?.status },
          };
          setUsers(user);
        }, 3000);
      }
      if (res.meta.requestStatus == 'rejected') {
        setParticipantAddStates({
          ...participantAddStates,
          loading: false,
          rejected: true,
        });
        setTimeout(() => {
          setParticipantAddStates({
            ...participantAddStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };

  //   let userSelect = (data) => {
  //     let check = userArr.some((val) => val.sk === data.sk);
  //     if (check) {
  //       let checked = userArr.filter((val) => val.sk !== data.sk);
  //       userAdd((state) => checked);
  //       setRequestCancelState({ ...requestCancelState, loading: true });
  //       dispatch(
  //         deleteParticipantFromActivity({
  //           data: {
  //             participantId: data.id,
  //             sk: jrnyActivity.sk,
  //             createdAt: jrnyActivity.createdAt,
  //             skParticipant: data.sk,
  //           },

  //           token: authinfo.token,
  //           id: jrnyActivity.id,
  //         })
  //       ).then((res) => {
  //         console.log('Response', res.meta.requestStatus);
  //         if (res.meta.requestStatus == 'fulfilled') {
  //           setRequestCancelState({
  //             ...requestCancelState,
  //             loading: false,
  //             fulfilled: true,
  //           });
  //           setTimeout(() => {
  //             setRequestCancelState({
  //               ...requestCancelState,
  //               loading: false,
  //               fulfilled: false,
  //               rejected: false,
  //             });
  //           }, 3000);
  //         }
  //         if (res.meta.requestStatus == 'rejected') {
  //           setRequestCancelState({
  //             ...requestCancelState,
  //             loading: false,
  //             rejected: true,
  //           });
  //           setTimeout(() => {
  //             setRequestCancelState({
  //               ...requestCancelState,
  //               loading: false,
  //               fulfilled: false,
  //               rejected: false,
  //             });
  //           }, 3000);
  //         }
  //       });
  //     } else {
  //       userAdd((state) => state.concat(data));
  //       // modalControl();
  //       setParticipantAddStates({ ...participantAddStates, loading: true });
  //       dispatch(
  //         addParticipantIntoActivity({
  //           data: {
  //             sk: jrnyActivity.sk,
  //             createdAt: jrnyActivity.createdAt,
  //             skParticipant: data.sk,
  //           },
  //           token: authinfo.token,
  //           id: jrnyActivity.id,
  //           participantId: data.id,
  //         })
  //       ).then((res) => {
  //         console.log('Response', res.meta.requestStatus);
  //         if (res.meta.requestStatus == 'fulfilled') {
  //           setParticipantAddStates({
  //             ...participantAddStates,
  //             loading: false,
  //             fulfilled: true,
  //           });
  //           setTimeout(() => {
  //             setParticipantAddStates({
  //               ...participantAddStates,
  //               loading: false,
  //               fulfilled: false,
  //               rejected: false,
  //             });
  //           }, 3000);
  //         }
  //         if (res.meta.requestStatus == 'rejected') {
  //           setParticipantAddStates({
  //             ...participantAddStates,
  //             loading: false,
  //             rejected: true,
  //           });
  //           setTimeout(() => {
  //             setParticipantAddStates({
  //               ...participantAddStates,
  //               loading: false,
  //               fulfilled: false,
  //               rejected: false,
  //             });
  //           }, 3000);
  //         }
  //       });
  //     }
  //   };
  return (
    <div className='flex justify-between items-center' key={ind}>
      <Persons
        avater={buildUrlToSize(val?.user?.thumbnail, PhotoSize.Large)}
        name={val.name}
        className='w-[40px] h-[40px] rounded-[50%] text-white'
        nameStyle='text-jSecTitle font-semibold'
      />
      <div>
        {participantStatus == undefined && (
          <FunctionalButton
            actionStates={participantAddStates}
            callback={() => addParticipant(val)}
            actionStatesTexts={{
              default: 'add',
              loading: 'adding',
              fulfilled: 'Added',
              rejected: 'add Failed',
            }}
          />
        )}
        {participantStatus == ParticipantStatus.Pending && (
          <FunctionalButton
            actionStates={requestCancelState}
            callback={() => removeParticipant(val)}
            actionStatesTexts={{
              default: 'Cancel Request',
              loading: 'canceling',
              fulfilled: 'canceled successfully',
              rejected: 'cancel Failed',
            }}
          />
        )}
        {participantStatus == ParticipantStatus.Accepted && (
          <FunctionalButton
            actionStates={requestCancelState}
            callback={() => removeParticipant(val)}
            actionStatesTexts={{
              default: 'Remove',
              loading: 'removing',
              fulfilled: 'removed successfully',
              rejected: 'remove Failed',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FollowerList;
