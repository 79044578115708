import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { networkStatusSelector } from '../../redux/slices/web/networkStatusSlice';
import moment from "moment";
import {
  activitySelector,
  updateActivity,
  uploadGalleryPhotos,
} from '../../redux/slices/mobile/activitySlice';
import { removeSpecialCharacters } from '../../res/commonFunctions';
import {
  FileUploadFor,
  PhotoSource,
  RecordStatus,
  TimelineType,
  YesNo,
} from '../utils/subjects';
import { onTypeChange } from '../utils/typing-change';
import ProgressPicThumb from './progressPicThumb';
import backBtn from '../../assets/icons/back.png';

const CompleteJourneyModal = ({
  setSuccessStoryDescription,
  completeYourJourneyModal,
  completeYourJourneyModalHandle,
  timeLineContent,
  setTimelineContent,
  activityManageModalHandle,
}) => {
  const [typeInput, setTypeInput] = useState(setSuccessStoryDescription);

  let dispatch = useDispatch();
  let { auth } = useSelector(authSelector);
  let { connection } = useSelector(networkStatusSelector);
  const [isEditEnable, setIsEditEnable] = useState(false);
  const { activity } = useSelector(activitySelector);
  const [storyImages, setStoryImages] = useState([]);

  const UploadStory = () => {
    console.log('Upload');
    let formData = new FormData();
    let galleryItems = [];
    for (let i = 0; i < storyImages.length; i++) {
      formData.append('photos', storyImages[i]?.file);
      galleryItems.push({
        caption: '',
        position: '',
        photoSource: PhotoSource.LocalStorage,
        photoReference: storyImages[i]?.url,
      });
    }
    setTimelineContent([
      {
        loading: true,
        files: [...galleryItems],
        type: 16,
        user: auth?.user,
        description: typeInput,
      },
      ...timeLineContent,
    ]);
    let tempTimelineContent = [
      {
        files: [...galleryItems],
        user: auth?.user,
        type: 16,
        description: typeInput,
      },
      ...timeLineContent,
    ];
    formData.append('id', activity.id);
    formData.append('sk', activity.sk);
    formData.append("date", moment(new Date()).unix());
    formData.append("multiple", YesNo.Yes); // 0 for activity , 1 for product
    formData.append("progress", YesNo.Yes);
    formData.append('createdAt', activity.createdAt);
    formData.append('finished', YesNo.Yes);
    formData.append('name', removeSpecialCharacters(activity.name));
    formData.append('uploadFor', FileUploadFor.Activity);

    dispatch(
      uploadGalleryPhotos({
        formData,
        token: auth.token,
        callback: (res) => {
          console.log('Progress Pic Upload', timeLineContent);
          // setDataLoading(false);
          tempTimelineContent[0] = res?.data?.results?.timeline;
          setTimelineContent(tempTimelineContent);
        },
      })
    );
  };

  useEffect(() => {
    setTypeInput(setSuccessStoryDescription);
  }, [setSuccessStoryDescription]);

  return (
    <Popup
      open={completeYourJourneyModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <AnimatePresence>
        <div className='relative space-y-5 bg-white w-[500px] rounded-[20px] p-10 max-[767px]:p-5 max-[767px]:h-screen  max-[767px]:overflow-y-scroll max-[600px]:rounded-none '>
          {/* -------cross button-------- */}

          <div className='flex gap-3 '>
            <button onClick={completeYourJourneyModalHandle}>
              <img
                src={backBtn}
                alt='cross'
                className='cursor-pointer w-[20px]'
              />
            </button>

            <p className='text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary'>
              Complete Your Journey
            </p>
          </div>
          <>
            {!isEditEnable && (
              <>
                <div className='flex flex-col mt-[20px] relative'>
                  <textarea
                    onChange={(e) => {
                      if (connection) {
                        setTypeInput(e.target.value);
                      }
                    }}
                    spellCheck='true'
                    className='resize-none  min-h-[100px] z-40 break-words focus:outline-none border-b-2'
                    value={typeInput}
                  />

                  {!typeInput && (
                    <span className='absolute top-0 pointer-events-none w-full text-jrnyfy-textprimary font-Montserrat z-[999]'>
                      Share your success story. What were the highlights. What
                      tips do you have for others who is looking to embark on a
                      similar journey
                    </span>
                  )}
                </div>
              </>
            )}
            <div className='items-center space-y-6 '>
              <p className='text-[14px]'>
                picture captures a thousand, consider adding a few to remind
                yourself where things were
              </p>
              <form className='w-full'>
                <div className='flex justify-evenly'>
                  <ProgressPicThumb
                    setIsEditEnable={setIsEditEnable}
                    isEditEnable={isEditEnable}
                    btnText='Add Success Story Images'
                    selectImg={storyImages}
                    setSelectImg={setStoryImages}
                  />
                  {/* <ProgressPicThumb />
              <ProgressPicThumb />
              <ProgressPicThumb /> */}
                </div>
              </form>
            </div>
            {!isEditEnable && (
              <div className='text-center mt-10'>
                <button
                  className='bg-jrnyfy-themeColor p-[10px_20px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic'
                  onClick={() => {
                    activityManageModalHandle();
                    if (storyImages) {
                      UploadStory();
                    }

                    completeYourJourneyModalHandle();
                    // if (connection) {
                    //   dispatch(
                    //     updateActivity({
                    //       data: {
                    //         finished: YesNo.Yes,
                    //         description: typeInput ? typeInput : undefined,
                    //         sk: activity.sk,
                    //         createdAt: activity.createdAt,
                    //       },
                    //       token: auth.token,
                    //       id: activity.id,
                    //       callback: () => {},
                    //     })
                    //   );
                    // }
                  }}
                >
                  complete journey
                </button>
              </div>
            )}
          </>
        </div>
      </AnimatePresence>
    </Popup>
  );
};

export default CompleteJourneyModal;
