import { AnimatePresence } from 'framer-motion';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import Badges from '../../assets/images/badges.svg';
import { useEffect, useRef } from 'react';

const BadegsModal = ({ bModal, bModalControl }) => {
  const inputRef = useRef();
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);
  return (
    <Popup
      open={bModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        backgroundColor: 'transparent',
        border: 'none',
      }}
    >
      <div className='relative bg-white mx-auto rounded-[20px] px-[55px] py-[40px] min-[300px]:max-[700px]:px-[40px] max-w-[555px] max-h-screen overflow-auto'>
        {/* -------cross button-------- */}
        <img
          src={Cross}
          alt='cross'
          className='cursor-pointer w-[25px] absolute top-[20px] left-[25px]'
          onClick={bModalControl}
        />

        {/* ------- main body start---- */}
        <div>
          <div className='flex justify-center items-center mb-[20px]'>
            <img src={Badges} alt='place' className='max-w-[50px] mr-2' />
            <p className='text-jrny font-semibold'>
              {} people like your journey
            </p>
          </div>
          <div className='relative'>
            <div className='relative'>
              <input
                type='search'
                ref={inputRef}
                placeholder='search badges'
                className='bg-gray-100 w-full p-[10px_20px] rounded-[50px] focus:outline-none'
                onChange={(e) => {}}
              />
              <button className='w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]'></button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default BadegsModal;
