import { YesNo } from './subjects';

export const onScrollEndTrigger = (
  setCurrentPage,
  isKeepScrolling,
  getDataLength
) => {
  if (isKeepScrolling() == YesNo.Yes) {
    document.onscroll = () => {
      if (
        window.innerHeight + window.scrollY + 1 >=
        document.body.scrollHeight
      ) {
        setCurrentPage(getDataLength());
      }
    };
  }
};
