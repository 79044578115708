import React from "react";
import { Link } from "react-router-dom";
import Seo from "../components/Seo/Seo";

const Terms = () => {
  return (
    <>
      <Seo
        title="Trending Journeys"
        description="Discover incredible places to see and amazing things to do"
        type="webapp"
        name="jrnyfy crop"
        canonicalHref="/terms"
      />
      <main className="pt-4">
        <div className="w-[90%] min-[1100px]:w-[75%] mx-auto flex justify-center min-[3000px]:w-1/2">
          <div className="w-full">
            <section className="mb-10">
              <div className="mb-10">
                <h3 class="text-3xl font-bold dark:text-white mb-5">
                  User License Agreement (ULA) - Jrnyfy App
                </h3>
                <span>Effective Date: Oct 10, 2023</span>
              </div>
              <div>
                <p className="mb-5">
                  This User License Agreement ("
                  <span className="font-bold">Company</span>") is entered into
                  by and between you (the "
                  <span className="font-bold">User</span>") and Jrnyfy Corp ("
                  <span className="font-bold">Jrnyfy</span>," "
                  <span className="font-bold">We</span>," or "
                  <span className="font-bold">Us</span>"). This Agreement
                  governs your use of the Jrnyfy mobile application (the "
                  <span className="font-bold">App</span>") and any related
                  services provided by Jrnyfy Corp.
                </p>
                <ol start="1" className="ml-10">
                  <li className="mb-5">
                    <strong>1. Acceptance of Terms</strong>
                    <p className="pl-5">
                      By downloading, installing, or using the Jrnyfy App, you
                      agree to be bound by the terms and conditions of this
                      Agreement. If you do not agree to these terms, please do
                      not use the App.
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>2. Description of Services</strong>
                    <p className="pl-5">
                      The Jrnyfy App is designed to enhance your travel
                      experiences. It allows users to create personalized
                      journeys, add places to their itineraries, and set
                      milestones. Additionally, users can book services from
                      various vendors through the App. It's important to note
                      that these services are provided by third-party vendors
                      not owned or operated by Jrnyfy Corp.
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>3. User Accounts</strong>
                    <p className="pl-5">
                      To unlock the full functionality of the App, you may be
                      required to create a user account. You agree to provide
                      accurate and complete information during the registration
                      process. Safeguard your account credentials, as you are
                      responsible for all activities that occur under your
                      account.
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>4. User-Generated Content</strong>
                    <p className="pl-5">
                      The App encourages users to share their travel
                      experiences, recommendations, and insights. You retain
                      ownership of your content, but by uploading it to the App,
                      you grant Jrnyfy Corp a non-exclusive, worldwide,
                      royalty-free license to use, reproduce, and distribute
                      your content for the purpose of operating and improving
                      the App.
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>5. Booking Services</strong>
                    <p className="pl-5">
                      Jrnyfy facilitates the booking of services from
                      third-party vendors to enhance your travel experience.
                      Jrnyfy Corp is not responsible for the quality, delivery,
                      or any issues related to these services. Users engage with
                      vendors at their own discretion, and Jrnyfy Corp disclaims
                      any liability associated with third-party services.
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>6. Privacy Policy</strong>
                    <p className="pl-5">
                      Your use of the App is also governed by our Privacy
                      Policy, which can be found at
                      www.jrnyfy.com/privacy-policy. By using the App, you
                      consent to the collection, use, and disclosure of your
                      personal information as described in the{" "}
                      <a
                        className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                        href="https://jrnyfy.com/privacy-policy"
                      >
                        Privacy Policy.
                      </a>{" "}
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>7. Intellectual Property</strong>
                    <p className="pl-5">
                      The App, including its content and features, is the
                      property of Jrnyfy Corp and is protected by intellectual
                      property laws. You may not reproduce, distribute, or
                      create derivative works without our express written
                      consent.
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>8. Termination</strong>
                    <p className="pl-5">
                      Jrnyfy Corp reserves the right to terminate or suspend
                      your account and access to the App at any time for any
                      reason, including violation of this Agreement.
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>9. Limitation of Liability</strong>
                    <p className="pl-5">
                      Jrnyfy Corp is not liable for any direct, indirect,
                      incidental, special, or consequential damages arising out
                      of or in connection with your use of the App or
                      third-party services.
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>10. Changes to the Agreement</strong>
                    <p className="pl-5">
                      Jrnyfy Corp reserves the right to modify or amend this
                      Agreement at any time. Changes will be effective
                      immediately upon posting. Continued use of the App after
                      changes constitute acceptance of the modified Agreement.
                    </p>
                  </li>
                  <li className="mb-5">
                    <strong>11. Governing Law</strong>
                    <p className="pl-5">
                      This Agreement is governed by and construed in accordance
                      with the laws of Ontario, Canada. Any disputes arising
                      under or in connection with this Agreement shall be
                      resolved in the state or federal courts located within
                      Ontario, Canada.
                    </p>
                  </li>
                </ol>
                <p className="mb-10">
                  By using the Jrnyfy App, you acknowledge that you have read,
                  understood, and agree to be bound by this User License
                  Agreement. If you have any questions or concerns, please
                  contact us at contactus@jrnyfy.com
                </p>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default Terms;
