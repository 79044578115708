import axios from 'axios';
import baseUrl from '../../../environments/proxyUrl';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getReportReasonsList = createAsyncThunk(
  'report/getReportReasonsList',
  async ({ token, skip, limit }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/tags/v1/report-issue?skip=${skip}&limit=1000`,
        { headers: { Authorization: token } }
      );

      return res.results;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);
export const reportItem = createAsyncThunk(
  'report/reportItem',
  async ({ data, id, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/tags/v1/report/`,
        data,
        { headers: { Authorization: token } }
      );

      return res.results;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

const reportSlice = createSlice({
  name: 'report',
  initialState: {
    loading: false,
    errors: [],
    reportsInfoList: [],
  },
  reducers: {},
  extraReducers: {
    [getReportReasonsList.pending]: (state) => {
      state.loading = true;
    },
    [getReportReasonsList.fulfilled]: (state, action) => {
      state.loading = false;
      const callcc = (f) => {
        const box = Symbol();
        try {
          return f((unbox) => {
            throw { box, unbox };
          });
        } catch (e) {
          if (e?.box == box) return e.unbox;
          throw e;
        }
      };

      // stacksnippets doesn't support modules yet
      const module = callcc;

      const index = module((exports) => {
        const empty = (_) => new Map();
        const update = (t, k, f) => t.set(k, f(t.get(k)));
        const append = (t, k, v) => update(t, k, (a = []) => [...a, v]);
        const _new = (a = [], f) =>
          a.reduce((t, v) => append(t, f(v), v), empty());
        exports({ empty, update, append, new: _new });
      });

      const graph = module((exports) => {
        const empty = (_) => {};
        const _new = (i, f, root = undefined) => {
          const many = (a = []) => a.map((v) => one(v));
          const one = (v) => f(v, (next) => many(i.get(next)));
          return many(i.get(root));
        };
        exports({ empty, new: _new });
      });

      const data = [...action.payload];

      const tree = graph.new(
        // create index by node.parent_id
        index.new(data, (node) => node.parentId),
        // construct node's children using node.id
        (node, children) => ({ ...node, children: children(node.id) })
      );
      state.reportsInfoList = tree;
      // console.log('reports', JSON.stringify(tree, null, 2));
    },
    [getReportReasonsList.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [reportItem.pending]: (state) => {
      state.loading = true;
    },
    [reportItem.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [reportItem.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {} = reportSlice.actions;
export default reportSlice.reducer;

export const reportSelector = (state) => state.report;
