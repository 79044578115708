import React from 'react';
import SetupForm from './SetupForm';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import baseUrl from '../../environments/proxyUrl';

const publishableKey =
  'pk_test_51J9D1pGRxQkYXSuuTBYySmkHA85dKU5oK8en3SyOozJcMayhBkYS4c2aTGmVdgaNkrWPubIQ7Kt1QJdOfLXr4j8o00c7VQ09jl';
const stripePromise = loadStripe(publishableKey);

const StripePayment = ({
  selectedDate,
  selectedPeople,
  selectTime,
  clientSecret,
  product,
  selectedActivity,
  paymentCompleteModalHandle,
  paymentModalHandle,
}) => {
  const { auth } = useSelector(authSelector);

  const options = {
    // passing the client secret obtained in step 3
    clientSecret: clientSecret.setupIntent,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm
        selectedDate={selectedDate}
        selectedPeople={selectedPeople}
        paymentCompleteModalHandle={paymentCompleteModalHandle}
        paymentModalHandle={paymentModalHandle}
        selectTime={selectTime}
        clientSecret={clientSecret}
        activity={selectedActivity}
        product={product}
      />
    </Elements>
  );
};

export default StripePayment;
