import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import Thumbnail from '../thumbnails/Thumbnail';
import profile from '../../assets/images/profile-pic.svg';
import ReactStars from 'react-rating-stars-component';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { PhotoSize, UserContentFilter } from '../utils/subjects';
import {
  getReviews,
  productSelector,
} from '../../redux/slices/mobile/productSlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { buildUrlToSize } from '../../utils/build-image-url';
import { getUserThumbnailFromName } from '../utils/getUserThumnailFromName';

const ReviewsModal = ({ product, reviewsModalState, reviewsModalHandle }) => {
  const dispatch = useDispatch();
  const { auth } = useSelector(authSelector);
  const { reviews } = useSelector(productSelector);
  const [startFrom, setStartFrom] = useState(0);
  const [dataSize, setDataSize] = useState(10);
  useEffect(() => {
    dispatch(
      getReviews({
        data: {
          filterId: product.id,
          from: 0,
          size: 10,
          filter: UserContentFilter.GetRatingsOfAService,
        },
        token: auth.token,
      })
    );
  }, []);

  useEffect(() => {}, [reviews.length]);
  return (
    <Popup
      open={reviewsModalState}
      closeOnDocumentClick
      onClose={reviewsModalHandle}
      contentStyle={{
        maxWidth: '520px',
        minHeight: '700px',
        borderRadius: '20px',
        padding: '40px',
      }}
    >
      <div className='flex flex-col justify-between gap-10 items-center'>
        <div className='max-h-[600px] p-[10px] w-full space-y-5 overflow-y-scroll'>
          {reviews &&
            reviews?.reviews?.map((item) => {
              return (
                <div className='flex gap-5'>
                  {item.thumbnail === undefined ? (
                    <div className='shrink-0 flex items-center justify-center text-center text-[22px] bg-jrnyfy-themeColor font-SourceSansPro italic rounded-[50%] text-white border-4 w-[50px] h-[50px]'>
                      {getUserThumbnailFromName(item)}
                    </div>
                  ) : (
                    <img
                      src={buildUrlToSize(item.thumbnail, PhotoSize.Large)}
                      className='rounded-[50%] border-4 w-[50px] h-[50px]'
                      alt={getUserThumbnailFromName(item)}
                    />
                  )}
                  <div>
                    <div className='text-jrnyfy-title'>{item.name}</div>
                    <div className='flex flex-col gap-1'>
                      <ReactStars
                        classNames='leading-[20px]'
                        count={5}
                        size={16}
                        edit={false}
                        value={item.rating}
                        isHalf={true}
                        emptyIcon={<i className='far fa-star'></i>}
                        halfIcon={<i className='fa fa-star-half-alt'></i>}
                        fullIcon={<i className='fa fa-star'></i>}
                        activeColor='#ffd700'
                      />
                      <span className='text-[10px] text-jrnyfy-textprimary leading-[11px]'>
                        Posted on{' '}
                        <Moment format='D MMM YYYY'>
                          2023-04-27T15:47:21.081Z
                        </Moment>
                      </span>
                    </div>
                    <p className='text-[15px] text-jrnyfy-textprimary mt-2 leading-[16px]'>
                      {item.description}
                    </p>
                  </div>
                </div>
              );
            })}

          {/* {activeActivities &&
            activeActivities.map((item) => {
              return (
                <div className='flex justify-between w-full'>
                  <Thumbnail btnFollow={false} data={item} />

                  <button className='p-[2px_20px_2px_20px] text-[14px] border italic rounded-[50px] text-jrnyfy-textprimary transition-all font-SourceSansPro'>
                    add
                  </button>
                </div>
              );
            })} */}
        </div>
        {reviews?.reviews?.length >= 10 && (
          <button
            onClick={() => {
              dispatch(
                getReviews({
                  data: {
                    filterId: product.id,
                    from: startFrom + 10,
                    size: 10,
                    filter: UserContentFilter.GetRatingsOfAService,
                  },
                  token: auth.token,
                })
              );
              setStartFrom(startFrom + 10);
            }}
            type='button'
            className='cursor-pointer border border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor  p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro'
          >
            show more reviews
          </button>
        )}
      </div>
    </Popup>
  );
};

export default ReviewsModal;
