import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tagPaginationOffset } from "../../../res/dimens";
import { baseUrl } from "../../../environments/proxyUrl";
import {
  parse_SourceArray,
  parse_SourceObjectWithInnerHitsTags,
  parse_SourceObjectWithInnerHits_Leads,
  parse_userListObjectWithInnerHits_fofo,
} from "./elasticParser";
import { sortGallery } from "../../../components/utils/gallerySorting";

export const createProduct = createAsyncThunk(
  "product/create",
  async ({ data, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/products/v1/service`,
        data,
        { headers: { Authorization: token } }
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateProduct = createAsyncThunk(
  "product/update",
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/service/${id}`,
        data,
        { headers: { Authorization: token } }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateProductName = createAsyncThunk(
  "product/updateName",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/name/${id}`,
        data,
        { headers: { Authorization: token } }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createPromotion = createAsyncThunk(
  "product/createPromotion",
  async ({ token, data, id }) => {
    const { data: res } = await axios.post(
      `${baseUrl}/api/products/v1/promotion/${id}`,
      data,
      {
        validateStatus: false,
        headers: {
          Authorization: token,
        },
      }
    );

    return res.results;
  }
);
export const updatePromotion = createAsyncThunk(
  "product/updatePromotion",
  async ({ token, data, id }) => {
    const { data: res } = await axios.put(
      `${baseUrl}/api/products/v1/promotion/${id}/${data.id}`,
      data,
      {
        validateStatus: false,
        headers: {
          Authorization: token,
        },
      }
    );

    return res.results;
  }
);
export const addTagIntoProduct = createAsyncThunk(
  "product/addTag",
  async ({ data, token, id, tagName, tagId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/tag/${id}`,
        { ...data, tagName, tagId },
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteTagFromProduct = createAsyncThunk(
  "product/deleteTag",
  async ({ data, token, id, tagName, tagId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/tag/${id}`,
        {
          params: { ...data, tagId, tagName },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createTaskForProduct = createAsyncThunk(
  "product/createTask",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/products/v1/task/${id}`,
        data,
        { headers: { Authorization: token } }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateTaskOfProduct = createAsyncThunk(
  "product/updateTask",
  async ({ data, token, id, taskId, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/task/${id}`,
        { ...data, taskId },
        {
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteTaskFromProduct = createAsyncThunk(
  "product/deleteTask",
  async ({ taskId, token, id, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/task/${id}`,
        {
          params: { ...data, taskId },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createGroupDiscountForProduct = createAsyncThunk(
  "product/createGroupDiscount",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/products/v1/gd/${id}`,
        data,
        { headers: { Authorization: token } }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
//TODO: no exist in the server side
export const updateGroupDiscountOfProduct = createAsyncThunk(
  "product/updateGroupDiscount",
  async ({ data, token, id, discountId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/gd/${id}/${discountId}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteGroupDiscountFromProduct = createAsyncThunk(
  "product/deleteGroupDiscount",
  async ({ token, id, size, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/gd/${id}`,
        {
          params: { ...data, size },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createEarlyBirdForProduct = createAsyncThunk(
  "product/createEarlyBird",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/products/v1/ebd/${id}`,
        data,
        { headers: { Authorization: token } }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
//TODO: do not exist in the server side
export const updateEarlyBirdfProduct = createAsyncThunk(
  "product/updateEarlyBird",
  async ({ data, token, id, discountId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/early-bird-discount/${id}/${discountId}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteEarlyBirdFromProduct = createAsyncThunk(
  "product/deleteEarlyBird",
  async ({ days, token, id, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/ebd/${id}`,
        {
          params: { ...data, days },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDayDiscountForProduct = createAsyncThunk(
  "product/createDayDiscount",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/products/v1/dd/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
//TODO: do not exist on server side

export const updateDayDiscountOfProduct = createAsyncThunk(
  "product/updateDayDiscount",
  async ({ data, token, id, dayDiscountId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/dd/${id}/${dayDiscountId}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteDayDiscountFromProduct = createAsyncThunk(
  "product/deleteDayDiscount",
  async ({ data, day, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/dd/${id}`,
        {
          params: { ...data, day },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createCancelChargeForProduct = createAsyncThunk(
  "product/createCancelChargeForProduct",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/products/v1/cc/${id}`,
        data,
        { headers: { Authorization: token } }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCancelChargeFromProduct = createAsyncThunk(
  "product/deleteCancelChargeFromProduct",
  async ({ data, token, id, days, cancel_id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/cc/${id}`,
        {
          params: { ...data, days },
          headers: { Authorization: token },
        }
      );
      return { ...res.results, cancel_id: cancel_id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const addOffDay = createAsyncThunk(
  "product/addOffDay",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/od/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteOffDay = createAsyncThunk(
  "product/deleteOffDay",
  async ({ data, token, id, day }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/od/${id}`,
        {
          params: { ...data, day },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const addOffDate = createAsyncThunk(
  "product/addOffDate",
  async ({ data, token, id, date }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/o-date/${id}`,
        { ...data, date },
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteOffDate = createAsyncThunk(
  "product/deleteOffDate",
  async ({ data, token, id, overridden, date }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/o-date/${id}`,
        {
          params: { ...data, date, overridden },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const addOffMonth = createAsyncThunk(
  "product/addOffMonth",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/o-month/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteOffMonth = createAsyncThunk(
  "product/deleteOffMonth",
  async ({ data, token, id, month }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/o-month/${id}`,
        {
          params: { ...data, month },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOffDatesOfAMonth = createAsyncThunk(
  "product/getOffDatesOfAMonth",
  async (
    { data, token, id, month, year, readOnly = false },
    { rejectWithValue }
  ) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/products/v1/o-d-m/${id}`,
        {
          params: { ...data, month, year },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createTimeSlot = createAsyncThunk(
  "product/createTimeSlot",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/products/v1/tslot/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateTimeSlot = createAsyncThunk(
  "product/updateTimeSlot",
  async ({ data, token, id, timeSlotId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/tslot/${id}`,
        { ...data, timeSlotId },
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteTimeSlot = createAsyncThunk(
  "product/deleteTimeSlot",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/tslot/${id}`,
        {
          params: { ...data, timeSlotId: data.timeSlot.id },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadProductPhotos = createAsyncThunk(
  "product/uploadProductPhoto",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/worker/v1/product-image-uploader`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      );
      return response;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const addLocationIntoProduct = createAsyncThunk(
  "product/addLocationIntoProduct",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/location/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "getProducts/getAllProducts",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/products/v1/service`,
        {
          headers: { Authorization: token },
          params: data,
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
//TODO: do not exist on server side
export const getLeads = createAsyncThunk(
  "product/getLeads",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(`${baseUrl}/api/search/v1/leads`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      return {
        size: data.size,
        from: data.from,
        leads: await parse_SourceObjectWithInnerHits_Leads(res),
      };
      //return parse_SourceArray(res.results);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMoreLeads = createAsyncThunk(
  "task/getMoreLeads",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(`${baseUrl}/api/search/v1/leads`, {
        params: { ...data },
        headers: { Authorization: token },
      });
      return {
        size: data.size,
        from: data.from,
        leads: await parse_SourceObjectWithInnerHits_Leads(res),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
// for vendor only
export const getProductById = createAsyncThunk(
  "product/getProductById",
  async ({ token, id, baseCurrency, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/products/v1/service/${id}`,
        {
          headers: { Authorization: token },
          params: data,
        }
      );
      let exchangeRate = 1;
      const pC = res.results.currency;
      const { photos, tags } = res.results;
      let nPhotos = [];
      if (!photos || photos.length == 0) {
        if (tags && tags.length > 0) {
          for (let i = 0; i < tags.length; i++) {
            if (tags[i].thumbnail && tags[i].thumbnail.length > 0) {
              nPhotos.push(tags[i].thumbnail);
            }
          }
        }
      } else {
        nPhotos = photos;
      }
      const result = {
        ...res.results,
        mrpBase: Math.round(res.results.mrp * exchangeRate * 100) / 100,
      };
      return result;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReviews = createAsyncThunk(
  "product/getReviews",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/search/v1/content/`, {
        params: data,
        headers: { Authorization: token },
      });
      console.log("raw response getSharesOfActivity", response);

      return {
        size: data.size,
        from: data.from,
        reviews: await parse_userListObjectWithInnerHits_fofo(
          response.data.results
        ),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

// TODO: do not found on server side
export const viewProductById = createAsyncThunk(
  "product/viewProductById",
  async (
    { token, baseCurrency, id, callback, activityId },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/products/v1/view/${id}/${activityId}`,
        { params: { ...data }, headers: { Authorization: token } }
      );
      let exchangeRate = 1;
      const pC = data.results.currency;

      const { photos, tags } = data.results;
      let nPhotos = [];
      if (!photos || photos.length == 0) {
        if (tags && tags.length > 0) {
          for (let i = 0; i < tags.length; i++) {
            if (tags[i].thumbnail && tags[i].thumbnail.length > 0) {
              nPhotos.push(tags[i].thumbnail);
            }
          }
        }
      } else {
        nPhotos = photos;
      }
      const result = {
        ...data.results,
        mrpBase: Math.round(data.results.mrp * exchangeRate * 100) / 100,
        photos: nPhotos,
      };
      if (callback) {
        callback(result);
      }
      return result;
      //return data.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMatchedTags = createAsyncThunk(
  "products/getMatchedTags",
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(`${baseUrl}/api/search/v1/tags`, {
        params: { ...data },
        headers: {
          Authorization: token,
        },
      });

      return parse_SourceArray(res.results);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const getMoreMatchedTags = createAsyncThunk(
  "products/getNextMatchedTags",
  async ({ token, data }) => {
    const { data: res } = await axios.get(`${baseUrl}/api/search/v1/tags`, {
      params: { ...data },
      headers: {
        Authorization: token,
      },
    });

    return parse_SourceArray(res.results);
  }
);
// export const createTag = createAsyncThunk(
//   'tags/createTag',
//   async ({token, data}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.post(`${baseUrl}/api/tags/v1`, data, {
//         headers: {
//           Authorization: token,
//         },
//       });
//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
// export const updateTag = createAsyncThunk(
//   'tags/updateTag',
//   async ({token, data, tagName}) => {
//     const {data: res} = await axios.put(
//       `${baseUrl}/api/tags/v1/${tagName}`,
//       data,
//       {
//         headers: {
//           Authorization: token,
//         },
//       },
//     );

//     return res.results;
//   },
// );
// export const uploadTagPhoto = createAsyncThunk(
//   'tag/uploadTagPhoto',
//   async ({formData, token}, {rejectWithValue}) => {
//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/worker/v1/tag-image-uploader`,
//         formData,
//         {
//           headers: {
//             Authorization: token,
//             'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
//           },
//         },
//       );

//       return response;
//     } catch (err) {
//       if (!err) {
//         throw err;
//       }
//       return rejectWithValue(err);
//     }
//   },
// );
//new image apis

//user can upload of max 7 pictures.
//user have to delete pictures to upload more
export const uploadGalleryPhotos = createAsyncThunk(
  "product/uploadGalleryPhotos",
  async ({ formData, token, callback }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/worker/v1/gallery`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      );

      if (callback) {
        callback(response.data.results);
      }
      return response.data.results;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const updateGallery = createAsyncThunk(
  "product/updateGallery",
  async ({ token, id, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/gallery/${id}`,
        data,
        { headers: { Authorization: token } }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGalleryItem = createAsyncThunk(
  "product/deleteGalleryItem",
  async (
    { photoReference, photoSource, id, token, sk, createdAt, callback },
    { rejectWithValue }
  ) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/gallery/${id}`,
        {
          params: { sk, createdAt, photoReference, photoSource },
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const redeemTicket = createAsyncThunk(
  "product/redeemedQRCode",
  async (
    { data, token, callback, ticketID, productId },
    { rejectWithValue }
  ) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/ticket/`,
        data,
        {
          headers: { Authorization: token },
        }
      );

      if (callback) {
        callback(res);
      }

      return { ...res.results, ticketID: ticketID };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      callback(false, err.response.data.errors);
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateTimeZone = createAsyncThunk(
  "product/updateTimeZone",
  async ({ data, token, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/service/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const addInterest = createAsyncThunk(
  "product/addInterest",
  async ({ data, token, id, interestId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/interest/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteInterest = createAsyncThunk(
  "product/deleteInterest",
  async ({ data, token, id, interestName }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/interest/${id}/${interestName}`,
        {
          params: { ...data },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
// export const getRedeemedList = createAsyncThunk(
//   'product/getRedeemedList',
//   async ({token}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.get(
//         `${baseUrl}/api/products/v1/user/review/redeemed`,
//         {
//           params: {...data},
//           headers: {Authorization: token},
//         },
//       );

//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );
// for activity user or for purchase.

// export const getUserReviewList = createAsyncThunk(
//   'product/getUserReviewList',
//   async ({token, id}, {rejectWithValue}) => {
//     try {
//       const {data: res} = await axios.get(
//         `${baseUrl}/api/products/v1/user/review/find/${id}`,
//         {
//           headers: {Authorization: token},
//         },
//       );

//       return res.results;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const addRedeemReview = createAsyncThunk(
  "product/addRedeemReview",
  async ({ token, data, id, productId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/ureview/${productId}`,
        { ...data, userId: id },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserReviewRating = createAsyncThunk(
  "product/getUserReviewRating",
  async ({ token, id, callback, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/products/v1/ureview`,
        {
          params: { ...data, userId: id },
          headers: {
            Authorization: token,
          },
        }
      );

      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProductTickets = createAsyncThunk(
  "product/getProductTickets",
  async ({ token, data, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/products/v1/ticket/`,
        {
          params: { ...data },
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res);
      }

      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCustomPriceOffer = createAsyncThunk(
  "product/addCustomPriceOffer",
  async (
    { token, data, id, userId, activityId, callback },
    { rejectWithValue }
  ) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/offer/${id}`,
        { ...data, activityId },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustomPriceOffer = createAsyncThunk(
  "product/deleteCustomPriceOffer",
  async ({ token, id, userId, activityId, data }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/offer/${id}`,
        {
          params: { ...data },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeProduct = createAsyncThunk(
  "product/removeProduct",
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.delete(
        `${baseUrl}/api/products/v1/service/${id}`,

        {
          headers: { Authorization: token },
          params: data,
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const ordersWithReservations = createAsyncThunk(
  "product/ordersWithReservations",
  async ({ token, data, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/products/v1/reservations/${id}`,
        {
          params: { ...data },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const ordersByDate = createAsyncThunk(
  "product/ordersByDate",
  async ({ token, data, id, timeSlotId }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/products/v1/orders/${id}`,
        {
          params: { ...data, timeSlotId },
          headers: { Authorization: token },
        }
      );
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const addMeetUpLocationForService = createAsyncThunk(
  "product/addMeetUpLocationForService",
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/location/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTheme = createAsyncThunk(
  "interest/getTheme",
  async ({ token, data }) => {
    const { data: res } = await axios.get(`${baseUrl}/api/tags/v1/theme/`, {
      params: { skip: data.skip, limit: data.limit },
      validateStatus: false,
      headers: {
        Authorization: token,
      },
    });
    console.log("GetTheme", res);
    return res.results;
  }
);

export const getBadges = createAsyncThunk(
  "product/getBadges",
  async ({ token, data, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(`${baseUrl}/api/tags/v1/badge`, {
        params: { ...data },
        headers: {
          Authorization: token,
        },
      });
      if (callback) {
        callback(res.results);
      }
      return {
        limit: data.limit,
        skip: data.skip,
        badges: res.results,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMoreBadges = createAsyncThunk(
  "product/getMoreBadges",
  async ({ token, data, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(`${baseUrl}/api/tags/v1/badge`, {
        params: { ...data },
        headers: {
          Authorization: token,
        },
      });
      console.log("GetMoreBadges", res);
      if (callback) {
        callback(res.results);
      }
      return {
        limit: data.limit,
        skip: data.skip,
        badges: res.results,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createCancellationPolicy = createAsyncThunk(
  "product/createCancellationPolicy",
  async ({ data, token, callback, id }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/products/v1/cc/${id}`,
        data,
        { headers: { Authorization: token } }
      );
      if (callback) {
        callback(res);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const requestApproval = createAsyncThunk(
  "product/requestApproval",
  async ({ data, token, id, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.put(
        `${baseUrl}/api/products/v1/ar/${id}`,
        data,
        {
          headers: { Authorization: token },
        }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createBadge = createAsyncThunk(
  "product/createBadge",
  async ({ data, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.post(
        `${baseUrl}/api/tags/v1/user/badge`,
        data,
        { headers: { Authorization: token } }
      );
      if (callback) {
        callback(res.results);
      }
      return res.results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const productSlice = createSlice({
  name: "product",
  initialState: {
    tags: [],
    selectedTags: [],
    newlyCreateTag: {},
    products: [],
    filters: [],
    errors: [],
    product: {},
    promotion: undefined,
    loading: false,
    uploadingPhoto: false,
    selectedTask: {},
    contentRangeLeads: undefined,
    contentRangeBadges: undefined,
    tickets: [],
    gallery: [],
    theme: [],
    contentRange: undefined,
    badges: [],
    sortedGalleryObjects: [],
    reviews: {},
  },
  reducers: {
    addBadges: (state, action) => {
      console.log("BadgesSlice", action.payload.badge);
      state.badges.unshift(action.payload.badge);
    },
    addTags: (state, action) => {
      state.product.tags.unshift(action.payload.tags);
    },

    setSelectedTask: (state, action) => {
      const { selectedTask } = action.payload;
      state.selectedTask = selectedTask;
    },
    setProductThumbnail: (state, action) => {
      const { thumbnail, id } = action.payload;

      let array = state.products.map((product) => {
        if (product.id === id) {
          product.thumbnail = thumbnail;
        }
        return product;
      });
      state.products = array;
    },
    clearErrors: (state, action) => {
      state.errors = [];
    },
    clearTags: (state, action) => {
      state.tags = [];
      state.newlyCreateTag = {};
      state.uploadingPhoto = false;
    },
    insertProductAfterCreate: (state, action) => {
      state.products.unshift(action.payload.results);
    },
    pushIntoSortedGallery: (state, action) => {
      const { galleryItems } = action.payload;

      if (state.sortedGalleryObjects.length == undefined) {
        state.sortedGalleryObjects = galleryItems;
      } else {
        let sG = state.sortedGalleryObjects;
        for (let i = 0; i < galleryItems.length; i++) {
          sG.unshift(galleryItems[i]);
        }
        state.sortedGalleryObjects = sG;
      }
    },
  },
  extraReducers: {
    [createProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.product = {};
    },
    [createProduct.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
    },
    [createProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [uploadProductPhotos.pending]: (state) => {
      state.errors = [];
      state.uploadingPhoto = true;
    },
    [uploadProductPhotos.fulfilled]: (state, action) => {
      state.errors = [];
      state.uploadingPhoto = false;
    },
    [uploadProductPhotos.rejected]: (state, action) => {
      state.uploadingPhoto = false;
      state.errors = action.payload;
    },
    [updateProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let array = state.products.map((item, index) => {
        if (action.payload.id === item.id) {
          return { ...item, ...action.payload };
        } else {
          return item;
        }
      });
      state.products = array;
      state.product = { ...state.product, ...action.payload };
      state.loading = false;
    },
    [updateProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [updateProductName.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateProductName.fulfilled]: (state, action) => {
      state.errors = [];
      let array = state.products.map((item, index) => {
        if (action.payload.id === item.id) {
          return { ...item, name: action.payload.name };
        } else {
          return item;
        }
      });
      state.products = array;
      let prod = state.product;
      let filteredTags = [];
      if (state.product.tags) {
        filteredTags = state.product.tags.filter(
          (a) => !action.payload.deletedTags.map((b) => b.id).includes(a.id)
        );
      }
      prod.tags = [...filteredTags, ...action.payload.tags];

      state.product = prod;
      state.loading = false;
    },
    [updateProductName.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteTagFromProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteTagFromProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let tags = state.product.tags.filter(
        (tag) => tag.name !== action.payload.tag.name
      );
      let newProduct = {
        ...state.product,
        tags,
      };
      state.product = newProduct;
      state.loading = false;
    },
    [deleteTagFromProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [createTaskForProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [createTaskForProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      if (state.product.tasks) {
        prod.tasks = [action.payload.task, ...state.product.tasks];
      } else {
        prod.tasks = [action.payload.task];
      }

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [createTaskForProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [updateTaskOfProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateTaskOfProduct.fulfilled]: (state, action) => {
      state.errors = [];
      state.product.tasks = action.payload.tasks;
      state.loading = false;
    },
    [updateTaskOfProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteTaskFromProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteTaskFromProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.tasks.filter(
        (task) => task.id !== action.payload.task.id
      );
      prod.tasks = [...updatedDocs];
      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [deleteTaskFromProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [createGroupDiscountForProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [createGroupDiscountForProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      if (state.product.groupDiscounts) {
        prod.groupDiscounts.push(action.payload.groupDiscount);
      } else {
        prod.groupDiscounts = [action.payload.groupDiscount];
      }

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [createGroupDiscountForProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [updateGroupDiscountOfProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateGroupDiscountOfProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.groupDiscounts.map((groupDiscount) => {
        if (groupDiscount.id === action.payload.groupDiscount.id) {
          return { ...groupDiscount, ...action.payload.groupDiscount };
        } else {
          return groupDiscount;
        }
      });
      prod.groupDiscounts = updatedDocs;
      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [updateGroupDiscountOfProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteGroupDiscountFromProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteGroupDiscountFromProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.groupDiscounts.filter(
        (groupDiscount) => groupDiscount.id !== action.payload.groupDiscount.id
      );

      prod.groupDiscounts = [...updatedDocs];

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [deleteGroupDiscountFromProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [createEarlyBirdForProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [createEarlyBirdForProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      if (state.product.earlyBirdDiscounts) {
        prod.earlyBirdDiscounts.push(action.payload.earlyBirdDiscount);
      } else {
        prod.earlyBirdDiscounts = [action.payload.earlyBirdDiscount];
      }

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [createEarlyBirdForProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [updateEarlyBirdfProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateEarlyBirdfProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.earlyBirdDiscounts.map((earlyBirdDiscount) => {
        if (earlyBirdDiscount.id === action.payload.earlyBirdDiscount.id) {
          return { ...earlyBirdDiscount, ...action.payload.earlyBirdDiscount };
        } else {
          return earlyBirdDiscount;
        }
      });
      prod.earlyBirdDiscounts = updatedDocs;
      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [updateEarlyBirdfProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteEarlyBirdFromProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteEarlyBirdFromProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.earlyBirdDiscounts.filter(
        (earlyBirdDiscount) =>
          earlyBirdDiscount.id !== action.payload.earlyBirdDiscount.id
      );

      prod.earlyBirdDiscounts = [...updatedDocs];

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [deleteEarlyBirdFromProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [createDayDiscountForProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [createDayDiscountForProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      if (state.product.dayDiscounts) {
        prod.dayDiscounts.push(action.payload.dayDiscount);
      } else {
        prod.dayDiscounts = [action.payload.dayDiscount];
      }

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [createDayDiscountForProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [updateDayDiscountOfProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateDayDiscountOfProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.dayDiscounts.map((dayDiscount) => {
        if (dayDiscount.id === action.payload.dayDiscount.id) {
          return { ...dayDiscount, ...action.payload.dayDiscount };
        } else {
          return dayDiscount;
        }
      });
      prod.dayDiscounts = updatedDocs;
      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [updateDayDiscountOfProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteDayDiscountFromProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteDayDiscountFromProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.dayDiscounts.filter(
        (dayDiscount) => dayDiscount.id !== action.payload.dayDiscount.id
      );

      prod.dayDiscounts = [...updatedDocs];

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [deleteDayDiscountFromProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [createCancelChargeForProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [createCancelChargeForProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      if (state.product.refundCharges) {
        prod.refundCharges.push(action.payload.refundCharge);
      } else {
        prod.refundCharges = [action.payload.refundCharge];
      }

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [createCancelChargeForProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [deleteCancelChargeFromProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteCancelChargeFromProduct.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.cancellationCharges.filter(
        (refundCharge) => refundCharge.id !== action.payload.cancel_id
      );
      prod.cancellationCharges = [...updatedDocs];
      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [deleteCancelChargeFromProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [addOffDay.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [addOffDay.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      if (state.product.offDays) {
        prod.offDays.push(action.payload.offDay);
      } else {
        prod.offDays = [action.payload.offDay];
      }

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [addOffDay.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [deleteOffDay.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteOffDay.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedOffDays = prod.offDays.filter(
        (offDay) => offDay.day != action.payload.offDay.day
      );
      prod.offDays = [...updatedOffDays];
      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [deleteOffDay.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [addOffMonth.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [addOffMonth.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      if (state.product.offMonths) {
        prod.offMonths.push(action.payload.offMonth);
      } else {
        prod.offMonths = [action.payload.offMonth];
      }

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [addOffMonth.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [deleteOffMonth.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteOffMonth.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.offMonths.filter(
        (offMonth) => offMonth.month != action.payload.offMonth.month
      );

      prod.offMonths = [...updatedDocs];

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [deleteOffMonth.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getOffDatesOfAMonth.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getOffDatesOfAMonth.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      prod.offDates = [...action.payload.offDates];
      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [getOffDatesOfAMonth.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [addOffDate.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [addOffDate.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      if (state.product.offDates) {
        prod.offDates.push(action.payload.offDate);
      } else {
        prod.offDates = [action.payload.offDate];
      }

      if (prod.overriddenOffDates) {
        const updatedDocs = prod.overriddenOffDates.filter(
          (offDate) => offDate.date !== action.payload.overriddenOffDate.date
        );
        prod.overriddenOffDates = [...updatedDocs];
      }

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [addOffDate.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [deleteOffDate.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteOffDate.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;

      if (action.payload.offDate && prod.offDates) {
        const updatedDocs = prod.offDates.filter(
          (offDate) => offDate.date !== action.payload.offDate.date
        );
        prod.offDates = [...updatedDocs];
      }
      if (action.payload.overriddenOffDate) {
        if (state.product.overriddenOffDates) {
          prod.overriddenOffDates.push(action.payload.overriddenOffDate);
        } else {
          prod.overriddenOffDates = [action.payload.overriddenOffDate];
        }
      }
      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [deleteOffDate.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [removeProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [removeProduct.fulfilled]: (state, action) => {
      let temp_products = state.products.filter(
        (item) => item.id !== action.payload.id
      );
      state.products = temp_products;
    },
    [removeProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [createTimeSlot.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [createTimeSlot.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      if (state.product.timeSlots) {
        prod.timeSlots.push(action.payload.timeSlot);
      } else {
        prod.timeSlots = [action.payload.timeSlot];
      }

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [createTimeSlot.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [updateTimeSlot.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateTimeSlot.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.timeSlots.map((timeSlot) => {
        if (timeSlot.id === action.payload?.timeSlots[0]?.id) {
          return { ...timeSlot, ...action.payload?.timeSlots[0] };
        } else {
          return timeSlot;
        }
      });
      prod.timeSlots = updatedDocs;
      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [updateTimeSlot.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteTimeSlot.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteTimeSlot.fulfilled]: (state, action) => {
      state.errors = [];
      let prod = state.product;
      const updatedDocs = prod.timeSlots.filter(
        (timeSlot) => timeSlot.id !== action.payload.timeSlot.id
      );

      prod.timeSlots = [...updatedDocs];

      state.product = {
        ...prod,
      };
      state.loading = false;
    },
    [deleteTimeSlot.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [getAllProducts.pending]: (state) => {
      state.errors = [];
      state.products = [];
      state.loading = true;
    },
    [getAllProducts.fulfilled]: (state, action) => {
      state.errors = [];
      state.products = action.payload;
      state.loading = false;
    },
    [getAllProducts.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getLeads.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },

    [getLeads.fulfilled]: (state, action) => {
      state.errors = [];
      state.product = {
        ...state.product,
        leads: [...action.payload.leads],
        readOnly: action.payload.readOnly,
      };

      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.leads.length;
      if (aSize < size) {
        state.contentRangeLeads = `${from}-${size}/false`;
      } else {
        state.contentRangeLeads = `${from}-${size}/true`;
      }
      state.loading = false;
    },
    [getLeads.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreLeads.pending]: (state) => {
      state.errors = [];
      // state.products = [];
      state.loading = true;
    },
    [getMoreLeads.fulfilled]: (state, action) => {
      state.errors = [];
      state.product = {
        ...state.product,
        leads: [...state.product.leads, ...action.payload.leads],
        readOnly: action.payload.readOnly,
      };
      state.loading = false;
      let size = action.payload.size;
      let from = action.payload.from;
      let aSize = action.payload.leads.length;
      let sum = parseInt(size) + parseInt(from);
      if (aSize < size) {
        state.contentRangeLeads = `${from}-${sum}/false`;
      } else {
        state.contentRangeLeads = `${from}-${sum}/true`;
      }
    },
    [getMoreLeads.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getProductById.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.product = [];
      state.gallery = [];
    },
    [getProductById.fulfilled]: (state, action) => {
      state.errors = [];
      state.product = action.payload;
      state.gallery = action.payload?.gallery;

      state.sortedGalleryObjects = sortGallery(action.payload.gallery);

      state.loading = false;
    },

    [getReviews.pending]: (state) => {
      state.errors = [];
      state.loading = true;
      state.reviews = { pending: true };
    },
    [getReviews.fulfilled]: (state, action) => {
      state.errors = [];
      state.reviews = action.payload;
      state.loading = false;
    },
    [getReviews.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getTheme.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getTheme.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getTheme.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      state.theme = action.payload;
    },
    [createCancellationPolicy.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [createCancellationPolicy.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [createCancellationPolicy.fulfilled]: (state, action) => {
      state.errors = [];
      state.loading = false;
      state.product?.cancellationCharges?.push(
        action.payload?.cancellationCharge
      );
    },
    [getProductById.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [updateGallery.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [updateGallery.fulfilled]: (state, action) => {
      if (action.payload.gallery1) {
        const sortedGallery = action.payload.gallery1.sort(
          (a, b) => a.position + "".localeCompare(b.position + "")
        );
        state.product.gallery = sortedGallery;
        state.gallery = sortedGallery;
      }
      state.loading = false;
    },
    [updateGallery.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteGalleryItem.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [deleteGalleryItem.fulfilled]: (state, action) => {
      let temp = state.product.gallery.filter(
        (gallery) =>
          action.payload.gallery.photoReference != gallery.photoReference
      );
      state.sortedGalleryObjects = sortGallery(temp);
      console.log("state.sortedGalleryObjects", state.sortedGalleryObjects);

      state.product.gallery = temp;
      state.gallery = temp;
      state.loading = false;
    },
    [deleteGalleryItem.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [uploadGalleryPhotos.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [uploadGalleryPhotos.fulfilled]: (state, action) => {
      let temp = [];
      if (state.product.gallery?.length > 0) {
        temp = [...state.product.gallery, ...action.payload.photos];
      } else {
        temp = action.payload.photos;
      }
      if (temp?.length > 0) {
        temp = temp.sort(
          (a, b) => a.position + "".localeCompare(b.position + "")
        );
      }
      state.product.gallery = temp;
      state.gallery = temp;
      state.loading = false;
    },
    [uploadGalleryPhotos.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [viewProductById.pending]: (state) => {
      state.errors = [];
      state.product = [];
      state.loading = true;
    },
    [viewProductById.fulfilled]: (state, action) => {
      state.errors = [];
      state.product = action.payload;
      state.loading = false;
    },
    [viewProductById.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMatchedTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.tags = action.payload;
    },
    [getMatchedTags.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getMoreMatchedTags.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [getMoreMatchedTags.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.length == 0) {
        state.contentRange = "max";
      }
      const aTags = state.tags.concat(action.payload);
      state.tags = aTags;
    },
    [getMoreMatchedTags.rejected]: (state, action) => {
      state.loading = false;
    },
    [addTagIntoProduct.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [addTagIntoProduct.fulfilled]: (state, action) => {
      let selectedTags = state.product.tags
        ? state.product.tags.map((item) => item)
        : [];
      selectedTags.push(action.payload.tag);
      state.product.tags = selectedTags;
      // state.tags = [];
      state.tags = state.tags.filter((t) => t.name !== action.payload.tag.name);
    },
    [addTagIntoProduct.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    // [createTag.pending]: state => {
    //   state.loading = true;
    //   state.errors = [];
    // },
    // [createTag.fulfilled]: (state, action) => {
    //   if (action.payload && action.payload.tag) {
    //     state.newlyCreateTag = action.payload.tag;
    //     if (state.product.tags && state.product.tags.length > 0) {
    //       let tags = state.product.tags.map(item => item);
    //       tags.push(action.payload.tag);
    //       state.product.tags = tags;
    //     } else {
    //       state.product.tags = [action.payload.tag];
    //     }
    //   }
    //   state.loading = false;
    // },
    // [createTag.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errors = action.payload;
    // },
    // [updateTag.pending]: state => {
    //   state.loading = true;
    //   state.errors = [];
    // },
    // [updateTag.fulfilled]: (state, action) => {
    //   state.loading = false;
    // },
    // [updateTag.rejected]: (state, action) => {
    //   state.loading = false;
    // },
    [createPromotion.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [createPromotion.fulfilled]: (state, action) => {
      state.promotion = action.payload;
      state.product = {
        ...state.product,
        latestPromotion: {
          id: action.payload.promotionId,
          status: action.payload.status,
          endDate: action.payload.endDate,
          startDate: action.payload.startDate,
        },
      };
    },
    [createPromotion.rejected]: (state, action) => {
      state.loading = false;
    },
    [updatePromotion.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [updatePromotion.fulfilled]: (state, action) => {
      state.promotion = action.payload;
      state.product = {
        ...state.product,
        latestPromotion: {
          id: action.payload.promotionId,
          status: action.payload.status,
          endDate: action.payload.endDate,
          startDate: action.payload.startDate,
        },
      };
    },
    [updatePromotion.rejected]: (state, action) => {
      state.loading = false;
    },
    [getProductTickets.pending]: (state) => {
      state.loading = true;
      state.errors = [];
      state.tickets = [];
    },
    [getProductTickets.fulfilled]: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.tickets = action.payload;
      } else {
        state.tickets = [];
      }
    },
    [getProductTickets.rejected]: (state, action) => {
      state.loading = false;
    },
    //END
    [redeemTicket.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [redeemTicket.fulfilled]: (state, action) => {
      const temp = [];
      state?.tickets?.forEach((item) => {
        if (action.payload.ticketID == item._id) {
          temp.push({ ...item, status: 2 });
        } else temp.push(item);
      });

      state.tickets = temp;
      state.loading = false;
    },
    [redeemTicket.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors;
    },
    [updateTimeZone.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [updateTimeZone.fulfilled]: (state, action) => {
      const prod = state.product;
      prod.timeZone = action.payload.timeZone;
      state.product = prod;
    },
    [updateTimeZone.rejected]: (state, action) => {
      state.loading = false;
    },
    [addInterest.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [addInterest.fulfilled]: (state, action) => {
      let interests = state.product.interests
        ? state.product.interests.map((item) => item)
        : [];
      interests.push(action.payload.interest);
      state.product.interests = interests;
    },
    [addInterest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [deleteInterest.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [addMeetUpLocationForService.fulfilled]: (state, action) => {
      state.loading = false;
      state.product.meetupLocation = action.payload?.meetupLocation;
    },
    [addMeetUpLocationForService.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [addMeetUpLocationForService.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },

    [deleteInterest.fulfilled]: (state, action) => {
      state.errors = [];
      let interests = state.product.interests.filter(
        (interest) => interest.name !== action.payload.interest.name
      );
      let newDoc = {
        ...state.product,
        interests,
      };
      state.product = newDoc;
      state.loading = false;
    },
    [deleteInterest.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },

    [addRedeemReview.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [addRedeemReview.fulfilled]: (state, action) => {
      state.errors = [];
      if (state.tickets && state.tickets.length > 0) {
        const tt = state.tickets;

        const uQCodes = tt.map((q) => {
          if (q.id == action.payload.id) {
            return { ...q, ...action.payload };
          } else {
            return q;
          }
        });
        state.tickets = uQCodes;
      } else {
      }
      state.loading = false;
    },
    [getBadges.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [getBadges.fulfilled]: (state, action) => {
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let aSize = action.payload.badges.length;
      if (aSize < limit) {
        state.contentRangeBadges = `${skip}-${limit}/false`;
      } else {
        state.contentRangeBadges = `${skip}-${limit}/true`;
      }
      state.badges = action.payload.badges;
      state.loading = false;
    },
    [getBadges.rejected]: (state, action) => {
      state.loading = false;
    },
    [getMoreBadges.pending]: (state) => {
      state.loading = true;
      state.errors = [];
    },
    [getMoreBadges.fulfilled]: (state, action) => {
      state.badges = [...state.badges, ...action.payload.badges];
      state.loading = false;
      let limit = action.payload.limit;
      let skip = action.payload.skip;
      let aSize = action.payload.badges.length;
      let sum = parseInt(limit) + parseInt(skip);
      if (aSize < limit) {
        state.contentRangeBadges = `${skip}-${sum}/false`;
      } else {
        state.contentRangeBadges = `${skip}-${sum}/true`;
      }
      state.loading = false;
    },
    [getMoreBadges.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  setProductThumbnail,
  setSelectedTask,
  clearTags,
  clearErrors,
  insertProductAfterCreate,
  pushIntoSortedGallery,
  addBadges,
  addTags,
} = productSlice.actions;

export default productSlice.reducer;

export const productSelector = (state) => state.product;
