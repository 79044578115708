import React, { useEffect, useState } from 'react';
import { FreeMode } from 'swiper';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { fileToDataString } from '../utils/static/global_functions';

const AddProgressPics2 = ({ modalState, modalControl }) => {
  const [progress, setProgress] = useState([]);
  const [text, setText] = useState();
  const handleImages = async (e) => {
    const images = [...e.target.files];
    const imagesUrl = [];
    let ImagePromise = images.map(async (item) => {
      return await fileToDataString(item);
    });
    console.log('ImageUrl', ImagePromise);
    Promise.all(ImagePromise).then((thumbnails) => {
      let imageData = thumbnails.map((thumbnail, index) => ({
        file: images[index],
        thumbnail,
      }));
      console.log('ImageDta', imageData);
      setProgress([...progress, ...imageData]);
    });
  };

  useEffect(() => {
    console.log('Imagessss', progress);
  }, [progress.length]);
  return (
    <Popup
      open={modalState}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='relative bg-white w-[500px] rounded-[20px] p-10 max-[767px]:p-5 max-[767px]:h-screen  max-[767px]:overflow-y-scroll max-[600px]:rounded-none'>
        <div className='flex items-start gap-3'>
          <img
            src={Cross}
            alt='cross'
            className='cursor-pointer w-[20px] '
            onClick={modalControl}
          />

          <p className='font-semibold text-jrnyfy-textprimary w-full text-center'>
            Add Progress Pic 2
          </p>
        </div>
        <div>
          <div>
            <div className='flex flex-col mt-[20px] relative text-center'>
              <textarea
                onChange={(e) => {
                  setText(e.target.value);
                }}
                spellCheck='true'
                className='resize-none border-b-2 border-solid border-jrnyfy-textprimary min-h-[100px] z-40 break-words text-[20px] focus:outline-none'
                value={text}
              />
              {!text && (
                <span className='absolute top-0 pointer-events-none w-full text-[20px] min-[350px]:max-[460px]:text-[17px] min-[350px]:max-[460px]:leading-[21px] text-jrnyfy-textprimary font-Montserrat z-[999]'>
                  how is your journey going?..
                  <br />
                  update everyone and keep a log for yourself
                </span>
              )}
            </div>
            <p className='text-jrnyfy-color2'>
              picture captures a thousand, consider adding a few to remind
              yourself where things were
            </p>
          </div>
          <div className='mt-5'>
            <label className='relative p-[10px] border w-full  border-jrnyfy-border rounded-[30px] flex justify-center items-center'>
              <input
                type='file'
                accept='image/*'
                className='hidden'
                onChange={(e) => handleImages(e)}
                multiple
              />

              <span className='absolute bottom-3 text-jrnyfy-textprimary'>
                Add Progress Pic
              </span>
            </label>
          </div>
        </div>

        <div>
          {progress?.length > 0 && (
            <div className='mt-4 max-w-full'>
              <Swiper
                slidesPerView={3}
                spaceBetween={10}
                freeMode={true}
                modules={[FreeMode]}
              >
                {progress?.map((val, ind) => (
                  <SwiperSlide key={ind}>
                    <div>
                      <img
                        src={val.thumbnail}
                        alt='places'
                        className='w-[200px] rounded-[10px] h-[100px] md:h-[120px] 2xl:h-[140px] object-cover'
                        onClick={true}
                      />
                      <div className='absolute top-0 right-0 rounded-[50px] cursor-pointer'>
                        <img
                          src={Cross}
                          alt='cross'
                          className='w-[25px] h-[25px]'
                          // onClick={() => {
                          //   removeImage(ind);
                          // }}
                        />
                      </div>
                      <div className='absolute top-0 left-0 rounded-[50px] cursor-pointer'>
                        {/* <img
                              src={editIcon}
                              alt='cross'
                              className='w-[25px] h-[25px]'
                              // onClick={() => {
                              //   setImageUrl({
                              //     url: URL.createObjectURL(val),
                              //     index: ind,
                              //   });
                              //   setImageFile(val);
                              //   setIsEditEnable(true);
                              // }}
                /> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default AddProgressPics2;
