import { useState } from 'react';
import Aside from '../Aside/Aside';

import FeedPage from '../feedPage/feedPage';
import SitemapInfo from '../SiteMapInfo/SitemapInfo';
import Seo from '../Seo/Seo';

const Body = ({
  tagSearchText,
  tagSearchTextHandle,
  contents,
  setContents,
  searchedText,
  isLogoClick,
}) => {
  console.log('tagSearchText', tagSearchText);

  return (
    <>
      <Seo
        title='Trending Journeys'
        description='Discover incredible places to see and amazing things to do'
        type='webapp'
        name='jrnyfy crop'
        canonicalHref='/'
      />
      <main className=''>
        <div className='w-[90%] min-[1100px]:w-[75%] mx-auto flex justify-between min-[3000px]:w-1/2'>
          <aside className='hidden md:block md:w-[30%] mt-[-127px]'>
            <Aside
              tagSearchText={tagSearchText}
              tagSearchTextHandle={tagSearchTextHandle}
            />
          </aside>
          <div className='md:px-[20px] max-w-[545px] w-full max-[767px]:mb-[80px]'>
            <FeedPage
              contents={contents}
              setContents={setContents}
              searchedText={searchedText}
              isLogoClick={isLogoClick}
              tagSearchText={tagSearchText}
            />
          </div>
          <div className='mt-[-127px] max-w-[500px]'>
            <SitemapInfo />
          </div>
        </div>
      </main>
    </>
  );
};

export default Body;
