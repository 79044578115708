import React, { useEffect } from 'react';
import Thumbnail from '../thumbnails/Thumbnail';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import {
  getChats,
  getMessages,
  messageSelector,
  resetMessages,
} from '../../redux/slices/mobile/messagingSlice';
import Moment from 'react-moment';
import ChatListItem from './ChatListItem';
import { getUnseenChats } from '../utils/static/global_functions';

const ChatList = ({
  setIsEnd,
  setSelectedChat,
  selectedChat,
  showChatRbSheetHandle,
  getMessageHandle,
}) => {
  const { auth, users } = useSelector(authSelector);
  const { chats, updatedSelectedChat } = useSelector(messageSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('updatedSelectedChat', updatedSelectedChat);
    if (updatedSelectedChat[0] >= 0) {
      console.log('updatedSelectedChat', updatedSelectedChat);
      setSelectedChat(chats?.chats[updatedSelectedChat[0]]);
    }
  }, [updatedSelectedChat[1]]);

  useEffect(() => {
    if (chats?.chats?.length > 0) {
      setSelectedChat(chats?.chats[0]);
    }
  }, [chats?.chats?.length]);

  console.log('chats', chats);
  return (
    <div className='space-y-3'>
      {chats.chats.length > 0 &&
        chats?.chats?.map((item) => {
          return (
            <ChatListItem
              setIsEnd={setIsEnd}
              showChatRbSheetHandle={showChatRbSheetHandle}
              getMessageHandle={getMessageHandle}
              item={item}
              auth={auth}
              selectedChat={selectedChat}
            />
          );
        })}
    </div>
  );
};

export default ChatList;
