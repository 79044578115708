import React from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { BTN_SHARE_YOUR_STORY } from '../../utils/static';
import { Navigate } from 'react-router-dom';
import { getUserNavOption } from '../../utils/get-nav-option';
import { getPhotosUrl } from '../../res/commonFunctions';
import Thumbnail from '../thumbnails/Thumbnail';
import { PhotoSize } from '../utils/subjects';

function CreateSuccessStory({ callback, btnText }) {
  const {
    auth: { user },
  } = useSelector(authSelector);
  console.log('User', user);
  return (
    <div>
      <div className='relative'>
        <div className='absolute top-0 left-0'>
          {user && (
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          )}
        </div>
        <button
          onClick={() => callback()}
          className='bg-gray-100 w-full p-[10px_20px] rounded-[50px] text-gray-400'
        >
          {btnText}
        </button>
      </div>
    </div>
  );
}

export default CreateSuccessStory;
