import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserListRenderer from '../userList/UserListRenderer';
import { getUsers } from '../../redux/slices/mobile/authSlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import topStory1 from '../../assets/images/pyramid.png';
import topStory2 from '../../assets/images/valley.png';
import topStory3 from '../../assets/images/river.png';

const ServicePageSidebar = () => {
  const { users, token } = useSelector(authSelector);

  const topTopics = [
    { topTopics: 'pyramid of giza' },
    { topTopics: 'Eiffel Tower' },
    { topTopics: 'fit body' },
    { topTopics: 'amstradam' },
    { topTopics: 'ice bucket challenge' },
  ];

  const topStoryImages = [
    { topStoryImg: topStory1 },
    { topStoryImg: topStory2 },
    { topStoryImg: topStory3 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getUsers({
        data: {
          from: 0,
          size: 10,
          value: undefined,
        },
        token,
      })
    );
  }, []);
  return (
    <div className='max-w-[416px] space-y-10'>
      <div className=''>
        <span>top contributors</span>
        <UserListRenderer users={users} />
      </div>
      <div className='space-y-5'>
        <span>top topics</span>
        <div className='flex flex-wrap gap-3'>
          {topTopics &&
            topTopics.map((item) => (
              <button className='p-[8px_20px_9px_15px] rounded-[50px] text-[12px] border border-jrnyfy-textprimary text-jrnyfy-textprimary font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white hover:bg-jrnyfy-themeColor transition-all font-SourceSansPro'>
                {item.topTopics}
              </button>
            ))}
        </div>
      </div>
      <div className='space-y-5'>
        <span>top story places</span>
        <div className='flex gap-4'>
          {topStoryImages &&
            topStoryImages.map((item) => (
              <div className='flex flex-col items-center space-y-3'>
                <img
                  src={item.topStoryImg}
                  alt='story place'
                  className='rounded-[50%] w-[80px] h-[75px]'
                />
                <span className='w-[80px] text-center'>pyramid of giza</span>
              </div>
            ))}
        </div>
      </div>
      <div className=''>
        <span>top services</span>
        <UserListRenderer users={users} />
      </div>
    </div>
  );
};

export default ServicePageSidebar;
