import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import { BTN_CREATE_A_NEW_SERVICE } from '../../utils/static';
import momentTZ from 'moment-timezone';
import addPlaceIcon from '../../assets/images/button_menu_places.svg';
import {
  createProduct,
  insertProductAfterCreate,
} from '../../redux/slices/mobile/productSlice';
import { buildUrlToSize } from '../../res/commonFunctions';
import { PhotoSource } from '../utils/subjects';
import AddPlaces from '../AddPlaces/AddPlaces';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import { onTypeChange } from '../utils/typing-change';
import {
  tagSelectorV2,
  tagsFromText,
} from '../../redux/slices/mobile/tagSliceV2';

const ServiceCreateModal = ({
  serviceCreateModal,
  serviceCreateModalHandle,
  setServiceCreateModal,
}) => {
  const { auth } = useSelector(authSelector);
  const { selectedTags, tagCheckingComplete, deletedTagsName } =
    useSelector(tagSelectorV2);
  const [serviceText, setServiceText] = useState('');
  const [placeArr, setPlaceArr] = useState([]);
  let [locState, setLocState] = useState(false);
  const dispatch = useDispatch();
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  let locationControl = () => {
    setLocState((state) => !state);
  };
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  useEffect(() => {
    if (serviceText) {
      dispatch(
        tagsFromText({
          token: auth.token,
          value: serviceText,
        })
      );
    }
  }, [serviceText]);

  const createServiceHandle = () => {
    // dispatch(clearGlobal());
    let thumbnail = undefined;
    if (selectedTags && selectedTags.length > 0) {
      for (let i = 0; i < selectedTags.length; i++) {
        if (selectedTags[i].thumbnail) {
          thumbnail = selectedTags[i].thumbnail;
          break;
        }
      }
    }
    setActionStates({ ...actionStates, loading: true });
    dispatch(
      createProduct({
        data: {
          name: serviceText,
          tags: selectedTags,
          thumbnail,
          tagChecked: tagCheckingComplete,
          deletedTagsName,
          timeZone: momentTZ.tz.guess(),
          locations: placeArr
            ? placeArr.map((item) => {
                return {
                  locationId: item?.plus_code?.global_code || item?.place_id,
                  latitude: item?.photos[0].photo_reference
                    ? item?.geometry.location.lat
                    : item?.geometry.location.lat(),
                  longitude: item?.photos[0].photo_reference
                    ? item?.geometry.location.lat
                    : item?.geometry.location.lat(),
                  name: item.name,
                  photo: {
                    photoReference: item?.photos[0].photo_reference
                      ? item?.photos[0].photo_reference
                      : item?.photos[0].getUrl(),
                    photoSource: item?.photos[0].photo_reference
                      ? PhotoSource.GooglePlace
                      : PhotoSource.GooglePlaceFullURL,
                  },
                };
              })
            : undefined,
        },
        token: auth.token,
        callback: (data) => {
          dispatch(insertProductAfterCreate(data));
        },
      })
    ).then((res) => {
      console.log('Response', res.meta.requestStatus);
      if (res.meta.requestStatus == 'fulfilled') {
        setServiceCreateModal(false);
        setServiceText('');
        setPlaceArr([]);
        setActionStates({ ...actionStates, loading: false, fulfilled: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
      if (res.meta.requestStatus == 'rejected') {
        setActionStates({ ...actionStates, loading: false, rejected: true });
        setServiceText('');
        setPlaceArr([]);
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };

  return (
    <Popup
      open={serviceCreateModal}
      onClose={() => {
        setServiceCreateModal(false);
      }}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='space-y-5 bg-white w-[600px] rounded-[20px] p-10 max-[500px]:p-5 max-[500px]:h-screen max-[500px]:overflow-y-scroll max-[500px]:rounded-none'>
        {!locState && (
          <div className='space-y-10'>
            <img
              src={Cross}
              alt='cross'
              className='cursor-pointer w-[25px] absolute top-[20px] left-[25px]'
              onClick={serviceCreateModalHandle}
            />

            <div className='flex flex-col relative max-[500px]:mt-[50px] mt-[20px]'>
              <textarea
                onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                  setServiceText(value);
                })}
                spellCheck='true'
                value={typeInput.text}
                className='resize-none max-[500px]:border-b min-[501px]:border-b-2 border-solid border-jrnyfy-textprimary min-h-[100px] z-40 break-words text-[20px] focus:outline-none'
              />

              {!typeInput.text && (
                <span
                  className={`text-center absolute top-0 pointer-events-none w-full text-[20px] max-[500px]:text-[14px] min-[350px]:max-[460px]:leading-[21px] text-jrnyfy-textprimary font-Montserrat z-[999]`}
                >
                  {BTN_CREATE_A_NEW_SERVICE}
                </span>
              )}
            </div>

            <div className='flex items-center flex-wrap-reverse justify-center gap-3'>
              <button
                className='max-[500px]:border min-[501px]:border-2 border-solid border-jrnyfy-textprimary text-jrnyfy-color2 max-[500px]:p-[5px_10px] min-[501px]:p-[7px_15px] max-[500px]:text-[14px]  rounded-[50px]  min-[350px]:max-[460px]:leading-[15px] font-SourceSansPro'
                onClick={locationControl}
              >
                add places
              </button>
              <div className='flex flex-wrap'>
                {placeArr && placeArr.length >= 1 ? (
                  placeArr.map((val, ind) => (
                    <img
                      key={ind}
                      src={
                        val?.photos === undefined
                          ? addPlaceIcon
                          : buildUrlToSize({
                              photoReference: val?.photos[0].photo_reference
                                ? val?.photos[0].photo_reference
                                : val?.photos[0].getUrl(),
                              photoSource: val?.photos[0].photo_reference
                                ? PhotoSource.GooglePlace
                                : PhotoSource.GooglePlaceFullURL,
                            })
                      }
                      alt={val.name}
                      className='w-[35px] h-[35px] max-w-none rounded-[50%] ring-2 ring-white ml-[-7px]'
                    />
                  ))
                ) : (
                  <span className='text-jrnyfy-color2'>
                    where will this journey take place?
                  </span>
                )}
              </div>
            </div>
            <div className='flex justify-center'>
              {/* <button
                onClick={createServiceHandle}
                className='bg-jrnyfy-themeColor max-[500px]:p-[5px_10px] min-[501px]:p-[10px_20px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro'
              >
                start service
              </button> */}
              <FunctionalButton
                actionStates={actionStates}
                callback={() => createServiceHandle()}
                actionStatesTexts={{
                  default: 'start service',
                  loading: 'service creating',
                  fulfilled: 'service created Successfully',
                  rejected: 'service creation Failed',
                }}
              />
            </div>
          </div>
        )}

        {locState && (
          <AddPlaces
            showBackBtn={true}
            control={locationControl}
            placeAdd={setPlaceArr}
            placeArr={placeArr}
          />
        )}
      </div>
    </Popup>
  );
};

export default ServiceCreateModal;
