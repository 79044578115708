import React, { useState } from 'react';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import {
  activitySelector,
  addSuggestedTask,
} from '../../redux/slices/mobile/activitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { buildUrlToSize } from '../../res/commonFunctions';
import { PhotoSize } from '../utils/subjects';
import defaultSvgTasks from '../../assets/images/defaultSvgTasks.svg';

const SuggestedTask = ({
  modalControl,
  timeLineContent,
  setTimelineContent,
  item,
  showFloatingMenuHandle,
}) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const { activity } = useSelector(activitySelector);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  console.log('TaksItem', item);
  const addSuggestionHandle = (item) => {
    setActionStates({ ...actionStates, loading: true });
    modalControl();
    if (showFloatingMenuHandle) {
      showFloatingMenuHandle();
    }
    let tempTimelineContent = [
      { ...item, user: auth?.user },
      ...timeLineContent,
    ];
    setTimelineContent([
      { ...item, user: auth?.user, loading: true, type: 1 },
      ...timeLineContent,
    ]);
    dispatch(
      addSuggestedTask({
        token: auth.token,
        id: activity.id,
        taskId: item.id,
        user: auth.user,
        data: {
          skSuggestedTask: item.sk,
          sk: activity.sk,
          createdAt: activity.createdAt,
        },
      })
    ).then((res) => {
      console.log('ResponseTask', res);
      if (res.meta.requestStatus == 'fulfilled') {
        tempTimelineContent[0] = res?.payload?.timelines[0];
        setTimelineContent(tempTimelineContent);
        setActionStates({ ...actionStates, loading: false, fulfilled: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
      if (res.meta.requestStatus == 'rejected') {
        setActionStates({ ...actionStates, loading: false, rejected: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };
  return (
    <div className='flex justify-between items-center'>
      <div className='flex items-center gap-3'>
        <img
          src={
            item?.location?.photos
              ? buildUrlToSize(item?.location?.photos[0], PhotoSize.Large)
              : defaultSvgTasks
          }
          className='rounded-[50%] border-4 w-[50px] h-[50px]'
          alt={item.description}
        />
        {item.description}
      </div>
      <div>
        <FunctionalButton
          actionStates={actionStates}
          callback={() => addSuggestionHandle(item)}
          params={item}
          bgColor={'jrnyfy-themeColor'}
          text={{
            color: 'white',
            weight: 'semibold',
            size: '14px',
            font: 'SourceSansPro',
            style: 'italic',
          }}
          padding={'5px_10px'}
          actionStatesTexts={{
            default: 'Add',
            loading: 'Adding',
            fulfilled: 'Added Successfully',
            rejected: 'Added Failed',
          }}
        />
      </div>
    </div>
  );
};

export default SuggestedTask;
