import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { Calendar, DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import {
  authSelector,
  updateProfile,
} from '../../redux/slices/mobile/authSlice';
import { format } from 'date-fns';
import { TimeStampToDate } from '../utils/static/global_functions';
import Seo from '../Seo/Seo';
import { useNavigate } from 'react-router-dom';
import { Gender } from '../utils/subjects';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import backBtn from '../../assets/icons/back.png';
import BackButton from '../BackButton/BackButton';

const PersonalInfoSetting = () => {
  const { auth } = useSelector(authSelector);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  const [dob, setDOB] = useState(TimeStampToDate(auth?.user?.dob));
  console.log('UserInfo', auth);
  const [userInfo, setUserInfo] = useState({
    name: undefined,
    dob: new Date(),
    gender: undefined,
    city: undefined,
    country: undefined,
  });
  const dateRef = useRef();
  const [data, setData] = useState({
    companyDescription: undefined,
    type: { key: undefined },
    theme: { name: undefined },
    name: undefined,
  });

  useEffect(() => {
    const hideModalOutSideClickHandle = (e) => {
      if (!dateRef?.current?.contains(e.target)) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener('mousedown', hideModalOutSideClickHandle);
  });

  useEffect(() => {
    setUserInfo((prevState) => ({
      ...prevState,
      name: auth?.user?.name,
      gender: auth?.user?.gender,
      city: auth?.user?.city,
      country: auth?.user?.country,
    }));
  }, [auth.user]);

  console.log('UserInfoState', userInfo);
  const updateUserInfoHandle = () => {
    setActionStates({ ...actionStates, loading: true });
    dispatch(updateProfile({ data: userInfo, token: auth?.token })).then(
      (res) => {
        console.log('Response', res.meta.requestStatus);
        if (res.meta.requestStatus == 'fulfilled') {
          setActionStates({ ...actionStates, loading: false, fulfilled: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
          }, 3000);
        }
        if (res.meta.requestStatus == 'rejected') {
          setActionStates({ ...actionStates, loading: false, rejected: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
          }, 3000);
        }
      }
    );
  };

  return (
    <>
      <Seo
        title={` Personal Info | Provide details about you`}
        description=''
        type='webapp'
        name='jrnyfy crop.'
        canonicalHref='/setting/personal-info'
      />
      <div className='max-w-[660px] pb-[80px] mx-auto px-5 mt-3 space-y-10'>
        <div className='flex items-start gap-1'>
          <BackButton callback={() => navigate(-1)} />
          <div className='w-fit space-y-3'>
            <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit leading-3'>
              Personal Info
            </h4>
            <p className='text-start text-jrnyfy-textprimary w-fit'>
              Provide details about you
            </p>
          </div>
        </div>

        <div className='pb-5 space-y-6'>
          <input
            type='text'
            name='company'
            className='text-jrnyfy-textprimary border-2 border-jrnyfy-colorLightGray rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            placeholder='Full Name'
            value={userInfo.name}
            onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
          />
          <div className='relative' ref={dateRef}>
            <input
              type='text'
              name='company'
              className='text-jrnyfy-textprimary border-2 border-jrnyfy-colorLightGray rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder='Full Name'
              onClick={() => setShowDatePicker((prevState) => !prevState)}
              value={dob}
            />
            {showDatePicker && (
              <Calendar
                maxDate={new Date()}
                className='absolute top-[50px] left-0 z-40 rounded-xl shadow-xl'
                onChange={(item) => {
                  setDOB(format(item, 'dd-LLL-yyyy'));
                  setUserInfo({
                    ...userInfo,
                    dob: item,
                  });
                }}
                date={userInfo.dob}
              />
            )}
          </div>
          <select
            onChange={(e) =>
              setUserInfo({ ...userInfo, gender: e.target.value })
            }
            className='w-full p-[10px] rounded-[64px] text-jrnyfy-textprimary bg-transparent border-2 border-jrnyfy-colorLightGray'
          >
            <option
              value=''
              className='hover:text-jrnyfy-themeColor'
              disabled
              selected
            >
              Gender
            </option>
            <option
              value={Gender.Male}
              className='hover:text-jrnyfy-themeColor'
              selected={userInfo.gender == Gender.Male ? true : false}
            >
              Male
            </option>
            <option
              value={Gender.Female}
              className='hover:text-jrnyfy-themeColor'
              selected={userInfo.gender == Gender.Female ? true : false}
            >
              Female
            </option>
            <option
              value={Gender.Other}
              className='hover:text-jrnyfy-themeColor'
              selected={userInfo.gender == Gender.Other ? true : false}
            >
              Other
            </option>
          </select>
          <Autocomplete
            apiKey={'AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE'}
            className='text-jrnyfy-textprimary border-2 border-jrnyfy-colorLightGray rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            onPlaceSelected={(place) => {
              const placeData = place.formatted_address.split(',');
              setUserInfo({
                ...userInfo,
                city: placeData[0],
                country: placeData[placeData.length - 1],
              });
            }}
            options={{
              types: ['locality', 'country'],
            }}
            value={userInfo.city}
            defaultValue='City'
            onChange={(e) =>
              setUserInfo({
                ...userInfo,
                city: e.target.value,
              })
            }
          />
          <input
            type='text'
            name='theme'
            className='text-jrnyfy-textprimary border-2 border-jrnyfy-colorLightGray rounded-[64px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            placeholder='Country'
            value={userInfo.country}
            onChange={(e) =>
              setUserInfo({
                ...userInfo,
                country: e.target.value,
              })
            }
          />
          <div className='flex justify-between'>
            {/* <button
              className='bg-jrnyfy-border rounded-[50px] p-[10px_40px] text-[15px] font-bold text-jrnyfy-textprimary'
              onClick={() => navigate(-1)}
            >
              back
            </button> */}

            <FunctionalButton
              actionStates={actionStates}
              callback={() => updateUserInfoHandle()}
              actionStatesTexts={{
                default: 'Update',
                loading: 'Updating',
                fulfilled: 'Updated Successfully',
                rejected: 'Update Failed',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInfoSetting;
