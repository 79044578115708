import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import backBtn from '../../assets/icons/back.png';
import BackButton from '../BackButton/BackButton';
import Popup from 'reactjs-popup';
import { useNavigate } from 'react-router-dom';
import Cross from '../../assets/images/xmark-solid.svg';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import {
  getReportReasonsList,
  reportItem,
  reportSelector,
} from '../../redux/slices/mobile/reportSlice';
import { ReportedContentType, ReportedDocumentType } from '../utils/subjects';

const ReportItem = ({ data, controlFunction, isModal,type,contentType }) => {
  const [steps, setSteps] = useState(0);
  const { reportsInfoList } = useSelector(reportSelector);
  const [reportArray, setReportArray] = useState(reportsInfoList);
  const [selectedReportInfo, setSelectedReportInfo] = useState({
    step1: [],
    step2: [],
  });
  const dispatch = useDispatch();
  const { auth } = useSelector(authSelector);
  const navigate = useNavigate();
  console.log('Report List ds', reportsInfoList);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  useEffect(() => {
    dispatch(
      getReportReasonsList({
        skip: 0,
        limit: 10,
        token: auth.token,
      })
    );
  }, []);

  useEffect(() => {
    setReportArray(reportsInfoList);
  }, [reportsInfoList]);

  // const reportArray = [
  //   {
  //     id: 1,
  //     name: 'Nudity',
  //     children: [
  //       {
  //         id: 1,
  //         name: 'Adult Nudity',
  //       },
  //       {
  //         id: 2,
  //         name: 'Sexually Suggestive',
  //       },
  //       {
  //         id: 3,
  //         name: 'Sexual Activity',
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: 'Violence',
  //     children: [
  //       {
  //         id: 1,
  //         name: 'Graphic Violence',
  //       },
  //       {
  //         id: 2,
  //         name: 'Animal Abuse',
  //       },
  //       {
  //         id: 3,
  //         name: 'Something Else',
  //       },
  //     ],
  //   },
  // ];

  const reportItemHandle = () => {
    setActionStates({ ...actionStates, loading: true});
    dispatch(
      reportItem({
        data: {
          document: { id: data.id, sk: data.sk, createdAt: data.createdAt },
          document: { id: data.id, sk: data.sk, createdAt: data.createdAt },
          photos: data?.photos ,
          type: type,
          contentType:contentType,
          name: data.name,
          issue: selectedReportInfo.step2.name,
        },
        token: auth.token,
      })
    ).then(
      (res) => {
        console.log('Response', res.meta.requestStatus);
        if (res.meta.requestStatus == 'fulfilled') {
          setActionStates({ ...actionStates, loading: false, fulfilled: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
          }, 3000);
        }
        if (res.meta.requestStatus == 'rejected') {
          setActionStates({ ...actionStates, loading: false, rejected: true });
          setTimeout(() => {
            setActionStates({
              ...actionStates,
              loading: false,
              fulfilled: false,
              rejected: false,
            });
          }, 3000);
        }
      }
    );
  };

  return (
    <Popup
      open={isModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='relative flex justify-between flex-col space-y-5  bg-white w-[500px] h-[500px] rounded-[20px] p-5 max-[767px]:p-5 max-[767px]:h-screen  max-[767px]:overflow-y-scroll max-[600px]:rounded-none '>
        <div>
          <div className='flex items-center justify-between gap-1 '>
            <BackButton
              callback={() =>
                steps == 0
                  ? navigate(-1)
                  : steps == 1
                  ? setSteps(0)
                  : setSteps(1)
              }
            />
            <p>Report</p>
            <button onClick={() => controlFunction(false)}>
              <img
                src={Cross}
                alt='cross'
                className='cursor-pointer w-[20px]'
              />
            </button>
          </div>
          <hr class='h-px my-2 bg-gray-200 border-0 dark:bg-gray-700'></hr>
        </div>
        <div className='flex-1 flex justify-between flex-col space-y-5'>
          <div className='w-fit space-y-2'>
            <h4 className='text-start text-jrnyfy-textprimary text-2xl w-fit leading-3'>
              {steps == 2
                ? selectedReportInfo?.step2.name
                : steps==0?'Please Select a Problem':`What kind of ${selectedReportInfo?.step1.name} ?`}
            </h4>
            <p className='text-start text-jrnyfy-textprimary w-fit text-[14px]'>
              {steps == 2
                ? "We only remove content that goes against our Community Standards. We don't allow things such as:"
                : "If someone is in a immediate danger, get help before reporting to JRNYFY. Don't wait."}
            </p>
            {steps == 2 && (
              <ul className='list-disc ml-5'>
                <li>Sexual activity</li>
                <li>Offering or requesting sexual activity</li>
                <li>
                  Female nipples (except breastfeeding, health and acts of
                  protest)
                </li>
                <li>Nudity showing genitals</li>
                <li>Sexually explicit language</li>
              </ul>
            )}
          </div>
          {steps == 0 ? (
            <div className='flex-1 flex flex-col'>
            
              {reportArray?.map((item, index) => (
                <div
                  onClick={() => {
                    setSelectedReportInfo({
                      ...selectedReportInfo,
                      step1: item,
                    });
                    setSteps(1);
                  }}
                  className='flex justify-between items-center hover:bg-gray-400 p-2 rounded-xl'
                >
                  <p>{item.name}</p>
                  <img
                    src={backBtn}
                    alt='right arrow'
                    className='cursor-pointer w-[10px] rotate-180'
                  />
                </div>
              ))}
          
            </div>
          ) : steps == 1 ? (
            <div className='flex-1 flex justify-between flex-col '>
        
             
                {selectedReportInfo?.step1?.children?.map((item) => (
                  <div
                    onClick={() => {
                      setSelectedReportInfo({
                        ...selectedReportInfo,
                        step2: item,
                      });
                      setSteps(2);
                    }}
                    className='flex justify-between items-center hover:bg-gray-400 p-2 rounded-xl'
                  >
                    <p>{item.name}</p>
                    <img
                      src={backBtn}
                      alt='right arrow'
                      className='cursor-pointer w-[10px] rotate-180'
                    />
                  </div>
                ))}
             
          
            </div>
          ) : (
            <div>
              <FunctionalButton
                actionStates={actionStates}
                callback={() => reportItemHandle()}
                actionStatesTexts={{
                  default: 'Submit',
                  loading: 'Processing',
                  fulfilled: 'Submitted Successfully',
                  rejected: 'Process Failed',
                }}
                text={{ size: '12px', weight: 'bold' }}
                padding={'5px_15px'}
              />
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default ReportItem;
