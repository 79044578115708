import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "../src/routes/Router";
import NetworkStatus from "./components/utils/NetworkStatus";
import { getServices } from "./redux/slices/web/serviceSlice";
import { HelmetProvider } from "react-helmet-async";
import {
  authSelector,
  checkAuthorizedUser,
  getNotificationCount,
  logOut,
} from "./redux/slices/mobile/authSlice";
import { configureSocket } from "./socket/socket";
import { setSocketChannels } from "./socket/socket-channels";
import { BrowserRouter } from "react-router-dom";

function App() {
  // useEffect(() => {
  //  if (users && users.length > 0){
  //    const urls = users.map(u=>{
  //      return buildUrlToSize(u.thumbnail);
  //    })
  //    console.log("urls", urls)

  //    const urls1 = users.map(u=>{
  //      if (u.thumbnail){
  //       return convertLargeToThumbnail(u.thumbnail);
  //      }else{
  //       return u
  //      }
  //   });

  //   console.log("urls1", urls1)

  //  }

  // }, [users]);
  // console.log("env", process.env.REACT_APP_BASE_URL)
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const test = "";

  useEffect(() => {
    if (auth.token) {
      dispatch(
        checkAuthorizedUser({
          token: auth.token,
          callback: (res) => {
            if (res?.data?.statusCode == 401) {
              dispatch(logOut());
            }
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (auth.token) {
      const socket = configureSocket(auth);
      if (socket) {
        setSocketChannels(socket, dispatch, auth);
      }
    }
  }, [auth]);
  return (
    <HelmetProvider>
      <BrowserRouter>
      <NetworkStatus/>
      <Router />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
