import { FeedFilter, JourneyFilter } from '../components/utils/subjects';

// export const UserJourneyFilter = {
//   CurrentJourney: 0,
//   PastJourney: 1,
//   DraftJourney: 2,
// };
// export const userFiltersOwner = [
//   {
//     name: 'CURRENT JOURNEY',
//     value: UserJourneyFilter.CurrentJourney,
//     status: true,
//   },
//   {
//     name: 'PAST JOURNEY',
//     value: UserJourneyFilter.PastJourney,
//     status: false,
//   },
//   {
//     name: 'DRAFT JOURNEY',
//     value: UserJourneyFilter.DraftJourney,
//     status: false,
//   },
// ];
export const vierJourneyFilter = [
  {
    name: 'All',
    value: JourneyFilter.All,
    status: true,
  },
  {
    name: 'CURRENT JOURNEY',
    value: JourneyFilter.Current,
    status: false,
  },
  {
    name: 'PAST JOURNEY',
    value: JourneyFilter.PastJourney,
    status: false,
  },
  {
    name: 'DRAFT JOURNEY',
    value: 100,
    status: false,
  },
];

export const feedFilters = [
  {
    name: 'TRENDING',
    value: FeedFilter.Trending,
    status: true,
  },
  {
    name: 'MY CIRCLE',
    value: FeedFilter.MyCircle,
    status: false,
  },
  {
    name: 'PEOPLE',
    value: -1,
    status: false,
  },
  {
    name: 'SUCCESS STORIES',
    value: 0,
    status: false,
  },
];
