import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import FollowerList from '../jrnyTimline/FollowerList';
import { useDispatch, useSelector } from 'react-redux';
import {
  activitySelector,
  getJourneyContent,
} from '../../redux/slices/mobile/activitySlice';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { ParticipantStatus, UserContentFilter } from '../utils/subjects';
import Cross from '../../assets/images/xmark-solid.svg';

const ParticipantListModal = ({
  showParticipantModal,
  showParticipantModalHandle,
  jrnyActivity,
}) => {
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  let [userArr, userAdd] = useState([]);
  let [users, setUsers] = useState([]);
  const { participant } = useSelector(activitySelector);

  useEffect(() => {
    dispatch(
      getJourneyContent({
        data: {
          from: 0,
          size: 25,
          filterId: jrnyActivity.id,
          filter: UserContentFilter.GetParticipantsOfAJourney,
          participantStatus: ParticipantStatus.Accepted,
        },
        token: auth.token,
        activityId: jrnyActivity.id,
      })
    );
  }, []);
  return (
    <Popup
      open={showParticipantModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='w-[500px] bg-white space-y-5 p-10 rounded-[20px]  max-[767px]:p-5 max-[767px]:h-screen  max-[767px]:overflow-y-scroll max-[600px]:rounded-none '>
        <div className='flex items-center'>
          <div className='w-fit'>
            <img
              src={Cross}
              alt='cross'
              className='cursor-pointer w-[20px]'
              onClick={() => showParticipantModalHandle(false)}
            />
          </div>
          <p className='w-full text-center text-jrnyfy-textprimary font-bold'>
            Participant List
          </p>
        </div>
        <div className=''>
          {participant.map((val, ind) => (
            <FollowerList
              users={users}
              setUsers={setUsers}
              jrnyActivity={jrnyActivity}
              userArr={userArr}
              userAdd={userAdd}
              val={val}
              ind={ind}
            />
          ))}
        </div>
      </div>
    </Popup>
  );
};

export default ParticipantListModal;
